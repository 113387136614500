import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { getUser } from "../../reducers/authReducer";
import { getDosarInscris } from "../../reducers/inscrisiReducer";
import { fetchDosarInscris, downloadScrisoare } from "../../actions";

class ValidareFinala extends Component {
  async componentDidMount() {
    const { t, user } = this.props;
    await this.props.fetchDosarInscris();
  }

  handleDownloadScrisoare = async () => {
    await this.props.downloadScrisoare({
      dosarId: this.props.dosar._id,
    });
  };

  handleDownloadAccomodation = async () => {
    await this.props.downloadDovadaCazareUTCB({
      dosarId: this.props.dosar._id,
    });
  };

  render() {
    const { t, dosar, lang } = this.props;
    // if (dosar.status === "semnat") {
    //   return (
    //     <div>
    //       <Typography gutterBottom variant="h4">
    //         {t.finalValidationPending}
    //       </Typography>
    //       <Typography>{t.finalValidationPendingDescription}</Typography>
    //     </div>
    //   );
    // }
    if (dosar.status === "respinsFinal") {
      return (
        <div>
          <Typography gutterBottom variant="h4">
            {t.finalValidationRejected}
          </Typography>

          <Typography>{t.finalValidationRejectedDescription}</Typography>
        </div>
      );
    }
    if (dosar.status === "eligibil") {
      if (lang === "ro" || lang === "en") {
        return (
          <div>
            <Typography gutterBottom variant="h4">
              Your dossier is eligible
            </Typography>
            <Typography>
              Dear prospective student,
              <br />
              <br />
              <br />
              We are pleased to inform you that your application for acceptance
              at the Technical University of Civil Engineering Bucharest has
              been approved.
              <br />
              <br />
              Regarding the VISA, you must contact the closest Romanian
              authority and search for information concerning the study visa
              application. This is a personal matter in which the university
              does not interfere. In case the visa application includes some
              documents issued by the university, you must require specifically
              those documents. En ce qui concerne le VISA, vous devez contacter
              les auhorités roumaines les plus proches et vous renseigner sur
              les demandes de visa d’études. Il s’agit d’une démarche
              personnelle dans laquelle l’université n’intervient pas. Au cas où
              le visa nécessiterait des documents propres à l’université, vous
              devrez nous demander ces documents spécifiques.
              <br />
              <br />
              For enrollment, you must be present at the Technical University of
              Civil Engineering Bucharest, with the following documents:
              <br />
              • the original of documents which were sent with the application
              form;
              <br />
              • the original of Letter of Acceptance for studies (unless the
              original is at UTCB);
              <br />
              • the passport with a study visa;
              <br />
              • two 3/4 photos.
              <br />
              It is mandatory for you to present the original study documents
              authenticated by the authorities in the issuer country as follows:
              <br />
              • the original study documents which are part of the Hague
              Convention will be stamped by the competent authorities in the
              issuer country with the Hague Apostille.
              <br />
              • the original study documents which are not part of the Hague
              Convention will be legalized by the Ministry of Foreign Affairs in
              the issuer country and by the Embassy/Consulate Office of Romania
              in the issuer country.
              <br />
              For more information consult:{" "}
              <a
                className="underline text-link"
                href="https://dri.utcb.ro/en/admitere-studenti-internationali/"
              >
                https://dri.utcb.ro/en/admitere-studenti-internationali/
              </a>
              <br />
              <br />
              Best regards, International Relations Office – UTCB
              <br />
              <a
                className="underline text-link"
                href="mailto:admission-info@utcb.ro"
              >
                admission-info@utcb.ro
              </a>
            </Typography>
            <div style={{ marginTop: "12px" }}>
              <Button
                onClick={this.handleDownloadScrisoare}
                variant="contained"
                color="primary"
              >
                {t.finalValidationDownloadLetter}
              </Button>
            </div>
            <div style={{ marginTop: "12px" }}>
              <Button
                onClick={this.handleDownloadAccomodation}
                variant="contained"
                color="primary"
              >
                {t.finalValidationDownloadAccomodation}
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div>
          <Typography gutterBottom variant="h4">
            Your dossier is eligible
          </Typography>
          <Typography>
            Cher(e)s futur(e)s étudiant(e)s,
            <br />
            <br />
            <br />
            Nous sommes heureux de vous informer que votre demande d’inscription
            à l’Université Technique de Construction Bucarest a été acceptée.
            <br />
            <br />
            Pour l’inscription, vous devez vous présenter directement à
            l’Université Technique de Construction Bucharest avec les documents
            suivants:
            <br />
            • - Les originaux des documents envoyés avec le dossier
            d’inscciption
            <br />
            • - L&apos;original de la Lettre d’Acceptation pour études (sauf si
            cet original est à l’UTCB)
            <br />
            • - Votre passport avec un visa d’études
            <br />
            • - 2 photos au format 3/4
            <br />
            Il est obligatoire que vous présentiez les documents originaux
            relatifs à vos études authentifiés par les authorités dans le pays
            de délivrance comme suit:
            <br />
            • - Les originaux des documents relatifs aux études faisant partie
            de la Convention de la Hague devront être tamponnés par les
            authorité compétences du pays de délivrance avec l’appostille de la
            Hague.
            <br />
            • - Les originaux des documents relatifs aux études sans relation
            avec la Convention de la Hague seront certifiés authentiques par le
            Ministère des affaires étrangères du pays de délivrance et par
            l’ambassade/le consulat de Roumanie du pays de délivrance.
            <br />
            Pour plus d’informations, nous vous invitons à vous rendre à cette
            adresse:{" "}
            <a
              className="underline text-link"
              href="https://dri.utcb.ro/en/admitere-studenti-internationali/"
            >
              https://dri.utcb.ro/en/admitere-studenti-internationali/
            </a>
            <br />
            <br />
            Best regards, International Relations Office – UTCB
            <br />
            <a
              className="underline text-link"
              href="mailto:admission-info@utcb.ro"
            >
              admission-info@utcb.ro
            </a>
          </Typography>
          <div style={{ marginTop: "12px" }}>
            <Button
              onClick={this.handleDownloadScrisoare}
              variant="contained"
              color="primary"
            >
              {t.finalValidationDownloadLetter}
            </Button>
          </div>
          <div style={{ marginTop: "12px" }}>
            <Button
              onClick={this.handleDownloadAccomodation}
              variant="contained"
              color="primary"
            >
              {t.finalValidationDownloadAccomodation}
            </Button>
          </div>
        </div>
      );
    }
    if (dosar.status === "acceptat") {
      return (
        <div>
          <Typography gutterBottom variant="h4">
            {t.finalValidationAccepted}
          </Typography>
          <div style={{ marginBottom: "12px" }}>
            <Button
              onClick={this.handleDownloadScrisoare}
              variant="contained"
              color="primary"
            >
              {t.finalValidationDownloadLetter}
            </Button>
          </div>
          <div style={{ marginBottom: "12px" }}>
            <Button
              onClick={this.handleDownloadAccomodation}
              variant="contained"
              color="primary"
            >
              {t.finalValidationDownloadAccomodation}
            </Button>
          </div>
          <Typography>{t.finalValidationAcceptedDescription}</Typography>
        </div>
      );
    }
    return <div />;
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations[state.i18nState.lang],
  nomenclatoare: state.nomenclatoare,
  user: getUser(state),
  dosar: getDosarInscris(state),
});

const mapDispatchToProps = {
  fetchDosarInscris,
  downloadScrisoare,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidareFinala);
