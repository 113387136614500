import React, { Component } from "react";
import {
  Tab,
  Tabs,
  AppBar,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import * as yup from "yup";
import { connect } from "react-redux";
import { setLanguage } from "redux-i18n";
import { Formik, Form } from "formik";
import FileSaver from "file-saver";
import request from "superagent";
import {
  fetchInscrisById,
  updateInscris,
  handleReject,
  handleValidate,
  finalizeDosar,
} from "../../../actions/inscrisi";
import {
  fetchNomenclatoarePublice,
  fetchTipuriDeDocumente,
  showSnackbar,
  setAppBarTitle,
} from "../../../actions";
import AddEditForm from "../../General/AddEditForm";
import { getInscrisById } from "../../../reducers/inscrisiReducer";
import { getTipuriDeDocumente } from "../../../reducers/tipuriDeDocumenteReducer";
import DocumentTable from "../../General/DocumentTable";
import { serverUrl } from "../../../utils";
import { getToken } from "../../../reducers/authReducer";
import Payments from "../Inscrisi/Payments";
import { getReadableStatusNonEu } from "../../../utils/helpers";
import Cerere from "./Cerere";
import Scrisoare from "./Scrisoare";
import generateInputs from "./secretaraInputs";
import InscrisiInfo from "../Inscrisi/InscrisiInfo";

class InscrisiEdit extends Component {
  state = {
    inputs: [],
    isLoading: false,
    isEditing: false,
    currentTab: 0,
    isValidateDialogOpen: false,
    isRejectDialogOpen: false,
  };

  async componentDidMount() {
    const { t, nomenclatoare, match, lang } = this.props;
    this.props.setLanguage("ro");
    await this.props.fetchInscrisById(match.params.dosarId);
    await this.props.fetchNomenclatoarePublice();
    await this.props.fetchTipuriDeDocumente();
    this.props.setAppBarTitle(
      `${this.props.dosar.nume} ${this.props.dosar.prenume}`
    );
    this.setState({
      inputs: generateInputs(t, this.props, lang),
    });
  }

  componentWillUnmount() {
    this.props.setAppBarTitle(undefined);
  }

  handleSubmit = async (values) => {
    await this.props.updateInscris(values);
  };

  handleExport = async () => {
    try {
      const { dosar, jwt } = this.props;
      const res = await request
        .get(`${serverUrl}/inscrisi/export/${dosar._id}`)
        .set("Authorization", `Bearer ${jwt}`)
        .responseType("blob");
      const { headers } = res;
      const contentDisposition = headers["content-disposition"];
      const blob = res.body;
      FileSaver.saveAs(
        blob,
        contentDisposition.substr(contentDisposition.indexOf("=") + 1)
      );
    } catch (e) {
      this.props.showSnackbar(e, "error");
    }
  };

  handleFinalizeDosar = (event) => {
    this.props.finalizeDosar(this.props.dosar, event.target.checked);
  };

  renderTabContent = () => {
    const { inputs, currentTab, isEditing } = this.state;
    const { dosar, t, tipuriDoc } = this.props;
    const isViewing = dosar.status === "finalizat";
    switch (currentTab) {
      case 0:
        return (
          <AddEditForm
            name="inscrisi"
            inputs={inputs}
            isViewing={isViewing}
            handleNext={this.handleNext}
            handleSubmit={this.handleSubmit}
            saveButtonLabel={t.save}
            validationSchema={yup.object().shape({})}
            initialValues={
              isEditing
                ? {
                    ...dosar,
                  }
                : {
                    ...dosar,
                  }
            }
          />
        );
      case 1:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente || []}
            tipuriDocumente={tipuriDoc}
            name="inscrisi"
            type="edit"
          />
        );
      case 2:
        return <Payments dosar={dosar} />;
      case 3:
        return <Cerere showAnyway dosar={dosar} />;
      case 4:
        return <Scrisoare dosar={dosar} />;
      default:
        return "Unknown Tab";
    }
  };

  handleChangeTab = (e, tab) => {
    this.setState({
      currentTab: tab,
    });
  };

  handleBackClick = () => {
    this.props.history.push("/inscrisi-noneu");
  };

  handleOpenDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      this.setState({
        isRejectDialogOpen: true,
      });
    } else {
      this.setState({
        isValidateDialogOpen: true,
      });
    }
  };

  closeDialog = (dialogType) => () => {
    if (dialogType === "reject") {
      this.setState({
        isRejectDialogOpen: false,
      });
    } else {
      this.setState({
        isValidateDialogOpen: false,
      });
    }
  };

  handleReject = async (values) => {
    const { dosar, match } = this.props;
    await this.props.handleReject(dosar, values);
    await this.props.fetchInscrisById(match.params.dosarId);
    this.closeDialog("reject")();
  };

  handleValidate = async () => {
    const { dosar, match } = this.props;
    await this.props.handleValidate(dosar);
    await this.props.fetchInscrisById(match.params.dosarId);
    this.closeDialog("validate")();
  };

  render() {
    const { currentTab, isRejectDialogOpen, isValidateDialogOpen } = this.state;
    const { dosar, role } = this.props;
    // if (!dosar.email) {
    //   return <CircularProgress />;
    // }
    return (
      <div>
        <div className="lvd-spacer20" />
        {!this.props.stayAfterEdit && (
          <div className="lvd-control-buttons">
            <Button
              onClick={this.handleBackClick}
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
            >
              <ArrowBackIos />
              Inapoi
            </Button>
          </div>
        )}
        <InscrisiInfo type="non-eu" dosar={dosar} role={role} />
        {/* {dosar.status === "semnat" && (
          <Button
            onClick={this.handleOpenDialog("reject")}
            variant="contained"
            color="secondary"
          >
            Respinge
          </Button>
        )} */}
        <div className="lvd-spacer20" />
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            className="text-white bg-secondaryText"
            value={currentTab}
            onChange={this.handleChangeTab}
          >
            <Tab label="Fisa" />
            <Tab label="Documente" />
            <Tab label="Plata" />
            <Tab label="Cerere" />
            <Tab label="Scrisoare & Cazare" />
          </Tabs>
        </AppBar>
        <Paper className="mt-4 lvd-container-form">
          {(dosar.status === "plataValidata" ||
            dosar.status === "finalizat") && (
            <div className="lvd-validate-reject-buttons">
              <FormControlLabel
                control={
                  <Switch
                    onChange={this.handleFinalizeDosar}
                    checked={dosar.status === "finalizat"}
                    // value={dosar.status === "finalizat"}
                    style={{ marginRight: "16px" }}
                    variant="contained"
                    color="primary"
                  />
                }
                label="Finalizeaza"
              >
                Finalizat
              </FormControlLabel>
            </div>
          )}
          {/* {dosar.status === "trimisCatreValidare" && (
            <div className="lvd-validate-reject-buttons">
              <Button
                onClick={this.handleOpenDialog("validate")}
                style={{ marginRight: "16px" }}
                variant="contained"
                color="primary"
              >
                Valideaza
              </Button>
              <Button
                onClick={this.handleOpenDialog("reject")}
                variant="contained"
                color="secondary"
              >
                Respinge
              </Button>
            </div>
          )} */}
          {this.renderTabContent(dosar)}
        </Paper>
        <Dialog
          open={isValidateDialogOpen}
          fullWidth
          maxWidth="xs"
          onClose={this.closeDialog("validate")}
        >
          <DialogContent>
            Esti sigur ca vrei sa validezi acest dosar?
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.closeDialog("validate")}
            >
              Renunta
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleValidate}
            >
              Valideaza
            </Button>
          </DialogActions>
        </Dialog>
        <Formik
          onSubmit={this.handleReject}
          initialValues={{ motiv: "" }}
          validationSchema={yup.object().shape({
            motiv: yup.string().required("Camp obligatoriu"),
          })}
        >
          {({
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            handleSubmit,
          }) => (
            <Dialog
              open={isRejectDialogOpen}
              onClose={this.closeDialog("reject")}
              fullWidth
              maxWidth="xs"
            >
              <DialogContent>
                <Form>
                  <TextField
                    label="Motiv respingere"
                    fullWidth
                    name="motiv"
                    error={!!errors.motiv && !!touched.motiv}
                    FormHelperTextProps={{ error: true }}
                    helperText={
                      !!errors.motiv && !!touched.motiv && errors.motiv
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.motivRespingere}
                  />
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="default"
                  onClick={this.closeDialog("reject")}
                >
                  Renunta
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Respinge
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.i18nState.lang,
  t: state.i18nState.translations.ro,
  jwt: getToken(state),
  nomenclatoare: state.nomenclatoare,
  dosar: getInscrisById(state),
  tipuriDeDocumente: getTipuriDeDocumente(state),
});

const mapDispatchToProps = {
  fetchNomenclatoarePublice,
  fetchInscrisById,
  fetchTipuriDeDocumente,
  updateInscris,
  setLanguage,
  handleReject,
  handleValidate,
  showSnackbar,
  finalizeDosar,
  setAppBarTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(InscrisiEdit);
