import React, { Component } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {
  Stepper,
  Step,
  StepLabel,
  Paper,
  withMobileDialog,
  Button,
  MobileStepper,
} from "@material-ui/core";
import AddEditForm from "../General/AddEditForm";
import {
  fetchNomenclatoare,
  fetchTipuriDeDocumente,
  fetchConducatori,
  addDoctorand,
} from "../../actions";
import { getConducatori } from "../../reducers/conducatoriReducer";
import { getTipuriDeDocumente } from "../../reducers/tipuriDeDocumenteReducer";
import EmailSelect from "../General/EmailSelect";
import DocumentTable from "../General/DocumentTable";
import { getDoctorandById } from "../../reducers/doctoranziReducer";

const steps = ["Adauga/Asociaza cont", "Completeaza dosar", "Adauga documente"];

class DoctoranziAdd extends Component {
  static propTypes = {
    fetchNomenclatoare: PropTypes.func.isRequired,
    fetchTipuriDeDocumente: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    fetchConducatori: PropTypes.func.isRequired,
    addDoctorand: PropTypes.func.isRequired,
  };

  state = {
    inputs: [],
    activeStep: 0,
    user: {},
  };

  // #region input props
  static getDerivedStateFromProps = (props, state) => ({
    inputs: [
      {
        name: "email",
        label: "Email",
        type: "text",
        disabled: true,
      },
      { name: "nrMatricol", label: "Numar matricol", type: "text" },
      {
        name: "nume",
        label: "Nume",
        type: "text",
        required: true,
      },
      { name: "numeCasatorie", label: "Nume Casatorie", type: "text" },
      { name: "initialaTatalui", label: "Initiala Tatalui", type: "text" },
      {
        name: "prenume",
        label: "Prenume",
        type: "text",
        required: true,
      },
      { name: "cnp", label: "CNP", type: "text" },
      { name: "dataNasterii", label: "Data nasterii", type: "datepicker" },
      { name: "loculNasterii", label: "Locul nasterii", type: "text" },
      { name: "anInmatriculare", label: "An Inmatriculare", type: "text" },
      {
        name: "cetatenie",
        label: "Cetatenie",
        type: "chips",
        options: props.nomenclatoare.cetatenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      { name: "telefon", label: "Telefon", type: "text" },
      {
        name: "specializari",
        label: "Specializari",
        type: "chips",
        options: props.nomenclatoare.specializari.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "domeniuDoctorat",
        label: "Domeniu Doctorat",
        type: "select",
        options: props.nomenclatoare.domenii.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "conducatorDoctorat",
        label: "Conducator Doctorat",
        type: "select",
        options: props.conducatoriDoctorat.map((n) => ({
          value: n._id,
          label: `${n.nume} ${n.prenume}`,
        })),
      },
      {
        name: "conducatorInitial",
        label: "Conducator Initial",
        type: "select",
        options: props.conducatoriDoctorat.map((n) => ({
          value: n._id,
          label: `${n.nume} ${n.prenume}`,
        })),
      },
      {
        name: "conducatorCotutela",
        label: "Conducator in cotutela",
        type: "creatableSelect",
        options: props.conducatoriDoctorat.map((n) => ({
          value: n._id,
          label: `${n.nume} ${n.prenume}`,
        })),
      },
      {
        name: "transferRetrasDeces",
        label: "Transfer/Retras/Deces",
        type: "text",
      },
      {
        name: "formaDeInvatamantInitiala",
        label: "Forma de Invatamant Initiala",
        type: "select",
        options: props.nomenclatoare.formeDeInvatamant.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "formaDeInvatamantCurenta",
        label: "Forma de Invatamant Curenta",
        type: "select",
        options: props.nomenclatoare.formeDeInvatamant.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "situatiaScolara",
        label: "Situatia Scolara",
        type: "select",
        options: props.nomenclatoare.situatiiScolare.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "decizieExmatriculare",
        label: "OM/Decizie exmatriculare",
        type: "text",
      },
      {
        name: "limbaStrainaAdmitere",
        label: "Limba Straina Admitere",
        type: "select",
        options: props.nomenclatoare.limbiStraine.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "calificativAdmitere",
        label: "Calificativ Admitere Limba Straina",
        type: "select",
        options: props.nomenclatoare.calificative.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "comisieDeIndrumare",
        label: "Comisie De Indrumare",
        type: "text",
      },
      { name: "disciplinaDeBaza", label: "Disciplina de baza", type: "text" },
      {
        name: "disciplinaOptionala1",
        label: "Disciplina Optionala 1",
        type: "select",
        options: props.nomenclatoare.discipline.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "disciplinaOptionala2",
        label: "Disciplina Optionala 2",
        type: "select",
        options: props.nomenclatoare.discipline.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "disciplinaOptionala3",
        label: "Disciplina Optionala 3",
        type: "select",
        options: props.nomenclatoare.discipline.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "denumireProiectCercetare",
        label: "Denumirea proiectului de cercetare doctorala",
        type: "text",
      },
      {
        name: "disciplinaElectiva1",
        label: "Disciplina Electiva 1",
        type: "text",
      },
      {
        name: "disciplinaElectiva2",
        label: "Disciplina Electiva 2",
        type: "text",
      },
      {
        name: "raportDeCercetare1",
        label: "Raport de cercetare 1",
        type: "text",
      },
      {
        name: "raportDeCercetare2",
        label: "Raport de cercetare 2",
        type: "text",
      },
      {
        name: "raportDeCercetare3",
        label: "Raport de cercetare 3",
        type: "text",
      },
      {
        name: "credite",
        label: "credite",
        type: "children",
        children: [
          { name: "db1Credite", label: "DB Credite", type: "number" },
          { name: "do1Credite", label: "DO1 Credite", type: "number" },
          { name: "do2Credite", label: "DO2 Credite", type: "number" },
          { name: "do3Credite", label: "DO3 Credite", type: "number" },
          { name: "de1Credite", label: "DE1 Credite", type: "number" },
          { name: "de2Credite", label: "DE2 Credite", type: "number" },
          { name: "de3Credite", label: "DE3 Credite", type: "number" },
          { name: "rc1Credite", label: "RC1 Credite", type: "number" },
          { name: "rc2Credite", label: "RC2 Credite", type: "number" },
          { name: "rc3Credite", label: "RC3 Credite", type: "number" },
          { name: "tezaCredite", label: "Teza Credite", type: "number" },
          { name: "totalCredite", label: "Total credite", type: "number" },
        ],
      },
      {
        name: "rezultate",
        label: "rezultate",
        type: "children",
        children: [
          { name: "db1Rezultate", label: "DB Rezultat", type: "number" },
          { name: "do1Rezultate", label: "DO1 Rezultat", type: "number" },
          { name: "do2Rezultate", label: "DO2 Rezultat", type: "number" },
          { name: "do3Rezultate", label: "DO3 Rezultat", type: "number" },
          { name: "de1Rezultate", label: "DE1 Rezultat", type: "number" },
          { name: "de2Rezultate", label: "DE2 Rezultat", type: "number" },
          { name: "de3Rezultate", label: "DE3 Rezultat", type: "number" },
          { name: "rc1Rezultate", label: "RC1 Rezultat", type: "number" },
          { name: "rc2Rezultate", label: "RC2 Rezultat", type: "number" },
          { name: "rc3Rezultate", label: "RC3 Rezultat", type: "number" },
          { name: "tezaRezultate", label: "Teza Rezultat", type: "number" },
          { name: "totalRezultate", label: "Total Rezultat", type: "number" },
        ],
      },
      {
        name: "medii",
        label: "Medii",
        type: "children",
        children: [
          {
            name: "mediaAnilor",
            label: "Media anilor de studiu",
            type: "number",
          },
          { name: "notaLicenta", label: "Nota licenta", type: "number" },
          {
            name: "mediaAnilorMasterat",
            label: "Media anilor de studiu masterat",
            type: "number",
          },
          { name: "notaMasterat", label: "Nota masterat", type: "number" },
          { name: "notaDoctorat", label: "Nota admitere", type: "number" },
        ],
      },
      {
        name: "situatiaTaxelorPlatiteTotal",
        label: "Situatia taxelor - Suma totala",
        type: "number",
      },
      {
        name: "situatiaTaxelorPlatite",
        type: "situatiaTaxelorPlatiteArray",
      },
      {
        name: "dePlataTaxaCrediteRestanteTotal",
        label: "De Plata Taxa Credite Restante Total",
        type: "number",
      },
      {
        name: "dePlataTaxaCrediteRestante",
        type: "dePlataTaxaCrediteRestanteArray",
      },
      { name: "plataCPLSauCPV", label: "De plata CPL sau CPV", type: "text" },
      {
        name: "comisiaDeSustinere",
        label: "Comisia de sustinere",
        type: "text",
      },
      {
        name: "dataSustinereTezaInDept",
        label: "Data sustinere teza in dept.",
        type: "datepicker",
      },
      {
        name: "dataSustinerePublica",
        label: "Data sustinere publica",
        type: "datepicker",
      },
      {
        name: "calificativObtinutDupaSustinereaPublica",
        label: "Calificativ obtinut dupa sustinerea publica",
        type: "select",
        options: props.nomenclatoare.calificative.map((n) => ({
          value: n._id,
          label: n.nume,
        })),
      },
      {
        name: "linkGoogleScholar",
        label: "Link profil Google Scholar",
        type: "text",
      },
    ],
    tipuriDocumente: props.tipuriDeDocumente.doctoranzi,
  });

  // #endregion input props
  componentDidMount() {
    this.props.fetchNomenclatoare();
    this.props.fetchTipuriDeDocumente();
  }

  handleSaveUser = (user) => {
    this.setState({ user });
    this.handleNext();
  };

  handleAddDosar = async (values) => {
    await this.props.addDoctorand(values);
    this.handleNext();
  };

  getStepContent = (step) => {
    const { inputs, tipuriDocumente, user } = this.state;
    const { dosar } = this.props;
    switch (step) {
      case 0:
        return <EmailSelect handleNext={this.handleSaveUser} />;
      case 1:
        return (
          <AddEditForm
            name="doctoranzi"
            isViewing={false}
            inputs={inputs}
            handleNext={this.handleNext}
            handleSubmit={this.handleAddDosar}
            validationSchema={yup.object().shape({
              email: yup.string().required("Camp obligatoriu"),
              // nrMatricol: yup.number().required('Camp obligatoriu'),
              nume: yup.string().required("Camp obligatoriu"),
              prenume: yup.string().required("Camp obligatoriu"),
              // numeCasatorie: yup.string().required('Camp obligatoriu'),
              // initialaTatalui: yup.string().required('Camp obligatoriu'),
              // cnp: yup.string().required('Camp obligatoriu'),
              // anInmatriculare: yup.number().required('Camp obligatoriu'),
              // cetatenie: yup.string().required('Camp obligatoriu'),
              // loculNasterii: yup.string().required('Camp obligatoriu'),
              // dataNasterii: yup
              //   .date()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) => (originalValue === null ? undefined : value)),
              // telefon: yup.string().required('Camp obligatoriu'),
              // specializari: yup.string().required('Camp obligatoriu'),
              // conducatorDoctorat: yup.string().required('Camp obligatoriu'),
              // transferRetrasDeces: yup.string().required('Camp obligatoriu'),
              // formaDeInvatamant: yup.string().required('Camp obligatoriu'),
              // situatiaScolara: yup.string().required('Camp obligatoriu'),
              // decizieExmatriculare: yup.string().required('Camp obligatoriu'),
              // limbaStrainaAdmitere: yup.string().required('Camp obligatoriu'),
              // calificativAdmitere: yup.string().required('Camp obligatoriu'),
              // comisieDeIndrumare: yup.string().required('Camp obligatoriu'),
              // disciplinaOptionala1: yup.string().required('Camp obligatoriu'),
              // disciplinaOptionala2: yup.string().required('Camp obligatoriu'),
              // disciplinaOptionala3: yup.string().required('Camp obligatoriu'),
              // disciplinaElectiva1: yup.string().required('Camp obligatoriu'),
              // disciplinaElectiva2: yup.string().required('Camp obligatoriu'),
              // denumireProiectCercetare: yup.string().required('Camp obligatoriu'),
              // situatiaTaxelorPlatite: yup.string().required('Camp obligatoriu'),
              // plataCPLSauCPV: yup.string().required('Camp obligatoriu'),
              // dePlataTaxaCrediteRestante: yup.string().required('Camp obligatoriu'),
              // dataSustinereTezaInDept: yup
              //   .date()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) => (originalValue === null ? undefined : value)),
              // dataSustinerePublica: yup
              //   .date()
              //   .required('Camp obligatoriu')
              //   .transform((value, originalValue) => (originalValue === null ? undefined : value)),
              // conducatorInitial: yup.string().required('Camp obligatoriu'),
              // conducatorCotutela: yup.string().required('Camp obligatoriu'),
              // raportDeCercetare1: yup.string().required('Camp obligatoriu'),
              // raportDeCercetare2: yup.string().required('Camp obligatoriu'),
              // raportDeCercetare3: yup.string().required('Camp obligatoriu'),
              // do1: yup.number().required('Camp obligatoriu'),
              // do2: yup.number().required('Camp obligatoriu'),
              // do3: yup.number().required('Camp obligatoriu'),
              // de1: yup.number().required('Camp obligatoriu'),
              // de2: yup.number().required('Camp obligatoriu'),
              // de3: yup.number().required('Camp obligatoriu'),
              // rc1: yup.number().required('Camp obligatoriu'),
              // rc2: yup.number().required('Camp obligatoriu'),
              // rc3: yup.number().required('Camp obligatoriu'),
              // teza: yup.number().required('Camp obligatoriu'),
              // total: yup.number().required('Camp obligatoriu'),
              // mediaAnilor: yup.number().required('Camp obligatoriu'),
              // notaLicenta: yup.number().required('Camp obligatoriu'),
              // mediaAnilorMasterat: yup.number().required('Camp obligatoriu'),
              // notaMasterat: yup.number().required('Camp obligatoriu'),
              // notaDoctorat: yup.number().required('Camp obligatoriu'),
              // linkGoogleScholar: yup.string(),
            })}
            initialValues={{
              email: user.email || "",
              nrMatricol: "",
              nume: "",
              prenume: "",
              numeCasatorie: "",
              cnp: "",
              anInmatriculare: "",
              initialaTatalui: "",
              loculNasterii: "",
              dataNasterii: null,
              situatiaTaxelorPlatiteSumaTotala: "",
              situatiaTaxelorPlatite: [
                {
                  detaliiPlata: "",
                  suma: "",
                },
              ],
              dePlataTaxaCrediteRestanteTotal: "",
              dePlataTaxaCrediteRestante: [
                {
                  detaliiPlata: "",
                  suma: "",
                },
              ],
              cetatenie: [],
              telefon: "",
              specializari: [],
              conducatorDoctorat: {},
              transferRetrasDeces: "",
              formaDeInvatamantInitiala: {},
              formaDeInvatamantCurenta: {},
              situatiaScolara: {},
              decizieExmatriculare: "",
              limbaStrainaAdmitere: {},
              calificativAdmitere: {},
              comisieDeIndrumare: "",
              disciplinaOptionala1: {},
              disciplinaOptionala2: {},
              disciplinaOptionala3: {},
              disciplinaElectiva1: "",
              disciplinaElectiva2: "",
              denumireProiectCercetare: "",
              plataCPLSauCPV: "",
              conducatorInitial: {},
              conducatorCotutela: {},
              raportDeCercetare1: "",
              raportDeCercetare2: "",
              raportDeCercetare3: "",
              dataSustinereTezaInDept: null,
              dataSustinerePublica: null,
              do1: "",
              do2: "",
              do3: "",
              de1: "",
              de2: "",
              de3: "",
              rc1: "",
              rc2: "",
              rc3: "",
              teza: "",
              total: "",
              mediaAnilor: "",
              notaLicenta: "",
              mediaAnilorMasterat: "",
              notaMasterat: "",
              notaDoctorat: "",
              linkGoogleScholar: user.linkGoogleScholar || "",
              userId: user._id,
            }}
          />
        );
      case 2:
        return (
          <DocumentTable
            dosarId={dosar._id}
            documente={dosar.documente}
            tipuriDocumente={tipuriDocumente}
            name="doctoranzi"
            type="edit"
          />
        );
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    this.setState(({ activeStep }) => ({
      activeStep: activeStep + 1,
    }));
  };

  handleBackClick = () => {
    this.props.history.push("/doctoranzi");
  };

  handleNewClick = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { activeStep } = this.state;
    const { fullScreen } = this.props;
    return (
      <>
        {fullScreen ? (
          <MobileStepper
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            className="lvd-mobile-stepper"
            // className={classes.mobileStepper}
          />
        ) : (
          <Stepper
            activeStep={activeStep}
            style={{ marginTop: "20px" }}
            className="lvd-stepper"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Paper className="lvd-container-form">
          <div>{this.getStepContent(activeStep)}</div>
        </Paper>
        {activeStep === steps.length - 1 && (
          <div className="lvd-control-buttons">
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-green-text"
              onClick={this.handleBackClick}
            >
              <ArrowBackIos />
              Inapoi la lista
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="lvd-button-padded-icon"
              onClick={this.handleNewClick}
            >
              <AddIcon />
              Dosar nou
            </Button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  nomenclatoare: state.nomenclatoare,
  conducatoriDoctorat: getConducatori(state),
  tipuriDeDocumente: getTipuriDeDocumente(state),
  dosar: getDoctorandById(state),
});

export default withMobileDialog()(
  connect(mapStateToProps, {
    fetchNomenclatoare,
    fetchTipuriDeDocumente,
    fetchConducatori,
    addDoctorand,
  })(DoctoranziAdd)
);
