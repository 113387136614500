export default {
  en: {
    changeRegistrationOptions: "Change registration options",
    registerFormOne: `I'm registering for a`,
    registerFor: (x) => {
      switch (x) {
        case "licenta":
          return "bachelor's degree";
        case "master":
          return "master's degree";
        default:
          return "";
      }
    },
    university: "University",
    domainSpecialization: "Domain/Specialization",
    studyLanguage: "Language of study",
    aplr: "Romanian preparatory year",
    tipInscriere: "Registration type",
    engineering: "Engineering",
    registerFormRoCandidateType: "I am a romanian candidate.",
    registerFormNonEuCandidateType: "I am a non-eu candidate.",
    bachelor: "Bachelor's degree",
    master: "Master's degree",
    firstName: "First Name",
    previousSurnames: "Previous surnames",
    previousEducation: "Previous education",
    previousEducationExplanation:
      "You have to fill in the data from your study diplomas. For Master's and PhD degrees, in addition to the details of your high school diploma, you must fill in the details of your Bachelor's or Master's degree.",
    studiesAppliedRomania: "Studies applied for in Romania",
    studiesAppliedRomaniaCaptionText:
      "Choose carefully  your desired level of study, language of teaching, faculty and specialization",
    lastName: "Last Name",
    emailAddress: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password",
    identificationData: "Identification data",
    identificationDataExplanation:
      "You must fill in the data from the official identity documents and please, choose the country from the dropdown menu",
    familyName: "Family name",
    familyNameExplanation: "From the birth certificate",
    fatherInitial: `Father's initial`,
    countryOfBirth: "Country of birth",
    placeOfBirth: "Place of birth",
    marriageName: "Marriage name (if it applies)",
    maritalStatus: "Marital status",
    married: "Married",
    passportIssueDate: "Issued at",
    passportIssuedBy: "Country issued by",
    profession: "Profession",
    workPlace: "Work place",
    passportNumber: "Passport number",
    studyYear: "Study Year",
    study: "Select the study level",
    studyOptions: "Lorem ipsum",
    studyOptionSpecialty: "Domain",
    openStudyOptionsModal: "Open study options",
    studyOptionHighschoolUniversity: "High School/University",
    studyOptionLanguage: "Language",
    studyAPLR: "Romanian language preparatory year",
    studyHelperText: "Level | University | Domain | Language",
    notMarried: "Not married",
    dateOfBirth: "Date of birth",
    nationality: "Nationality",
    citizenshipPresent: "Actual citizenship",
    citizenshipPast: "Previous citizenship",
    birthplace: "Birth place address (City, department, country)",
    residenceCountry: "Permanent residence country",
    residencePlace: "Permanent residence place",
    fathersName: "Father's given name",
    mothersName: "Mother's given name",
    street: "Street",
    cnp: "Numerical identification code",
    phoneNumber: "Phone Number",
    register: "Register",
    alreadyHaveAccountQuestion: "Already have an account?",
    signIn: "Sign In",
    bloc: "Building",
    number: "Number",
    email: "Email",
    invalidEmail: "Invalid email address",
    requiredField: "This field is required",
    shortPassword: "Password is too short",
    passwordsNoMatch: "Passwords don't match",
    postalCode: "Postal code",
    entrance: "Entrance",
    floor: "Floor",
    apartment: "Apartment",
    identityCardSerial: "Identity card serial",
    identityCardNo: "Identity card no.",
    identityCardIssueDate: "Identity card issue date",
    passportSerial: "Passport serial no.",
    passportValabilityYears: "Valability",
    secondaryStudies: "Secondary studies",
    branchSpecialty: "Branch/Specialty",
    highSchoolUniversity: "High School/University",
    languageOfInstruction: "Language of instruction",
    aplrCheckbox: "I apply for the Romanian language course",
    aplrUniversity: "University for the Romanian language course",
    correspondenceAddress:
      "Complete address where the letter of acceptance ca be sent",
    language: "Language",
    writing: "Writing",
    speaking: "Speaking",
    institution: "Institution",
    excellent: "Excellent",
    good: "Good",
    poor: "Poor",
    certificatePreviousEducation: "Certificate",
    countryPreviousEducation: "Country",
    nameOfSchoolPreviousEducation: "Name of school",
    yearOfAdmissionPreviousEducation: "Year of admission",
    yearOfGraduationPreviousEducation: "Year of graduation",
    dataOnGraduatedLicenceStudies: "Data on graduated licence studies",
    nameOfHighEducationInstitution: "Name of the high education institution",
    proficiencyInOtherLanguages: "Proficiency in other languages",
    country: "Country",
    city: "City",
    faculty: "Faculty",
    field: "Field",
    specialization: "Specialization",
    graduationYear: "Graduation year",
    durationSem: "Duration (no. of semesters)",
    obtainedTitle: "Obtained title",
    diplomaCertificateNo: "Diploma / Certificate no.",
    matricolSheetCertificateNo: "Matricol sheet / Certificate no.",
    dataOnUniversityMasterStudies: "Data on graduated master studies",
    dataOnUniversityDoctoralStudies:
      "Data on the universitary doctoral studies",
    registrationYear: "Year of registration",
    formOfDoctoralStudy: "Form (select and specify if applicable)",
    withScholarship: "With scholarship",
    withoutScholarship: "Without scholarship",
    withRegularTax: "With regular tax",
    phdAdvisor: "Ph.D Advisor",
    doctoralField: "Doctoral field",
    studyQuestion:
      "Have you ever undergone doctoral studies funded by the national budget at UTCB ?",
    period: "Period",
    studyQuestionOtherUniversity:
      "Have you ever undergone doctoral studies funded by the national budget at a different university in Romania?",
    yes: "Yes",
    no: "No",
    foreignLanguage: "Foreign language",
    proficiencyCertificate: "Proficiency Certificate",
    accomodationInUniversitaryResidence:
      "Do you require accommodation in UTCB universitary residences?",
    declaration:
      "I, the undersigned, declare that I understand the meaning of all the data requested in this sheet. I have to notify the Secretariat of the Doctoral School of any changes in the declared data in the shortest possible time.",
    save: "Save",
    createAccount: "Create account",
    createAccountDescription: "Create an account to manage registration",
    completeRegistrationPaper: "Complete registration paper",
    completeRegistrationPaperDescription: "Fill in registration paper",
    loadDocuments: "Load documents",
    loadDocumentsDescription: "Load the scans of the requested documents",
    signDosar: "Sign it",
    signDosarDescription: "This represents your approval",
    registrationPayment: "Registration payment",
    registrationPaymentDescription: "Pay online",
    signatureExplain:
      "Use your mouse (if you're using a PC) or your finger (tablet/phone) in order to sign in the box below",
    done: "Save and send",
    clear: "Clear",
    signOut: "Sign out",
    finalizeRegistration: "Resolution",
    finalizeRegistrationDescription: "Dossier eligibility",
    acceptanceLetter: "Approval letter",
    acceptanceLetterDescription: "Wait for your official approval",
    back: "Back",
    finalize: "Finish",
    forward: "Forward",
    registerMe: "Registration",
    alreadyHaveAccount: "Authentication",
    candidateType: "I am a",
    roCandidate: "romanian candidate",
    euCandidate: "EU candidate",
    translationAndInterpretation: "Translation and interpretation",
    registeringFor: "I am registering for",
    nonEuCandidate: "non-eu candidate",
    startRegistration: "Start registration",
    recoverPassword: "Recover password",
    access: "Access",
    pendingValidation: "Wait for validation",
    pendingValidationDescription:
      "Your information is going to be validated by a human",
    others: "Others",
    options: "Options",
    name2: "Name",
    template: "Template",
    file: "File",
    type: "Type",
    size: "Size",
    uploadDate: "Upload date",
    validationDate: "Validation Date",
    sendToValidation: "Send to validation",
    awaitingValidation:
      "Now your file is sent to the admissions committee. As soon as possible, it will be checked and you will be notified by email when it receives validation or if additions or clarifications are needed.",
    validationReceived: "Your file has been validated.",
    validationReceivedMinister:
      "You are at the stage where your documents have been validated by UTCB and will be sent to the Ministry of Education to receive the Letter of Acceptance. The waiting time to get the answer from the Ministry of Education is 1-2 months. Return to the platform to see the answer and the Letter of Acceptance received from the Ministry of Education.",
    validationReceived2:
      "You will be notified by email when your acceptance letter is uploaded.",
    acceptTerms: "I agree to the terms and conditions",
    verifyEmailPart1: "Please check your e-mail address",
    verifyEmailPart2:
      "and click the validation link in the message in order to continue the registration process.",
    acceptGDPR: "I agree to UTCB's Privacy Statement",
    requestResendVerificationToken: "Resend validation link",
    expiredLinkTitle: "Expired validation link",
    expiredLinkDescription:
      "Your validation link is valid for 48 hours. Please request a new one by logging in.",
    backToLogin: "Back to login",
    downloadUpload: "Upload/Download",
    download: "Download",
    upload: "Upload",
    sendEmail: "Email the instructions",
    payFee: "Pay",
    payByCreditCard: "Pay online",
    tabLabelCard: "Card",
    tabLabelOP: "OP",
    tabLabelCash: "Cash",
    finalValidationPending: "Your request is pending official validation",
    finalValidationPendingDescription: ``,
    finalValidationRejected: "Your request has been rejected",
    finalValidationRejectedDescription: ``,
    finalValidationAccepted: "Your request has been accepted",
    finalValidationAcceptedDescription: `The enrollment within the academic education in Romania is conditioned by obtaining a Letter of
    acceptance for studies from the Romanian Ministry of Education and Research. For the issuance of
    this letter, you must send us a complete admission file containing the documents requested.
    If you want more infos contact us here: admission-info@utcb.ro`,
    finalValidationDownloadLetter: "Download your approval letter",
    finalValidationDownloadAccomodation: "Download your accomodation proof",
    fees: "Fees",
    cycle: "Cycle",
    feePerYear: "FEE (EURO)/year",
  },
  ro: {
    domainSpecialization: "Domeniu/Specializare",
    studyLanguage: "Limba de studiu",
    aplr: "An pregatitor de limba romana",
    changeRegistrationOptions: "Schimba optiuni inscriere",
    registerFormOne: `Ma inscriu la`,
    registerFor: (x) => {
      switch (x) {
        case "licenta":
          return "licenta";
        case "master":
          return "master";
        default:
          return "";
      }
    },
    university: "Universitatea",
    tipInscriere: "Tip inscriere",
    engineering: "Inginerie",
    registerFormRoCandidateType: "Sunt candidat roman/UE.",
    registerFormNonEuCandidateType: "Sunt candidat non-UE.",
    bachelor: "Licenta",
    master: "Master",
    firstName: "Prenume",
    previousSurnames: "Nume purtate anterior",
    lastName: "Nume",
    emailAddress: "Email",
    password: "Parola",
    confirmPassword: "Confirmare parola",
    fathersName: "Prenume tata",
    mothersName: "Prenume mama",
    identificationData: "Date de identificare",
    identificationDataExplanation:
      "Trebuie să completezi datele din documentele de identitate oficiale și te rugăm să alegi țara din meniul derulant",
    familyName: "Nume de familie",
    familyNameExplanation: "Din certificatul de nastere",
    fatherInitial: "Initiala Tatalui",
    countryOfBirth: "Tara nasterii",
    placeOfBirth: "Localitatea nasterii",
    marriageName: "Nume dobandit (daca este cazul)",
    passportIssueDate: "Eliberat la",
    passportIssuedBy: "Eliberat de tara",
    maritalStatus: "Stare civila",
    married: "Casatorit",
    notMarried: "Necasatorit",
    dateOfBirth: "Data nasterii",
    nationality: "Nationalitate",
    citizenshipPresent: "Cetatenie",
    citizenshipPast: "Cetatenie anterioara",
    birthplace: "Locul Nasterii (localitate, judet, tara)",
    residenceCountry: "Tara domiciliu permanent actual",
    residencePlace: "Localitate domiciliu permanent actual",
    street: "Strada",
    cnp: "Cod numeric personal",
    idCardSeries: "Serie si Numar Buletin",
    studyYear: "An de studiu",
    studyOptionSpecialty: "Domeniu",
    studyOptionHighschoolUniversity: "Liceu/Universitate",
    studyOptionLanguage: "Limba",
    openStudyOptionsModal: "Deschide optiuni de studiu",
    study: "Alege nivelul de studii",
    studyOptions: "Lorem ipsum",
    studyAPLR: "An pregatitor de limba romana",
    studyHelperText: "Nivel | Universitate | Specializare | Limba",
    phoneNumber: "Numar de telefon",
    register: "Inregistrare",
    alreadyHaveAccountQuestion: "Ai deja cont?",
    signIn: "Logheaza-te",
    bloc: "Bloc",
    number: "Numar",
    email: "Email",
    previousEducation: "Educatie anterioara",
    previousEducationExplanation:
      "Trebuie să completezi datele din diplomele de studiu. Pentru master și doctorat, pe lângă datele diplomei de studiu de la liceu, trebuie trecute informațiile aferente diplomelor de licență sau respectiv master.",
    invalidEmail: "Adresa de email invalida",
    requiredField: "Acest camp este obligatoriu",
    shortPassword: "Parola este prea scurta",
    passwordsNoMatch: "Parolele nu se potrivesc",
    postalCode: "Cod Postal",
    entrance: "Scara",
    floor: "Etaj",
    apartment: "Apartament",
    identityCardSerial: "Serie carte de identitate",
    identityCardNo: "Nr. Carte de Identitate",
    identityCardIssueDate: "Data eliberarii cartii de identitate",
    passportSerial: "Serie Pasaport",
    passportValabilityYears: "Valabilitate",
    passportNumber: "Numar pasaport",
    profession: "Profesia",
    workPlace: "Locul de munca",
    secondaryStudies: "Studii secundare",
    branchSpecialty: "Domeniu/Specialitate",
    highSchoolUniversity: "Liceu/Universitate",
    languageOfInstruction: "Limba de predare",
    aplrCheckbox: "Curs pregatitor de limba Romana",
    aplrUniversity: "Universitate curs pregatitor de limba Romana",
    proficiencyInOtherLanguages: "Limbi straine cunoscute",
    correspondenceAddress:
      "Adresa completa unde poate fi trimisa scrisoarea de acceptare",
    language: "Limba",
    writing: "Scris",
    speaking: "Vorbit",
    excellent: "Excelent",
    good: "Bun",
    poor: "Slab",
    institution: "Instutitia emitenta a certificatului",
    certificatePreviousEducation: "Certificat",
    countryPreviousEducation: "Tara",
    nameOfSchoolPreviousEducation: "Nume scoala",
    yearOfAdmissionPreviousEducation: "An admitere",
    yearOfGraduationPreviousEducation: "An absolvire",
    dataOnGraduatedLicenceStudies: "Date privind studiile de licenta absolvite",
    nameOfHighEducationInstitution:
      "Denumirea instituţiei de învăţământ superior",
    country: "Tara",
    city: "Localitatea",
    faculty: "Facultatea",
    field: "Domeniul",
    specialization: "Specializarea",
    graduationYear: "Anul absolvirii",
    durationSem: "Durata (sem)",
    obtainedTitle: "Titlu obtinut",
    diplomaCertificateNo: "Diploma / Adeverinta nr.",
    matricolSheetCertificateNo: "Foaia matricola/Adeverinta nr.",
    dataOnUniversityMasterStudies: "Date privind studiile de master absolvite",
    dataOnUniversityDoctoralStudies:
      "Date privind studiile universitare de doctorat",
    registrationYear: "Anul inscrierii",
    formOfDoctoralStudy: "Forma (selectati si specificati daca este cazul)",
    withScholarship: "Cu bursa",
    withoutScholarship: "Fara bursa",
    withRegularTax: "Cu taxa",
    phdAdvisor: "Conducator Doctorat",
    doctoralField: "Domeniul de doctorat",
    studyQuestion:
      "Ati mai urmat studii universitare de doctorat finantate de la buget in UTCB?",
    period: "Perioada",
    studyQuestionOtherUniversity:
      "Ati mai urmat studii universitare de doctorat finantate de la buget in alta universitate din Romania?",
    yes: "Da",
    no: "Nu",
    foreignLanguage: "Limba straina cunoscuta",
    proficiencyCertificate: "Certificat de atestare competente lingvistice",
    accomodationInUniversitaryResidence: "Solicitati cazare in caminele UTCB?",
    declaration:
      "Subsemnatul, declar ca am înţeles semnificaţia tuturor datelor solicitate în această fişă. Mă oblig să anunţ secretariatului Scolii Doctorale orice modificare în ceea ce priveşte datele declarate, în cel mai scurt timp.",
    save: "Salveaza",
    createAccount: "Creezi cont",
    createAccountDescription: "Contul te ajuta la inscrierea online",
    completeRegistrationPaper: "Completezi fisa",
    completeRegistrationPaperDescription: "Completezi fisa de inscriere",
    studiesAppliedRomania: "Studii pentru care aplici in Romania",
    studiesAppliedRomaniaCaptionText:
      "Alege cu atenție nivelul de studii dorit, limba de predate, facultatea și specializarea",
    loadDocuments: "Incarci documente",
    loadDocumentsDescription: "Incarci scan-uri cu documentele solicitate",
    signFisa: "Semnezi fisa",
    signDosar: "Semnezi cererea",
    signDosarDescription: "Semnezi digital",
    registrationPayment: "Platesti inscrierea",
    registrationPaymentDescription: "Achita online taxa inscriere",
    signatureExplain:
      "Foloseste mouse-ul (daca esti pe PC) sau degetul (tableta/telefon) pentru a-ti face semnatura in casuta de mai jos",
    done: "Salveaza si trimite",
    clear: "Reseteaza",
    signOut: "Iesi din cont",
    finalizeRegistration: "Validare",
    finalizeRegistrationDescription:
      "Astepti validarea dosarului tau de inscriere",
    acceptanceLetter: "Scrisoare de acceptare",
    acceptanceLetterDescription: "Astepti scrisoare de acceptare",
    back: "Inapoi",
    forward: "Inainte",
    finalize: "Finalizare",
    registerMe: "Ma inscriu la:",
    alreadyHaveAccount: "Intra in cont",
    candidateType: "Sunt candidat",
    roCandidate: "roman/ue",
    euCandidate: "UE",
    nonEuCandidate: "din afara UE",
    translationAndInterpretation: "Traducere si interpretare",
    registeringFor: "Ma inscriu la",
    startRegistration: "Incepe inscrierea",
    recoverPassword: "Recupereaza parola",
    access: "Acces",
    pendingValidation: "Validare dosar",
    pendingValidationDescription: "Astepti validarea dosarului de inscriere",
    others: "Altele",
    options: "Optiuni",
    name2: "Denumire",
    template: "Template",
    file: "Fisier",
    type: "Tip",
    size: "Dimensiune",
    uploadDate: "Data incarcare",
    validationDate: "Data validare",
    sendToValidation: "Trimite catre validare",
    awaitingValidation: `Acum dosarul tău este trimis către comisia de admitere.
    În cel mai scurt timp, va fi verificat și vei fi anunțat pe e-mail când primește validarea sau dacă este nevoie de completări sau clarificări.`,
    validationReceived: "Dosarul tau a fost validat.",
    validationReceivedMinister:
      "Te afli în pasul în care documentele ți-au fost validate de UTCB și vor fi trimise către Ministerul Educației pentru a primi Scrisoarea de Acceptare. Timpul de așteptare pentru obținerea răspunsului de la Ministerul Educației este de 1-2 luni. Revin în aplicație să vezi răspunsul și Scrisoarea de Acceptare primită de la Ministerul Educație.",
    validationReceived2:
      "Revino in aplicatie sa vezi lista de candidati eligibili.",
    acceptTerms: "Sunt de acord cu termenii și condițile de utilizare",
    verifyEmailPart1: "Te rugam sa verifici adresa ta de email",
    verifyEmailPart2:
      "si sa dai click pe link-ul de validare din mesaj pentru a putea continua procesul de inscriere.",
    acceptGDPR:
      "Sunt de acord cu Politica de Confidențialitate a datelor cu caracter personal",
    requestResendVerificationToken: "Retrimite link validare",
    expiredLinkTitle: "Expired validation link",
    expiredLinkDescription:
      "Your validation link is valid for 48 hours. Please request a new one by logging in.",
    backToLogin: "Inapoi la login",
    downloadUpload: "Adauga/Descarca",
    download: "Descarca",
    upload: "Adauga",
    sendEmail: "Trimite instructiunile pe mail",
    payFee: "Plateste",
    payByCreditCard: "Plateste online",
    tabLabelCard: "Card",
    tabLabelOP: "OP",
    tabLabelCash: "Casierie",
    finalValidationPending: "Cererea ta este in curs de analiza",
    finalValidationPendingDescription: ``,
    finalValidationRejected: "Cererea a fost respinsa",
    finalValidationRejectedDescription: ``,
    finalValidationAccepted: "Cererea ta a fost acceptata",
    finalValidationAcceptedDescription: `The enrollment within the academic education in Romania is conditioned by obtaining a Letter of
    acceptance for studies from the Romanian Ministry of Education and Research. For the issuance of
    this letter, you must send us a complete admission file containing the documents requested.
    If you want more infos contact us here: admission-info@utcb.ro`,
    finalValidationDownloadLetter: "Descarca scrisoarea de acceptare",
    finalValidationDownloadAccomodation: "Descarca dovada cazare",
    fees: "Taxe",
    feePerYear: "Taxa (EURO)/an",
    cycle: "Ciclul",
  },
  fr: {
    domainSpecialization: "Domaine/Spécialisation",
    studyLanguage: "Langue d’étude",
    aplr: "Année préparatoire de roumain",
    changeRegistrationOptions: "Modifier les options d’inscription ",
    registerFormOne: `Formulaire d’inscription`,
    registerFor: (x) => {
      switch (x) {
        case "licence":
          return "licence";
        case "master":
          return "master";
        default:
          return "Retour";
      }
    },
    university: "Université",
    tipInscriere: "Type inscription",
    engineering: " Ingénierie",
    registerFormRoCandidateType: "Candidat roumain/U.E.",
    registerFormNonEuCandidateType: "Sunt candidat non-UE.",
    bachelor: "Licence",
    master: "Master",
    firstName: "Prénoms",
    previousSurnames: "Nom de jeunes filles ",
    lastName: "Nom",
    emailAddress: "Email",
    password: "Mot de passe",
    confirmPassword: "Confirmation mot de passe",
    fathersName: "Prénom du père",
    mothersName: " Prénom de la mère",
    identificationData: "Données d’identification",
    identificationDataExplanation:
      "Vous devez insérer les données des documents d’identité officiels et choisir le pays dans le menu déroulant",
    familyName: "Nom de famille",
    familyNameExplanation: "Nom de naissance ",
    fatherInitial: "Première lettre du prénom du père",
    countryOfBirth: "Pays de naissance ",
    placeOfBirth: "Localité de naissance ",
    marriageName: "Nom après mariage (si c’est le cas)",
    passportIssueDate: "Date de délivrance du passeport ",
    passportIssuedBy: "Organisme de délivrance du passeport ",
    maritalStatus: "État civil",
    married: "Marié",
    notMarried: "Non marié",
    dateOfBirth: "Date de naissance ",
    nationality: "Nationalité",
    citizenshipPresent: "Citoyenneté actuelle",
    citizenshipPast: " Citoyenneté antérieure",
    birthplace: "Lieu de naissance (localité, département, pays)",
    residenceCountry: "Pays de résidence actuel ",
    residencePlace: "Localité de résidence actuelle ",
    street: "Rue",
    cnp: "Numéro d’identification personnelle",
    idCardSeries: "Série et Numéro de carte d’identité ",
    studyYear: "Année d’étude visée",
    studyOptionSpecialty: "Domaine d’étude",
    studyOptionHighschoolUniversity: "Lycée/Université",
    studyOptionLanguage: "Langue",
    openStudyOptionsModal: "Options d’étude",
    study: "Choix du niveau d’étude ",
    studyOptions: "Lorem ipsum",
    studyAPLR: "Année préparatoire de roumain ",
    studyHelperText: "Niveau | Université | Spécialisation | Langue",
    phoneNumber: "Numéro de téléphone ",
    register: "Inscription",
    alreadyHaveAccountQuestion: "Vous avez déjà un compte?",
    signIn: "Inscription",
    bloc: "Immeuble",
    number: "Numéro",
    email: "Email",
    previousEducation: "Études antérieures",
    previousEducationExplanation:
      "Vous devez compléter les données de vos diplômes d'études. Pour le Master et le Doctorat, en plus des données du diplôme d'études secondaires, vous devez remplir les données du diplôme de Bachelor ou de Master",
    invalidEmail: "Email invalide",
    requiredField: "Champ obligatoire",
    shortPassword: "Mot de passe trop court",
    passwordsNoMatch: "Mot de passe incorrect ",
    postalCode: "Code postal",
    entrance: "Escalier",
    floor: "Étage",
    apartment: "Appartement",
    identityCardSerial: "Série carte d’identité",
    identityCardNo: "No. carte d’identité ",
    identityCardIssueDate: "Date de délivrance de la carte d’identité",
    passportSerial: "Série du Passeport",
    passportValabilityYears: "Validité du passeport",
    passportNumber: "Numéro passeport",
    profession: "Profession",
    workPlace: "Lieu de travail",
    secondaryStudies: "Études secondaires ",
    branchSpecialty: "Domaine/Spécialité",
    highSchoolUniversity: "Lycée/Université",
    languageOfInstruction: "Langue d’enseignement ",
    aplrCheckbox: "Cours préparatoire de roumain ",
    aplrUniversity: "Université : Cours préparatoire de roumain ",
    proficiencyInOtherLanguages: "Compétences en Langues étrangères",
    correspondenceAddress:
      "L’adresse complète où on peut envoyer la lettre d’acceptation ",
    language: "Langue",
    writing: "Écrit",
    speaking: "Parlé",
    excellent: "Excellent",
    good: "Bon",
    poor: "Faible",
    institution: "Institution de délivrance du certificat",
    certificatePreviousEducation: "Certificat",
    countryPreviousEducation: "Pays",
    nameOfSchoolPreviousEducation: "Nom de l’établissement scolaire précédent",
    yearOfAdmissionPreviousEducation:
      "Année d’entrée dans l’établissement scolaire précédent ",
    yearOfGraduationPreviousEducation: "Année de fin d’études  précédentes",
    dataOnGraduatedLicenceStudies: "Données sur les études de licence",
    nameOfHighEducationInstitution:
      "Nom établissement d’enseignement supérieur",
    country: "Pays",
    city: "Localité",
    faculty: "Faculté",
    field: "Domaine",
    specialization: "Spécialisation",
    graduationYear: "Année de fin d’études ",
    durationSem: "Durée (sem)",
    obtainedTitle: "Diplôme obtenu",
    diplomaCertificateNo: "Diplôme / No. du Certificat de diplôme",
    matricolSheetCertificateNo: "Relevé de notes/ No. du Relevé de notes ",
    dataOnUniversityMasterStudies: "Données sur les études de Master ",
    dataOnUniversityDoctoralStudies: "Données sur les études de Doctorat ",
    registrationYear: "Année d’inscription ",
    formOfDoctoralStudy: "Type (choisir et spécifier si c’est le cas)",
    withScholarship: "Avec bourse",
    withoutScholarship: "Sans bourse",
    withRegularTax: "Avec taxe",
    phdAdvisor: "Directeur de thèse de Doctorat",
    doctoralField: "Domaine de doctorat",
    studyQuestion:
      "Avez-vous suivi d’autres études universitaires de doctorat financées du budget à l’UTCB?",
    period: "Période",
    studyQuestionOtherUniversity:
      " Avez-vous suivi d’autres études universitaires de doctorat financées du budget dans une autre université roumaine?",
    yes: "Oui",
    no: "Non",
    foreignLanguage: "Langue étrangère",
    proficiencyCertificate: "Attestation de compétences linguistiques ",
    accomodationInUniversitaryResidence:
      "Demande de logement étudiant à l’UTCB?",
    declaration:
      "Moi, soussigné, je déclare avoir compris la signification de toutes les données sollicitées. J’ai l’obligation de déclarer auprès du Secrétariat de l’École doctorale toute modification concernant les données déclarées, dans les plus brefs délais.",
    save: "Sauvegarder",
    createAccount: "Créer un compte",
    createAccountDescription:
      "Créer un compte  vous aide à vous inscrire en ligne ",
    completeRegistrationPaper: "Compléter la fiche ",
    completeRegistrationPaperDescription: "Completezi fisa de inscriere",
    studiesAppliedRomania: "Études pour lesquelles vous postuler en Roumanie",
    studiesAppliedRomaniaCaptionText:
      "Choisissez le niveau d'études, la langue d'enseignement, la faculté et la spécialisation qui vous voulez suivre.",
    loadDocuments: "Transférer les documents ",
    loadDocumentsDescription: "Transférer les documents sollicités scannés",
    signFisa: "Signer la fiche",
    signDosar: " Signer la demande ",
    signDosarDescription: "Signature numérique ",
    registrationPayment: "Paiement d’inscription ",
    registrationPaymentDescription: "Payer en ligne les frais d’inscription ",
    signatureExplain:
      "Utilisez la soris sur l’ordinateur ou le doigt sur tablette ou smartphone pour signer dans la zone ci-dessous",
    done: "Enregistrez et envoyez ",
    clear: "Réinitialiser",
    signOut: "Sortir du compte",
    finalizeRegistration: "Terminer l’inscription",
    finalizeRegistrationDescription:
      "Information sur l’éligibilité et signature du contrat de scolarité ",
    acceptanceLetter: "Lettre d’acceptation",
    acceptanceLetterDescription: "Lettre d’acceptation en cours d’envoi",
    back: "Retour",
    forward: "Suivant",
    finalize: "Finaliser",
    registerMe: "Inscription",
    alreadyHaveAccount: "Authentification",
    candidateType: "Je suis candidat",
    roCandidate: "Roumain/U.E.",
    euCandidate: "UE",
    nonEuCandidate: "candidat hors UE",
    translationAndInterpretation: "Traduction et Interprétation ",
    registeringFor: "Je m’inscris à",
    startRegistration: "Début de l’inscription inscrierea",
    recoverPassword: "Récupération du mot de passe",
    access: "Accès",
    pendingValidation: "Validation en attente ",
    pendingValidationDescription:
      "Vos données seront validées par un opérateur ",
    others: "Autres",
    options: "Options",
    name2: "Nom",
    template: "Template",
    file: "Fichier",
    type: "Type",
    size: "Taille",
    uploadDate: "Date de transfert",
    validationDate: "Date de validation",
    sendToValidation: "Envoyez pour validation ",
    awaitingValidation:
      "Votre dossier est maintenant transmis à la commission d'admission. Dans les plus brefs délais, il sera vérifié et vous serez averti par e-mail s'il est validé ou si des ajouts ou des informations supplémentaires sont nécessaires.",
    validationReceived: "Votre dossier a été validé.",
    validationReceivedMinister:
      "Vous êtes à l'étape où vos documents ont été validés  UTCB et seront envoyés au ministère de l'Éducation pour recevoir la lettre d'acceptation. Le délai d'attente pour obtenir la réponse du ministère de l'Éducation est de 1 à 2 mois. Retournez à la demande pour voir la réponse et la lettre d'acceptation reçue du ministère de l'Éducation.",
    validationReceived2:
      "Vous serez informé par e-mail lorsque votre lettre d'acceptation sera téléchargée.",
    acceptTerms:
      "Je suis d’accord avec les termes et les conditions d’utilisation ",
    verifyEmailPart1: "Vérifier votre adresse email",
    verifyEmailPart2:
      "clicker sur le lien de validation du message pour continuer le processus d’inscription.",
    acceptGDPR:
      "Sunt de acord cu Politica de Confidențialitate a datelor cu caracter personal",
    requestResendVerificationToken: "Envoyez un nouveau lien de validation ",
    expiredLinkTitle: "Lien de validation expiré ",
    expiredLinkDescription:
      "Votre lien de validation est valide pendant 48 h. Connectez-vous pour en obtenir un autre",
    backToLogin: "Retour à la page de connexion",
    downloadUpload: "Télécharger/Transférer",
    download: "Télécharger",
    upload: " Transférer",
    sendEmail: "Envoyez des instructions par email",
    payFee: "Frais",
    payByCreditCard: "Paiement en ligne",
    tabLabelCard: "Carte bancaire",
    tabLabelOP: "OP",
    tabLabelCash: "Caisse",
    finalValidationPending: "Demande en cours d’analyse ",
    finalValidationPendingDescription: ``,
    finalValidationRejected: "Demande réjetée",
    finalValidationRejectedDescription: ``,
    finalValidationAccepted: " Demande acceptée ",
    finalValidationAcceptedDescription: `L’inscription dans le système éducatif roumain nécessite l’obtention d’une lettre d’acceptation pour les études de la part du Ministère de l’Éducation et de la Recherche de Roumanie. Pour la délivrance de cette lettre vous devez nous envoyer un fichier d’admission complet comprenant les documents sollicités.
   Pour des informations supplémentaires, contactez-nous à l’adresse suivante : admission-info@utcb.ro`,
    finalValidationDownloadLetter:
      "Téléchargement de la lettre de validation finale",
    finalValidationDownloadAccomodation:
      "Téléchargez votre justificatif d'hébergement",
    fees: "Frais",
    feePerYear: "Frais (EURO)/an",
    cycle: "Cycle",
  },
};
