import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import "jQuery-QueryBuilder/dist/js/query-builder";
import "jQuery-QueryBuilder/dist/css/query-builder.default.css";
import { Button } from "@material-ui/core";
import "../../css/queryBuilder.css";

class QueryBuilder extends Component {
  static propTypes = {
    filterConfig: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    setFilterNo: PropTypes.func.isRequired,
    saveQuery: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.$el = $(this.el);
    // const { utils } = this.$el.queryBuilder.constructor;
    this.$el.queryBuilder({
      //   plugins: ["not-group"],
      filters: this.props.filterConfig,
      // || [
      //   {
      //     id: 'nume',
      //     label: 'String - Select',
      //     type: 'string',
      //     input: 'select',
      //     values: ['a', 'b', 'c'],
      //   },
      //   {
      //     id: 'nume1',
      //     label: 'String - Text',
      //     type: 'string',
      //     input: 'text',
      //   },
      //   {
      //     id: 'nume2',
      //     label: 'String - TextArea',
      //     type: 'string',
      //     input: 'textarea',
      //   },
      //   {
      //     id: 'nume3',
      //     label: 'Integer - Number',
      //     type: 'integer',
      //     input: 'number',
      //   },
      //   {
      //     id: 'nume6',
      //     label: 'Double - Number',
      //     type: 'double',
      //     input: 'number',
      //   },
      //   {
      //     id: 'nume4',
      //     label: 'Integer - Radio',
      //     type: 'integer',
      //     input: 'radio',
      //     values: [1, 2, 3],
      //   },
      //   {
      //     id: 'nume5',
      //     label: 'Integer - Checkbox',
      //     type: 'integer',
      //     input: 'checkbox',
      //     values: [3, 4, 5],
      //   },
      // ],

      icons: {
        add_group: "fas fa-plus-square",
        add_rule: "fas fa-plus-circle",
        remove_group: "fas fa-minus-square",
        remove_rule: "fas fa-minus-circle",
        error: "fas fa-exclamation-triangle",
      },
      lang_code: "ro",
      lang: {
        add_rule: "Adaugă regulă",
        add_group: "Adaugă grup",
        delete_rule: "Şterge",
        delete_group: "Şterge",
        conditions: {
          AND: "ŞI",
          OR: "SAU",
        },
        operators: {
          equal: "egal",
          not_equal: "diferit",
          in: "în",
          not_in: "nu în",
          less: "mai puţin",
          less_or_equal: "mai puţin sau egal",
          greater: "mai mare",
          greater_or_equal: "mai mare sau egal",
          begins_with: "începe cu",
          not_begins_with: "nu începe cu",
          contains: "conţine",
          not_contains: "nu conţine",
          ends_with: "se termină cu",
          not_ends_with: "nu se termină cu",
          is_empty: "este gol",
          is_not_empty: "nu este gol",
          is_null: "e nul",
          is_not_null: "nu e nul",
        },
      },
      operators: [
        {
          type: "equal",
          nb_inputs: 1,
          apply_to: ["string", "number", "boolean"],
        },
        {
          type: "not_equal",
          nb_inputs: 1,
          apply_to: ["string", "number", "boolean"],
        },
        // in: 'în',
        // not_in: 'nu în',
        { type: "less", nb_inputs: 1, apply_to: ["number"] },
        { type: "less_or_equal", nb_inputs: 1, apply_to: ["number"] },
        { type: "greater", nb_inputs: 1, apply_to: ["number"] },
        {
          type: "greater_or_equal",
          nb_inputs: 1,
          apply_to: ["number"],
        },
        { type: "begins_with", nb_inputs: 1, apply_to: ["string"] },
        { type: "not_begins_with", nb_inputs: 1, apply_to: ["string"] },
        { type: "contains", nb_inputs: 1, apply_to: ["string"] },
        { type: "not_contains", nb_inputs: 1, apply_to: ["string"] },
        { type: "ends_with", nb_inputs: 1, apply_to: ["string"] },
        { type: "not_ends_with", nb_inputs: 1, apply_to: ["string"] },
        { type: "is_empty", nb_inputs: 0, apply_to: ["string"] },
        { type: "is_not_empty", nb_inputs: 0, apply_to: ["string"] },
        // is_null: 'e nul',
        // is_not_null: 'nu e nul',
      ],
    });
    if (this.props.query) {
      this.$el.queryBuilder("setRulesFromMongo", this.props.query);
      const filter = this.$el.queryBuilder("getRules");
      this.props.setFilterNo(filter ? filter.rules.length : 0);
    }
  }

  // shouldComponentUpdate() {
  //   return false;
  // }
  componentWillUnmount() {
    this.$el.queryBuilder("destroy");
  }

  handleGetMongoQueryClick = () => {
    const mongoQuery = this.$el.queryBuilder("getMongo");
    const filter = this.$el.queryBuilder("getRules");
    this.props.setFilterNo(filter ? filter.rules.length : 0);
    this.props.saveQuery(mongoQuery);
    if (filter !== null) this.props.handleClose();
  };

  handleReset = () => {
    this.$el.queryBuilder("reset");
    this.props.setFilterNo(0);
    this.props.saveQuery(undefined);
    this.props.handleClose("reset");
  };

  render() {
    return (
      <div>
        <div
          className="lvd-query-builder"
          ref={(el) => {
            this.el = el;
          }}
        />
        <Button
          onClick={this.handleReset}
          style={{ position: "absolute", bottom: "22px", left: "18px" }}
          color="primary"
        >
          Reseteaza
        </Button>
        <Button
          style={{ position: "absolute", bottom: "22px", right: "18px" }}
          type="submit"
          variant="contained"
          onClick={this.handleGetMongoQueryClick}
          color="primary"
        >
          Filtreaza
        </Button>
      </div>
    );
  }
}

QueryBuilder.propTypes = {};

export default QueryBuilder;
