export default [
  { judet: "Alba", localitate: "Aiud" },
  { judet: "Alba", localitate: "Abrud" },
  { judet: "Alba", localitate: "Baia de Aries" },
  { judet: "Alba", localitate: "Alba Iulia" },
  { judet: "Alba", localitate: "Blaj" },
  { judet: "Alba", localitate: "Cugir" },
  { judet: "Alba", localitate: "Teius" },
  { judet: "Alba", localitate: "Sebes" },
  { judet: "Alba", localitate: "Campeni" },
  { judet: "Alba", localitate: "Zlatna" },
  { judet: "Alba", localitate: "Ocna Mures" },
  { judet: "Alba", localitate: "Salciua" },
  { judet: "Alba", localitate: "Sugag" },
  { judet: "Alba", localitate: "Lunca Muresului" },
  { judet: "Alba", localitate: "Jidvei" },
  { judet: "Alba", localitate: "Unirea" },
  { judet: "Alba", localitate: "Sasciori" },
  { judet: "Alba", localitate: "Valea Lunga" },
  { judet: "Alba", localitate: "Vintu de Jos" },
  { judet: "Alba", localitate: "Craciunelu de Jos" },
  { judet: "Alba", localitate: "Bucerdea Granoasa" },
  { judet: "Alba", localitate: "Albac" },
  { judet: "Alba", localitate: "Almasu Mare" },
  { judet: "Alba", localitate: "Arieseni" },
  { judet: "Alba", localitate: "Avram Iancu" },
  { judet: "Alba", localitate: "Berghin" },
  { judet: "Alba", localitate: "Bistra" },
  { judet: "Alba", localitate: "Blandiana" },
  { judet: "Alba", localitate: "Bucium" },
  { judet: "Alba", localitate: "Calnic" },
  { judet: "Alba", localitate: "Cenade" },
  { judet: "Alba", localitate: "Cergau" },
  { judet: "Alba", localitate: "Ceru-Bacainti" },
  { judet: "Alba", localitate: "Cetatea de Balta" },
  { judet: "Alba", localitate: "Ciugud" },
  { judet: "Alba", localitate: "Ciuruleasa" },
  { judet: "Alba", localitate: "Cricau" },
  { judet: "Alba", localitate: "Cut" },
  { judet: "Alba", localitate: "Daia Romana" },
  { judet: "Alba", localitate: "Dostat" },
  { judet: "Alba", localitate: "Farau" },
  { judet: "Alba", localitate: "Galda de Jos" },
  { judet: "Alba", localitate: "Garda de Sus" },
  { judet: "Alba", localitate: "Garbova" },
  { judet: "Alba", localitate: "Hoparta" },
  { judet: "Alba", localitate: "Horea" },
  { judet: "Alba", localitate: "Ighiu" },
  { judet: "Alba", localitate: "Intregalde" },
  { judet: "Alba", localitate: "Livezile" },
  { judet: "Alba", localitate: "Lupsa" },
  { judet: "Alba", localitate: "Lopadea Noua" },
  { judet: "Alba", localitate: "Metes" },
  { judet: "Alba", localitate: "Mihalt" },
  { judet: "Alba", localitate: "Miraslau" },
  { judet: "Alba", localitate: "Mogos" },
  { judet: "Alba", localitate: "Noslac" },
  { judet: "Alba", localitate: "Ocolis" },
  { judet: "Alba", localitate: "Ohaba" },
  { judet: "Alba", localitate: "Pianu" },
  { judet: "Alba", localitate: "Poiana Vadului" },
  { judet: "Alba", localitate: "Ponor" },
  { judet: "Alba", localitate: "Posaga" },
  { judet: "Alba", localitate: "Radesti" },
  { judet: "Alba", localitate: "Ramet" },
  { judet: "Alba", localitate: "Rimetea" },
  { judet: "Alba", localitate: "Rosia de Secas" },
  { judet: "Alba", localitate: "Rosia Montana" },
  { judet: "Alba", localitate: "Salistea" },
  { judet: "Alba", localitate: "Sancel" },
  { judet: "Alba", localitate: "Santimbru" },
  { judet: "Alba", localitate: "Scarisoara" },
  { judet: "Alba", localitate: "Sohodol" },
  { judet: "Alba", localitate: "Stremt" },
  { judet: "Alba", localitate: "Sibot" },
  { judet: "Alba", localitate: "Sona" },
  { judet: "Alba", localitate: "Spring" },
  { judet: "Alba", localitate: "Vadu Motilor" },
  { judet: "Alba", localitate: "Vidra" },
  { judet: "Alba", localitate: "Petresti" },
  { judet: "Alba", localitate: "Oiejdea" },
  { judet: "Alba", localitate: "Barabant" },
  { judet: "Alba", localitate: "Micesti" },
  { judet: "Alba", localitate: "Oarda" },
  { judet: "Alba", localitate: "Paclisa" },
  { judet: "Alba", localitate: "Abrud-Sat" },
  { judet: "Alba", localitate: "Gura Cornei" },
  { judet: "Alba", localitate: "Soharu" },
  { judet: "Alba", localitate: "Aiudul de Sus" },
  { judet: "Alba", localitate: "Ciumbrud" },
  { judet: "Alba", localitate: "Gambas" },
  { judet: "Alba", localitate: "Garbova de Jos" },
  { judet: "Alba", localitate: "Garbova de Sus" },
  { judet: "Alba", localitate: "Garbovita" },
  { judet: "Alba", localitate: "Magina" },
  { judet: "Alba", localitate: "Pagida" },
  { judet: "Alba", localitate: "Sancrai" },
  { judet: "Alba", localitate: "Tifra" },
  { judet: "Alba", localitate: "Brazesti" },
  { judet: "Alba", localitate: "Cioara de Sus" },
  { judet: "Alba", localitate: "Muncelu" },
  { judet: "Alba", localitate: "Sartas" },
  { judet: "Alba", localitate: "Simulesti" },
  { judet: "Alba", localitate: "Deleni-Obarsie" },
  { judet: "Alba", localitate: "Flitesti" },
  { judet: "Alba", localitate: "Izvoarele (Blaj)" },
  { judet: "Alba", localitate: "Manarade" },
  { judet: "Alba", localitate: "Petrisat" },
  { judet: "Alba", localitate: "Spatac" },
  { judet: "Alba", localitate: "Tiur" },
  { judet: "Alba", localitate: "Veza" },
  { judet: "Alba", localitate: "Boncesti" },
  { judet: "Alba", localitate: "Borlesti" },
  { judet: "Alba", localitate: "Botesti (Campeni)" },
  { judet: "Alba", localitate: "Certege" },
  { judet: "Alba", localitate: "Coasta Vascului" },
  { judet: "Alba", localitate: "Dandut" },
  { judet: "Alba", localitate: "Dealu Bistrii" },
  { judet: "Alba", localitate: "Dealu Capsei" },
  { judet: "Alba", localitate: "Dric" },
  { judet: "Alba", localitate: "Fata Abrudului" },
  { judet: "Alba", localitate: "Floresti (Campeni)" },
  { judet: "Alba", localitate: "Furduiesti (Campeni)" },
  { judet: "Alba", localitate: "Mihoesti" },
  { judet: "Alba", localitate: "Motorasti" },
  { judet: "Alba", localitate: "Peste Valea Bistrii" },
  { judet: "Alba", localitate: "Poduri" },
  { judet: "Alba", localitate: "Sorlita" },
  { judet: "Alba", localitate: "Tomusesti" },
  { judet: "Alba", localitate: "Valea Bistrii" },
  { judet: "Alba", localitate: "Valea Caselor" },
  { judet: "Alba", localitate: "Varsi" },
  { judet: "Alba", localitate: "Bocsitura" },
  { judet: "Alba", localitate: "Bucuru" },
  { judet: "Alba", localitate: "Calene" },
  { judet: "Alba", localitate: "Feteni" },
  { judet: "Alba", localitate: "Goasele" },
  { judet: "Alba", localitate: "Mugesti" },
  { judet: "Alba", localitate: "Vinerea" },
  { judet: "Alba", localitate: "Cisteiu de Mures" },
  { judet: "Alba", localitate: "Micoslaca" },
  { judet: "Alba", localitate: "Razboieni-Cetate" },
  { judet: "Alba", localitate: "Uioara de Jos" },
  { judet: "Alba", localitate: "Uioara de Sus" },
  { judet: "Alba", localitate: "Lancram" },
  { judet: "Alba", localitate: "Rahau" },
  { judet: "Alba", localitate: "Beldiu" },
  { judet: "Alba", localitate: "Capud" },
  { judet: "Alba", localitate: "Coslariu Nou" },
  { judet: "Alba", localitate: "Petelca" },
  { judet: "Alba", localitate: "Botesti (Zlatna)" },
  { judet: "Alba", localitate: "Budeni" },
  { judet: "Alba", localitate: "Dealu Roatei" },
  { judet: "Alba", localitate: "Dobrot" },
  { judet: "Alba", localitate: "Dumbrava (Zlatna)" },
  { judet: "Alba", localitate: "Fenes" },
  { judet: "Alba", localitate: "Galati" },
  { judet: "Alba", localitate: "Izvoru Ampoiului" },
  { judet: "Alba", localitate: "Patrangeni" },
  { judet: "Alba", localitate: "Parau Gruiului" },
  { judet: "Alba", localitate: "Pirita" },
  { judet: "Alba", localitate: "Podu lui Paul" },
  { judet: "Alba", localitate: "Runc (Zlatna)" },
  { judet: "Alba", localitate: "Rusi" },
  { judet: "Alba", localitate: "Suseni" },
  { judet: "Alba", localitate: "Trampoiele" },
  { judet: "Alba", localitate: "Valea Mica" },
  { judet: "Alba", localitate: "Valtori (Zlatna)" },
  { judet: "Alba", localitate: "Barasti" },
  { judet: "Alba", localitate: "Budaiesti" },
  { judet: "Alba", localitate: "Cionesti" },
  { judet: "Alba", localitate: "Costesti (Albac)" },
  { judet: "Alba", localitate: "Dealu Lamasoi" },
  { judet: "Alba", localitate: "Deve" },
  { judet: "Alba", localitate: "Dupa Plese" },
  { judet: "Alba", localitate: "Fata" },
  { judet: "Alba", localitate: "Plesesti" },
  { judet: "Alba", localitate: "Potionci" },
  { judet: "Alba", localitate: "Rogoz" },
  { judet: "Alba", localitate: "Rosesti" },
  { judet: "Alba", localitate: "Rusesti" },
  { judet: "Alba", localitate: "Sohodol (Albac)" },
  { judet: "Alba", localitate: "Tamboresti" },
  { judet: "Alba", localitate: "Almasu de Mijloc" },
  { judet: "Alba", localitate: "Bradet" },
  { judet: "Alba", localitate: "Cheile Cibului" },
  { judet: "Alba", localitate: "Cib" },
  { judet: "Alba", localitate: "Glod" },
  { judet: "Alba", localitate: "Hanes Mina" },
  { judet: "Alba", localitate: "Nadastia" },
  { judet: "Alba", localitate: "Avramesti (Arieseni)" },
  { judet: "Alba", localitate: "Bubesti" },
  { judet: "Alba", localitate: "Casa de Piatra" },
  { judet: "Alba", localitate: "Cobles" },
  { judet: "Alba", localitate: "Dealu Bajului" },
  { judet: "Alba", localitate: "Fata Cristesei" },
  { judet: "Alba", localitate: "Fata Lapusului" },
  { judet: "Alba", localitate: "Galbena" },
  { judet: "Alba", localitate: "Hodobana" },
  { judet: "Alba", localitate: "Izlaz" },
  { judet: "Alba", localitate: "Pantesti" },
  { judet: "Alba", localitate: "Patrahaitesti" },
  { judet: "Alba", localitate: "Poienita (Arieseni)" },
  { judet: "Alba", localitate: "Ravicesti" },
  { judet: "Alba", localitate: "Sturu" },
  { judet: "Alba", localitate: "Stei-Arieseni" },
  { judet: "Alba", localitate: "Vanvucesti" },
  { judet: "Alba", localitate: "Achimetesti" },
  { judet: "Alba", localitate: "Avramesti (Avram Iancu)" },
  { judet: "Alba", localitate: "Badai" },
  { judet: "Alba", localitate: "Boldesti" },
  { judet: "Alba", localitate: "Calugaresti" },
  { judet: "Alba", localitate: "Casoaia" },
  { judet: "Alba", localitate: "Candesti" },
  { judet: "Alba", localitate: "Carasti" },
  { judet: "Alba", localitate: "Cartulesti" },
  { judet: "Alba", localitate: "Cocesti" },
  { judet: "Alba", localitate: "Cocosesti" },
  { judet: "Alba", localitate: "Coroiesti" },
  { judet: "Alba", localitate: "Dealu Crisului" },
  { judet: "Alba", localitate: "Dolesti" },
  { judet: "Alba", localitate: "Dumacesti" },
  { judet: "Alba", localitate: "Gojeiesti" },
  { judet: "Alba", localitate: "Heleresti" },
  { judet: "Alba", localitate: "Incesti (Avram Iancu)" },
  { judet: "Alba", localitate: "Jojei" },
  { judet: "Alba", localitate: "Martesti" },
  { judet: "Alba", localitate: "Orgesti" },
  { judet: "Alba", localitate: "Patrutesti" },
  { judet: "Alba", localitate: "Plai (Avram Iancu)" },
  { judet: "Alba", localitate: "Puselesti" },
  { judet: "Alba", localitate: "Soicesti" },
  { judet: "Alba", localitate: "Stertesti" },
  { judet: "Alba", localitate: "Tarsa" },
  { judet: "Alba", localitate: "Tarsa-Plai" },
  { judet: "Alba", localitate: "Valea Maciului" },
  { judet: "Alba", localitate: "Valea Utului" },
  { judet: "Alba", localitate: "Verdesti" },
  { judet: "Alba", localitate: "Vidrisoara" },
  { judet: "Alba", localitate: "Ghirbom" },
  { judet: "Alba", localitate: "Henig" },
  { judet: "Alba", localitate: "Straja" },
  { judet: "Alba", localitate: "Aronesti" },
  { judet: "Alba", localitate: "Balesti" },
  { judet: "Alba", localitate: "Balesti-Catun" },
  { judet: "Alba", localitate: "Barlesti (Bistra)" },
  { judet: "Alba", localitate: "Cheleteni" },
  { judet: "Alba", localitate: "Ciuldesti" },
  { judet: "Alba", localitate: "Cretesti" },
  { judet: "Alba", localitate: "Dambureni" },
  { judet: "Alba", localitate: "Dealu Muntelui" },
  { judet: "Alba", localitate: "Durasti" },
  { judet: "Alba", localitate: "Ganesti" },
  { judet: "Alba", localitate: "Garde" },
  { judet: "Alba", localitate: "Hodisesti" },
  { judet: "Alba", localitate: "Hudricesti" },
  { judet: "Alba", localitate: "Lipaia" },
  { judet: "Alba", localitate: "Lunca Larga (Bistra)" },
  { judet: "Alba", localitate: "Lunca Merilor" },
  { judet: "Alba", localitate: "Mihaiesti" },
  { judet: "Alba", localitate: "Namas" },
  { judet: "Alba", localitate: "Novacesti" },
  { judet: "Alba", localitate: "Perjesti" },
  { judet: "Alba", localitate: "Poiana (Bistra)" },
  { judet: "Alba", localitate: "Poiu" },
  { judet: "Alba", localitate: "Ratitis" },
  { judet: "Alba", localitate: "Runcuri" },
  { judet: "Alba", localitate: "Salagesti" },
  { judet: "Alba", localitate: "Stefanca" },
  { judet: "Alba", localitate: "Tolacesti" },
  { judet: "Alba", localitate: "Tomnatec" },
  { judet: "Alba", localitate: "Trisoresti" },
  { judet: "Alba", localitate: "Taranesti" },
  { judet: "Alba", localitate: "Varsi-Rontu" },
  { judet: "Alba", localitate: "Varsii Mari" },
  { judet: "Alba", localitate: "Varsii Mici" },
  { judet: "Alba", localitate: "Acmariu" },
  { judet: "Alba", localitate: "Ibru" },
  { judet: "Alba", localitate: "Poieni (Blandiana)" },
  { judet: "Alba", localitate: "Racatau" },
  { judet: "Alba", localitate: "Anghelesti" },
  { judet: "Alba", localitate: "Bisericani" },
  { judet: "Alba", localitate: "Bucium-Sat" },
  { judet: "Alba", localitate: "Cerbu" },
  { judet: "Alba", localitate: "Ciuculesti" },
  { judet: "Alba", localitate: "Coleseni" },
  { judet: "Alba", localitate: "Dogaresti" },
  { judet: "Alba", localitate: "Feresti" },
  { judet: "Alba", localitate: "Floresti (Bucium)" },
  { judet: "Alba", localitate: "Gura Izbitei" },
  { judet: "Alba", localitate: "Helesti" },
  { judet: "Alba", localitate: "Izbicioara" },
  { judet: "Alba", localitate: "Izbita" },
  { judet: "Alba", localitate: "Jurcuiesti" },
  { judet: "Alba", localitate: "Lupulesti" },
  { judet: "Alba", localitate: "Magura (Bucium)" },
  { judet: "Alba", localitate: "Muntari" },
  { judet: "Alba", localitate: "Petreni" },
  { judet: "Alba", localitate: "Poiana (Bucium)" },
  { judet: "Alba", localitate: "Poieni (Bucium)" },
  { judet: "Alba", localitate: "Stalnisoara" },
  { judet: "Alba", localitate: "Valea Abruzel" },
  { judet: "Alba", localitate: "Valea Alba" },
  { judet: "Alba", localitate: "Valea Cerbului" },
  { judet: "Alba", localitate: "Valea Negrilesii" },
  { judet: "Alba", localitate: "Valea Poienii (Bucium)" },
  { judet: "Alba", localitate: "Valea Sesii (Bucium)" },
  { judet: "Alba", localitate: "Valeni (Bucium)" },
  { judet: "Alba", localitate: "Valcea" },
  { judet: "Alba", localitate: "Deal" },
  { judet: "Alba", localitate: "Capu Dealului" },
  { judet: "Alba", localitate: "Gorgan" },
  { judet: "Alba", localitate: "Cergau Mare" },
  { judet: "Alba", localitate: "Cergau Mic" },
  { judet: "Alba", localitate: "Lupu" },
  { judet: "Alba", localitate: "Bolovanesti" },
  { judet: "Alba", localitate: "Bulbuc" },
  { judet: "Alba", localitate: "Cucuta" },
  { judet: "Alba", localitate: "Curpeni" },
  { judet: "Alba", localitate: "Dumbravita" },
  { judet: "Alba", localitate: "Fantanele" },
  { judet: "Alba", localitate: "Grosi" },
  { judet: "Alba", localitate: "Valea Mare" },
  { judet: "Alba", localitate: "Viezuri" },
  { judet: "Alba", localitate: "Craciunelu de Sus" },
  { judet: "Alba", localitate: "Santamarie" },
  { judet: "Alba", localitate: "Tatarlaua" },
  { judet: "Alba", localitate: "Drambar" },
  { judet: "Alba", localitate: "Limba" },
  { judet: "Alba", localitate: "Hapria" },
  { judet: "Alba", localitate: "Seusa" },
  { judet: "Alba", localitate: "Teleac" },
  { judet: "Alba", localitate: "Bidigesti" },
  { judet: "Alba", localitate: "Bodresti" },
  { judet: "Alba", localitate: "Boglesti" },
  { judet: "Alba", localitate: "Buninginea" },
  { judet: "Alba", localitate: "Ghedulesti" },
  { judet: "Alba", localitate: "Matisesti (Ciuruleasa)" },
  { judet: "Alba", localitate: "Moraresti (Ciuruleasa)" },
  { judet: "Alba", localitate: "Vulcan" },
  { judet: "Alba", localitate: "Cornu" },
  { judet: "Alba", localitate: "Padure" },
  { judet: "Alba", localitate: "Panca" },
  { judet: "Alba", localitate: "Craiva" },
  { judet: "Alba", localitate: "Tibru" },
  { judet: "Alba", localitate: "Boz" },
  { judet: "Alba", localitate: "Dealu Dostatului" },
  { judet: "Alba", localitate: "Heria" },
  { judet: "Alba", localitate: "Medves" },
  { judet: "Alba", localitate: "Sanbenedic" },
  { judet: "Alba", localitate: "Silea" },
  { judet: "Alba", localitate: "Benic" },
  { judet: "Alba", localitate: "Cetea" },
  { judet: "Alba", localitate: "Galda de Sus" },
  { judet: "Alba", localitate: "Lupseni" },
  { judet: "Alba", localitate: "Magura (Galda de Jos)" },
  { judet: "Alba", localitate: "Mesentea" },
  { judet: "Alba", localitate: "Poiana Galdei" },
  { judet: "Alba", localitate: "Raicani" },
  { judet: "Alba", localitate: "Zagris" },
  { judet: "Alba", localitate: "Carpinis (Garbova)" },
  { judet: "Alba", localitate: "Reciu" },
  { judet: "Alba", localitate: "Biharia" },
  { judet: "Alba", localitate: "Dealu Frumos (Garda de Sus)" },
  { judet: "Alba", localitate: "Dealu Ordancusii" },
  { judet: "Alba", localitate: "Dobresti" },
  { judet: "Alba", localitate: "Garda Seaca" },
  { judet: "Alba", localitate: "Ghetari" },
  { judet: "Alba", localitate: "Hanasesti (Garda de Sus)" },
  { judet: "Alba", localitate: "Huzaresti" },
  { judet: "Alba", localitate: "Izvoarele (Garda de Sus)" },
  { judet: "Alba", localitate: "Mununa" },
  { judet: "Alba", localitate: "Ocoale" },
  { judet: "Alba", localitate: "Plai (Garda de Sus)" },
  { judet: "Alba", localitate: "Plisti" },
  { judet: "Alba", localitate: "Scoarta" },
  { judet: "Alba", localitate: "Snide" },
  { judet: "Alba", localitate: "Sucesti" },
  { judet: "Alba", localitate: "Silivas" },
  { judet: "Alba", localitate: "Spalnaca" },
  { judet: "Alba", localitate: "Turdas" },
  { judet: "Alba", localitate: "Vama Seaca" },
  { judet: "Alba", localitate: "Baba" },
  { judet: "Alba", localitate: "Butesti (Horea)" },
  { judet: "Alba", localitate: "Darlesti" },
  { judet: "Alba", localitate: "Fericet" },
  { judet: "Alba", localitate: "Giurgiut" },
  { judet: "Alba", localitate: "Mancesti" },
  { judet: "Alba", localitate: "Matisesti (Horea)" },
  { judet: "Alba", localitate: "Niculesti" },
  { judet: "Alba", localitate: "Patrusesti" },
  { judet: "Alba", localitate: "Petreasa" },
  { judet: "Alba", localitate: "Preluca" },
  { judet: "Alba", localitate: "Teiu" },
  { judet: "Alba", localitate: "Trifesti (Horea)" },
  { judet: "Alba", localitate: "Zanzesti" },
  { judet: "Alba", localitate: "Bucerdea Vinoasa" },
  { judet: "Alba", localitate: "Ighiel" },
  { judet: "Alba", localitate: "Sard" },
  { judet: "Alba", localitate: "Telna" },
  { judet: "Alba", localitate: "Dealu Geoagiului" },
  { judet: "Alba", localitate: "Ghioncani" },
  { judet: "Alba", localitate: "Iliesti" },
  { judet: "Alba", localitate: "Ivanis" },
  { judet: "Alba", localitate: "Marinesti" },
  { judet: "Alba", localitate: "Modolesti (Intregalde)" },
  { judet: "Alba", localitate: "Necrilesti" },
  { judet: "Alba", localitate: "Popesti" },
  { judet: "Alba", localitate: "Sfarcea" },
  { judet: "Alba", localitate: "Tecsesti" },
  { judet: "Alba", localitate: "Balcaciu" },
  { judet: "Alba", localitate: "Capalna de Jos" },
  { judet: "Alba", localitate: "Feisa" },
  { judet: "Alba", localitate: "Veseus" },
  { judet: "Alba", localitate: "Izvoarele (Livezile)" },
  { judet: "Alba", localitate: "Poiana Aiudului" },
  { judet: "Alba", localitate: "Valisoara" },
  { judet: "Alba", localitate: "Asinip" },
  { judet: "Alba", localitate: "Bagau" },
  { judet: "Alba", localitate: "Beta" },
  { judet: "Alba", localitate: "Cicard" },
  { judet: "Alba", localitate: "Ciuguzel" },
  { judet: "Alba", localitate: "Ocnisoara" },
  { judet: "Alba", localitate: "Odverem" },
  { judet: "Alba", localitate: "Gura Ariesului" },
  { judet: "Alba", localitate: "Bardesti" },
  { judet: "Alba", localitate: "Barzan" },
  { judet: "Alba", localitate: "Curmatura" },
  { judet: "Alba", localitate: "Dupa Deal (Lupsa)" },
  { judet: "Alba", localitate: "Geamana" },
  { judet: "Alba", localitate: "Hadarau" },
  { judet: "Alba", localitate: "Holobani" },
  { judet: "Alba", localitate: "Lazuri (Lupsa)" },
  { judet: "Alba", localitate: "Lunca (Lupsa)" },
  { judet: "Alba", localitate: "Manastire" },
  { judet: "Alba", localitate: "Margaia" },
  { judet: "Alba", localitate: "Musca" },
  { judet: "Alba", localitate: "Parau-Carbunari" },
  { judet: "Alba", localitate: "Pitiga" },
  { judet: "Alba", localitate: "Posogani" },
  { judet: "Alba", localitate: "Sasa" },
  { judet: "Alba", localitate: "Trifesti (Lupsa)" },
  { judet: "Alba", localitate: "Valea Holhorii" },
  { judet: "Alba", localitate: "Valea Lupsii" },
  { judet: "Alba", localitate: "Valea Sesii (Lupsa)" },
  { judet: "Alba", localitate: "Vai" },
  { judet: "Alba", localitate: "Vinta" },
  { judet: "Alba", localitate: "Ampoita" },
  { judet: "Alba", localitate: "Isca" },
  { judet: "Alba", localitate: "Lunca Ampoitei" },
  { judet: "Alba", localitate: "Lunca Metesului" },
  { judet: "Alba", localitate: "Padurea" },
  { judet: "Alba", localitate: "Poiana Ampoiului" },
  { judet: "Alba", localitate: "Poiana Ursului" },
  { judet: "Alba", localitate: "Presaca Ampoiului" },
  { judet: "Alba", localitate: "Remetea" },
  { judet: "Alba", localitate: "Tauti" },
  { judet: "Alba", localitate: "Valeni (Metes)" },
  { judet: "Alba", localitate: "Cistei" },
  { judet: "Alba", localitate: "Obreja" },
  { judet: "Alba", localitate: "Zaries" },
  { judet: "Alba", localitate: "Cicau" },
  { judet: "Alba", localitate: "Decea" },
  { judet: "Alba", localitate: "Lopadea Veche" },
  { judet: "Alba", localitate: "Ormenis" },
  { judet: "Alba", localitate: "Rachis" },
  { judet: "Alba", localitate: "Barbesti" },
  { judet: "Alba", localitate: "Barlesti (Mogos)" },
  { judet: "Alba", localitate: "Barlesti-Catun" },
  { judet: "Alba", localitate: "Barzogani" },
  { judet: "Alba", localitate: "Bocesti" },
  { judet: "Alba", localitate: "Bogdanesti (Mogos)" },
  { judet: "Alba", localitate: "Butesti (Mogos)" },
  { judet: "Alba", localitate: "Cojocani" },
  { judet: "Alba", localitate: "Cristesti" },
  { judet: "Alba", localitate: "Mamaligani" },
  { judet: "Alba", localitate: "Negresti" },
  { judet: "Alba", localitate: "Oncesti" },
  { judet: "Alba", localitate: "Poienile-Mogos" },
  { judet: "Alba", localitate: "Tomesti" },
  { judet: "Alba", localitate: "Valea Barnii" },
  { judet: "Alba", localitate: "Valea Barlutesti" },
  { judet: "Alba", localitate: "Valea Cocesti" },
  { judet: "Alba", localitate: "Valea Giogesti" },
  { judet: "Alba", localitate: "Valea Mlacii" },
  { judet: "Alba", localitate: "Valea Tupilor" },
  { judet: "Alba", localitate: "Captalan" },
  { judet: "Alba", localitate: "Copand" },
  { judet: "Alba", localitate: "Gabud" },
  { judet: "Alba", localitate: "Stana de Mures" },
  { judet: "Alba", localitate: "Valea Ciuciului" },
  { judet: "Alba", localitate: "Lunca Larga (Ocolis)" },
  { judet: "Alba", localitate: "Runc (Ocolis)" },
  { judet: "Alba", localitate: "Vidolm" },
  { judet: "Alba", localitate: "Colibi" },
  { judet: "Alba", localitate: "Maghierat" },
  { judet: "Alba", localitate: "Secasel" },
  { judet: "Alba", localitate: "Pianu de Jos" },
  { judet: "Alba", localitate: "Pianu de Sus" },
  { judet: "Alba", localitate: "Plaiuri" },
  { judet: "Alba", localitate: "Purcareti" },
  { judet: "Alba", localitate: "Strungari" },
  { judet: "Alba", localitate: "Costesti (Poiana Vadului)" },
  { judet: "Alba", localitate: "Duduieni" },
  { judet: "Alba", localitate: "Fagetu de Jos" },
  { judet: "Alba", localitate: "Fagetu de Sus" },
  { judet: "Alba", localitate: "Hanasesti (Poiana Vadului)" },
  { judet: "Alba", localitate: "Lupaiesti" },
  { judet: "Alba", localitate: "Morcanesti" },
  { judet: "Alba", localitate: "Pastesti" },
  { judet: "Alba", localitate: "Petelei" },
  { judet: "Alba", localitate: "Stanesti" },
  { judet: "Alba", localitate: "Dupa Deal (Ponor)" },
  { judet: "Alba", localitate: "Geogel" },
  { judet: "Alba", localitate: "Macaresti" },
  { judet: "Alba", localitate: "Vale in Jos" },
  { judet: "Alba", localitate: "Valea Bucurului" },
  { judet: "Alba", localitate: "Cortesti" },
  { judet: "Alba", localitate: "Incesti (Posaga)" },
  { judet: "Alba", localitate: "Lunca (Posaga)" },
  { judet: "Alba", localitate: "Orasti" },
  { judet: "Alba", localitate: "Posaga de Jos" },
  { judet: "Alba", localitate: "Posaga de Sus" },
  { judet: "Alba", localitate: "Sagagea" },
  { judet: "Alba", localitate: "Leorint" },
  { judet: "Alba", localitate: "Mescreac" },
  { judet: "Alba", localitate: "Soimus" },
  { judet: "Alba", localitate: "Botani" },
  { judet: "Alba", localitate: "Bradesti" },
  { judet: "Alba", localitate: "Cheia" },
  { judet: "Alba", localitate: "Cotorasti" },
  { judet: "Alba", localitate: "Floresti (Ramet)" },
  { judet: "Alba", localitate: "Olteni" },
  { judet: "Alba", localitate: "Valea Fagetului" },
  { judet: "Alba", localitate: "Valea Inzelului" },
  { judet: "Alba", localitate: "Valea Manastirii" },
  { judet: "Alba", localitate: "Valea Poienii (Ramet)" },
  { judet: "Alba", localitate: "Valea Uzei" },
  { judet: "Alba", localitate: "Vladesti" },
  { judet: "Alba", localitate: "Coltesti" },
  { judet: "Alba", localitate: "Balmosesti" },
  { judet: "Alba", localitate: "Blidesti" },
  { judet: "Alba", localitate: "Bunta" },
  { judet: "Alba", localitate: "Carpinis (Rosia Montana)" },
  { judet: "Alba", localitate: "Coasta Hentii" },
  { judet: "Alba", localitate: "Corna" },
  { judet: "Alba", localitate: "Curaturi" },
  { judet: "Alba", localitate: "Daroaia" },
  { judet: "Alba", localitate: "Garda-Barbulesti" },
  { judet: "Alba", localitate: "Gura Rosiei" },
  { judet: "Alba", localitate: "Iacobesti" },
  { judet: "Alba", localitate: "Ignatesti" },
  { judet: "Alba", localitate: "Soal" },
  { judet: "Alba", localitate: "Tarina" },
  { judet: "Alba", localitate: "Vartop" },
  { judet: "Alba", localitate: "Tau" },
  { judet: "Alba", localitate: "Ungurei" },
  { judet: "Alba", localitate: "Dealu Caselor" },
  { judet: "Alba", localitate: "Dumesti" },
  { judet: "Alba", localitate: "Salciua de Jos" },
  { judet: "Alba", localitate: "Salciua de Sus" },
  { judet: "Alba", localitate: "Sub Piatra" },
  { judet: "Alba", localitate: "Valea Larga" },
  { judet: "Alba", localitate: "Margineni" },
  { judet: "Alba", localitate: "Salistea-Deal" },
  { judet: "Alba", localitate: "Tartaria" },
  { judet: "Alba", localitate: "Capalna" },
  { judet: "Alba", localitate: "Dumbrava (Sasciori)" },
  { judet: "Alba", localitate: "Laz (Sasciori)" },
  { judet: "Alba", localitate: "Loman" },
  { judet: "Alba", localitate: "Plesi" },
  { judet: "Alba", localitate: "Rachita" },
  { judet: "Alba", localitate: "Sebesel" },
  { judet: "Alba", localitate: "Tonea" },
  { judet: "Alba", localitate: "Iclod" },
  { judet: "Alba", localitate: "Panade" },
  { judet: "Alba", localitate: "Coslariu" },
  { judet: "Alba", localitate: "Dumitra" },
  { judet: "Alba", localitate: "Galtiu" },
  { judet: "Alba", localitate: "Totoi" },
  { judet: "Alba", localitate: "Barlesti (Scarisoara)" },
  { judet: "Alba", localitate: "Botesti (Scarisoara)" },
  { judet: "Alba", localitate: "Fata-Lazesti" },
  { judet: "Alba", localitate: "Floresti (Scarisoara)" },
  { judet: "Alba", localitate: "Lazesti (Scarisoara)" },
  { judet: "Alba", localitate: "Lespezea" },
  { judet: "Alba", localitate: "Matei" },
  { judet: "Alba", localitate: "Negesti" },
  { judet: "Alba", localitate: "Preluca" },
  { judet: "Alba", localitate: "Runc (Scarisoara)" },
  { judet: "Alba", localitate: "Sfoartea" },
  { judet: "Alba", localitate: "Stiuleti" },
  { judet: "Alba", localitate: "Trancesti" },
  { judet: "Alba", localitate: "Bazesti" },
  { judet: "Alba", localitate: "Bilanesti" },
  { judet: "Alba", localitate: "Bobaresti (Sohodol)" },
  { judet: "Alba", localitate: "Bradeana" },
  { judet: "Alba", localitate: "Burzonesti" },
  { judet: "Alba", localitate: "Deoncesti" },
  { judet: "Alba", localitate: "Dilimani" },
  { judet: "Alba", localitate: "Furduiesti (Sohodol)" },
  { judet: "Alba", localitate: "Gura Sohodol" },
  { judet: "Alba", localitate: "Hoanca (Sohodol)" },
  { judet: "Alba", localitate: "Joldisesti" },
  { judet: "Alba", localitate: "Lazuri (Sohodol)" },
  { judet: "Alba", localitate: "Lehesti" },
  { judet: "Alba", localitate: "Luminesti" },
  { judet: "Alba", localitate: "Medresti" },
  { judet: "Alba", localitate: "Moraresti (Sohodol)" },
  { judet: "Alba", localitate: "Munesti" },
  { judet: "Alba", localitate: "Napaiesti" },
  { judet: "Alba", localitate: "Nelegesti" },
  { judet: "Alba", localitate: "Nicoresti" },
  { judet: "Alba", localitate: "Peles" },
  { judet: "Alba", localitate: "Poiana (Sohodol)" },
  { judet: "Alba", localitate: "Robesti" },
  { judet: "Alba", localitate: "Sebisesti" },
  { judet: "Alba", localitate: "Sicoiesti" },
  { judet: "Alba", localitate: "Surdesti" },
  { judet: "Alba", localitate: "Simocesti" },
  { judet: "Alba", localitate: "Toci" },
  { judet: "Alba", localitate: "Valea Verde" },
  { judet: "Alba", localitate: "Vladosesti" },
  { judet: "Alba", localitate: "Fata Pietrii" },
  { judet: "Alba", localitate: "Geoagiu de Sus" },
  { judet: "Alba", localitate: "Geomal" },
  { judet: "Alba", localitate: "Balomiru de Camp" },
  { judet: "Alba", localitate: "Bacainti" },
  { judet: "Alba", localitate: "Saracsau" },
  { judet: "Alba", localitate: "Alecus" },
  { judet: "Alba", localitate: "Biia" },
  { judet: "Alba", localitate: "Doptau" },
  { judet: "Alba", localitate: "Lunca Tarnavei" },
  { judet: "Alba", localitate: "Sanmiclaus" },
  { judet: "Alba", localitate: "Valea Sasului" },
  { judet: "Alba", localitate: "Carpen" },
  { judet: "Alba", localitate: "Carpenii de Sus" },
  { judet: "Alba", localitate: "Cunta" },
  { judet: "Alba", localitate: "Drasov" },
  { judet: "Alba", localitate: "Vingard" },
  { judet: "Alba", localitate: "Arti" },
  { judet: "Alba", localitate: "Barsana" },
  { judet: "Alba", localitate: "Dobra" },
  { judet: "Alba", localitate: "Jidostina" },
  { judet: "Alba", localitate: "Martinie" },
  { judet: "Alba", localitate: "Tau Bistra" },
  { judet: "Alba", localitate: "Ciugudu de Jos" },
  { judet: "Alba", localitate: "Ciugudu de Sus" },
  { judet: "Alba", localitate: "Dumbrava (Unirea)" },
  { judet: "Alba", localitate: "Inoc" },
  { judet: "Alba", localitate: "Mahaceni" },
  { judet: "Alba", localitate: "Bodesti" },
  { judet: "Alba", localitate: "Burzesti" },
  { judet: "Alba", localitate: "Dealu Frumos (Vadu Motilor)" },
  { judet: "Alba", localitate: "Lazesti (Vadu Motilor)" },
  { judet: "Alba", localitate: "Necsesti" },
  { judet: "Alba", localitate: "Poduri-Bricesti" },
  { judet: "Alba", localitate: "Popestii de Jos" },
  { judet: "Alba", localitate: "Popestii de Sus" },
  { judet: "Alba", localitate: "Tomutesti" },
  { judet: "Alba", localitate: "Totesti" },
  { judet: "Alba", localitate: "Valtori (Vadu Motilor)" },
  { judet: "Alba", localitate: "Faget" },
  { judet: "Alba", localitate: "Glogovet" },
  { judet: "Alba", localitate: "Lodroman" },
  { judet: "Alba", localitate: "Lunca (Valea Lunga)" },
  { judet: "Alba", localitate: "Tauni" },
  { judet: "Alba", localitate: "Bai" },
  { judet: "Alba", localitate: "Bobaresti (Vidra)" },
  { judet: "Alba", localitate: "Bogdanesti (Vidra)" },
  { judet: "Alba", localitate: "Bordestii Poieni" },
  { judet: "Alba", localitate: "Culdesti" },
  { judet: "Alba", localitate: "Dealu Goiesti" },
  { judet: "Alba", localitate: "Dos" },
  { judet: "Alba", localitate: "Dosu Luncii" },
  { judet: "Alba", localitate: "Dosu Vasesti" },
  { judet: "Alba", localitate: "Dragoiesti-Lunca" },
  { judet: "Alba", localitate: "Ficaresti" },
  { judet: "Alba", localitate: "Gligoresti" },
  { judet: "Alba", localitate: "Goiesti" },
  { judet: "Alba", localitate: "Haiducesti" },
  { judet: "Alba", localitate: "Harasti" },
  { judet: "Alba", localitate: "Hoanca (Vidra)" },
  { judet: "Alba", localitate: "Jeflesti" },
  { judet: "Alba", localitate: "Lunca (Vidra)" },
  { judet: "Alba", localitate: "Lunca Bisericii" },
  { judet: "Alba", localitate: "Lunca Goiesti" },
  { judet: "Alba", localitate: "Lunca Vesesti" },
  { judet: "Alba", localitate: "Lunca de Jos" },
  { judet: "Alba", localitate: "Modolesti (Vidra)" },
  { judet: "Alba", localitate: "Nemesi" },
  { judet: "Alba", localitate: "Oidesti" },
  { judet: "Alba", localitate: "Pitarcesti" },
  { judet: "Alba", localitate: "Plescuta" },
  { judet: "Alba", localitate: "Poieni (Vidra)" },
  { judet: "Alba", localitate: "Ponorel" },
  { judet: "Alba", localitate: "Puiuletesti" },
  { judet: "Alba", localitate: "Runc (Vidra)" },
  { judet: "Alba", localitate: "Segaj" },
  { judet: "Alba", localitate: "Urdes" },
  { judet: "Alba", localitate: "Valea Morii" },
  { judet: "Alba", localitate: "Vasesti" },
  { judet: "Alba", localitate: "Valcaneasa" },
  { judet: "Alba", localitate: "Valcesti" },
  { judet: "Alba", localitate: "Vartanesti" },
  { judet: "Alba", localitate: "Campu Goblii" },
  { judet: "Alba", localitate: "Ciocasu" },
  { judet: "Alba", localitate: "Criseni" },
  { judet: "Alba", localitate: "Dealu Ferului" },
  { judet: "Alba", localitate: "Gura Cutului" },
  { judet: "Alba", localitate: "Hategana" },
  { judet: "Alba", localitate: "Inuri" },
  { judet: "Alba", localitate: "Laz (Vintu de Jos)" },
  { judet: "Alba", localitate: "Matacina" },
  { judet: "Alba", localitate: "Mereteu" },
  { judet: "Alba", localitate: "Parau lui Mihai" },
  { judet: "Alba", localitate: "Poienita (Vintu de Jos)" },
  { judet: "Alba", localitate: "Stauini" },
  { judet: "Alba", localitate: "Valea Goblii" },
  { judet: "Alba", localitate: "Valea Vintului" },
  { judet: "Alba", localitate: "Valea lui Mihai" },
  { judet: "Alba", localitate: "Vurpar" },
  { judet: "Arad", localitate: "Arad" },
  { judet: "Arad", localitate: "Chisineu-Cris" },
  { judet: "Arad", localitate: "Ineu" },
  { judet: "Arad", localitate: "Curtici" },
  { judet: "Arad", localitate: "Pecica" },
  { judet: "Arad", localitate: "Simand" },
  { judet: "Arad", localitate: "Sebis" },
  { judet: "Arad", localitate: "Santana" },
  { judet: "Arad", localitate: "Vladimirescu" },
  { judet: "Arad", localitate: "Lipova" },
  { judet: "Arad", localitate: "Nadlac" },
  { judet: "Arad", localitate: "Pancota" },
  { judet: "Arad", localitate: "Almas" },
  { judet: "Arad", localitate: "Apateu" },
  { judet: "Arad", localitate: "Archis" },
  { judet: "Arad", localitate: "Bata" },
  { judet: "Arad", localitate: "Barsa" },
  { judet: "Arad", localitate: "Barzava" },
  { judet: "Arad", localitate: "Beliu" },
  { judet: "Arad", localitate: "Birchis" },
  { judet: "Arad", localitate: "Bocsig" },
  { judet: "Arad", localitate: "Brazii" },
  { judet: "Arad", localitate: "Buteni" },
  { judet: "Arad", localitate: "Carand" },
  { judet: "Arad", localitate: "Cermei" },
  { judet: "Arad", localitate: "Chisindia" },
  { judet: "Arad", localitate: "Conop" },
  { judet: "Arad", localitate: "Craiva" },
  { judet: "Arad", localitate: "Dezna" },
  { judet: "Arad", localitate: "Dieci" },
  { judet: "Arad", localitate: "Dorobanti" },
  { judet: "Arad", localitate: "Fantanele" },
  { judet: "Arad", localitate: "Felnac" },
  { judet: "Arad", localitate: "Frumuseni" },
  { judet: "Arad", localitate: "Ghioroc" },
  { judet: "Arad", localitate: "Graniceri" },
  { judet: "Arad", localitate: "Gurahont" },
  { judet: "Arad", localitate: "Halmagiu" },
  { judet: "Arad", localitate: "Halmagel" },
  { judet: "Arad", localitate: "Hasmas" },
  { judet: "Arad", localitate: "Ignesti" },
  { judet: "Arad", localitate: "Iratosu" },
  { judet: "Arad", localitate: "Livada" },
  { judet: "Arad", localitate: "Macea" },
  { judet: "Arad", localitate: "Misca" },
  { judet: "Arad", localitate: "Moneasa" },
  { judet: "Arad", localitate: "Olari" },
  { judet: "Arad", localitate: "Paulis" },
  { judet: "Arad", localitate: "Peregu Mare" },
  { judet: "Arad", localitate: "Petris" },
  { judet: "Arad", localitate: "Pilu" },
  { judet: "Arad", localitate: "Plescuta" },
  { judet: "Arad", localitate: "Sagu" },
  { judet: "Arad", localitate: "Savarsin" },
  { judet: "Arad", localitate: "Secusigiu" },
  { judet: "Arad", localitate: "Seleus" },
  { judet: "Arad", localitate: "Semlac" },
  { judet: "Arad", localitate: "Seitin" },
  { judet: "Arad", localitate: "Sepreus" },
  { judet: "Arad", localitate: "Sintea Mare" },
  { judet: "Arad", localitate: "Socodor" },
  { judet: "Arad", localitate: "Sicula" },
  { judet: "Arad", localitate: "Silindia" },
  { judet: "Arad", localitate: "Siria" },
  { judet: "Arad", localitate: "Sistarovat" },
  { judet: "Arad", localitate: "Sofronea" },
  { judet: "Arad", localitate: "Taut" },
  { judet: "Arad", localitate: "Tarnova" },
  { judet: "Arad", localitate: "Ususau" },
  { judet: "Arad", localitate: "Varadia de Mures" },
  { judet: "Arad", localitate: "Varfurile" },
  { judet: "Arad", localitate: "Vinga" },
  { judet: "Arad", localitate: "Zabrani" },
  { judet: "Arad", localitate: "Zadareni" },
  { judet: "Arad", localitate: "Zarand" },
  { judet: "Arad", localitate: "Zerind" },
  { judet: "Arad", localitate: "Zimandu Nou" },
  { judet: "Arad", localitate: "Varsand" },
  { judet: "Arad", localitate: "Nadab" },
  { judet: "Arad", localitate: "Mocrea" },
  { judet: "Arad", localitate: "Radna" },
  { judet: "Arad", localitate: "Soimos" },
  { judet: "Arad", localitate: "Maderat" },
  { judet: "Arad", localitate: "Donceni" },
  { judet: "Arad", localitate: "Prunisor" },
  { judet: "Arad", localitate: "Salajeni" },
  { judet: "Arad", localitate: "Cil" },
  { judet: "Arad", localitate: "Joia Mare" },
  { judet: "Arad", localitate: "Radesti" },
  { judet: "Arad", localitate: "Berechiu" },
  { judet: "Arad", localitate: "Motiori" },
  { judet: "Arad", localitate: "Barzesti" },
  { judet: "Arad", localitate: "Groseni" },
  { judet: "Arad", localitate: "Nermis" },
  { judet: "Arad", localitate: "Bacau de Mijloc" },
  { judet: "Arad", localitate: "Bulci" },
  { judet: "Arad", localitate: "Tela" },
  { judet: "Arad", localitate: "Aldesti" },
  { judet: "Arad", localitate: "Hodis" },
  { judet: "Arad", localitate: "Voivodeni" },
  { judet: "Arad", localitate: "Batuta" },
  { judet: "Arad", localitate: "Capruta" },
  { judet: "Arad", localitate: "Dumbravita" },
  { judet: "Arad", localitate: "Grosii Noi" },
  { judet: "Arad", localitate: "Lalasint" },
  { judet: "Arad", localitate: "Monorostia" },
  { judet: "Arad", localitate: "Slatina de Mures" },
  { judet: "Arad", localitate: "Benesti" },
  { judet: "Arad", localitate: "Bochia" },
  { judet: "Arad", localitate: "Secaci" },
  { judet: "Arad", localitate: "Tagadau" },
  { judet: "Arad", localitate: "Vasile Goldis" },
  { judet: "Arad", localitate: "Capalnas" },
  { judet: "Arad", localitate: "Ostrov" },
  { judet: "Arad", localitate: "Virismort" },
  { judet: "Arad", localitate: "Manerau" },
  { judet: "Arad", localitate: "Rapsig" },
  { judet: "Arad", localitate: "Buceava-Soimus" },
  { judet: "Arad", localitate: "Iacobini" },
  { judet: "Arad", localitate: "Madrigesti" },
  { judet: "Arad", localitate: "Secas" },
  { judet: "Arad", localitate: "Berindia" },
  { judet: "Arad", localitate: "Cuied" },
  { judet: "Arad", localitate: "Paulian" },
  { judet: "Arad", localitate: "Selistea" },
  { judet: "Arad", localitate: "Avram Iancu (Cermei)" },
  { judet: "Arad", localitate: "Somosches" },
  { judet: "Arad", localitate: "Paiuseni" },
  { judet: "Arad", localitate: "Vasoaia" },
  { judet: "Arad", localitate: "Belotint" },
  { judet: "Arad", localitate: "Chelmac" },
  { judet: "Arad", localitate: "Milova" },
  { judet: "Arad", localitate: "Odvos" },
  { judet: "Arad", localitate: "Covasint" },
  { judet: "Arad", localitate: "Chislaca" },
  { judet: "Arad", localitate: "Ciuntesti" },
  { judet: "Arad", localitate: "Coroi" },
  { judet: "Arad", localitate: "Maraus" },
  { judet: "Arad", localitate: "Rogoz de Beliu" },
  { judet: "Arad", localitate: "Stoinesti" },
  { judet: "Arad", localitate: "Susag" },
  { judet: "Arad", localitate: "Siad" },
  { judet: "Arad", localitate: "Talmaci" },
  { judet: "Arad", localitate: "Buhani" },
  { judet: "Arad", localitate: "Laz" },
  { judet: "Arad", localitate: "Neagra" },
  { judet: "Arad", localitate: "Slatina de Cris" },
  { judet: "Arad", localitate: "Cociuba" },
  { judet: "Arad", localitate: "Crocna" },
  { judet: "Arad", localitate: "Revetis" },
  { judet: "Arad", localitate: "Rosia" },
  { judet: "Arad", localitate: "Alunis" },
  { judet: "Arad", localitate: "Tisa Noua" },
  { judet: "Arad", localitate: "Bodrogu Nou" },
  { judet: "Arad", localitate: "Calugareni" },
  { judet: "Arad", localitate: "Cuvin" },
  { judet: "Arad", localitate: "Minis" },
  { judet: "Arad", localitate: "Siclau" },
  { judet: "Arad", localitate: "Bontesti" },
  { judet: "Arad", localitate: "Dulcele" },
  { judet: "Arad", localitate: "Fenis" },
  { judet: "Arad", localitate: "Hontisor" },
  { judet: "Arad", localitate: "Iosas" },
  { judet: "Arad", localitate: "Mustesti" },
  { judet: "Arad", localitate: "Pescari" },
  { judet: "Arad", localitate: "Valea Mare (Gurahont)" },
  { judet: "Arad", localitate: "Zimbru" },
  { judet: "Arad", localitate: "Banesti" },
  { judet: "Arad", localitate: "Bodesti" },
  { judet: "Arad", localitate: "Brusturi" },
  { judet: "Arad", localitate: "Cristesti" },
  { judet: "Arad", localitate: "Ionesti" },
  { judet: "Arad", localitate: "Leasa" },
  { judet: "Arad", localitate: "Lestioara" },
  { judet: "Arad", localitate: "Poienari" },
  { judet: "Arad", localitate: "Tisa" },
  { judet: "Arad", localitate: "Tarmure" },
  { judet: "Arad", localitate: "Luncsoara" },
  { judet: "Arad", localitate: "Sarbi" },
  { judet: "Arad", localitate: "Tarnavita" },
  { judet: "Arad", localitate: "Tohesti" },
  { judet: "Arad", localitate: "Agrisu Mic" },
  { judet: "Arad", localitate: "Botfei" },
  { judet: "Arad", localitate: "Clit" },
  { judet: "Arad", localitate: "Comanesti" },
  { judet: "Arad", localitate: "Urvisu de Beliu" },
  { judet: "Arad", localitate: "Minead" },
  { judet: "Arad", localitate: "Nadalbesti" },
  { judet: "Arad", localitate: "Susani" },
  { judet: "Arad", localitate: "Variasu Mare" },
  { judet: "Arad", localitate: "Variasu Mic" },
  { judet: "Arad", localitate: "Sanleani" },
  { judet: "Arad", localitate: "Sanmartin" },
  { judet: "Arad", localitate: "Satu Nou" },
  { judet: "Arad", localitate: "Vanatori" },
  { judet: "Arad", localitate: "Zerindu Mic" },
  { judet: "Arad", localitate: "Ranusa" },
  { judet: "Arad", localitate: "Sintea Mica" },
  { judet: "Arad", localitate: "Baratca" },
  { judet: "Arad", localitate: "Cladova" },
  { judet: "Arad", localitate: "Sambateni" },
  { judet: "Arad", localitate: "Bodrogu Vechi" },
  { judet: "Arad", localitate: "Sederhat" },
  { judet: "Arad", localitate: "Turnu" },
  { judet: "Arad", localitate: "Peregu Mic" },
  { judet: "Arad", localitate: "Corbesti" },
  { judet: "Arad", localitate: "Ilteu" },
  { judet: "Arad", localitate: "Obarsia" },
  { judet: "Arad", localitate: "Rosia Noua" },
  { judet: "Arad", localitate: "Seliste" },
  { judet: "Arad", localitate: "Aciuta" },
  { judet: "Arad", localitate: "Budesti" },
  { judet: "Arad", localitate: "Dumbrava" },
  { judet: "Arad", localitate: "Gura Vaii" },
  { judet: "Arad", localitate: "Rostoci" },
  { judet: "Arad", localitate: "Talagiu" },
  { judet: "Arad", localitate: "Caprioara" },
  { judet: "Arad", localitate: "Cuias" },
  { judet: "Arad", localitate: "Halalis" },
  { judet: "Arad", localitate: "Parnesti" },
  { judet: "Arad", localitate: "Temesesti" },
  { judet: "Arad", localitate: "Toc" },
  { judet: "Arad", localitate: "Troas" },
  { judet: "Arad", localitate: "Valea Mare (Savarsin)" },
  { judet: "Arad", localitate: "Caporal Alexa" },
  { judet: "Arad", localitate: "Munar" },
  { judet: "Arad", localitate: "Satu Mare" },
  { judet: "Arad", localitate: "Sanpetru German" },
  { judet: "Arad", localitate: "Iermata" },
  { judet: "Arad", localitate: "Moroda" },
  { judet: "Arad", localitate: "Adea" },
  { judet: "Arad", localitate: "Tipar" },
  { judet: "Arad", localitate: "Cruceni" },
  { judet: "Arad", localitate: "Firiteaz" },
  { judet: "Arad", localitate: "Fiscut" },
  { judet: "Arad", localitate: "Hunedoara Timisana" },
  { judet: "Arad", localitate: "Cherelus" },
  { judet: "Arad", localitate: "Gurba" },
  { judet: "Arad", localitate: "Camna" },
  { judet: "Arad", localitate: "Iercoseni" },
  { judet: "Arad", localitate: "Luguzau" },
  { judet: "Arad", localitate: "Satu Mic" },
  { judet: "Arad", localitate: "Galsa" },
  { judet: "Arad", localitate: "Masca" },
  { judet: "Arad", localitate: "Cuvesdia" },
  { judet: "Arad", localitate: "Labasint" },
  { judet: "Arad", localitate: "Varnita" },
  { judet: "Arad", localitate: "Sanpaul" },
  { judet: "Arad", localitate: "Minisel" },
  { judet: "Arad", localitate: "Minisu de Sus" },
  { judet: "Arad", localitate: "Nadas" },
  { judet: "Arad", localitate: "Araneag" },
  { judet: "Arad", localitate: "Chier" },
  { judet: "Arad", localitate: "Draut" },
  { judet: "Arad", localitate: "Dud" },
  { judet: "Arad", localitate: "Agrisu Mare" },
  { judet: "Arad", localitate: "Bruznic" },
  { judet: "Arad", localitate: "Dorgos" },
  { judet: "Arad", localitate: "Patars" },
  { judet: "Arad", localitate: "Zabalt" },
  { judet: "Arad", localitate: "Baia" },
  { judet: "Arad", localitate: "Julita" },
  { judet: "Arad", localitate: "Lupesti" },
  { judet: "Arad", localitate: "Nicolae Balcescu" },
  { judet: "Arad", localitate: "Stejar" },
  { judet: "Arad", localitate: "Avram Iancu (Varfurile)" },
  { judet: "Arad", localitate: "Grosi" },
  { judet: "Arad", localitate: "Lazuri" },
  { judet: "Arad", localitate: "Magulicea" },
  { judet: "Arad", localitate: "Mermesti" },
  { judet: "Arad", localitate: "Poiana" },
  { judet: "Arad", localitate: "Vidra" },
  { judet: "Arad", localitate: "Mailat" },
  { judet: "Arad", localitate: "Manastur" },
  { judet: "Arad", localitate: "Cicir" },
  { judet: "Arad", localitate: "Horia" },
  { judet: "Arad", localitate: "Mandruloc" },
  { judet: "Arad", localitate: "Chesint" },
  { judet: "Arad", localitate: "Neudorf" },
  { judet: "Arad", localitate: "Cintei" },
  { judet: "Arad", localitate: "Iermata Neagra" },
  { judet: "Arad", localitate: "Andrei Saguna" },
  { judet: "Arad", localitate: "Zimandcuz" },
  { judet: "Arges", localitate: "Pitesti" },
  { judet: "Arges", localitate: "Campulung" },
  { judet: "Arges", localitate: "Curtea de Arges" },
  { judet: "Arges", localitate: "Mioveni" },
  { judet: "Arges", localitate: "Poiana Lacului" },
  { judet: "Arges", localitate: "Valcelele" },
  { judet: "Arges", localitate: "Merisani" },
  { judet: "Arges", localitate: "Costesti" },
  { judet: "Arges", localitate: "Topoloveni" },
  { judet: "Arges", localitate: "Stefanesti" },
  { judet: "Arges", localitate: "Albestii de Arges" },
  { judet: "Arges", localitate: "Albestii de Muscel" },
  { judet: "Arges", localitate: "Albota" },
  { judet: "Arges", localitate: "Aninoasa" },
  { judet: "Arges", localitate: "Arefu" },
  { judet: "Arges", localitate: "Babana" },
  { judet: "Arges", localitate: "Baiculesti" },
  { judet: "Arges", localitate: "Balilesti" },
  { judet: "Arges", localitate: "Barla" },
  { judet: "Arges", localitate: "Bascov" },
  { judet: "Arges", localitate: "Beleti-Negresti" },
  { judet: "Arges", localitate: "Berevoesti" },
  { judet: "Arges", localitate: "Bogati" },
  { judet: "Arges", localitate: "Boteni" },
  { judet: "Arges", localitate: "Botesti" },
  { judet: "Arges", localitate: "Bradu" },
  { judet: "Arges", localitate: "Bradulet" },
  { judet: "Arges", localitate: "Budeasa" },
  { judet: "Arges", localitate: "Bughea de Jos" },
  { judet: "Arges", localitate: "Bughea de Sus" },
  { judet: "Arges", localitate: "Buzoesti" },
  { judet: "Arges", localitate: "Caldararu" },
  { judet: "Arges", localitate: "Calinesti" },
  { judet: "Arges", localitate: "Cateasca" },
  { judet: "Arges", localitate: "Cepari" },
  { judet: "Arges", localitate: "Cetateni" },
  { judet: "Arges", localitate: "Cicanesti" },
  { judet: "Arges", localitate: "Ciofrangeni" },
  { judet: "Arges", localitate: "Ciomagesti" },
  { judet: "Arges", localitate: "Cocu" },
  { judet: "Arges", localitate: "Corbeni" },
  { judet: "Arges", localitate: "Corbi" },
  { judet: "Arges", localitate: "Cosesti" },
  { judet: "Arges", localitate: "Cotmeana" },
  { judet: "Arges", localitate: "Cuca" },
  { judet: "Arges", localitate: "Davidesti" },
  { judet: "Arges", localitate: "Dambovicioara" },
  { judet: "Arges", localitate: "Darmanesti" },
  { judet: "Arges", localitate: "Dobresti" },
  { judet: "Arges", localitate: "Domnesti" },
  { judet: "Arges", localitate: "Draganu" },
  { judet: "Arges", localitate: "Dragoslavele" },
  { judet: "Arges", localitate: "Godeni" },
  { judet: "Arges", localitate: "Harsesti" },
  { judet: "Arges", localitate: "Hartiesti" },
  { judet: "Arges", localitate: "Izvoru" },
  { judet: "Arges", localitate: "Leordeni" },
  { judet: "Arges", localitate: "Leresti" },
  { judet: "Arges", localitate: "Lunca Corbului" },
  { judet: "Arges", localitate: "Malureni" },
  { judet: "Arges", localitate: "Maracineni" },
  { judet: "Arges", localitate: "Micesti" },
  { judet: "Arges", localitate: "Mihaesti" },
  { judet: "Arges", localitate: "Mioarele" },
  { judet: "Arges", localitate: "Mirosi" },
  { judet: "Arges", localitate: "Moraresti" },
  { judet: "Arges", localitate: "Mosoaia" },
  { judet: "Arges", localitate: "Mozaceni" },
  { judet: "Arges", localitate: "Musatesti" },
  { judet: "Arges", localitate: "Negrasi" },
  { judet: "Arges", localitate: "Nucsoara" },
  { judet: "Arges", localitate: "Oarja" },
  { judet: "Arges", localitate: "Pietrosani" },
  { judet: "Arges", localitate: "Poienarii de Arges" },
  { judet: "Arges", localitate: "Poienarii de Muscel" },
  { judet: "Arges", localitate: "Popesti" },
  { judet: "Arges", localitate: "Priboieni" },
  { judet: "Arges", localitate: "Raca" },
  { judet: "Arges", localitate: "Ratesti" },
  { judet: "Arges", localitate: "Recea" },
  { judet: "Arges", localitate: "Rociu" },
  { judet: "Arges", localitate: "Rucar" },
  { judet: "Arges", localitate: "Salatrucu" },
  { judet: "Arges", localitate: "Sapata" },
  { judet: "Arges", localitate: "Schitu Golesti" },
  { judet: "Arges", localitate: "Slobozia" },
  { judet: "Arges", localitate: "Stalpeni" },
  { judet: "Arges", localitate: "Stefan cel Mare" },
  { judet: "Arges", localitate: "Stoenesti" },
  { judet: "Arges", localitate: "Stolnici" },
  { judet: "Arges", localitate: "Suici" },
  { judet: "Arges", localitate: "Suseni" },
  { judet: "Arges", localitate: "Teiu" },
  { judet: "Arges", localitate: "Tigveni" },
  { judet: "Arges", localitate: "Titesti" },
  { judet: "Arges", localitate: "Uda" },
  { judet: "Arges", localitate: "Ungheni" },
  { judet: "Arges", localitate: "Valea Danului" },
  { judet: "Arges", localitate: "Valea Iasului" },
  { judet: "Arges", localitate: "Valea Mare-Pravat" },
  { judet: "Arges", localitate: "Vedea" },
  { judet: "Arges", localitate: "Vladesti" },
  { judet: "Arges", localitate: "Vulturesti" },
  { judet: "Arges", localitate: "Geamana" },
  { judet: "Arges", localitate: "Valea Rumanestilor" },
  { judet: "Arges", localitate: "Brosteni (Costesti)" },
  { judet: "Arges", localitate: "Laceni" },
  { judet: "Arges", localitate: "Parvu Rosu" },
  { judet: "Arges", localitate: "Podu Brosteni" },
  { judet: "Arges", localitate: "Smei" },
  { judet: "Arges", localitate: "Starci" },
  { judet: "Arges", localitate: "Noaptes" },
  { judet: "Arges", localitate: "Clucereasa" },
  { judet: "Arges", localitate: "Colibasi" },
  { judet: "Arges", localitate: "Fagetu" },
  { judet: "Arges", localitate: "Racovita" },
  { judet: "Arges", localitate: "Botarcani" },
  { judet: "Arges", localitate: "Crintesti" },
  { judet: "Arges", localitate: "Goranesti" },
  { judet: "Arges", localitate: "Tiganesti" },
  { judet: "Arges", localitate: "Albestii Ungureni" },
  { judet: "Arges", localitate: "Bratesti" },
  { judet: "Arges", localitate: "Doblea" },
  { judet: "Arges", localitate: "Dobrotu" },
  { judet: "Arges", localitate: "Albestii Pamanteni" },
  { judet: "Arges", localitate: "Dumiresti" },
  { judet: "Arges", localitate: "Florieni" },
  { judet: "Arges", localitate: "Albesti" },
  { judet: "Arges", localitate: "Candesti" },
  { judet: "Arges", localitate: "Cerbu" },
  { judet: "Arges", localitate: "Fratesti" },
  { judet: "Arges", localitate: "Gura Vaii" },
  { judet: "Arges", localitate: "Mares" },
  { judet: "Arges", localitate: "Brosteni (Aninoasa)" },
  { judet: "Arges", localitate: "Slanic" },
  { judet: "Arges", localitate: "Valea Silistii" },
  { judet: "Arges", localitate: "Capatanenii Pamanteni" },
  { judet: "Arges", localitate: "Capatanenii Ungureni" },
  { judet: "Arges", localitate: "Braileni" },
  { judet: "Arges", localitate: "Glambocu" },
  { judet: "Arges", localitate: "Mica" },
  { judet: "Arges", localitate: "Prislopu Mic" },
  { judet: "Arges", localitate: "Schiau" },
  { judet: "Arges", localitate: "Uiasca" },
  { judet: "Arges", localitate: "Valea Ursului" },
  { judet: "Arges", localitate: "Bajanesti" },
  { judet: "Arges", localitate: "Ciobanesti" },
  { judet: "Arges", localitate: "Cotmenita" },
  { judet: "Arges", localitate: "Grosi" },
  { judet: "Arges", localitate: "Lupueni" },
  { judet: "Arges", localitate: "Slatioarele" },
  { judet: "Arges", localitate: "Alunisu (Baiculesti)" },
  { judet: "Arges", localitate: "Anghinesti" },
  { judet: "Arges", localitate: "Argesani" },
  { judet: "Arges", localitate: "Manicesti" },
  { judet: "Arges", localitate: "Stejari" },
  { judet: "Arges", localitate: "Tutana" },
  { judet: "Arges", localitate: "Valea Brazilor" },
  { judet: "Arges", localitate: "Valea lui Enache" },
  { judet: "Arges", localitate: "Zigoneni" },
  { judet: "Arges", localitate: "Bajesti" },
  { judet: "Arges", localitate: "Golesti (Balilesti)" },
  { judet: "Arges", localitate: "Poienita" },
  { judet: "Arges", localitate: "Priboaia" },
  { judet: "Arges", localitate: "Ulita" },
  { judet: "Arges", localitate: "Valea Mare-Bratia" },
  { judet: "Arges", localitate: "Afrimesti" },
  { judet: "Arges", localitate: "Badesti (Barla)" },
  { judet: "Arges", localitate: "Brabeti" },
  { judet: "Arges", localitate: "Ciocesti" },
  { judet: "Arges", localitate: "Malu (Barla)" },
  { judet: "Arges", localitate: "Mandra" },
  { judet: "Arges", localitate: "Mozacenii-Vale" },
  { judet: "Arges", localitate: "Podisoru" },
  { judet: "Arges", localitate: "Selareasca" },
  { judet: "Arges", localitate: "Urlueni" },
  { judet: "Arges", localitate: "Zuvelcati" },
  { judet: "Arges", localitate: "Beleti" },
  { judet: "Arges", localitate: "Lentea" },
  { judet: "Arges", localitate: "Negresti" },
  { judet: "Arges", localitate: "Zgripcesti" },
  { judet: "Arges", localitate: "Bratia (Berevoesti)" },
  { judet: "Arges", localitate: "Gamacesti" },
  { judet: "Arges", localitate: "Otelu" },
  { judet: "Arges", localitate: "Barloi" },
  { judet: "Arges", localitate: "Bujoi" },
  { judet: "Arges", localitate: "Chitesti" },
  { judet: "Arges", localitate: "Dumbrava" },
  { judet: "Arges", localitate: "Glambocel" },
  { judet: "Arges", localitate: "Glambocelu" },
  { judet: "Arges", localitate: "Suseni (Bogati)" },
  { judet: "Arges", localitate: "Balabani" },
  { judet: "Arges", localitate: "Lunca" },
  { judet: "Arges", localitate: "Muscel" },
  { judet: "Arges", localitate: "Mosteni-Greci" },
  { judet: "Arges", localitate: "Alunisu (Bradulet)" },
  { judet: "Arges", localitate: "Bradetu" },
  { judet: "Arges", localitate: "Cosaci" },
  { judet: "Arges", localitate: "Galesu" },
  { judet: "Arges", localitate: "Piatra (Bradulet)" },
  { judet: "Arges", localitate: "Slamnesti" },
  { judet: "Arges", localitate: "Uleni" },
  { judet: "Arges", localitate: "Ungureni (Bradulet)" },
  { judet: "Arges", localitate: "Budeasa Mare" },
  { judet: "Arges", localitate: "Budeasa Mica" },
  { judet: "Arges", localitate: "Calotesti" },
  { judet: "Arges", localitate: "Galasesti (Budeasa)" },
  { judet: "Arges", localitate: "Rogojina" },
  { judet: "Arges", localitate: "Valea Marului" },
  { judet: "Arges", localitate: "Bujoreni" },
  { judet: "Arges", localitate: "Cornatel" },
  { judet: "Arges", localitate: "Curteanca" },
  { judet: "Arges", localitate: "Ionesti" },
  { judet: "Arges", localitate: "Podeni" },
  { judet: "Arges", localitate: "Redea" },
  { judet: "Arges", localitate: "Serboeni" },
  { judet: "Arges", localitate: "Tomsanca" },
  { judet: "Arges", localitate: "Vladuta" },
  { judet: "Arges", localitate: "Vulpesti" },
  { judet: "Arges", localitate: "Burdea" },
  { judet: "Arges", localitate: "Strambeni (Caldararu)" },
  { judet: "Arges", localitate: "Carstieni" },
  { judet: "Arges", localitate: "Ciocanesti" },
  { judet: "Arges", localitate: "Glodu (Calinesti)" },
  { judet: "Arges", localitate: "Gorganu" },
  { judet: "Arges", localitate: "Radu Negru" },
  { judet: "Arges", localitate: "Rancaciov" },
  { judet: "Arges", localitate: "Udeni-Zavoi" },
  { judet: "Arges", localitate: "Urlucea" },
  { judet: "Arges", localitate: "Valea Corbului" },
  { judet: "Arges", localitate: "Valeni-Podgoria" },
  { judet: "Arges", localitate: "Vranesti" },
  { judet: "Arges", localitate: "Catanele" },
  { judet: "Arges", localitate: "Ciresu" },
  { judet: "Arges", localitate: "Coseri" },
  { judet: "Arges", localitate: "Gruiu (Cateasca)" },
  { judet: "Arges", localitate: "Recea (Cateasca)" },
  { judet: "Arges", localitate: "Silistea" },
  { judet: "Arges", localitate: "Carpenis" },
  { judet: "Arges", localitate: "Ceparii Pamanteni" },
  { judet: "Arges", localitate: "Ceparii Ungureni" },
  { judet: "Arges", localitate: "Morasti" },
  { judet: "Arges", localitate: "Sendrulesti" },
  { judet: "Arges", localitate: "Urluiesti" },
  { judet: "Arges", localitate: "Valea Magurei" },
  { judet: "Arges", localitate: "Zamfiresti (Cepari)" },
  { judet: "Arges", localitate: "Laicai" },
  { judet: "Arges", localitate: "Valea Cetatuia" },
  { judet: "Arges", localitate: "Barasti" },
  { judet: "Arges", localitate: "Mioarele (Cicanesti)" },
  { judet: "Arges", localitate: "Urechesti" },
  { judet: "Arges", localitate: "Burlusi" },
  { judet: "Arges", localitate: "Lacurile" },
  { judet: "Arges", localitate: "Piatra (Ciofrangeni)" },
  { judet: "Arges", localitate: "Schitu-Matei" },
  { judet: "Arges", localitate: "Beculesti" },
  { judet: "Arges", localitate: "Bratia (Ciomagesti)" },
  { judet: "Arges", localitate: "Cungrea" },
  { judet: "Arges", localitate: "Dogari" },
  { judet: "Arges", localitate: "Fedelesoiu" },
  { judet: "Arges", localitate: "Giuclani" },
  { judet: "Arges", localitate: "Paunesti" },
  { judet: "Arges", localitate: "Radutesti" },
  { judet: "Arges", localitate: "Barbatesti" },
  { judet: "Arges", localitate: "Crucisoara" },
  { judet: "Arges", localitate: "Facaletesti" },
  { judet: "Arges", localitate: "Greabanu" },
  { judet: "Arges", localitate: "Popesti (Cocu)" },
  { judet: "Arges", localitate: "Rachitele de Jos" },
  { judet: "Arges", localitate: "Rachitele de Sus" },
  { judet: "Arges", localitate: "Berindesti" },
  { judet: "Arges", localitate: "Bucsenesti" },
  { judet: "Arges", localitate: "Oestii Pamanteni" },
  { judet: "Arges", localitate: "Oestii Ungureni" },
  { judet: "Arges", localitate: "Poienari (Corbeni)" },
  { judet: "Arges", localitate: "Rotunda" },
  { judet: "Arges", localitate: "Turburea" },
  { judet: "Arges", localitate: "Corbsori" },
  { judet: "Arges", localitate: "Jgheaburi" },
  { judet: "Arges", localitate: "Poduri" },
  { judet: "Arges", localitate: "Poienarei" },
  { judet: "Arges", localitate: "Stanesti" },
  { judet: "Arges", localitate: "Jupanesti" },
  { judet: "Arges", localitate: "Lapusani" },
  { judet: "Arges", localitate: "Leicesti" },
  { judet: "Arges", localitate: "Pacioiu" },
  { judet: "Arges", localitate: "Petresti" },
  { judet: "Arges", localitate: "Priseaca" },
  { judet: "Arges", localitate: "Bascovele" },
  { judet: "Arges", localitate: "Bunesti (Cotmeana)" },
  { judet: "Arges", localitate: "Dealu Padurii" },
  { judet: "Arges", localitate: "Dragolesti" },
  { judet: "Arges", localitate: "Costesti (Cotmeana)" },
  { judet: "Arges", localitate: "Lintesti" },
  { judet: "Arges", localitate: "Negesti" },
  { judet: "Arges", localitate: "Pielesti" },
  { judet: "Arges", localitate: "Sandulesti" },
  { judet: "Arges", localitate: "Spiridoni" },
  { judet: "Arges", localitate: "Ursoaia" },
  { judet: "Arges", localitate: "Varloveni" },
  { judet: "Arges", localitate: "Zamfiresti (Cotmeana)" },
  { judet: "Arges", localitate: "Baltata" },
  { judet: "Arges", localitate: "Barbalani" },
  { judet: "Arges", localitate: "Carcesti" },
  { judet: "Arges", localitate: "Cotu (Cuca)" },
  { judet: "Arges", localitate: "Crivatu" },
  { judet: "Arges", localitate: "Launele de Sus" },
  { judet: "Arges", localitate: "Macai" },
  { judet: "Arges", localitate: "Manesti" },
  { judet: "Arges", localitate: "Sinesti" },
  { judet: "Arges", localitate: "Stanicei" },
  { judet: "Arges", localitate: "Teodoresti" },
  { judet: "Arges", localitate: "Valea Cucii" },
  { judet: "Arges", localitate: "Vonigeasa" },
  { judet: "Arges", localitate: "Contesti" },
  { judet: "Arges", localitate: "Voroveni" },
  { judet: "Arges", localitate: "Ciocanu" },
  { judet: "Arges", localitate: "Podu Dambovitei" },
  { judet: "Arges", localitate: "Negreni" },
  { judet: "Arges", localitate: "Piscani" },
  { judet: "Arges", localitate: "Valea Nandrii" },
  { judet: "Arges", localitate: "Valea Rizii" },
  { judet: "Arges", localitate: "Furesti" },
  { judet: "Arges", localitate: "Valea Hotarului" },
  { judet: "Arges", localitate: "Bacesti" },
  { judet: "Arges", localitate: "Draganu-Olteni" },
  { judet: "Arges", localitate: "Dumbravesti" },
  { judet: "Arges", localitate: "Prislopu Mare" },
  { judet: "Arges", localitate: "Bordeieni" },
  { judet: "Arges", localitate: "Capu Piscului (Godeni)" },
  { judet: "Arges", localitate: "Cotesti" },
  { judet: "Arges", localitate: "Malu (Godeni)" },
  { judet: "Arges", localitate: "Ciobani" },
  { judet: "Arges", localitate: "Martalogi" },
  { judet: "Arges", localitate: "Barzesti" },
  { judet: "Arges", localitate: "Dealu" },
  { judet: "Arges", localitate: "Huluba" },
  { judet: "Arges", localitate: "Lespezi" },
  { judet: "Arges", localitate: "Lucieni" },
  { judet: "Arges", localitate: "Baloteasca" },
  { judet: "Arges", localitate: "Baila" },
  { judet: "Arges", localitate: "Bantau" },
  { judet: "Arges", localitate: "Budisteni" },
  { judet: "Arges", localitate: "Carciumaresti" },
  { judet: "Arges", localitate: "Ciolcesti" },
  { judet: "Arges", localitate: "Ciulnita" },
  { judet: "Arges", localitate: "Cotu Malului" },
  { judet: "Arges", localitate: "Glambocata" },
  { judet: "Arges", localitate: "Glambocata-Deal" },
  { judet: "Arges", localitate: "Glodu (Leordeni)" },
  { judet: "Arges", localitate: "Moara Mocanului" },
  { judet: "Arges", localitate: "Schitu Scoicesti" },
  { judet: "Arges", localitate: "Pojorata" },
  { judet: "Arges", localitate: "Voinesti" },
  { judet: "Arges", localitate: "Bumbueni" },
  { judet: "Arges", localitate: "Catane" },
  { judet: "Arges", localitate: "Ciesti" },
  { judet: "Arges", localitate: "Langesti" },
  { judet: "Arges", localitate: "Marghia de Jos" },
  { judet: "Arges", localitate: "Marghia de Sus" },
  { judet: "Arges", localitate: "Padureti" },
  { judet: "Arges", localitate: "Silisteni" },
  { judet: "Arges", localitate: "Bunesti (Malureni)" },
  { judet: "Arges", localitate: "Pauleasca (Malureni)" },
  { judet: "Arges", localitate: "Toplita" },
  { judet: "Arges", localitate: "Zarnesti" },
  { judet: "Arges", localitate: "Argeselu" },
  { judet: "Arges", localitate: "Borlesti" },
  { judet: "Arges", localitate: "Brateasca" },
  { judet: "Arges", localitate: "Capu Piscului (Merisani)" },
  { judet: "Arges", localitate: "Crampotani" },
  { judet: "Arges", localitate: "Dobrogostea" },
  { judet: "Arges", localitate: "Malu Vanat" },
  { judet: "Arges", localitate: "Varzaru" },
  { judet: "Arges", localitate: "Branzari" },
  { judet: "Arges", localitate: "Pauleasca (Micesti)" },
  { judet: "Arges", localitate: "Purcareni (Micesti)" },
  { judet: "Arges", localitate: "Draghici" },
  { judet: "Arges", localitate: "Furnicosi" },
  { judet: "Arges", localitate: "Rudeni (Mihaesti)" },
  { judet: "Arges", localitate: "Valea Bradului" },
  { judet: "Arges", localitate: "Valea Popii (Mihaesti)" },
  { judet: "Arges", localitate: "Vacarea" },
  { judet: "Arges", localitate: "Alunis" },
  { judet: "Arges", localitate: "Chilii" },
  { judet: "Arges", localitate: "Cocenesti" },
  { judet: "Arges", localitate: "Matau" },
  { judet: "Arges", localitate: "Suslanesti" },
  { judet: "Arges", localitate: "Surdulesti" },
  { judet: "Arges", localitate: "Dealu Obejdeanului" },
  { judet: "Arges", localitate: "Dedulesti" },
  { judet: "Arges", localitate: "Luminile" },
  { judet: "Arges", localitate: "Mancioiu" },
  { judet: "Arges", localitate: "Sapunari" },
  { judet: "Arges", localitate: "Batrani" },
  { judet: "Arges", localitate: "Ciocanai" },
  { judet: "Arges", localitate: "Dealu Viilor (Mosoaia)" },
  { judet: "Arges", localitate: "Hintesti" },
  { judet: "Arges", localitate: "Lazaresti (Mosoaia)" },
  { judet: "Arges", localitate: "Smeura" },
  { judet: "Arges", localitate: "Babaroaga" },
  { judet: "Arges", localitate: "Zidurile" },
  { judet: "Arges", localitate: "Bolovanesti" },
  { judet: "Arges", localitate: "Costesti-Valsan" },
  { judet: "Arges", localitate: "Prosia" },
  { judet: "Arges", localitate: "Robaia" },
  { judet: "Arges", localitate: "Stroesti" },
  { judet: "Arges", localitate: "Valea Faurului" },
  { judet: "Arges", localitate: "Valea Muscelului" },
  { judet: "Arges", localitate: "Valea lui Mas" },
  { judet: "Arges", localitate: "Valsanesti" },
  { judet: "Arges", localitate: "Barlogu" },
  { judet: "Arges", localitate: "Buta" },
  { judet: "Arges", localitate: "Mozacu" },
  { judet: "Arges", localitate: "Gruiu (Nucsoara)" },
  { judet: "Arges", localitate: "Sboghitesti" },
  { judet: "Arges", localitate: "Slatina" },
  { judet: "Arges", localitate: "Ceausesti" },
  { judet: "Arges", localitate: "Badesti (Pietrosani)" },
  { judet: "Arges", localitate: "Ganesti" },
  { judet: "Arges", localitate: "Retevoiesti" },
  { judet: "Arges", localitate: "Varzaroaia" },
  { judet: "Arges", localitate: "Catunasi" },
  { judet: "Arges", localitate: "Cepari (Poiana Lacului)" },
  { judet: "Arges", localitate: "Dealu Orasului" },
  { judet: "Arges", localitate: "Dealu Viilor (Poiana Lacului)" },
  { judet: "Arges", localitate: "Dinculesti" },
  { judet: "Arges", localitate: "Galeteanu" },
  { judet: "Arges", localitate: "Gardinesti" },
  { judet: "Arges", localitate: "Galcesti" },
  { judet: "Arges", localitate: "Metofu" },
  { judet: "Arges", localitate: "Paduroiu din Deal" },
  { judet: "Arges", localitate: "Paduroiu din Vale" },
  { judet: "Arges", localitate: "Samara" },
  { judet: "Arges", localitate: "Ceauresti" },
  { judet: "Arges", localitate: "Ioanicesti" },
  { judet: "Arges", localitate: "Poienari (Poienarii de Arges)" },
  { judet: "Arges", localitate: "Tomulesti" },
  { judet: "Arges", localitate: "Grosani" },
  { judet: "Arges", localitate: "Jugur" },
  { judet: "Arges", localitate: "Poienari (Poienarii de Muscel)" },
  { judet: "Arges", localitate: "Serbanesti (Poienarii de Muscel)" },
  { judet: "Arges", localitate: "Valea Indarat" },
  { judet: "Arges", localitate: "Adunati" },
  { judet: "Arges", localitate: "Bucov" },
  { judet: "Arges", localitate: "Palanga" },
  { judet: "Arges", localitate: "Purcareni (Popesti)" },
  { judet: "Arges", localitate: "Slobozia (Popesti)" },
  { judet: "Arges", localitate: "Albotele" },
  { judet: "Arges", localitate: "Paraschivesti" },
  { judet: "Arges", localitate: "Pitoi" },
  { judet: "Arges", localitate: "Samaila" },
  { judet: "Arges", localitate: "Valea Mare" },
  { judet: "Arges", localitate: "Valea Nenii" },
  { judet: "Arges", localitate: "Valea Popii (Priboieni)" },
  { judet: "Arges", localitate: "Ciupa-Manciulescu" },
  { judet: "Arges", localitate: "Furduesti" },
  { judet: "Arges", localitate: "Mavrodolu" },
  { judet: "Arges", localitate: "Nejlovelu" },
  { judet: "Arges", localitate: "Patuleni" },
  { judet: "Arges", localitate: "Tigveni (Ratesti)" },
  { judet: "Arges", localitate: "Deagu de Jos" },
  { judet: "Arges", localitate: "Deagu de Sus" },
  { judet: "Arges", localitate: "Goleasca" },
  { judet: "Arges", localitate: "Orodel" },
  { judet: "Arges", localitate: "Gliganu de Jos" },
  { judet: "Arges", localitate: "Gliganu de Sus" },
  { judet: "Arges", localitate: "Serbanesti (Rociu)" },
  { judet: "Arges", localitate: "Satic" },
  { judet: "Arges", localitate: "Valeni" },
  { judet: "Arges", localitate: "Banaresti" },
  { judet: "Arges", localitate: "Dealu Bradului" },
  { judet: "Arges", localitate: "Draghicesti" },
  { judet: "Arges", localitate: "Gainusa" },
  { judet: "Arges", localitate: "Lipia" },
  { judet: "Arges", localitate: "Martesti" },
  { judet: "Arges", localitate: "Popesti (Sapata)" },
  { judet: "Arges", localitate: "Turcesti" },
  { judet: "Arges", localitate: "Burnesti" },
  { judet: "Arges", localitate: "Costita" },
  { judet: "Arges", localitate: "Lazaresti (Schitu Golesti)" },
  { judet: "Arges", localitate: "Loturi" },
  { judet: "Arges", localitate: "Valea Pechii" },
  { judet: "Arges", localitate: "Nigrisoara" },
  { judet: "Arges", localitate: "Dealu Frumos" },
  { judet: "Arges", localitate: "Livezeni" },
  { judet: "Arges", localitate: "Ogrezea" },
  { judet: "Arges", localitate: "Opresti" },
  { judet: "Arges", localitate: "Pitigaia" },
  { judet: "Arges", localitate: "Radesti" },
  { judet: "Arges", localitate: "Badeni" },
  { judet: "Arges", localitate: "Cotenesti" },
  { judet: "Arges", localitate: "Lunca Gartii" },
  { judet: "Arges", localitate: "Piatra (Stoenesti)" },
  { judet: "Arges", localitate: "Slobozia (Stoenesti)" },
  { judet: "Arges", localitate: "Valea Badenilor" },
  { judet: "Arges", localitate: "Cochinesti" },
  { judet: "Arges", localitate: "Cotmeana (Stolnici)" },
  { judet: "Arges", localitate: "Falfani" },
  { judet: "Arges", localitate: "Izbasesti" },
  { judet: "Arges", localitate: "Vlascuta" },
  { judet: "Arges", localitate: "Burdesti" },
  { judet: "Arges", localitate: "Cersani" },
  { judet: "Arges", localitate: "Chiritesti (Suseni)" },
  { judet: "Arges", localitate: "Galasesti (Suseni)" },
  { judet: "Arges", localitate: "Odaeni" },
  { judet: "Arges", localitate: "Padureni" },
  { judet: "Arges", localitate: "Strambeni (Suseni)" },
  { judet: "Arges", localitate: "Stefanesti (Suseni)" },
  { judet: "Arges", localitate: "Tutulesti" },
  { judet: "Arges", localitate: "Glavacioc" },
  { judet: "Arges", localitate: "Enculesti" },
  { judet: "Arges", localitate: "Golesti (Stefanesti)" },
  { judet: "Arges", localitate: "Izvorani" },
  { judet: "Arges", localitate: "Stefanestii Noi" },
  { judet: "Arges", localitate: "Valea Mare-Podgoria" },
  { judet: "Arges", localitate: "Viisoara" },
  { judet: "Arges", localitate: "Zavoi" },
  { judet: "Arges", localitate: "Ianculesti" },
  { judet: "Arges", localitate: "Paltenu" },
  { judet: "Arges", localitate: "Pauleni" },
  { judet: "Arges", localitate: "Rudeni (Suici)" },
  { judet: "Arges", localitate: "Valea Calului" },
  { judet: "Arges", localitate: "Lesile" },
  { judet: "Arges", localitate: "Badislava" },
  { judet: "Arges", localitate: "Balilesti (Tigveni)" },
  { judet: "Arges", localitate: "Balteni" },
  { judet: "Arges", localitate: "Barsestii de Jos" },
  { judet: "Arges", localitate: "Barsestii de Sus" },
  { judet: "Arges", localitate: "Blaju" },
  { judet: "Arges", localitate: "Vladesti (Tigveni)" },
  { judet: "Arges", localitate: "Bucsenesti-Lotasi" },
  { judet: "Arges", localitate: "Cismea" },
  { judet: "Arges", localitate: "Valea Manastirii" },
  { judet: "Arges", localitate: "Valea Stanii" },
  { judet: "Arges", localitate: "Badulesti" },
  { judet: "Arges", localitate: "Baranesti" },
  { judet: "Arges", localitate: "Branistea" },
  { judet: "Arges", localitate: "Chiritesti (Uda)" },
  { judet: "Arges", localitate: "Cotu (Uda)" },
  { judet: "Arges", localitate: "Dealu Bisericii" },
  { judet: "Arges", localitate: "Dealu Tolcesii" },
  { judet: "Arges", localitate: "Diconesti" },
  { judet: "Arges", localitate: "Gorani" },
  { judet: "Arges", localitate: "Greaban" },
  { judet: "Arges", localitate: "Lungulesti" },
  { judet: "Arges", localitate: "Miercani" },
  { judet: "Arges", localitate: "Rajletu-Govora" },
  { judet: "Arges", localitate: "Romana" },
  { judet: "Arges", localitate: "Salistea" },
  { judet: "Arges", localitate: "Coltu" },
  { judet: "Arges", localitate: "Gaujani" },
  { judet: "Arges", localitate: "Goia" },
  { judet: "Arges", localitate: "Humele" },
  { judet: "Arges", localitate: "Satu Nou" },
  { judet: "Arges", localitate: "Banicesti" },
  { judet: "Arges", localitate: "Bolculesti" },
  { judet: "Arges", localitate: "Borobanesti" },
  { judet: "Arges", localitate: "Vernesti" },
  { judet: "Arges", localitate: "Badila" },
  { judet: "Arges", localitate: "Barbalatesti" },
  { judet: "Arges", localitate: "Borovinesti" },
  { judet: "Arges", localitate: "Cerbureni" },
  { judet: "Arges", localitate: "Mustatesti" },
  { judet: "Arges", localitate: "Ruginoasa" },
  { judet: "Arges", localitate: "Ungureni (Valea Iasului)" },
  { judet: "Arges", localitate: "Valea Uleiului" },
  { judet: "Arges", localitate: "Bilcesti" },
  { judet: "Arges", localitate: "Colnic" },
  { judet: "Arges", localitate: "Fantanea" },
  { judet: "Arges", localitate: "Gura Pravat" },
  { judet: "Arges", localitate: "Namaesti" },
  { judet: "Arges", localitate: "Pietroasa" },
  { judet: "Arges", localitate: "Selari" },
  { judet: "Arges", localitate: "Badicea" },
  { judet: "Arges", localitate: "Blejani" },
  { judet: "Arges", localitate: "Buretesti" },
  { judet: "Arges", localitate: "Chiritesti (Vedea)" },
  { judet: "Arges", localitate: "Chitani" },
  { judet: "Arges", localitate: "Ciuresti" },
  { judet: "Arges", localitate: "Dincani" },
  { judet: "Arges", localitate: "Fata" },
  { judet: "Arges", localitate: "Fratici" },
  { judet: "Arges", localitate: "Izvoru de Jos" },
  { judet: "Arges", localitate: "Izvoru de Sus" },
  { judet: "Arges", localitate: "Lungani" },
  { judet: "Arges", localitate: "Mogosesti" },
  { judet: "Arges", localitate: "Prodani" },
  { judet: "Arges", localitate: "Ratoi" },
  { judet: "Arges", localitate: "Vata" },
  { judet: "Arges", localitate: "Varsesti" },
  { judet: "Arges", localitate: "Vetisoara" },
  { judet: "Arges", localitate: "Coteasca" },
  { judet: "Arges", localitate: "Draghescu" },
  { judet: "Arges", localitate: "Putina" },
  { judet: "Bacau", localitate: "Bacau" },
  { judet: "Bacau", localitate: "Onesti" },
  { judet: "Bacau", localitate: "Targu Ocna" },
  { judet: "Bacau", localitate: "Moinesti" },
  { judet: "Bacau", localitate: "Comanesti" },
  { judet: "Bacau", localitate: "Slanic Moldova" },
  { judet: "Bacau", localitate: "Buhusi" },
  { judet: "Bacau", localitate: "Zemes" },
  { judet: "Bacau", localitate: "Darmanesti" },
  { judet: "Bacau", localitate: "Agas" },
  { judet: "Bacau", localitate: "Ardeoani" },
  { judet: "Bacau", localitate: "Asau" },
  { judet: "Bacau", localitate: "Balcani" },
  { judet: "Bacau", localitate: "Beresti-Bistrita" },
  { judet: "Bacau", localitate: "Beresti-Tazlau" },
  { judet: "Bacau", localitate: "Berzunti" },
  { judet: "Bacau", localitate: "Barsanesti" },
  { judet: "Bacau", localitate: "Blagesti" },
  { judet: "Bacau", localitate: "Bogdanesti" },
  { judet: "Bacau", localitate: "Brusturoasa" },
  { judet: "Bacau", localitate: "Buciumi" },
  { judet: "Bacau", localitate: "Buhoci" },
  { judet: "Bacau", localitate: "Casin" },
  { judet: "Bacau", localitate: "Caiuti" },
  { judet: "Bacau", localitate: "Cleja" },
  { judet: "Bacau", localitate: "Colonesti" },
  { judet: "Bacau", localitate: "Corbasca" },
  { judet: "Bacau", localitate: "Cotofanesti" },
  { judet: "Bacau", localitate: "Damienesti" },
  { judet: "Bacau", localitate: "Dealu Morii" },
  { judet: "Bacau", localitate: "Dofteana" },
  { judet: "Bacau", localitate: "Faraoani" },
  { judet: "Bacau", localitate: "Filipeni" },
  { judet: "Bacau", localitate: "Filipesti" },
  { judet: "Bacau", localitate: "Gaiceana" },
  { judet: "Bacau", localitate: "Ghimes-Faget" },
  { judet: "Bacau", localitate: "Gioseni" },
  { judet: "Bacau", localitate: "Garleni" },
  { judet: "Bacau", localitate: "Glavanesti" },
  { judet: "Bacau", localitate: "Gura Vaii" },
  { judet: "Bacau", localitate: "Helegiu" },
  { judet: "Bacau", localitate: "Hemeius" },
  { judet: "Bacau", localitate: "Huruiesti" },
  { judet: "Bacau", localitate: "Horgesti" },
  { judet: "Bacau", localitate: "Itesti" },
  { judet: "Bacau", localitate: "Izvoru Berheciului" },
  { judet: "Bacau", localitate: "Letea Veche" },
  { judet: "Bacau", localitate: "Lipova" },
  { judet: "Bacau", localitate: "Livezi" },
  { judet: "Bacau", localitate: "Luizi-Calugara" },
  { judet: "Bacau", localitate: "Magiresti" },
  { judet: "Bacau", localitate: "Magura" },
  { judet: "Bacau", localitate: "Manastirea Casin" },
  { judet: "Bacau", localitate: "Margineni" },
  { judet: "Bacau", localitate: "Motoseni" },
  { judet: "Bacau", localitate: "Negri" },
  { judet: "Bacau", localitate: "Nicolae Balcescu" },
  { judet: "Bacau", localitate: "Odobesti" },
  { judet: "Bacau", localitate: "Oituz" },
  { judet: "Bacau", localitate: "Oncesti" },
  { judet: "Bacau", localitate: "Orbeni" },
  { judet: "Bacau", localitate: "Palanca" },
  { judet: "Bacau", localitate: "Parava" },
  { judet: "Bacau", localitate: "Parincea" },
  { judet: "Bacau", localitate: "Pancesti" },
  { judet: "Bacau", localitate: "Pargaresti" },
  { judet: "Bacau", localitate: "Parjol" },
  { judet: "Bacau", localitate: "Plopana" },
  { judet: "Bacau", localitate: "Podu Turcului" },
  { judet: "Bacau", localitate: "Poduri" },
  { judet: "Bacau", localitate: "Prajesti" },
  { judet: "Bacau", localitate: "Racova" },
  { judet: "Bacau", localitate: "Racaciuni" },
  { judet: "Bacau", localitate: "Rachitoasa" },
  { judet: "Bacau", localitate: "Rosiori" },
  { judet: "Bacau", localitate: "Sascut" },
  { judet: "Bacau", localitate: "Sanduleni" },
  { judet: "Bacau", localitate: "Saucesti" },
  { judet: "Bacau", localitate: "Scorteni" },
  { judet: "Bacau", localitate: "Secuieni" },
  { judet: "Bacau", localitate: "Solont" },
  { judet: "Bacau", localitate: "Stanisesti" },
  { judet: "Bacau", localitate: "Strugari" },
  { judet: "Bacau", localitate: "Stefan cel Mare" },
  { judet: "Bacau", localitate: "Tamasi" },
  { judet: "Bacau", localitate: "Tatarasti" },
  { judet: "Bacau", localitate: "Targu Trotus" },
  { judet: "Bacau", localitate: "Traian" },
  { judet: "Bacau", localitate: "Ungureni" },
  { judet: "Bacau", localitate: "Urechesti" },
  { judet: "Bacau", localitate: "Valea Seaca" },
  { judet: "Bacau", localitate: "Vultureni" },
  { judet: "Bacau", localitate: "Borzesti" },
  { judet: "Bacau", localitate: "Slobozia (Onesti)" },
  { judet: "Bacau", localitate: "Marginea (Buhusi)" },
  { judet: "Bacau", localitate: "Runcu" },
  { judet: "Bacau", localitate: "Podei" },
  { judet: "Bacau", localitate: "Vermesti" },
  { judet: "Bacau", localitate: "Darmaneasca" },
  { judet: "Bacau", localitate: "Lapos" },
  { judet: "Bacau", localitate: "Pagubeni" },
  { judet: "Bacau", localitate: "Plopu (Darmanesti)" },
  { judet: "Bacau", localitate: "Salatruc" },
  { judet: "Bacau", localitate: "Gazarie" },
  { judet: "Bacau", localitate: "Cerdac" },
  { judet: "Bacau", localitate: "Ciresoaia" },
  { judet: "Bacau", localitate: "Poieni (Targu Ocna)" },
  { judet: "Bacau", localitate: "Valcele (Targu Ocna)" },
  { judet: "Bacau", localitate: "Beleghet" },
  { judet: "Bacau", localitate: "Cosnea" },
  { judet: "Bacau", localitate: "Cotumba" },
  { judet: "Bacau", localitate: "Diaconesti" },
  { judet: "Bacau", localitate: "Goioasa" },
  { judet: "Bacau", localitate: "Preluci" },
  { judet: "Bacau", localitate: "Sulta" },
  { judet: "Bacau", localitate: "Leontinesti" },
  { judet: "Bacau", localitate: "Apa Asau" },
  { judet: "Bacau", localitate: "Ciobanus" },
  { judet: "Bacau", localitate: "Lunca Asau" },
  { judet: "Bacau", localitate: "Paltinis" },
  { judet: "Bacau", localitate: "Straja" },
  { judet: "Bacau", localitate: "Frumoasa" },
  { judet: "Bacau", localitate: "Ludasi" },
  { judet: "Bacau", localitate: "Schitu Frumoasa" },
  { judet: "Bacau", localitate: "Albele" },
  { judet: "Bacau", localitate: "Bratesti" },
  { judet: "Bacau", localitate: "Caraclau" },
  { judet: "Bacau", localitate: "Brad (Beresti-Bistrita)" },
  { judet: "Bacau", localitate: "Ciumasi" },
  { judet: "Bacau", localitate: "Climesti" },
  { judet: "Bacau", localitate: "Dumbrava (Itesti)" },
  { judet: "Bacau", localitate: "Fagetel" },
  { judet: "Bacau", localitate: "Padureni (Beresti-Bistrita)" },
  { judet: "Bacau", localitate: "Bosoteni" },
  { judet: "Bacau", localitate: "Enachesti" },
  { judet: "Bacau", localitate: "Prisaca" },
  { judet: "Bacau", localitate: "Romanesti" },
  { judet: "Bacau", localitate: "Tescani" },
  { judet: "Bacau", localitate: "Turluianu" },
  { judet: "Bacau", localitate: "Buda (Berzunti)" },
  { judet: "Bacau", localitate: "Dragomir" },
  { judet: "Bacau", localitate: "Buda (Blagesti)" },
  { judet: "Bacau", localitate: "Poiana Negustorului" },
  { judet: "Bacau", localitate: "Tardenii Mari" },
  { judet: "Bacau", localitate: "Valea lui Ion" },
  { judet: "Bacau", localitate: "Filipesti (Bogdanesti)" },
  { judet: "Bacau", localitate: "Buruienis" },
  { judet: "Bacau", localitate: "Buruienisu de Sus" },
  { judet: "Bacau", localitate: "Camenca" },
  { judet: "Bacau", localitate: "Cuchinis" },
  { judet: "Bacau", localitate: "Hanganesti" },
  { judet: "Bacau", localitate: "Bijghir" },
  { judet: "Bacau", localitate: "Buhocel" },
  { judet: "Bacau", localitate: "Coteni" },
  { judet: "Bacau", localitate: "Dospinesti" },
  { judet: "Bacau", localitate: "Curita" },
  { judet: "Bacau", localitate: "Blidari" },
  { judet: "Bacau", localitate: "Boistea" },
  { judet: "Bacau", localitate: "Floresti (Caiuti)" },
  { judet: "Bacau", localitate: "Heltiu" },
  { judet: "Bacau", localitate: "Marcesti" },
  { judet: "Bacau", localitate: "Popeni" },
  { judet: "Bacau", localitate: "Pralea" },
  { judet: "Bacau", localitate: "Vranceni" },
  { judet: "Bacau", localitate: "Somusca" },
  { judet: "Bacau", localitate: "Valea Mica (Cleja)" },
  { judet: "Bacau", localitate: "Calini" },
  { judet: "Bacau", localitate: "Poiana (Colonesti)" },
  { judet: "Bacau", localitate: "Satu Nou (Colonesti)" },
  { judet: "Bacau", localitate: "Spria" },
  { judet: "Bacau", localitate: "Valea Mare (Colonesti)" },
  { judet: "Bacau", localitate: "Zapodia (Colonesti)" },
  { judet: "Bacau", localitate: "Bacioiu" },
  { judet: "Bacau", localitate: "Marvila" },
  { judet: "Bacau", localitate: "Poglet" },
  { judet: "Bacau", localitate: "Rogoaza" },
  { judet: "Bacau", localitate: "Scarisoara" },
  { judet: "Bacau", localitate: "Valcele (Corbasca)" },
  { judet: "Bacau", localitate: "Balca" },
  { judet: "Bacau", localitate: "Boistea de Jos" },
  { judet: "Bacau", localitate: "Borsani" },
  { judet: "Bacau", localitate: "Tamasoaia" },
  { judet: "Bacau", localitate: "Calugareni" },
  { judet: "Bacau", localitate: "Dragesti (Damienesti)" },
  { judet: "Bacau", localitate: "Padureni (Damienesti)" },
  { judet: "Bacau", localitate: "Banca" },
  { judet: "Bacau", localitate: "Balanesti (Dealu Morii)" },
  { judet: "Bacau", localitate: "Blaga" },
  { judet: "Bacau", localitate: "Bobos" },
  { judet: "Bacau", localitate: "Bodeasa" },
  { judet: "Bacau", localitate: "Bostanesti" },
  { judet: "Bacau", localitate: "Calapodesti" },
  { judet: "Bacau", localitate: "Cauia" },
  { judet: "Bacau", localitate: "Dorofei" },
  { judet: "Bacau", localitate: "Ghionoaia" },
  { judet: "Bacau", localitate: "Gradesti" },
  { judet: "Bacau", localitate: "Negulesti" },
  { judet: "Bacau", localitate: "Tavadaresti" },
  { judet: "Bacau", localitate: "Bogata" },
  { judet: "Bacau", localitate: "Cucuieti (Dofteana)" },
  { judet: "Bacau", localitate: "Haghiac (Dofteana)" },
  { judet: "Bacau", localitate: "Larga" },
  { judet: "Bacau", localitate: "Seaca" },
  { judet: "Bacau", localitate: "Stefan Voda" },
  { judet: "Bacau", localitate: "Balaia" },
  { judet: "Bacau", localitate: "Brad (Filipeni)" },
  { judet: "Bacau", localitate: "Fruntesti" },
  { judet: "Bacau", localitate: "Marasti" },
  { judet: "Bacau", localitate: "Padureni (Filipeni)" },
  { judet: "Bacau", localitate: "Slobozia (Filipeni)" },
  { judet: "Bacau", localitate: "Valea Botului" },
  { judet: "Bacau", localitate: "Boanta" },
  { judet: "Bacau", localitate: "Carligi" },
  { judet: "Bacau", localitate: "Cornesti" },
  { judet: "Bacau", localitate: "Cotu Grosului" },
  { judet: "Bacau", localitate: "Galbeni (Filipesti)" },
  { judet: "Bacau", localitate: "Harlesti" },
  { judet: "Bacau", localitate: "Oniscani" },
  { judet: "Bacau", localitate: "Arini" },
  { judet: "Bacau", localitate: "Hutu" },
  { judet: "Bacau", localitate: "Popesti" },
  { judet: "Bacau", localitate: "Garlenii de Sus" },
  { judet: "Bacau", localitate: "Lespezi" },
  { judet: "Bacau", localitate: "Surina" },
  { judet: "Bacau", localitate: "Bolovanis" },
  { judet: "Bacau", localitate: "Faget" },
  { judet: "Bacau", localitate: "Fagetu de Sus" },
  { judet: "Bacau", localitate: "Ghimes" },
  { judet: "Bacau", localitate: "Rachitis" },
  { judet: "Bacau", localitate: "Tarhausi" },
  { judet: "Bacau", localitate: "Frumuselu" },
  { judet: "Bacau", localitate: "Muncelu" },
  { judet: "Bacau", localitate: "Putredeni" },
  { judet: "Bacau", localitate: "Razesu" },
  { judet: "Bacau", localitate: "Capata" },
  { judet: "Bacau", localitate: "Dumbrava (Gura Vaii)" },
  { judet: "Bacau", localitate: "Motocesti" },
  { judet: "Bacau", localitate: "Paltinata" },
  { judet: "Bacau", localitate: "Temelia" },
  { judet: "Bacau", localitate: "Bratila" },
  { judet: "Bacau", localitate: "Deleni" },
  { judet: "Bacau", localitate: "Dragugesti" },
  { judet: "Bacau", localitate: "Fantanele (Hemeius)" },
  { judet: "Bacau", localitate: "Lilieci" },
  { judet: "Bacau", localitate: "Bazga" },
  { judet: "Bacau", localitate: "Galeri" },
  { judet: "Bacau", localitate: "Marascu" },
  { judet: "Bacau", localitate: "Racatau de Jos" },
  { judet: "Bacau", localitate: "Racatau-Razesi" },
  { judet: "Bacau", localitate: "Recea" },
  { judet: "Bacau", localitate: "Sohodor" },
  { judet: "Bacau", localitate: "Capotesti" },
  { judet: "Bacau", localitate: "Floresti (Huruiesti)" },
  { judet: "Bacau", localitate: "Fundoaia" },
  { judet: "Bacau", localitate: "Ocheni" },
  { judet: "Bacau", localitate: "Perchiu" },
  { judet: "Bacau", localitate: "Pradais" },
  { judet: "Bacau", localitate: "Antohesti" },
  { judet: "Bacau", localitate: "Baimac" },
  { judet: "Bacau", localitate: "Faghieni" },
  { judet: "Bacau", localitate: "Obarsia" },
  { judet: "Bacau", localitate: "Otelesti" },
  { judet: "Bacau", localitate: "Padureni (Izvoru Berheciului)" },
  { judet: "Bacau", localitate: "Holt" },
  { judet: "Bacau", localitate: "Radomiresti" },
  { judet: "Bacau", localitate: "Rusi-Ciutea" },
  { judet: "Bacau", localitate: "Siretu (Letea Veche)" },
  { judet: "Bacau", localitate: "Malosu" },
  { judet: "Bacau", localitate: "Satu Nou (Lipova)" },
  { judet: "Bacau", localitate: "Valea Caselor" },
  { judet: "Bacau", localitate: "Valea Hogei" },
  { judet: "Bacau", localitate: "Valea Marului" },
  { judet: "Bacau", localitate: "Valea Mosneagului" },
  { judet: "Bacau", localitate: "Balaneasa" },
  { judet: "Bacau", localitate: "Orasa" },
  { judet: "Bacau", localitate: "Poiana (Livezi)" },
  { judet: "Bacau", localitate: "Prajoaia" },
  { judet: "Bacau", localitate: "Scariga" },
  { judet: "Bacau", localitate: "Osebiti" },
  { judet: "Bacau", localitate: "Prajesti (Magiresti)" },
  { judet: "Bacau", localitate: "Stanesti" },
  { judet: "Bacau", localitate: "Sesuri" },
  { judet: "Bacau", localitate: "Valea Arinilor" },
  { judet: "Bacau", localitate: "Crihan" },
  { judet: "Bacau", localitate: "Dealu Mare" },
  { judet: "Bacau", localitate: "Sohodol" },
  { judet: "Bacau", localitate: "Lupesti" },
  { judet: "Bacau", localitate: "Parvulesti" },
  { judet: "Bacau", localitate: "Scutaru" },
  { judet: "Bacau", localitate: "Barati" },
  { judet: "Bacau", localitate: "Luncani" },
  { judet: "Bacau", localitate: "Padureni (Margineni)" },
  { judet: "Bacau", localitate: "Podis" },
  { judet: "Bacau", localitate: "Poiana (Margineni)" },
  { judet: "Bacau", localitate: "Trebes" },
  { judet: "Bacau", localitate: "Valea Budului" },
  { judet: "Bacau", localitate: "Baclesti" },
  { judet: "Bacau", localitate: "Chetreni" },
  { judet: "Bacau", localitate: "Chicerea" },
  { judet: "Bacau", localitate: "Cociu" },
  { judet: "Bacau", localitate: "Cornatelu" },
  { judet: "Bacau", localitate: "Fantanele (Motoseni)" },
  { judet: "Bacau", localitate: "Fundatura" },
  { judet: "Bacau", localitate: "Gura Craiesti" },
  { judet: "Bacau", localitate: "Poiana (Motoseni)" },
  { judet: "Bacau", localitate: "Praja" },
  { judet: "Bacau", localitate: "Rotaria" },
  { judet: "Bacau", localitate: "Sendresti" },
  { judet: "Bacau", localitate: "Tepoaia" },
  { judet: "Bacau", localitate: "Brad (Negri)" },
  { judet: "Bacau", localitate: "Calinesti" },
  { judet: "Bacau", localitate: "Magla" },
  { judet: "Bacau", localitate: "Poiana (Negri)" },
  { judet: "Bacau", localitate: "Ursoaia" },
  { judet: "Bacau", localitate: "Baltata" },
  { judet: "Bacau", localitate: "Buchila" },
  { judet: "Bacau", localitate: "Galbeni (Nicolae Balcescu)" },
  { judet: "Bacau", localitate: "Larguta" },
  { judet: "Bacau", localitate: "Sarata (Nicolae Balcescu)" },
  { judet: "Bacau", localitate: "Valea Seaca (Nicolae Balcescu)" },
  { judet: "Bacau", localitate: "Calcai" },
  { judet: "Bacau", localitate: "Ferestrau-Oituz" },
  { judet: "Bacau", localitate: "Harja" },
  { judet: "Bacau", localitate: "Marginea (Oituz)" },
  { judet: "Bacau", localitate: "Poiana Sarata" },
  { judet: "Bacau", localitate: "Barboasa" },
  { judet: "Bacau", localitate: "Dealu Perjului" },
  { judet: "Bacau", localitate: "Oncestii Vechi" },
  { judet: "Bacau", localitate: "Satu Nou (Oncesti)" },
  { judet: "Bacau", localitate: "Tarnita" },
  { judet: "Bacau", localitate: "Taula" },
  { judet: "Bacau", localitate: "Scurta" },
  { judet: "Bacau", localitate: "Cadaresti" },
  { judet: "Bacau", localitate: "Ciughes" },
  { judet: "Bacau", localitate: "Pajistea" },
  { judet: "Bacau", localitate: "Popoiu" },
  { judet: "Bacau", localitate: "Dragusani" },
  { judet: "Bacau", localitate: "Radoaia" },
  { judet: "Bacau", localitate: "Teius" },
  { judet: "Bacau", localitate: "Barna" },
  { judet: "Bacau", localitate: "Milestii de Jos" },
  { judet: "Bacau", localitate: "Milestii de Sus" },
  { judet: "Bacau", localitate: "Nanesti" },
  { judet: "Bacau", localitate: "Nastaseni" },
  { judet: "Bacau", localitate: "Poieni (Parincea)" },
  { judet: "Bacau", localitate: "Satu Nou (Parincea)" },
  { judet: "Bacau", localitate: "Valeni (Parincea)" },
  { judet: "Bacau", localitate: "Vladnic" },
  { judet: "Bacau", localitate: "Chilia Benei" },
  { judet: "Bacau", localitate: "Dienet" },
  { judet: "Bacau", localitate: "Fulgeris" },
  { judet: "Bacau", localitate: "Fundu Vaii" },
  { judet: "Bacau", localitate: "Motoc" },
  { judet: "Bacau", localitate: "Petresti" },
  { judet: "Bacau", localitate: "Soci" },
  { judet: "Bacau", localitate: "Bahna" },
  { judet: "Bacau", localitate: "Nicoresti" },
  { judet: "Bacau", localitate: "Parau Boghii" },
  { judet: "Bacau", localitate: "Satu Nou (Pargaresti)" },
  { judet: "Bacau", localitate: "Bahnaseni" },
  { judet: "Bacau", localitate: "Barnesti" },
  { judet: "Bacau", localitate: "Basasti" },
  { judet: "Bacau", localitate: "Campeni" },
  { judet: "Bacau", localitate: "Haineala" },
  { judet: "Bacau", localitate: "Pustiana" },
  { judet: "Bacau", localitate: "Tarata" },
  { judet: "Bacau", localitate: "Hemieni" },
  { judet: "Bacau", localitate: "Budesti" },
  { judet: "Bacau", localitate: "Dorneni (Plopana)" },
  { judet: "Bacau", localitate: "Fundu Tutovei" },
  { judet: "Bacau", localitate: "Itcani" },
  { judet: "Bacau", localitate: "Rusenii Razesi" },
  { judet: "Bacau", localitate: "Rusenii de Sus" },
  { judet: "Bacau", localitate: "Straminoasa" },
  { judet: "Bacau", localitate: "Tagara" },
  { judet: "Bacau", localitate: "Balanesti (Podu Turcului)" },
  { judet: "Bacau", localitate: "Cabesti" },
  { judet: "Bacau", localitate: "Fichitesti" },
  { judet: "Bacau", localitate: "Giurgioana" },
  { judet: "Bacau", localitate: "Hanta" },
  { judet: "Bacau", localitate: "Lehancea" },
  { judet: "Bacau", localitate: "Plopu (Podu Turcului)" },
  { judet: "Bacau", localitate: "Racusana" },
  { judet: "Bacau", localitate: "Sarbi" },
  { judet: "Bacau", localitate: "Bucsesti" },
  { judet: "Bacau", localitate: "Cernu" },
  { judet: "Bacau", localitate: "Cornet" },
  { judet: "Bacau", localitate: "Negreni" },
  { judet: "Bacau", localitate: "Prohozesti" },
  { judet: "Bacau", localitate: "Valea Sosii" },
  { judet: "Bacau", localitate: "Gura Vaii (Racova)" },
  { judet: "Bacau", localitate: "Halmacioaia" },
  { judet: "Bacau", localitate: "Iliesi" },
  { judet: "Bacau", localitate: "Ciucani" },
  { judet: "Bacau", localitate: "Fundu Racaciuni" },
  { judet: "Bacau", localitate: "Gasteni" },
  { judet: "Bacau", localitate: "Gheorghe Doja" },
  { judet: "Bacau", localitate: "Rastoaca" },
  { judet: "Bacau", localitate: "Barcana" },
  { judet: "Bacau", localitate: "Bucsa" },
  { judet: "Bacau", localitate: "Buda (Rachitoasa)" },
  { judet: "Bacau", localitate: "Burdusaci" },
  { judet: "Bacau", localitate: "Danaila" },
  { judet: "Bacau", localitate: "Dumbrava (Rachitoasa)" },
  { judet: "Bacau", localitate: "Farcasa" },
  { judet: "Bacau", localitate: "Fundatura Rachitoasa" },
  { judet: "Bacau", localitate: "Haghiac (Rachitoasa)" },
  { judet: "Bacau", localitate: "Magazia" },
  { judet: "Bacau", localitate: "Movilita" },
  { judet: "Bacau", localitate: "Oprisesti" },
  { judet: "Bacau", localitate: "Putini" },
  { judet: "Bacau", localitate: "Tochilea" },
  { judet: "Bacau", localitate: "Misihanesti" },
  { judet: "Bacau", localitate: "Neguseni" },
  { judet: "Bacau", localitate: "Poieni (Rosiori)" },
  { judet: "Bacau", localitate: "Valea Mare (Rosiori)" },
  { judet: "Bacau", localitate: "Valea Mica (Rosiori)" },
  { judet: "Bacau", localitate: "Beresti" },
  { judet: "Bacau", localitate: "Contesti" },
  { judet: "Bacau", localitate: "Pancesti (Sascut)" },
  { judet: "Bacau", localitate: "Sascut-Sat" },
  { judet: "Bacau", localitate: "Schineni (Sascut)" },
  { judet: "Bacau", localitate: "Valea Nacului" },
  { judet: "Bacau", localitate: "Barzulesti" },
  { judet: "Bacau", localitate: "Coman" },
  { judet: "Bacau", localitate: "Mateiesti" },
  { judet: "Bacau", localitate: "Stufu" },
  { judet: "Bacau", localitate: "Tisa" },
  { judet: "Bacau", localitate: "Versesti" },
  { judet: "Bacau", localitate: "Bogdan Voda" },
  { judet: "Bacau", localitate: "Costei" },
  { judet: "Bacau", localitate: "Schineni (Saucesti)" },
  { judet: "Bacau", localitate: "Siretu (Saucesti)" },
  { judet: "Bacau", localitate: "Serbesti" },
  { judet: "Bacau", localitate: "Bogdanesti (Scorteni)" },
  { judet: "Bacau", localitate: "Floresti (Scorteni)" },
  { judet: "Bacau", localitate: "Grigoreni" },
  { judet: "Bacau", localitate: "Stejaru" },
  { judet: "Bacau", localitate: "Serpeni" },
  { judet: "Bacau", localitate: "Balusa" },
  { judet: "Bacau", localitate: "Berbinceni" },
  { judet: "Bacau", localitate: "Chiticeni" },
  { judet: "Bacau", localitate: "Ciuturesti" },
  { judet: "Bacau", localitate: "Fundeni" },
  { judet: "Bacau", localitate: "Glodisoarele" },
  { judet: "Bacau", localitate: "Tisa-Silvestri" },
  { judet: "Bacau", localitate: "Valea Fanatului" },
  { judet: "Bacau", localitate: "Valeni (Secuieni)" },
  { judet: "Bacau", localitate: "Cucuieti (Solont)" },
  { judet: "Bacau", localitate: "Sarata (Solont)" },
  { judet: "Bacau", localitate: "Balotesti" },
  { judet: "Bacau", localitate: "Belciuneasa" },
  { judet: "Bacau", localitate: "Benesti" },
  { judet: "Bacau", localitate: "Craiesti" },
  { judet: "Bacau", localitate: "Gorghesti" },
  { judet: "Bacau", localitate: "Slobozia (Stanisesti)" },
  { judet: "Bacau", localitate: "Slobozia Noua" },
  { judet: "Bacau", localitate: "Valeni (Stanisesti)" },
  { judet: "Bacau", localitate: "Cetatuia" },
  { judet: "Bacau", localitate: "Iaz" },
  { judet: "Bacau", localitate: "Nadisa" },
  { judet: "Bacau", localitate: "Petricica" },
  { judet: "Bacau", localitate: "Rachitisu" },
  { judet: "Bacau", localitate: "Bogdana" },
  { judet: "Bacau", localitate: "Gutinas" },
  { judet: "Bacau", localitate: "Negoiesti" },
  { judet: "Bacau", localitate: "Racauti" },
  { judet: "Bacau", localitate: "Radeana" },
  { judet: "Bacau", localitate: "Viisoara (Stefan cel Mare)" },
  { judet: "Bacau", localitate: "Chetris" },
  { judet: "Bacau", localitate: "Furnicari" },
  { judet: "Bacau", localitate: "Cornii de Jos" },
  { judet: "Bacau", localitate: "Cornii de Sus" },
  { judet: "Bacau", localitate: "Dragesti (Tatarasti)" },
  { judet: "Bacau", localitate: "Gherdana" },
  { judet: "Bacau", localitate: "Giurgeni" },
  { judet: "Bacau", localitate: "Ungureni (Tatarasti)" },
  { judet: "Bacau", localitate: "Tuta" },
  { judet: "Bacau", localitate: "Viisoara (Targu Trotus)" },
  { judet: "Bacau", localitate: "Bogdanesti (Traian)" },
  { judet: "Bacau", localitate: "Hertioana de Jos" },
  { judet: "Bacau", localitate: "Hertioana-Razesi" },
  { judet: "Bacau", localitate: "Zapodia (Traian)" },
  { judet: "Bacau", localitate: "Bartasesti" },
  { judet: "Bacau", localitate: "Bibiresti" },
  { judet: "Bacau", localitate: "Bota" },
  { judet: "Bacau", localitate: "Botesti" },
  { judet: "Bacau", localitate: "Garla Anei" },
  { judet: "Bacau", localitate: "Viforeni" },
  { judet: "Bacau", localitate: "Zlatari" },
  { judet: "Bacau", localitate: "Cornatel" },
  { judet: "Bacau", localitate: "Lunca Dochiei" },
  { judet: "Bacau", localitate: "Satu Nou (Urechesti)" },
  { judet: "Bacau", localitate: "Slobozia (Urechesti)" },
  { judet: "Bacau", localitate: "Cucova" },
  { judet: "Bacau", localitate: "Bosia" },
  { judet: "Bacau", localitate: "Dadesti" },
  { judet: "Bacau", localitate: "Dorneni (Vultureni)" },
  { judet: "Bacau", localitate: "Ghilavesti" },
  { judet: "Bacau", localitate: "Godinestii de Jos" },
  { judet: "Bacau", localitate: "Godinestii de Sus" },
  { judet: "Bacau", localitate: "Lichitiseni" },
  { judet: "Bacau", localitate: "Medeleni" },
  { judet: "Bacau", localitate: "Nazarioaia" },
  { judet: "Bacau", localitate: "Reprivat" },
  { judet: "Bacau", localitate: "Tomozia" },
  { judet: "Bacau", localitate: "Tiganesti" },
  { judet: "Bacau", localitate: "Valea Lupului" },
  { judet: "Bacau", localitate: "Valea Merilor" },
  { judet: "Bacau", localitate: "Valea Salciei" },
  { judet: "Bacau", localitate: "Bolatau" },
  { judet: "Bihor", localitate: "Alesd" },
  { judet: "Bihor", localitate: "Oradea" },
  { judet: "Bihor", localitate: "Salonta" },
  { judet: "Bihor", localitate: "Stei" },
  { judet: "Bihor", localitate: "Beius" },
  { judet: "Bihor", localitate: "Marghita" },
  { judet: "Bihor", localitate: "Valea lui Mihai" },
  { judet: "Bihor", localitate: "Sacueni" },
  { judet: "Bihor", localitate: "Suplacu de Barcau" },
  { judet: "Bihor", localitate: "Bors" },
  { judet: "Bihor", localitate: "Nucet" },
  { judet: "Bihor", localitate: "Vascau" },
  { judet: "Bihor", localitate: "Abram" },
  { judet: "Bihor", localitate: "Abramut" },
  { judet: "Bihor", localitate: "Astileu" },
  { judet: "Bihor", localitate: "Auseu" },
  { judet: "Bihor", localitate: "Avram Iancu" },
  { judet: "Bihor", localitate: "Balc" },
  { judet: "Bihor", localitate: "Batar" },
  { judet: "Bihor", localitate: "Biharia" },
  { judet: "Bihor", localitate: "Boianu Mare" },
  { judet: "Bihor", localitate: "Borod" },
  { judet: "Bihor", localitate: "Bratca" },
  { judet: "Bihor", localitate: "Brusturi" },
  { judet: "Bihor", localitate: "Budureasa" },
  { judet: "Bihor", localitate: "Buduslau" },
  { judet: "Bihor", localitate: "Bulz" },
  { judet: "Bihor", localitate: "Buntesti" },
  { judet: "Bihor", localitate: "Cabesti" },
  { judet: "Bihor", localitate: "Capalna" },
  { judet: "Bihor", localitate: "Carpinet" },
  { judet: "Bihor", localitate: "Cefa" },
  { judet: "Bihor", localitate: "Ceica" },
  { judet: "Bihor", localitate: "Cetariu" },
  { judet: "Bihor", localitate: "Cherechiu" },
  { judet: "Bihor", localitate: "Chislaz" },
  { judet: "Bihor", localitate: "Ciuhoi" },
  { judet: "Bihor", localitate: "Ciumeghiu" },
  { judet: "Bihor", localitate: "Campani" },
  { judet: "Bihor", localitate: "Cociuba Mare" },
  { judet: "Bihor", localitate: "Copacel" },
  { judet: "Bihor", localitate: "Cristioru de Jos" },
  { judet: "Bihor", localitate: "Curatele" },
  { judet: "Bihor", localitate: "Derna" },
  { judet: "Bihor", localitate: "Diosig" },
  { judet: "Bihor", localitate: "Dobresti" },
  { judet: "Bihor", localitate: "Draganesti" },
  { judet: "Bihor", localitate: "Dragesti" },
  { judet: "Bihor", localitate: "Finis" },
  { judet: "Bihor", localitate: "Gepiu" },
  { judet: "Bihor", localitate: "Girisu de Cris" },
  { judet: "Bihor", localitate: "Hidiselu de Sus" },
  { judet: "Bihor", localitate: "Holod" },
  { judet: "Bihor", localitate: "Husasau de Tinca" },
  { judet: "Bihor", localitate: "Ineu" },
  { judet: "Bihor", localitate: "Lazuri de Beius" },
  { judet: "Bihor", localitate: "Lazareni" },
  { judet: "Bihor", localitate: "Lugasu de Jos" },
  { judet: "Bihor", localitate: "Lunca" },
  { judet: "Bihor", localitate: "Madaras" },
  { judet: "Bihor", localitate: "Magesti" },
  { judet: "Bihor", localitate: "Nojorid" },
  { judet: "Bihor", localitate: "Olcea" },
  { judet: "Bihor", localitate: "Osorhei" },
  { judet: "Bihor", localitate: "Paleu" },
  { judet: "Bihor", localitate: "Pietroasa" },
  { judet: "Bihor", localitate: "Pocola" },
  { judet: "Bihor", localitate: "Pomezeu" },
  { judet: "Bihor", localitate: "Popesti" },
  { judet: "Bihor", localitate: "Rabagani" },
  { judet: "Bihor", localitate: "Remetea" },
  { judet: "Bihor", localitate: "Rieni" },
  { judet: "Bihor", localitate: "Rosia" },
  { judet: "Bihor", localitate: "Rosiori" },
  { judet: "Bihor", localitate: "Sacadat" },
  { judet: "Bihor", localitate: "Salacea" },
  { judet: "Bihor", localitate: "Salard" },
  { judet: "Bihor", localitate: "Sambata" },
  { judet: "Bihor", localitate: "Sanmartin" },
  { judet: "Bihor", localitate: "Sannicolau Roman" },
  { judet: "Bihor", localitate: "Santandrei" },
  { judet: "Bihor", localitate: "Sarbi" },
  { judet: "Bihor", localitate: "Spinus" },
  { judet: "Bihor", localitate: "Simian" },
  { judet: "Bihor", localitate: "Sinteu" },
  { judet: "Bihor", localitate: "Soimi" },
  { judet: "Bihor", localitate: "Suncuius" },
  { judet: "Bihor", localitate: "Tarcea" },
  { judet: "Bihor", localitate: "Tamaseu" },
  { judet: "Bihor", localitate: "Tarcaia" },
  { judet: "Bihor", localitate: "Tauteu" },
  { judet: "Bihor", localitate: "Tileagd" },
  { judet: "Bihor", localitate: "Tinca" },
  { judet: "Bihor", localitate: "Tulca" },
  { judet: "Bihor", localitate: "Tetchea" },
  { judet: "Bihor", localitate: "Uileacu de Beius" },
  { judet: "Bihor", localitate: "Vadu Crisului" },
  { judet: "Bihor", localitate: "Viisoara" },
  { judet: "Bihor", localitate: "Varciorog" },
  { judet: "Bihor", localitate: "Baile Felix" },
  { judet: "Bihor", localitate: "Baile 1 Mai" },
  { judet: "Bihor", localitate: "Padurea Neagra" },
  { judet: "Bihor", localitate: "Pestis" },
  { judet: "Bihor", localitate: "Tinaud" },
  { judet: "Bihor", localitate: "Delani" },
  { judet: "Bihor", localitate: "Chet" },
  { judet: "Bihor", localitate: "Ghenetea" },
  { judet: "Bihor", localitate: "Baita" },
  { judet: "Bihor", localitate: "Baita-Plai" },
  { judet: "Bihor", localitate: "Camp" },
  { judet: "Bihor", localitate: "Camp-Moti" },
  { judet: "Bihor", localitate: "Colesti" },
  { judet: "Bihor", localitate: "Varzarii de Jos" },
  { judet: "Bihor", localitate: "Varzarii de Sus" },
  { judet: "Bihor", localitate: "Cohani" },
  { judet: "Bihor", localitate: "Dijir" },
  { judet: "Bihor", localitate: "Iteu" },
  { judet: "Bihor", localitate: "Iteu Nou" },
  { judet: "Bihor", localitate: "Margine" },
  { judet: "Bihor", localitate: "Satu Barba" },
  { judet: "Bihor", localitate: "Suiug" },
  { judet: "Bihor", localitate: "Crestur" },
  { judet: "Bihor", localitate: "Fancica" },
  { judet: "Bihor", localitate: "Petreu" },
  { judet: "Bihor", localitate: "Calatea" },
  { judet: "Bihor", localitate: "Chistag" },
  { judet: "Bihor", localitate: "Pestere" },
  { judet: "Bihor", localitate: "Cacuciu Vechi" },
  { judet: "Bihor", localitate: "Codrisoru" },
  { judet: "Bihor", localitate: "Gheghie" },
  { judet: "Bihor", localitate: "Grosi" },
  { judet: "Bihor", localitate: "Luncsoara" },
  { judet: "Bihor", localitate: "Ant" },
  { judet: "Bihor", localitate: "Tamasda" },
  { judet: "Bihor", localitate: "Almasu Mare" },
  { judet: "Bihor", localitate: "Almasu Mic (Balc)" },
  { judet: "Bihor", localitate: "Ghida" },
  { judet: "Bihor", localitate: "Saldabagiu de Barcau" },
  { judet: "Bihor", localitate: "Arpasel" },
  { judet: "Bihor", localitate: "Talpos" },
  { judet: "Bihor", localitate: "Taut" },
  { judet: "Bihor", localitate: "Cauaceu" },
  { judet: "Bihor", localitate: "Niuved" },
  { judet: "Bihor", localitate: "Parhida" },
  { judet: "Bihor", localitate: "Satu Nou" },
  { judet: "Bihor", localitate: "Corboaia" },
  { judet: "Bihor", localitate: "Huta" },
  { judet: "Bihor", localitate: "Pagaia" },
  { judet: "Bihor", localitate: "Rugea" },
  { judet: "Bihor", localitate: "Borozel" },
  { judet: "Bihor", localitate: "Cetea" },
  { judet: "Bihor", localitate: "Cornitel" },
  { judet: "Bihor", localitate: "Serani" },
  { judet: "Bihor", localitate: "Valea Mare de Cris" },
  { judet: "Bihor", localitate: "Santaul Mare" },
  { judet: "Bihor", localitate: "Santaul Mic" },
  { judet: "Bihor", localitate: "Santion" },
  { judet: "Bihor", localitate: "Beznea" },
  { judet: "Bihor", localitate: "Damis" },
  { judet: "Bihor", localitate: "Lorau" },
  { judet: "Bihor", localitate: "Ponoara" },
  { judet: "Bihor", localitate: "Valea Crisului" },
  { judet: "Bihor", localitate: "Cuiesd" },
  { judet: "Bihor", localitate: "Loranta" },
  { judet: "Bihor", localitate: "Orvisele" },
  { judet: "Bihor", localitate: "Paulesti" },
  { judet: "Bihor", localitate: "Picleu" },
  { judet: "Bihor", localitate: "Tiganestii de Cris" },
  { judet: "Bihor", localitate: "Varasau" },
  { judet: "Bihor", localitate: "Burda" },
  { judet: "Bihor", localitate: "Saca" },
  { judet: "Bihor", localitate: "Saliste de Beius" },
  { judet: "Bihor", localitate: "Teleac" },
  { judet: "Bihor", localitate: "Albis" },
  { judet: "Bihor", localitate: "Baraj Lesu" },
  { judet: "Bihor", localitate: "Munteni" },
  { judet: "Bihor", localitate: "Remeti" },
  { judet: "Bihor", localitate: "Valea Iadului" },
  { judet: "Bihor", localitate: "Bradet" },
  { judet: "Bihor", localitate: "Dumbravani" },
  { judet: "Bihor", localitate: "Ferice" },
  { judet: "Bihor", localitate: "Lelesti" },
  { judet: "Bihor", localitate: "Poienii de Jos" },
  { judet: "Bihor", localitate: "Poienii de Sus" },
  { judet: "Bihor", localitate: "Saud" },
  { judet: "Bihor", localitate: "Stancesti" },
  { judet: "Bihor", localitate: "Goila" },
  { judet: "Bihor", localitate: "Gurbesti (Cabesti)" },
  { judet: "Bihor", localitate: "Josani (Cabesti)" },
  { judet: "Bihor", localitate: "Sohodol" },
  { judet: "Bihor", localitate: "Ginta" },
  { judet: "Bihor", localitate: "Rohani" },
  { judet: "Bihor", localitate: "Saldabagiu Mic" },
  { judet: "Bihor", localitate: "Suplacu de Tinca" },
  { judet: "Bihor", localitate: "Calugari" },
  { judet: "Bihor", localitate: "Izbuc" },
  { judet: "Bihor", localitate: "Leheceni" },
  { judet: "Bihor", localitate: "Fanate" },
  { judet: "Bihor", localitate: "Harsesti" },
  { judet: "Bihor", localitate: "Sighistel" },
  { judet: "Bihor", localitate: "Valea de Sus" },
  { judet: "Bihor", localitate: "Ateas" },
  { judet: "Bihor", localitate: "Berechiu" },
  { judet: "Bihor", localitate: "Bicaci" },
  { judet: "Bihor", localitate: "Inand" },
  { judet: "Bihor", localitate: "Roit" },
  { judet: "Bihor", localitate: "Bucium" },
  { judet: "Bihor", localitate: "Ceisoara" },
  { judet: "Bihor", localitate: "Corbesti" },
  { judet: "Bihor", localitate: "Cotiglet" },
  { judet: "Bihor", localitate: "Dusesti" },
  { judet: "Bihor", localitate: "Incesti" },
  { judet: "Bihor", localitate: "Saldabagiu de Munte" },
  { judet: "Bihor", localitate: "Sisterea" },
  { judet: "Bihor", localitate: "Susturogi" },
  { judet: "Bihor", localitate: "Tautelec" },
  { judet: "Bihor", localitate: "Uileacu de Munte" },
  { judet: "Bihor", localitate: "Chesereu" },
  { judet: "Bihor", localitate: "Targusor" },
  { judet: "Bihor", localitate: "Chiraleu" },
  { judet: "Bihor", localitate: "Haucesti" },
  { judet: "Bihor", localitate: "Misca" },
  { judet: "Bihor", localitate: "Poclusa de Barcau" },
  { judet: "Bihor", localitate: "Sarsig" },
  { judet: "Bihor", localitate: "Sanlazar" },
  { judet: "Bihor", localitate: "Cenalos" },
  { judet: "Bihor", localitate: "Saniob" },
  { judet: "Bihor", localitate: "Sfarnas" },
  { judet: "Bihor", localitate: "Boiu" },
  { judet: "Bihor", localitate: "Ghiorac" },
  { judet: "Bihor", localitate: "Carasau" },
  { judet: "Bihor", localitate: "Chesa" },
  { judet: "Bihor", localitate: "Petid" },
  { judet: "Bihor", localitate: "Bucuroaia" },
  { judet: "Bihor", localitate: "Chijic" },
  { judet: "Bihor", localitate: "Poiana Tasad" },
  { judet: "Bihor", localitate: "Sarand" },
  { judet: "Bihor", localitate: "Surduc" },
  { judet: "Bihor", localitate: "Cristioru de Sus" },
  { judet: "Bihor", localitate: "Poiana (Cristioru de Jos)" },
  { judet: "Bihor", localitate: "Saliste de Vascau" },
  { judet: "Bihor", localitate: "Beiusele" },
  { judet: "Bihor", localitate: "Cresuia" },
  { judet: "Bihor", localitate: "Nimaiesti" },
  { judet: "Bihor", localitate: "Pocioveliste" },
  { judet: "Bihor", localitate: "Curtuiseni" },
  { judet: "Bihor", localitate: "Vasad" },
  { judet: "Bihor", localitate: "Dernisoara" },
  { judet: "Bihor", localitate: "Sacalasau" },
  { judet: "Bihor", localitate: "Sacalasau Nou" },
  { judet: "Bihor", localitate: "Tria" },
  { judet: "Bihor", localitate: "Ianca" },
  { judet: "Bihor", localitate: "Mihai Bravu" },
  { judet: "Bihor", localitate: "Vaida" },
  { judet: "Bihor", localitate: "Cornisesti" },
  { judet: "Bihor", localitate: "Crancesti" },
  { judet: "Bihor", localitate: "Hidisel" },
  { judet: "Bihor", localitate: "Luncasprie" },
  { judet: "Bihor", localitate: "Racas" },
  { judet: "Bihor", localitate: "Topa de Jos" },
  { judet: "Bihor", localitate: "Topa de Sus" },
  { judet: "Bihor", localitate: "Belejeni" },
  { judet: "Bihor", localitate: "Gradinari" },
  { judet: "Bihor", localitate: "Livada Beiusului" },
  { judet: "Bihor", localitate: "Mizies" },
  { judet: "Bihor", localitate: "Pacalesti" },
  { judet: "Bihor", localitate: "Pantasesti" },
  { judet: "Bihor", localitate: "Sebis" },
  { judet: "Bihor", localitate: "Talpe" },
  { judet: "Bihor", localitate: "Tiganestii de Beius" },
  { judet: "Bihor", localitate: "Dicanesti" },
  { judet: "Bihor", localitate: "Stracos" },
  { judet: "Bihor", localitate: "Tasad" },
  { judet: "Bihor", localitate: "Topesti" },
  { judet: "Bihor", localitate: "Brusturi (Finis)" },
  { judet: "Bihor", localitate: "Fizis" },
  { judet: "Bihor", localitate: "Ioanis" },
  { judet: "Bihor", localitate: "Suncuis" },
  { judet: "Bihor", localitate: "Cheresig" },
  { judet: "Bihor", localitate: "Tarian" },
  { judet: "Bihor", localitate: "Toboliu" },
  { judet: "Bihor", localitate: "Hidiselu de Jos" },
  { judet: "Bihor", localitate: "Mierlau" },
  { judet: "Bihor", localitate: "Santelec" },
  { judet: "Bihor", localitate: "Sumugiu" },
  { judet: "Bihor", localitate: "Dumbrava" },
  { judet: "Bihor", localitate: "Dumbravita" },
  { judet: "Bihor", localitate: "Forosig" },
  { judet: "Bihor", localitate: "Hodis" },
  { judet: "Bihor", localitate: "Lupoaia" },
  { judet: "Bihor", localitate: "Valea Mare de Codru" },
  { judet: "Bihor", localitate: "Vintere" },
  { judet: "Bihor", localitate: "Fonau" },
  { judet: "Bihor", localitate: "Miersig" },
  { judet: "Bihor", localitate: "Osand" },
  { judet: "Bihor", localitate: "Sititelec" },
  { judet: "Bihor", localitate: "Botean" },
  { judet: "Bihor", localitate: "Husasau de Cris" },
  { judet: "Bihor", localitate: "Baleni" },
  { judet: "Bihor", localitate: "Cusuius" },
  { judet: "Bihor", localitate: "Hinchiris" },
  { judet: "Bihor", localitate: "Bicacel" },
  { judet: "Bihor", localitate: "Calea Mare" },
  { judet: "Bihor", localitate: "Carandeni" },
  { judet: "Bihor", localitate: "Caranzel" },
  { judet: "Bihor", localitate: "Gepis" },
  { judet: "Bihor", localitate: "Gruilung" },
  { judet: "Bihor", localitate: "Miheleu" },
  { judet: "Bihor", localitate: "Lugasu de Sus" },
  { judet: "Bihor", localitate: "Urvind" },
  { judet: "Bihor", localitate: "Briheni" },
  { judet: "Bihor", localitate: "Hotarel" },
  { judet: "Bihor", localitate: "Sarbesti" },
  { judet: "Bihor", localitate: "Seghiste" },
  { judet: "Bihor", localitate: "Sustiu" },
  { judet: "Bihor", localitate: "Homorog" },
  { judet: "Bihor", localitate: "Ianosda" },
  { judet: "Bihor", localitate: "Martihaz" },
  { judet: "Bihor", localitate: "Butani" },
  { judet: "Bihor", localitate: "Cacuciu Nou" },
  { judet: "Bihor", localitate: "Dobricionesti" },
  { judet: "Bihor", localitate: "Galaseni" },
  { judet: "Bihor", localitate: "Josani (Magesti)" },
  { judet: "Bihor", localitate: "Ortiteag" },
  { judet: "Bihor", localitate: "Apateu" },
  { judet: "Bihor", localitate: "Chisirid" },
  { judet: "Bihor", localitate: "Les" },
  { judet: "Bihor", localitate: "Livada de Bihor" },
  { judet: "Bihor", localitate: "Pausa" },
  { judet: "Bihor", localitate: "Sauaieu" },
  { judet: "Bihor", localitate: "Calacea" },
  { judet: "Bihor", localitate: "Hodisel" },
  { judet: "Bihor", localitate: "Ucuris" },
  { judet: "Bihor", localitate: "Alparea" },
  { judet: "Bihor", localitate: "Cheriu" },
  { judet: "Bihor", localitate: "Felcheriu" },
  { judet: "Bihor", localitate: "Fughiu" },
  { judet: "Bihor", localitate: "Chiscau" },
  { judet: "Bihor", localitate: "Cociuba Mica" },
  { judet: "Bihor", localitate: "Giulesti" },
  { judet: "Bihor", localitate: "Gurani" },
  { judet: "Bihor", localitate: "Magura" },
  { judet: "Bihor", localitate: "Motesti" },
  { judet: "Bihor", localitate: "Feneris" },
  { judet: "Bihor", localitate: "Petrani" },
  { judet: "Bihor", localitate: "Poietari" },
  { judet: "Bihor", localitate: "Sanmartin de Beius" },
  { judet: "Bihor", localitate: "Campani de Pomezeu" },
  { judet: "Bihor", localitate: "Cosdeni" },
  { judet: "Bihor", localitate: "Hidis" },
  { judet: "Bihor", localitate: "Lacu Sarat" },
  { judet: "Bihor", localitate: "Sitani" },
  { judet: "Bihor", localitate: "Spinus de Pomezeu" },
  { judet: "Bihor", localitate: "Valani de Pomezeu" },
  { judet: "Bihor", localitate: "Bistra" },
  { judet: "Bihor", localitate: "Budoi" },
  { judet: "Bihor", localitate: "Cuzap" },
  { judet: "Bihor", localitate: "Varviz" },
  { judet: "Bihor", localitate: "Varzari" },
  { judet: "Bihor", localitate: "Voivozi (Popesti)" },
  { judet: "Bihor", localitate: "Albesti" },
  { judet: "Bihor", localitate: "Bratesti" },
  { judet: "Bihor", localitate: "Saliste de Pomezeu" },
  { judet: "Bihor", localitate: "Saucani" },
  { judet: "Bihor", localitate: "Varaseni" },
  { judet: "Bihor", localitate: "Dragoteni" },
  { judet: "Bihor", localitate: "Meziad" },
  { judet: "Bihor", localitate: "Petreasa" },
  { judet: "Bihor", localitate: "Soimus" },
  { judet: "Bihor", localitate: "Cucuceni" },
  { judet: "Bihor", localitate: "Ghighiseni" },
  { judet: "Bihor", localitate: "Petrileni" },
  { judet: "Bihor", localitate: "Sudrigiu" },
  { judet: "Bihor", localitate: "Valea de Jos" },
  { judet: "Bihor", localitate: "Lazuri" },
  { judet: "Bihor", localitate: "Borsa" },
  { judet: "Bihor", localitate: "Sabolciu" },
  { judet: "Bihor", localitate: "Cadea" },
  { judet: "Bihor", localitate: "Ciocaia" },
  { judet: "Bihor", localitate: "Cubulcut" },
  { judet: "Bihor", localitate: "Olosig" },
  { judet: "Bihor", localitate: "Sannicolau de Munte" },
  { judet: "Bihor", localitate: "Otomani" },
  { judet: "Bihor", localitate: "Hodos" },
  { judet: "Bihor", localitate: "Santimreu" },
  { judet: "Bihor", localitate: "Copaceni" },
  { judet: "Bihor", localitate: "Ogesti" },
  { judet: "Bihor", localitate: "Rogoz" },
  { judet: "Bihor", localitate: "Rotaresti" },
  { judet: "Bihor", localitate: "Zavoiu" },
  { judet: "Bihor", localitate: "Betfia" },
  { judet: "Bihor", localitate: "Cihei" },
  { judet: "Bihor", localitate: "Cordau" },
  { judet: "Bihor", localitate: "Haieu" },
  { judet: "Bihor", localitate: "Rontau" },
  { judet: "Bihor", localitate: "Palota" },
  { judet: "Bihor", localitate: "Almasu Mic (Sarbi)" },
  { judet: "Bihor", localitate: "Burzuc" },
  { judet: "Bihor", localitate: "Chioag" },
  { judet: "Bihor", localitate: "Fegernic" },
  { judet: "Bihor", localitate: "Fegernicu Nou" },
  { judet: "Bihor", localitate: "Sarcau" },
  { judet: "Bihor", localitate: "Ciulesti" },
  { judet: "Bihor", localitate: "Gurbesti (Spinus)" },
  { judet: "Bihor", localitate: "Nadar" },
  { judet: "Bihor", localitate: "Saliste" },
  { judet: "Bihor", localitate: "Borumlaca" },
  { judet: "Bihor", localitate: "Dolea" },
  { judet: "Bihor", localitate: "Foglas" },
  { judet: "Bihor", localitate: "Valea Cerului" },
  { judet: "Bihor", localitate: "Valcelele" },
  { judet: "Bihor", localitate: "Silindru" },
  { judet: "Bihor", localitate: "Voivozi (Simian)" },
  { judet: "Bihor", localitate: "Huta Voivozi" },
  { judet: "Bihor", localitate: "Socet" },
  { judet: "Bihor", localitate: "Valea Tarnei" },
  { judet: "Bihor", localitate: "Borz" },
  { judet: "Bihor", localitate: "Codru" },
  { judet: "Bihor", localitate: "Dumbravita de Codru" },
  { judet: "Bihor", localitate: "Poclusa de Beius" },
  { judet: "Bihor", localitate: "Sannicolau de Beius" },
  { judet: "Bihor", localitate: "Ursad" },
  { judet: "Bihor", localitate: "Urvis de Beius" },
  { judet: "Bihor", localitate: "Balnaca" },
  { judet: "Bihor", localitate: "Balnaca-Grosi" },
  { judet: "Bihor", localitate: "Zece Hotare" },
  { judet: "Bihor", localitate: "Adoni" },
  { judet: "Bihor", localitate: "Galospetreu" },
  { judet: "Bihor", localitate: "Mierag" },
  { judet: "Bihor", localitate: "Tarcaita" },
  { judet: "Bihor", localitate: "Totoreni" },
  { judet: "Bihor", localitate: "Bogei" },
  { judet: "Bihor", localitate: "Chiribis" },
  { judet: "Bihor", localitate: "Ciutelec" },
  { judet: "Bihor", localitate: "Poiana (Tauteu)" },
  { judet: "Bihor", localitate: "Balaia" },
  { judet: "Bihor", localitate: "Calatani" },
  { judet: "Bihor", localitate: "Posoloaca" },
  { judet: "Bihor", localitate: "Tilecus" },
  { judet: "Bihor", localitate: "Uileacu de Cris" },
  { judet: "Bihor", localitate: "Belfir" },
  { judet: "Bihor", localitate: "Girisu Negru" },
  { judet: "Bihor", localitate: "Gurbediu" },
  { judet: "Bihor", localitate: "Rapa" },
  { judet: "Bihor", localitate: "Cauasd" },
  { judet: "Bihor", localitate: "Hotar" },
  { judet: "Bihor", localitate: "Subpiatra" },
  { judet: "Bihor", localitate: "Telechiu" },
  { judet: "Bihor", localitate: "Forau" },
  { judet: "Bihor", localitate: "Prisaca" },
  { judet: "Bihor", localitate: "Valanii de Beius" },
  { judet: "Bihor", localitate: "Birtin" },
  { judet: "Bihor", localitate: "Tomnatic" },
  { judet: "Bihor", localitate: "Topa de Cris" },
  { judet: "Bihor", localitate: "Fasca" },
  { judet: "Bihor", localitate: "Surducel" },
  { judet: "Bihor", localitate: "Serghis" },
  { judet: "Bihor", localitate: "Izvoarele" },
  { judet: "Bihor", localitate: "Padureni" },
  { judet: "Bihor", localitate: "Reghea" },
  { judet: "Bistrita Nasaud", localitate: "Bistrita" },
  { judet: "Bistrita Nasaud", localitate: "Beclean" },
  { judet: "Bistrita Nasaud", localitate: "Nasaud" },
  { judet: "Bistrita Nasaud", localitate: "Sangeorz-Bai" },
  { judet: "Bistrita Nasaud", localitate: "Bistrita Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Branistea" },
  { judet: "Bistrita Nasaud", localitate: "Budacu de Jos" },
  { judet: "Bistrita Nasaud", localitate: "Budesti" },
  { judet: "Bistrita Nasaud", localitate: "Caianu Mic" },
  { judet: "Bistrita Nasaud", localitate: "Cetate" },
  { judet: "Bistrita Nasaud", localitate: "Ciceu-Giurgesti" },
  { judet: "Bistrita Nasaud", localitate: "Ciceu-Mihaiesti" },
  { judet: "Bistrita Nasaud", localitate: "Chiochis" },
  { judet: "Bistrita Nasaud", localitate: "Chiuza" },
  { judet: "Bistrita Nasaud", localitate: "Cosbuc" },
  { judet: "Bistrita Nasaud", localitate: "Dumitra" },
  { judet: "Bistrita Nasaud", localitate: "Dumitrita" },
  { judet: "Bistrita Nasaud", localitate: "Feldru" },
  { judet: "Bistrita Nasaud", localitate: "Galatii Bistritei" },
  { judet: "Bistrita Nasaud", localitate: "Ilva Mare" },
  { judet: "Bistrita Nasaud", localitate: "Ilva Mica" },
  { judet: "Bistrita Nasaud", localitate: "Josenii Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Lechinta" },
  { judet: "Bistrita Nasaud", localitate: "Lesu" },
  { judet: "Bistrita Nasaud", localitate: "Livezile" },
  { judet: "Bistrita Nasaud", localitate: "Lunca Ilvei" },
  { judet: "Bistrita Nasaud", localitate: "Maieru" },
  { judet: "Bistrita Nasaud", localitate: "Matei" },
  { judet: "Bistrita Nasaud", localitate: "Magura Ilvei" },
  { judet: "Bistrita Nasaud", localitate: "Mariselu" },
  { judet: "Bistrita Nasaud", localitate: "Micestii de Campie" },
  { judet: "Bistrita Nasaud", localitate: "Milas" },
  { judet: "Bistrita Nasaud", localitate: "Monor" },
  { judet: "Bistrita Nasaud", localitate: "Negrilesti" },
  { judet: "Bistrita Nasaud", localitate: "Nimigea" },
  { judet: "Bistrita Nasaud", localitate: "Nuseni" },
  { judet: "Bistrita Nasaud", localitate: "Parva" },
  { judet: "Bistrita Nasaud", localitate: "Petru Rares" },
  { judet: "Bistrita Nasaud", localitate: "Poiana Ilvei" },
  { judet: "Bistrita Nasaud", localitate: "Prundu Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Rebra" },
  { judet: "Bistrita Nasaud", localitate: "Rebrisoara" },
  { judet: "Bistrita Nasaud", localitate: "Rodna" },
  { judet: "Bistrita Nasaud", localitate: "Romuli" },
  { judet: "Bistrita Nasaud", localitate: "Runcu Salvei" },
  { judet: "Bistrita Nasaud", localitate: "Salva" },
  { judet: "Bistrita Nasaud", localitate: "Sanmihaiu de Campie" },
  { judet: "Bistrita Nasaud", localitate: "Silivasu de Campie" },
  { judet: "Bistrita Nasaud", localitate: "Spermezeu" },
  { judet: "Bistrita Nasaud", localitate: "Sant" },
  { judet: "Bistrita Nasaud", localitate: "Sieu" },
  { judet: "Bistrita Nasaud", localitate: "Sieu-Magherus" },
  { judet: "Bistrita Nasaud", localitate: "Sieu-Odorhei" },
  { judet: "Bistrita Nasaud", localitate: "Sieut" },
  { judet: "Bistrita Nasaud", localitate: "Sintereag" },
  { judet: "Bistrita Nasaud", localitate: "Tarlisua" },
  { judet: "Bistrita Nasaud", localitate: "Teaca" },
  { judet: "Bistrita Nasaud", localitate: "Telciu" },
  { judet: "Bistrita Nasaud", localitate: "Tiha Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Uriu" },
  { judet: "Bistrita Nasaud", localitate: "Urmenis" },
  { judet: "Bistrita Nasaud", localitate: "Zagra" },
  { judet: "Bistrita Nasaud", localitate: "Reteag" },
  { judet: "Bistrita Nasaud", localitate: "Ghinda" },
  { judet: "Bistrita Nasaud", localitate: "Sarata" },
  { judet: "Bistrita Nasaud", localitate: "Sigmir" },
  { judet: "Bistrita Nasaud", localitate: "Slatinita" },
  { judet: "Bistrita Nasaud", localitate: "Unirea" },
  { judet: "Bistrita Nasaud", localitate: "Viisoara" },
  { judet: "Bistrita Nasaud", localitate: "Coldau" },
  { judet: "Bistrita Nasaud", localitate: "Figa" },
  { judet: "Bistrita Nasaud", localitate: "Rusu de Jos" },
  { judet: "Bistrita Nasaud", localitate: "Liviu Rebreanu" },
  { judet: "Bistrita Nasaud", localitate: "Lusca" },
  { judet: "Bistrita Nasaud", localitate: "Cormaia" },
  { judet: "Bistrita Nasaud", localitate: "Valea Borcutului" },
  { judet: "Bistrita Nasaud", localitate: "Bistrita Bargaului Fabrici" },
  { judet: "Bistrita Nasaud", localitate: "Colibita" },
  { judet: "Bistrita Nasaud", localitate: "Ciresoaia" },
  { judet: "Bistrita Nasaud", localitate: "Malut" },
  { judet: "Bistrita Nasaud", localitate: "Budus" },
  { judet: "Bistrita Nasaud", localitate: "Jelna" },
  { judet: "Bistrita Nasaud", localitate: "Monariu" },
  { judet: "Bistrita Nasaud", localitate: "Simionesti" },
  { judet: "Bistrita Nasaud", localitate: "Budesti-Fanate" },
  { judet: "Bistrita Nasaud", localitate: "Tagu" },
  { judet: "Bistrita Nasaud", localitate: "Tagsoru" },
  { judet: "Bistrita Nasaud", localitate: "Caianu Mare" },
  { judet: "Bistrita Nasaud", localitate: "Ciceu-Poieni" },
  { judet: "Bistrita Nasaud", localitate: "Dobric" },
  { judet: "Bistrita Nasaud", localitate: "Dobricel" },
  { judet: "Bistrita Nasaud", localitate: "Dumbravita" },
  { judet: "Bistrita Nasaud", localitate: "Budacu de Sus" },
  { judet: "Bistrita Nasaud", localitate: "Orheiu Bistritei" },
  { judet: "Bistrita Nasaud", localitate: "Petris" },
  { judet: "Bistrita Nasaud", localitate: "Ragla" },
  { judet: "Bistrita Nasaud", localitate: "Satu Nou" },
  { judet: "Bistrita Nasaud", localitate: "Apatiu" },
  { judet: "Bistrita Nasaud", localitate: "Bozies" },
  { judet: "Bistrita Nasaud", localitate: "Buza Catun" },
  { judet: "Bistrita Nasaud", localitate: "Chetiu" },
  { judet: "Bistrita Nasaud", localitate: "Jimbor" },
  { judet: "Bistrita Nasaud", localitate: "Manic" },
  { judet: "Bistrita Nasaud", localitate: "Sannicoara" },
  { judet: "Bistrita Nasaud", localitate: "Strugureni" },
  { judet: "Bistrita Nasaud", localitate: "Tentea" },
  { judet: "Bistrita Nasaud", localitate: "Mires" },
  { judet: "Bistrita Nasaud", localitate: "Piatra" },
  { judet: "Bistrita Nasaud", localitate: "Sasarm" },
  { judet: "Bistrita Nasaud", localitate: "Breaza" },
  { judet: "Bistrita Nasaud", localitate: "Dumbraveni" },
  { judet: "Bistrita Nasaud", localitate: "Purcarete" },
  { judet: "Bistrita Nasaud", localitate: "Bichigiu" },
  { judet: "Bistrita Nasaud", localitate: "Cepari" },
  { judet: "Bistrita Nasaud", localitate: "Tarpiu" },
  { judet: "Bistrita Nasaud", localitate: "Nepos" },
  { judet: "Bistrita Nasaud", localitate: "Albestii Bistritei" },
  { judet: "Bistrita Nasaud", localitate: "Dipsa" },
  { judet: "Bistrita Nasaud", localitate: "Herina" },
  { judet: "Bistrita Nasaud", localitate: "Tonciu" },
  { judet: "Bistrita Nasaud", localitate: "Ivaneasa" },
  { judet: "Bistrita Nasaud", localitate: "Mijlocenii Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Rusu Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Stramba" },
  { judet: "Bistrita Nasaud", localitate: "Bungard" },
  { judet: "Bistrita Nasaud", localitate: "Chirales" },
  { judet: "Bistrita Nasaud", localitate: "Sangeorzu Nou" },
  { judet: "Bistrita Nasaud", localitate: "Saniacob" },
  { judet: "Bistrita Nasaud", localitate: "Tigau" },
  { judet: "Bistrita Nasaud", localitate: "Vermes" },
  { judet: "Bistrita Nasaud", localitate: "Lunca Lesului" },
  { judet: "Bistrita Nasaud", localitate: "Cusma" },
  { judet: "Bistrita Nasaud", localitate: "Dorolea" },
  { judet: "Bistrita Nasaud", localitate: "Dumbrava (Livezile)" },
  { judet: "Bistrita Nasaud", localitate: "Valea Poenii" },
  { judet: "Bistrita Nasaud", localitate: "Anies" },
  { judet: "Bistrita Nasaud", localitate: "Bidiu" },
  { judet: "Bistrita Nasaud", localitate: "Corvinesti" },
  { judet: "Bistrita Nasaud", localitate: "Enciu" },
  { judet: "Bistrita Nasaud", localitate: "Fantanele" },
  { judet: "Bistrita Nasaud", localitate: "Morut" },
  { judet: "Bistrita Nasaud", localitate: "Arsita" },
  { judet: "Bistrita Nasaud", localitate: "Barla" },
  { judet: "Bistrita Nasaud", localitate: "Domnesti" },
  { judet: "Bistrita Nasaud", localitate: "Jeica" },
  { judet: "Bistrita Nasaud", localitate: "Magurele" },
  { judet: "Bistrita Nasaud", localitate: "Neteni" },
  { judet: "Bistrita Nasaud", localitate: "Santioana" },
  { judet: "Bistrita Nasaud", localitate: "Fantanita" },
  { judet: "Bistrita Nasaud", localitate: "Visuia" },
  { judet: "Bistrita Nasaud", localitate: "Comlod" },
  { judet: "Bistrita Nasaud", localitate: "Dupa Deal" },
  { judet: "Bistrita Nasaud", localitate: "Ghemes" },
  { judet: "Bistrita Nasaud", localitate: "Hirean" },
  { judet: "Bistrita Nasaud", localitate: "Orosfaia" },
  { judet: "Bistrita Nasaud", localitate: "Gledin" },
  { judet: "Bistrita Nasaud", localitate: "Floresti" },
  { judet: "Bistrita Nasaud", localitate: "Mintiu" },
  { judet: "Bistrita Nasaud", localitate: "Mititei" },
  { judet: "Bistrita Nasaud", localitate: "Mocod" },
  { judet: "Bistrita Nasaud", localitate: "Mogoseni" },
  { judet: "Bistrita Nasaud", localitate: "Nimigea de Sus" },
  { judet: "Bistrita Nasaud", localitate: "Taure" },
  { judet: "Bistrita Nasaud", localitate: "Nimigea de Jos" },
  { judet: "Bistrita Nasaud", localitate: "Beudiu" },
  { judet: "Bistrita Nasaud", localitate: "Dumbrava (Nuseni)" },
  { judet: "Bistrita Nasaud", localitate: "Feleac" },
  { judet: "Bistrita Nasaud", localitate: "Malin" },
  { judet: "Bistrita Nasaud", localitate: "Rusu de Sus" },
  { judet: "Bistrita Nasaud", localitate: "Vita" },
  { judet: "Bistrita Nasaud", localitate: "Bata" },
  { judet: "Bistrita Nasaud", localitate: "Ciceu-Corabia" },
  { judet: "Bistrita Nasaud", localitate: "Lelesti" },
  { judet: "Bistrita Nasaud", localitate: "Susenii Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Gersa I" },
  { judet: "Bistrita Nasaud", localitate: "Gersa II" },
  { judet: "Bistrita Nasaud", localitate: "Poderei" },
  { judet: "Bistrita Nasaud", localitate: "Valea Vinului" },
  { judet: "Bistrita Nasaud", localitate: "Dealu Stefanitei" },
  { judet: "Bistrita Nasaud", localitate: "Brateni" },
  { judet: "Bistrita Nasaud", localitate: "La Curte" },
  { judet: "Bistrita Nasaud", localitate: "Salcuta" },
  { judet: "Bistrita Nasaud", localitate: "Stupini" },
  { judet: "Bistrita Nasaud", localitate: "Zoreni" },
  { judet: "Bistrita Nasaud", localitate: "Draga" },
  { judet: "Bistrita Nasaud", localitate: "Fanatele Silivasului" },
  { judet: "Bistrita Nasaud", localitate: "Porumbenii" },
  { judet: "Bistrita Nasaud", localitate: "Halmasau" },
  { judet: "Bistrita Nasaud", localitate: "Lunca Borlesei" },
  { judet: "Bistrita Nasaud", localitate: "Paltineasa" },
  { judet: "Bistrita Nasaud", localitate: "Sita" },
  { judet: "Bistrita Nasaud", localitate: "Sesuri Spermezeu-Vale" },
  { judet: "Bistrita Nasaud", localitate: "Valea Mare (Sant)" },
  { judet: "Bistrita Nasaud", localitate: "Ardan" },
  { judet: "Bistrita Nasaud", localitate: "Posmus" },
  { judet: "Bistrita Nasaud", localitate: "Soimus" },
  { judet: "Bistrita Nasaud", localitate: "Arcalia" },
  { judet: "Bistrita Nasaud", localitate: "Chintelnic" },
  { judet: "Bistrita Nasaud", localitate: "Crainimat" },
  { judet: "Bistrita Nasaud", localitate: "Podirei" },
  { judet: "Bistrita Nasaud", localitate: "Saratel" },
  { judet: "Bistrita Nasaud", localitate: "Valea Magherusului" },
  { judet: "Bistrita Nasaud", localitate: "Agrisu de Jos" },
  { judet: "Bistrita Nasaud", localitate: "Agrisu de Sus" },
  { judet: "Bistrita Nasaud", localitate: "Bretea" },
  { judet: "Bistrita Nasaud", localitate: "Coasta" },
  { judet: "Bistrita Nasaud", localitate: "Cristur-Sieu" },
  { judet: "Bistrita Nasaud", localitate: "Sirioara" },
  { judet: "Bistrita Nasaud", localitate: "Lunca" },
  { judet: "Bistrita Nasaud", localitate: "Rustior" },
  { judet: "Bistrita Nasaud", localitate: "Sebis" },
  { judet: "Bistrita Nasaud", localitate: "Blajenii de Jos" },
  { judet: "Bistrita Nasaud", localitate: "Blajenii de Sus" },
  { judet: "Bistrita Nasaud", localitate: "Caila" },
  { judet: "Bistrita Nasaud", localitate: "Cociu" },
  { judet: "Bistrita Nasaud", localitate: "Sieu-Sfantu" },
  { judet: "Bistrita Nasaud", localitate: "Sintereag-Gara" },
  { judet: "Bistrita Nasaud", localitate: "Agries" },
  { judet: "Bistrita Nasaud", localitate: "Agriesel" },
  { judet: "Bistrita Nasaud", localitate: "Borleasa" },
  { judet: "Bistrita Nasaud", localitate: "Cireasi" },
  { judet: "Bistrita Nasaud", localitate: "Lunca Sateasca" },
  { judet: "Bistrita Nasaud", localitate: "Moliset" },
  { judet: "Bistrita Nasaud", localitate: "Oarzina" },
  { judet: "Bistrita Nasaud", localitate: "Racatesu" },
  { judet: "Bistrita Nasaud", localitate: "Sendroaia" },
  { judet: "Bistrita Nasaud", localitate: "Archiud" },
  { judet: "Bistrita Nasaud", localitate: "Budurleni" },
  { judet: "Bistrita Nasaud", localitate: "Ocnita" },
  { judet: "Bistrita Nasaud", localitate: "Pinticu" },
  { judet: "Bistrita Nasaud", localitate: "Viile Tecii" },
  { judet: "Bistrita Nasaud", localitate: "Fiad" },
  { judet: "Bistrita Nasaud", localitate: "Telcisor" },
  { judet: "Bistrita Nasaud", localitate: "Ciosa" },
  { judet: "Bistrita Nasaud", localitate: "Muresenii Bargaului" },
  { judet: "Bistrita Nasaud", localitate: "Piatra Fantanele" },
  { judet: "Bistrita Nasaud", localitate: "Tureac" },
  { judet: "Bistrita Nasaud", localitate: "Cristestii Ciceului" },
  { judet: "Bistrita Nasaud", localitate: "Hasmasu Ciceului" },
  { judet: "Bistrita Nasaud", localitate: "Ilisua" },
  { judet: "Bistrita Nasaud", localitate: "Camp" },
  { judet: "Bistrita Nasaud", localitate: "Coseriu" },
  { judet: "Bistrita Nasaud", localitate: "Delureni" },
  { judet: "Bistrita Nasaud", localitate: "Fanate" },
  { judet: "Bistrita Nasaud", localitate: "Podenii" },
  { judet: "Bistrita Nasaud", localitate: "Scoabe" },
  { judet: "Bistrita Nasaud", localitate: "Sopteriu" },
  { judet: "Bistrita Nasaud", localitate: "Valea" },
  { judet: "Bistrita Nasaud", localitate: "Valea Mare (Urmenis)" },
  { judet: "Bistrita Nasaud", localitate: "Alunisul" },
  { judet: "Bistrita Nasaud", localitate: "Perisor" },
  { judet: "Bistrita Nasaud", localitate: "Poienile Zagrei" },
  { judet: "Bistrita Nasaud", localitate: "Suplai" },
  { judet: "Botosani", localitate: "Botosani" },
  { judet: "Botosani", localitate: "Dorohoi" },
  { judet: "Botosani", localitate: "Bucecea" },
  { judet: "Botosani", localitate: "Darabani" },
  { judet: "Botosani", localitate: "Flamanzi" },
  { judet: "Botosani", localitate: "Saveni" },
  { judet: "Botosani", localitate: "Stefanesti" },
  { judet: "Botosani", localitate: "Adaseni" },
  { judet: "Botosani", localitate: "Albesti" },
  { judet: "Botosani", localitate: "Avrameni" },
  { judet: "Botosani", localitate: "Baluseni" },
  { judet: "Botosani", localitate: "Blandesti" },
  { judet: "Botosani", localitate: "Braesti" },
  { judet: "Botosani", localitate: "Broscauti" },
  { judet: "Botosani", localitate: "Calarasi" },
  { judet: "Botosani", localitate: "Candesti" },
  { judet: "Botosani", localitate: "Concesti" },
  { judet: "Botosani", localitate: "Copalau" },
  { judet: "Botosani", localitate: "Cordareni" },
  { judet: "Botosani", localitate: "Corlateni" },
  { judet: "Botosani", localitate: "Corni" },
  { judet: "Botosani", localitate: "Cosula" },
  { judet: "Botosani", localitate: "Cotusca" },
  { judet: "Botosani", localitate: "Cristesti" },
  { judet: "Botosani", localitate: "Cristinesti" },
  { judet: "Botosani", localitate: "Curtesti" },
  { judet: "Botosani", localitate: "Dersca" },
  { judet: "Botosani", localitate: "Dangeni" },
  { judet: "Botosani", localitate: "Dimacheni" },
  { judet: "Botosani", localitate: "Dobarceni" },
  { judet: "Botosani", localitate: "Draguseni" },
  { judet: "Botosani", localitate: "Durnesti" },
  { judet: "Botosani", localitate: "Frumusica" },
  { judet: "Botosani", localitate: "George Enescu" },
  { judet: "Botosani", localitate: "Gorbanesti" },
  { judet: "Botosani", localitate: "Havarna" },
  { judet: "Botosani", localitate: "Hanesti" },
  { judet: "Botosani", localitate: "Hiliseu-Horia" },
  { judet: "Botosani", localitate: "Hlipiceni" },
  { judet: "Botosani", localitate: "Hudesti" },
  { judet: "Botosani", localitate: "Ibanesti" },
  { judet: "Botosani", localitate: "Leorda" },
  { judet: "Botosani", localitate: "Lozna" },
  { judet: "Botosani", localitate: "Lunca" },
  { judet: "Botosani", localitate: "Manoleasa" },
  { judet: "Botosani", localitate: "Mihai Eminescu" },
  { judet: "Botosani", localitate: "Mihaileni" },
  { judet: "Botosani", localitate: "Mihalaseni" },
  { judet: "Botosani", localitate: "Mitoc" },
  { judet: "Botosani", localitate: "Nicseni" },
  { judet: "Botosani", localitate: "Paltinis" },
  { judet: "Botosani", localitate: "Pomarla" },
  { judet: "Botosani", localitate: "Prajeni" },
  { judet: "Botosani", localitate: "Radauti-Prut" },
  { judet: "Botosani", localitate: "Rachiti" },
  { judet: "Botosani", localitate: "Rauseni" },
  { judet: "Botosani", localitate: "Ripiceni" },
  { judet: "Botosani", localitate: "Roma" },
  { judet: "Botosani", localitate: "Romanesti" },
  { judet: "Botosani", localitate: "Santa Mare" },
  { judet: "Botosani", localitate: "Stauceni" },
  { judet: "Botosani", localitate: "Suharau" },
  { judet: "Botosani", localitate: "Sulita" },
  { judet: "Botosani", localitate: "Sendriceni" },
  { judet: "Botosani", localitate: "Stiubieni" },
  { judet: "Botosani", localitate: "Todireni" },
  { judet: "Botosani", localitate: "Trusesti" },
  { judet: "Botosani", localitate: "Tudora" },
  { judet: "Botosani", localitate: "Ungureni" },
  { judet: "Botosani", localitate: "Unteni" },
  { judet: "Botosani", localitate: "Vaculesti" },
  { judet: "Botosani", localitate: "Viisoara" },
  { judet: "Botosani", localitate: "Varfu Campului" },
  { judet: "Botosani", localitate: "Vladeni" },
  { judet: "Botosani", localitate: "Vlasinesti" },
  { judet: "Botosani", localitate: "Vorniceni" },
  { judet: "Botosani", localitate: "Vorona" },
  { judet: "Botosani", localitate: "Bajura" },
  { judet: "Botosani", localitate: "Esanca" },
  { judet: "Botosani", localitate: "Lismanita" },
  { judet: "Botosani", localitate: "Dealu Mare" },
  { judet: "Botosani", localitate: "Loturi Enescu" },
  { judet: "Botosani", localitate: "Progresul" },
  { judet: "Botosani", localitate: "Bodeasa" },
  { judet: "Botosani", localitate: "Bozieni" },
  { judet: "Botosani", localitate: "Chiscareni" },
  { judet: "Botosani", localitate: "Petricani" },
  { judet: "Botosani", localitate: "Sat Nou" },
  { judet: "Botosani", localitate: "Buimaceni" },
  { judet: "Botosani", localitate: "Costiugeni" },
  { judet: "Botosani", localitate: "Jijia" },
  { judet: "Botosani", localitate: "Mascateni" },
  { judet: "Botosani", localitate: "Tudor Vladimirescu (Albesti)" },
  { judet: "Botosani", localitate: "Aurel Vlaicu" },
  { judet: "Botosani", localitate: "Dimitrie Cantemir" },
  { judet: "Botosani", localitate: "Ichimeni" },
  { judet: "Botosani", localitate: "Panaitoaia" },
  { judet: "Botosani", localitate: "Timus" },
  { judet: "Botosani", localitate: "Tudor Vladimirescu (Avrameni)" },
  { judet: "Botosani", localitate: "Zoitani" },
  { judet: "Botosani", localitate: "Balusenii Noi" },
  { judet: "Botosani", localitate: "Buzeni" },
  { judet: "Botosani", localitate: "Cosuleni" },
  { judet: "Botosani", localitate: "Draxini" },
  { judet: "Botosani", localitate: "Zaicesti" },
  { judet: "Botosani", localitate: "Poiana (Braesti)" },
  { judet: "Botosani", localitate: "Popeni (Braesti)" },
  { judet: "Botosani", localitate: "Valcelele" },
  { judet: "Botosani", localitate: "Slobozia (Broscauti)" },
  { judet: "Botosani", localitate: "Bohoghina" },
  { judet: "Botosani", localitate: "Calinesti (Bucecea)" },
  { judet: "Botosani", localitate: "Libertatea" },
  { judet: "Botosani", localitate: "Plesani" },
  { judet: "Botosani", localitate: "Movileni" },
  { judet: "Botosani", localitate: "Buda" },
  { judet: "Botosani", localitate: "Cerbu" },
  { judet: "Botosani", localitate: "Cotu" },
  { judet: "Botosani", localitate: "Padureni (Cosula)" },
  { judet: "Botosani", localitate: "Supitca" },
  { judet: "Botosani", localitate: "Grivita" },
  { judet: "Botosani", localitate: "Slobozia (Cordareni)" },
  { judet: "Botosani", localitate: "Carasa" },
  { judet: "Botosani", localitate: "Mateieni" },
  { judet: "Botosani", localitate: "Podeni" },
  { judet: "Botosani", localitate: "Recia-Verbia" },
  { judet: "Botosani", localitate: "Vladeni (Corlateni)" },
  { judet: "Botosani", localitate: "Balta Arsa" },
  { judet: "Botosani", localitate: "Mesteacan" },
  { judet: "Botosani", localitate: "Sarafinesti" },
  { judet: "Botosani", localitate: "Avram Iancu" },
  { judet: "Botosani", localitate: "Cotu Miculinti" },
  { judet: "Botosani", localitate: "Crasnaleuca" },
  { judet: "Botosani", localitate: "Ghireni" },
  { judet: "Botosani", localitate: "Mihail Kogalniceanu" },
  { judet: "Botosani", localitate: "Nichiteni" },
  { judet: "Botosani", localitate: "Nicolae Balcescu (Cotusca)" },
  { judet: "Botosani", localitate: "Putureni" },
  { judet: "Botosani", localitate: "Oneaga" },
  { judet: "Botosani", localitate: "Schit-Oraseni" },
  { judet: "Botosani", localitate: "Unguroaia" },
  { judet: "Botosani", localitate: "Damileni" },
  { judet: "Botosani", localitate: "Baranca (Cristinesti)" },
  { judet: "Botosani", localitate: "Dragalina (Cristinesti)" },
  { judet: "Botosani", localitate: "Fundu Hertii" },
  { judet: "Botosani", localitate: "Poiana (Cristinesti)" },
  { judet: "Botosani", localitate: "Agafton" },
  { judet: "Botosani", localitate: "Baiceni" },
  { judet: "Botosani", localitate: "Hudum" },
  { judet: "Botosani", localitate: "Manastirea Doamnei" },
  { judet: "Botosani", localitate: "Oraseni-Deal" },
  { judet: "Botosani", localitate: "Oraseni-Vale" },
  { judet: "Botosani", localitate: "Hulub" },
  { judet: "Botosani", localitate: "Iacobeni" },
  { judet: "Botosani", localitate: "Strahotin" },
  { judet: "Botosani", localitate: "Strateni" },
  { judet: "Botosani", localitate: "Bivolari" },
  { judet: "Botosani", localitate: "Brateni" },
  { judet: "Botosani", localitate: "Cismanesti" },
  { judet: "Botosani", localitate: "Livada" },
  { judet: "Botosani", localitate: "Murguta" },
  { judet: "Botosani", localitate: "Podriga" },
  { judet: "Botosani", localitate: "Sarata-Draguseni" },
  { judet: "Botosani", localitate: "Babiceni" },
  { judet: "Botosani", localitate: "Barsanesti" },
  { judet: "Botosani", localitate: "Brosteni" },
  { judet: "Botosani", localitate: "Cucuteni" },
  { judet: "Botosani", localitate: "Guranda" },
  { judet: "Botosani", localitate: "Chitoveni" },
  { judet: "Botosani", localitate: "Nicolae Balcescu (Flamanzi)" },
  { judet: "Botosani", localitate: "Poiana (Flamanzi)" },
  { judet: "Botosani", localitate: "Prisacani" },
  { judet: "Botosani", localitate: "Boscoteni" },
  { judet: "Botosani", localitate: "Radeni" },
  { judet: "Botosani", localitate: "Storesti" },
  { judet: "Botosani", localitate: "Sendreni" },
  { judet: "Botosani", localitate: "Vladeni-Deal" },
  { judet: "Botosani", localitate: "Arborea" },
  { judet: "Botosani", localitate: "Dumeni" },
  { judet: "Botosani", localitate: "Popeni (George Enescu)" },
  { judet: "Botosani", localitate: "Stanca (George Enescu)" },
  { judet: "Botosani", localitate: "Batranesti" },
  { judet: "Botosani", localitate: "George Cosbuc" },
  { judet: "Botosani", localitate: "Mihai Eminescu (Gorbanesti)" },
  { judet: "Botosani", localitate: "Siliscani" },
  { judet: "Botosani", localitate: "Socrujeni" },
  { judet: "Botosani", localitate: "Vanatori" },
  { judet: "Botosani", localitate: "Viforeni" },
  { judet: "Botosani", localitate: "Balinti" },
  { judet: "Botosani", localitate: "Galbeni" },
  { judet: "Botosani", localitate: "Garbeni" },
  { judet: "Botosani", localitate: "Niculcea" },
  { judet: "Botosani", localitate: "Tataraseni" },
  { judet: "Botosani", localitate: "Borolea" },
  { judet: "Botosani", localitate: "Moara Jorii" },
  { judet: "Botosani", localitate: "Sarata-Basarab" },
  { judet: "Botosani", localitate: "Slobozia Hanesti" },
  { judet: "Botosani", localitate: "Corjauti" },
  { judet: "Botosani", localitate: "Hiliseu-Closca" },
  { judet: "Botosani", localitate: "Hiliseu-Crisan" },
  { judet: "Botosani", localitate: "Iezer" },
  { judet: "Botosani", localitate: "Dragalina (Hlipiceni)" },
  { judet: "Botosani", localitate: "Victoria (Hlipiceni)" },
  { judet: "Botosani", localitate: "Baseu" },
  { judet: "Botosani", localitate: "Alba" },
  { judet: "Botosani", localitate: "Baranca (Hudesti)" },
  { judet: "Botosani", localitate: "Mlenauti" },
  { judet: "Botosani", localitate: "Vatra" },
  { judet: "Botosani", localitate: "Dumbravita" },
  { judet: "Botosani", localitate: "Belcea" },
  { judet: "Botosani", localitate: "Costinesti" },
  { judet: "Botosani", localitate: "Dolina" },
  { judet: "Botosani", localitate: "Mitoc (Leorda)" },
  { judet: "Botosani", localitate: "Stroiesti" },
  { judet: "Botosani", localitate: "Zlatunoaia" },
  { judet: "Botosani", localitate: "Bold" },
  { judet: "Botosani", localitate: "Flondora" },
  { judet: "Botosani", localitate: "Iorga" },
  { judet: "Botosani", localitate: "Liveni" },
  { judet: "Botosani", localitate: "Loturi" },
  { judet: "Botosani", localitate: "Manoleasa-Prut" },
  { judet: "Botosani", localitate: "Sadoveni" },
  { judet: "Botosani", localitate: "Serpenita" },
  { judet: "Botosani", localitate: "Zahoreni" },
  { judet: "Botosani", localitate: "Baisa" },
  { judet: "Botosani", localitate: "Catamaresti" },
  { judet: "Botosani", localitate: "Catamaresti-Deal" },
  { judet: "Botosani", localitate: "Cervicesti" },
  { judet: "Botosani", localitate: "Cervicesti-Deal" },
  { judet: "Botosani", localitate: "Cucorani" },
  { judet: "Botosani", localitate: "Ipotesti" },
  { judet: "Botosani", localitate: "Manolesti" },
  { judet: "Botosani", localitate: "Stancesti" },
  { judet: "Botosani", localitate: "Calinesti (Candesti)" },
  { judet: "Botosani", localitate: "Parau Negru" },
  { judet: "Botosani", localitate: "Rogojesti" },
  { judet: "Botosani", localitate: "Talpa" },
  { judet: "Botosani", localitate: "Vitcani" },
  { judet: "Botosani", localitate: "Caraiman" },
  { judet: "Botosani", localitate: "Nastase" },
  { judet: "Botosani", localitate: "Negresti" },
  { judet: "Botosani", localitate: "Paun" },
  { judet: "Botosani", localitate: "Sarata" },
  { judet: "Botosani", localitate: "Slobozia Siliscani" },
  { judet: "Botosani", localitate: "Mileanca" },
  { judet: "Botosani", localitate: "Codreni" },
  { judet: "Botosani", localitate: "Scutari" },
  { judet: "Botosani", localitate: "Selistea" },
  { judet: "Botosani", localitate: "Horia" },
  { judet: "Botosani", localitate: "Dacia" },
  { judet: "Botosani", localitate: "Dorobanti" },
  { judet: "Botosani", localitate: "Cuzlau" },
  { judet: "Botosani", localitate: "Horodistea" },
  { judet: "Botosani", localitate: "Slobozia (Paltinis)" },
  { judet: "Botosani", localitate: "Hulubesti" },
  { judet: "Botosani", localitate: "Racovat" },
  { judet: "Botosani", localitate: "Campeni" },
  { judet: "Botosani", localitate: "Luparia" },
  { judet: "Botosani", localitate: "Miletin" },
  { judet: "Botosani", localitate: "Cismea" },
  { judet: "Botosani", localitate: "Costesti" },
  { judet: "Botosani", localitate: "Rosiori" },
  { judet: "Botosani", localitate: "Miorcani" },
  { judet: "Botosani", localitate: "Rediu (Radauti-Prut)" },
  { judet: "Botosani", localitate: "Doina" },
  { judet: "Botosani", localitate: "Pogorasti" },
  { judet: "Botosani", localitate: "Rediu (Rauseni)" },
  { judet: "Botosani", localitate: "Stolniceni" },
  { judet: "Botosani", localitate: "Cinghiniia" },
  { judet: "Botosani", localitate: "Lehnesti" },
  { judet: "Botosani", localitate: "Movila Rupta" },
  { judet: "Botosani", localitate: "Popoaia" },
  { judet: "Botosani", localitate: "Rasca" },
  { judet: "Botosani", localitate: "Ripicenii Vechi" },
  { judet: "Botosani", localitate: "Cotargaci" },
  { judet: "Botosani", localitate: "Damideni" },
  { judet: "Botosani", localitate: "Romanesti-Vale" },
  { judet: "Botosani", localitate: "Sarata" },
  { judet: "Botosani", localitate: "Badarai" },
  { judet: "Botosani", localitate: "Berza" },
  { judet: "Botosani", localitate: "Bogdanesti" },
  { judet: "Botosani", localitate: "Durnesti (Santa Mare)" },
  { judet: "Botosani", localitate: "Iliseni" },
  { judet: "Botosani", localitate: "Ranghilesti" },
  { judet: "Botosani", localitate: "Ranghilesti-Deal" },
  { judet: "Botosani", localitate: "Silistea" },
  { judet: "Botosani", localitate: "Tocileni" },
  { judet: "Botosani", localitate: "Victoria (Stauceni)" },
  { judet: "Botosani", localitate: "Izvoare" },
  { judet: "Botosani", localitate: "Lisna" },
  { judet: "Botosani", localitate: "Oroftiana" },
  { judet: "Botosani", localitate: "Plevna" },
  { judet: "Botosani", localitate: "Smardan" },
  { judet: "Botosani", localitate: "Cerchejeni" },
  { judet: "Botosani", localitate: "Chelis" },
  { judet: "Botosani", localitate: "Dracsani" },
  { judet: "Botosani", localitate: "Soldanesti" },
  { judet: "Botosani", localitate: "Horlaceni" },
  { judet: "Botosani", localitate: "Padureni (Sendriceni)" },
  { judet: "Botosani", localitate: "Badiuti" },
  { judet: "Botosani", localitate: "Bobulesti" },
  { judet: "Botosani", localitate: "Stanca (Stefanesti)" },
  { judet: "Botosani", localitate: "Stefanesti-Sat" },
  { judet: "Botosani", localitate: "Ibaneasa" },
  { judet: "Botosani", localitate: "Negreni" },
  { judet: "Botosani", localitate: "Cernesti" },
  { judet: "Botosani", localitate: "Floresti" },
  { judet: "Botosani", localitate: "Garbesti" },
  { judet: "Botosani", localitate: "Iuresti" },
  { judet: "Botosani", localitate: "Buhaceni" },
  { judet: "Botosani", localitate: "Ciritei" },
  { judet: "Botosani", localitate: "Drislea" },
  { judet: "Botosani", localitate: "Ionaseni (Trusesti)" },
  { judet: "Botosani", localitate: "Pasateni" },
  { judet: "Botosani", localitate: "Borzesti" },
  { judet: "Botosani", localitate: "Calugareni" },
  { judet: "Botosani", localitate: "Calugarenii Noi" },
  { judet: "Botosani", localitate: "Durnesti (Ungureni)" },
  { judet: "Botosani", localitate: "Mandresti (Ungureni)" },
  { judet: "Botosani", localitate: "Mihai Viteazu" },
  { judet: "Botosani", localitate: "Plopenii Mari" },
  { judet: "Botosani", localitate: "Plopenii Mici" },
  { judet: "Botosani", localitate: "Tautesti" },
  { judet: "Botosani", localitate: "Vicoleni" },
  { judet: "Botosani", localitate: "Burla" },
  { judet: "Botosani", localitate: "Burlesti" },
  { judet: "Botosani", localitate: "Manastireni" },
  { judet: "Botosani", localitate: "Soroceni" },
  { judet: "Botosani", localitate: "Valea Grajdului" },
  { judet: "Botosani", localitate: "Vultureni" },
  { judet: "Botosani", localitate: "Gorovei" },
  { judet: "Botosani", localitate: "Saucenita" },
  { judet: "Botosani", localitate: "Dobrinauti-Hapai" },
  { judet: "Botosani", localitate: "Ionaseni (Varfu Campului)" },
  { judet: "Botosani", localitate: "Lunca (Varfu Campului)" },
  { judet: "Botosani", localitate: "Maghera" },
  { judet: "Botosani", localitate: "Pustoaia" },
  { judet: "Botosani", localitate: "Cuza Voda" },
  { judet: "Botosani", localitate: "Viisoara Mica" },
  { judet: "Botosani", localitate: "Brehuiesti" },
  { judet: "Botosani", localitate: "Hriscani" },
  { judet: "Botosani", localitate: "Hutani" },
  { judet: "Botosani", localitate: "Mandresti (Vladeni)" },
  { judet: "Botosani", localitate: "Miron Costin" },
  { judet: "Botosani", localitate: "Sarbi" },
  { judet: "Botosani", localitate: "Davidoaia" },
  { judet: "Botosani", localitate: "Dealu Crucii" },
  { judet: "Botosani", localitate: "Icuseni" },
  { judet: "Botosani", localitate: "Joldesti" },
  { judet: "Botosani", localitate: "Poiana (Vorona)" },
  { judet: "Botosani", localitate: "Vorona Mare" },
  { judet: "Botosani", localitate: "Vorona-Teodoru" },
  { judet: "Braila", localitate: "Braila" },
  { judet: "Braila", localitate: "Chiscani" },
  { judet: "Braila", localitate: "Varsatura" },
  { judet: "Braila", localitate: "Oprisenesti" },
  { judet: "Braila", localitate: "Faurei" },
  { judet: "Braila", localitate: "Ianca" },
  { judet: "Braila", localitate: "Insuratei" },
  { judet: "Braila", localitate: "Baraganul" },
  { judet: "Braila", localitate: "Bertestii de Jos" },
  { judet: "Braila", localitate: "Bordei Verde" },
  { judet: "Braila", localitate: "Cazasu" },
  { judet: "Braila", localitate: "Ciocile" },
  { judet: "Braila", localitate: "Ciresu" },
  { judet: "Braila", localitate: "Dudesti" },
  { judet: "Braila", localitate: "Frecatei" },
  { judet: "Braila", localitate: "Galbenu" },
  { judet: "Braila", localitate: "Gemenele" },
  { judet: "Braila", localitate: "Gradistea" },
  { judet: "Braila", localitate: "Gropeni" },
  { judet: "Braila", localitate: "Jirlau" },
  { judet: "Braila", localitate: "Marasu" },
  { judet: "Braila", localitate: "Maxineni" },
  { judet: "Braila", localitate: "Mircea Voda" },
  { judet: "Braila", localitate: "Movila Miresii" },
  { judet: "Braila", localitate: "Racovita" },
  { judet: "Braila", localitate: "Ramnicelu" },
  { judet: "Braila", localitate: "Romanu" },
  { judet: "Braila", localitate: "Rosiori" },
  { judet: "Braila", localitate: "Salcia Tudor" },
  { judet: "Braila", localitate: "Scortaru Nou" },
  { judet: "Braila", localitate: "Silistea" },
  { judet: "Braila", localitate: "Stancuta" },
  { judet: "Braila", localitate: "Surdila-Gaiseanca" },
  { judet: "Braila", localitate: "Surdila-Greci" },
  { judet: "Braila", localitate: "Sutesti" },
  { judet: "Braila", localitate: "Tichilesti" },
  { judet: "Braila", localitate: "Traian" },
  { judet: "Braila", localitate: "Tudor Vladimirescu" },
  { judet: "Braila", localitate: "Tufesti" },
  { judet: "Braila", localitate: "Ulmu" },
  { judet: "Braila", localitate: "Unirea" },
  { judet: "Braila", localitate: "Vadeni" },
  { judet: "Braila", localitate: "Victoria" },
  { judet: "Braila", localitate: "Visani" },
  { judet: "Braila", localitate: "Viziru" },
  { judet: "Braila", localitate: "Zavoaia" },
  { judet: "Braila", localitate: "Berlesti" },
  { judet: "Braila", localitate: "Gara Ianca" },
  { judet: "Braila", localitate: "Perisoru" },
  { judet: "Braila", localitate: "Plopu" },
  { judet: "Braila", localitate: "Tarlele Filiu" },
  { judet: "Braila", localitate: "Lacu Rezii" },
  { judet: "Braila", localitate: "Maru Rosu" },
  { judet: "Braila", localitate: "Valea Calmatuiului" },
  { judet: "Braila", localitate: "Bertestii de Sus" },
  { judet: "Braila", localitate: "Gura Calmatui" },
  { judet: "Braila", localitate: "Gura Garlutei" },
  { judet: "Braila", localitate: "Nicolesti" },
  { judet: "Braila", localitate: "Spiru Haret" },
  { judet: "Braila", localitate: "Constantin Gabrielescu" },
  { judet: "Braila", localitate: "Filiu" },
  { judet: "Braila", localitate: "Liscoteanca" },
  { judet: "Braila", localitate: "Lacu Sarat" },
  { judet: "Braila", localitate: "Chichinetu" },
  { judet: "Braila", localitate: "Chioibasesti" },
  { judet: "Braila", localitate: "Odaieni" },
  { judet: "Braila", localitate: "Batogu" },
  { judet: "Braila", localitate: "Ionesti" },
  { judet: "Braila", localitate: "Scarlatesti" },
  { judet: "Braila", localitate: "Vultureni" },
  { judet: "Braila", localitate: "Bumbacari" },
  { judet: "Braila", localitate: "Tataru" },
  { judet: "Braila", localitate: "Agaua" },
  { judet: "Braila", localitate: "Cistia" },
  { judet: "Braila", localitate: "Salcia" },
  { judet: "Braila", localitate: "Stoienesti" },
  { judet: "Braila", localitate: "Titcov" },
  { judet: "Braila", localitate: "Drogu" },
  { judet: "Braila", localitate: "Pantecani" },
  { judet: "Braila", localitate: "Satuc" },
  { judet: "Braila", localitate: "Zamfiresti" },
  { judet: "Braila", localitate: "Gavani" },
  { judet: "Braila", localitate: "Ibrianu" },
  { judet: "Braila", localitate: "Maraloiu" },
  { judet: "Braila", localitate: "Bradeanca" },
  { judet: "Braila", localitate: "Bandoiu" },
  { judet: "Braila", localitate: "Magureni" },
  { judet: "Braila", localitate: "Nedelcu" },
  { judet: "Braila", localitate: "Plopi" },
  { judet: "Braila", localitate: "Tacau" },
  { judet: "Braila", localitate: "Corbu Nou" },
  { judet: "Braila", localitate: "Corbu Vechi" },
  { judet: "Braila", localitate: "Latinu" },
  { judet: "Braila", localitate: "Voinesti" },
  { judet: "Braila", localitate: "Dedulesti" },
  { judet: "Braila", localitate: "Esna" },
  { judet: "Braila", localitate: "Tepes Voda" },
  { judet: "Braila", localitate: "Corbeni" },
  { judet: "Braila", localitate: "Custura" },
  { judet: "Braila", localitate: "Boarca" },
  { judet: "Braila", localitate: "Constantinesti" },
  { judet: "Braila", localitate: "Mihail Kogalniceanu (Ramnicelu)" },
  { judet: "Braila", localitate: "Oancea" },
  { judet: "Braila", localitate: "Coltea" },
  { judet: "Braila", localitate: "Florica" },
  { judet: "Braila", localitate: "Pribeagu" },
  { judet: "Braila", localitate: "Ariciu" },
  { judet: "Braila", localitate: "Cuza Voda (Salcia Tudor)" },
  { judet: "Braila", localitate: "Gulianca" },
  { judet: "Braila", localitate: "Olaneasca" },
  { judet: "Braila", localitate: "Desirati" },
  { judet: "Braila", localitate: "Gurguieti" },
  { judet: "Braila", localitate: "Nicolae Balcescu" },
  { judet: "Braila", localitate: "Pitulati" },
  { judet: "Braila", localitate: "Sihleanu" },
  { judet: "Braila", localitate: "Cotu Lung" },
  { judet: "Braila", localitate: "Cotu Mihalea" },
  { judet: "Braila", localitate: "Martacesti" },
  { judet: "Braila", localitate: "Muchea" },
  { judet: "Braila", localitate: "Vamesu" },
  { judet: "Braila", localitate: "Cuza Voda (Stancuta)" },
  { judet: "Braila", localitate: "Polizesti" },
  { judet: "Braila", localitate: "Stanca" },
  { judet: "Braila", localitate: "Filipesti" },
  { judet: "Braila", localitate: "Bratesu Vechi" },
  { judet: "Braila", localitate: "Faurei-Sat" },
  { judet: "Braila", localitate: "Horia" },
  { judet: "Braila", localitate: "Mihail Kogalniceanu (Sutesti)" },
  { judet: "Braila", localitate: "Albina" },
  { judet: "Braila", localitate: "Caldarusa" },
  { judet: "Braila", localitate: "Silistraru" },
  { judet: "Braila", localitate: "Urleasca" },
  { judet: "Braila", localitate: "Comaneasca" },
  { judet: "Braila", localitate: "Scortaru Vechi" },
  { judet: "Braila", localitate: "Jugureanu" },
  { judet: "Braila", localitate: "Morotesti" },
  { judet: "Braila", localitate: "Valea Canepii" },
  { judet: "Braila", localitate: "Baldovinesti" },
  { judet: "Braila", localitate: "Pietroiu" },
  { judet: "Braila", localitate: "Mihai Bravu" },
  { judet: "Braila", localitate: "Caineni-Bai" },
  { judet: "Braila", localitate: "Plasoiu" },
  { judet: "Braila", localitate: "Lanurile" },
  { judet: "Braila", localitate: "Dudescu" },
  { judet: "Brasov", localitate: "Brasov" },
  { judet: "Brasov", localitate: "Codlea" },
  { judet: "Brasov", localitate: "Fagaras" },
  { judet: "Brasov", localitate: "Ghimbav" },
  { judet: "Brasov", localitate: "Predeal" },
  { judet: "Brasov", localitate: "Rasnov" },
  { judet: "Brasov", localitate: "Rupea" },
  { judet: "Brasov", localitate: "Sacele" },
  { judet: "Brasov", localitate: "Zarnesti" },
  { judet: "Brasov", localitate: "Victoria" },
  { judet: "Brasov", localitate: "Bod" },
  { judet: "Brasov", localitate: "Bran" },
  { judet: "Brasov", localitate: "Feldioara" },
  { judet: "Brasov", localitate: "Halchiu" },
  { judet: "Brasov", localitate: "Harman" },
  { judet: "Brasov", localitate: "Hoghiz" },
  { judet: "Brasov", localitate: "Lisa" },
  { judet: "Brasov", localitate: "Moieciu" },
  { judet: "Brasov", localitate: "Prejmer" },
  { judet: "Brasov", localitate: "Racos" },
  { judet: "Brasov", localitate: "Sercaia" },
  { judet: "Brasov", localitate: "Sinca Veche" },
  { judet: "Brasov", localitate: "Vulcan" },
  { judet: "Brasov", localitate: "Cheia" },
  { judet: "Brasov", localitate: "Apata" },
  { judet: "Brasov", localitate: "Augustin" },
  { judet: "Brasov", localitate: "Beclean" },
  { judet: "Brasov", localitate: "Budila" },
  { judet: "Brasov", localitate: "Bunesti" },
  { judet: "Brasov", localitate: "Cata" },
  { judet: "Brasov", localitate: "Cincu" },
  { judet: "Brasov", localitate: "Comana" },
  { judet: "Brasov", localitate: "Cristian" },
  { judet: "Brasov", localitate: "Crizbav" },
  { judet: "Brasov", localitate: "Dragus" },
  { judet: "Brasov", localitate: "Dumbravita" },
  { judet: "Brasov", localitate: "Fundata" },
  { judet: "Brasov", localitate: "Harseni" },
  { judet: "Brasov", localitate: "Holbav" },
  { judet: "Brasov", localitate: "Homorod" },
  { judet: "Brasov", localitate: "Jibert" },
  { judet: "Brasov", localitate: "Mandra" },
  { judet: "Brasov", localitate: "Maierus" },
  { judet: "Brasov", localitate: "Ormenis" },
  { judet: "Brasov", localitate: "Parau" },
  { judet: "Brasov", localitate: "Poiana Marului" },
  { judet: "Brasov", localitate: "Recea" },
  { judet: "Brasov", localitate: "Sinca" },
  { judet: "Brasov", localitate: "Sinca Noua" },
  { judet: "Brasov", localitate: "Sambata de Sus" },
  { judet: "Brasov", localitate: "Sanpetru" },
  { judet: "Brasov", localitate: "Soars" },
  { judet: "Brasov", localitate: "Tarlungeni" },
  { judet: "Brasov", localitate: "Teliu" },
  { judet: "Brasov", localitate: "Ticusu" },
  { judet: "Brasov", localitate: "Ucea" },
  { judet: "Brasov", localitate: "Ungra" },
  { judet: "Brasov", localitate: "Vama Buzaului" },
  { judet: "Brasov", localitate: "Vistea" },
  { judet: "Brasov", localitate: "Voila" },
  { judet: "Brasov", localitate: "Poiana Brasov" },
  { judet: "Brasov", localitate: "Paraul Rece" },
  { judet: "Brasov", localitate: "Timisu de Jos" },
  { judet: "Brasov", localitate: "Timisu de Sus" },
  { judet: "Brasov", localitate: "Rasnov Romacril" },
  { judet: "Brasov", localitate: "Fiser" },
  { judet: "Brasov", localitate: "Tohanu Nou" },
  { judet: "Brasov", localitate: "Boholt" },
  { judet: "Brasov", localitate: "Calbor" },
  { judet: "Brasov", localitate: "Hurez" },
  { judet: "Brasov", localitate: "Luta" },
  { judet: "Brasov", localitate: "Colonia Bod" },
  { judet: "Brasov", localitate: "Predelut" },
  { judet: "Brasov", localitate: "Sohodol" },
  { judet: "Brasov", localitate: "Simon" },
  { judet: "Brasov", localitate: "Crit" },
  { judet: "Brasov", localitate: "Mesendorf" },
  { judet: "Brasov", localitate: "Roades" },
  { judet: "Brasov", localitate: "Viscri" },
  { judet: "Brasov", localitate: "Beia" },
  { judet: "Brasov", localitate: "Drauseni" },
  { judet: "Brasov", localitate: "Ionesti" },
  { judet: "Brasov", localitate: "Palos" },
  { judet: "Brasov", localitate: "Toarcla" },
  { judet: "Brasov", localitate: "Comana de Jos" },
  { judet: "Brasov", localitate: "Comana de Sus" },
  { judet: "Brasov", localitate: "Crihalma" },
  { judet: "Brasov", localitate: "Ticusu Nou" },
  { judet: "Brasov", localitate: "Vladeni" },
  { judet: "Brasov", localitate: "Colonia Reconstructia" },
  { judet: "Brasov", localitate: "Rotbav" },
  { judet: "Brasov", localitate: "Fundatica" },
  { judet: "Brasov", localitate: "Sirnea" },
  { judet: "Brasov", localitate: "Cutus" },
  { judet: "Brasov", localitate: "Satu Nou" },
  { judet: "Brasov", localitate: "Podu Oltului" },
  { judet: "Brasov", localitate: "Copacel" },
  { judet: "Brasov", localitate: "Malinis" },
  { judet: "Brasov", localitate: "Margineni" },
  { judet: "Brasov", localitate: "Sebes" },
  { judet: "Brasov", localitate: "Bogata Olteana" },
  { judet: "Brasov", localitate: "Cuciulata" },
  { judet: "Brasov", localitate: "Dopca" },
  { judet: "Brasov", localitate: "Fantana" },
  { judet: "Brasov", localitate: "Lupsa" },
  { judet: "Brasov", localitate: "Jimbor" },
  { judet: "Brasov", localitate: "Mercheasa" },
  { judet: "Brasov", localitate: "Dacia" },
  { judet: "Brasov", localitate: "Granari" },
  { judet: "Brasov", localitate: "Lovnic" },
  { judet: "Brasov", localitate: "Valeni" },
  { judet: "Brasov", localitate: "Breaza" },
  { judet: "Brasov", localitate: "Pojorta" },
  { judet: "Brasov", localitate: "Arini" },
  { judet: "Brasov", localitate: "Ileni" },
  { judet: "Brasov", localitate: "Rausor" },
  { judet: "Brasov", localitate: "Sona" },
  { judet: "Brasov", localitate: "Toderita" },
  { judet: "Brasov", localitate: "Drumul Carului" },
  { judet: "Brasov", localitate: "Magura" },
  { judet: "Brasov", localitate: "Moieciu de Sus" },
  { judet: "Brasov", localitate: "Moieciu de Jos" },
  { judet: "Brasov", localitate: "Pestera" },
  { judet: "Brasov", localitate: "Grid" },
  { judet: "Brasov", localitate: "Venetia de Jos" },
  { judet: "Brasov", localitate: "Venetia de Sus" },
  { judet: "Brasov", localitate: "Stupinii Prejmerului" },
  { judet: "Brasov", localitate: "Lunca Calnicului" },
  { judet: "Brasov", localitate: "Mateias" },
  { judet: "Brasov", localitate: "Berivoi" },
  { judet: "Brasov", localitate: "Dejani" },
  { judet: "Brasov", localitate: "Gura Vaii" },
  { judet: "Brasov", localitate: "Iasi" },
  { judet: "Brasov", localitate: "Sasciori" },
  { judet: "Brasov", localitate: "Savastreni" },
  { judet: "Brasov", localitate: "Halmeag" },
  { judet: "Brasov", localitate: "Vad" },
  { judet: "Brasov", localitate: "Bucium" },
  { judet: "Brasov", localitate: "Ohaba" },
  { judet: "Brasov", localitate: "Persani" },
  { judet: "Brasov", localitate: "Sercaita" },
  { judet: "Brasov", localitate: "Valcea" },
  { judet: "Brasov", localitate: "Paltin" },
  { judet: "Brasov", localitate: "Barcut" },
  { judet: "Brasov", localitate: "Felmer" },
  { judet: "Brasov", localitate: "Rodbav" },
  { judet: "Brasov", localitate: "Selistat" },
  { judet: "Brasov", localitate: "Carpinis" },
  { judet: "Brasov", localitate: "Purcareni" },
  { judet: "Brasov", localitate: "Zizin" },
  { judet: "Brasov", localitate: "Cobor" },
  { judet: "Brasov", localitate: "Ticusu Vechi" },
  { judet: "Brasov", localitate: "Corbi" },
  { judet: "Brasov", localitate: "Feldioara (Ucea)" },
  { judet: "Brasov", localitate: "Ucea de Jos" },
  { judet: "Brasov", localitate: "Ucea de Sus" },
  { judet: "Brasov", localitate: "Daisoara" },
  { judet: "Brasov", localitate: "Acris" },
  { judet: "Brasov", localitate: "Buzaiel" },
  { judet: "Brasov", localitate: "Dalghiu" },
  { judet: "Brasov", localitate: "Oltet" },
  { judet: "Brasov", localitate: "Rucar" },
  { judet: "Brasov", localitate: "Vistea de Jos" },
  { judet: "Brasov", localitate: "Vistea de Sus" },
  { judet: "Brasov", localitate: "Vistisoara" },
  { judet: "Brasov", localitate: "Cincsor" },
  { judet: "Brasov", localitate: "Dridif" },
  { judet: "Brasov", localitate: "Ludisor" },
  { judet: "Brasov", localitate: "Sambata de Jos" },
  { judet: "Brasov", localitate: "Statiunea Climaterica Sambata" },
  { judet: "Brasov", localitate: "Voivodeni" },
  { judet: "Brasov", localitate: "Colonia 1 Mai" },
  { judet: "BUCURESTI", localitate: "Sector 1" },
  { judet: "BUCURESTI", localitate: "Sector 2" },
  { judet: "BUCURESTI", localitate: "Sector 3" },
  { judet: "BUCURESTI", localitate: "Sector 4" },
  { judet: "BUCURESTI", localitate: "Sector 5" },
  { judet: "BUCURESTI", localitate: "Sector 6" },
  { judet: "Buzau", localitate: "Buzau" },
  { judet: "Buzau", localitate: "Ramnicu Sarat" },
  { judet: "Buzau", localitate: "Patarlagele" },
  { judet: "Buzau", localitate: "Berca" },
  { judet: "Buzau", localitate: "Nehoiu" },
  { judet: "Buzau", localitate: "Pogoanele" },
  { judet: "Buzau", localitate: "Amaru" },
  { judet: "Buzau", localitate: "Balaceanu" },
  { judet: "Buzau", localitate: "Balta Alba" },
  { judet: "Buzau", localitate: "Beceni" },
  { judet: "Buzau", localitate: "Bisoca" },
  { judet: "Buzau", localitate: "Blajani" },
  { judet: "Buzau", localitate: "Boldu" },
  { judet: "Buzau", localitate: "Bozioru" },
  { judet: "Buzau", localitate: "Bradeanu" },
  { judet: "Buzau", localitate: "Braesti" },
  { judet: "Buzau", localitate: "Breaza" },
  { judet: "Buzau", localitate: "Buda" },
  { judet: "Buzau", localitate: "C.A. Rosetti" },
  { judet: "Buzau", localitate: "Calvini" },
  { judet: "Buzau", localitate: "Canesti" },
  { judet: "Buzau", localitate: "Catina" },
  { judet: "Buzau", localitate: "Cernatesti" },
  { judet: "Buzau", localitate: "Chiliile" },
  { judet: "Buzau", localitate: "Chiojdu" },
  { judet: "Buzau", localitate: "Cilibia" },
  { judet: "Buzau", localitate: "Cislau" },
  { judet: "Buzau", localitate: "Cochirleanca" },
  { judet: "Buzau", localitate: "Colti" },
  { judet: "Buzau", localitate: "Costesti" },
  { judet: "Buzau", localitate: "Cozieni" },
  { judet: "Buzau", localitate: "Florica" },
  { judet: "Buzau", localitate: "Galbinasi" },
  { judet: "Buzau", localitate: "Gheraseni" },
  { judet: "Buzau", localitate: "Ghergheasa" },
  { judet: "Buzau", localitate: "Glodeanu Sarat" },
  { judet: "Buzau", localitate: "Glodeanu-Silistea" },
  { judet: "Buzau", localitate: "Grebanu" },
  { judet: "Buzau", localitate: "Gura Teghii" },
  { judet: "Buzau", localitate: "Largu" },
  { judet: "Buzau", localitate: "Lopatari" },
  { judet: "Buzau", localitate: "Luciu" },
  { judet: "Buzau", localitate: "Magura" },
  { judet: "Buzau", localitate: "Maracineni" },
  { judet: "Buzau", localitate: "Margaritesti" },
  { judet: "Buzau", localitate: "Manzalesti" },
  { judet: "Buzau", localitate: "Merei" },
  { judet: "Buzau", localitate: "Mihailesti" },
  { judet: "Buzau", localitate: "Movila Banului" },
  { judet: "Buzau", localitate: "Murgesti" },
  { judet: "Buzau", localitate: "Naeni" },
  { judet: "Buzau", localitate: "Odaile" },
  { judet: "Buzau", localitate: "Padina" },
  { judet: "Buzau", localitate: "Pardosi" },
  { judet: "Buzau", localitate: "Panatau" },
  { judet: "Buzau", localitate: "Parscov" },
  { judet: "Buzau", localitate: "Pietroasele" },
  { judet: "Buzau", localitate: "Podgoria" },
  { judet: "Buzau", localitate: "Posta Calnau" },
  { judet: "Buzau", localitate: "Puiesti" },
  { judet: "Buzau", localitate: "Racoviteni" },
  { judet: "Buzau", localitate: "Ramnicelu" },
  { judet: "Buzau", localitate: "Robeasca" },
  { judet: "Buzau", localitate: "Rusetu" },
  { judet: "Buzau", localitate: "Sageata" },
  { judet: "Buzau", localitate: "Sahateni" },
  { judet: "Buzau", localitate: "Sapoca" },
  { judet: "Buzau", localitate: "Sarulesti" },
  { judet: "Buzau", localitate: "Scortoasa" },
  { judet: "Buzau", localitate: "Scutelnici" },
  { judet: "Buzau", localitate: "Siriu" },
  { judet: "Buzau", localitate: "Smeeni" },
  { judet: "Buzau", localitate: "Stalpu" },
  { judet: "Buzau", localitate: "Tisau" },
  { judet: "Buzau", localitate: "Topliceni" },
  { judet: "Buzau", localitate: "Tintesti" },
  { judet: "Buzau", localitate: "Ulmeni" },
  { judet: "Buzau", localitate: "Unguriu" },
  { judet: "Buzau", localitate: "Vadu Pasii" },
  { judet: "Buzau", localitate: "Valea Ramnicului" },
  { judet: "Buzau", localitate: "Valea Salciei" },
  { judet: "Buzau", localitate: "Valcelele" },
  { judet: "Buzau", localitate: "Vernesti" },
  { judet: "Buzau", localitate: "Vintila Voda" },
  { judet: "Buzau", localitate: "Viperesti" },
  { judet: "Buzau", localitate: "Zarnesti" },
  { judet: "Buzau", localitate: "Ziduri" },
  { judet: "Buzau", localitate: "Basca Rozilei" },
  { judet: "Buzau", localitate: "Chirlesti" },
  { judet: "Buzau", localitate: "Curmatura" },
  { judet: "Buzau", localitate: "Lunca Priporului" },
  { judet: "Buzau", localitate: "Mlajet" },
  { judet: "Buzau", localitate: "Nehoiasu" },
  { judet: "Buzau", localitate: "Paltineni" },
  { judet: "Buzau", localitate: "Stanila" },
  { judet: "Buzau", localitate: "Vinetisu" },
  { judet: "Buzau", localitate: "Caldarasti" },
  { judet: "Buzau", localitate: "Campeni" },
  { judet: "Buzau", localitate: "Dulbanu" },
  { judet: "Buzau", localitate: "Lacu Sinaia" },
  { judet: "Buzau", localitate: "Lunca (Amaru)" },
  { judet: "Buzau", localitate: "Scorteanca" },
  { judet: "Buzau", localitate: "Amara" },
  { judet: "Buzau", localitate: "Baile" },
  { judet: "Buzau", localitate: "Stavarasti" },
  { judet: "Buzau", localitate: "Arbanasi" },
  { judet: "Buzau", localitate: "Carpinistea" },
  { judet: "Buzau", localitate: "Dogari" },
  { judet: "Buzau", localitate: "Floresti" },
  { judet: "Buzau", localitate: "Gura Dimienii" },
  { judet: "Buzau", localitate: "Izvoru Dulce (Beceni)" },
  { judet: "Buzau", localitate: "Margariti" },
  { judet: "Buzau", localitate: "Valea Parului" },
  { judet: "Buzau", localitate: "Baceni" },
  { judet: "Buzau", localitate: "Cojanu" },
  { judet: "Buzau", localitate: "Joseni" },
  { judet: "Buzau", localitate: "Manastirea Ratesti" },
  { judet: "Buzau", localitate: "Paclele" },
  { judet: "Buzau", localitate: "Plescoi" },
  { judet: "Buzau", localitate: "Plesesti (Berca)" },
  { judet: "Buzau", localitate: "Ratesti" },
  { judet: "Buzau", localitate: "Satuc" },
  { judet: "Buzau", localitate: "Tatarligu" },
  { judet: "Buzau", localitate: "Valea Nucului" },
  { judet: "Buzau", localitate: "Viforata" },
  { judet: "Buzau", localitate: "Baltagari" },
  { judet: "Buzau", localitate: "Lacurile" },
  { judet: "Buzau", localitate: "Lopatareasa" },
  { judet: "Buzau", localitate: "Plesi" },
  { judet: "Buzau", localitate: "Recea" },
  { judet: "Buzau", localitate: "Sarile" },
  { judet: "Buzau", localitate: "Sindrila" },
  { judet: "Buzau", localitate: "Soresti" },
  { judet: "Buzau", localitate: "Buduile" },
  { judet: "Buzau", localitate: "Fisici" },
  { judet: "Buzau", localitate: "Gavanele" },
  { judet: "Buzau", localitate: "Gresia" },
  { judet: "Buzau", localitate: "Izvoarele" },
  { judet: "Buzau", localitate: "Nucu" },
  { judet: "Buzau", localitate: "Scaeni" },
  { judet: "Buzau", localitate: "Ulmet" },
  { judet: "Buzau", localitate: "Vavalucile" },
  { judet: "Buzau", localitate: "Mitropolia" },
  { judet: "Buzau", localitate: "Smardan" },
  { judet: "Buzau", localitate: "Bratilesti" },
  { judet: "Buzau", localitate: "Goidesti" },
  { judet: "Buzau", localitate: "Ivanetu" },
  { judet: "Buzau", localitate: "Parscovelu" },
  { judet: "Buzau", localitate: "Pinu" },
  { judet: "Buzau", localitate: "Ruginoasa" },
  { judet: "Buzau", localitate: "Badeni" },
  { judet: "Buzau", localitate: "Greceanca" },
  { judet: "Buzau", localitate: "Valeanca-Vilanesti" },
  { judet: "Buzau", localitate: "Vispesti" },
  { judet: "Buzau", localitate: "Alexandru Odobescu" },
  { judet: "Buzau", localitate: "Danulesti" },
  { judet: "Buzau", localitate: "Mucesti-Danulesti" },
  { judet: "Buzau", localitate: "Spidele" },
  { judet: "Buzau", localitate: "Toropalesti" },
  { judet: "Buzau", localitate: "Valea Larga" },
  { judet: "Buzau", localitate: "Balteni" },
  { judet: "Buzau", localitate: "Balhacu" },
  { judet: "Buzau", localitate: "Cotu Ciorii" },
  { judet: "Buzau", localitate: "Lunca (C.A. Rosetti)" },
  { judet: "Buzau", localitate: "Vizireni" },
  { judet: "Buzau", localitate: "Bascenii de Jos" },
  { judet: "Buzau", localitate: "Bascenii de Sus" },
  { judet: "Buzau", localitate: "Frasinet" },
  { judet: "Buzau", localitate: "Olari" },
  { judet: "Buzau", localitate: "Gontesti" },
  { judet: "Buzau", localitate: "Negosina" },
  { judet: "Buzau", localitate: "Pacurile" },
  { judet: "Buzau", localitate: "Suchea" },
  { judet: "Buzau", localitate: "Valea Verzei" },
  { judet: "Buzau", localitate: "Corbu (Catina)" },
  { judet: "Buzau", localitate: "Slobozia" },
  { judet: "Buzau", localitate: "Valea Catinei" },
  { judet: "Buzau", localitate: "Zeletin" },
  { judet: "Buzau", localitate: "Aldeni" },
  { judet: "Buzau", localitate: "Baesti" },
  { judet: "Buzau", localitate: "Caldarusa" },
  { judet: "Buzau", localitate: "Fulga" },
  { judet: "Buzau", localitate: "Manasia" },
  { judet: "Buzau", localitate: "Vladeni" },
  { judet: "Buzau", localitate: "Zarnestii de Slanic" },
  { judet: "Buzau", localitate: "Budesti" },
  { judet: "Buzau", localitate: "Crevelesti" },
  { judet: "Buzau", localitate: "Ghiocari" },
  { judet: "Buzau", localitate: "Glodu-Petcari" },
  { judet: "Buzau", localitate: "Poiana Pletari" },
  { judet: "Buzau", localitate: "Trestioara (Chiliile)" },
  { judet: "Buzau", localitate: "Basca Chiojdului" },
  { judet: "Buzau", localitate: "Catiasu" },
  { judet: "Buzau", localitate: "Lera" },
  { judet: "Buzau", localitate: "Plescioara" },
  { judet: "Buzau", localitate: "Poenitele" },
  { judet: "Buzau", localitate: "Gara Cilibia" },
  { judet: "Buzau", localitate: "Manzu" },
  { judet: "Buzau", localitate: "Movila Oii" },
  { judet: "Buzau", localitate: "Posta (Cilibia)" },
  { judet: "Buzau", localitate: "Barasti" },
  { judet: "Buzau", localitate: "Buda Craciunesti" },
  { judet: "Buzau", localitate: "Gura Bascei" },
  { judet: "Buzau", localitate: "Scarisoara" },
  { judet: "Buzau", localitate: "Boboc" },
  { judet: "Buzau", localitate: "Gara Bobocu" },
  { judet: "Buzau", localitate: "Rosioru" },
  { judet: "Buzau", localitate: "Tarlele" },
  { judet: "Buzau", localitate: "Alunis" },
  { judet: "Buzau", localitate: "Coltii de Jos" },
  { judet: "Buzau", localitate: "Muscelu Caramanesti" },
  { judet: "Buzau", localitate: "Budisteni" },
  { judet: "Buzau", localitate: "Gomoesti" },
  { judet: "Buzau", localitate: "Grosani" },
  { judet: "Buzau", localitate: "Pietrosu" },
  { judet: "Buzau", localitate: "Spataru" },
  { judet: "Buzau", localitate: "Anini" },
  { judet: "Buzau", localitate: "Balanesti" },
  { judet: "Buzau", localitate: "Bercesti" },
  { judet: "Buzau", localitate: "Ciocanesti" },
  { judet: "Buzau", localitate: "Cocarceni" },
  { judet: "Buzau", localitate: "Colteni" },
  { judet: "Buzau", localitate: "Fata lui Nan" },
  { judet: "Buzau", localitate: "Glodurile" },
  { judet: "Buzau", localitate: "Izvoru (Cozieni)" },
  { judet: "Buzau", localitate: "Lungesti" },
  { judet: "Buzau", localitate: "Nistoresti" },
  { judet: "Buzau", localitate: "Pietraru" },
  { judet: "Buzau", localitate: "Punga" },
  { judet: "Buzau", localitate: "Teisu" },
  { judet: "Buzau", localitate: "Trestia" },
  { judet: "Buzau", localitate: "Tulburea" },
  { judet: "Buzau", localitate: "Valea Banului" },
  { judet: "Buzau", localitate: "Valea Roatei" },
  { judet: "Buzau", localitate: "Zapodia" },
  { judet: "Buzau", localitate: "Bentu" },
  { judet: "Buzau", localitate: "Tabarasti" },
  { judet: "Buzau", localitate: "Suditi (Gheraseni)" },
  { judet: "Buzau", localitate: "Salcioara" },
  { judet: "Buzau", localitate: "Caldaruseanca" },
  { judet: "Buzau", localitate: "Ileana" },
  { judet: "Buzau", localitate: "Pitulicea" },
  { judet: "Buzau", localitate: "Casota" },
  { judet: "Buzau", localitate: "Carligu Mare" },
  { judet: "Buzau", localitate: "Carligu Mic" },
  { judet: "Buzau", localitate: "Corbu (Glodeanu-Silistea)" },
  { judet: "Buzau", localitate: "Cotorca" },
  { judet: "Buzau", localitate: "Satu Nou (Glodeanu-Silistea)" },
  { judet: "Buzau", localitate: "Vacareasca" },
  { judet: "Buzau", localitate: "Homesti" },
  { judet: "Buzau", localitate: "Livada" },
  { judet: "Buzau", localitate: "Livada Mica" },
  { judet: "Buzau", localitate: "Plevna" },
  { judet: "Buzau", localitate: "Zaplazi" },
  { judet: "Buzau", localitate: "Furtunesti" },
  { judet: "Buzau", localitate: "Nemertea" },
  { judet: "Buzau", localitate: "Paltinis" },
  { judet: "Buzau", localitate: "Secuiu" },
  { judet: "Buzau", localitate: "Vadu Oii" },
  { judet: "Buzau", localitate: "Varlaam" },
  { judet: "Buzau", localitate: "Scarlatesti" },
  { judet: "Buzau", localitate: "Brebu" },
  { judet: "Buzau", localitate: "Fundata" },
  { judet: "Buzau", localitate: "Luncile" },
  { judet: "Buzau", localitate: "Pestritu" },
  { judet: "Buzau", localitate: "Plaiu Nucului" },
  { judet: "Buzau", localitate: "Plostina" },
  { judet: "Buzau", localitate: "Potecu" },
  { judet: "Buzau", localitate: "Sareni" },
  { judet: "Buzau", localitate: "Terca" },
  { judet: "Buzau", localitate: "Varteju" },
  { judet: "Buzau", localitate: "Caragele" },
  { judet: "Buzau", localitate: "Ciuta" },
  { judet: "Buzau", localitate: "Ojasca" },
  { judet: "Buzau", localitate: "Capatanesti" },
  { judet: "Buzau", localitate: "Potoceni" },
  { judet: "Buzau", localitate: "Campulungeanca" },
  { judet: "Buzau", localitate: "Fantanele (Margaritesti)" },
  { judet: "Buzau", localitate: "Beslii" },
  { judet: "Buzau", localitate: "Bustea" },
  { judet: "Buzau", localitate: "Ciresu" },
  { judet: "Buzau", localitate: "Ghizdita" },
  { judet: "Buzau", localitate: "Gura Badicului" },
  { judet: "Buzau", localitate: "Jghiab" },
  { judet: "Buzau", localitate: "Plavatu" },
  { judet: "Buzau", localitate: "Poiana Valcului" },
  { judet: "Buzau", localitate: "Satu Vechi" },
  { judet: "Buzau", localitate: "Trestioara (Manzalesti)" },
  { judet: "Buzau", localitate: "Valea Cotoarei" },
  { judet: "Buzau", localitate: "Valea Ursului" },
  { judet: "Buzau", localitate: "Ciobanoaia" },
  { judet: "Buzau", localitate: "Dealu Viei" },
  { judet: "Buzau", localitate: "Dobrilesti" },
  { judet: "Buzau", localitate: "Gura Saratii" },
  { judet: "Buzau", localitate: "Izvoru Dulce (Merei)" },
  { judet: "Buzau", localitate: "Lipia" },
  { judet: "Buzau", localitate: "Nenciulesti" },
  { judet: "Buzau", localitate: "Ograzile" },
  { judet: "Buzau", localitate: "Sarata-Monteoru" },
  { judet: "Buzau", localitate: "Valea Putului Merei" },
  { judet: "Buzau", localitate: "Coltaneni" },
  { judet: "Buzau", localitate: "Margineanu" },
  { judet: "Buzau", localitate: "Satu Nou (Mihailesti)" },
  { judet: "Buzau", localitate: "Cioranca" },
  { judet: "Buzau", localitate: "Limpezis" },
  { judet: "Buzau", localitate: "Batogu" },
  { judet: "Buzau", localitate: "Valea Ratei" },
  { judet: "Buzau", localitate: "Fantanele (Naeni)" },
  { judet: "Buzau", localitate: "Fintesti" },
  { judet: "Buzau", localitate: "Prosca" },
  { judet: "Buzau", localitate: "Varf" },
  { judet: "Buzau", localitate: "Capu Satului" },
  { judet: "Buzau", localitate: "Corneanu" },
  { judet: "Buzau", localitate: "Gorani" },
  { judet: "Buzau", localitate: "Lacu" },
  { judet: "Buzau", localitate: "Piatra Alba" },
  { judet: "Buzau", localitate: "Posobesti" },
  { judet: "Buzau", localitate: "Scorosesti" },
  { judet: "Buzau", localitate: "Valea Fantanei" },
  { judet: "Buzau", localitate: "Valea Stefanului" },
  { judet: "Buzau", localitate: "Tatulesti" },
  { judet: "Buzau", localitate: "Chiperu" },
  { judet: "Buzau", localitate: "Costomiru" },
  { judet: "Buzau", localitate: "Valea Schiopului" },
  { judet: "Buzau", localitate: "Valea lui Lalu" },
  { judet: "Buzau", localitate: "Begu" },
  { judet: "Buzau", localitate: "Lacu cu Anini" },
  { judet: "Buzau", localitate: "Maguricea" },
  { judet: "Buzau", localitate: "Plaisor" },
  { judet: "Buzau", localitate: "Rapile" },
  { judet: "Buzau", localitate: "Sibiciu de Jos" },
  { judet: "Buzau", localitate: "Tega" },
  { judet: "Buzau", localitate: "Zaharesti" },
  { judet: "Buzau", localitate: "Calea Chiojdului" },
  { judet: "Buzau", localitate: "Crang" },
  { judet: "Buzau", localitate: "Fundaturile" },
  { judet: "Buzau", localitate: "Gornet" },
  { judet: "Buzau", localitate: "Lunca (Patarlagele)" },
  { judet: "Buzau", localitate: "Manastirea" },
  { judet: "Buzau", localitate: "Maruntisu" },
  { judet: "Buzau", localitate: "Muscel" },
  { judet: "Buzau", localitate: "Poienile" },
  { judet: "Buzau", localitate: "Sibiciu de Sus" },
  { judet: "Buzau", localitate: "Stroesti" },
  { judet: "Buzau", localitate: "Valea Lupului" },
  { judet: "Buzau", localitate: "Valea Sibiciului" },
  { judet: "Buzau", localitate: "Valea Viei" },
  { judet: "Buzau", localitate: "Badila" },
  { judet: "Buzau", localitate: "Curcanesti" },
  { judet: "Buzau", localitate: "Lunca Frumoasa" },
  { judet: "Buzau", localitate: "Olesesti" },
  { judet: "Buzau", localitate: "Parjolesti" },
  { judet: "Buzau", localitate: "Robesti" },
  { judet: "Buzau", localitate: "Runcu" },
  { judet: "Buzau", localitate: "Tarcov" },
  { judet: "Buzau", localitate: "Tocileni" },
  { judet: "Buzau", localitate: "Trestieni" },
  { judet: "Buzau", localitate: "Valea Purcarului" },
  { judet: "Buzau", localitate: "Caltesti" },
  { judet: "Buzau", localitate: "Clondiru de Sus" },
  { judet: "Buzau", localitate: "Dara" },
  { judet: "Buzau", localitate: "Pietroasa Mica" },
  { judet: "Buzau", localitate: "Saranga" },
  { judet: "Buzau", localitate: "Cotatcu" },
  { judet: "Buzau", localitate: "Oratia" },
  { judet: "Buzau", localitate: "Plesesti (Podgoria)" },
  { judet: "Buzau", localitate: "Tabacari" },
  { judet: "Buzau", localitate: "Aliceni" },
  { judet: "Buzau", localitate: "Coconari" },
  { judet: "Buzau", localitate: "Potarnichesti" },
  { judet: "Buzau", localitate: "Suditi (Posta Calnau)" },
  { judet: "Buzau", localitate: "Zilisteanca" },
  { judet: "Buzau", localitate: "Dascalesti" },
  { judet: "Buzau", localitate: "Lunca (Puiesti)" },
  { judet: "Buzau", localitate: "Macrina" },
  { judet: "Buzau", localitate: "Nicolesti" },
  { judet: "Buzau", localitate: "Plopi" },
  { judet: "Buzau", localitate: "Puiestii de Jos" },
  { judet: "Buzau", localitate: "Puiestii de Sus" },
  { judet: "Buzau", localitate: "Budrea" },
  { judet: "Buzau", localitate: "Petrisoru" },
  { judet: "Buzau", localitate: "Colibasi" },
  { judet: "Buzau", localitate: "Fotin" },
  { judet: "Buzau", localitate: "Stiubei" },
  { judet: "Buzau", localitate: "Mosesti" },
  { judet: "Buzau", localitate: "Sergent Ionel Stefan" },
  { judet: "Buzau", localitate: "Banita" },
  { judet: "Buzau", localitate: "Beilic" },
  { judet: "Buzau", localitate: "Bordusani" },
  { judet: "Buzau", localitate: "Dambroca" },
  { judet: "Buzau", localitate: "Gavanesti" },
  { judet: "Buzau", localitate: "Movilita" },
  { judet: "Buzau", localitate: "Gageni" },
  { judet: "Buzau", localitate: "Istrita de Jos" },
  { judet: "Buzau", localitate: "Vintileanca" },
  { judet: "Buzau", localitate: "Matesti" },
  { judet: "Buzau", localitate: "Caratnau de Jos" },
  { judet: "Buzau", localitate: "Caratnau de Sus" },
  { judet: "Buzau", localitate: "Goicelu" },
  { judet: "Buzau", localitate: "Sarile-Catun" },
  { judet: "Buzau", localitate: "Valea Larga-Sarulesti" },
  { judet: "Buzau", localitate: "Valea Stanei" },
  { judet: "Buzau", localitate: "Balta Tocila" },
  { judet: "Buzau", localitate: "Beciu" },
  { judet: "Buzau", localitate: "Dalma" },
  { judet: "Buzau", localitate: "Deleni" },
  { judet: "Buzau", localitate: "Golu Grabicina" },
  { judet: "Buzau", localitate: "Grabicina de Jos" },
  { judet: "Buzau", localitate: "Grabicina de Sus" },
  { judet: "Buzau", localitate: "Gura Vaii" },
  { judet: "Buzau", localitate: "Plopeasa" },
  { judet: "Buzau", localitate: "Policiori" },
  { judet: "Buzau", localitate: "Arcanu" },
  { judet: "Buzau", localitate: "Bragareasa" },
  { judet: "Buzau", localitate: "Lipanescu" },
  { judet: "Buzau", localitate: "Casoca" },
  { judet: "Buzau", localitate: "Coltu Pietrii" },
  { judet: "Buzau", localitate: "Gura Siriului" },
  { judet: "Buzau", localitate: "Muscelusa" },
  { judet: "Buzau", localitate: "Lunca Jaristei" },
  { judet: "Buzau", localitate: "Albesti" },
  { judet: "Buzau", localitate: "Balaia" },
  { judet: "Buzau", localitate: "Caltuna" },
  { judet: "Buzau", localitate: "Moisica" },
  { judet: "Buzau", localitate: "Udati-Lucieni" },
  { judet: "Buzau", localitate: "Udati-Manzu" },
  { judet: "Buzau", localitate: "Barbuncesti" },
  { judet: "Buzau", localitate: "Grajdana" },
  { judet: "Buzau", localitate: "Hales" },
  { judet: "Buzau", localitate: "Izvoranu" },
  { judet: "Buzau", localitate: "Izvoru (Tisau)" },
  { judet: "Buzau", localitate: "Leiculesti" },
  { judet: "Buzau", localitate: "Padurenii" },
  { judet: "Buzau", localitate: "Salcia" },
  { judet: "Buzau", localitate: "Strezeni" },
  { judet: "Buzau", localitate: "Valea Salciilor" },
  { judet: "Buzau", localitate: "Babeni" },
  { judet: "Buzau", localitate: "Ceairu" },
  { judet: "Buzau", localitate: "Dedulesti" },
  { judet: "Buzau", localitate: "Gura Fagetului" },
  { judet: "Buzau", localitate: "Posta (Topliceni)" },
  { judet: "Buzau", localitate: "Raducesti" },
  { judet: "Buzau", localitate: "Maxenu" },
  { judet: "Buzau", localitate: "Odaia Banului" },
  { judet: "Buzau", localitate: "Pogonele" },
  { judet: "Buzau", localitate: "Baltareti" },
  { judet: "Buzau", localitate: "Clondiru" },
  { judet: "Buzau", localitate: "Sarata" },
  { judet: "Buzau", localitate: "Valcele" },
  { judet: "Buzau", localitate: "Bajani" },
  { judet: "Buzau", localitate: "Focsanei" },
  { judet: "Buzau", localitate: "Gura Calnaului" },
  { judet: "Buzau", localitate: "Scurtesti" },
  { judet: "Buzau", localitate: "Stancesti" },
  { judet: "Buzau", localitate: "Oreavul" },
  { judet: "Buzau", localitate: "Rubla" },
  { judet: "Buzau", localitate: "Modreni" },
  { judet: "Buzau", localitate: "Valea Salciei-Catun" },
  { judet: "Buzau", localitate: "Bradeanca" },
  { judet: "Buzau", localitate: "Candesti" },
  { judet: "Buzau", localitate: "Carlomanesti" },
  { judet: "Buzau", localitate: "Mierea" },
  { judet: "Buzau", localitate: "Nenciu" },
  { judet: "Buzau", localitate: "Niscov" },
  { judet: "Buzau", localitate: "Sasenii Noi" },
  { judet: "Buzau", localitate: "Sasenii Vechi" },
  { judet: "Buzau", localitate: "Sasenii pe Vale" },
  { judet: "Buzau", localitate: "Zoresti" },
  { judet: "Buzau", localitate: "Bodinesti" },
  { judet: "Buzau", localitate: "Coca-Antimiresti" },
  { judet: "Buzau", localitate: "Coca-Niculesti" },
  { judet: "Buzau", localitate: "Niculesti" },
  { judet: "Buzau", localitate: "Petrachesti" },
  { judet: "Buzau", localitate: "Podu Muncii" },
  { judet: "Buzau", localitate: "Sarbesti" },
  { judet: "Buzau", localitate: "Smeesti" },
  { judet: "Buzau", localitate: "Muscel" },
  { judet: "Buzau", localitate: "Palici" },
  { judet: "Buzau", localitate: "Rusavat" },
  { judet: "Buzau", localitate: "Tronari" },
  { judet: "Buzau", localitate: "Ursoaia" },
  { judet: "Buzau", localitate: "Comisoaia" },
  { judet: "Buzau", localitate: "Fundeni" },
  { judet: "Buzau", localitate: "Pruneni" },
  { judet: "Buzau", localitate: "Vadu Soresti" },
  { judet: "Buzau", localitate: "Costieni" },
  { judet: "Buzau", localitate: "Cuculeasa" },
  { judet: "Buzau", localitate: "Heliade Radulescu" },
  { judet: "Buzau", localitate: "Lanurile" },
  { judet: "Buzau", localitate: "Zoita" },
  { judet: "Calarasi", localitate: "Calarasi" },
  { judet: "Calarasi", localitate: "Oltenita" },
  { judet: "Calarasi", localitate: "Dragalina" },
  { judet: "Calarasi", localitate: "Budesti" },
  { judet: "Calarasi", localitate: "Fundulea" },
  { judet: "Calarasi", localitate: "Lehliu Gara" },
  { judet: "Calarasi", localitate: "Alexandru Odobescu" },
  { judet: "Calarasi", localitate: "Belciugatele" },
  { judet: "Calarasi", localitate: "Borcea" },
  { judet: "Calarasi", localitate: "Cascioarele" },
  { judet: "Calarasi", localitate: "Chirnogi" },
  { judet: "Calarasi", localitate: "Chiselet" },
  { judet: "Calarasi", localitate: "Ciocanesti" },
  { judet: "Calarasi", localitate: "Crivat" },
  { judet: "Calarasi", localitate: "Curcani" },
  { judet: "Calarasi", localitate: "Cuza Voda" },
  { judet: "Calarasi", localitate: "Dichiseni" },
  { judet: "Calarasi", localitate: "Dor Marunt" },
  { judet: "Calarasi", localitate: "Dorobantu" },
  { judet: "Calarasi", localitate: "Dragos Voda" },
  { judet: "Calarasi", localitate: "Frasinet" },
  { judet: "Calarasi", localitate: "Frumusani" },
  { judet: "Calarasi", localitate: "Fundeni" },
  { judet: "Calarasi", localitate: "Galbinasi" },
  { judet: "Calarasi", localitate: "Gradistea" },
  { judet: "Calarasi", localitate: "Gurbanesti" },
  { judet: "Calarasi", localitate: "Ileana" },
  { judet: "Calarasi", localitate: "Independenta" },
  { judet: "Calarasi", localitate: "Jegalia" },
  { judet: "Calarasi", localitate: "Lehliu" },
  { judet: "Calarasi", localitate: "Luica" },
  { judet: "Calarasi", localitate: "Lupsanu" },
  { judet: "Calarasi", localitate: "Manastirea" },
  { judet: "Calarasi", localitate: "Mitreni" },
  { judet: "Calarasi", localitate: "Modelu" },
  { judet: "Calarasi", localitate: "Nana" },
  { judet: "Calarasi", localitate: "Nicolae Balcescu" },
  { judet: "Calarasi", localitate: "Perisoru" },
  { judet: "Calarasi", localitate: "Plataresti" },
  { judet: "Calarasi", localitate: "Radovanu" },
  { judet: "Calarasi", localitate: "Roseti" },
  { judet: "Calarasi", localitate: "Sarulesti" },
  { judet: "Calarasi", localitate: "Sohatu" },
  { judet: "Calarasi", localitate: "Spantov" },
  { judet: "Calarasi", localitate: "Soldanu" },
  { judet: "Calarasi", localitate: "Stefan cel Mare" },
  { judet: "Calarasi", localitate: "Stefan Voda" },
  { judet: "Calarasi", localitate: "Tamadau Mare" },
  { judet: "Calarasi", localitate: "Ulmeni" },
  { judet: "Calarasi", localitate: "Ulmu" },
  { judet: "Calarasi", localitate: "Unirea" },
  { judet: "Calarasi", localitate: "Valea Argovei" },
  { judet: "Calarasi", localitate: "Vasilati" },
  { judet: "Calarasi", localitate: "Valcelele" },
  { judet: "Calarasi", localitate: "Vlad Tepes" },
  { judet: "Calarasi", localitate: "Aprozi" },
  { judet: "Calarasi", localitate: "Buciumeni" },
  { judet: "Calarasi", localitate: "Gruiu" },
  { judet: "Calarasi", localitate: "Alexandru Ioan Cuza" },
  { judet: "Calarasi", localitate: "Gostilele" },
  { judet: "Calarasi", localitate: "Buzoeni" },
  { judet: "Calarasi", localitate: "Razvani" },
  { judet: "Calarasi", localitate: "Valea Seaca" },
  { judet: "Calarasi", localitate: "Galatui" },
  { judet: "Calarasi", localitate: "Nicolae Balcescu (Alexandru Odobescu)" },
  { judet: "Calarasi", localitate: "Candeasca" },
  { judet: "Calarasi", localitate: "Cojesti" },
  { judet: "Calarasi", localitate: "Mataraua" },
  { judet: "Calarasi", localitate: "Mariuta" },
  { judet: "Calarasi", localitate: "Salcioara" },
  { judet: "Calarasi", localitate: "Calarasii Vechi" },
  { judet: "Calarasi", localitate: "Ceacu" },
  { judet: "Calarasi", localitate: "Coslogeni" },
  { judet: "Calarasi", localitate: "Libertatea" },
  { judet: "Calarasi", localitate: "Satnoeni" },
  { judet: "Calarasi", localitate: "Dalga" },
  { judet: "Calarasi", localitate: "Dalga-Gara" },
  { judet: "Calarasi", localitate: "Infratirea" },
  { judet: "Calarasi", localitate: "Ogoru" },
  { judet: "Calarasi", localitate: "Pelinu" },
  { judet: "Calarasi", localitate: "Bosneagu" },
  { judet: "Calarasi", localitate: "Varasti" },
  { judet: "Calarasi", localitate: "Ciulnita-Gara" },
  { judet: "Calarasi", localitate: "Constantin Brancoveanu" },
  { judet: "Calarasi", localitate: "Drajna Noua" },
  { judet: "Calarasi", localitate: "Bogdana" },
  { judet: "Calarasi", localitate: "Socoalele" },
  { judet: "Calarasi", localitate: "Curatesti" },
  { judet: "Calarasi", localitate: "Danesti" },
  { judet: "Calarasi", localitate: "Frasinetu de Jos" },
  { judet: "Calarasi", localitate: "Luptatori" },
  { judet: "Calarasi", localitate: "Tariceni" },
  { judet: "Calarasi", localitate: "Orasti" },
  { judet: "Calarasi", localitate: "Pasarea" },
  { judet: "Calarasi", localitate: "Padurisu" },
  { judet: "Calarasi", localitate: "Pitigaia" },
  { judet: "Calarasi", localitate: "Postavari" },
  { judet: "Calarasi", localitate: "Bogata" },
  { judet: "Calarasi", localitate: "Cunesti" },
  { judet: "Calarasi", localitate: "Rasa" },
  { judet: "Calarasi", localitate: "Codreni" },
  { judet: "Calarasi", localitate: "Cotofanca" },
  { judet: "Calarasi", localitate: "Preasna" },
  { judet: "Calarasi", localitate: "Preasna Veche" },
  { judet: "Calarasi", localitate: "Valea Presnei" },
  { judet: "Calarasi", localitate: "Artari" },
  { judet: "Calarasi", localitate: "Florica" },
  { judet: "Calarasi", localitate: "Podari" },
  { judet: "Calarasi", localitate: "Rasurile" },
  { judet: "Calarasi", localitate: "Razoarele" },
  { judet: "Calarasi", localitate: "Satu Nou" },
  { judet: "Calarasi", localitate: "Stefanesti" },
  { judet: "Calarasi", localitate: "Vlaiculesti" },
  { judet: "Calarasi", localitate: "Potcoava" },
  { judet: "Calarasi", localitate: "Visinii" },
  { judet: "Calarasi", localitate: "Galdau" },
  { judet: "Calarasi", localitate: "Iezeru" },
  { judet: "Calarasi", localitate: "Sapunari" },
  { judet: "Calarasi", localitate: "Valea Stanii" },
  { judet: "Calarasi", localitate: "Nucetu" },
  { judet: "Calarasi", localitate: "Plevna" },
  { judet: "Calarasi", localitate: "Valea Rusului" },
  { judet: "Calarasi", localitate: "Radu Voda" },
  { judet: "Calarasi", localitate: "Coconi" },
  { judet: "Calarasi", localitate: "Sultana" },
  { judet: "Calarasi", localitate: "Clatesti" },
  { judet: "Calarasi", localitate: "Valea Rosie" },
  { judet: "Calarasi", localitate: "Radu Negru" },
  { judet: "Calarasi", localitate: "Stoenesti" },
  { judet: "Calarasi", localitate: "Tonea" },
  { judet: "Calarasi", localitate: "Fantana Doamnei" },
  { judet: "Calarasi", localitate: "Paicu" },
  { judet: "Calarasi", localitate: "Marculesti-Gara" },
  { judet: "Calarasi", localitate: "Tudor Vladimirescu" },
  { judet: "Calarasi", localitate: "Cucuieti" },
  { judet: "Calarasi", localitate: "Dorobantu (Plataresti)" },
  { judet: "Calarasi", localitate: "Podu Pitarului" },
  { judet: "Calarasi", localitate: "Valea Popii" },
  { judet: "Calarasi", localitate: "Magureni" },
  { judet: "Calarasi", localitate: "Polcesti" },
  { judet: "Calarasi", localitate: "Sandulita" },
  { judet: "Calarasi", localitate: "Sarulesti-Gara" },
  { judet: "Calarasi", localitate: "Satucu" },
  { judet: "Calarasi", localitate: "Solacolu" },
  { judet: "Calarasi", localitate: "Progresu" },
  { judet: "Calarasi", localitate: "Cetatea Veche" },
  { judet: "Calarasi", localitate: "Stancea" },
  { judet: "Calarasi", localitate: "Negoesti" },
  { judet: "Calarasi", localitate: "Calareti" },
  { judet: "Calarasi", localitate: "Darvari" },
  { judet: "Calarasi", localitate: "Plumbuita" },
  { judet: "Calarasi", localitate: "Sacele" },
  { judet: "Calarasi", localitate: "Seinoiu" },
  { judet: "Calarasi", localitate: "Tamadau Mic" },
  { judet: "Calarasi", localitate: "Chirnogi (Ulmu)" },
  { judet: "Calarasi", localitate: "Faurei" },
  { judet: "Calarasi", localitate: "Zimbru" },
  { judet: "Calarasi", localitate: "Oltina" },
  { judet: "Calarasi", localitate: "Lunca" },
  { judet: "Calarasi", localitate: "Ostrovu" },
  { judet: "Calarasi", localitate: "Silistea" },
  { judet: "Calarasi", localitate: "Vladiceasca" },
  { judet: "Calarasi", localitate: "Nuci" },
  { judet: "Calarasi", localitate: "Popesti" },
  { judet: "Calarasi", localitate: "Floroaica" },
  { judet: "Calarasi", localitate: "Mihai Viteazu" },
  { judet: "Caras Severin", localitate: "Resita" },
  { judet: "Caras Severin", localitate: "Caransebes" },
  { judet: "Caras Severin", localitate: "Bozovici" },
  { judet: "Caras Severin", localitate: "Bocsa" },
  { judet: "Caras Severin", localitate: "Oravita" },
  { judet: "Caras Severin", localitate: "Moldova Noua" },
  { judet: "Caras Severin", localitate: "Otelu Rosu" },
  { judet: "Caras Severin", localitate: "Anina" },
  { judet: "Caras Severin", localitate: "Baile Herculane" },
  { judet: "Caras Severin", localitate: "Armenis" },
  { judet: "Caras Severin", localitate: "Bania" },
  { judet: "Caras Severin", localitate: "Bautar" },
  { judet: "Caras Severin", localitate: "Berliste" },
  { judet: "Caras Severin", localitate: "Berzasca" },
  { judet: "Caras Severin", localitate: "Berzovia" },
  { judet: "Caras Severin", localitate: "Bolvasnita" },
  { judet: "Caras Severin", localitate: "Brebu" },
  { judet: "Caras Severin", localitate: "Brebu Nou" },
  { judet: "Caras Severin", localitate: "Buchin" },
  { judet: "Caras Severin", localitate: "Bucosnita" },
  { judet: "Caras Severin", localitate: "Carasova" },
  { judet: "Caras Severin", localitate: "Carbunari" },
  { judet: "Caras Severin", localitate: "Ciclova Romana" },
  { judet: "Caras Severin", localitate: "Ciuchici" },
  { judet: "Caras Severin", localitate: "Ciudanovita" },
  { judet: "Caras Severin", localitate: "Constantin Daicoviciu" },
  { judet: "Caras Severin", localitate: "Copacele" },
  { judet: "Caras Severin", localitate: "Cornea" },
  { judet: "Caras Severin", localitate: "Cornereva" },
  { judet: "Caras Severin", localitate: "Coronini" },
  { judet: "Caras Severin", localitate: "Dalboset" },
  { judet: "Caras Severin", localitate: "Doclin" },
  { judet: "Caras Severin", localitate: "Dognecea" },
  { judet: "Caras Severin", localitate: "Domasnea" },
  { judet: "Caras Severin", localitate: "Eftimie Murgu" },
  { judet: "Caras Severin", localitate: "Ezeris" },
  { judet: "Caras Severin", localitate: "Farliug" },
  { judet: "Caras Severin", localitate: "Forotic" },
  { judet: "Caras Severin", localitate: "Garnic" },
  { judet: "Caras Severin", localitate: "Glimboca" },
  { judet: "Caras Severin", localitate: "Goruia" },
  { judet: "Caras Severin", localitate: "Gradinari" },
  { judet: "Caras Severin", localitate: "Iablanita" },
  { judet: "Caras Severin", localitate: "Lapusnicel" },
  { judet: "Caras Severin", localitate: "Lapusnicu Mare" },
  { judet: "Caras Severin", localitate: "Luncavita" },
  { judet: "Caras Severin", localitate: "Lupac" },
  { judet: "Caras Severin", localitate: "Marga" },
  { judet: "Caras Severin", localitate: "Maureni" },
  { judet: "Caras Severin", localitate: "Mehadia" },
  { judet: "Caras Severin", localitate: "Mehadica" },
  { judet: "Caras Severin", localitate: "Naidas" },
  { judet: "Caras Severin", localitate: "Obreja" },
  { judet: "Caras Severin", localitate: "Ocna de Fier" },
  { judet: "Caras Severin", localitate: "Paltinis" },
  { judet: "Caras Severin", localitate: "Pojejena" },
  { judet: "Caras Severin", localitate: "Prigor" },
  { judet: "Caras Severin", localitate: "Racasdia" },
  { judet: "Caras Severin", localitate: "Ramna" },
  { judet: "Caras Severin", localitate: "Rusca Montana" },
  { judet: "Caras Severin", localitate: "Sacu" },
  { judet: "Caras Severin", localitate: "Sasca Montana" },
  { judet: "Caras Severin", localitate: "Sichevita" },
  { judet: "Caras Severin", localitate: "Slatina-Timis" },
  { judet: "Caras Severin", localitate: "Socol" },
  { judet: "Caras Severin", localitate: "Sopotu Nou" },
  { judet: "Caras Severin", localitate: "Tarnova" },
  { judet: "Caras Severin", localitate: "Teregova" },
  { judet: "Caras Severin", localitate: "Ticvaniu Mare" },
  { judet: "Caras Severin", localitate: "Toplet" },
  { judet: "Caras Severin", localitate: "Turnu Ruieni" },
  { judet: "Caras Severin", localitate: "Valiug" },
  { judet: "Caras Severin", localitate: "Varadia" },
  { judet: "Caras Severin", localitate: "Vermes" },
  { judet: "Caras Severin", localitate: "Vrani" },
  { judet: "Caras Severin", localitate: "Zavoi" },
  { judet: "Caras Severin", localitate: "Zorlentu Mare" },
  { judet: "Caras Severin", localitate: "Calnic" },
  { judet: "Caras Severin", localitate: "Cuptoare (Resita)" },
  { judet: "Caras Severin", localitate: "Doman" },
  { judet: "Caras Severin", localitate: "Moniom" },
  { judet: "Caras Severin", localitate: "Secu" },
  { judet: "Caras Severin", localitate: "Terova" },
  { judet: "Caras Severin", localitate: "Steierdorf" },
  { judet: "Caras Severin", localitate: "Pecinisca" },
  { judet: "Caras Severin", localitate: "Jupa" },
  { judet: "Caras Severin", localitate: "Macesti" },
  { judet: "Caras Severin", localitate: "Moldovita" },
  { judet: "Caras Severin", localitate: "Moldova Veche" },
  { judet: "Caras Severin", localitate: "Agadici" },
  { judet: "Caras Severin", localitate: "Bradisoru de Jos" },
  { judet: "Caras Severin", localitate: "Brosteni" },
  { judet: "Caras Severin", localitate: "Ciclova Montana" },
  { judet: "Caras Severin", localitate: "Marila" },
  { judet: "Caras Severin", localitate: "Rachitova" },
  { judet: "Caras Severin", localitate: "Ciresa" },
  { judet: "Caras Severin", localitate: "Mal" },
  { judet: "Caras Severin", localitate: "Fenes" },
  { judet: "Caras Severin", localitate: "Plopu" },
  { judet: "Caras Severin", localitate: "Sat Batran" },
  { judet: "Caras Severin", localitate: "Sub Margine" },
  { judet: "Caras Severin", localitate: "Garbovat" },
  { judet: "Caras Severin", localitate: "Bucova" },
  { judet: "Caras Severin", localitate: "Cornisoru" },
  { judet: "Caras Severin", localitate: "Preveciori" },
  { judet: "Caras Severin", localitate: "Iam" },
  { judet: "Caras Severin", localitate: "Milcoveni" },
  { judet: "Caras Severin", localitate: "Rusova Noua" },
  { judet: "Caras Severin", localitate: "Rusova Veche" },
  { judet: "Caras Severin", localitate: "Bigar" },
  { judet: "Caras Severin", localitate: "Cozla" },
  { judet: "Caras Severin", localitate: "Drencova" },
  { judet: "Caras Severin", localitate: "Liubcova" },
  { judet: "Caras Severin", localitate: "Fizes" },
  { judet: "Caras Severin", localitate: "Ghertenis" },
  { judet: "Caras Severin", localitate: "Varciorova" },
  { judet: "Caras Severin", localitate: "Poneasca" },
  { judet: "Caras Severin", localitate: "Prilipet" },
  { judet: "Caras Severin", localitate: "Valea Minisului" },
  { judet: "Caras Severin", localitate: "Apadia" },
  { judet: "Caras Severin", localitate: "Valeadeni" },
  { judet: "Caras Severin", localitate: "Garana" },
  { judet: "Caras Severin", localitate: "Lindenfeld" },
  { judet: "Caras Severin", localitate: "Poiana" },
  { judet: "Caras Severin", localitate: "Prisian" },
  { judet: "Caras Severin", localitate: "Valea Timisului" },
  { judet: "Caras Severin", localitate: "Golet" },
  { judet: "Caras Severin", localitate: "Petrosnita" },
  { judet: "Caras Severin", localitate: "Valisoara" },
  { judet: "Caras Severin", localitate: "Iabalcea" },
  { judet: "Caras Severin", localitate: "Nermed" },
  { judet: "Caras Severin", localitate: "Stinapari" },
  { judet: "Caras Severin", localitate: "Ilidia" },
  { judet: "Caras Severin", localitate: "Socolari" },
  { judet: "Caras Severin", localitate: "Macoviste (Ciuchici)" },
  { judet: "Caras Severin", localitate: "Nicolint" },
  { judet: "Caras Severin", localitate: "Petrilova" },
  { judet: "Caras Severin", localitate: "Jitin" },
  { judet: "Caras Severin", localitate: "Cavaran" },
  { judet: "Caras Severin", localitate: "Maciova" },
  { judet: "Caras Severin", localitate: "Matnicu Mare" },
  { judet: "Caras Severin", localitate: "Pestere" },
  { judet: "Caras Severin", localitate: "Prisaca" },
  { judet: "Caras Severin", localitate: "Zagujeni" },
  { judet: "Caras Severin", localitate: "Ohaba-Matnic" },
  { judet: "Caras Severin", localitate: "Ruginosu" },
  { judet: "Caras Severin", localitate: "Zorile" },
  { judet: "Caras Severin", localitate: "Crusovat" },
  { judet: "Caras Severin", localitate: "Cuptoare (Cornea)" },
  { judet: "Caras Severin", localitate: "Macoviste (Cornea)" },
  { judet: "Caras Severin", localitate: "Arsuri" },
  { judet: "Caras Severin", localitate: "Bogaltin" },
  { judet: "Caras Severin", localitate: "Bojia" },
  { judet: "Caras Severin", localitate: "Borugi" },
  { judet: "Caras Severin", localitate: "Camena" },
  { judet: "Caras Severin", localitate: "Ciresel" },
  { judet: "Caras Severin", localitate: "Costis" },
  { judet: "Caras Severin", localitate: "Cozia" },
  { judet: "Caras Severin", localitate: "Cracu Mare" },
  { judet: "Caras Severin", localitate: "Cracu Teiului" },
  { judet: "Caras Severin", localitate: "Dobraia" },
  { judet: "Caras Severin", localitate: "Dolina" },
  { judet: "Caras Severin", localitate: "Gruni" },
  { judet: "Caras Severin", localitate: "Hora Mare" },
  { judet: "Caras Severin", localitate: "Hora Mica" },
  { judet: "Caras Severin", localitate: "Inelet" },
  { judet: "Caras Severin", localitate: "Izvor" },
  { judet: "Caras Severin", localitate: "Lunca Florii" },
  { judet: "Caras Severin", localitate: "Lunca Zaicii" },
  { judet: "Caras Severin", localitate: "Mesteacan" },
  { judet: "Caras Severin", localitate: "Negiudin" },
  { judet: "Caras Severin", localitate: "Obita" },
  { judet: "Caras Severin", localitate: "Pogara" },
  { judet: "Caras Severin", localitate: "Pogara de Sus" },
  { judet: "Caras Severin", localitate: "Poiana Lunga" },
  { judet: "Caras Severin", localitate: "Prisacina" },
  { judet: "Caras Severin", localitate: "Prislop (Cornereva)" },
  { judet: "Caras Severin", localitate: "Rustin" },
  { judet: "Caras Severin", localitate: "Scarisoara" },
  { judet: "Caras Severin", localitate: "Strugasca" },
  { judet: "Caras Severin", localitate: "Studena" },
  { judet: "Caras Severin", localitate: "Sub Crang" },
  { judet: "Caras Severin", localitate: "Sub Plai" },
  { judet: "Caras Severin", localitate: "Topla" },
  { judet: "Caras Severin", localitate: "Tatu" },
  { judet: "Caras Severin", localitate: "Zanogi" },
  { judet: "Caras Severin", localitate: "Zbegu" },
  { judet: "Caras Severin", localitate: "Zmogotin" },
  { judet: "Caras Severin", localitate: "Zoina" },
  { judet: "Caras Severin", localitate: "Sfanta Elena" },
  { judet: "Caras Severin", localitate: "Barz" },
  { judet: "Caras Severin", localitate: "Boina" },
  { judet: "Caras Severin", localitate: "Boinita" },
  { judet: "Caras Severin", localitate: "Prislop (Dalboset)" },
  { judet: "Caras Severin", localitate: "Resita Mica" },
  { judet: "Caras Severin", localitate: "Sopotu Vechi" },
  { judet: "Caras Severin", localitate: "Binis" },
  { judet: "Caras Severin", localitate: "Tirol" },
  { judet: "Caras Severin", localitate: "Calina" },
  { judet: "Caras Severin", localitate: "Canicea" },
  { judet: "Caras Severin", localitate: "Soceni" },
  { judet: "Caras Severin", localitate: "Dezesti" },
  { judet: "Caras Severin", localitate: "Duleu" },
  { judet: "Caras Severin", localitate: "Remetea-Poganici" },
  { judet: "Caras Severin", localitate: "Scaius" },
  { judet: "Caras Severin", localitate: "Valea Mare" },
  { judet: "Caras Severin", localitate: "Brezon" },
  { judet: "Caras Severin", localitate: "Comoraste" },
  { judet: "Caras Severin", localitate: "Surducu Mare" },
  { judet: "Caras Severin", localitate: "Padina Matei" },
  { judet: "Caras Severin", localitate: "Garliste" },
  { judet: "Caras Severin", localitate: "Giurgiova" },
  { judet: "Caras Severin", localitate: "Greoni" },
  { judet: "Caras Severin", localitate: "Globu Craiovei" },
  { judet: "Caras Severin", localitate: "Petnic" },
  { judet: "Caras Severin", localitate: "Parvova" },
  { judet: "Caras Severin", localitate: "Sumita" },
  { judet: "Caras Severin", localitate: "Moceris" },
  { judet: "Caras Severin", localitate: "Verendin" },
  { judet: "Caras Severin", localitate: "Clocotici" },
  { judet: "Caras Severin", localitate: "Rafnic" },
  { judet: "Caras Severin", localitate: "Vodnic" },
  { judet: "Caras Severin", localitate: "Vama Marga" },
  { judet: "Caras Severin", localitate: "Sosdea" },
  { judet: "Caras Severin", localitate: "Globurau" },
  { judet: "Caras Severin", localitate: "Plugova" },
  { judet: "Caras Severin", localitate: "Valea Bolvasnita" },
  { judet: "Caras Severin", localitate: "Lescovita" },
  { judet: "Caras Severin", localitate: "Ciuta" },
  { judet: "Caras Severin", localitate: "Iaz" },
  { judet: "Caras Severin", localitate: "Var" },
  { judet: "Caras Severin", localitate: "Cornutel" },
  { judet: "Caras Severin", localitate: "Delinesti" },
  { judet: "Caras Severin", localitate: "Ohabita" },
  { judet: "Caras Severin", localitate: "Rugi" },
  { judet: "Caras Severin", localitate: "Belobresca" },
  { judet: "Caras Severin", localitate: "Divici" },
  { judet: "Caras Severin", localitate: "Radimna" },
  { judet: "Caras Severin", localitate: "Susca" },
  { judet: "Caras Severin", localitate: "Borlovenii Noi" },
  { judet: "Caras Severin", localitate: "Borlovenii Vechi" },
  { judet: "Caras Severin", localitate: "Patas" },
  { judet: "Caras Severin", localitate: "Putna" },
  { judet: "Caras Severin", localitate: "Barbosu" },
  { judet: "Caras Severin", localitate: "Valeapai" },
  { judet: "Caras Severin", localitate: "Vraniut" },
  { judet: "Caras Severin", localitate: "Ruschita" },
  { judet: "Caras Severin", localitate: "Salbagelu Nou" },
  { judet: "Caras Severin", localitate: "Tincova" },
  { judet: "Caras Severin", localitate: "Bogodint" },
  { judet: "Caras Severin", localitate: "Potoc" },
  { judet: "Caras Severin", localitate: "Sasca Romana" },
  { judet: "Caras Severin", localitate: "Slatina-Nera" },
  { judet: "Caras Severin", localitate: "Brestelnic" },
  { judet: "Caras Severin", localitate: "Camenita" },
  { judet: "Caras Severin", localitate: "Carsie" },
  { judet: "Caras Severin", localitate: "Cracu Almaj" },
  { judet: "Caras Severin", localitate: "Crusovita" },
  { judet: "Caras Severin", localitate: "Curmatura" },
  { judet: "Caras Severin", localitate: "Frasinis" },
  { judet: "Caras Severin", localitate: "Gornea" },
  { judet: "Caras Severin", localitate: "Liborajdea" },
  { judet: "Caras Severin", localitate: "Lucacevat" },
  { judet: "Caras Severin", localitate: "Martinovat" },
  { judet: "Caras Severin", localitate: "Ogasu Podului" },
  { judet: "Caras Severin", localitate: "Streneac" },
  { judet: "Caras Severin", localitate: "Valea Orevita" },
  { judet: "Caras Severin", localitate: "Valea Ravensca" },
  { judet: "Caras Severin", localitate: "Valea Sichevitei" },
  { judet: "Caras Severin", localitate: "Zanou" },
  { judet: "Caras Severin", localitate: "Zasloane" },
  { judet: "Caras Severin", localitate: "Ilova" },
  { judet: "Caras Severin", localitate: "Sadova Noua" },
  { judet: "Caras Severin", localitate: "Sadova Veche" },
  { judet: "Caras Severin", localitate: "Bazias" },
  { judet: "Caras Severin", localitate: "Campia" },
  { judet: "Caras Severin", localitate: "Parneaura" },
  { judet: "Caras Severin", localitate: "Carsa Rosie" },
  { judet: "Caras Severin", localitate: "Dristie" },
  { judet: "Caras Severin", localitate: "Poienile Boinei" },
  { judet: "Caras Severin", localitate: "Ravensca" },
  { judet: "Caras Severin", localitate: "Rachita" },
  { judet: "Caras Severin", localitate: "Stancilova" },
  { judet: "Caras Severin", localitate: "Urcu" },
  { judet: "Caras Severin", localitate: "Valea Rachitei" },
  { judet: "Caras Severin", localitate: "Valea Rosie" },
  { judet: "Caras Severin", localitate: "Bratova" },
  { judet: "Caras Severin", localitate: "Rusca" },
  { judet: "Caras Severin", localitate: "Carnecea" },
  { judet: "Caras Severin", localitate: "Secaseni" },
  { judet: "Caras Severin", localitate: "Ticvaniu Mic" },
  { judet: "Caras Severin", localitate: "Barza" },
  { judet: "Caras Severin", localitate: "Borlova" },
  { judet: "Caras Severin", localitate: "Cicleni" },
  { judet: "Caras Severin", localitate: "Dalci" },
  { judet: "Caras Severin", localitate: "Zervesti" },
  { judet: "Caras Severin", localitate: "Zlagna" },
  { judet: "Caras Severin", localitate: "Mercina" },
  { judet: "Caras Severin", localitate: "Ersig" },
  { judet: "Caras Severin", localitate: "Izgar" },
  { judet: "Caras Severin", localitate: "Ciortea" },
  { judet: "Caras Severin", localitate: "Iertof" },
  { judet: "Caras Severin", localitate: "23 August" },
  { judet: "Caras Severin", localitate: "Magura" },
  { judet: "Caras Severin", localitate: "Maru" },
  { judet: "Caras Severin", localitate: "Poiana Marului" },
  { judet: "Caras Severin", localitate: "Valea Bistrei" },
  { judet: "Caras Severin", localitate: "Voislova" },
  { judet: "Caras Severin", localitate: "Zlatita" },
  { judet: "Caras Severin", localitate: "Zorlencior" },
  { judet: "Cluj", localitate: "Cluj-Napoca" },
  { judet: "Cluj", localitate: "Dej" },
  { judet: "Cluj", localitate: "Turda" },
  { judet: "Cluj", localitate: "Gherla" },
  { judet: "Cluj", localitate: "Campia Turzii" },
  { judet: "Cluj", localitate: "Huedin" },
  { judet: "Cluj", localitate: "Floresti" },
  { judet: "Cluj", localitate: "Luna" },
  { judet: "Cluj", localitate: "Aghiresu" },
  { judet: "Cluj", localitate: "Aiton" },
  { judet: "Cluj", localitate: "Alunis" },
  { judet: "Cluj", localitate: "Apahida" },
  { judet: "Cluj", localitate: "Aschileu" },
  { judet: "Cluj", localitate: "Baciu" },
  { judet: "Cluj", localitate: "Baisoara" },
  { judet: "Cluj", localitate: "Belis" },
  { judet: "Cluj", localitate: "Bobalna" },
  { judet: "Cluj", localitate: "Bontida" },
  { judet: "Cluj", localitate: "Borsa" },
  { judet: "Cluj", localitate: "Buza" },
  { judet: "Cluj", localitate: "Caianu" },
  { judet: "Cluj", localitate: "Calatele" },
  { judet: "Cluj", localitate: "Camarasu" },
  { judet: "Cluj", localitate: "Capusu Mare" },
  { judet: "Cluj", localitate: "Caseiu" },
  { judet: "Cluj", localitate: "Catina" },
  { judet: "Cluj", localitate: "Catcau" },
  { judet: "Cluj", localitate: "Ceanu Mare" },
  { judet: "Cluj", localitate: "Chinteni" },
  { judet: "Cluj", localitate: "Chiuiesti" },
  { judet: "Cluj", localitate: "Ciucea" },
  { judet: "Cluj", localitate: "Ciurila" },
  { judet: "Cluj", localitate: "Cojocna" },
  { judet: "Cluj", localitate: "Cornesti" },
  { judet: "Cluj", localitate: "Cuzdrioara" },
  { judet: "Cluj", localitate: "Dabaca" },
  { judet: "Cluj", localitate: "Feleacu" },
  { judet: "Cluj", localitate: "Fizesu Gherlii" },
  { judet: "Cluj", localitate: "Frata" },
  { judet: "Cluj", localitate: "Garbau" },
  { judet: "Cluj", localitate: "Geaca" },
  { judet: "Cluj", localitate: "Gilau" },
  { judet: "Cluj", localitate: "Iara" },
  { judet: "Cluj", localitate: "Iclod" },
  { judet: "Cluj", localitate: "Izvoru Crisului" },
  { judet: "Cluj", localitate: "Jichisu de Jos" },
  { judet: "Cluj", localitate: "Jucu" },
  { judet: "Cluj", localitate: "Maguri-Racatau" },
  { judet: "Cluj", localitate: "Manastireni" },
  { judet: "Cluj", localitate: "Margau" },
  { judet: "Cluj", localitate: "Marisel" },
  { judet: "Cluj", localitate: "Mica" },
  { judet: "Cluj", localitate: "Mihai Viteazu" },
  { judet: "Cluj", localitate: "Mintiu Gherlii" },
  { judet: "Cluj", localitate: "Mociu" },
  { judet: "Cluj", localitate: "Moldovenesti" },
  { judet: "Cluj", localitate: "Negreni" },
  { judet: "Cluj", localitate: "Palatca" },
  { judet: "Cluj", localitate: "Panticeu" },
  { judet: "Cluj", localitate: "Petrestii de Jos" },
  { judet: "Cluj", localitate: "Ploscos" },
  { judet: "Cluj", localitate: "Poieni" },
  { judet: "Cluj", localitate: "Rasca" },
  { judet: "Cluj", localitate: "Recea-Cristur" },
  { judet: "Cluj", localitate: "Sacuieu" },
  { judet: "Cluj", localitate: "Sandulesti" },
  { judet: "Cluj", localitate: "Savadisla" },
  { judet: "Cluj", localitate: "Sancraiu" },
  { judet: "Cluj", localitate: "Sanmartin" },
  { judet: "Cluj", localitate: "Sanpaul" },
  { judet: "Cluj", localitate: "Sic" },
  { judet: "Cluj", localitate: "Suatu" },
  { judet: "Cluj", localitate: "Tritenii de Jos" },
  { judet: "Cluj", localitate: "Tureni" },
  { judet: "Cluj", localitate: "Taga" },
  { judet: "Cluj", localitate: "Vad" },
  { judet: "Cluj", localitate: "Valea Ierii" },
  { judet: "Cluj", localitate: "Viisoara" },
  { judet: "Cluj", localitate: "Vultureni" },
  { judet: "Cluj", localitate: "Ocna Dejului" },
  { judet: "Cluj", localitate: "Pestera" },
  { judet: "Cluj", localitate: "Pintic" },
  { judet: "Cluj", localitate: "Somcutu Mic" },
  { judet: "Cluj", localitate: "Baita" },
  { judet: "Cluj", localitate: "Hasdate (Gherla)" },
  { judet: "Cluj", localitate: "Silivas" },
  { judet: "Cluj", localitate: "Bicalatu" },
  { judet: "Cluj", localitate: "Arghisu" },
  { judet: "Cluj", localitate: "Bagara" },
  { judet: "Cluj", localitate: "Dancu" },
  { judet: "Cluj", localitate: "Doroltu" },
  { judet: "Cluj", localitate: "Aghiresu-Fabrici" },
  { judet: "Cluj", localitate: "Inucu" },
  { judet: "Cluj", localitate: "Leghia" },
  { judet: "Cluj", localitate: "Macau" },
  { judet: "Cluj", localitate: "Ticu" },
  { judet: "Cluj", localitate: "Ticu-Colonie" },
  { judet: "Cluj", localitate: "Rediu" },
  { judet: "Cluj", localitate: "Corneni" },
  { judet: "Cluj", localitate: "Ghirolt" },
  { judet: "Cluj", localitate: "Pruneni" },
  { judet: "Cluj", localitate: "Vale" },
  { judet: "Cluj", localitate: "Bodrog" },
  { judet: "Cluj", localitate: "Campenesti" },
  { judet: "Cluj", localitate: "Corpadea" },
  { judet: "Cluj", localitate: "Dezmir" },
  { judet: "Cluj", localitate: "Pata" },
  { judet: "Cluj", localitate: "Sannicoara" },
  { judet: "Cluj", localitate: "Sub Coasta" },
  { judet: "Cluj", localitate: "Aschileu Mare" },
  { judet: "Cluj", localitate: "Aschileu Mic" },
  { judet: "Cluj", localitate: "Cristorel" },
  { judet: "Cluj", localitate: "Dorna" },
  { judet: "Cluj", localitate: "Fodora" },
  { judet: "Cluj", localitate: "Corusu" },
  { judet: "Cluj", localitate: "Mera" },
  { judet: "Cluj", localitate: "Popesti" },
  { judet: "Cluj", localitate: "Radaia" },
  { judet: "Cluj", localitate: "Salistea Noua" },
  { judet: "Cluj", localitate: "Suceagu" },
  { judet: "Cluj", localitate: "Frasinet" },
  { judet: "Cluj", localitate: "Moara de Padure" },
  { judet: "Cluj", localitate: "Muntele Baisorii" },
  { judet: "Cluj", localitate: "Muntele Bocului" },
  { judet: "Cluj", localitate: "Muntele Cacovei" },
  { judet: "Cluj", localitate: "Muntele Filii" },
  { judet: "Cluj", localitate: "Muntele Sacelului" },
  { judet: "Cluj", localitate: "Sacel" },
  { judet: "Cluj", localitate: "Balcesti (Belis)" },
  { judet: "Cluj", localitate: "Dealu Botii" },
  { judet: "Cluj", localitate: "Giurcuta de Jos" },
  { judet: "Cluj", localitate: "Giurcuta de Sus" },
  { judet: "Cluj", localitate: "Poiana Horea" },
  { judet: "Cluj", localitate: "Smida" },
  { judet: "Cluj", localitate: "Antas" },
  { judet: "Cluj", localitate: "Babdiu" },
  { judet: "Cluj", localitate: "Blidaresti" },
  { judet: "Cluj", localitate: "Cremenea" },
  { judet: "Cluj", localitate: "Maia" },
  { judet: "Cluj", localitate: "Osorhel" },
  { judet: "Cluj", localitate: "Pruni" },
  { judet: "Cluj", localitate: "Razbuneni" },
  { judet: "Cluj", localitate: "Suaras" },
  { judet: "Cluj", localitate: "Valcelele" },
  { judet: "Cluj", localitate: "Coasta" },
  { judet: "Cluj", localitate: "Rascruci" },
  { judet: "Cluj", localitate: "Tauseni" },
  { judet: "Cluj", localitate: "Borsa-Catun" },
  { judet: "Cluj", localitate: "Borsa-Crestaia" },
  { judet: "Cluj", localitate: "Ciumafaia" },
  { judet: "Cluj", localitate: "Giula" },
  { judet: "Cluj", localitate: "Rotunda" },
  { judet: "Cluj", localitate: "Barai" },
  { judet: "Cluj", localitate: "Caianu Mic" },
  { judet: "Cluj", localitate: "Caianu-Vama" },
  { judet: "Cluj", localitate: "Vaida-Camaras" },
  { judet: "Cluj", localitate: "Valeni (Caianu)" },
  { judet: "Cluj", localitate: "Calarasi" },
  { judet: "Cluj", localitate: "Bogata" },
  { judet: "Cluj", localitate: "Calarasi-Gara" },
  { judet: "Cluj", localitate: "Calata" },
  { judet: "Cluj", localitate: "Dealu Negru" },
  { judet: "Cluj", localitate: "Finciu" },
  { judet: "Cluj", localitate: "Valeni (Calatele)" },
  { judet: "Cluj", localitate: "Naoiu" },
  { judet: "Cluj", localitate: "Samboleni" },
  { judet: "Cluj", localitate: "Balcesti (Capusu Mare)" },
  { judet: "Cluj", localitate: "Capusu Mic" },
  { judet: "Cluj", localitate: "Dangau Mare" },
  { judet: "Cluj", localitate: "Dangau Mic" },
  { judet: "Cluj", localitate: "Dumbrava" },
  { judet: "Cluj", localitate: "Paniceni" },
  { judet: "Cluj", localitate: "Straja (Capusu Mare)" },
  { judet: "Cluj", localitate: "Comorata" },
  { judet: "Cluj", localitate: "Coplean" },
  { judet: "Cluj", localitate: "Custura" },
  { judet: "Cluj", localitate: "Garbau Dejului" },
  { judet: "Cluj", localitate: "Guga" },
  { judet: "Cluj", localitate: "Leurda" },
  { judet: "Cluj", localitate: "Rugasesti" },
  { judet: "Cluj", localitate: "Salatruc" },
  { judet: "Cluj", localitate: "Urisor" },
  { judet: "Cluj", localitate: "Copru" },
  { judet: "Cluj", localitate: "Feldioara" },
  { judet: "Cluj", localitate: "Hagau" },
  { judet: "Cluj", localitate: "Hodaie" },
  { judet: "Cluj", localitate: "Valea Calda" },
  { judet: "Cluj", localitate: "Muncel" },
  { judet: "Cluj", localitate: "Salisca" },
  { judet: "Cluj", localitate: "Andici" },
  { judet: "Cluj", localitate: "Boian" },
  { judet: "Cluj", localitate: "Boldut" },
  { judet: "Cluj", localitate: "Ciurgau" },
  { judet: "Cluj", localitate: "Dosu Napului" },
  { judet: "Cluj", localitate: "Fanate" },
  { judet: "Cluj", localitate: "Hodai-Boian" },
  { judet: "Cluj", localitate: "Iacobeni" },
  { judet: "Cluj", localitate: "Mortesti" },
  { judet: "Cluj", localitate: "Starcu" },
  { judet: "Cluj", localitate: "Strucut" },
  { judet: "Cluj", localitate: "Valea lui Cati" },
  { judet: "Cluj", localitate: "Deusu" },
  { judet: "Cluj", localitate: "Feiurdeni" },
  { judet: "Cluj", localitate: "Macicasu" },
  { judet: "Cluj", localitate: "Padureni (Chinteni)" },
  { judet: "Cluj", localitate: "Satu Lung" },
  { judet: "Cluj", localitate: "Salistea Veche" },
  { judet: "Cluj", localitate: "Sanmartin (Chinteni)" },
  { judet: "Cluj", localitate: "Vechea" },
  { judet: "Cluj", localitate: "Dosu Bricii" },
  { judet: "Cluj", localitate: "Huta" },
  { judet: "Cluj", localitate: "Magoaja" },
  { judet: "Cluj", localitate: "Strambu" },
  { judet: "Cluj", localitate: "Valea Caseielului" },
  { judet: "Cluj", localitate: "Valea lui Opris" },
  { judet: "Cluj", localitate: "Vanatori" },
  { judet: "Cluj", localitate: "Filea de Jos" },
  { judet: "Cluj", localitate: "Filea de Sus" },
  { judet: "Cluj", localitate: "Padureni (Ciurila)" },
  { judet: "Cluj", localitate: "Prunis" },
  { judet: "Cluj", localitate: "Salicea" },
  { judet: "Cluj", localitate: "Saliste" },
  { judet: "Cluj", localitate: "Sutu" },
  { judet: "Cluj", localitate: "Boj-Catun" },
  { judet: "Cluj", localitate: "Boju" },
  { judet: "Cluj", localitate: "Cara" },
  { judet: "Cluj", localitate: "Huci" },
  { judet: "Cluj", localitate: "Iuriu de Campie" },
  { judet: "Cluj", localitate: "Moristi" },
  { judet: "Cluj", localitate: "Straja (Cojocna)" },
  { judet: "Cluj", localitate: "Barlea" },
  { judet: "Cluj", localitate: "Igritia" },
  { judet: "Cluj", localitate: "Lujerdiu" },
  { judet: "Cluj", localitate: "Morau" },
  { judet: "Cluj", localitate: "Stoiana" },
  { judet: "Cluj", localitate: "Tiocu de Jos" },
  { judet: "Cluj", localitate: "Tiocu de Sus" },
  { judet: "Cluj", localitate: "Tioltiur" },
  { judet: "Cluj", localitate: "Manasturel" },
  { judet: "Cluj", localitate: "Valea Garboului" },
  { judet: "Cluj", localitate: "Luna de Jos" },
  { judet: "Cluj", localitate: "Paglisa" },
  { judet: "Cluj", localitate: "Casele Micesti" },
  { judet: "Cluj", localitate: "Gheorghieni" },
  { judet: "Cluj", localitate: "Saradis" },
  { judet: "Cluj", localitate: "Valcele" },
  { judet: "Cluj", localitate: "Bont" },
  { judet: "Cluj", localitate: "Lunca Bontului" },
  { judet: "Cluj", localitate: "Nicula" },
  { judet: "Cluj", localitate: "Sacalaia" },
  { judet: "Cluj", localitate: "Luna de Sus" },
  { judet: "Cluj", localitate: "Tauti" },
  { judet: "Cluj", localitate: "Berchiesu" },
  { judet: "Cluj", localitate: "Oas" },
  { judet: "Cluj", localitate: "Olariu" },
  { judet: "Cluj", localitate: "Padurea Iacobeni" },
  { judet: "Cluj", localitate: "Poiana Fratii" },
  { judet: "Cluj", localitate: "Razoare" },
  { judet: "Cluj", localitate: "Soporu de Campie" },
  { judet: "Cluj", localitate: "Cornesti (Garbau)" },
  { judet: "Cluj", localitate: "Nadaselu" },
  { judet: "Cluj", localitate: "Turea" },
  { judet: "Cluj", localitate: "Vistea" },
  { judet: "Cluj", localitate: "Chiris" },
  { judet: "Cluj", localitate: "Lacu" },
  { judet: "Cluj", localitate: "Legii" },
  { judet: "Cluj", localitate: "Puini" },
  { judet: "Cluj", localitate: "Sucutard" },
  { judet: "Cluj", localitate: "Somesu Cald" },
  { judet: "Cluj", localitate: "Somesu Rece" },
  { judet: "Cluj", localitate: "Agris" },
  { judet: "Cluj", localitate: "Borzesti" },
  { judet: "Cluj", localitate: "Buru" },
  { judet: "Cluj", localitate: "Cacova Ierii" },
  { judet: "Cluj", localitate: "Fagetu Ierii" },
  { judet: "Cluj", localitate: "Lungesti" },
  { judet: "Cluj", localitate: "Masca" },
  { judet: "Cluj", localitate: "Magura Ierii" },
  { judet: "Cluj", localitate: "Ocolisel" },
  { judet: "Cluj", localitate: "Surduc" },
  { judet: "Cluj", localitate: "Valea Agrisului" },
  { judet: "Cluj", localitate: "Valea Vadului" },
  { judet: "Cluj", localitate: "Fundatura" },
  { judet: "Cluj", localitate: "Iclozel" },
  { judet: "Cluj", localitate: "Livada (Iclod)" },
  { judet: "Cluj", localitate: "Orman" },
  { judet: "Cluj", localitate: "Nadasu" },
  { judet: "Cluj", localitate: "Nearsova" },
  { judet: "Cluj", localitate: "Saula" },
  { judet: "Cluj", localitate: "Codor" },
  { judet: "Cluj", localitate: "Jichisu de Sus" },
  { judet: "Cluj", localitate: "Sigau" },
  { judet: "Cluj", localitate: "Tarpiu" },
  { judet: "Cluj", localitate: "Gadalin" },
  { judet: "Cluj", localitate: "Juc-Herghelie" },
  { judet: "Cluj", localitate: "Jucu de Mijloc" },
  { judet: "Cluj", localitate: "Jucu de Sus" },
  { judet: "Cluj", localitate: "Visea" },
  { judet: "Cluj", localitate: "Gligoresti" },
  { judet: "Cluj", localitate: "Luncani" },
  { judet: "Cluj", localitate: "Maguri" },
  { judet: "Cluj", localitate: "Muntele Rece" },
  { judet: "Cluj", localitate: "Ardeova" },
  { judet: "Cluj", localitate: "Bedeciu" },
  { judet: "Cluj", localitate: "Bica" },
  { judet: "Cluj", localitate: "Dretea" },
  { judet: "Cluj", localitate: "Manasturu Romanesc" },
  { judet: "Cluj", localitate: "Bociu" },
  { judet: "Cluj", localitate: "Buteni" },
  { judet: "Cluj", localitate: "Ciuleni" },
  { judet: "Cluj", localitate: "Rachitele" },
  { judet: "Cluj", localitate: "Scrind-Frasinet" },
  { judet: "Cluj", localitate: "Tarnita" },
  { judet: "Cluj", localitate: "Dambu Mare" },
  { judet: "Cluj", localitate: "Manastirea" },
  { judet: "Cluj", localitate: "Nires" },
  { judet: "Cluj", localitate: "Sanmarghita" },
  { judet: "Cluj", localitate: "Valea Ciresoii" },
  { judet: "Cluj", localitate: "Valea Luncii" },
  { judet: "Cluj", localitate: "Cheia" },
  { judet: "Cluj", localitate: "Cornesti (Mihai Viteazu)" },
  { judet: "Cluj", localitate: "Bunesti" },
  { judet: "Cluj", localitate: "Nima" },
  { judet: "Cluj", localitate: "Padurenii (Mintiu Gherlii)" },
  { judet: "Cluj", localitate: "Petresti" },
  { judet: "Cluj", localitate: "Salatiu" },
  { judet: "Cluj", localitate: "Boteni" },
  { judet: "Cluj", localitate: "Chesau" },
  { judet: "Cluj", localitate: "Criseni" },
  { judet: "Cluj", localitate: "Falca" },
  { judet: "Cluj", localitate: "Ghirisu Roman" },
  { judet: "Cluj", localitate: "Rosieni" },
  { judet: "Cluj", localitate: "Turmasi" },
  { judet: "Cluj", localitate: "Zorenii de Vale" },
  { judet: "Cluj", localitate: "Badeni" },
  { judet: "Cluj", localitate: "Pietroasa" },
  { judet: "Cluj", localitate: "Plaiesti" },
  { judet: "Cluj", localitate: "Podeni" },
  { judet: "Cluj", localitate: "Stejeris" },
  { judet: "Cluj", localitate: "Bucea" },
  { judet: "Cluj", localitate: "Prelucele" },
  { judet: "Cluj", localitate: "Catalina" },
  { judet: "Cluj", localitate: "Cublesu Somesan" },
  { judet: "Cluj", localitate: "Darja" },
  { judet: "Cluj", localitate: "Sarata" },
  { judet: "Cluj", localitate: "Bagaciu" },
  { judet: "Cluj", localitate: "Muresenii de Campie" },
  { judet: "Cluj", localitate: "Petea" },
  { judet: "Cluj", localitate: "Sava" },
  { judet: "Cluj", localitate: "Craesti" },
  { judet: "Cluj", localitate: "Deleni" },
  { judet: "Cluj", localitate: "Livada (Petrestii de Jos)" },
  { judet: "Cluj", localitate: "Petrestii de Mijloc" },
  { judet: "Cluj", localitate: "Petrestii de Sus" },
  { judet: "Cluj", localitate: "Plaiuri" },
  { judet: "Cluj", localitate: "Crairat" },
  { judet: "Cluj", localitate: "Lobodas" },
  { judet: "Cluj", localitate: "Valea Florilor" },
  { judet: "Cluj", localitate: "Bologa" },
  { judet: "Cluj", localitate: "Cerbesti" },
  { judet: "Cluj", localitate: "Hodisu" },
  { judet: "Cluj", localitate: "Lunca Visagului" },
  { judet: "Cluj", localitate: "Morlaca" },
  { judet: "Cluj", localitate: "Tranisu" },
  { judet: "Cluj", localitate: "Valea Draganului" },
  { judet: "Cluj", localitate: "Caprioara" },
  { judet: "Cluj", localitate: "Ciubanca" },
  { judet: "Cluj", localitate: "Ciubancuta" },
  { judet: "Cluj", localitate: "Elciu" },
  { judet: "Cluj", localitate: "Escu" },
  { judet: "Cluj", localitate: "Jurca" },
  { judet: "Cluj", localitate: "Osoi" },
  { judet: "Cluj", localitate: "Pustuta" },
  { judet: "Cluj", localitate: "Dealu Mare" },
  { judet: "Cluj", localitate: "Lapustesti" },
  { judet: "Cluj", localitate: "Marcesti" },
  { judet: "Cluj", localitate: "Rogojel" },
  { judet: "Cluj", localitate: "Visagu" },
  { judet: "Cluj", localitate: "Copaceni" },
  { judet: "Cluj", localitate: "Finisel" },
  { judet: "Cluj", localitate: "Hasdate (Savadisla)" },
  { judet: "Cluj", localitate: "Lita" },
  { judet: "Cluj", localitate: "Liteni" },
  { judet: "Cluj", localitate: "Stolna" },
  { judet: "Cluj", localitate: "Valisoara" },
  { judet: "Cluj", localitate: "Vlaha" },
  { judet: "Cluj", localitate: "Alunisu" },
  { judet: "Cluj", localitate: "Braisoru" },
  { judet: "Cluj", localitate: "Domosu" },
  { judet: "Cluj", localitate: "Horlacea" },
  { judet: "Cluj", localitate: "Ceaba" },
  { judet: "Cluj", localitate: "Cutca" },
  { judet: "Cluj", localitate: "Diviciorii Mari" },
  { judet: "Cluj", localitate: "Diviciorii Mici" },
  { judet: "Cluj", localitate: "Mahal" },
  { judet: "Cluj", localitate: "Samboieni" },
  { judet: "Cluj", localitate: "Targusor" },
  { judet: "Cluj", localitate: "Berindu" },
  { judet: "Cluj", localitate: "Mihaiesti" },
  { judet: "Cluj", localitate: "Sumurducu" },
  { judet: "Cluj", localitate: "Sardu" },
  { judet: "Cluj", localitate: "Topa Mica" },
  { judet: "Cluj", localitate: "Aruncuta" },
  { judet: "Cluj", localitate: "Damburile" },
  { judet: "Cluj", localitate: "Clapa" },
  { judet: "Cluj", localitate: "Colonia" },
  { judet: "Cluj", localitate: "Padurenii (Tritenii de Jos)" },
  { judet: "Cluj", localitate: "Tritenii de Sus" },
  { judet: "Cluj", localitate: "Tritenii-Hotar" },
  { judet: "Cluj", localitate: "Ceanu Mic" },
  { judet: "Cluj", localitate: "Comsesti" },
  { judet: "Cluj", localitate: "Martinesti" },
  { judet: "Cluj", localitate: "Micesti" },
  { judet: "Cluj", localitate: "Nasal" },
  { judet: "Cluj", localitate: "Santejude" },
  { judet: "Cluj", localitate: "Santejude-Vale" },
  { judet: "Cluj", localitate: "Santioana" },
  { judet: "Cluj", localitate: "Unguras" },
  { judet: "Cluj", localitate: "Batin" },
  { judet: "Cluj", localitate: "Darot" },
  { judet: "Cluj", localitate: "Sicfa" },
  { judet: "Cluj", localitate: "Valea Ungurasului" },
  { judet: "Cluj", localitate: "Bogata de Jos" },
  { judet: "Cluj", localitate: "Bogata de Sus" },
  { judet: "Cluj", localitate: "Calna" },
  { judet: "Cluj", localitate: "Cetan" },
  { judet: "Cluj", localitate: "Curtuiusu Dejului" },
  { judet: "Cluj", localitate: "Valea Grosilor" },
  { judet: "Cluj", localitate: "Cerc" },
  { judet: "Cluj", localitate: "Plopi" },
  { judet: "Cluj", localitate: "Urca" },
  { judet: "Cluj", localitate: "Babutiu" },
  { judet: "Cluj", localitate: "Badesti" },
  { judet: "Cluj", localitate: "Chidea" },
  { judet: "Cluj", localitate: "Faureni" },
  { judet: "Cluj", localitate: "Soimeni" },
  { judet: "Cluj", localitate: "Agarbiciu" },
  { judet: "Constanta", localitate: "Constanta" },
  { judet: "Constanta", localitate: "Mangalia" },
  { judet: "Constanta", localitate: "Medgidia" },
  { judet: "Constanta", localitate: "Ovidiu" },
  { judet: "Constanta", localitate: "Poarta Alba" },
  { judet: "Constanta", localitate: "Neptun - Olimp" },
  { judet: "Constanta", localitate: "Mihail Kogalniceanu" },
  { judet: "Constanta", localitate: "Navodari" },
  { judet: "Constanta", localitate: "Negru Voda" },
  { judet: "Constanta", localitate: "Mamaia" },
  { judet: "Constanta", localitate: "Nicolae Balcescu" },
  { judet: "Constanta", localitate: "Techirghiol" },
  { judet: "Constanta", localitate: "Costinesti" },
  { judet: "Constanta", localitate: "Eforie Nord" },
  { judet: "Constanta", localitate: "Eforie Sud" },
  { judet: "Constanta", localitate: "2 Mai" },
  { judet: "Constanta", localitate: "Murfatlar" },
  { judet: "Constanta", localitate: "Harsova" },
  { judet: "Constanta", localitate: "Baneasa" },
  { judet: "Constanta", localitate: "Cernavoda" },
  { judet: "Constanta", localitate: "Cobadin" },
  { judet: "Constanta", localitate: "Cogealac" },
  { judet: "Constanta", localitate: "Ostrov" },
  { judet: "Constanta", localitate: "Lumina" },
  { judet: "Constanta", localitate: "Cumpana" },
  { judet: "Constanta", localitate: "Tuzla" },
  { judet: "Constanta", localitate: "23 August" },
  { judet: "Constanta", localitate: "Valu lui Traian" },
  { judet: "Constanta", localitate: "Albesti" },
  { judet: "Constanta", localitate: "Agigea" },
  { judet: "Constanta", localitate: "Adamclisi" },
  { judet: "Constanta", localitate: "Aliman" },
  { judet: "Constanta", localitate: "Amzacea" },
  { judet: "Constanta", localitate: "Baraganu" },
  { judet: "Constanta", localitate: "Castelu" },
  { judet: "Constanta", localitate: "Cerchezu" },
  { judet: "Constanta", localitate: "Chirnogeni" },
  { judet: "Constanta", localitate: "Ciobanu" },
  { judet: "Constanta", localitate: "Ciocarlia" },
  { judet: "Constanta", localitate: "Comana" },
  { judet: "Constanta", localitate: "Corbu" },
  { judet: "Constanta", localitate: "Crucea" },
  { judet: "Constanta", localitate: "Cuza Voda" },
  { judet: "Constanta", localitate: "Deleni" },
  { judet: "Constanta", localitate: "Dobromir" },
  { judet: "Constanta", localitate: "Dumbraveni" },
  { judet: "Constanta", localitate: "Fantanele" },
  { judet: "Constanta", localitate: "Garliciu" },
  { judet: "Constanta", localitate: "Ghindaresti" },
  { judet: "Constanta", localitate: "Gradina" },
  { judet: "Constanta", localitate: "Horia" },
  { judet: "Constanta", localitate: "Independenta" },
  { judet: "Constanta", localitate: "Ion Corvin" },
  { judet: "Constanta", localitate: "Istria" },
  { judet: "Constanta", localitate: "Limanu" },
  { judet: "Constanta", localitate: "Lipnita" },
  { judet: "Constanta", localitate: "Mereni" },
  { judet: "Constanta", localitate: "Mihai Viteazu" },
  { judet: "Constanta", localitate: "Mircea Voda" },
  { judet: "Constanta", localitate: "Oltina" },
  { judet: "Constanta", localitate: "Pantelimon" },
  { judet: "Constanta", localitate: "Pecineaga" },
  { judet: "Constanta", localitate: "Rasova" },
  { judet: "Constanta", localitate: "Sacele" },
  { judet: "Constanta", localitate: "Saligny" },
  { judet: "Constanta", localitate: "Saraiu" },
  { judet: "Constanta", localitate: "Seimeni" },
  { judet: "Constanta", localitate: "Silistea" },
  { judet: "Constanta", localitate: "Targusor" },
  { judet: "Constanta", localitate: "Topalu" },
  { judet: "Constanta", localitate: "Topraisar" },
  { judet: "Constanta", localitate: "Tortoman" },
  { judet: "Constanta", localitate: "Vulturu" },
  { judet: "Constanta", localitate: "Palazu Mare" },
  { judet: "Constanta", localitate: "Siminoc" },
  { judet: "Constanta", localitate: "Eforie" },
  { judet: "Constanta", localitate: "Vadu Oii" },
  { judet: "Constanta", localitate: "Aurora" },
  { judet: "Constanta", localitate: "Jupiter" },
  { judet: "Constanta", localitate: "Olimp" },
  { judet: "Constanta", localitate: "Venus" },
  { judet: "Constanta", localitate: "Saturn" },
  { judet: "Constanta", localitate: "Remus Opreanu" },
  { judet: "Constanta", localitate: "Valea Dacilor" },
  { judet: "Constanta", localitate: "Mamaia-Sat" },
  { judet: "Constanta", localitate: "Darabani" },
  { judet: "Constanta", localitate: "Graniceru" },
  { judet: "Constanta", localitate: "Valcelele" },
  { judet: "Constanta", localitate: "Poiana" },
  { judet: "Constanta", localitate: "Culmea" },
  { judet: "Constanta", localitate: "Dulcesti" },
  { judet: "Constanta", localitate: "Mosneni" },
  { judet: "Constanta", localitate: "Abrud" },
  { judet: "Constanta", localitate: "Hateg" },
  { judet: "Constanta", localitate: "Urluia" },
  { judet: "Constanta", localitate: "Zorile" },
  { judet: "Constanta", localitate: "Lazu" },
  { judet: "Constanta", localitate: "Sanatoriul Agigea" },
  { judet: "Constanta", localitate: "Statiunea Zoologica Marina Agigea" },
  { judet: "Constanta", localitate: "Arsa" },
  { judet: "Constanta", localitate: "Coroana" },
  { judet: "Constanta", localitate: "Cotu Vaii" },
  { judet: "Constanta", localitate: "Vartop" },
  { judet: "Constanta", localitate: "Dunareni" },
  { judet: "Constanta", localitate: "Floriile" },
  { judet: "Constanta", localitate: "Vlahii" },
  { judet: "Constanta", localitate: "Casicea" },
  { judet: "Constanta", localitate: "General Scarisoreanu" },
  { judet: "Constanta", localitate: "Faurei" },
  { judet: "Constanta", localitate: "Negureni" },
  { judet: "Constanta", localitate: "Tudor Vladimirescu" },
  { judet: "Constanta", localitate: "Nisipari" },
  { judet: "Constanta", localitate: "Cascioarele" },
  { judet: "Constanta", localitate: "Magura" },
  { judet: "Constanta", localitate: "Viroaga" },
  { judet: "Constanta", localitate: "Credinta" },
  { judet: "Constanta", localitate: "Plopeni" },
  { judet: "Constanta", localitate: "Miorita" },
  { judet: "Constanta", localitate: "Ciocarlia de Sus" },
  { judet: "Constanta", localitate: "Conacu" },
  { judet: "Constanta", localitate: "Curcani" },
  { judet: "Constanta", localitate: "Negresti" },
  { judet: "Constanta", localitate: "Viisoara" },
  { judet: "Constanta", localitate: "Gura Dobrogei" },
  { judet: "Constanta", localitate: "Ramnicu de Jos" },
  { judet: "Constanta", localitate: "Ramnicu de Sus" },
  { judet: "Constanta", localitate: "Tariverde" },
  { judet: "Constanta", localitate: "Pelinu" },
  { judet: "Constanta", localitate: "Tataru" },
  { judet: "Constanta", localitate: "Luminita" },
  { judet: "Constanta", localitate: "Vadu" },
  { judet: "Constanta", localitate: "Schitu" },
  { judet: "Constanta", localitate: "Baltagesti" },
  { judet: "Constanta", localitate: "Crisan" },
  { judet: "Constanta", localitate: "Galbiori" },
  { judet: "Constanta", localitate: "Siriu" },
  { judet: "Constanta", localitate: "Stupina" },
  { judet: "Constanta", localitate: "Straja" },
  { judet: "Constanta", localitate: "Petrosani" },
  { judet: "Constanta", localitate: "Pietreni" },
  { judet: "Constanta", localitate: "Sipotele" },
  { judet: "Constanta", localitate: "Cetatea" },
  { judet: "Constanta", localitate: "Dobromiru din Deal" },
  { judet: "Constanta", localitate: "Lespezi" },
  { judet: "Constanta", localitate: "Padureni" },
  { judet: "Constanta", localitate: "Valeni" },
  { judet: "Constanta", localitate: "Furnica" },
  { judet: "Constanta", localitate: "Closca" },
  { judet: "Constanta", localitate: "Tichilesti" },
  { judet: "Constanta", localitate: "Fantana Mare" },
  { judet: "Constanta", localitate: "Movila Verde" },
  { judet: "Constanta", localitate: "Olteni" },
  { judet: "Constanta", localitate: "Tufani" },
  { judet: "Constanta", localitate: "Brebeni" },
  { judet: "Constanta", localitate: "Crangu" },
  { judet: "Constanta", localitate: "Raristea" },
  { judet: "Constanta", localitate: "Viile" },
  { judet: "Constanta", localitate: "Nuntasi" },
  { judet: "Constanta", localitate: "Hagieni" },
  { judet: "Constanta", localitate: "Vama Veche" },
  { judet: "Constanta", localitate: "Canlia" },
  { judet: "Constanta", localitate: "Carvan" },
  { judet: "Constanta", localitate: "Coslugea" },
  { judet: "Constanta", localitate: "Cuiugiuc" },
  { judet: "Constanta", localitate: "Goruni" },
  { judet: "Constanta", localitate: "Izvoarele" },
  { judet: "Constanta", localitate: "Oituz" },
  { judet: "Constanta", localitate: "Sibioara" },
  { judet: "Constanta", localitate: "Ciobanita" },
  { judet: "Constanta", localitate: "Lanurile" },
  { judet: "Constanta", localitate: "Miristea" },
  { judet: "Constanta", localitate: "Osmancea" },
  { judet: "Constanta", localitate: "Sinoie" },
  { judet: "Constanta", localitate: "Palazu Mic" },
  { judet: "Constanta", localitate: "Piatra" },
  { judet: "Constanta", localitate: "Faclia" },
  { judet: "Constanta", localitate: "Gherghina" },
  { judet: "Constanta", localitate: "Satu Nou (Mircea Voda)" },
  { judet: "Constanta", localitate: "Stefan cel Mare" },
  { judet: "Constanta", localitate: "Tibrinu" },
  { judet: "Constanta", localitate: "Dorobantu" },
  { judet: "Constanta", localitate: "Razoarele" },
  { judet: "Constanta", localitate: "Satu Nou (Oltina)" },
  { judet: "Constanta", localitate: "Strunga" },
  { judet: "Constanta", localitate: "Almalau" },
  { judet: "Constanta", localitate: "Bugeac" },
  { judet: "Constanta", localitate: "Esechioi" },
  { judet: "Constanta", localitate: "Galita" },
  { judet: "Constanta", localitate: "Garlita" },
  { judet: "Constanta", localitate: "Calugareni" },
  { judet: "Constanta", localitate: "Nistoresti" },
  { judet: "Constanta", localitate: "Pantelimon de Jos" },
  { judet: "Constanta", localitate: "Runcu" },
  { judet: "Constanta", localitate: "Vanatori" },
  { judet: "Constanta", localitate: "Pestera" },
  { judet: "Constanta", localitate: "Ivrinezu Mare" },
  { judet: "Constanta", localitate: "Ivrinezu Mic" },
  { judet: "Constanta", localitate: "Izvoru Mare" },
  { judet: "Constanta", localitate: "Veteranu" },
  { judet: "Constanta", localitate: "Nazarcea" },
  { judet: "Constanta", localitate: "Cochirleni" },
  { judet: "Constanta", localitate: "Dulgheru" },
  { judet: "Constanta", localitate: "Stejaru" },
  { judet: "Constanta", localitate: "Traian" },
  { judet: "Constanta", localitate: "Dunarea" },
  { judet: "Constanta", localitate: "Seimenii Mici" },
  { judet: "Constanta", localitate: "Tepes Voda" },
  { judet: "Constanta", localitate: "Casian" },
  { judet: "Constanta", localitate: "Cheia" },
  { judet: "Constanta", localitate: "Mireasa" },
  { judet: "Constanta", localitate: "Capidava" },
  { judet: "Constanta", localitate: "Biruinta" },
  { judet: "Constanta", localitate: "Movilita" },
  { judet: "Constanta", localitate: "Potarnichea" },
  { judet: "Constanta", localitate: "Dropia" },
  { judet: "Covasna", localitate: "Sfantu Gheorghe" },
  { judet: "Covasna", localitate: "Intorsura Buzaului" },
  { judet: "Covasna", localitate: "Targu Secuiesc" },
  { judet: "Covasna", localitate: "Covasna" },
  { judet: "Covasna", localitate: "Baraolt" },
  { judet: "Covasna", localitate: "Bretcu" },
  { judet: "Covasna", localitate: "Aita Mare" },
  { judet: "Covasna", localitate: "Arcus" },
  { judet: "Covasna", localitate: "Barcani" },
  { judet: "Covasna", localitate: "Batani" },
  { judet: "Covasna", localitate: "Belin" },
  { judet: "Covasna", localitate: "Bixad" },
  { judet: "Covasna", localitate: "Bodoc" },
  { judet: "Covasna", localitate: "Borosneu Mare" },
  { judet: "Covasna", localitate: "Bradut" },
  { judet: "Covasna", localitate: "Brates" },
  { judet: "Covasna", localitate: "Catalina" },
  { judet: "Covasna", localitate: "Cernat" },
  { judet: "Covasna", localitate: "Chichis" },
  { judet: "Covasna", localitate: "Comandau" },
  { judet: "Covasna", localitate: "Dalnic" },
  { judet: "Covasna", localitate: "Dobarlau" },
  { judet: "Covasna", localitate: "Estelnic" },
  { judet: "Covasna", localitate: "Ghelinta" },
  { judet: "Covasna", localitate: "Ghidfalau" },
  { judet: "Covasna", localitate: "Haghig" },
  { judet: "Covasna", localitate: "Ilieni" },
  { judet: "Covasna", localitate: "Lemnia" },
  { judet: "Covasna", localitate: "Malnas" },
  { judet: "Covasna", localitate: "Mereni" },
  { judet: "Covasna", localitate: "Micfalau" },
  { judet: "Covasna", localitate: "Moacsa" },
  { judet: "Covasna", localitate: "Ojdula" },
  { judet: "Covasna", localitate: "Ozun" },
  { judet: "Covasna", localitate: "Poian" },
  { judet: "Covasna", localitate: "Reci" },
  { judet: "Covasna", localitate: "Sanzieni" },
  { judet: "Covasna", localitate: "Sita Buzaului" },
  { judet: "Covasna", localitate: "Turia" },
  { judet: "Covasna", localitate: "Valcele" },
  { judet: "Covasna", localitate: "Valea Crisului" },
  { judet: "Covasna", localitate: "Valea Mare" },
  { judet: "Covasna", localitate: "Varghis" },
  { judet: "Covasna", localitate: "Zabala" },
  { judet: "Covasna", localitate: "Zagon" },
  { judet: "Covasna", localitate: "Chilieni" },
  { judet: "Covasna", localitate: "Coseni" },
  { judet: "Covasna", localitate: "Biborteni" },
  { judet: "Covasna", localitate: "Bodos" },
  { judet: "Covasna", localitate: "Capeni" },
  { judet: "Covasna", localitate: "Miclosoara" },
  { judet: "Covasna", localitate: "Racosul de Sus" },
  { judet: "Covasna", localitate: "Chiurus" },
  { judet: "Covasna", localitate: "Bradet" },
  { judet: "Covasna", localitate: "Floroaia" },
  { judet: "Covasna", localitate: "Scradoasa" },
  { judet: "Covasna", localitate: "Lunga" },
  { judet: "Covasna", localitate: "Aita Medie" },
  { judet: "Covasna", localitate: "Ladauti" },
  { judet: "Covasna", localitate: "Saramas" },
  { judet: "Covasna", localitate: "Aita Seaca" },
  { judet: "Covasna", localitate: "Batanii Mici" },
  { judet: "Covasna", localitate: "Herculian" },
  { judet: "Covasna", localitate: "Ozunca-Bai" },
  { judet: "Covasna", localitate: "Batanii Mari" },
  { judet: "Covasna", localitate: "Belin-Vale" },
  { judet: "Covasna", localitate: "Olteni" },
  { judet: "Covasna", localitate: "Zalan" },
  { judet: "Covasna", localitate: "Borosneu Mic" },
  { judet: "Covasna", localitate: "Dobolii de Sus" },
  { judet: "Covasna", localitate: "Let" },
  { judet: "Covasna", localitate: "Tufalau" },
  { judet: "Covasna", localitate: "Valea Mica" },
  { judet: "Covasna", localitate: "Pachia" },
  { judet: "Covasna", localitate: "Telechia" },
  { judet: "Covasna", localitate: "Doboseni" },
  { judet: "Covasna", localitate: "Filia" },
  { judet: "Covasna", localitate: "Talisoara" },
  { judet: "Covasna", localitate: "Martanus" },
  { judet: "Covasna", localitate: "Oituz" },
  { judet: "Covasna", localitate: "Hatuica" },
  { judet: "Covasna", localitate: "Imeni" },
  { judet: "Covasna", localitate: "Marcusa" },
  { judet: "Covasna", localitate: "Martineni" },
  { judet: "Covasna", localitate: "Albis" },
  { judet: "Covasna", localitate: "Icafalau" },
  { judet: "Covasna", localitate: "Bacel" },
  { judet: "Covasna", localitate: "Lunca Marcusului" },
  { judet: "Covasna", localitate: "Marcus" },
  { judet: "Covasna", localitate: "Valea Dobarlaului" },
  { judet: "Covasna", localitate: "Harale" },
  { judet: "Covasna", localitate: "Anghelus" },
  { judet: "Covasna", localitate: "Fotos" },
  { judet: "Covasna", localitate: "Zoltan" },
  { judet: "Covasna", localitate: "Iaras" },
  { judet: "Covasna", localitate: "Dobolii de Jos" },
  { judet: "Covasna", localitate: "Sancraiu" },
  { judet: "Covasna", localitate: "Lutoasa" },
  { judet: "Covasna", localitate: "Malnas-Bai" },
  { judet: "Covasna", localitate: "Valea Zalanului" },
  { judet: "Covasna", localitate: "Padureni" },
  { judet: "Covasna", localitate: "Hilib" },
  { judet: "Covasna", localitate: "Bicfalau" },
  { judet: "Covasna", localitate: "Lisnau" },
  { judet: "Covasna", localitate: "Lisnau-Vale" },
  { judet: "Covasna", localitate: "Lunca Ozunului" },
  { judet: "Covasna", localitate: "Magherus" },
  { judet: "Covasna", localitate: "Santionlunca" },
  { judet: "Covasna", localitate: "Belani" },
  { judet: "Covasna", localitate: "Carpinenii" },
  { judet: "Covasna", localitate: "Valea Scurta" },
  { judet: "Covasna", localitate: "Aninoasa" },
  { judet: "Covasna", localitate: "Bita" },
  { judet: "Covasna", localitate: "Saciova" },
  { judet: "Covasna", localitate: "Casinu Mic" },
  { judet: "Covasna", localitate: "Petriceni" },
  { judet: "Covasna", localitate: "Valea Seaca" },
  { judet: "Covasna", localitate: "Crasna" },
  { judet: "Covasna", localitate: "Merisor" },
  { judet: "Covasna", localitate: "Zabratau" },
  { judet: "Covasna", localitate: "Alungeni" },
  { judet: "Covasna", localitate: "Calnic" },
  { judet: "Covasna", localitate: "Araci" },
  { judet: "Covasna", localitate: "Ariusd" },
  { judet: "Covasna", localitate: "Hetea" },
  { judet: "Covasna", localitate: "Papauti" },
  { judet: "Covasna", localitate: "Peteni" },
  { judet: "Covasna", localitate: "Surcea" },
  { judet: "Covasna", localitate: "Tamasfalau" },
  { judet: "Dambovita", localitate: "Targoviste" },
  { judet: "Dambovita", localitate: "Moreni" },
  { judet: "Dambovita", localitate: "Pucioasa" },
  { judet: "Dambovita", localitate: "Gaesti" },
  { judet: "Dambovita", localitate: "Titu" },
  { judet: "Dambovita", localitate: "Valea Mare" },
  { judet: "Dambovita", localitate: "Fieni" },
  { judet: "Dambovita", localitate: "Racari" },
  { judet: "Dambovita", localitate: "Aninoasa" },
  { judet: "Dambovita", localitate: "Baleni" },
  { judet: "Dambovita", localitate: "Barbuletu" },
  { judet: "Dambovita", localitate: "Bezdead" },
  { judet: "Dambovita", localitate: "Bilciuresti" },
  { judet: "Dambovita", localitate: "Branistea" },
  { judet: "Dambovita", localitate: "Branesti" },
  { judet: "Dambovita", localitate: "Brezoaele" },
  { judet: "Dambovita", localitate: "Buciumeni" },
  { judet: "Dambovita", localitate: "Bucsani" },
  { judet: "Dambovita", localitate: "Butimanu" },
  { judet: "Dambovita", localitate: "Candesti" },
  { judet: "Dambovita", localitate: "Ciocanesti" },
  { judet: "Dambovita", localitate: "Cobia" },
  { judet: "Dambovita", localitate: "Cojasca" },
  { judet: "Dambovita", localitate: "Comisani" },
  { judet: "Dambovita", localitate: "Contesti" },
  { judet: "Dambovita", localitate: "Corbii Mari" },
  { judet: "Dambovita", localitate: "Cornatelu" },
  { judet: "Dambovita", localitate: "Cornesti" },
  { judet: "Dambovita", localitate: "Costestii din Vale" },
  { judet: "Dambovita", localitate: "Crangurile" },
  { judet: "Dambovita", localitate: "Crevedia" },
  { judet: "Dambovita", localitate: "Darmanesti" },
  { judet: "Dambovita", localitate: "Dobra" },
  { judet: "Dambovita", localitate: "Doicesti" },
  { judet: "Dambovita", localitate: "Dragodana" },
  { judet: "Dambovita", localitate: "Dragomiresti" },
  { judet: "Dambovita", localitate: "Finta" },
  { judet: "Dambovita", localitate: "Glodeni" },
  { judet: "Dambovita", localitate: "Gura Foii" },
  { judet: "Dambovita", localitate: "Gura Ocnitei" },
  { judet: "Dambovita", localitate: "Gura Sutii" },
  { judet: "Dambovita", localitate: "Hulubesti" },
  { judet: "Dambovita", localitate: "I. L. Caragiale" },
  { judet: "Dambovita", localitate: "Iedera" },
  { judet: "Dambovita", localitate: "Lucieni" },
  { judet: "Dambovita", localitate: "Ludesti" },
  { judet: "Dambovita", localitate: "Lunguletu" },
  { judet: "Dambovita", localitate: "Malu cu Flori" },
  { judet: "Dambovita", localitate: "Manesti" },
  { judet: "Dambovita", localitate: "Matasaru" },
  { judet: "Dambovita", localitate: "Mogosani" },
  { judet: "Dambovita", localitate: "Moroeni" },
  { judet: "Dambovita", localitate: "Morteni" },
  { judet: "Dambovita", localitate: "Motaieni" },
  { judet: "Dambovita", localitate: "Niculesti" },
  { judet: "Dambovita", localitate: "Nucet" },
  { judet: "Dambovita", localitate: "Ocnita" },
  { judet: "Dambovita", localitate: "Odobesti" },
  { judet: "Dambovita", localitate: "Petresti" },
  { judet: "Dambovita", localitate: "Persinari" },
  { judet: "Dambovita", localitate: "Pietrari" },
  { judet: "Dambovita", localitate: "Pietrosita" },
  { judet: "Dambovita", localitate: "Poiana" },
  { judet: "Dambovita", localitate: "Potlogi" },
  { judet: "Dambovita", localitate: "Produlesti" },
  { judet: "Dambovita", localitate: "Pucheni" },
  { judet: "Dambovita", localitate: "Raciu" },
  { judet: "Dambovita", localitate: "Rascaeti" },
  { judet: "Dambovita", localitate: "Razvad" },
  { judet: "Dambovita", localitate: "Rau Alb" },
  { judet: "Dambovita", localitate: "Runcu" },
  { judet: "Dambovita", localitate: "Salcioara" },
  { judet: "Dambovita", localitate: "Slobozia Moara" },
  { judet: "Dambovita", localitate: "Selaru" },
  { judet: "Dambovita", localitate: "Sotanga" },
  { judet: "Dambovita", localitate: "Tartasesti" },
  { judet: "Dambovita", localitate: "Tatarani" },
  { judet: "Dambovita", localitate: "Uliesti" },
  { judet: "Dambovita", localitate: "Ulmi" },
  { judet: "Dambovita", localitate: "Valea Lunga" },
  { judet: "Dambovita", localitate: "Vacaresti" },
  { judet: "Dambovita", localitate: "Valeni-Dambovita" },
  { judet: "Dambovita", localitate: "Varfuri" },
  { judet: "Dambovita", localitate: "Visina" },
  { judet: "Dambovita", localitate: "Visinesti" },
  { judet: "Dambovita", localitate: "Vladeni" },
  { judet: "Dambovita", localitate: "Voinesti" },
  { judet: "Dambovita", localitate: "Vulcana-Bai" },
  { judet: "Dambovita", localitate: "Vulcana-Pandele" },
  { judet: "Dambovita", localitate: "Priseaca" },
  { judet: "Dambovita", localitate: "Berevoesti" },
  { judet: "Dambovita", localitate: "Costesti" },
  { judet: "Dambovita", localitate: "Bela" },
  { judet: "Dambovita", localitate: "Diaconesti" },
  { judet: "Dambovita", localitate: "Glodeni (Pucioasa)" },
  { judet: "Dambovita", localitate: "Malurile" },
  { judet: "Dambovita", localitate: "Miculesti" },
  { judet: "Dambovita", localitate: "Pucioasa-Sat" },
  { judet: "Dambovita", localitate: "Fusea" },
  { judet: "Dambovita", localitate: "Hagioaica" },
  { judet: "Dambovita", localitate: "Mereni (Titu)" },
  { judet: "Dambovita", localitate: "Plopu" },
  { judet: "Dambovita", localitate: "Salcuta" },
  { judet: "Dambovita", localitate: "Sateni" },
  { judet: "Dambovita", localitate: "Viforata" },
  { judet: "Dambovita", localitate: "Baleni-Sarbi" },
  { judet: "Dambovita", localitate: "Baleni-Romani" },
  { judet: "Dambovita", localitate: "Alunis" },
  { judet: "Dambovita", localitate: "Cetatuia" },
  { judet: "Dambovita", localitate: "Dupa Deal" },
  { judet: "Dambovita", localitate: "Gura Barbuletului" },
  { judet: "Dambovita", localitate: "Rau Alb de Jos" },
  { judet: "Dambovita", localitate: "Rau Alb de Sus" },
  { judet: "Dambovita", localitate: "Sipot" },
  { judet: "Dambovita", localitate: "Valea" },
  { judet: "Dambovita", localitate: "Brosteni (Bezdead)" },
  { judet: "Dambovita", localitate: "Costisata" },
  { judet: "Dambovita", localitate: "Magura (Bezdead)" },
  { judet: "Dambovita", localitate: "Tunari" },
  { judet: "Dambovita", localitate: "Valea Morii" },
  { judet: "Dambovita", localitate: "Suseni-Socetu" },
  { judet: "Dambovita", localitate: "Dambovicioara" },
  { judet: "Dambovita", localitate: "Savesti" },
  { judet: "Dambovita", localitate: "Priboiu (Branesti)" },
  { judet: "Dambovita", localitate: "Brezoaia" },
  { judet: "Dambovita", localitate: "Dealu Mare" },
  { judet: "Dambovita", localitate: "Valea Leurzii" },
  { judet: "Dambovita", localitate: "Habeni" },
  { judet: "Dambovita", localitate: "Racovita" },
  { judet: "Dambovita", localitate: "Ratoaia" },
  { judet: "Dambovita", localitate: "Barbuceanu" },
  { judet: "Dambovita", localitate: "Lucianca" },
  { judet: "Dambovita", localitate: "Ungureni (Butimanu)" },
  { judet: "Dambovita", localitate: "Aninosani" },
  { judet: "Dambovita", localitate: "Candesti-Deal" },
  { judet: "Dambovita", localitate: "Candesti-Vale" },
  { judet: "Dambovita", localitate: "Dragodanesti" },
  { judet: "Dambovita", localitate: "Valea Mare (Candesti)" },
  { judet: "Dambovita", localitate: "Cretu" },
  { judet: "Dambovita", localitate: "Decindea" },
  { judet: "Dambovita", localitate: "Urziceanca" },
  { judet: "Dambovita", localitate: "Vizuresti" },
  { judet: "Dambovita", localitate: "Blidari" },
  { judet: "Dambovita", localitate: "Calugareni (Cobia)" },
  { judet: "Dambovita", localitate: "Capsuna" },
  { judet: "Dambovita", localitate: "Cobiuta" },
  { judet: "Dambovita", localitate: "Craciunesti" },
  { judet: "Dambovita", localitate: "Frasin-Deal" },
  { judet: "Dambovita", localitate: "Frasin-Vale" },
  { judet: "Dambovita", localitate: "Gherghitesti" },
  { judet: "Dambovita", localitate: "Manastirea" },
  { judet: "Dambovita", localitate: "Mislea" },
  { judet: "Dambovita", localitate: "Fantanele" },
  { judet: "Dambovita", localitate: "Iazu" },
  { judet: "Dambovita", localitate: "Lazuri" },
  { judet: "Dambovita", localitate: "Balteni" },
  { judet: "Dambovita", localitate: "Boteni" },
  { judet: "Dambovita", localitate: "Calugareni (Contesti)" },
  { judet: "Dambovita", localitate: "Crangasi" },
  { judet: "Dambovita", localitate: "Gamanesti" },
  { judet: "Dambovita", localitate: "Helesteu" },
  { judet: "Dambovita", localitate: "Mereni (Contesti)" },
  { judet: "Dambovita", localitate: "Baraceni" },
  { judet: "Dambovita", localitate: "Grozavesti" },
  { judet: "Dambovita", localitate: "Moara din Groapa" },
  { judet: "Dambovita", localitate: "Petresti (Corbii Mari)" },
  { judet: "Dambovita", localitate: "Podu Corbencii" },
  { judet: "Dambovita", localitate: "Satu Nou" },
  { judet: "Dambovita", localitate: "Ungureni (Corbii Mari)" },
  { judet: "Dambovita", localitate: "Vadu Stanchii" },
  { judet: "Dambovita", localitate: "Alunisu" },
  { judet: "Dambovita", localitate: "Bolovani" },
  { judet: "Dambovita", localitate: "Corni" },
  { judet: "Dambovita", localitate: "Slobozia" },
  { judet: "Dambovita", localitate: "Bujoreanca" },
  { judet: "Dambovita", localitate: "Catunu (Cornesti)" },
  { judet: "Dambovita", localitate: "Cristeasca" },
  { judet: "Dambovita", localitate: "Crivatu" },
  { judet: "Dambovita", localitate: "Frasinu" },
  { judet: "Dambovita", localitate: "Hodarasti" },
  { judet: "Dambovita", localitate: "Ibrianu" },
  { judet: "Dambovita", localitate: "Postarnacu" },
  { judet: "Dambovita", localitate: "Ungureni (Cornesti)" },
  { judet: "Dambovita", localitate: "Maruntisu" },
  { judet: "Dambovita", localitate: "Tomsani" },
  { judet: "Dambovita", localitate: "Badulesti" },
  { judet: "Dambovita", localitate: "Crangurile de Jos" },
  { judet: "Dambovita", localitate: "Crangurile de Sus" },
  { judet: "Dambovita", localitate: "Patroaia-Deal" },
  { judet: "Dambovita", localitate: "Patroaia-Vale" },
  { judet: "Dambovita", localitate: "Potlogeni-Vale" },
  { judet: "Dambovita", localitate: "Ratesti" },
  { judet: "Dambovita", localitate: "Voia" },
  { judet: "Dambovita", localitate: "Cocani" },
  { judet: "Dambovita", localitate: "Darza" },
  { judet: "Dambovita", localitate: "Manastirea" },
  { judet: "Dambovita", localitate: "Samurcasi" },
  { judet: "Dambovita", localitate: "Marginenii de Sus" },
  { judet: "Dambovita", localitate: "Marcesti" },
  { judet: "Dambovita", localitate: "Boboci" },
  { judet: "Dambovita", localitate: "Burduca" },
  { judet: "Dambovita", localitate: "Cuparu" },
  { judet: "Dambovita", localitate: "Padureni" },
  { judet: "Dambovita", localitate: "Picior de Munte" },
  { judet: "Dambovita", localitate: "Straosti" },
  { judet: "Dambovita", localitate: "Decindeni" },
  { judet: "Dambovita", localitate: "Geangoesti" },
  { judet: "Dambovita", localitate: "Mogosesti" },
  { judet: "Dambovita", localitate: "Rancaciov" },
  { judet: "Dambovita", localitate: "Ungureni (Dragomiresti)" },
  { judet: "Dambovita", localitate: "Bechinesti" },
  { judet: "Dambovita", localitate: "Finta Mare" },
  { judet: "Dambovita", localitate: "Finta Veche" },
  { judet: "Dambovita", localitate: "Gheboaia" },
  { judet: "Dambovita", localitate: "Gusoiu" },
  { judet: "Dambovita", localitate: "Laculete" },
  { judet: "Dambovita", localitate: "Livezile (Glodeni)" },
  { judet: "Dambovita", localitate: "Malu Mierii" },
  { judet: "Dambovita", localitate: "Schela" },
  { judet: "Dambovita", localitate: "Bumbuia" },
  { judet: "Dambovita", localitate: "Catanele" },
  { judet: "Dambovita", localitate: "Fagetu" },
  { judet: "Dambovita", localitate: "Adanca" },
  { judet: "Dambovita", localitate: "Ochiuri" },
  { judet: "Dambovita", localitate: "Sacueni" },
  { judet: "Dambovita", localitate: "Sperieteni" },
  { judet: "Dambovita", localitate: "Butoiu de Jos" },
  { judet: "Dambovita", localitate: "Butoiu de Sus" },
  { judet: "Dambovita", localitate: "Magura (Hulubesti)" },
  { judet: "Dambovita", localitate: "Valea Dadei" },
  { judet: "Dambovita", localitate: "Ghirdoveni" },
  { judet: "Dambovita", localitate: "Mija" },
  { judet: "Dambovita", localitate: "Colibasi" },
  { judet: "Dambovita", localitate: "Cricovu Dulce" },
  { judet: "Dambovita", localitate: "Iedera de Jos" },
  { judet: "Dambovita", localitate: "Iedera de Sus" },
  { judet: "Dambovita", localitate: "Olteni (Lucieni)" },
  { judet: "Dambovita", localitate: "Silistea (Raciu)" },
  { judet: "Dambovita", localitate: "Suta Seaca" },
  { judet: "Dambovita", localitate: "Milosari" },
  { judet: "Dambovita", localitate: "Potocelu" },
  { judet: "Dambovita", localitate: "Scheiu de Jos" },
  { judet: "Dambovita", localitate: "Scheiu de Sus" },
  { judet: "Dambovita", localitate: "Telesti" },
  { judet: "Dambovita", localitate: "Oreasca" },
  { judet: "Dambovita", localitate: "Serdanu" },
  { judet: "Dambovita", localitate: "Capu Coastei" },
  { judet: "Dambovita", localitate: "Copaceni" },
  { judet: "Dambovita", localitate: "Miclosanii Mari" },
  { judet: "Dambovita", localitate: "Miclosanii Mici" },
  { judet: "Dambovita", localitate: "Dragaesti-Pamanteni" },
  { judet: "Dambovita", localitate: "Dragaesti-Ungureni" },
  { judet: "Dambovita", localitate: "Cretulesti" },
  { judet: "Dambovita", localitate: "Odaia Turcului" },
  { judet: "Dambovita", localitate: "Poroinica" },
  { judet: "Dambovita", localitate: "Putu cu Salcie" },
  { judet: "Dambovita", localitate: "Salcioara (Matasaru)" },
  { judet: "Dambovita", localitate: "Tetcoiu" },
  { judet: "Dambovita", localitate: "Chirca" },
  { judet: "Dambovita", localitate: "Cojocaru" },
  { judet: "Dambovita", localitate: "Merii" },
  { judet: "Dambovita", localitate: "Zavoiu" },
  { judet: "Dambovita", localitate: "Dobresti" },
  { judet: "Dambovita", localitate: "Glod" },
  { judet: "Dambovita", localitate: "Lunca (Moroeni)" },
  { judet: "Dambovita", localitate: "Muscel" },
  { judet: "Dambovita", localitate: "Pucheni (Moroeni)" },
  { judet: "Dambovita", localitate: "Neajlovu" },
  { judet: "Dambovita", localitate: "Cucuteni" },
  { judet: "Dambovita", localitate: "Ciocanari" },
  { judet: "Dambovita", localitate: "Movila (Niculesti)" },
  { judet: "Dambovita", localitate: "Cazaci" },
  { judet: "Dambovita", localitate: "Ilfoveni" },
  { judet: "Dambovita", localitate: "Brancoveanu" },
  { judet: "Dambovita", localitate: "Crovu" },
  { judet: "Dambovita", localitate: "Miulesti" },
  { judet: "Dambovita", localitate: "Zidurile" },
  { judet: "Dambovita", localitate: "Coada Izvorului" },
  { judet: "Dambovita", localitate: "Gherghesti" },
  { judet: "Dambovita", localitate: "Greci" },
  { judet: "Dambovita", localitate: "Ionesti" },
  { judet: "Dambovita", localitate: "Potlogeni-Deal" },
  { judet: "Dambovita", localitate: "Puntea de Greci" },
  { judet: "Dambovita", localitate: "Dealu Frumos" },
  { judet: "Dambovita", localitate: "Poienita" },
  { judet: "Dambovita", localitate: "Pitaru" },
  { judet: "Dambovita", localitate: "Podu Cristinii" },
  { judet: "Dambovita", localitate: "Romanesti" },
  { judet: "Dambovita", localitate: "Vlasceni" },
  { judet: "Dambovita", localitate: "Brosteni (Produlesti)" },
  { judet: "Dambovita", localitate: "Costestii din Deal" },
  { judet: "Dambovita", localitate: "Bradatel" },
  { judet: "Dambovita", localitate: "Meisoare" },
  { judet: "Dambovita", localitate: "Valea Larga" },
  { judet: "Dambovita", localitate: "Varfureni" },
  { judet: "Dambovita", localitate: "Balanesti" },
  { judet: "Dambovita", localitate: "Colacu" },
  { judet: "Dambovita", localitate: "Ghergani" },
  { judet: "Dambovita", localitate: "Ghimpati" },
  { judet: "Dambovita", localitate: "Mavrodin" },
  { judet: "Dambovita", localitate: "Sabiesti" },
  { judet: "Dambovita", localitate: "Stanesti" },
  { judet: "Dambovita", localitate: "Gorgota" },
  { judet: "Dambovita", localitate: "Valea Voievozilor" },
  { judet: "Dambovita", localitate: "Badeni" },
  { judet: "Dambovita", localitate: "Brebu" },
  { judet: "Dambovita", localitate: "Ferestre" },
  { judet: "Dambovita", localitate: "Piatra" },
  { judet: "Dambovita", localitate: "Silistea (Runcu)" },
  { judet: "Dambovita", localitate: "Banesti" },
  { judet: "Dambovita", localitate: "Catunu (Salcioara)" },
  { judet: "Dambovita", localitate: "Cuza Voda" },
  { judet: "Dambovita", localitate: "Ghinesti" },
  { judet: "Dambovita", localitate: "Mircea Voda" },
  { judet: "Dambovita", localitate: "Moara Noua" },
  { judet: "Dambovita", localitate: "Movila (Salcioara)" },
  { judet: "Dambovita", localitate: "Podu Rizii" },
  { judet: "Dambovita", localitate: "Fierbinti" },
  { judet: "Dambovita", localitate: "Glogoveanu" },
  { judet: "Dambovita", localitate: "Teis" },
  { judet: "Dambovita", localitate: "Baldana" },
  { judet: "Dambovita", localitate: "Gulia" },
  { judet: "Dambovita", localitate: "Caprioru" },
  { judet: "Dambovita", localitate: "Gheboieni" },
  { judet: "Dambovita", localitate: "Priboiu (Tatarani)" },
  { judet: "Dambovita", localitate: "Croitori" },
  { judet: "Dambovita", localitate: "Hanu lui Pala" },
  { judet: "Dambovita", localitate: "Jugureni" },
  { judet: "Dambovita", localitate: "Manastioara" },
  { judet: "Dambovita", localitate: "Olteni (Uliesti)" },
  { judet: "Dambovita", localitate: "Ragu" },
  { judet: "Dambovita", localitate: "Stavropolia" },
  { judet: "Dambovita", localitate: "Colanu" },
  { judet: "Dambovita", localitate: "Dimoiu" },
  { judet: "Dambovita", localitate: "Dumbrava" },
  { judet: "Dambovita", localitate: "Matraca" },
  { judet: "Dambovita", localitate: "Nisipurile" },
  { judet: "Dambovita", localitate: "Udresti" },
  { judet: "Dambovita", localitate: "Viisoara" },
  { judet: "Dambovita", localitate: "Bacesti" },
  { judet: "Dambovita", localitate: "Izvoru (Valea Lunga)" },
  { judet: "Dambovita", localitate: "Mosia Mica" },
  { judet: "Dambovita", localitate: "Serbaneasa" },
  { judet: "Dambovita", localitate: "Stubeie Tisa" },
  { judet: "Dambovita", localitate: "Valea Lunga-Gorgota" },
  { judet: "Dambovita", localitate: "Valea Lunga-Ogrea" },
  { judet: "Dambovita", localitate: "Valea Mare (Valea Lunga)" },
  { judet: "Dambovita", localitate: "Valea Lunga-Cricov" },
  { judet: "Dambovita", localitate: "Valea lui Dan" },
  { judet: "Dambovita", localitate: "Feteni" },
  { judet: "Dambovita", localitate: "Garleni" },
  { judet: "Dambovita", localitate: "Livezile (Valea Mare)" },
  { judet: "Dambovita", localitate: "Saru" },
  { judet: "Dambovita", localitate: "Stratonesti" },
  { judet: "Dambovita", localitate: "Valea Caselor" },
  { judet: "Dambovita", localitate: "Bratestii de Jos" },
  { judet: "Dambovita", localitate: "Bungetu" },
  { judet: "Dambovita", localitate: "Mesteacan" },
  { judet: "Dambovita", localitate: "Carlanesti" },
  { judet: "Dambovita", localitate: "Cojoiu" },
  { judet: "Dambovita", localitate: "Merisoru" },
  { judet: "Dambovita", localitate: "Statesti" },
  { judet: "Dambovita", localitate: "Suvita" },
  { judet: "Dambovita", localitate: "Ulmetu" },
  { judet: "Dambovita", localitate: "Brosteni (Visina)" },
  { judet: "Dambovita", localitate: "Izvoru (Visina)" },
  { judet: "Dambovita", localitate: "Vultureanca" },
  { judet: "Dambovita", localitate: "Dospinesti" },
  { judet: "Dambovita", localitate: "Sultanu" },
  { judet: "Dambovita", localitate: "Urseiu" },
  { judet: "Dambovita", localitate: "Gemenea-Bratulesti" },
  { judet: "Dambovita", localitate: "Izvoarele" },
  { judet: "Dambovita", localitate: "Lunca (Voinesti)" },
  { judet: "Dambovita", localitate: "Manga" },
  { judet: "Dambovita", localitate: "Manjina" },
  { judet: "Dambovita", localitate: "Oncesti" },
  { judet: "Dambovita", localitate: "Suduleni" },
  { judet: "Dambovita", localitate: "Nicolaesti" },
  { judet: "Dambovita", localitate: "Vulcana de Sus" },
  { judet: "Dambovita", localitate: "Gura Vulcanei" },
  { judet: "Dambovita", localitate: "Laculete-Gara" },
  { judet: "Dambovita", localitate: "Toculesti" },
  { judet: "Dolj", localitate: "Craiova" },
  { judet: "Dolj", localitate: "Segarcea" },
  { judet: "Dolj", localitate: "Calafat" },
  { judet: "Dolj", localitate: "Bailesti" },
  { judet: "Dolj", localitate: "Ciupercenii Noi" },
  { judet: "Dolj", localitate: "Filiasi" },
  { judet: "Dolj", localitate: "Isalnita" },
  { judet: "Dolj", localitate: "Bechet" },
  { judet: "Dolj", localitate: "Dabuleni" },
  { judet: "Dolj", localitate: "Afumati" },
  { judet: "Dolj", localitate: "Almaj" },
  { judet: "Dolj", localitate: "Amarastii de Jos" },
  { judet: "Dolj", localitate: "Amarastii de Sus" },
  { judet: "Dolj", localitate: "Apele Vii" },
  { judet: "Dolj", localitate: "Argetoaia" },
  { judet: "Dolj", localitate: "Barca" },
  { judet: "Dolj", localitate: "Botosesti-Paia" },
  { judet: "Dolj", localitate: "Brabova" },
  { judet: "Dolj", localitate: "Bradesti" },
  { judet: "Dolj", localitate: "Bralostita" },
  { judet: "Dolj", localitate: "Bratovoesti" },
  { judet: "Dolj", localitate: "Breasta" },
  { judet: "Dolj", localitate: "Bucovat" },
  { judet: "Dolj", localitate: "Bulzesti" },
  { judet: "Dolj", localitate: "Calarasi" },
  { judet: "Dolj", localitate: "Calopar" },
  { judet: "Dolj", localitate: "Caraula" },
  { judet: "Dolj", localitate: "Carcea" },
  { judet: "Dolj", localitate: "Carna" },
  { judet: "Dolj", localitate: "Carpen" },
  { judet: "Dolj", localitate: "Castranova" },
  { judet: "Dolj", localitate: "Catane" },
  { judet: "Dolj", localitate: "Celaru" },
  { judet: "Dolj", localitate: "Cerat" },
  { judet: "Dolj", localitate: "Cernatesti" },
  { judet: "Dolj", localitate: "Cetate" },
  { judet: "Dolj", localitate: "Cioroiasi" },
  { judet: "Dolj", localitate: "Macesu de Jos" },
  { judet: "Dolj", localitate: "Cosoveni" },
  { judet: "Dolj", localitate: "Cotofenii din Dos" },
  { judet: "Dolj", localitate: "Cotofenii din Fata" },
  { judet: "Dolj", localitate: "Daneti" },
  { judet: "Dolj", localitate: "Desa" },
  { judet: "Dolj", localitate: "Diosti" },
  { judet: "Dolj", localitate: "Dobresti" },
  { judet: "Dolj", localitate: "Dobrotesti" },
  { judet: "Dolj", localitate: "Dragotesti" },
  { judet: "Dolj", localitate: "Dranic" },
  { judet: "Dolj", localitate: "Farcas" },
  { judet: "Dolj", localitate: "Galicea Mare" },
  { judet: "Dolj", localitate: "Galiciuica" },
  { judet: "Dolj", localitate: "Gangiova" },
  { judet: "Dolj", localitate: "Ghercesti" },
  { judet: "Dolj", localitate: "Ghidici" },
  { judet: "Dolj", localitate: "Ghindeni" },
  { judet: "Dolj", localitate: "Gighera" },
  { judet: "Dolj", localitate: "Giubega" },
  { judet: "Dolj", localitate: "Giurgita" },
  { judet: "Dolj", localitate: "Gogosu" },
  { judet: "Dolj", localitate: "Goicea" },
  { judet: "Dolj", localitate: "Goiesti" },
  { judet: "Dolj", localitate: "Grecesti" },
  { judet: "Dolj", localitate: "Intorsura" },
  { judet: "Dolj", localitate: "Izvoare" },
  { judet: "Dolj", localitate: "Leu" },
  { judet: "Dolj", localitate: "Lipovu" },
  { judet: "Dolj", localitate: "Macesu de Sus" },
  { judet: "Dolj", localitate: "Maglavit" },
  { judet: "Dolj", localitate: "Malu Mare" },
  { judet: "Dolj", localitate: "Marsani" },
  { judet: "Dolj", localitate: "Melinesti" },
  { judet: "Dolj", localitate: "Mischii" },
  { judet: "Dolj", localitate: "Motatei" },
  { judet: "Dolj", localitate: "Murgasi" },
  { judet: "Dolj", localitate: "Negoi" },
  { judet: "Dolj", localitate: "Orodel" },
  { judet: "Dolj", localitate: "Ostroveni" },
  { judet: "Dolj", localitate: "Perisor" },
  { judet: "Dolj", localitate: "Pielesti" },
  { judet: "Dolj", localitate: "Piscu Vechi" },
  { judet: "Dolj", localitate: "Plenita" },
  { judet: "Dolj", localitate: "Plesoi" },
  { judet: "Dolj", localitate: "Podari" },
  { judet: "Dolj", localitate: "Poiana Mare" },
  { judet: "Dolj", localitate: "Predesti" },
  { judet: "Dolj", localitate: "Radovan" },
  { judet: "Dolj", localitate: "Rast" },
  { judet: "Dolj", localitate: "Robanesti" },
  { judet: "Dolj", localitate: "Rojiste" },
  { judet: "Dolj", localitate: "Sadova" },
  { judet: "Dolj", localitate: "Salcuta" },
  { judet: "Dolj", localitate: "Scaesti" },
  { judet: "Dolj", localitate: "Seaca de Camp" },
  { judet: "Dolj", localitate: "Seaca de Padure" },
  { judet: "Dolj", localitate: "Secu" },
  { judet: "Dolj", localitate: "Silistea Crucii" },
  { judet: "Dolj", localitate: "Simnicu de Sus" },
  { judet: "Dolj", localitate: "Sopot" },
  { judet: "Dolj", localitate: "Talpas" },
  { judet: "Dolj", localitate: "Teasc" },
  { judet: "Dolj", localitate: "Terpezita" },
  { judet: "Dolj", localitate: "Teslui" },
  { judet: "Dolj", localitate: "Tuglui" },
  { judet: "Dolj", localitate: "Unirea" },
  { judet: "Dolj", localitate: "Urzicuta" },
  { judet: "Dolj", localitate: "Valea Stanciului" },
  { judet: "Dolj", localitate: "Vartop" },
  { judet: "Dolj", localitate: "Varvoru de Jos" },
  { judet: "Dolj", localitate: "Vela" },
  { judet: "Dolj", localitate: "Verbita" },
  { judet: "Dolj", localitate: "Bistret" },
  { judet: "Dolj", localitate: "Facai" },
  { judet: "Dolj", localitate: "Mofleni" },
  { judet: "Dolj", localitate: "Popoveni" },
  { judet: "Dolj", localitate: "Simnicu de Jos" },
  { judet: "Dolj", localitate: "Balasan" },
  { judet: "Dolj", localitate: "Basarabi" },
  { judet: "Dolj", localitate: "Ciupercenii Vechi" },
  { judet: "Dolj", localitate: "Golenti" },
  { judet: "Dolj", localitate: "Almajel" },
  { judet: "Dolj", localitate: "Balta" },
  { judet: "Dolj", localitate: "Braniste (Filiasi)" },
  { judet: "Dolj", localitate: "Fratostita" },
  { judet: "Dolj", localitate: "Racarii de Sus" },
  { judet: "Dolj", localitate: "Uscaci" },
  { judet: "Dolj", localitate: "Boureni" },
  { judet: "Dolj", localitate: "Covei" },
  { judet: "Dolj", localitate: "Beharca" },
  { judet: "Dolj", localitate: "Bogea" },
  { judet: "Dolj", localitate: "Mosneni" },
  { judet: "Dolj", localitate: "Sitoaia" },
  { judet: "Dolj", localitate: "Ocolna" },
  { judet: "Dolj", localitate: "Prapor" },
  { judet: "Dolj", localitate: "Nisipuri" },
  { judet: "Dolj", localitate: "Zvorsca" },
  { judet: "Dolj", localitate: "Baranu" },
  { judet: "Dolj", localitate: "Berbesu" },
  { judet: "Dolj", localitate: "Iordachesti" },
  { judet: "Dolj", localitate: "Leordoasa" },
  { judet: "Dolj", localitate: "Malumnic" },
  { judet: "Dolj", localitate: "Novac" },
  { judet: "Dolj", localitate: "Piria" },
  { judet: "Dolj", localitate: "Poiana Fantanii" },
  { judet: "Dolj", localitate: "Salcia" },
  { judet: "Dolj", localitate: "Teascu din Deal" },
  { judet: "Dolj", localitate: "Ursoaia" },
  { judet: "Dolj", localitate: "Bistretu Nou" },
  { judet: "Dolj", localitate: "Brandusa" },
  { judet: "Dolj", localitate: "Plosca" },
  { judet: "Dolj", localitate: "Caraiman" },
  { judet: "Dolj", localitate: "Mosna" },
  { judet: "Dolj", localitate: "Rachita de Jos" },
  { judet: "Dolj", localitate: "Urdinita" },
  { judet: "Dolj", localitate: "Voita" },
  { judet: "Dolj", localitate: "Ciocanele" },
  { judet: "Dolj", localitate: "Racovita" },
  { judet: "Dolj", localitate: "Schitu" },
  { judet: "Dolj", localitate: "Sfircea" },
  { judet: "Dolj", localitate: "Valea Fantanilor" },
  { judet: "Dolj", localitate: "Badosi" },
  { judet: "Dolj", localitate: "Georocu Mare" },
  { judet: "Dolj", localitate: "Prunet" },
  { judet: "Dolj", localitate: "Tamburesti" },
  { judet: "Dolj", localitate: "Bradestii Batrani" },
  { judet: "Dolj", localitate: "Meteu" },
  { judet: "Dolj", localitate: "Piscani" },
  { judet: "Dolj", localitate: "Racarii de Jos" },
  { judet: "Dolj", localitate: "Tatomiresti" },
  { judet: "Dolj", localitate: "Cotu" },
  { judet: "Dolj", localitate: "Crovna" },
  { judet: "Dolj", localitate: "Faget" },
  { judet: "Dolj", localitate: "Obedin" },
  { judet: "Dolj", localitate: "Rosieni" },
  { judet: "Dolj", localitate: "Valea Lungului" },
  { judet: "Dolj", localitate: "Carligei" },
  { judet: "Dolj", localitate: "Italieni" },
  { judet: "Dolj", localitate: "Leamna de Jos" },
  { judet: "Dolj", localitate: "Leamna de Sus" },
  { judet: "Dolj", localitate: "Palilula" },
  { judet: "Dolj", localitate: "Sarbatoarea" },
  { judet: "Dolj", localitate: "Fratila" },
  { judet: "Dolj", localitate: "Gura Racului" },
  { judet: "Dolj", localitate: "Infratirea" },
  { judet: "Dolj", localitate: "Piscu Lung" },
  { judet: "Dolj", localitate: "Poienile" },
  { judet: "Dolj", localitate: "Prejoi" },
  { judet: "Dolj", localitate: "Saliste" },
  { judet: "Dolj", localitate: "Seculesti" },
  { judet: "Dolj", localitate: "Stoicesti" },
  { judet: "Dolj", localitate: "Bazdana" },
  { judet: "Dolj", localitate: "Belcinu" },
  { judet: "Dolj", localitate: "Panaghia" },
  { judet: "Dolj", localitate: "Salcuta (Calopar)" },
  { judet: "Dolj", localitate: "Cleanov" },
  { judet: "Dolj", localitate: "Geblesti" },
  { judet: "Dolj", localitate: "Puturi" },
  { judet: "Dolj", localitate: "Sarata" },
  { judet: "Dolj", localitate: "Ghizdavesti" },
  { judet: "Dolj", localitate: "Marotinu de Jos" },
  { judet: "Dolj", localitate: "Marotinu de Sus" },
  { judet: "Dolj", localitate: "Soreni" },
  { judet: "Dolj", localitate: "Malaica" },
  { judet: "Dolj", localitate: "Cornita" },
  { judet: "Dolj", localitate: "Rasnicu Batran" },
  { judet: "Dolj", localitate: "Rasnicu Oghian" },
  { judet: "Dolj", localitate: "Tiu" },
  { judet: "Dolj", localitate: "Moreni" },
  { judet: "Dolj", localitate: "Cetatuia (Cioroiasi)" },
  { judet: "Dolj", localitate: "Cioroiu Nou" },
  { judet: "Dolj", localitate: "Smardan" },
  { judet: "Dolj", localitate: "Mihaita" },
  { judet: "Dolj", localitate: "Potmeltu" },
  { judet: "Dolj", localitate: "Brabeti" },
  { judet: "Dolj", localitate: "Braniste (Daneti)" },
  { judet: "Dolj", localitate: "Locusteni" },
  { judet: "Dolj", localitate: "Chiasu" },
  { judet: "Dolj", localitate: "Ciocanesti" },
  { judet: "Dolj", localitate: "Radomir" },
  { judet: "Dolj", localitate: "Caciulatesti" },
  { judet: "Dolj", localitate: "Georocel" },
  { judet: "Dolj", localitate: "Murta" },
  { judet: "Dolj", localitate: "Toceni" },
  { judet: "Dolj", localitate: "Benesti" },
  { judet: "Dolj", localitate: "Bobeanu" },
  { judet: "Dolj", localitate: "Buzduc" },
  { judet: "Dolj", localitate: "Popanzalesti" },
  { judet: "Dolj", localitate: "Viisoara" },
  { judet: "Dolj", localitate: "Booveni" },
  { judet: "Dolj", localitate: "Foisor" },
  { judet: "Dolj", localitate: "Padea" },
  { judet: "Dolj", localitate: "Amarasti" },
  { judet: "Dolj", localitate: "Golumbelu" },
  { judet: "Dolj", localitate: "Golumbu" },
  { judet: "Dolj", localitate: "Moflesti" },
  { judet: "Dolj", localitate: "Nistoi" },
  { judet: "Dolj", localitate: "Plopu-Amarasti" },
  { judet: "Dolj", localitate: "Putinei" },
  { judet: "Dolj", localitate: "Soceni" },
  { judet: "Dolj", localitate: "Comosteni" },
  { judet: "Dolj", localitate: "Garlesti" },
  { judet: "Dolj", localitate: "Luncsoru" },
  { judet: "Dolj", localitate: "Ungureni" },
  { judet: "Dolj", localitate: "Ungurenii Mici" },
  { judet: "Dolj", localitate: "Nedeia" },
  { judet: "Dolj", localitate: "Zaval" },
  { judet: "Dolj", localitate: "Curmatura" },
  { judet: "Dolj", localitate: "Filaret" },
  { judet: "Dolj", localitate: "Gogosita" },
  { judet: "Dolj", localitate: "Stefanel" },
  { judet: "Dolj", localitate: "Adancata" },
  { judet: "Dolj", localitate: "Fantani" },
  { judet: "Dolj", localitate: "Gruita" },
  { judet: "Dolj", localitate: "Malaesti" },
  { judet: "Dolj", localitate: "Mogosesti" },
  { judet: "Dolj", localitate: "Muereni" },
  { judet: "Dolj", localitate: "Pioresti" },
  { judet: "Dolj", localitate: "Pometesti" },
  { judet: "Dolj", localitate: "Popeasa" },
  { judet: "Dolj", localitate: "Tandara" },
  { judet: "Dolj", localitate: "Vladimir" },
  { judet: "Dolj", localitate: "Zlatari" },
  { judet: "Dolj", localitate: "Barboi" },
  { judet: "Dolj", localitate: "Busu" },
  { judet: "Dolj", localitate: "Busuletu" },
  { judet: "Dolj", localitate: "Gradistea" },
  { judet: "Dolj", localitate: "Gropanele" },
  { judet: "Dolj", localitate: "Corlate" },
  { judet: "Dolj", localitate: "Domnu Tudor" },
  { judet: "Dolj", localitate: "Zanoaga" },
  { judet: "Dolj", localitate: "Lipovu de Sus" },
  { judet: "Dolj", localitate: "Hunia" },
  { judet: "Dolj", localitate: "Preajba" },
  { judet: "Dolj", localitate: "Sapata" },
  { judet: "Dolj", localitate: "Bodaiesti" },
  { judet: "Dolj", localitate: "Bodaiestii de Sus" },
  { judet: "Dolj", localitate: "Godeni" },
  { judet: "Dolj", localitate: "Muierusu" },
  { judet: "Dolj", localitate: "Negoiesti" },
  { judet: "Dolj", localitate: "Odoleni" },
  { judet: "Dolj", localitate: "Ohaba" },
  { judet: "Dolj", localitate: "Plostina" },
  { judet: "Dolj", localitate: "Popesti" },
  { judet: "Dolj", localitate: "Spineni" },
  { judet: "Dolj", localitate: "Valea Mare" },
  { judet: "Dolj", localitate: "Valea Muierii de Jos" },
  { judet: "Dolj", localitate: "Calinesti" },
  { judet: "Dolj", localitate: "Gogosesti" },
  { judet: "Dolj", localitate: "Mlecanesti" },
  { judet: "Dolj", localitate: "Motoci" },
  { judet: "Dolj", localitate: "Urechesti" },
  { judet: "Dolj", localitate: "Dobridor" },
  { judet: "Dolj", localitate: "Motatei-Gara" },
  { judet: "Dolj", localitate: "Balota de Jos" },
  { judet: "Dolj", localitate: "Balota de Sus" },
  { judet: "Dolj", localitate: "Busteni" },
  { judet: "Dolj", localitate: "Gaia" },
  { judet: "Dolj", localitate: "Picaturile" },
  { judet: "Dolj", localitate: "Rupturile" },
  { judet: "Dolj", localitate: "Velesti" },
  { judet: "Dolj", localitate: "Catanele Noi" },
  { judet: "Dolj", localitate: "Bechet (Orodel)" },
  { judet: "Dolj", localitate: "Calugarei" },
  { judet: "Dolj", localitate: "Cornu" },
  { judet: "Dolj", localitate: "Teiu" },
  { judet: "Dolj", localitate: "Listeava" },
  { judet: "Dolj", localitate: "Maracinele" },
  { judet: "Dolj", localitate: "Campeni" },
  { judet: "Dolj", localitate: "Langa" },
  { judet: "Dolj", localitate: "Pisculet" },
  { judet: "Dolj", localitate: "Castrele Traiane" },
  { judet: "Dolj", localitate: "Balta Verde" },
  { judet: "Dolj", localitate: "Braniste (Podari)" },
  { judet: "Dolj", localitate: "Gura Vaii" },
  { judet: "Dolj", localitate: "Livezi" },
  { judet: "Dolj", localitate: "Tunarii Noi" },
  { judet: "Dolj", localitate: "Tunarii Vechi" },
  { judet: "Dolj", localitate: "Bucicani" },
  { judet: "Dolj", localitate: "Carstovani" },
  { judet: "Dolj", localitate: "Frasin" },
  { judet: "Dolj", localitate: "Milovan" },
  { judet: "Dolj", localitate: "Predestii Mici" },
  { judet: "Dolj", localitate: "Fantanele (Radovan)" },
  { judet: "Dolj", localitate: "Tarnava" },
  { judet: "Dolj", localitate: "Bojoiu" },
  { judet: "Dolj", localitate: "Golfin" },
  { judet: "Dolj", localitate: "Lacrita Mare" },
  { judet: "Dolj", localitate: "Lacrita Mica" },
  { judet: "Dolj", localitate: "Robanestii de Jos" },
  { judet: "Dolj", localitate: "Robanestii de Sus" },
  { judet: "Dolj", localitate: "Piscu Sadovei" },
  { judet: "Dolj", localitate: "Marza" },
  { judet: "Dolj", localitate: "Plopsor" },
  { judet: "Dolj", localitate: "Tencanau" },
  { judet: "Dolj", localitate: "Valea lui Patru" },
  { judet: "Dolj", localitate: "Piscu Nou" },
  { judet: "Dolj", localitate: "Rachita de Sus" },
  { judet: "Dolj", localitate: "Veleni" },
  { judet: "Dolj", localitate: "Comanicea" },
  { judet: "Dolj", localitate: "Smadovicioara de Secu" },
  { judet: "Dolj", localitate: "Sumandra" },
  { judet: "Dolj", localitate: "Bascov" },
  { judet: "Dolj", localitate: "Belot" },
  { judet: "Dolj", localitate: "Cernat" },
  { judet: "Dolj", localitate: "Pereni" },
  { judet: "Dolj", localitate: "Pietroaia" },
  { judet: "Dolj", localitate: "Sarsca" },
  { judet: "Dolj", localitate: "Albesti" },
  { judet: "Dolj", localitate: "Cornetu" },
  { judet: "Dolj", localitate: "Deleni" },
  { judet: "Dolj", localitate: "Dudovicesti" },
  { judet: "Dolj", localitate: "Dutulesti" },
  { judet: "Dolj", localitate: "Floresti" },
  { judet: "Dolj", localitate: "Izvor" },
  { judet: "Dolj", localitate: "Jieni" },
  { judet: "Dolj", localitate: "Lesile" },
  { judet: "Dolj", localitate: "Milesti" },
  { judet: "Dolj", localitate: "Romanesti" },
  { judet: "Dolj", localitate: "Secui" },
  { judet: "Dolj", localitate: "Caciulatu" },
  { judet: "Dolj", localitate: "Caruia" },
  { judet: "Dolj", localitate: "Floran" },
  { judet: "Dolj", localitate: "Lazu" },
  { judet: "Dolj", localitate: "Cosereni" },
  { judet: "Dolj", localitate: "Fantanele (Teslui)" },
  { judet: "Dolj", localitate: "Preajba de Jos" },
  { judet: "Dolj", localitate: "Preajba de Padure" },
  { judet: "Dolj", localitate: "Tartal" },
  { judet: "Dolj", localitate: "Urieni" },
  { judet: "Dolj", localitate: "Viisoara-Mosneni" },
  { judet: "Dolj", localitate: "Jiul" },
  { judet: "Dolj", localitate: "Urzica Mare" },
  { judet: "Dolj", localitate: "Horezu-Poenari" },
  { judet: "Dolj", localitate: "Bujor" },
  { judet: "Dolj", localitate: "Ciutura" },
  { judet: "Dolj", localitate: "Criva" },
  { judet: "Dolj", localitate: "Dobromira" },
  { judet: "Dolj", localitate: "Dragoaia" },
  { judet: "Dolj", localitate: "Gabru" },
  { judet: "Dolj", localitate: "Varvor" },
  { judet: "Dolj", localitate: "Bucovicior" },
  { judet: "Dolj", localitate: "Cetatuia (Vela)" },
  { judet: "Dolj", localitate: "Desnatui" },
  { judet: "Dolj", localitate: "Gubaucea" },
  { judet: "Dolj", localitate: "Seglet" },
  { judet: "Dolj", localitate: "Suharu" },
  { judet: "Dolj", localitate: "Stiubei" },
  { judet: "Dolj", localitate: "Verbicioara" },
  { judet: "Galati", localitate: "Galati" },
  { judet: "Galati", localitate: "Tecuci" },
  { judet: "Galati", localitate: "Cudalbi" },
  { judet: "Galati", localitate: "Liesti" },
  { judet: "Galati", localitate: "Matca" },
  { judet: "Galati", localitate: "Beresti" },
  { judet: "Galati", localitate: "Targu Bujor" },
  { judet: "Galati", localitate: "Balabanesti" },
  { judet: "Galati", localitate: "Balasesti" },
  { judet: "Galati", localitate: "Baleni" },
  { judet: "Galati", localitate: "Baneasa" },
  { judet: "Galati", localitate: "Barcea" },
  { judet: "Galati", localitate: "Beresti-Meria" },
  { judet: "Galati", localitate: "Brahasesti" },
  { judet: "Galati", localitate: "Branistea" },
  { judet: "Galati", localitate: "Buciumeni" },
  { judet: "Galati", localitate: "Cavadinesti" },
  { judet: "Galati", localitate: "Certesti" },
  { judet: "Galati", localitate: "Corni" },
  { judet: "Galati", localitate: "Corod" },
  { judet: "Galati", localitate: "Cosmesti" },
  { judet: "Galati", localitate: "Costache Negri" },
  { judet: "Galati", localitate: "Cuca" },
  { judet: "Galati", localitate: "Cuza Voda" },
  { judet: "Galati", localitate: "Draganesti" },
  { judet: "Galati", localitate: "Draguseni" },
  { judet: "Galati", localitate: "Fartanesti" },
  { judet: "Galati", localitate: "Foltesti" },
  { judet: "Galati", localitate: "Frumusita" },
  { judet: "Galati", localitate: "Fundeni" },
  { judet: "Galati", localitate: "Ghidigeni" },
  { judet: "Galati", localitate: "Gohor" },
  { judet: "Galati", localitate: "Grivita" },
  { judet: "Galati", localitate: "Independenta" },
  { judet: "Galati", localitate: "Ivesti" },
  { judet: "Galati", localitate: "Jorasti" },
  { judet: "Galati", localitate: "Munteni" },
  { judet: "Galati", localitate: "Mastacani" },
  { judet: "Galati", localitate: "Movileni" },
  { judet: "Galati", localitate: "Namoloasa" },
  { judet: "Galati", localitate: "Negrilesti" },
  { judet: "Galati", localitate: "Nicoresti" },
  { judet: "Galati", localitate: "Oancea" },
  { judet: "Galati", localitate: "Pechea" },
  { judet: "Galati", localitate: "Piscu" },
  { judet: "Galati", localitate: "Poiana" },
  { judet: "Galati", localitate: "Priponesti" },
  { judet: "Galati", localitate: "Rediu" },
  { judet: "Galati", localitate: "Scanteiesti" },
  { judet: "Galati", localitate: "Schela" },
  { judet: "Galati", localitate: "Sendreni" },
  { judet: "Galati", localitate: "Slobozia Conachi" },
  { judet: "Galati", localitate: "Smardan" },
  { judet: "Galati", localitate: "Smulti" },
  { judet: "Galati", localitate: "Suceveni" },
  { judet: "Galati", localitate: "Suhurlui" },
  { judet: "Galati", localitate: "Tepu" },
  { judet: "Galati", localitate: "Tudor Vladimirescu" },
  { judet: "Galati", localitate: "Tulucesti" },
  { judet: "Galati", localitate: "Umbraresti" },
  { judet: "Galati", localitate: "Valea Marului" },
  { judet: "Galati", localitate: "Vanatori" },
  { judet: "Galati", localitate: "Varlezi" },
  { judet: "Galati", localitate: "Vladesti" },
  { judet: "Galati", localitate: "Moscu" },
  { judet: "Galati", localitate: "Umbraresti (Targu Bujor)" },
  { judet: "Galati", localitate: "Podoleni" },
  { judet: "Galati", localitate: "Bursucani" },
  { judet: "Galati", localitate: "Cruceanu" },
  { judet: "Galati", localitate: "Lungesti" },
  { judet: "Galati", localitate: "Radesti" },
  { judet: "Galati", localitate: "Zimbru" },
  { judet: "Galati", localitate: "Ciuresti" },
  { judet: "Galati", localitate: "Ciurestii Noi" },
  { judet: "Galati", localitate: "Pupezeni" },
  { judet: "Galati", localitate: "Roscani" },
  { judet: "Galati", localitate: "Aldesti" },
  { judet: "Galati", localitate: "Balintesti" },
  { judet: "Galati", localitate: "Onciu" },
  { judet: "Galati", localitate: "Plesa" },
  { judet: "Galati", localitate: "Prodanesti" },
  { judet: "Galati", localitate: "Puricani" },
  { judet: "Galati", localitate: "Saseni" },
  { judet: "Galati", localitate: "Slivna" },
  { judet: "Galati", localitate: "Sipote" },
  { judet: "Galati", localitate: "Lozova" },
  { judet: "Galati", localitate: "Traian" },
  { judet: "Galati", localitate: "Vasile Alecsandri" },
  { judet: "Galati", localitate: "Corcioveni" },
  { judet: "Galati", localitate: "Cositeni" },
  { judet: "Galati", localitate: "Toflea" },
  { judet: "Galati", localitate: "Hantesti" },
  { judet: "Galati", localitate: "Tecucelu Sec" },
  { judet: "Galati", localitate: "Vizuresti" },
  { judet: "Galati", localitate: "Comanesti" },
  { judet: "Galati", localitate: "Ganesti" },
  { judet: "Galati", localitate: "Vadeni" },
  { judet: "Galati", localitate: "Carlomanesti" },
  { judet: "Galati", localitate: "Cotoroaia" },
  { judet: "Galati", localitate: "Maciseni" },
  { judet: "Galati", localitate: "Urlesti" },
  { judet: "Galati", localitate: "Blanzi" },
  { judet: "Galati", localitate: "Bratulesti" },
  { judet: "Galati", localitate: "Carapcesti" },
  { judet: "Galati", localitate: "Baltareti" },
  { judet: "Galati", localitate: "Cosmestii-Vale" },
  { judet: "Galati", localitate: "Furcenii Noi" },
  { judet: "Galati", localitate: "Furcenii Vechi" },
  { judet: "Galati", localitate: "Satu Nou" },
  { judet: "Galati", localitate: "Malu Alb" },
  { judet: "Galati", localitate: "Adam" },
  { judet: "Galati", localitate: "Cauiesti" },
  { judet: "Galati", localitate: "Fundeanu" },
  { judet: "Galati", localitate: "Ghinghesti" },
  { judet: "Galati", localitate: "Nicopole" },
  { judet: "Galati", localitate: "Stietesti" },
  { judet: "Galati", localitate: "Viile" },
  { judet: "Galati", localitate: "Stoicani" },
  { judet: "Galati", localitate: "Ijdileni" },
  { judet: "Galati", localitate: "Tamaoani" },
  { judet: "Galati", localitate: "Fundenii Noi" },
  { judet: "Galati", localitate: "Hanu Conachi" },
  { judet: "Galati", localitate: "Lungoci" },
  { judet: "Galati", localitate: "Gara Ghidigeni" },
  { judet: "Galati", localitate: "Garbovat" },
  { judet: "Galati", localitate: "Gefu" },
  { judet: "Galati", localitate: "Gura Garbovatului" },
  { judet: "Galati", localitate: "Slobozia Corni" },
  { judet: "Galati", localitate: "Talpigi" },
  { judet: "Galati", localitate: "Taplau" },
  { judet: "Galati", localitate: "Gara Berheci" },
  { judet: "Galati", localitate: "Ireasca" },
  { judet: "Galati", localitate: "Nartesti" },
  { judet: "Galati", localitate: "Posta" },
  { judet: "Galati", localitate: "Calmatui" },
  { judet: "Galati", localitate: "Bucesti" },
  { judet: "Galati", localitate: "Lunca" },
  { judet: "Galati", localitate: "Zarnesti" },
  { judet: "Galati", localitate: "Chiraftei" },
  { judet: "Galati", localitate: "Frunzeasca" },
  { judet: "Galati", localitate: "Slobozia Blaneasa" },
  { judet: "Galati", localitate: "Tiganesti" },
  { judet: "Galati", localitate: "Ungureni" },
  { judet: "Galati", localitate: "Crangeni" },
  { judet: "Galati", localitate: "Namoloasa-Sat" },
  { judet: "Galati", localitate: "Branistea (Nicoresti)" },
  { judet: "Galati", localitate: "Coasta Lupei" },
  { judet: "Galati", localitate: "Dobrinesti" },
  { judet: "Galati", localitate: "Fantani" },
  { judet: "Galati", localitate: "Grozavesti" },
  { judet: "Galati", localitate: "Ionasesti" },
  { judet: "Galati", localitate: "Malureni" },
  { judet: "Galati", localitate: "Piscu Corbului" },
  { judet: "Galati", localitate: "Sarbi" },
  { judet: "Galati", localitate: "Visina" },
  { judet: "Galati", localitate: "Slobozia Oancea" },
  { judet: "Galati", localitate: "Lupele" },
  { judet: "Galati", localitate: "Vames" },
  { judet: "Galati", localitate: "Ciorasti" },
  { judet: "Galati", localitate: "Hustiu" },
  { judet: "Galati", localitate: "Liesti (Priponesti)" },
  { judet: "Galati", localitate: "Priponestii de Jos" },
  { judet: "Galati", localitate: "Plevna" },
  { judet: "Galati", localitate: "Fantanele" },
  { judet: "Galati", localitate: "Negrea" },
  { judet: "Galati", localitate: "Izvoarele" },
  { judet: "Galati", localitate: "Cismele" },
  { judet: "Galati", localitate: "Mihail Kogalniceanu" },
  { judet: "Galati", localitate: "Rogojeni" },
  { judet: "Galati", localitate: "Movileni (Sendreni)" },
  { judet: "Galati", localitate: "Serbestii Vechi" },
  { judet: "Galati", localitate: "Sivita" },
  { judet: "Galati", localitate: "Tatarca" },
  { judet: "Galati", localitate: "Tepu de Sus" },
  { judet: "Galati", localitate: "Condrea" },
  { judet: "Galati", localitate: "Salcia" },
  { judet: "Galati", localitate: "Silistea" },
  { judet: "Galati", localitate: "Torcesti" },
  { judet: "Galati", localitate: "Umbraresti-Deal" },
  { judet: "Galati", localitate: "Mandresti" },
  { judet: "Galati", localitate: "Costi" },
  { judet: "Galati", localitate: "Odaia Manolache" },
  { judet: "Galati", localitate: "Craiesti" },
  { judet: "Galati", localitate: "Branesti" },
  { judet: "Giurgiu", localitate: "Giurgiu" },
  { judet: "Giurgiu", localitate: "Hotarele" },
  { judet: "Giurgiu", localitate: "Bolintin-Vale" },
  { judet: "Giurgiu", localitate: "Mihailesti" },
  { judet: "Giurgiu", localitate: "Adunatii-Copaceni" },
  { judet: "Giurgiu", localitate: "Baneasa" },
  { judet: "Giurgiu", localitate: "Bolintin-Deal" },
  { judet: "Giurgiu", localitate: "Bucsani" },
  { judet: "Giurgiu", localitate: "Bulbucata" },
  { judet: "Giurgiu", localitate: "Buturugeni" },
  { judet: "Giurgiu", localitate: "Calugareni" },
  { judet: "Giurgiu", localitate: "Clejani" },
  { judet: "Giurgiu", localitate: "Colibasi" },
  { judet: "Giurgiu", localitate: "Comana" },
  { judet: "Giurgiu", localitate: "Cosoba" },
  { judet: "Giurgiu", localitate: "Crevedia Mare" },
  { judet: "Giurgiu", localitate: "Daia" },
  { judet: "Giurgiu", localitate: "Floresti-Stoenesti" },
  { judet: "Giurgiu", localitate: "Fratesti" },
  { judet: "Giurgiu", localitate: "Gaiseni" },
  { judet: "Giurgiu", localitate: "Gaujani" },
  { judet: "Giurgiu", localitate: "Ghimpati" },
  { judet: "Giurgiu", localitate: "Gogosari" },
  { judet: "Giurgiu", localitate: "Gostinari" },
  { judet: "Giurgiu", localitate: "Gostinu" },
  { judet: "Giurgiu", localitate: "Gradinari" },
  { judet: "Giurgiu", localitate: "Greaca" },
  { judet: "Giurgiu", localitate: "Herasti" },
  { judet: "Giurgiu", localitate: "Iepuresti" },
  { judet: "Giurgiu", localitate: "Isvoarele" },
  { judet: "Giurgiu", localitate: "Izvoarele" },
  { judet: "Giurgiu", localitate: "Joita" },
  { judet: "Giurgiu", localitate: "Letca Noua" },
  { judet: "Giurgiu", localitate: "Malu" },
  { judet: "Giurgiu", localitate: "Marsa" },
  { judet: "Giurgiu", localitate: "Mihai Bravu" },
  { judet: "Giurgiu", localitate: "Ogrezeni" },
  { judet: "Giurgiu", localitate: "Oinacu" },
  { judet: "Giurgiu", localitate: "Prundu" },
  { judet: "Giurgiu", localitate: "Putineiu" },
  { judet: "Giurgiu", localitate: "Rasuceni" },
  { judet: "Giurgiu", localitate: "Roata de Jos" },
  { judet: "Giurgiu", localitate: "Sabareni" },
  { judet: "Giurgiu", localitate: "Schitu" },
  { judet: "Giurgiu", localitate: "Singureni" },
  { judet: "Giurgiu", localitate: "Slobozia" },
  { judet: "Giurgiu", localitate: "Stanesti" },
  { judet: "Giurgiu", localitate: "Stoenesti" },
  { judet: "Giurgiu", localitate: "Toporu" },
  { judet: "Giurgiu", localitate: "Ulmi" },
  { judet: "Giurgiu", localitate: "Valea Dragului" },
  { judet: "Giurgiu", localitate: "Varasti" },
  { judet: "Giurgiu", localitate: "Vanatorii Mici" },
  { judet: "Giurgiu", localitate: "Vedea" },
  { judet: "Giurgiu", localitate: "Crivina" },
  { judet: "Giurgiu", localitate: "Malu Spart" },
  { judet: "Giurgiu", localitate: "Suseni" },
  { judet: "Giurgiu", localitate: "Draganescu" },
  { judet: "Giurgiu", localitate: "Novaci" },
  { judet: "Giurgiu", localitate: "Popesti" },
  { judet: "Giurgiu", localitate: "Darasti-Vlasca" },
  { judet: "Giurgiu", localitate: "Mogosesti" },
  { judet: "Giurgiu", localitate: "Varlaam" },
  { judet: "Giurgiu", localitate: "Frasinu" },
  { judet: "Giurgiu", localitate: "Pietrele" },
  { judet: "Giurgiu", localitate: "Sfantu Gheorghe (Baneasa)" },
  { judet: "Giurgiu", localitate: "Mihai Voda" },
  { judet: "Giurgiu", localitate: "Anghelesti" },
  { judet: "Giurgiu", localitate: "Goleasca" },
  { judet: "Giurgiu", localitate: "Obedeni" },
  { judet: "Giurgiu", localitate: "Podisor" },
  { judet: "Giurgiu", localitate: "Uiesti" },
  { judet: "Giurgiu", localitate: "Vadu Lat" },
  { judet: "Giurgiu", localitate: "Coteni" },
  { judet: "Giurgiu", localitate: "Facau" },
  { judet: "Giurgiu", localitate: "Teisori" },
  { judet: "Giurgiu", localitate: "Padureni" },
  { judet: "Giurgiu", localitate: "Podu Ilfovatului" },
  { judet: "Giurgiu", localitate: "Posta" },
  { judet: "Giurgiu", localitate: "Branistari" },
  { judet: "Giurgiu", localitate: "Crucea de Piatra" },
  { judet: "Giurgiu", localitate: "Hulubesti" },
  { judet: "Giurgiu", localitate: "Uzunu" },
  { judet: "Giurgiu", localitate: "Neajlovu" },
  { judet: "Giurgiu", localitate: "Podu Doamnei" },
  { judet: "Giurgiu", localitate: "Sterea" },
  { judet: "Giurgiu", localitate: "Campurelu" },
  { judet: "Giurgiu", localitate: "Budeni" },
  { judet: "Giurgiu", localitate: "Falastoaca" },
  { judet: "Giurgiu", localitate: "Gradistea" },
  { judet: "Giurgiu", localitate: "Vlad Tepes" },
  { judet: "Giurgiu", localitate: "Crevedia Mica" },
  { judet: "Giurgiu", localitate: "Dealu" },
  { judet: "Giurgiu", localitate: "Gaiseanca" },
  { judet: "Giurgiu", localitate: "Priboiu" },
  { judet: "Giurgiu", localitate: "Sfantu Gheorghe (Crevedia Mare)" },
  { judet: "Giurgiu", localitate: "Plopsoru" },
  { judet: "Giurgiu", localitate: "Floresti" },
  { judet: "Giurgiu", localitate: "Palanca" },
  { judet: "Giurgiu", localitate: "Stoenesti (Floresti-Stoenesti)" },
  { judet: "Giurgiu", localitate: "Cetatea" },
  { judet: "Giurgiu", localitate: "Remus" },
  { judet: "Giurgiu", localitate: "Carpenisu" },
  { judet: "Giurgiu", localitate: "Cascioarele (Gaiseni)" },
  { judet: "Giurgiu", localitate: "Podu Popa Nae" },
  { judet: "Giurgiu", localitate: "Cetatuia" },
  { judet: "Giurgiu", localitate: "Pietrisu" },
  { judet: "Giurgiu", localitate: "Copaciu" },
  { judet: "Giurgiu", localitate: "Naipu" },
  { judet: "Giurgiu", localitate: "Valea Plopilor" },
  { judet: "Giurgiu", localitate: "Draghiceanu" },
  { judet: "Giurgiu", localitate: "Izvoru (Gogosari)" },
  { judet: "Giurgiu", localitate: "Ralesti" },
  { judet: "Giurgiu", localitate: "Mironesti" },
  { judet: "Giurgiu", localitate: "Tantava" },
  { judet: "Giurgiu", localitate: "Zorile" },
  { judet: "Giurgiu", localitate: "Putu Greci" },
  { judet: "Giurgiu", localitate: "Zboiu" },
  { judet: "Giurgiu", localitate: "Milosesti" },
  { judet: "Giurgiu", localitate: "Teiusu" },
  { judet: "Giurgiu", localitate: "Banesti" },
  { judet: "Giurgiu", localitate: "Chirculesti" },
  { judet: "Giurgiu", localitate: "Gorneni" },
  { judet: "Giurgiu", localitate: "Stalpu" },
  { judet: "Giurgiu", localitate: "Valter Maracineanu" },
  { judet: "Giurgiu", localitate: "Chiriacu" },
  { judet: "Giurgiu", localitate: "Dimitrie Cantemir" },
  { judet: "Giurgiu", localitate: "Petru Rares" },
  { judet: "Giurgiu", localitate: "Radu Voda" },
  { judet: "Giurgiu", localitate: "Valea Bujorului" },
  { judet: "Giurgiu", localitate: "Bacu" },
  { judet: "Giurgiu", localitate: "Letca Veche" },
  { judet: "Giurgiu", localitate: "Milcovatu" },
  { judet: "Giurgiu", localitate: "Hobaia" },
  { judet: "Giurgiu", localitate: "Branistea" },
  { judet: "Giurgiu", localitate: "Comasca" },
  { judet: "Giurgiu", localitate: "Puieni" },
  { judet: "Giurgiu", localitate: "Hodivoaia" },
  { judet: "Giurgiu", localitate: "Vieru" },
  { judet: "Giurgiu", localitate: "Carapancea" },
  { judet: "Giurgiu", localitate: "Cucuruzu" },
  { judet: "Giurgiu", localitate: "Satu Nou" },
  { judet: "Giurgiu", localitate: "Cartojani" },
  { judet: "Giurgiu", localitate: "Roata Mica" },
  { judet: "Giurgiu", localitate: "Sadina" },
  { judet: "Giurgiu", localitate: "Bila" },
  { judet: "Giurgiu", localitate: "Camineasca" },
  { judet: "Giurgiu", localitate: "Vlasin" },
  { judet: "Giurgiu", localitate: "Cranguri" },
  { judet: "Giurgiu", localitate: "Stejaru" },
  { judet: "Giurgiu", localitate: "Balanu" },
  { judet: "Giurgiu", localitate: "Ghizdaru" },
  { judet: "Giurgiu", localitate: "Oncesti" },
  { judet: "Giurgiu", localitate: "Ianculesti" },
  { judet: "Giurgiu", localitate: "Mirau" },
  { judet: "Giurgiu", localitate: "Tomulesti" },
  { judet: "Giurgiu", localitate: "Cascioarele (Ulmi)" },
  { judet: "Giurgiu", localitate: "Draganeasca" },
  { judet: "Giurgiu", localitate: "Ghionea" },
  { judet: "Giurgiu", localitate: "Icoana" },
  { judet: "Giurgiu", localitate: "Mosteni" },
  { judet: "Giurgiu", localitate: "Poenari" },
  { judet: "Giurgiu", localitate: "Trestieni" },
  { judet: "Giurgiu", localitate: "Dobreni" },
  { judet: "Giurgiu", localitate: "Corbeanca" },
  { judet: "Giurgiu", localitate: "Cupele" },
  { judet: "Giurgiu", localitate: "Izvoru (Vanatorii Mici)" },
  { judet: "Giurgiu", localitate: "Poiana lui Stanga" },
  { judet: "Giurgiu", localitate: "Valcelele" },
  { judet: "Giurgiu", localitate: "Vanatorii Mari" },
  { judet: "Giurgiu", localitate: "Zadariciu" },
  { judet: "Gorj", localitate: "Targu Jiu" },
  { judet: "Gorj", localitate: "Motru" },
  { judet: "Gorj", localitate: "Targu Carbunesti" },
  { judet: "Gorj", localitate: "Matasari" },
  { judet: "Gorj", localitate: "Rosiuta" },
  { judet: "Gorj", localitate: "Rovinari" },
  { judet: "Gorj", localitate: "Birsesti" },
  { judet: "Gorj", localitate: "Bumbesti Jiu" },
  { judet: "Gorj", localitate: "Stoina" },
  { judet: "Gorj", localitate: "Bustuchin" },
  { judet: "Gorj", localitate: "Ticleni" },
  { judet: "Gorj", localitate: "Turburea" },
  { judet: "Gorj", localitate: "Novaci" },
  { judet: "Gorj", localitate: "Tismana" },
  { judet: "Gorj", localitate: "Turceni" },
  { judet: "Gorj", localitate: "Albeni" },
  { judet: "Gorj", localitate: "Alimpesti" },
  { judet: "Gorj", localitate: "Aninoasa" },
  { judet: "Gorj", localitate: "Arcani" },
  { judet: "Gorj", localitate: "Baia de Fier" },
  { judet: "Gorj", localitate: "Balanesti" },
  { judet: "Gorj", localitate: "Balesti" },
  { judet: "Gorj", localitate: "Barbatesti" },
  { judet: "Gorj", localitate: "Bengesti-Ciocadia" },
  { judet: "Gorj", localitate: "Berlesti" },
  { judet: "Gorj", localitate: "Balteni" },
  { judet: "Gorj", localitate: "Bolbosi" },
  { judet: "Gorj", localitate: "Borascu" },
  { judet: "Gorj", localitate: "Branesti" },
  { judet: "Gorj", localitate: "Bumbesti-Pitic" },
  { judet: "Gorj", localitate: "Calnic" },
  { judet: "Gorj", localitate: "Capreni" },
  { judet: "Gorj", localitate: "Catunele" },
  { judet: "Gorj", localitate: "Ciuperceni" },
  { judet: "Gorj", localitate: "Crasna" },
  { judet: "Gorj", localitate: "Cruset" },
  { judet: "Gorj", localitate: "Danciulesti" },
  { judet: "Gorj", localitate: "Danesti" },
  { judet: "Gorj", localitate: "Dragotesti" },
  { judet: "Gorj", localitate: "Dragutesti" },
  { judet: "Gorj", localitate: "Farcasesti" },
  { judet: "Gorj", localitate: "Glogova" },
  { judet: "Gorj", localitate: "Godinesti" },
  { judet: "Gorj", localitate: "Hurezani" },
  { judet: "Gorj", localitate: "Ionesti" },
  { judet: "Gorj", localitate: "Jupanesti" },
  { judet: "Gorj", localitate: "Lelesti" },
  { judet: "Gorj", localitate: "Licurici" },
  { judet: "Gorj", localitate: "Logresti" },
  { judet: "Gorj", localitate: "Musetesti" },
  { judet: "Gorj", localitate: "Negomir" },
  { judet: "Gorj", localitate: "Pades" },
  { judet: "Gorj", localitate: "Pestisani" },
  { judet: "Gorj", localitate: "Plopsoru" },
  { judet: "Gorj", localitate: "Polovragi" },
  { judet: "Gorj", localitate: "Prigoria" },
  { judet: "Gorj", localitate: "Rosia de Amaradia" },
  { judet: "Gorj", localitate: "Runcu" },
  { judet: "Gorj", localitate: "Sacelu" },
  { judet: "Gorj", localitate: "Samarinesti" },
  { judet: "Gorj", localitate: "Schela" },
  { judet: "Gorj", localitate: "Scoarta" },
  { judet: "Gorj", localitate: "Slivilesti" },
  { judet: "Gorj", localitate: "Stanesti" },
  { judet: "Gorj", localitate: "Stejari" },
  { judet: "Gorj", localitate: "Tantareni" },
  { judet: "Gorj", localitate: "Telesti" },
  { judet: "Gorj", localitate: "Turcinesti" },
  { judet: "Gorj", localitate: "Urdari" },
  { judet: "Gorj", localitate: "Vagiulesti" },
  { judet: "Gorj", localitate: "Vladimir" },
  { judet: "Gorj", localitate: "Pesteana Jiu" },
  { judet: "Gorj", localitate: "Targu Logresti" },
  { judet: "Gorj", localitate: "Dragoeni" },
  { judet: "Gorj", localitate: "Iezureni" },
  { judet: "Gorj", localitate: "Polata" },
  { judet: "Gorj", localitate: "Preajba Mare" },
  { judet: "Gorj", localitate: "Romanesti" },
  { judet: "Gorj", localitate: "Slobozia" },
  { judet: "Gorj", localitate: "Ursati" },
  { judet: "Gorj", localitate: "Curtisoara" },
  { judet: "Gorj", localitate: "Lazaresti" },
  { judet: "Gorj", localitate: "Plesa" },
  { judet: "Gorj", localitate: "Tetila" },
  { judet: "Gorj", localitate: "Dealu Pomilor" },
  { judet: "Gorj", localitate: "Horasti" },
  { judet: "Gorj", localitate: "Insuratei" },
  { judet: "Gorj", localitate: "Leurda" },
  { judet: "Gorj", localitate: "Lupoita" },
  { judet: "Gorj", localitate: "Plostina" },
  { judet: "Gorj", localitate: "Rapa" },
  { judet: "Gorj", localitate: "Bercesti" },
  { judet: "Gorj", localitate: "Hirisesti" },
  { judet: "Gorj", localitate: "Pociovalistea" },
  { judet: "Gorj", localitate: "Sitesti" },
  { judet: "Gorj", localitate: "Blahnita de Jos" },
  { judet: "Gorj", localitate: "Carbunesti-Sat" },
  { judet: "Gorj", localitate: "Cojani" },
  { judet: "Gorj", localitate: "Cretesti" },
  { judet: "Gorj", localitate: "Curteana" },
  { judet: "Gorj", localitate: "Floresteni" },
  { judet: "Gorj", localitate: "Macesu" },
  { judet: "Gorj", localitate: "Pojogeni" },
  { judet: "Gorj", localitate: "Rogojeni" },
  { judet: "Gorj", localitate: "Stefanesti" },
  { judet: "Gorj", localitate: "Barzeiu de Gilort" },
  { judet: "Gorj", localitate: "Bolbocesti" },
  { judet: "Gorj", localitate: "Doseni" },
  { judet: "Gorj", localitate: "Mirosloveni" },
  { judet: "Gorj", localitate: "Prunesti" },
  { judet: "Gorj", localitate: "Ciupercenii de Oltet" },
  { judet: "Gorj", localitate: "Corsoru" },
  { judet: "Gorj", localitate: "Nistoresti" },
  { judet: "Gorj", localitate: "Sarbesti" },
  { judet: "Gorj", localitate: "Bobaia" },
  { judet: "Gorj", localitate: "Costesti" },
  { judet: "Gorj", localitate: "Groserea" },
  { judet: "Gorj", localitate: "Sterpoaia" },
  { judet: "Gorj", localitate: "Campofeni" },
  { judet: "Gorj", localitate: "Sanatesti" },
  { judet: "Gorj", localitate: "Stroiesti" },
  { judet: "Gorj", localitate: "Cernadia" },
  { judet: "Gorj", localitate: "Voitestii din Vale" },
  { judet: "Gorj", localitate: "Blidari" },
  { judet: "Gorj", localitate: "Canepesti" },
  { judet: "Gorj", localitate: "Glodeni" },
  { judet: "Gorj", localitate: "Ohaba" },
  { judet: "Gorj", localitate: "Voitestii din Deal" },
  { judet: "Gorj", localitate: "Ceauru" },
  { judet: "Gorj", localitate: "Cornesti" },
  { judet: "Gorj", localitate: "Gavanesti" },
  { judet: "Gorj", localitate: "Rasova" },
  { judet: "Gorj", localitate: "Stolojani" },
  { judet: "Gorj", localitate: "Talpasesti" },
  { judet: "Gorj", localitate: "Tamasesti" },
  { judet: "Gorj", localitate: "Voinigesti" },
  { judet: "Gorj", localitate: "Musculesti" },
  { judet: "Gorj", localitate: "Petresti" },
  { judet: "Gorj", localitate: "Socu" },
  { judet: "Gorj", localitate: "Cocoreni" },
  { judet: "Gorj", localitate: "Moi" },
  { judet: "Gorj", localitate: "Vladuleni" },
  { judet: "Gorj", localitate: "Balcesti" },
  { judet: "Gorj", localitate: "Bengesti" },
  { judet: "Gorj", localitate: "Bircii" },
  { judet: "Gorj", localitate: "Ciocadia" },
  { judet: "Gorj", localitate: "Barzeiu" },
  { judet: "Gorj", localitate: "Galcesti" },
  { judet: "Gorj", localitate: "Lihulesti" },
  { judet: "Gorj", localitate: "Parau Viu" },
  { judet: "Gorj", localitate: "Scrada" },
  { judet: "Gorj", localitate: "Scurtu" },
  { judet: "Gorj", localitate: "Balacesti" },
  { judet: "Gorj", localitate: "Bolboasa" },
  { judet: "Gorj", localitate: "Igirosu" },
  { judet: "Gorj", localitate: "Miclosu" },
  { judet: "Gorj", localitate: "Ohaba-Jiu" },
  { judet: "Gorj", localitate: "Valea" },
  { judet: "Gorj", localitate: "Baniu" },
  { judet: "Gorj", localitate: "Calaparu" },
  { judet: "Gorj", localitate: "Gura-Menti" },
  { judet: "Gorj", localitate: "Mentii din Dos" },
  { judet: "Gorj", localitate: "Miluta" },
  { judet: "Gorj", localitate: "Scorusu" },
  { judet: "Gorj", localitate: "Badesti" },
  { judet: "Gorj", localitate: "Brebenei" },
  { judet: "Gorj", localitate: "Capu Dealului" },
  { judet: "Gorj", localitate: "Gilortu" },
  { judet: "Gorj", localitate: "Parau" },
  { judet: "Gorj", localitate: "Carligei" },
  { judet: "Gorj", localitate: "Poienari" },
  { judet: "Gorj", localitate: "Cionti" },
  { judet: "Gorj", localitate: "Motorgi" },
  { judet: "Gorj", localitate: "Namete" },
  { judet: "Gorj", localitate: "Poiana-Seciuri" },
  { judet: "Gorj", localitate: "Poienita" },
  { judet: "Gorj", localitate: "Pojaru" },
  { judet: "Gorj", localitate: "Valea Pojarului" },
  { judet: "Gorj", localitate: "Alunis" },
  { judet: "Gorj", localitate: "Bratesti" },
  { judet: "Gorj", localitate: "Bulbuceni" },
  { judet: "Gorj", localitate: "Cetatea" },
  { judet: "Gorj", localitate: "Cornetu" },
  { judet: "Gorj", localitate: "Dealu Spirei" },
  { judet: "Gorj", localitate: "Satu Nou" },
  { judet: "Gorj", localitate: "Dealu Viilor" },
  { judet: "Gorj", localitate: "Lupoaia" },
  { judet: "Gorj", localitate: "Steic" },
  { judet: "Gorj", localitate: "Valea Manastirii" },
  { judet: "Gorj", localitate: "Valea Perilor" },
  { judet: "Gorj", localitate: "Calnicu de Sus" },
  { judet: "Gorj", localitate: "Didilesti" },
  { judet: "Gorj", localitate: "Galesoaia" },
  { judet: "Gorj", localitate: "Hodoreasca" },
  { judet: "Gorj", localitate: "Pieptani" },
  { judet: "Gorj", localitate: "Pinoasa" },
  { judet: "Gorj", localitate: "Stejerei" },
  { judet: "Gorj", localitate: "Valceaua" },
  { judet: "Gorj", localitate: "Boboiesti" },
  { judet: "Gorj", localitate: "Pesteana-Vulcan" },
  { judet: "Gorj", localitate: "Priporu" },
  { judet: "Gorj", localitate: "Stramba-Vulcan" },
  { judet: "Gorj", localitate: "Vartopu" },
  { judet: "Gorj", localitate: "Zorzila" },
  { judet: "Gorj", localitate: "Aninisu din Deal" },
  { judet: "Gorj", localitate: "Aninisu din Vale" },
  { judet: "Gorj", localitate: "Buzesti" },
  { judet: "Gorj", localitate: "Carpinis" },
  { judet: "Gorj", localitate: "Crasna din Deal" },
  { judet: "Gorj", localitate: "Dragoiesti" },
  { judet: "Gorj", localitate: "Dumbraveni" },
  { judet: "Gorj", localitate: "Radosi" },
  { judet: "Gorj", localitate: "Bojinu" },
  { judet: "Gorj", localitate: "Marinesti" },
  { judet: "Gorj", localitate: "Maiag" },
  { judet: "Gorj", localitate: "Mierea" },
  { judet: "Gorj", localitate: "Miericeaua" },
  { judet: "Gorj", localitate: "Slamnesti" },
  { judet: "Gorj", localitate: "Slavuta" },
  { judet: "Gorj", localitate: "Urda de Jos" },
  { judet: "Gorj", localitate: "Valuta" },
  { judet: "Gorj", localitate: "Bibulesti" },
  { judet: "Gorj", localitate: "Halangesti" },
  { judet: "Gorj", localitate: "Obarsia" },
  { judet: "Gorj", localitate: "Petrachei" },
  { judet: "Gorj", localitate: "Radinesti" },
  { judet: "Gorj", localitate: "Zaicoiu" },
  { judet: "Gorj", localitate: "Barza" },
  { judet: "Gorj", localitate: "Botorogi" },
  { judet: "Gorj", localitate: "Bratuia" },
  { judet: "Gorj", localitate: "Bucureasa" },
  { judet: "Gorj", localitate: "Merfulesti" },
  { judet: "Gorj", localitate: "Sasa" },
  { judet: "Gorj", localitate: "Trocani" },
  { judet: "Gorj", localitate: "Tarculesti" },
  { judet: "Gorj", localitate: "Ungureni" },
  { judet: "Gorj", localitate: "Vacarea" },
  { judet: "Gorj", localitate: "Corobai" },
  { judet: "Gorj", localitate: "Trestioara" },
  { judet: "Gorj", localitate: "Carbesti" },
  { judet: "Gorj", localitate: "Dambova" },
  { judet: "Gorj", localitate: "Iasi-Gorj" },
  { judet: "Gorj", localitate: "Talvesti" },
  { judet: "Gorj", localitate: "Urechesti" },
  { judet: "Gorj", localitate: "Farcasesti-Mosneni" },
  { judet: "Gorj", localitate: "Pesteana de Jos" },
  { judet: "Gorj", localitate: "Rogojel" },
  { judet: "Gorj", localitate: "Rosia-Jiu" },
  { judet: "Gorj", localitate: "Timiseni" },
  { judet: "Gorj", localitate: "Valea cu Apa" },
  { judet: "Gorj", localitate: "Camuiesti" },
  { judet: "Gorj", localitate: "Clesnesti" },
  { judet: "Gorj", localitate: "Iormanesti" },
  { judet: "Gorj", localitate: "Olteanu" },
  { judet: "Gorj", localitate: "Arjoci" },
  { judet: "Gorj", localitate: "Calcesti" },
  { judet: "Gorj", localitate: "Chiliu" },
  { judet: "Gorj", localitate: "Parau de Pripor" },
  { judet: "Gorj", localitate: "Parau de Vale" },
  { judet: "Gorj", localitate: "Ratez" },
  { judet: "Gorj", localitate: "Busuioci" },
  { judet: "Gorj", localitate: "Pegeni" },
  { judet: "Gorj", localitate: "Plopu" },
  { judet: "Gorj", localitate: "Totea de Hurezani" },
  { judet: "Gorj", localitate: "Gura Susitei" },
  { judet: "Gorj", localitate: "Iliesti" },
  { judet: "Gorj", localitate: "Picu" },
  { judet: "Gorj", localitate: "Boia" },
  { judet: "Gorj", localitate: "Parau Boia" },
  { judet: "Gorj", localitate: "Vidin" },
  { judet: "Gorj", localitate: "Viersani" },
  { judet: "Gorj", localitate: "Fratesti" },
  { judet: "Gorj", localitate: "Rasovita" },
  { judet: "Gorj", localitate: "Frumusei" },
  { judet: "Gorj", localitate: "Negreni" },
  { judet: "Gorj", localitate: "Totea" },
  { judet: "Gorj", localitate: "Coltesti" },
  { judet: "Gorj", localitate: "Frunza" },
  { judet: "Gorj", localitate: "Logresti-Mosteni" },
  { judet: "Gorj", localitate: "Maru" },
  { judet: "Gorj", localitate: "Popesti" },
  { judet: "Gorj", localitate: "Seaca" },
  { judet: "Gorj", localitate: "Bradet" },
  { judet: "Gorj", localitate: "Bradetel" },
  { judet: "Gorj", localitate: "Croici" },
  { judet: "Gorj", localitate: "Runcurel" },
  { judet: "Gorj", localitate: "Arseni" },
  { judet: "Gorj", localitate: "Barcaciu" },
  { judet: "Gorj", localitate: "Gamani" },
  { judet: "Gorj", localitate: "Grui" },
  { judet: "Gorj", localitate: "Stancesti" },
  { judet: "Gorj", localitate: "Stancesti-Larga" },
  { judet: "Gorj", localitate: "Artanu" },
  { judet: "Gorj", localitate: "Bohorel" },
  { judet: "Gorj", localitate: "Condeiesti" },
  { judet: "Gorj", localitate: "Nucetu" },
  { judet: "Gorj", localitate: "Orzu" },
  { judet: "Gorj", localitate: "Paltinu" },
  { judet: "Gorj", localitate: "Raci" },
  { judet: "Gorj", localitate: "Ursoaia" },
  { judet: "Gorj", localitate: "Valea Racilor" },
  { judet: "Gorj", localitate: "Apa Neagra" },
  { judet: "Gorj", localitate: "Calugareni" },
  { judet: "Gorj", localitate: "Cerna-Sat" },
  { judet: "Gorj", localitate: "Closani" },
  { judet: "Gorj", localitate: "Motru Sec" },
  { judet: "Gorj", localitate: "Orzesti" },
  { judet: "Gorj", localitate: "Vaieni" },
  { judet: "Gorj", localitate: "Borosteni" },
  { judet: "Gorj", localitate: "Bradiceni" },
  { judet: "Gorj", localitate: "Francesti" },
  { judet: "Gorj", localitate: "Gureni" },
  { judet: "Gorj", localitate: "Hobita" },
  { judet: "Gorj", localitate: "Seuca" },
  { judet: "Gorj", localitate: "Brosteni" },
  { judet: "Gorj", localitate: "Brostenii de Sus" },
  { judet: "Gorj", localitate: "Ceplea" },
  { judet: "Gorj", localitate: "Cursaru" },
  { judet: "Gorj", localitate: "Deleni" },
  { judet: "Gorj", localitate: "Izvoarele" },
  { judet: "Gorj", localitate: "Olari" },
  { judet: "Gorj", localitate: "Piscuri" },
  { judet: "Gorj", localitate: "Sardanesti" },
  { judet: "Gorj", localitate: "Valeni" },
  { judet: "Gorj", localitate: "Racovita" },
  { judet: "Gorj", localitate: "Bucsana" },
  { judet: "Gorj", localitate: "Burlani" },
  { judet: "Gorj", localitate: "Calugareasa" },
  { judet: "Gorj", localitate: "Dobrana" },
  { judet: "Gorj", localitate: "Negoiesti" },
  { judet: "Gorj", localitate: "Zorlesti" },
  { judet: "Gorj", localitate: "Becheni" },
  { judet: "Gorj", localitate: "Dealu Viei" },
  { judet: "Gorj", localitate: "Ruget" },
  { judet: "Gorj", localitate: "Seciurile" },
  { judet: "Gorj", localitate: "Stejaru" },
  { judet: "Gorj", localitate: "Sitoaia" },
  { judet: "Gorj", localitate: "Balta" },
  { judet: "Gorj", localitate: "Baltisoara" },
  { judet: "Gorj", localitate: "Dobrita" },
  { judet: "Gorj", localitate: "Rachiti" },
  { judet: "Gorj", localitate: "Suseni" },
  { judet: "Gorj", localitate: "Valea Mare" },
  { judet: "Gorj", localitate: "Bazavani" },
  { judet: "Gorj", localitate: "Boca" },
  { judet: "Gorj", localitate: "Duculesti" },
  { judet: "Gorj", localitate: "Larga" },
  { judet: "Gorj", localitate: "Tirioi" },
  { judet: "Gorj", localitate: "Valea Bisericii" },
  { judet: "Gorj", localitate: "Valea Mica" },
  { judet: "Gorj", localitate: "Valea Poienii" },
  { judet: "Gorj", localitate: "Blahnita de Sus" },
  { judet: "Gorj", localitate: "Haiesti" },
  { judet: "Gorj", localitate: "Jeristea" },
  { judet: "Gorj", localitate: "Magheresti" },
  { judet: "Gorj", localitate: "Saulesti" },
  { judet: "Gorj", localitate: "Bibesti" },
  { judet: "Gorj", localitate: "Dolcesti" },
  { judet: "Gorj", localitate: "Purcaru" },
  { judet: "Gorj", localitate: "Arsuri" },
  { judet: "Gorj", localitate: "Gornacel" },
  { judet: "Gorj", localitate: "Pajistele" },
  { judet: "Gorj", localitate: "Sambotin" },
  { judet: "Gorj", localitate: "Bobu" },
  { judet: "Gorj", localitate: "Budieni" },
  { judet: "Gorj", localitate: "Campu Mare" },
  { judet: "Gorj", localitate: "Ceratu de Copacioasa" },
  { judet: "Gorj", localitate: "Colibasi" },
  { judet: "Gorj", localitate: "Copacioasa" },
  { judet: "Gorj", localitate: "Lazuri" },
  { judet: "Gorj", localitate: "Lintea" },
  { judet: "Gorj", localitate: "Mogosani" },
  { judet: "Gorj", localitate: "Pistestii din Deal" },
  { judet: "Gorj", localitate: "Cojmanesti" },
  { judet: "Gorj", localitate: "Miculesti" },
  { judet: "Gorj", localitate: "Stramtu" },
  { judet: "Gorj", localitate: "Sura" },
  { judet: "Gorj", localitate: "Siacu" },
  { judet: "Gorj", localitate: "Stiucani" },
  { judet: "Gorj", localitate: "Tehomir" },
  { judet: "Gorj", localitate: "Alexeni" },
  { judet: "Gorj", localitate: "Balani" },
  { judet: "Gorj", localitate: "Calesti" },
  { judet: "Gorj", localitate: "Curpen" },
  { judet: "Gorj", localitate: "Mazaroi" },
  { judet: "Gorj", localitate: "Obreja" },
  { judet: "Gorj", localitate: "Parvulesti" },
  { judet: "Gorj", localitate: "Vaidei" },
  { judet: "Gorj", localitate: "Valari" },
  { judet: "Gorj", localitate: "Balosani" },
  { judet: "Gorj", localitate: "Bacesti" },
  { judet: "Gorj", localitate: "Dealu Leului" },
  { judet: "Gorj", localitate: "Piscoiu" },
  { judet: "Gorj", localitate: "Popesti-Stejari" },
  { judet: "Gorj", localitate: "Ciorari" },
  { judet: "Gorj", localitate: "Mielusei" },
  { judet: "Gorj", localitate: "Paisani" },
  { judet: "Gorj", localitate: "Toiaga" },
  { judet: "Gorj", localitate: "Ulmet" },
  { judet: "Gorj", localitate: "Urda de Sus" },
  { judet: "Gorj", localitate: "Buduhala" },
  { judet: "Gorj", localitate: "Somanesti" },
  { judet: "Gorj", localitate: "Celei" },
  { judet: "Gorj", localitate: "Costeni" },
  { judet: "Gorj", localitate: "Gornovita" },
  { judet: "Gorj", localitate: "Isvarna" },
  { judet: "Gorj", localitate: "Pocruia" },
  { judet: "Gorj", localitate: "Racoti" },
  { judet: "Gorj", localitate: "Sohodol" },
  { judet: "Gorj", localitate: "Topesti" },
  { judet: "Gorj", localitate: "Valcele" },
  { judet: "Gorj", localitate: "Vanata" },
  { judet: "Gorj", localitate: "Cocorova" },
  { judet: "Gorj", localitate: "Poiana" },
  { judet: "Gorj", localitate: "Spahii" },
  { judet: "Gorj", localitate: "Sipotu" },
  { judet: "Gorj", localitate: "Garbovu" },
  { judet: "Gorj", localitate: "Jiltu" },
  { judet: "Gorj", localitate: "Murgesti" },
  { judet: "Gorj", localitate: "Stramba-Jiu" },
  { judet: "Gorj", localitate: "Valea Viei" },
  { judet: "Gorj", localitate: "Cartiu" },
  { judet: "Gorj", localitate: "Horezu" },
  { judet: "Gorj", localitate: "Rugi" },
  { judet: "Gorj", localitate: "Arpadia" },
  { judet: "Gorj", localitate: "Chiciora" },
  { judet: "Gorj", localitate: "Floresti" },
  { judet: "Gorj", localitate: "Fantanele" },
  { judet: "Gorj", localitate: "Hotaroasa" },
  { judet: "Gorj", localitate: "Carciu" },
  { judet: "Gorj", localitate: "Covrigi" },
  { judet: "Gorj", localitate: "Murgilesti" },
  { judet: "Gorj", localitate: "Valea Motrului" },
  { judet: "Gorj", localitate: "Andreesti" },
  { judet: "Gorj", localitate: "Frasin" },
  { judet: "Gorj", localitate: "Valea Desului" },
  { judet: "Harghita", localitate: "Miercurea Ciuc" },
  { judet: "Harghita", localitate: "Odorheiu Secuiesc" },
  { judet: "Harghita", localitate: "Gheorgheni" },
  { judet: "Harghita", localitate: "Toplita" },
  { judet: "Harghita", localitate: "Corund" },
  { judet: "Harghita", localitate: "Cristuru Secuiesc" },
  { judet: "Harghita", localitate: "Balan" },
  { judet: "Harghita", localitate: "Vlahita" },
  { judet: "Harghita", localitate: "Borsec" },
  { judet: "Harghita", localitate: "Baile Tusnad" },
  { judet: "Harghita", localitate: "Atid" },
  { judet: "Harghita", localitate: "Avramesti" },
  { judet: "Harghita", localitate: "Bilbor" },
  { judet: "Harghita", localitate: "Bradesti" },
  { judet: "Harghita", localitate: "Capalnita" },
  { judet: "Harghita", localitate: "Carta" },
  { judet: "Harghita", localitate: "Ciceu" },
  { judet: "Harghita", localitate: "Ciucsangeorgiu" },
  { judet: "Harghita", localitate: "Ciumani" },
  { judet: "Harghita", localitate: "Corbu" },
  { judet: "Harghita", localitate: "Cozmeni" },
  { judet: "Harghita", localitate: "Danesti" },
  { judet: "Harghita", localitate: "Darjiu" },
  { judet: "Harghita", localitate: "Dealu" },
  { judet: "Harghita", localitate: "Ditrau" },
  { judet: "Harghita", localitate: "Feliceni" },
  { judet: "Harghita", localitate: "Frumoasa" },
  { judet: "Harghita", localitate: "Galautas" },
  { judet: "Harghita", localitate: "Joseni" },
  { judet: "Harghita", localitate: "Lazarea" },
  { judet: "Harghita", localitate: "Leliceni" },
  { judet: "Harghita", localitate: "Lueta" },
  { judet: "Harghita", localitate: "Lunca de Jos" },
  { judet: "Harghita", localitate: "Lunca de Sus" },
  { judet: "Harghita", localitate: "Lupeni" },
  { judet: "Harghita", localitate: "Madaras" },
  { judet: "Harghita", localitate: "Martinis" },
  { judet: "Harghita", localitate: "Meresti" },
  { judet: "Harghita", localitate: "Mihaileni" },
  { judet: "Harghita", localitate: "Mugeni" },
  { judet: "Harghita", localitate: "Ocland" },
  { judet: "Harghita", localitate: "Pauleni-Ciuc" },
  { judet: "Harghita", localitate: "Plaiesii de Jos" },
  { judet: "Harghita", localitate: "Porumbeni" },
  { judet: "Harghita", localitate: "Praid" },
  { judet: "Harghita", localitate: "Racu" },
  { judet: "Harghita", localitate: "Remetea" },
  { judet: "Harghita", localitate: "Sacel" },
  { judet: "Harghita", localitate: "Sancraieni" },
  { judet: "Harghita", localitate: "Sandominic" },
  { judet: "Harghita", localitate: "Sanmartin" },
  { judet: "Harghita", localitate: "Sansimion" },
  { judet: "Harghita", localitate: "Santimbru" },
  { judet: "Harghita", localitate: "Sarmas" },
  { judet: "Harghita", localitate: "Satu Mare" },
  { judet: "Harghita", localitate: "Secuieni" },
  { judet: "Harghita", localitate: "Siculeni" },
  { judet: "Harghita", localitate: "Simonesti" },
  { judet: "Harghita", localitate: "Subcetate" },
  { judet: "Harghita", localitate: "Suseni" },
  { judet: "Harghita", localitate: "Tomesti" },
  { judet: "Harghita", localitate: "Tulghes" },
  { judet: "Harghita", localitate: "Tusnad" },
  { judet: "Harghita", localitate: "Ulies" },
  { judet: "Harghita", localitate: "Varsag" },
  { judet: "Harghita", localitate: "Voslabeni" },
  { judet: "Harghita", localitate: "Zetea" },
  { judet: "Harghita", localitate: "Ciba" },
  { judet: "Harghita", localitate: "Harghita-Bai" },
  { judet: "Harghita", localitate: "Jigodin-Bai" },
  { judet: "Harghita", localitate: "Carpitus" },
  { judet: "Harghita", localitate: "Filias" },
  { judet: "Harghita", localitate: "Covacipeter" },
  { judet: "Harghita", localitate: "Lacu Rosu" },
  { judet: "Harghita", localitate: "Vargatac" },
  { judet: "Harghita", localitate: "Visafolio" },
  { judet: "Harghita", localitate: "Calimanel" },
  { judet: "Harghita", localitate: "Luncani" },
  { judet: "Harghita", localitate: "Magherus" },
  { judet: "Harghita", localitate: "Moglanesti" },
  { judet: "Harghita", localitate: "Secu" },
  { judet: "Harghita", localitate: "Vale" },
  { judet: "Harghita", localitate: "Vagani" },
  { judet: "Harghita", localitate: "Zencani" },
  { judet: "Harghita", localitate: "Baile Homorod" },
  { judet: "Harghita", localitate: "Minele Lueta" },
  { judet: "Harghita", localitate: "Criseni" },
  { judet: "Harghita", localitate: "Cusmed" },
  { judet: "Harghita", localitate: "Inlaceni" },
  { judet: "Harghita", localitate: "Siclod" },
  { judet: "Harghita", localitate: "Andreeni" },
  { judet: "Harghita", localitate: "Cechesti" },
  { judet: "Harghita", localitate: "Firtanus" },
  { judet: "Harghita", localitate: "Goagiu" },
  { judet: "Harghita", localitate: "Laz-Firtanus" },
  { judet: "Harghita", localitate: "Laz-Soimus" },
  { judet: "Harghita", localitate: "Medisoru Mic" },
  { judet: "Harghita", localitate: "Rachitis" },
  { judet: "Harghita", localitate: "Tarnovita" },
  { judet: "Harghita", localitate: "Ineu" },
  { judet: "Harghita", localitate: "Armaseni" },
  { judet: "Harghita", localitate: "Armasenii Noi" },
  { judet: "Harghita", localitate: "Bancu" },
  { judet: "Harghita", localitate: "Ciobanis" },
  { judet: "Harghita", localitate: "Cotormani" },
  { judet: "Harghita", localitate: "Eghersec" },
  { judet: "Harghita", localitate: "Ghiurche" },
  { judet: "Harghita", localitate: "Potiond" },
  { judet: "Harghita", localitate: "Capu Corbului" },
  { judet: "Harghita", localitate: "Atia" },
  { judet: "Harghita", localitate: "Calonda" },
  { judet: "Harghita", localitate: "Fantana Brazilor" },
  { judet: "Harghita", localitate: "Valea lui Pavel" },
  { judet: "Harghita", localitate: "Lazaresti" },
  { judet: "Harghita", localitate: "Mujna" },
  { judet: "Harghita", localitate: "Fancel" },
  { judet: "Harghita", localitate: "Sancrai" },
  { judet: "Harghita", localitate: "Tamasu" },
  { judet: "Harghita", localitate: "Tibod" },
  { judet: "Harghita", localitate: "Ulcani" },
  { judet: "Harghita", localitate: "Valea Rotunda" },
  { judet: "Harghita", localitate: "Jolotca" },
  { judet: "Harghita", localitate: "Tengheler" },
  { judet: "Harghita", localitate: "Alexandrita" },
  { judet: "Harghita", localitate: "Arvateni" },
  { judet: "Harghita", localitate: "Cireseni" },
  { judet: "Harghita", localitate: "Forteni" },
  { judet: "Harghita", localitate: "Hoghia" },
  { judet: "Harghita", localitate: "Oteni" },
  { judet: "Harghita", localitate: "Polonita" },
  { judet: "Harghita", localitate: "Taureni" },
  { judet: "Harghita", localitate: "Teleac" },
  { judet: "Harghita", localitate: "Valeni" },
  { judet: "Harghita", localitate: "Barzava" },
  { judet: "Harghita", localitate: "Fagetel (Frumoasa)" },
  { judet: "Harghita", localitate: "Nicolesti (Frumoasa)" },
  { judet: "Harghita", localitate: "Dealu Armanului" },
  { judet: "Harghita", localitate: "Galautas-Parau" },
  { judet: "Harghita", localitate: "Nuteni" },
  { judet: "Harghita", localitate: "Plopis" },
  { judet: "Harghita", localitate: "Preluca" },
  { judet: "Harghita", localitate: "Toleseni" },
  { judet: "Harghita", localitate: "Zapodea" },
  { judet: "Harghita", localitate: "Borzont" },
  { judet: "Harghita", localitate: "Bucin (Joseni)" },
  { judet: "Harghita", localitate: "Ghidut" },
  { judet: "Harghita", localitate: "Baile Chirui" },
  { judet: "Harghita", localitate: "Baratcos" },
  { judet: "Harghita", localitate: "Poiana Fagului" },
  { judet: "Harghita", localitate: "Puntea Lupului" },
  { judet: "Harghita", localitate: "Valea Boros" },
  { judet: "Harghita", localitate: "Valea Capelei" },
  { judet: "Harghita", localitate: "Valea Intunecoasa" },
  { judet: "Harghita", localitate: "Valea Rece" },
  { judet: "Harghita", localitate: "Valea lui Antaloc" },
  { judet: "Harghita", localitate: "Comiat" },
  { judet: "Harghita", localitate: "Izvorul Trotusului" },
  { judet: "Harghita", localitate: "Paltinis-Ciuc" },
  { judet: "Harghita", localitate: "Valea Garbea" },
  { judet: "Harghita", localitate: "Valea Ugra" },
  { judet: "Harghita", localitate: "Bisericani" },
  { judet: "Harghita", localitate: "Bulgareni" },
  { judet: "Harghita", localitate: "Firtusu" },
  { judet: "Harghita", localitate: "Morareni" },
  { judet: "Harghita", localitate: "Paltinis" },
  { judet: "Harghita", localitate: "Pauleni" },
  { judet: "Harghita", localitate: "Satu Mic" },
  { judet: "Harghita", localitate: "Sancel" },
  { judet: "Harghita", localitate: "Aldea" },
  { judet: "Harghita", localitate: "Badeni" },
  { judet: "Harghita", localitate: "Calugareni" },
  { judet: "Harghita", localitate: "Chinusu" },
  { judet: "Harghita", localitate: "Comanesti" },
  { judet: "Harghita", localitate: "Ghipes" },
  { judet: "Harghita", localitate: "Locodeni" },
  { judet: "Harghita", localitate: "Oraseni" },
  { judet: "Harghita", localitate: "Petreni" },
  { judet: "Harghita", localitate: "Rares" },
  { judet: "Harghita", localitate: "Sanpaul" },
  { judet: "Harghita", localitate: "Livezi" },
  { judet: "Harghita", localitate: "Nadejdea" },
  { judet: "Harghita", localitate: "Vacaresti" },
  { judet: "Harghita", localitate: "Alunis" },
  { judet: "Harghita", localitate: "Beta" },
  { judet: "Harghita", localitate: "Betesti" },
  { judet: "Harghita", localitate: "Dejutiu" },
  { judet: "Harghita", localitate: "Dobeni" },
  { judet: "Harghita", localitate: "Lutita" },
  { judet: "Harghita", localitate: "Matiseni" },
  { judet: "Harghita", localitate: "Porumbenii Mari" },
  { judet: "Harghita", localitate: "Porumbenii Mici" },
  { judet: "Harghita", localitate: "Taietura" },
  { judet: "Harghita", localitate: "Craciunel" },
  { judet: "Harghita", localitate: "Satu Nou (Ocland)" },
  { judet: "Harghita", localitate: "Delnita" },
  { judet: "Harghita", localitate: "Soimeni" },
  { judet: "Harghita", localitate: "Casinu Nou" },
  { judet: "Harghita", localitate: "Iacobeni" },
  { judet: "Harghita", localitate: "Imper" },
  { judet: "Harghita", localitate: "Plaiesii de Sus" },
  { judet: "Harghita", localitate: "Becas" },
  { judet: "Harghita", localitate: "Bucin (Praid)" },
  { judet: "Harghita", localitate: "Ocna de Jos" },
  { judet: "Harghita", localitate: "Ocna de Sus" },
  { judet: "Harghita", localitate: "Sasveres" },
  { judet: "Harghita", localitate: "Fagetel (Remetea)" },
  { judet: "Harghita", localitate: "Martonca" },
  { judet: "Harghita", localitate: "Sineu" },
  { judet: "Harghita", localitate: "Soimusu Mare" },
  { judet: "Harghita", localitate: "Soimusu Mic" },
  { judet: "Harghita", localitate: "Uilac" },
  { judet: "Harghita", localitate: "Vidacut" },
  { judet: "Harghita", localitate: "Fundoaia" },
  { judet: "Harghita", localitate: "Hodosa" },
  { judet: "Harghita", localitate: "Platonesti" },
  { judet: "Harghita", localitate: "Runc" },
  { judet: "Harghita", localitate: "Fitod" },
  { judet: "Harghita", localitate: "Hosasau" },
  { judet: "Harghita", localitate: "Misentea" },
  { judet: "Harghita", localitate: "Santimbru-Bai" },
  { judet: "Harghita", localitate: "Ciucani" },
  { judet: "Harghita", localitate: "Valea Uzului" },
  { judet: "Harghita", localitate: "Cetatuia" },
  { judet: "Harghita", localitate: "Bodogaia" },
  { judet: "Harghita", localitate: "Eliseni" },
  { judet: "Harghita", localitate: "Ciaracio" },
  { judet: "Harghita", localitate: "Garciu (Racu)" },
  { judet: "Harghita", localitate: "Calnaci" },
  { judet: "Harghita", localitate: "Duda" },
  { judet: "Harghita", localitate: "Filpea" },
  { judet: "Harghita", localitate: "Chileni" },
  { judet: "Harghita", localitate: "Liban" },
  { judet: "Harghita", localitate: "Senetea" },
  { judet: "Harghita", localitate: "Valea Stramba" },
  { judet: "Harghita", localitate: "Bentid" },
  { judet: "Harghita", localitate: "Cadaciu Mare" },
  { judet: "Harghita", localitate: "Cadaciu Mic" },
  { judet: "Harghita", localitate: "Cehetel" },
  { judet: "Harghita", localitate: "Chedia Mare" },
  { judet: "Harghita", localitate: "Chedia Mica" },
  { judet: "Harghita", localitate: "Cobatesti" },
  { judet: "Harghita", localitate: "Medisoru Mare" },
  { judet: "Harghita", localitate: "Mihaileni (Simonesti)" },
  { judet: "Harghita", localitate: "Nicoleni" },
  { judet: "Harghita", localitate: "Ruganesti" },
  { judet: "Harghita", localitate: "Tarcesti" },
  { judet: "Harghita", localitate: "Turdeni" },
  { judet: "Harghita", localitate: "Hagota" },
  { judet: "Harghita", localitate: "Pintic" },
  { judet: "Harghita", localitate: "Recea" },
  { judet: "Harghita", localitate: "Tusnadu Nou" },
  { judet: "Harghita", localitate: "Vrabia" },
  { judet: "Harghita", localitate: "Daia" },
  { judet: "Harghita", localitate: "Iasu" },
  { judet: "Harghita", localitate: "Ighiu" },
  { judet: "Harghita", localitate: "Nicolesti (Ulies)" },
  { judet: "Harghita", localitate: "Obranesti" },
  { judet: "Harghita", localitate: "Petecu" },
  { judet: "Harghita", localitate: "Vasileni" },
  { judet: "Harghita", localitate: "Izvoru Muresului" },
  { judet: "Harghita", localitate: "Desag" },
  { judet: "Harghita", localitate: "Izvoare" },
  { judet: "Harghita", localitate: "Poiana Tarnavei" },
  { judet: "Harghita", localitate: "Sub Cetate" },
  { judet: "Harghita", localitate: "Sicasau" },
  { judet: "Hunedoara", localitate: "Deva" },
  { judet: "Hunedoara", localitate: "Hunedoara" },
  { judet: "Hunedoara", localitate: "Petrosani" },
  { judet: "Hunedoara", localitate: "Hateg" },
  { judet: "Hunedoara", localitate: "Calan" },
  { judet: "Hunedoara", localitate: "Orastie" },
  { judet: "Hunedoara", localitate: "Brad" },
  { judet: "Hunedoara", localitate: "Petrila" },
  { judet: "Hunedoara", localitate: "Simeria" },
  { judet: "Hunedoara", localitate: "Vulcan" },
  { judet: "Hunedoara", localitate: "Lupeni" },
  { judet: "Hunedoara", localitate: "Aninoasa" },
  { judet: "Hunedoara", localitate: "Geoagiu" },
  { judet: "Hunedoara", localitate: "Uricani" },
  { judet: "Hunedoara", localitate: "Baia de Cris" },
  { judet: "Hunedoara", localitate: "Balsa" },
  { judet: "Hunedoara", localitate: "Banita" },
  { judet: "Hunedoara", localitate: "Baru" },
  { judet: "Hunedoara", localitate: "Bacia" },
  { judet: "Hunedoara", localitate: "Baita" },
  { judet: "Hunedoara", localitate: "Batrana" },
  { judet: "Hunedoara", localitate: "Beriu" },
  { judet: "Hunedoara", localitate: "Blajeni" },
  { judet: "Hunedoara", localitate: "Bosorod" },
  { judet: "Hunedoara", localitate: "Branisca" },
  { judet: "Hunedoara", localitate: "Buces" },
  { judet: "Hunedoara", localitate: "Bretea Romana" },
  { judet: "Hunedoara", localitate: "Bucuresci" },
  { judet: "Hunedoara", localitate: "Bulzestii de Sus" },
  { judet: "Hunedoara", localitate: "Bunila" },
  { judet: "Hunedoara", localitate: "Burjuc" },
  { judet: "Hunedoara", localitate: "Cerbal" },
  { judet: "Hunedoara", localitate: "Certeju de Sus" },
  { judet: "Hunedoara", localitate: "Carjiti" },
  { judet: "Hunedoara", localitate: "Criscior" },
  { judet: "Hunedoara", localitate: "Densus" },
  { judet: "Hunedoara", localitate: "Dobra" },
  { judet: "Hunedoara", localitate: "General Berthelot" },
  { judet: "Hunedoara", localitate: "Ghelari" },
  { judet: "Hunedoara", localitate: "Gurasada" },
  { judet: "Hunedoara", localitate: "Harau" },
  { judet: "Hunedoara", localitate: "Ilia" },
  { judet: "Hunedoara", localitate: "Lapugiu de Jos" },
  { judet: "Hunedoara", localitate: "Lelese" },
  { judet: "Hunedoara", localitate: "Lunca Cernii de Jos" },
  { judet: "Hunedoara", localitate: "Luncoiu de Jos" },
  { judet: "Hunedoara", localitate: "Martinesti" },
  { judet: "Hunedoara", localitate: "Orastioara de Sus" },
  { judet: "Hunedoara", localitate: "Pestisu Mic" },
  { judet: "Hunedoara", localitate: "Pui" },
  { judet: "Hunedoara", localitate: "Rapoltu Mare" },
  { judet: "Hunedoara", localitate: "Rachitova" },
  { judet: "Hunedoara", localitate: "Ribita" },
  { judet: "Hunedoara", localitate: "Rau de Mori" },
  { judet: "Hunedoara", localitate: "Romos" },
  { judet: "Hunedoara", localitate: "Sarmizegetusa" },
  { judet: "Hunedoara", localitate: "Salasu de Sus" },
  { judet: "Hunedoara", localitate: "Santamaria-Orlea" },
  { judet: "Hunedoara", localitate: "Soimus" },
  { judet: "Hunedoara", localitate: "Teliucu Inferior" },
  { judet: "Hunedoara", localitate: "Tomesti" },
  { judet: "Hunedoara", localitate: "Toplita" },
  { judet: "Hunedoara", localitate: "Totesti" },
  { judet: "Hunedoara", localitate: "Turdas" },
  { judet: "Hunedoara", localitate: "Vata de Jos" },
  { judet: "Hunedoara", localitate: "Valisoara" },
  { judet: "Hunedoara", localitate: "Vetel" },
  { judet: "Hunedoara", localitate: "Vorta" },
  { judet: "Hunedoara", localitate: "Zam" },
  { judet: "Hunedoara", localitate: "Archia" },
  { judet: "Hunedoara", localitate: "Barcea Mica" },
  { judet: "Hunedoara", localitate: "Cristur" },
  { judet: "Hunedoara", localitate: "Santuhalm" },
  { judet: "Hunedoara", localitate: "Bos" },
  { judet: "Hunedoara", localitate: "Gros" },
  { judet: "Hunedoara", localitate: "Hasdat" },
  { judet: "Hunedoara", localitate: "Pestisu Mare" },
  { judet: "Hunedoara", localitate: "Racastia" },
  { judet: "Hunedoara", localitate: "Dalja Mare" },
  { judet: "Hunedoara", localitate: "Dalja Mica" },
  { judet: "Hunedoara", localitate: "Pestera (Petrosani)" },
  { judet: "Hunedoara", localitate: "Slatinioara" },
  { judet: "Hunedoara", localitate: "Iscroni" },
  { judet: "Hunedoara", localitate: "Mesteacan (Brad)" },
  { judet: "Hunedoara", localitate: "Potingani" },
  { judet: "Hunedoara", localitate: "Ruda-Brad" },
  { judet: "Hunedoara", localitate: "Taratel" },
  { judet: "Hunedoara", localitate: "Valea Bradului" },
  { judet: "Hunedoara", localitate: "Batiz" },
  { judet: "Hunedoara", localitate: "Calanu Mic" },
  { judet: "Hunedoara", localitate: "Grid" },
  { judet: "Hunedoara", localitate: "Nadastia de Jos" },
  { judet: "Hunedoara", localitate: "Nadastia de Sus" },
  { judet: "Hunedoara", localitate: "Ohaba Streiului" },
  { judet: "Hunedoara", localitate: "Sancrai" },
  { judet: "Hunedoara", localitate: "Santamaria de Piatra" },
  { judet: "Hunedoara", localitate: "Strei" },
  { judet: "Hunedoara", localitate: "Strei-Sacel" },
  { judet: "Hunedoara", localitate: "Streisangeorgiu" },
  { judet: "Hunedoara", localitate: "Valea Sangeorgiului" },
  { judet: "Hunedoara", localitate: "Aurel Vlaicu" },
  { judet: "Hunedoara", localitate: "Bacaia" },
  { judet: "Hunedoara", localitate: "Bozes" },
  { judet: "Hunedoara", localitate: "Cigmau" },
  { judet: "Hunedoara", localitate: "Gelmar" },
  { judet: "Hunedoara", localitate: "Homorod" },
  { judet: "Hunedoara", localitate: "Mermezeu-Valeni" },
  { judet: "Hunedoara", localitate: "Renghet" },
  { judet: "Hunedoara", localitate: "Valeni (Geoagiu)" },
  { judet: "Hunedoara", localitate: "Geoagiu-Bai" },
  { judet: "Hunedoara", localitate: "Nalatvad" },
  { judet: "Hunedoara", localitate: "Silvasu de Jos" },
  { judet: "Hunedoara", localitate: "Silvasu de Sus" },
  { judet: "Hunedoara", localitate: "Cimpa" },
  { judet: "Hunedoara", localitate: "Jiet" },
  { judet: "Hunedoara", localitate: "Rascoala" },
  { judet: "Hunedoara", localitate: "Tirici" },
  { judet: "Hunedoara", localitate: "Barcea Mare" },
  { judet: "Hunedoara", localitate: "Carpinis" },
  { judet: "Hunedoara", localitate: "Santandrei" },
  { judet: "Hunedoara", localitate: "Simeria Veche" },
  { judet: "Hunedoara", localitate: "Saulesti" },
  { judet: "Hunedoara", localitate: "Uroi" },
  { judet: "Hunedoara", localitate: "Campu lui Neag" },
  { judet: "Hunedoara", localitate: "Valea de Brazi" },
  { judet: "Hunedoara", localitate: "Dealu Babii" },
  { judet: "Hunedoara", localitate: "Jiu-Paroseni" },
  { judet: "Hunedoara", localitate: "Baldovin" },
  { judet: "Hunedoara", localitate: "Caraci" },
  { judet: "Hunedoara", localitate: "Carastau" },
  { judet: "Hunedoara", localitate: "Lunca (Baia de Cris)" },
  { judet: "Hunedoara", localitate: "Risca" },
  { judet: "Hunedoara", localitate: "Risculita" },
  { judet: "Hunedoara", localitate: "Tebea" },
  { judet: "Hunedoara", localitate: "Valeni (Baia de Cris)" },
  { judet: "Hunedoara", localitate: "Almasu Mic de Munte" },
  { judet: "Hunedoara", localitate: "Ardeu" },
  { judet: "Hunedoara", localitate: "Bunesti" },
  { judet: "Hunedoara", localitate: "Galbina" },
  { judet: "Hunedoara", localitate: "Mada" },
  { judet: "Hunedoara", localitate: "Oprisesti" },
  { judet: "Hunedoara", localitate: "Poiana" },
  { judet: "Hunedoara", localitate: "Poienita" },
  { judet: "Hunedoara", localitate: "Rosia" },
  { judet: "Hunedoara", localitate: "Stauini" },
  { judet: "Hunedoara", localitate: "Techereu" },
  { judet: "Hunedoara", localitate: "Valisoara (Balsa)" },
  { judet: "Hunedoara", localitate: "Voia" },
  { judet: "Hunedoara", localitate: "Livadia" },
  { judet: "Hunedoara", localitate: "Petros" },
  { judet: "Hunedoara", localitate: "Valea Lupului" },
  { judet: "Hunedoara", localitate: "Petreni" },
  { judet: "Hunedoara", localitate: "Tampa" },
  { judet: "Hunedoara", localitate: "Totia" },
  { judet: "Hunedoara", localitate: "Barbura" },
  { judet: "Hunedoara", localitate: "Cainelu de Sus" },
  { judet: "Hunedoara", localitate: "Craciunesti" },
  { judet: "Hunedoara", localitate: "Fizes" },
  { judet: "Hunedoara", localitate: "Hartagani" },
  { judet: "Hunedoara", localitate: "Lunca (Baita)" },
  { judet: "Hunedoara", localitate: "Ormindea" },
  { judet: "Hunedoara", localitate: "Pestera (Baita)" },
  { judet: "Hunedoara", localitate: "Saliste" },
  { judet: "Hunedoara", localitate: "Trestia" },
  { judet: "Hunedoara", localitate: "Crivadia" },
  { judet: "Hunedoara", localitate: "Merisor (Banita)" },
  { judet: "Hunedoara", localitate: "Fata Rosie" },
  { judet: "Hunedoara", localitate: "Piatra" },
  { judet: "Hunedoara", localitate: "Rachitaua" },
  { judet: "Hunedoara", localitate: "Castau" },
  { judet: "Hunedoara", localitate: "Cucuis" },
  { judet: "Hunedoara", localitate: "Magureni" },
  { judet: "Hunedoara", localitate: "Orastioara de Jos" },
  { judet: "Hunedoara", localitate: "Poieni (Beriu)" },
  { judet: "Hunedoara", localitate: "Sereca" },
  { judet: "Hunedoara", localitate: "Sibisel (Beriu)" },
  { judet: "Hunedoara", localitate: "Blajeni-Vulcan" },
  { judet: "Hunedoara", localitate: "Cris" },
  { judet: "Hunedoara", localitate: "Dragu-Brad" },
  { judet: "Hunedoara", localitate: "Grosuri" },
  { judet: "Hunedoara", localitate: "Plai" },
  { judet: "Hunedoara", localitate: "Ret" },
  { judet: "Hunedoara", localitate: "Salatruc" },
  { judet: "Hunedoara", localitate: "Alun (Bosorod)" },
  { judet: "Hunedoara", localitate: "Bobaia" },
  { judet: "Hunedoara", localitate: "Chitid" },
  { judet: "Hunedoara", localitate: "Cioclovina" },
  { judet: "Hunedoara", localitate: "Luncani" },
  { judet: "Hunedoara", localitate: "Prihodiste (Bosorod)" },
  { judet: "Hunedoara", localitate: "Tarsa" },
  { judet: "Hunedoara", localitate: "Ursici" },
  { judet: "Hunedoara", localitate: "Barastii Iliei" },
  { judet: "Hunedoara", localitate: "Boz" },
  { judet: "Hunedoara", localitate: "Cabesti" },
  { judet: "Hunedoara", localitate: "Furcsoara" },
  { judet: "Hunedoara", localitate: "Gialacuta" },
  { judet: "Hunedoara", localitate: "Rovina (Branisca)" },
  { judet: "Hunedoara", localitate: "Tarnava" },
  { judet: "Hunedoara", localitate: "Tarnavita" },
  { judet: "Hunedoara", localitate: "Batalar" },
  { judet: "Hunedoara", localitate: "Bercu" },
  { judet: "Hunedoara", localitate: "Bretea Streiului" },
  { judet: "Hunedoara", localitate: "Covragiu" },
  { judet: "Hunedoara", localitate: "Gantaga" },
  { judet: "Hunedoara", localitate: "Maceu" },
  { judet: "Hunedoara", localitate: "Ocolisu Mare" },
  { judet: "Hunedoara", localitate: "Plopi" },
  { judet: "Hunedoara", localitate: "Rusi" },
  { judet: "Hunedoara", localitate: "Valcele" },
  { judet: "Hunedoara", localitate: "Valcelele Bune" },
  { judet: "Hunedoara", localitate: "Valceluta" },
  { judet: "Hunedoara", localitate: "Buces-Vulcan" },
  { judet: "Hunedoara", localitate: "Dupapiatra" },
  { judet: "Hunedoara", localitate: "Grohotele" },
  { judet: "Hunedoara", localitate: "Mihaileni" },
  { judet: "Hunedoara", localitate: "Stanija" },
  { judet: "Hunedoara", localitate: "Tarnita" },
  { judet: "Hunedoara", localitate: "Curechiu" },
  { judet: "Hunedoara", localitate: "Merisor (Bucuresci)" },
  { judet: "Hunedoara", localitate: "Rovina (Bucuresci)" },
  { judet: "Hunedoara", localitate: "Sesuri" },
  { judet: "Hunedoara", localitate: "Bulzestii de Jos" },
  { judet: "Hunedoara", localitate: "Giurgesti" },
  { judet: "Hunedoara", localitate: "Grohot" },
  { judet: "Hunedoara", localitate: "Paulesti" },
  { judet: "Hunedoara", localitate: "Rusesti" },
  { judet: "Hunedoara", localitate: "Stanculesti" },
  { judet: "Hunedoara", localitate: "Ticera" },
  { judet: "Hunedoara", localitate: "Tomnatec" },
  { judet: "Hunedoara", localitate: "Alun (Bunila)" },
  { judet: "Hunedoara", localitate: "Cernisoara Florese" },
  { judet: "Hunedoara", localitate: "Poienita Voinii" },
  { judet: "Hunedoara", localitate: "Vadu Dobrii" },
  { judet: "Hunedoara", localitate: "Bradatel" },
  { judet: "Hunedoara", localitate: "Glodghilesti" },
  { judet: "Hunedoara", localitate: "Petresti" },
  { judet: "Hunedoara", localitate: "Tatarasti" },
  { judet: "Hunedoara", localitate: "Tisa" },
  { judet: "Hunedoara", localitate: "Almasu Sec" },
  { judet: "Hunedoara", localitate: "Cherghes" },
  { judet: "Hunedoara", localitate: "Cozia" },
  { judet: "Hunedoara", localitate: "Popesti" },
  { judet: "Hunedoara", localitate: "Aranies" },
  { judet: "Hunedoara", localitate: "Feregi" },
  { judet: "Hunedoara", localitate: "Merisoru de Munte" },
  { judet: "Hunedoara", localitate: "Poiana Rachitelii" },
  { judet: "Hunedoara", localitate: "Poienita Tomii" },
  { judet: "Hunedoara", localitate: "Socet" },
  { judet: "Hunedoara", localitate: "Ulm" },
  { judet: "Hunedoara", localitate: "Bocsa Mare" },
  { judet: "Hunedoara", localitate: "Bocsa Mica" },
  { judet: "Hunedoara", localitate: "Hondol" },
  { judet: "Hunedoara", localitate: "Magura-Toplita" },
  { judet: "Hunedoara", localitate: "Nojag" },
  { judet: "Hunedoara", localitate: "Sacaramb" },
  { judet: "Hunedoara", localitate: "Toplita Muresului" },
  { judet: "Hunedoara", localitate: "Varmaga" },
  { judet: "Hunedoara", localitate: "Barza" },
  { judet: "Hunedoara", localitate: "Valea Arsului" },
  { judet: "Hunedoara", localitate: "Zdrapti" },
  { judet: "Hunedoara", localitate: "Criva" },
  { judet: "Hunedoara", localitate: "Hatagel" },
  { judet: "Hunedoara", localitate: "Pesteana" },
  { judet: "Hunedoara", localitate: "Pestenita" },
  { judet: "Hunedoara", localitate: "Poieni (Densus)" },
  { judet: "Hunedoara", localitate: "Stei" },
  { judet: "Hunedoara", localitate: "Abucea" },
  { judet: "Hunedoara", localitate: "Bujoru" },
  { judet: "Hunedoara", localitate: "Fagetel" },
  { judet: "Hunedoara", localitate: "Lapusnic" },
  { judet: "Hunedoara", localitate: "Mihaiesti" },
  { judet: "Hunedoara", localitate: "Panc" },
  { judet: "Hunedoara", localitate: "Panc-Saliste" },
  { judet: "Hunedoara", localitate: "Radulesti (Stancesti)" },
  { judet: "Hunedoara", localitate: "Roscani" },
  { judet: "Hunedoara", localitate: "Stancesti" },
  { judet: "Hunedoara", localitate: "Stancesti-Ohaba" },
  { judet: "Hunedoara", localitate: "Stretea" },
  { judet: "Hunedoara", localitate: "Craguis" },
  { judet: "Hunedoara", localitate: "Farcadin" },
  { judet: "Hunedoara", localitate: "Livezi" },
  { judet: "Hunedoara", localitate: "Tustea" },
  { judet: "Hunedoara", localitate: "Govajdia" },
  { judet: "Hunedoara", localitate: "Plop" },
  { judet: "Hunedoara", localitate: "Ruda" },
  { judet: "Hunedoara", localitate: "Boiu de Jos" },
  { judet: "Hunedoara", localitate: "Boiu de Sus" },
  { judet: "Hunedoara", localitate: "Carmazanesti" },
  { judet: "Hunedoara", localitate: "Campuri de Sus" },
  { judet: "Hunedoara", localitate: "Campuri-Surduc" },
  { judet: "Hunedoara", localitate: "Danulesti" },
  { judet: "Hunedoara", localitate: "Gothatea" },
  { judet: "Hunedoara", localitate: "Runcsor" },
  { judet: "Hunedoara", localitate: "Ulies" },
  { judet: "Hunedoara", localitate: "Vica" },
  { judet: "Hunedoara", localitate: "Banpotoc" },
  { judet: "Hunedoara", localitate: "Barsau" },
  { judet: "Hunedoara", localitate: "Chimindia" },
  { judet: "Hunedoara", localitate: "Bacea" },
  { judet: "Hunedoara", localitate: "Braznic" },
  { judet: "Hunedoara", localitate: "Bretea Muresana" },
  { judet: "Hunedoara", localitate: "Cuies" },
  { judet: "Hunedoara", localitate: "Dumbravita" },
  { judet: "Hunedoara", localitate: "Sacamas" },
  { judet: "Hunedoara", localitate: "Sarbi" },
  { judet: "Hunedoara", localitate: "Valea Lunga" },
  { judet: "Hunedoara", localitate: "Bastea" },
  { judet: "Hunedoara", localitate: "Cosesti" },
  { judet: "Hunedoara", localitate: "Fintoag" },
  { judet: "Hunedoara", localitate: "Grind" },
  { judet: "Hunedoara", localitate: "Holdea" },
  { judet: "Hunedoara", localitate: "Lapugiu de Sus" },
  { judet: "Hunedoara", localitate: "Lasau" },
  { judet: "Hunedoara", localitate: "Ohaba" },
  { judet: "Hunedoara", localitate: "Teiu" },
  { judet: "Hunedoara", localitate: "Cerisor" },
  { judet: "Hunedoara", localitate: "Runcu Mare" },
  { judet: "Hunedoara", localitate: "Sohodol" },
  { judet: "Hunedoara", localitate: "Ciumita" },
  { judet: "Hunedoara", localitate: "Fantana" },
  { judet: "Hunedoara", localitate: "Gura Bordului" },
  { judet: "Hunedoara", localitate: "Lunca Cernii de Sus" },
  { judet: "Hunedoara", localitate: "Meria" },
  { judet: "Hunedoara", localitate: "Negoiu" },
  { judet: "Hunedoara", localitate: "Valea Babii" },
  { judet: "Hunedoara", localitate: "Dudesti" },
  { judet: "Hunedoara", localitate: "Luncoiu de Sus" },
  { judet: "Hunedoara", localitate: "Podele" },
  { judet: "Hunedoara", localitate: "Stejarel" },
  { judet: "Hunedoara", localitate: "Dancu Mare" },
  { judet: "Hunedoara", localitate: "Dancu Mic" },
  { judet: "Hunedoara", localitate: "Jeledinti" },
  { judet: "Hunedoara", localitate: "Magura" },
  { judet: "Hunedoara", localitate: "Tamasasa" },
  { judet: "Hunedoara", localitate: "Turmas" },
  { judet: "Hunedoara", localitate: "Bucium" },
  { judet: "Hunedoara", localitate: "Costesti" },
  { judet: "Hunedoara", localitate: "Costesti-Deal" },
  { judet: "Hunedoara", localitate: "Gradistea de Munte" },
  { judet: "Hunedoara", localitate: "Ludestii de Jos" },
  { judet: "Hunedoara", localitate: "Ludestii de Sus" },
  { judet: "Hunedoara", localitate: "Ocolisu Mic" },
  { judet: "Hunedoara", localitate: "Almasu Mic" },
  { judet: "Hunedoara", localitate: "Ciulpaz" },
  { judet: "Hunedoara", localitate: "Cutin" },
  { judet: "Hunedoara", localitate: "Dumbrava" },
  { judet: "Hunedoara", localitate: "Josani" },
  { judet: "Hunedoara", localitate: "Manerau" },
  { judet: "Hunedoara", localitate: "Nandru" },
  { judet: "Hunedoara", localitate: "Valea Nandrului" },
  { judet: "Hunedoara", localitate: "Baiesti" },
  { judet: "Hunedoara", localitate: "Federi" },
  { judet: "Hunedoara", localitate: "Fizesti" },
  { judet: "Hunedoara", localitate: "Galati" },
  { judet: "Hunedoara", localitate: "Hobita" },
  { judet: "Hunedoara", localitate: "Ohaba-Ponor" },
  { judet: "Hunedoara", localitate: "Ponor" },
  { judet: "Hunedoara", localitate: "Rau Barbat" },
  { judet: "Hunedoara", localitate: "Rusor" },
  { judet: "Hunedoara", localitate: "Serel" },
  { judet: "Hunedoara", localitate: "Uric" },
  { judet: "Hunedoara", localitate: "Bobalna" },
  { judet: "Hunedoara", localitate: "Boiu" },
  { judet: "Hunedoara", localitate: "Folt" },
  { judet: "Hunedoara", localitate: "Rapoltel" },
  { judet: "Hunedoara", localitate: "Boita" },
  { judet: "Hunedoara", localitate: "Ciula Mare" },
  { judet: "Hunedoara", localitate: "Ciula Mica" },
  { judet: "Hunedoara", localitate: "Gotesti" },
  { judet: "Hunedoara", localitate: "Mesteacan (Rachitova)" },
  { judet: "Hunedoara", localitate: "Valioara" },
  { judet: "Hunedoara", localitate: "Brazi" },
  { judet: "Hunedoara", localitate: "Clopotiva" },
  { judet: "Hunedoara", localitate: "Ohaba-Sibisel" },
  { judet: "Hunedoara", localitate: "Ostrov" },
  { judet: "Hunedoara", localitate: "Ostrovel" },
  { judet: "Hunedoara", localitate: "Ostrovu Mic" },
  { judet: "Hunedoara", localitate: "Sibisel (Rau de Mori)" },
  { judet: "Hunedoara", localitate: "Suseni" },
  { judet: "Hunedoara", localitate: "Unciuc" },
  { judet: "Hunedoara", localitate: "Valea Daljii" },
  { judet: "Hunedoara", localitate: "Crisan" },
  { judet: "Hunedoara", localitate: "Dumbrava de Jos" },
  { judet: "Hunedoara", localitate: "Dumbrava de Sus" },
  { judet: "Hunedoara", localitate: "Ribicioara" },
  { judet: "Hunedoara", localitate: "Uibaresti" },
  { judet: "Hunedoara", localitate: "Ciungu Mare" },
  { judet: "Hunedoara", localitate: "Pischinti" },
  { judet: "Hunedoara", localitate: "Romosel" },
  { judet: "Hunedoara", localitate: "Vaidei" },
  { judet: "Hunedoara", localitate: "Breazova" },
  { judet: "Hunedoara", localitate: "Hobita-Gradiste" },
  { judet: "Hunedoara", localitate: "Paucinesti" },
  { judet: "Hunedoara", localitate: "Zeicani" },
  { judet: "Hunedoara", localitate: "Coroiesti" },
  { judet: "Hunedoara", localitate: "Malaiesti" },
  { judet: "Hunedoara", localitate: "Nucsoara" },
  { judet: "Hunedoara", localitate: "Ohaba de sub Piatra" },
  { judet: "Hunedoara", localitate: "Paros" },
  { judet: "Hunedoara", localitate: "Pestera (Salasu de Sus)" },
  { judet: "Hunedoara", localitate: "Rau Alb" },
  { judet: "Hunedoara", localitate: "Rau Mic" },
  { judet: "Hunedoara", localitate: "Salasu de Jos" },
  { judet: "Hunedoara", localitate: "Zavoi" },
  { judet: "Hunedoara", localitate: "Balomir" },
  { judet: "Hunedoara", localitate: "Barastii Hategului" },
  { judet: "Hunedoara", localitate: "Bucium-Orlea" },
  { judet: "Hunedoara", localitate: "Ciopeia" },
  { judet: "Hunedoara", localitate: "Sacel" },
  { judet: "Hunedoara", localitate: "Sanpetru" },
  { judet: "Hunedoara", localitate: "Subcetate" },
  { judet: "Hunedoara", localitate: "Vadu" },
  { judet: "Hunedoara", localitate: "Balata" },
  { judet: "Hunedoara", localitate: "Bejan" },
  { judet: "Hunedoara", localitate: "Bejan-Tarnavita" },
  { judet: "Hunedoara", localitate: "Boholt" },
  { judet: "Hunedoara", localitate: "Cainelu de Jos" },
  { judet: "Hunedoara", localitate: "Chiscadaga" },
  { judet: "Hunedoara", localitate: "Fornadia" },
  { judet: "Hunedoara", localitate: "Paulis" },
  { judet: "Hunedoara", localitate: "Sulighete" },
  { judet: "Hunedoara", localitate: "Cincis-Cerna" },
  { judet: "Hunedoara", localitate: "Izvoarele" },
  { judet: "Hunedoara", localitate: "Teliucu Superior" },
  { judet: "Hunedoara", localitate: "Dobrot" },
  { judet: "Hunedoara", localitate: "Leaut" },
  { judet: "Hunedoara", localitate: "Livada" },
  { judet: "Hunedoara", localitate: "Obarsa" },
  { judet: "Hunedoara", localitate: "Steia" },
  { judet: "Hunedoara", localitate: "Tiulesti" },
  { judet: "Hunedoara", localitate: "Valea Mare de Cris" },
  { judet: "Hunedoara", localitate: "Curpenii Silvasului" },
  { judet: "Hunedoara", localitate: "Dabaca" },
  { judet: "Hunedoara", localitate: "Dealu Mic" },
  { judet: "Hunedoara", localitate: "Goles" },
  { judet: "Hunedoara", localitate: "Hasdau" },
  { judet: "Hunedoara", localitate: "Mosoru" },
  { judet: "Hunedoara", localitate: "Valari" },
  { judet: "Hunedoara", localitate: "Carnesti" },
  { judet: "Hunedoara", localitate: "Copaci" },
  { judet: "Hunedoara", localitate: "Paclisa" },
  { judet: "Hunedoara", localitate: "Reea" },
  { judet: "Hunedoara", localitate: "Pricaz" },
  { judet: "Hunedoara", localitate: "Rapas" },
  { judet: "Hunedoara", localitate: "Spini" },
  { judet: "Hunedoara", localitate: "Basarabasa" },
  { judet: "Hunedoara", localitate: "Birtin" },
  { judet: "Hunedoara", localitate: "Brotuna" },
  { judet: "Hunedoara", localitate: "Cazanesti" },
  { judet: "Hunedoara", localitate: "Ciungani" },
  { judet: "Hunedoara", localitate: "Ocisor" },
  { judet: "Hunedoara", localitate: "Ociu" },
  { judet: "Hunedoara", localitate: "Pravaleni" },
  { judet: "Hunedoara", localitate: "Prihodiste (Vata de Jos)" },
  { judet: "Hunedoara", localitate: "Tatarastii de Cris" },
  { judet: "Hunedoara", localitate: "Tarnava de Cris" },
  { judet: "Hunedoara", localitate: "Vata de Sus" },
  { judet: "Hunedoara", localitate: "Dealu Mare" },
  { judet: "Hunedoara", localitate: "Salistioara" },
  { judet: "Hunedoara", localitate: "Stoieneasa" },
  { judet: "Hunedoara", localitate: "Boia Barzii" },
  { judet: "Hunedoara", localitate: "Bretelin" },
  { judet: "Hunedoara", localitate: "Caoi" },
  { judet: "Hunedoara", localitate: "Herepeia" },
  { judet: "Hunedoara", localitate: "Lesnic" },
  { judet: "Hunedoara", localitate: "Mintia" },
  { judet: "Hunedoara", localitate: "Muncelu Mare" },
  { judet: "Hunedoara", localitate: "Muncelu Mic" },
  { judet: "Hunedoara", localitate: "Runcu Mic" },
  { judet: "Hunedoara", localitate: "Certeju de Jos" },
  { judet: "Hunedoara", localitate: "Coaja" },
  { judet: "Hunedoara", localitate: "Dumesti" },
  { judet: "Hunedoara", localitate: "Luncsoara" },
  { judet: "Hunedoara", localitate: "Valea Poienii" },
  { judet: "Hunedoara", localitate: "Visca" },
  { judet: "Hunedoara", localitate: "Almas-Saliste" },
  { judet: "Hunedoara", localitate: "Almasel" },
  { judet: "Hunedoara", localitate: "Braseu" },
  { judet: "Hunedoara", localitate: "Cerbia" },
  { judet: "Hunedoara", localitate: "Deleni" },
  { judet: "Hunedoara", localitate: "Godinesti" },
  { judet: "Hunedoara", localitate: "Micanesti" },
  { judet: "Hunedoara", localitate: "Poganesti" },
  { judet: "Hunedoara", localitate: "Pojoga" },
  { judet: "Hunedoara", localitate: "Salciva" },
  { judet: "Hunedoara", localitate: "Tamasesti" },
  { judet: "Hunedoara", localitate: "Valea" },
  { judet: "Ialomita", localitate: "Slobozia" },
  { judet: "Ialomita", localitate: "Urziceni" },
  { judet: "Ialomita", localitate: "Fetesti" },
  { judet: "Ialomita", localitate: "Amara" },
  { judet: "Ialomita", localitate: "Cazanesti" },
  { judet: "Ialomita", localitate: "Fierbinti-Targ" },
  { judet: "Ialomita", localitate: "Tandarei" },
  { judet: "Ialomita", localitate: "Adancata" },
  { judet: "Ialomita", localitate: "Albesti" },
  { judet: "Ialomita", localitate: "Alexeni" },
  { judet: "Ialomita", localitate: "Andrasesti" },
  { judet: "Ialomita", localitate: "Armasesti" },
  { judet: "Ialomita", localitate: "Axintele" },
  { judet: "Ialomita", localitate: "Balaciu" },
  { judet: "Ialomita", localitate: "Barcanesti" },
  { judet: "Ialomita", localitate: "Boranesti" },
  { judet: "Ialomita", localitate: "Bordusani" },
  { judet: "Ialomita", localitate: "Bucu" },
  { judet: "Ialomita", localitate: "Buesti" },
  { judet: "Ialomita", localitate: "Ciocarlia" },
  { judet: "Ialomita", localitate: "Ciochina" },
  { judet: "Ialomita", localitate: "Ciulnita" },
  { judet: "Ialomita", localitate: "Cocora" },
  { judet: "Ialomita", localitate: "Cosambesti" },
  { judet: "Ialomita", localitate: "Cosereni" },
  { judet: "Ialomita", localitate: "Dragoesti" },
  { judet: "Ialomita", localitate: "Dridu" },
  { judet: "Ialomita", localitate: "Facaeni" },
  { judet: "Ialomita", localitate: "Garbovi" },
  { judet: "Ialomita", localitate: "Gheorghe Doja" },
  { judet: "Ialomita", localitate: "Gheorghe Lazar" },
  { judet: "Ialomita", localitate: "Giurgeni" },
  { judet: "Ialomita", localitate: "Grindu" },
  { judet: "Ialomita", localitate: "Grivita" },
  { judet: "Ialomita", localitate: "Gura Ialomitei" },
  { judet: "Ialomita", localitate: "Ion Roata" },
  { judet: "Ialomita", localitate: "Jilavele" },
  { judet: "Ialomita", localitate: "Maia" },
  { judet: "Ialomita", localitate: "Manasia" },
  { judet: "Ialomita", localitate: "Marculesti" },
  { judet: "Ialomita", localitate: "Mihail Kogalniceanu" },
  { judet: "Ialomita", localitate: "Milosesti" },
  { judet: "Ialomita", localitate: "Moldoveni" },
  { judet: "Ialomita", localitate: "Movila" },
  { judet: "Ialomita", localitate: "Movilita" },
  { judet: "Ialomita", localitate: "Munteni-Buzau" },
  { judet: "Ialomita", localitate: "Ograda" },
  { judet: "Ialomita", localitate: "Perieti" },
  { judet: "Ialomita", localitate: "Platonesti" },
  { judet: "Ialomita", localitate: "Reviga" },
  { judet: "Ialomita", localitate: "Rosiori" },
  { judet: "Ialomita", localitate: "Salcioara" },
  { judet: "Ialomita", localitate: "Sarateni" },
  { judet: "Ialomita", localitate: "Saveni" },
  { judet: "Ialomita", localitate: "Scanteia" },
  { judet: "Ialomita", localitate: "Sfantu Gheorghe" },
  { judet: "Ialomita", localitate: "Sinesti" },
  { judet: "Ialomita", localitate: "Stelnica" },
  { judet: "Ialomita", localitate: "Suditi" },
  { judet: "Ialomita", localitate: "Traian" },
  { judet: "Ialomita", localitate: "Valea Ciorii" },
  { judet: "Ialomita", localitate: "Valea Macrisului" },
  { judet: "Ialomita", localitate: "Vladeni" },
  { judet: "Ialomita", localitate: "Fetesti Gara" },
  { judet: "Ialomita", localitate: "Bora" },
  { judet: "Ialomita", localitate: "Slobozia Noua" },
  { judet: "Ialomita", localitate: "Buliga" },
  { judet: "Ialomita", localitate: "Vlasca" },
  { judet: "Ialomita", localitate: "Patru Frati" },
  { judet: "Ialomita", localitate: "Bataluri" },
  { judet: "Ialomita", localitate: "Marsilieni" },
  { judet: "Ialomita", localitate: "Amara Noua" },
  { judet: "Ialomita", localitate: "Orboesti" },
  { judet: "Ialomita", localitate: "Barbulesti" },
  { judet: "Ialomita", localitate: "Malu Rosu" },
  { judet: "Ialomita", localitate: "Nenisori" },
  { judet: "Ialomita", localitate: "Barbatescu" },
  { judet: "Ialomita", localitate: "Horia" },
  { judet: "Ialomita", localitate: "Copuzu" },
  { judet: "Ialomita", localitate: "Crasanii de Jos" },
  { judet: "Ialomita", localitate: "Crasanii de Sus" },
  { judet: "Ialomita", localitate: "Condeesti" },
  { judet: "Ialomita", localitate: "Cegani" },
  { judet: "Ialomita", localitate: "Brazii" },
  { judet: "Ialomita", localitate: "Movileanca" },
  { judet: "Ialomita", localitate: "Rasimnicea" },
  { judet: "Ialomita", localitate: "Cotorca" },
  { judet: "Ialomita", localitate: "Borduselu" },
  { judet: "Ialomita", localitate: "Orezu" },
  { judet: "Ialomita", localitate: "Piersica" },
  { judet: "Ialomita", localitate: "Ion Ghica" },
  { judet: "Ialomita", localitate: "Ivanesti" },
  { judet: "Ialomita", localitate: "Poiana" },
  { judet: "Ialomita", localitate: "Colelia" },
  { judet: "Ialomita", localitate: "Gimbasani" },
  { judet: "Ialomita", localitate: "Sintesti" },
  { judet: "Ialomita", localitate: "Chiroiu-Pamanteni" },
  { judet: "Ialomita", localitate: "Chiroiu-Satu Nou" },
  { judet: "Ialomita", localitate: "Chiroiu-Ungureni" },
  { judet: "Ialomita", localitate: "Valea Bisericii" },
  { judet: "Ialomita", localitate: "Dridu-Snagov" },
  { judet: "Ialomita", localitate: "Progresu" },
  { judet: "Ialomita", localitate: "Fierbintii de Jos" },
  { judet: "Ialomita", localitate: "Fierbintii de Sus" },
  { judet: "Ialomita", localitate: "Grecii de Jos" },
  { judet: "Ialomita", localitate: "Smirna" },
  { judet: "Ialomita", localitate: "Brosteni" },
  { judet: "Ialomita", localitate: "Slatioarele" },
  { judet: "Ialomita", localitate: "Hagieni" },
  { judet: "Ialomita", localitate: "Luciu" },
  { judet: "Ialomita", localitate: "Nicolesti" },
  { judet: "Ialomita", localitate: "Tovarasia" },
  { judet: "Ialomita", localitate: "Baraganu" },
  { judet: "Ialomita", localitate: "Bitina-Pamanteni" },
  { judet: "Ialomita", localitate: "Bitina-Ungureni" },
  { judet: "Ialomita", localitate: "Fundata" },
  { judet: "Ialomita", localitate: "Misleanu" },
  { judet: "Ialomita", localitate: "Paltinisu" },
  { judet: "Ialomita", localitate: "Stejaru" },
  { judet: "Ialomita", localitate: "Crunti" },
  { judet: "Ialomita", localitate: "Mircea cel Batran" },
  { judet: "Ialomita", localitate: "Rovine" },
  { judet: "Ialomita", localitate: "Rasi" },
  { judet: "Ialomita", localitate: "Fratilesti" },
  { judet: "Ialomita", localitate: "Lacusteni" },
  { judet: "Ialomita", localitate: "Iazu" },
  { judet: "Ialomita", localitate: "Butoiu" },
  { judet: "Ialomita", localitate: "Malu" },
  { judet: "Ialomita", localitate: "Boteni" },
  { judet: "Ialomita", localitate: "Catrunesti" },
  { judet: "Ialomita", localitate: "Hagiesti" },
  { judet: "Ialomita", localitate: "Lilieci" },
  { judet: "Ialomita", localitate: "Livedea" },
  { judet: "Ialomita", localitate: "Maltezi" },
  { judet: "Ialomita", localitate: "Retezatu" },
  { judet: "Ialomita", localitate: "Gura Vaii" },
  { judet: "Ialomita", localitate: "Bucsa" },
  { judet: "Ialomita", localitate: "Dumitresti" },
  { judet: "Ialomita", localitate: "Murgeanca" },
  { judet: "Ialomita", localitate: "Grindasi" },
  { judet: "Iasi", localitate: "Iasi" },
  { judet: "Iasi", localitate: "Pascani" },
  { judet: "Iasi", localitate: "Targu Frumos" },
  { judet: "Iasi", localitate: "Lunca Cetatuii" },
  { judet: "Iasi", localitate: "Dancu" },
  { judet: "Iasi", localitate: "Holboca" },
  { judet: "Iasi", localitate: "Harlau" },
  { judet: "Iasi", localitate: "Podu Iloaiei" },
  { judet: "Iasi", localitate: "Alexandru Ioan Cuza" },
  { judet: "Iasi", localitate: "Andrieseni" },
  { judet: "Iasi", localitate: "Aroneanu" },
  { judet: "Iasi", localitate: "Bals" },
  { judet: "Iasi", localitate: "Baltati" },
  { judet: "Iasi", localitate: "Barnova" },
  { judet: "Iasi", localitate: "Belcesti" },
  { judet: "Iasi", localitate: "Bivolari" },
  { judet: "Iasi", localitate: "Braesti" },
  { judet: "Iasi", localitate: "Butea" },
  { judet: "Iasi", localitate: "Ceplenita" },
  { judet: "Iasi", localitate: "Ciohorani" },
  { judet: "Iasi", localitate: "Ciortesti" },
  { judet: "Iasi", localitate: "Ciurea" },
  { judet: "Iasi", localitate: "Coarnele Caprei" },
  { judet: "Iasi", localitate: "Comarna" },
  { judet: "Iasi", localitate: "Costesti" },
  { judet: "Iasi", localitate: "Costuleni" },
  { judet: "Iasi", localitate: "Cotnari" },
  { judet: "Iasi", localitate: "Cozmesti" },
  { judet: "Iasi", localitate: "Cristesti" },
  { judet: "Iasi", localitate: "Cucuteni" },
  { judet: "Iasi", localitate: "Dagata" },
  { judet: "Iasi", localitate: "Deleni" },
  { judet: "Iasi", localitate: "Dobrovat" },
  { judet: "Iasi", localitate: "Dolhesti" },
  { judet: "Iasi", localitate: "Draguseni" },
  { judet: "Iasi", localitate: "Dumesti" },
  { judet: "Iasi", localitate: "Erbiceni" },
  { judet: "Iasi", localitate: "Fantanele" },
  { judet: "Iasi", localitate: "Focuri" },
  { judet: "Iasi", localitate: "Golaiesti" },
  { judet: "Iasi", localitate: "Gorban" },
  { judet: "Iasi", localitate: "Grajduri" },
  { judet: "Iasi", localitate: "Gropnita" },
  { judet: "Iasi", localitate: "Grozesti" },
  { judet: "Iasi", localitate: "Halaucesti" },
  { judet: "Iasi", localitate: "Harmanesti" },
  { judet: "Iasi", localitate: "Helesteni" },
  { judet: "Iasi", localitate: "Horlesti" },
  { judet: "Iasi", localitate: "Ion Neculce" },
  { judet: "Iasi", localitate: "Ipatele" },
  { judet: "Iasi", localitate: "Lespezi" },
  { judet: "Iasi", localitate: "Letcani" },
  { judet: "Iasi", localitate: "Lungani" },
  { judet: "Iasi", localitate: "Madarjac" },
  { judet: "Iasi", localitate: "Mircesti" },
  { judet: "Iasi", localitate: "Mironeasa" },
  { judet: "Iasi", localitate: "Miroslava" },
  { judet: "Iasi", localitate: "Miroslovesti" },
  { judet: "Iasi", localitate: "Mogosesti" },
  { judet: "Iasi", localitate: "Mogosesti-Siret" },
  { judet: "Iasi", localitate: "Mosna" },
  { judet: "Iasi", localitate: "Motca" },
  { judet: "Iasi", localitate: "Movileni" },
  { judet: "Iasi", localitate: "Oteleni" },
  { judet: "Iasi", localitate: "Plugari" },
  { judet: "Iasi", localitate: "Popesti" },
  { judet: "Iasi", localitate: "Popricani" },
  { judet: "Iasi", localitate: "Prisacani" },
  { judet: "Iasi", localitate: "Probota" },
  { judet: "Iasi", localitate: "Rachiteni" },
  { judet: "Iasi", localitate: "Raducaneni" },
  { judet: "Iasi", localitate: "Rediu" },
  { judet: "Iasi", localitate: "Romanesti" },
  { judet: "Iasi", localitate: "Roscani" },
  { judet: "Iasi", localitate: "Ruginoasa" },
  { judet: "Iasi", localitate: "Scanteia" },
  { judet: "Iasi", localitate: "Schitu Duca" },
  { judet: "Iasi", localitate: "Scobinti" },
  { judet: "Iasi", localitate: "Sinesti" },
  { judet: "Iasi", localitate: "Siretel" },
  { judet: "Iasi", localitate: "Stolniceni-Prajescu" },
  { judet: "Iasi", localitate: "Strunga" },
  { judet: "Iasi", localitate: "Scheia" },
  { judet: "Iasi", localitate: "Sipote" },
  { judet: "Iasi", localitate: "Tansa" },
  { judet: "Iasi", localitate: "Tatarusi" },
  { judet: "Iasi", localitate: "Todiresti" },
  { judet: "Iasi", localitate: "Tomesti" },
  { judet: "Iasi", localitate: "Trifesti" },
  { judet: "Iasi", localitate: "Tibana" },
  { judet: "Iasi", localitate: "Tibanesti" },
  { judet: "Iasi", localitate: "Tiganasi" },
  { judet: "Iasi", localitate: "Tutora" },
  { judet: "Iasi", localitate: "Ungheni" },
  { judet: "Iasi", localitate: "Valea Lupului" },
  { judet: "Iasi", localitate: "Valea Seaca" },
  { judet: "Iasi", localitate: "Vanatori" },
  { judet: "Iasi", localitate: "Victoria" },
  { judet: "Iasi", localitate: "Vladeni" },
  { judet: "Iasi", localitate: "Voinesti" },
  { judet: "Iasi", localitate: "Parcovaci" },
  { judet: "Iasi", localitate: "Blagesti" },
  { judet: "Iasi", localitate: "Bosteni" },
  { judet: "Iasi", localitate: "Gastesti" },
  { judet: "Iasi", localitate: "Lunca (Pascani)" },
  { judet: "Iasi", localitate: "Sodomeni" },
  { judet: "Iasi", localitate: "Boureni (Bals)" },
  { judet: "Iasi", localitate: "Buznea" },
  { judet: "Iasi", localitate: "Coasta Magurii" },
  { judet: "Iasi", localitate: "Dadesti" },
  { judet: "Iasi", localitate: "Ganesti" },
  { judet: "Iasi", localitate: "Giurgesti" },
  { judet: "Iasi", localitate: "Prigoreni" },
  { judet: "Iasi", localitate: "Razboieni" },
  { judet: "Iasi", localitate: "Kogalniceni" },
  { judet: "Iasi", localitate: "Scheia" },
  { judet: "Iasi", localitate: "Volintiresti" },
  { judet: "Iasi", localitate: "Buhaeni" },
  { judet: "Iasi", localitate: "Draganesti" },
  { judet: "Iasi", localitate: "Fantanele (Andrieseni)" },
  { judet: "Iasi", localitate: "Glavanesti" },
  { judet: "Iasi", localitate: "Iepureni (Andrieseni)" },
  { judet: "Iasi", localitate: "Spineni" },
  { judet: "Iasi", localitate: "Dorobant" },
  { judet: "Iasi", localitate: "Rediu Aldei" },
  { judet: "Iasi", localitate: "Sorogari" },
  { judet: "Iasi", localitate: "Cotargaci" },
  { judet: "Iasi", localitate: "Filiasi" },
  { judet: "Iasi", localitate: "Madarjesti" },
  { judet: "Iasi", localitate: "Podisu" },
  { judet: "Iasi", localitate: "Sarca" },
  { judet: "Iasi", localitate: "Valea Oilor" },
  { judet: "Iasi", localitate: "Cercu" },
  { judet: "Iasi", localitate: "Paun" },
  { judet: "Iasi", localitate: "Pietraria" },
  { judet: "Iasi", localitate: "Todirel" },
  { judet: "Iasi", localitate: "Visan" },
  { judet: "Iasi", localitate: "Liteni" },
  { judet: "Iasi", localitate: "Munteni" },
  { judet: "Iasi", localitate: "Satu Nou (Belcesti)" },
  { judet: "Iasi", localitate: "Tansa (Belcesti)" },
  { judet: "Iasi", localitate: "Ulmi" },
  { judet: "Iasi", localitate: "Buruienesti" },
  { judet: "Iasi", localitate: "Solonet" },
  { judet: "Iasi", localitate: "Tabara" },
  { judet: "Iasi", localitate: "Traian" },
  { judet: "Iasi", localitate: "Albesti" },
  { judet: "Iasi", localitate: "Buda (Braesti)" },
  { judet: "Iasi", localitate: "Cristesti (Braesti)" },
  { judet: "Iasi", localitate: "Rediu (Braesti)" },
  { judet: "Iasi", localitate: "Miclauseni" },
  { judet: "Iasi", localitate: "Buhalnita" },
  { judet: "Iasi", localitate: "Poiana Marului" },
  { judet: "Iasi", localitate: "Zlodica" },
  { judet: "Iasi", localitate: "Coropceni" },
  { judet: "Iasi", localitate: "Deleni (Ciortesti)" },
  { judet: "Iasi", localitate: "Rotaria" },
  { judet: "Iasi", localitate: "Serbesti" },
  { judet: "Iasi", localitate: "Curaturi" },
  { judet: "Iasi", localitate: "Dumbrava (Ciurea)" },
  { judet: "Iasi", localitate: "Hlincea" },
  { judet: "Iasi", localitate: "Picioru Lupului" },
  { judet: "Iasi", localitate: "Slobozia (Ciurea)" },
  { judet: "Iasi", localitate: "Arama" },
  { judet: "Iasi", localitate: "Petrosica" },
  { judet: "Iasi", localitate: "Curagau" },
  { judet: "Iasi", localitate: "Stanca (Comarna)" },
  { judet: "Iasi", localitate: "Osoi (Comarna)" },
  { judet: "Iasi", localitate: "Covasna" },
  { judet: "Iasi", localitate: "Cozia" },
  { judet: "Iasi", localitate: "Hilita" },
  { judet: "Iasi", localitate: "Bahluiu" },
  { judet: "Iasi", localitate: "Carjoaia" },
  { judet: "Iasi", localitate: "Cireseni" },
  { judet: "Iasi", localitate: "Fagat" },
  { judet: "Iasi", localitate: "Hodora" },
  { judet: "Iasi", localitate: "Horodistea" },
  { judet: "Iasi", localitate: "Iosupeni" },
  { judet: "Iasi", localitate: "Luparia" },
  { judet: "Iasi", localitate: "Valea Racului" },
  { judet: "Iasi", localitate: "Zbereni" },
  { judet: "Iasi", localitate: "Podolenii de Jos" },
  { judet: "Iasi", localitate: "Podolenii de Sus" },
  { judet: "Iasi", localitate: "Homita" },
  { judet: "Iasi", localitate: "Baiceni (Cucuteni)" },
  { judet: "Iasi", localitate: "Barbatesti" },
  { judet: "Iasi", localitate: "Sacaresti" },
  { judet: "Iasi", localitate: "Balusesti" },
  { judet: "Iasi", localitate: "Boatca" },
  { judet: "Iasi", localitate: "Buzdug" },
  { judet: "Iasi", localitate: "Manastirea" },
  { judet: "Iasi", localitate: "Piscu Rusului" },
  { judet: "Iasi", localitate: "Poienile" },
  { judet: "Iasi", localitate: "Tarnita" },
  { judet: "Iasi", localitate: "Zece Prajini" },
  { judet: "Iasi", localitate: "Feredeni" },
  { judet: "Iasi", localitate: "Leahu-Nacu" },
  { judet: "Iasi", localitate: "Maxut" },
  { judet: "Iasi", localitate: "Poiana (Deleni)" },
  { judet: "Iasi", localitate: "Slobozia (Deleni)" },
  { judet: "Iasi", localitate: "Bradicesti" },
  { judet: "Iasi", localitate: "Pietris" },
  { judet: "Iasi", localitate: "Banu" },
  { judet: "Iasi", localitate: "Chilisoaia" },
  { judet: "Iasi", localitate: "Hoisesti" },
  { judet: "Iasi", localitate: "Pausesti" },
  { judet: "Iasi", localitate: "Barlesti" },
  { judet: "Iasi", localitate: "Spinoasa" },
  { judet: "Iasi", localitate: "Spranceana" },
  { judet: "Iasi", localitate: "Totoesti" },
  { judet: "Iasi", localitate: "Bran" },
  { judet: "Iasi", localitate: "Cilibiu" },
  { judet: "Iasi", localitate: "Cotu lui Ivan" },
  { judet: "Iasi", localitate: "Gradinari" },
  { judet: "Iasi", localitate: "Medeleni" },
  { judet: "Iasi", localitate: "Petresti" },
  { judet: "Iasi", localitate: "Podu Jijiei" },
  { judet: "Iasi", localitate: "Gura Bohotin" },
  { judet: "Iasi", localitate: "Podu Hagiului" },
  { judet: "Iasi", localitate: "Scoposeni (Gorban)" },
  { judet: "Iasi", localitate: "Zberoaia" },
  { judet: "Iasi", localitate: "Carbunari" },
  { judet: "Iasi", localitate: "Corcodel" },
  { judet: "Iasi", localitate: "Lunca (Grajduri)" },
  { judet: "Iasi", localitate: "Padureni (Grajduri)" },
  { judet: "Iasi", localitate: "Poiana cu Cetate" },
  { judet: "Iasi", localitate: "Valea Satului" },
  { judet: "Iasi", localitate: "Bulbucani" },
  { judet: "Iasi", localitate: "Forasti" },
  { judet: "Iasi", localitate: "Malaesti" },
  { judet: "Iasi", localitate: "Saveni" },
  { judet: "Iasi", localitate: "Sangeri" },
  { judet: "Iasi", localitate: "Coltu Cornii" },
  { judet: "Iasi", localitate: "Salageni" },
  { judet: "Iasi", localitate: "Luncasi" },
  { judet: "Iasi", localitate: "Harmaneasa" },
  { judet: "Iasi", localitate: "Movileni (Helesteni)" },
  { judet: "Iasi", localitate: "Oboroceni" },
  { judet: "Iasi", localitate: "Cristesti (Holboca)" },
  { judet: "Iasi", localitate: "Orzeni" },
  { judet: "Iasi", localitate: "Rusenii Noi" },
  { judet: "Iasi", localitate: "Rusenii Vechi" },
  { judet: "Iasi", localitate: "Valea Lunga" },
  { judet: "Iasi", localitate: "Bogdanesti" },
  { judet: "Iasi", localitate: "Scoposeni (Horlesti)" },
  { judet: "Iasi", localitate: "Alexesti" },
  { judet: "Iasi", localitate: "Bacu" },
  { judet: "Iasi", localitate: "Cuza Voda (Ipatele)" },
  { judet: "Iasi", localitate: "Buda (Lespezi)" },
  { judet: "Iasi", localitate: "Bursuc-Deal" },
  { judet: "Iasi", localitate: "Bursuc-Vale" },
  { judet: "Iasi", localitate: "Dumbrava (Lespezi)" },
  { judet: "Iasi", localitate: "Heci" },
  { judet: "Iasi", localitate: "Bogonos" },
  { judet: "Iasi", localitate: "Cogeasca" },
  { judet: "Iasi", localitate: "Cucuteni (Letcani)" },
  { judet: "Iasi", localitate: "Crucea" },
  { judet: "Iasi", localitate: "Goesti" },
  { judet: "Iasi", localitate: "Zmeu" },
  { judet: "Iasi", localitate: "Bojila" },
  { judet: "Iasi", localitate: "Frumusica" },
  { judet: "Iasi", localitate: "Iugani" },
  { judet: "Iasi", localitate: "Izvoarele" },
  { judet: "Iasi", localitate: "Ursaresti" },
  { judet: "Iasi", localitate: "Schitu Hadambului" },
  { judet: "Iasi", localitate: "Ursita" },
  { judet: "Iasi", localitate: "Balciu" },
  { judet: "Iasi", localitate: "Bratuleni" },
  { judet: "Iasi", localitate: "Ciurbesti" },
  { judet: "Iasi", localitate: "Cornesti" },
  { judet: "Iasi", localitate: "Dancas" },
  { judet: "Iasi", localitate: "Gaureni" },
  { judet: "Iasi", localitate: "Horpaz" },
  { judet: "Iasi", localitate: "Proselnici" },
  { judet: "Iasi", localitate: "Uricani" },
  { judet: "Iasi", localitate: "Valea Adanca" },
  { judet: "Iasi", localitate: "Valea Ursului" },
  { judet: "Iasi", localitate: "Vorovesti" },
  { judet: "Iasi", localitate: "Mitesti" },
  { judet: "Iasi", localitate: "Soci" },
  { judet: "Iasi", localitate: "Verseni" },
  { judet: "Iasi", localitate: "Budesti" },
  { judet: "Iasi", localitate: "Hadambu" },
  { judet: "Iasi", localitate: "Manjesti" },
  { judet: "Iasi", localitate: "Muncelu de Sus" },
  { judet: "Iasi", localitate: "Tudor Vladimirescu" },
  { judet: "Iasi", localitate: "Boureni (Motca)" },
  { judet: "Iasi", localitate: "Iepureni (Movileni)" },
  { judet: "Iasi", localitate: "Larga-Jijia" },
  { judet: "Iasi", localitate: "Potangeni" },
  { judet: "Iasi", localitate: "Handresti" },
  { judet: "Iasi", localitate: "Borosoaia" },
  { judet: "Iasi", localitate: "Onesti" },
  { judet: "Iasi", localitate: "Budai" },
  { judet: "Iasi", localitate: "Cositeni" },
  { judet: "Iasi", localitate: "Holm" },
  { judet: "Iasi", localitate: "Scobalteni" },
  { judet: "Iasi", localitate: "Doroscani" },
  { judet: "Iasi", localitate: "Harpasesti" },
  { judet: "Iasi", localitate: "Obrijeni" },
  { judet: "Iasi", localitate: "Padureni (Popesti)" },
  { judet: "Iasi", localitate: "Vama" },
  { judet: "Iasi", localitate: "Carlig" },
  { judet: "Iasi", localitate: "Cotu Morii" },
  { judet: "Iasi", localitate: "Cuza Voda (Popricani)" },
  { judet: "Iasi", localitate: "Moimesti" },
  { judet: "Iasi", localitate: "Rediu Mitropoliei" },
  { judet: "Iasi", localitate: "Tipilesti" },
  { judet: "Iasi", localitate: "Vanatori (Popricani)" },
  { judet: "Iasi", localitate: "Vulturi" },
  { judet: "Iasi", localitate: "Macaresti" },
  { judet: "Iasi", localitate: "Moreni" },
  { judet: "Iasi", localitate: "Balteni" },
  { judet: "Iasi", localitate: "Perieni" },
  { judet: "Iasi", localitate: "Bohotin" },
  { judet: "Iasi", localitate: "Isaiia" },
  { judet: "Iasi", localitate: "Rosu" },
  { judet: "Iasi", localitate: "Breazu" },
  { judet: "Iasi", localitate: "Horlesti (Rediu)" },
  { judet: "Iasi", localitate: "Tautesti" },
  { judet: "Iasi", localitate: "Avantu" },
  { judet: "Iasi", localitate: "Ursoaia" },
  { judet: "Iasi", localitate: "Dumbravita" },
  { judet: "Iasi", localitate: "Rediu (Ruginoasa)" },
  { judet: "Iasi", localitate: "Vascani" },
  { judet: "Iasi", localitate: "Bodesti" },
  { judet: "Iasi", localitate: "Borosesti" },
  { judet: "Iasi", localitate: "Ciocarlesti" },
  { judet: "Iasi", localitate: "Lunca Rates" },
  { judet: "Iasi", localitate: "Rediu (Scanteia)" },
  { judet: "Iasi", localitate: "Tufestii de Sus" },
  { judet: "Iasi", localitate: "Blaga" },
  { judet: "Iasi", localitate: "Dumitrestii Galatii" },
  { judet: "Iasi", localitate: "Pocreaca" },
  { judet: "Iasi", localitate: "Poiana (Schitu Duca)" },
  { judet: "Iasi", localitate: "Poieni" },
  { judet: "Iasi", localitate: "Satu Nou" },
  { judet: "Iasi", localitate: "Slobozia (Schitu Duca)" },
  { judet: "Iasi", localitate: "Badeni" },
  { judet: "Iasi", localitate: "Fetesti" },
  { judet: "Iasi", localitate: "Sticlaria" },
  { judet: "Iasi", localitate: "Zagavia" },
  { judet: "Iasi", localitate: "Bocnita" },
  { judet: "Iasi", localitate: "Osoi (Sinesti)" },
  { judet: "Iasi", localitate: "Stornesti" },
  { judet: "Iasi", localitate: "Berezlogi" },
  { judet: "Iasi", localitate: "Humosu" },
  { judet: "Iasi", localitate: "Satu Nou (Siretel)" },
  { judet: "Iasi", localitate: "Slobozia (Siretel)" },
  { judet: "Iasi", localitate: "Bratesti" },
  { judet: "Iasi", localitate: "Cozmesti (Stolniceni-Prajescu)" },
  { judet: "Iasi", localitate: "Bratulesti" },
  { judet: "Iasi", localitate: "Crivesti (Strunga)" },
  { judet: "Iasi", localitate: "Cucova" },
  { judet: "Iasi", localitate: "Farcaseni" },
  { judet: "Iasi", localitate: "Fedeleseni" },
  { judet: "Iasi", localitate: "Gura Vaii" },
  { judet: "Iasi", localitate: "Habasesti" },
  { judet: "Iasi", localitate: "Cauesti" },
  { judet: "Iasi", localitate: "Cioca-Boca" },
  { judet: "Iasi", localitate: "Frenciugi" },
  { judet: "Iasi", localitate: "Poiana Scheii" },
  { judet: "Iasi", localitate: "Satu Nou (Scheia)" },
  { judet: "Iasi", localitate: "Chiscareni" },
  { judet: "Iasi", localitate: "Halceni" },
  { judet: "Iasi", localitate: "Iazu Nou" },
  { judet: "Iasi", localitate: "Iazu Vechi" },
  { judet: "Iasi", localitate: "Mitoc" },
  { judet: "Iasi", localitate: "Suhulet" },
  { judet: "Iasi", localitate: "Iorcani" },
  { judet: "Iasi", localitate: "Pietrosu" },
  { judet: "Iasi", localitate: "Uda" },
  { judet: "Iasi", localitate: "Valcica" },
  { judet: "Iasi", localitate: "Baiceni (Todiresti)" },
  { judet: "Iasi", localitate: "Boldesti" },
  { judet: "Iasi", localitate: "Harmanestii Noi" },
  { judet: "Iasi", localitate: "Harmanestii Vechi" },
  { judet: "Iasi", localitate: "Stroesti" },
  { judet: "Iasi", localitate: "Chicerea" },
  { judet: "Iasi", localitate: "Goruni" },
  { judet: "Iasi", localitate: "Vladiceni" },
  { judet: "Iasi", localitate: "Hermeziu" },
  { judet: "Iasi", localitate: "Radeni" },
  { judet: "Iasi", localitate: "Vladomira" },
  { judet: "Iasi", localitate: "Zaboloteni" },
  { judet: "Iasi", localitate: "Alexeni" },
  { judet: "Iasi", localitate: "Domnita" },
  { judet: "Iasi", localitate: "Garbesti" },
  { judet: "Iasi", localitate: "Moara Ciornei" },
  { judet: "Iasi", localitate: "Oproaia" },
  { judet: "Iasi", localitate: "Poiana Manastirii" },
  { judet: "Iasi", localitate: "Poiana de Sus" },
  { judet: "Iasi", localitate: "Runcu" },
  { judet: "Iasi", localitate: "Vadu Vejei" },
  { judet: "Iasi", localitate: "Glodenii Gandului" },
  { judet: "Iasi", localitate: "Griesti" },
  { judet: "Iasi", localitate: "Jigoreni" },
  { judet: "Iasi", localitate: "Rasboieni" },
  { judet: "Iasi", localitate: "Recea" },
  { judet: "Iasi", localitate: "Tungujei" },
  { judet: "Iasi", localitate: "Valenii" },
  { judet: "Iasi", localitate: "Carniceni" },
  { judet: "Iasi", localitate: "Mihail Kogalniceanu" },
  { judet: "Iasi", localitate: "Stejarii" },
  { judet: "Iasi", localitate: "Chiperesti" },
  { judet: "Iasi", localitate: "Opriseni" },
  { judet: "Iasi", localitate: "Bosia" },
  { judet: "Iasi", localitate: "Coada Stancii" },
  { judet: "Iasi", localitate: "Manzatesti" },
  { judet: "Iasi", localitate: "Contesti" },
  { judet: "Iasi", localitate: "Topile" },
  { judet: "Iasi", localitate: "Crivesti (Vanatori)" },
  { judet: "Iasi", localitate: "Gura Badilitei" },
  { judet: "Iasi", localitate: "Hartoape" },
  { judet: "Iasi", localitate: "Vladnicut" },
  { judet: "Iasi", localitate: "Frasuleni" },
  { judet: "Iasi", localitate: "Icuseni" },
  { judet: "Iasi", localitate: "Luceni" },
  { judet: "Iasi", localitate: "Sculeni" },
  { judet: "Iasi", localitate: "Stanca (Victoria)" },
  { judet: "Iasi", localitate: "Sendreni" },
  { judet: "Iasi", localitate: "Alexandru cel Bun" },
  { judet: "Iasi", localitate: "Borsa" },
  { judet: "Iasi", localitate: "Brosteni" },
  { judet: "Iasi", localitate: "Iacobeni" },
  { judet: "Iasi", localitate: "Valcelele" },
  { judet: "Iasi", localitate: "Lungani (Voinesti)" },
  { judet: "Iasi", localitate: "Schitu Stavnic" },
  { judet: "Iasi", localitate: "Slobozia (Voinesti)" },
  { judet: "Iasi", localitate: "Vocotesti" },
  { judet: "Ilfov", localitate: "Voluntari" },
  { judet: "Ilfov", localitate: "Otopeni" },
  { judet: "Ilfov", localitate: "Buftea" },
  { judet: "Ilfov", localitate: "Afumati" },
  { judet: "Ilfov", localitate: "Chiajna" },
  { judet: "Ilfov", localitate: "Snagov" },
  { judet: "Ilfov", localitate: "Jilava" },
  { judet: "Ilfov", localitate: "Magurele" },
  { judet: "Ilfov", localitate: "Clinceni" },
  { judet: "Ilfov", localitate: "Bragadiru" },
  { judet: "Ilfov", localitate: "Chitila" },
  { judet: "Ilfov", localitate: "Popesti Leordeni" },
  { judet: "Ilfov", localitate: "Pantelimon" },
  { judet: "Ilfov", localitate: "Pipera (Voluntari)" },
  { judet: "Ilfov", localitate: "Domnesti" },
  { judet: "Ilfov", localitate: "Dascalu" },
  { judet: "Ilfov", localitate: "Ciolpani" },
  { judet: "Ilfov", localitate: "Gruiu" },
  { judet: "Ilfov", localitate: "Moara Vlasiei" },
  { judet: "Ilfov", localitate: "Nuci" },
  { judet: "Ilfov", localitate: "Peris" },
  { judet: "Ilfov", localitate: "Vidra" },
  { judet: "Ilfov", localitate: "Fundeni" },
  { judet: "Ilfov", localitate: "Stefanestii de Jos" },
  { judet: "Ilfov", localitate: "Tunari" },
  { judet: "Ilfov", localitate: "Corbeanca" },
  { judet: "Ilfov", localitate: "Dobroiesti" },
  { judet: "Ilfov", localitate: "1 Decembrie" },
  { judet: "Ilfov", localitate: "Balotesti" },
  { judet: "Ilfov", localitate: "Berceni" },
  { judet: "Ilfov", localitate: "Branesti" },
  { judet: "Ilfov", localitate: "Cernica" },
  { judet: "Ilfov", localitate: "Ciorogarla" },
  { judet: "Ilfov", localitate: "Copaceni" },
  { judet: "Ilfov", localitate: "Cornetu" },
  { judet: "Ilfov", localitate: "Darasti-Ilfov" },
  { judet: "Ilfov", localitate: "Dragomiresti-Vale" },
  { judet: "Ilfov", localitate: "Ganeasa" },
  { judet: "Ilfov", localitate: "Glina" },
  { judet: "Ilfov", localitate: "Gradistea" },
  { judet: "Ilfov", localitate: "Mogosoaia" },
  { judet: "Ilfov", localitate: "Petrachioaia" },
  { judet: "Ilfov", localitate: "Tancabesti" },
  { judet: "Ilfov", localitate: "Buciumeni" },
  { judet: "Ilfov", localitate: "Odaile" },
  { judet: "Ilfov", localitate: "Dumbraveni" },
  { judet: "Ilfov", localitate: "Saftica" },
  { judet: "Ilfov", localitate: "Islaz" },
  { judet: "Ilfov", localitate: "Pasarea" },
  { judet: "Ilfov", localitate: "Vadu Anei" },
  { judet: "Ilfov", localitate: "Balaceanca" },
  { judet: "Ilfov", localitate: "Caldararu" },
  { judet: "Ilfov", localitate: "Posta" },
  { judet: "Ilfov", localitate: "Tanganu" },
  { judet: "Ilfov", localitate: "Dudu" },
  { judet: "Ilfov", localitate: "Rosu" },
  { judet: "Ilfov", localitate: "Rudeni" },
  { judet: "Ilfov", localitate: "Izvorani" },
  { judet: "Ilfov", localitate: "Luparia" },
  { judet: "Ilfov", localitate: "Piscu" },
  { judet: "Ilfov", localitate: "Darvari" },
  { judet: "Ilfov", localitate: "Olteni" },
  { judet: "Ilfov", localitate: "Ordoreanu" },
  { judet: "Ilfov", localitate: "Ostratu" },
  { judet: "Ilfov", localitate: "Petresti" },
  { judet: "Ilfov", localitate: "Tamasi" },
  { judet: "Ilfov", localitate: "Buda" },
  { judet: "Ilfov", localitate: "Creata" },
  { judet: "Ilfov", localitate: "Gagu" },
  { judet: "Ilfov", localitate: "Runcu" },
  { judet: "Ilfov", localitate: "Teghes" },
  { judet: "Ilfov", localitate: "Dragomiresti-Deal" },
  { judet: "Ilfov", localitate: "Zurbaua" },
  { judet: "Ilfov", localitate: "Cozieni" },
  { judet: "Ilfov", localitate: "Moara Domneasca" },
  { judet: "Ilfov", localitate: "Piteasca" },
  { judet: "Ilfov", localitate: "Sindrilita" },
  { judet: "Ilfov", localitate: "Catelu" },
  { judet: "Ilfov", localitate: "Manolache" },
  { judet: "Ilfov", localitate: "Sitaru" },
  { judet: "Ilfov", localitate: "Lipia" },
  { judet: "Ilfov", localitate: "Silistea Snagovului" },
  { judet: "Ilfov", localitate: "Santu-Floresti" },
  { judet: "Ilfov", localitate: "Alunisu" },
  { judet: "Ilfov", localitate: "Dumitrana" },
  { judet: "Ilfov", localitate: "Pruni" },
  { judet: "Ilfov", localitate: "Varteju" },
  { judet: "Ilfov", localitate: "Caciulati" },
  { judet: "Ilfov", localitate: "Balta Neagra" },
  { judet: "Ilfov", localitate: "Merii Petchii" },
  { judet: "Ilfov", localitate: "Micsunesti-Moara" },
  { judet: "Ilfov", localitate: "Micsunestii Mari" },
  { judet: "Ilfov", localitate: "Balteni" },
  { judet: "Ilfov", localitate: "Burias" },
  { judet: "Ilfov", localitate: "Maineasca" },
  { judet: "Ilfov", localitate: "Surlari" },
  { judet: "Ilfov", localitate: "Vanatori" },
  { judet: "Ilfov", localitate: "Ciofliceni" },
  { judet: "Ilfov", localitate: "Vladiceasca" },
  { judet: "Ilfov", localitate: "Ghermanesti" },
  { judet: "Ilfov", localitate: "Cretuleasca" },
  { judet: "Ilfov", localitate: "Stefanestii de Sus" },
  { judet: "Ilfov", localitate: "Dimieni" },
  { judet: "Ilfov", localitate: "Cretesti" },
  { judet: "Ilfov", localitate: "Sintesti" },
  { judet: "Maramures", localitate: "Baia Mare" },
  { judet: "Maramures", localitate: "Sighetu Marmatiei" },
  { judet: "Maramures", localitate: "Viseu de Sus" },
  { judet: "Maramures", localitate: "Borsa" },
  { judet: "Maramures", localitate: "Salistea de Sus" },
  { judet: "Maramures", localitate: "Targu Lapus" },
  { judet: "Maramures", localitate: "Baia Sprie" },
  { judet: "Maramures", localitate: "Seini" },
  { judet: "Maramures", localitate: "Cavnic" },
  { judet: "Maramures", localitate: "Ulmeni" },
  { judet: "Maramures", localitate: "Dragomiresti" },
  { judet: "Maramures", localitate: "Somcuta Mare" },
  { judet: "Maramures", localitate: "Tautii-Magheraus" },
  { judet: "Maramures", localitate: "Ardusat" },
  { judet: "Maramures", localitate: "Arinis" },
  { judet: "Maramures", localitate: "Asuaju de Sus" },
  { judet: "Maramures", localitate: "Baita de sub Codru" },
  { judet: "Maramures", localitate: "Baiut" },
  { judet: "Maramures", localitate: "Barsana" },
  { judet: "Maramures", localitate: "Basesti" },
  { judet: "Maramures", localitate: "Bicaz" },
  { judet: "Maramures", localitate: "Bistra" },
  { judet: "Maramures", localitate: "Bocicoiu Mare" },
  { judet: "Maramures", localitate: "Bogdan Voda" },
  { judet: "Maramures", localitate: "Boiu Mare" },
  { judet: "Maramures", localitate: "Botiza" },
  { judet: "Maramures", localitate: "Budesti" },
  { judet: "Maramures", localitate: "Calinesti" },
  { judet: "Maramures", localitate: "Campulung la Tisa" },
  { judet: "Maramures", localitate: "Cernesti" },
  { judet: "Maramures", localitate: "Cicarlau" },
  { judet: "Maramures", localitate: "Coas" },
  { judet: "Maramures", localitate: "Coltau" },
  { judet: "Maramures", localitate: "Copalnic-Manastur" },
  { judet: "Maramures", localitate: "Coroieni" },
  { judet: "Maramures", localitate: "Cupseni" },
  { judet: "Maramures", localitate: "Desesti" },
  { judet: "Maramures", localitate: "Dumbravita" },
  { judet: "Maramures", localitate: "Farcasa" },
  { judet: "Maramures", localitate: "Gardani" },
  { judet: "Maramures", localitate: "Giulesti" },
  { judet: "Maramures", localitate: "Grosi" },
  { judet: "Maramures", localitate: "Grosii Tiblesului" },
  { judet: "Maramures", localitate: "Ieud" },
  { judet: "Maramures", localitate: "Lapus" },
  { judet: "Maramures", localitate: "Leordina" },
  { judet: "Maramures", localitate: "Miresu Mare" },
  { judet: "Maramures", localitate: "Moisei" },
  { judet: "Maramures", localitate: "Oarta de Jos" },
  { judet: "Maramures", localitate: "Ocna Sugatag" },
  { judet: "Maramures", localitate: "Oncesti" },
  { judet: "Maramures", localitate: "Petrova" },
  { judet: "Maramures", localitate: "Poienile de sub Munte" },
  { judet: "Maramures", localitate: "Poienile Izei" },
  { judet: "Maramures", localitate: "Recea" },
  { judet: "Maramures", localitate: "Remetea Chioarului" },
  { judet: "Maramures", localitate: "Remeti" },
  { judet: "Maramures", localitate: "Repedea" },
  { judet: "Maramures", localitate: "Rona de Jos" },
  { judet: "Maramures", localitate: "Rona de Sus" },
  { judet: "Maramures", localitate: "Rozavlea" },
  { judet: "Maramures", localitate: "Ruscova" },
  { judet: "Maramures", localitate: "Sacalaseni" },
  { judet: "Maramures", localitate: "Sacel" },
  { judet: "Maramures", localitate: "Salsig" },
  { judet: "Maramures", localitate: "Sapanta" },
  { judet: "Maramures", localitate: "Sarasau" },
  { judet: "Maramures", localitate: "Satulung" },
  { judet: "Maramures", localitate: "Sieu" },
  { judet: "Maramures", localitate: "Sisesti" },
  { judet: "Maramures", localitate: "Stramtura" },
  { judet: "Maramures", localitate: "Suciu de Sus" },
  { judet: "Maramures", localitate: "Vadu Izei" },
  { judet: "Maramures", localitate: "Valea Chioarului" },
  { judet: "Maramures", localitate: "Vima Mica" },
  { judet: "Maramures", localitate: "Viseu de Jos" },
  { judet: "Maramures", localitate: "Baile Borsa" },
  { judet: "Maramures", localitate: "Blidari" },
  { judet: "Maramures", localitate: "Firiza" },
  { judet: "Maramures", localitate: "Valea Neagra" },
  { judet: "Maramures", localitate: "Chiuzbaia" },
  { judet: "Maramures", localitate: "Satu Nou de Sus" },
  { judet: "Maramures", localitate: "Tautii de Sus" },
  { judet: "Maramures", localitate: "Sabisa" },
  { judet: "Maramures", localitate: "Viile Apei" },
  { judet: "Maramures", localitate: "Iapa" },
  { judet: "Maramures", localitate: "Lazu Baciului" },
  { judet: "Maramures", localitate: "Sugau" },
  { judet: "Maramures", localitate: "Valea Cufundoasa" },
  { judet: "Maramures", localitate: "Valea Hotarului" },
  { judet: "Maramures", localitate: "Boiereni" },
  { judet: "Maramures", localitate: "Borcut" },
  { judet: "Maramures", localitate: "Cufoaia" },
  { judet: "Maramures", localitate: "Damacuseni" },
  { judet: "Maramures", localitate: "Dobricu Lapusului" },
  { judet: "Maramures", localitate: "Dumbrava" },
  { judet: "Maramures", localitate: "Fantanele" },
  { judet: "Maramures", localitate: "Groape" },
  { judet: "Maramures", localitate: "Inau" },
  { judet: "Maramures", localitate: "Razoare" },
  { judet: "Maramures", localitate: "Rogoz" },
  { judet: "Maramures", localitate: "Rohia" },
  { judet: "Maramures", localitate: "Stoiceni" },
  { judet: "Maramures", localitate: "Viseu de Mijloc" },
  { judet: "Maramures", localitate: "Ariesu de Camp" },
  { judet: "Maramures", localitate: "Coltirea" },
  { judet: "Maramures", localitate: "Rodina" },
  { judet: "Maramures", localitate: "Tamasesti" },
  { judet: "Maramures", localitate: "Asuaju de Jos" },
  { judet: "Maramures", localitate: "Urmenis" },
  { judet: "Maramures", localitate: "Poiana Botizii" },
  { judet: "Maramures", localitate: "Strambu-Baiut" },
  { judet: "Maramures", localitate: "Odesti" },
  { judet: "Maramures", localitate: "Saliste" },
  { judet: "Maramures", localitate: "Stremt" },
  { judet: "Maramures", localitate: "Nanesti" },
  { judet: "Maramures", localitate: "Ciuta" },
  { judet: "Maramures", localitate: "Corni" },
  { judet: "Maramures", localitate: "Crasna Viseului" },
  { judet: "Maramures", localitate: "Valea Viseului" },
  { judet: "Maramures", localitate: "Craciunesti" },
  { judet: "Maramures", localitate: "Lunca la Tisa" },
  { judet: "Maramures", localitate: "Tisa" },
  { judet: "Maramures", localitate: "Bocicoel" },
  { judet: "Maramures", localitate: "Francenii Boiului" },
  { judet: "Maramures", localitate: "Prislop" },
  { judet: "Maramures", localitate: "Romanesti" },
  { judet: "Maramures", localitate: "Sarbi (Budesti)" },
  { judet: "Maramures", localitate: "Cornesti" },
  { judet: "Maramures", localitate: "Valeni" },
  { judet: "Maramures", localitate: "Brebeni" },
  { judet: "Maramures", localitate: "Ciocotis" },
  { judet: "Maramures", localitate: "Fanate" },
  { judet: "Maramures", localitate: "Izvoarele" },
  { judet: "Maramures", localitate: "Magureni" },
  { judet: "Maramures", localitate: "Trestia" },
  { judet: "Maramures", localitate: "Bargau" },
  { judet: "Maramures", localitate: "Handalu Ilbei" },
  { judet: "Maramures", localitate: "Ilba" },
  { judet: "Maramures", localitate: "Berinta" },
  { judet: "Maramures", localitate: "Carpinis" },
  { judet: "Maramures", localitate: "Copalnic" },
  { judet: "Maramures", localitate: "Copalnic-Deal" },
  { judet: "Maramures", localitate: "Curtuiusu Mic" },
  { judet: "Maramures", localitate: "Fauresti" },
  { judet: "Maramures", localitate: "Laschia" },
  { judet: "Maramures", localitate: "Preluca Noua" },
  { judet: "Maramures", localitate: "Preluca Veche" },
  { judet: "Maramures", localitate: "Rusor" },
  { judet: "Maramures", localitate: "Vad" },
  { judet: "Maramures", localitate: "Valenii Lapusului" },
  { judet: "Maramures", localitate: "Baba" },
  { judet: "Maramures", localitate: "Dealu Mare" },
  { judet: "Maramures", localitate: "Draghia" },
  { judet: "Maramures", localitate: "Costeni" },
  { judet: "Maramures", localitate: "Libotin" },
  { judet: "Maramures", localitate: "Ungureni" },
  { judet: "Maramures", localitate: "Harnicesti" },
  { judet: "Maramures", localitate: "Mara" },
  { judet: "Maramures", localitate: "Carbunari" },
  { judet: "Maramures", localitate: "Chechis" },
  { judet: "Maramures", localitate: "Rus" },
  { judet: "Maramures", localitate: "Sindresti" },
  { judet: "Maramures", localitate: "Unguras" },
  { judet: "Maramures", localitate: "Buzesti" },
  { judet: "Maramures", localitate: "Sarbi (Farcasa)" },
  { judet: "Maramures", localitate: "Tamaia" },
  { judet: "Maramures", localitate: "Berbesti" },
  { judet: "Maramures", localitate: "Feresti" },
  { judet: "Maramures", localitate: "Manastirea" },
  { judet: "Maramures", localitate: "Ocolis" },
  { judet: "Maramures", localitate: "Satu Nou de Jos" },
  { judet: "Maramures", localitate: "Danestii Chioarului" },
  { judet: "Maramures", localitate: "Iadara" },
  { judet: "Maramures", localitate: "Lucacesti" },
  { judet: "Maramures", localitate: "Remeti pe Somes" },
  { judet: "Maramures", localitate: "Stejera" },
  { judet: "Maramures", localitate: "Tulghies" },
  { judet: "Maramures", localitate: "Oarta de Sus" },
  { judet: "Maramures", localitate: "Ortita" },
  { judet: "Maramures", localitate: "Breb" },
  { judet: "Maramures", localitate: "Hoteni" },
  { judet: "Maramures", localitate: "Sat-Sugatag" },
  { judet: "Maramures", localitate: "Bozanta Mica" },
  { judet: "Maramures", localitate: "Lapusel" },
  { judet: "Maramures", localitate: "Mocira" },
  { judet: "Maramures", localitate: "Sasar" },
  { judet: "Maramures", localitate: "Berchez" },
  { judet: "Maramures", localitate: "Berchezoaia" },
  { judet: "Maramures", localitate: "Posta" },
  { judet: "Maramures", localitate: "Remecioara" },
  { judet: "Maramures", localitate: "Piatra" },
  { judet: "Maramures", localitate: "Teceu Mic" },
  { judet: "Maramures", localitate: "Costiui" },
  { judet: "Maramures", localitate: "Salta" },
  { judet: "Maramures", localitate: "Ariesu de Padure" },
  { judet: "Maramures", localitate: "Fersig" },
  { judet: "Maramures", localitate: "Finteusu Mic" },
  { judet: "Maramures", localitate: "Hideaga" },
  { judet: "Maramures", localitate: "Mogosesti" },
  { judet: "Maramures", localitate: "Pribilesti" },
  { judet: "Maramures", localitate: "Catalina" },
  { judet: "Maramures", localitate: "Coruia" },
  { judet: "Maramures", localitate: "Culcea" },
  { judet: "Maramures", localitate: "Intrerauri" },
  { judet: "Maramures", localitate: "Glod" },
  { judet: "Maramures", localitate: "Slatioara" },
  { judet: "Maramures", localitate: "Larga" },
  { judet: "Maramures", localitate: "Suciu de Jos" },
  { judet: "Maramures", localitate: "Bontaieni" },
  { judet: "Maramures", localitate: "Cetatele" },
  { judet: "Maramures", localitate: "Danesti" },
  { judet: "Maramures", localitate: "Negreia" },
  { judet: "Maramures", localitate: "Plopis" },
  { judet: "Maramures", localitate: "Surdesti" },
  { judet: "Maramures", localitate: "Buciumi" },
  { judet: "Maramures", localitate: "Buteasa" },
  { judet: "Maramures", localitate: "Ciolt" },
  { judet: "Maramures", localitate: "Codru Butesii" },
  { judet: "Maramures", localitate: "Finteusu Mare" },
  { judet: "Maramures", localitate: "Hovrila" },
  { judet: "Maramures", localitate: "Valenii Somcutei" },
  { judet: "Maramures", localitate: "Baita" },
  { judet: "Maramures", localitate: "Bozanta Mare" },
  { judet: "Maramures", localitate: "Busag" },
  { judet: "Maramures", localitate: "Merisor" },
  { judet: "Maramures", localitate: "Nistru" },
  { judet: "Maramures", localitate: "Ulmoasa" },
  { judet: "Maramures", localitate: "Arduzel" },
  { judet: "Maramures", localitate: "Chelinta" },
  { judet: "Maramures", localitate: "Manau" },
  { judet: "Maramures", localitate: "Somes-Uileac" },
  { judet: "Maramures", localitate: "Tohat" },
  { judet: "Maramures", localitate: "Ticau" },
  { judet: "Maramures", localitate: "Vicea" },
  { judet: "Maramures", localitate: "Valea Stejarului" },
  { judet: "Maramures", localitate: "Curtuiusu Mare" },
  { judet: "Maramures", localitate: "Durusa" },
  { judet: "Maramures", localitate: "Fericea" },
  { judet: "Maramures", localitate: "Mesteacan" },
  { judet: "Maramures", localitate: "Varai" },
  { judet: "Maramures", localitate: "Aspra" },
  { judet: "Maramures", localitate: "Dealu Corbului" },
  { judet: "Maramures", localitate: "Jugastreni" },
  { judet: "Maramures", localitate: "Peteritea" },
  { judet: "Maramures", localitate: "Salnita" },
  { judet: "Maramures", localitate: "Vima Mare" },
  { judet: "Mehedinti", localitate: "Drobeta Turnu Severin" },
  { judet: "Mehedinti", localitate: "Orsova" },
  { judet: "Mehedinti", localitate: "Strehaia" },
  { judet: "Mehedinti", localitate: "Vanju Mare" },
  { judet: "Mehedinti", localitate: "Baia de Arama" },
  { judet: "Mehedinti", localitate: "Bala" },
  { judet: "Mehedinti", localitate: "Balta" },
  { judet: "Mehedinti", localitate: "Balacita" },
  { judet: "Mehedinti", localitate: "Bacles" },
  { judet: "Mehedinti", localitate: "Balvanesti" },
  { judet: "Mehedinti", localitate: "Branistea" },
  { judet: "Mehedinti", localitate: "Breznita-Motru" },
  { judet: "Mehedinti", localitate: "Breznita-Ocol" },
  { judet: "Mehedinti", localitate: "Brosteni" },
  { judet: "Mehedinti", localitate: "Burila Mare" },
  { judet: "Mehedinti", localitate: "Butoiesti" },
  { judet: "Mehedinti", localitate: "Cazanesti" },
  { judet: "Mehedinti", localitate: "Ciresu" },
  { judet: "Mehedinti", localitate: "Corcova" },
  { judet: "Mehedinti", localitate: "Corlatel" },
  { judet: "Mehedinti", localitate: "Cujmir" },
  { judet: "Mehedinti", localitate: "Darvari" },
  { judet: "Mehedinti", localitate: "Devesel" },
  { judet: "Mehedinti", localitate: "Dubova" },
  { judet: "Mehedinti", localitate: "Dumbrava" },
  { judet: "Mehedinti", localitate: "Eselnita" },
  { judet: "Mehedinti", localitate: "Floresti" },
  { judet: "Mehedinti", localitate: "Garla Mare" },
  { judet: "Mehedinti", localitate: "Godeanu" },
  { judet: "Mehedinti", localitate: "Gogosu" },
  { judet: "Mehedinti", localitate: "Greci" },
  { judet: "Mehedinti", localitate: "Grozesti" },
  { judet: "Mehedinti", localitate: "Gruia" },
  { judet: "Mehedinti", localitate: "Hinova" },
  { judet: "Mehedinti", localitate: "Husnicioara" },
  { judet: "Mehedinti", localitate: "Ilovat" },
  { judet: "Mehedinti", localitate: "Ilovita" },
  { judet: "Mehedinti", localitate: "Isverna" },
  { judet: "Mehedinti", localitate: "Izvoru Barzii" },
  { judet: "Mehedinti", localitate: "Jiana" },
  { judet: "Mehedinti", localitate: "Livezile" },
  { judet: "Mehedinti", localitate: "Malovat" },
  { judet: "Mehedinti", localitate: "Obarsia de Camp" },
  { judet: "Mehedinti", localitate: "Obarsia-Closani" },
  { judet: "Mehedinti", localitate: "Oprisor" },
  { judet: "Mehedinti", localitate: "Padina Mare" },
  { judet: "Mehedinti", localitate: "Patulele" },
  { judet: "Mehedinti", localitate: "Podeni" },
  { judet: "Mehedinti", localitate: "Ponoarele" },
  { judet: "Mehedinti", localitate: "Poroina Mare" },
  { judet: "Mehedinti", localitate: "Pristol" },
  { judet: "Mehedinti", localitate: "Prunisor" },
  { judet: "Mehedinti", localitate: "Punghina" },
  { judet: "Mehedinti", localitate: "Rogova" },
  { judet: "Mehedinti", localitate: "Salcia" },
  { judet: "Mehedinti", localitate: "Sisesti" },
  { judet: "Mehedinti", localitate: "Stangaceaua" },
  { judet: "Mehedinti", localitate: "Svinita" },
  { judet: "Mehedinti", localitate: "Simian" },
  { judet: "Mehedinti", localitate: "Sovarna" },
  { judet: "Mehedinti", localitate: "Tamna" },
  { judet: "Mehedinti", localitate: "Vanatori" },
  { judet: "Mehedinti", localitate: "Vanjulet" },
  { judet: "Mehedinti", localitate: "Vladaia" },
  { judet: "Mehedinti", localitate: "Voloiac" },
  { judet: "Mehedinti", localitate: "Vrata" },
  { judet: "Mehedinti", localitate: "Dudasu Schelei" },
  { judet: "Mehedinti", localitate: "Gura Vaii" },
  { judet: "Mehedinti", localitate: "Schela Cladovei" },
  { judet: "Mehedinti", localitate: "Bratilovu" },
  { judet: "Mehedinti", localitate: "Brebina" },
  { judet: "Mehedinti", localitate: "Dealu Mare" },
  { judet: "Mehedinti", localitate: "Marasesti" },
  { judet: "Mehedinti", localitate: "Negoesti" },
  { judet: "Mehedinti", localitate: "Pistrita" },
  { judet: "Mehedinti", localitate: "Stanesti" },
  { judet: "Mehedinti", localitate: "Titerlesti" },
  { judet: "Mehedinti", localitate: "Ciochiuta" },
  { judet: "Mehedinti", localitate: "Comanda" },
  { judet: "Mehedinti", localitate: "Hurducesti" },
  { judet: "Mehedinti", localitate: "Lunca Banului" },
  { judet: "Mehedinti", localitate: "Menti" },
  { judet: "Mehedinti", localitate: "Motruleni" },
  { judet: "Mehedinti", localitate: "Slatinicu Mare" },
  { judet: "Mehedinti", localitate: "Slatinicu Mic" },
  { judet: "Mehedinti", localitate: "Stancesti" },
  { judet: "Mehedinti", localitate: "Bucura" },
  { judet: "Mehedinti", localitate: "Nicolae Balcescu" },
  { judet: "Mehedinti", localitate: "Orevita Mare" },
  { judet: "Mehedinti", localitate: "Traian" },
  { judet: "Mehedinti", localitate: "Portile de Fier II" },
  { judet: "Mehedinti", localitate: "Bala de Sus" },
  { judet: "Mehedinti", localitate: "Bratesul" },
  { judet: "Mehedinti", localitate: "Brativoesti" },
  { judet: "Mehedinti", localitate: "Campu Mare" },
  { judet: "Mehedinti", localitate: "Carsu" },
  { judet: "Mehedinti", localitate: "Comanesti" },
  { judet: "Mehedinti", localitate: "Crainici" },
  { judet: "Mehedinti", localitate: "Dalma" },
  { judet: "Mehedinti", localitate: "Iupca" },
  { judet: "Mehedinti", localitate: "Molani" },
  { judet: "Mehedinti", localitate: "Rudina" },
  { judet: "Mehedinti", localitate: "Runcusoru" },
  { judet: "Mehedinti", localitate: "Sardanesti" },
  { judet: "Mehedinti", localitate: "Vidimiresti" },
  { judet: "Mehedinti", localitate: "Coada Cornetului" },
  { judet: "Mehedinti", localitate: "Costesti" },
  { judet: "Mehedinti", localitate: "Gornovita" },
  { judet: "Mehedinti", localitate: "Nevatu" },
  { judet: "Mehedinti", localitate: "Prejna" },
  { judet: "Mehedinti", localitate: "Sfodea" },
  { judet: "Mehedinti", localitate: "Dobra" },
  { judet: "Mehedinti", localitate: "Gvardinita" },
  { judet: "Mehedinti", localitate: "Corzu" },
  { judet: "Mehedinti", localitate: "Giura" },
  { judet: "Mehedinti", localitate: "Petra" },
  { judet: "Mehedinti", localitate: "Podu Grosului" },
  { judet: "Mehedinti", localitate: "Selistiuta" },
  { judet: "Mehedinti", localitate: "Smadovita" },
  { judet: "Mehedinti", localitate: "Balvanestii de Jos" },
  { judet: "Mehedinti", localitate: "Calinestii de Jos" },
  { judet: "Mehedinti", localitate: "Calinestii de Sus" },
  { judet: "Mehedinti", localitate: "Parlagele" },
  { judet: "Mehedinti", localitate: "Cosovat" },
  { judet: "Mehedinti", localitate: "Deleni" },
  { judet: "Mehedinti", localitate: "Fauroaia" },
  { judet: "Mehedinti", localitate: "Plai" },
  { judet: "Mehedinti", localitate: "Talapanu" },
  { judet: "Mehedinti", localitate: "Valea Teiului" },
  { judet: "Mehedinti", localitate: "Jidostita" },
  { judet: "Mehedinti", localitate: "Magheru" },
  { judet: "Mehedinti", localitate: "Susita (Breznita-Ocol)" },
  { judet: "Mehedinti", localitate: "Capatanesti" },
  { judet: "Mehedinti", localitate: "Luncsoara" },
  { judet: "Mehedinti", localitate: "Lupsa de Jos" },
  { judet: "Mehedinti", localitate: "Lupsa de Sus" },
  { judet: "Mehedinti", localitate: "Meris" },
  { judet: "Mehedinti", localitate: "Crivina" },
  { judet: "Mehedinti", localitate: "Izvoru Frumos" },
  { judet: "Mehedinti", localitate: "Tiganasi" },
  { judet: "Mehedinti", localitate: "Vrancea" },
  { judet: "Mehedinti", localitate: "Arginesti" },
  { judet: "Mehedinti", localitate: "Buicesti" },
  { judet: "Mehedinti", localitate: "Gura Motrului" },
  { judet: "Mehedinti", localitate: "Jugastru" },
  { judet: "Mehedinti", localitate: "Pluta" },
  { judet: "Mehedinti", localitate: "Radutesti" },
  { judet: "Mehedinti", localitate: "Tantaru" },
  { judet: "Mehedinti", localitate: "Ercea" },
  { judet: "Mehedinti", localitate: "Garbovatu de Sus" },
  { judet: "Mehedinti", localitate: "Govodarva" },
  { judet: "Mehedinti", localitate: "Ilovu" },
  { judet: "Mehedinti", localitate: "Jignita" },
  { judet: "Mehedinti", localitate: "Paltinisu" },
  { judet: "Mehedinti", localitate: "Poiana" },
  { judet: "Mehedinti", localitate: "Rosia" },
  { judet: "Mehedinti", localitate: "Severinesti" },
  { judet: "Mehedinti", localitate: "Suharu" },
  { judet: "Mehedinti", localitate: "Valea Cosustei" },
  { judet: "Mehedinti", localitate: "Bunoaica" },
  { judet: "Mehedinti", localitate: "Jupanesti" },
  { judet: "Mehedinti", localitate: "Negrusa" },
  { judet: "Mehedinti", localitate: "Breta" },
  { judet: "Mehedinti", localitate: "Cernaia" },
  { judet: "Mehedinti", localitate: "Cordun" },
  { judet: "Mehedinti", localitate: "Croica" },
  { judet: "Mehedinti", localitate: "Garbovatu de Jos" },
  { judet: "Mehedinti", localitate: "Imoasa" },
  { judet: "Mehedinti", localitate: "Jirov" },
  { judet: "Mehedinti", localitate: "Maru Rosu" },
  { judet: "Mehedinti", localitate: "Parvulesti" },
  { judet: "Mehedinti", localitate: "Puscasu" },
  { judet: "Mehedinti", localitate: "Stejaru" },
  { judet: "Mehedinti", localitate: "Vladasesti" },
  { judet: "Mehedinti", localitate: "Valea Anilor" },
  { judet: "Mehedinti", localitate: "Aurora" },
  { judet: "Mehedinti", localitate: "Cujmiru Mic" },
  { judet: "Mehedinti", localitate: "Gemeni" },
  { judet: "Mehedinti", localitate: "Batoti" },
  { judet: "Mehedinti", localitate: "Bistretu" },
  { judet: "Mehedinti", localitate: "Dunarea Mica" },
  { judet: "Mehedinti", localitate: "Scapau" },
  { judet: "Mehedinti", localitate: "Tismana" },
  { judet: "Mehedinti", localitate: "Baia Noua" },
  { judet: "Mehedinti", localitate: "Eibenthal" },
  { judet: "Mehedinti", localitate: "Albulesti" },
  { judet: "Mehedinti", localitate: "Bragleasa" },
  { judet: "Mehedinti", localitate: "Dumbrava de Jos" },
  { judet: "Mehedinti", localitate: "Dumbrava de Mijloc" },
  { judet: "Mehedinti", localitate: "Dumbrava de Sus" },
  { judet: "Mehedinti", localitate: "Golineasa" },
  { judet: "Mehedinti", localitate: "Higiu" },
  { judet: "Mehedinti", localitate: "Rocsoreni" },
  { judet: "Mehedinti", localitate: "Valea Marcului" },
  { judet: "Mehedinti", localitate: "Varodia" },
  { judet: "Mehedinti", localitate: "Vladica" },
  { judet: "Mehedinti", localitate: "Copacioasa" },
  { judet: "Mehedinti", localitate: "Gardoaia" },
  { judet: "Mehedinti", localitate: "Livezi" },
  { judet: "Mehedinti", localitate: "Mosneni" },
  { judet: "Mehedinti", localitate: "Pesteana" },
  { judet: "Mehedinti", localitate: "Pestenuta" },
  { judet: "Mehedinti", localitate: "Stroesti" },
  { judet: "Mehedinti", localitate: "Zegujani" },
  { judet: "Mehedinti", localitate: "Marga" },
  { judet: "Mehedinti", localitate: "Paunesti" },
  { judet: "Mehedinti", localitate: "Siroca" },
  { judet: "Mehedinti", localitate: "Balta Verde" },
  { judet: "Mehedinti", localitate: "Burila Mica" },
  { judet: "Mehedinti", localitate: "Ostrovu Mare" },
  { judet: "Mehedinti", localitate: "Baltanele (Greci)" },
  { judet: "Mehedinti", localitate: "Blidaru" },
  { judet: "Mehedinti", localitate: "Salatruc" },
  { judet: "Mehedinti", localitate: "Valea Petrii" },
  { judet: "Mehedinti", localitate: "Visina" },
  { judet: "Mehedinti", localitate: "Carceni" },
  { judet: "Mehedinti", localitate: "Pasarani" },
  { judet: "Mehedinti", localitate: "Susita (Grozesti)" },
  { judet: "Mehedinti", localitate: "Izvoarele" },
  { judet: "Mehedinti", localitate: "Poiana Gruii" },
  { judet: "Mehedinti", localitate: "Bistrita" },
  { judet: "Mehedinti", localitate: "Carjei" },
  { judet: "Mehedinti", localitate: "Ostrovu Corbului" },
  { judet: "Mehedinti", localitate: "Alunisul" },
  { judet: "Mehedinti", localitate: "Baditesti" },
  { judet: "Mehedinti", localitate: "Borogea" },
  { judet: "Mehedinti", localitate: "Celnata" },
  { judet: "Mehedinti", localitate: "Dumbravita" },
  { judet: "Mehedinti", localitate: "Marmanu" },
  { judet: "Mehedinti", localitate: "Opranesti" },
  { judet: "Mehedinti", localitate: "Peri" },
  { judet: "Mehedinti", localitate: "Priboiesti" },
  { judet: "Mehedinti", localitate: "Selisteni" },
  { judet: "Mehedinti", localitate: "Budanesti" },
  { judet: "Mehedinti", localitate: "Cracu Lung" },
  { judet: "Mehedinti", localitate: "Dalbocita" },
  { judet: "Mehedinti", localitate: "Firizu" },
  { judet: "Mehedinti", localitate: "Racova" },
  { judet: "Mehedinti", localitate: "Bahna" },
  { judet: "Mehedinti", localitate: "Moisesti" },
  { judet: "Mehedinti", localitate: "Busesti" },
  { judet: "Mehedinti", localitate: "Cerna-Varf" },
  { judet: "Mehedinti", localitate: "Draghesti" },
  { judet: "Mehedinti", localitate: "Giurgiani" },
  { judet: "Mehedinti", localitate: "Nadanova" },
  { judet: "Mehedinti", localitate: "Selistea" },
  { judet: "Mehedinti", localitate: "Turtaba" },
  { judet: "Mehedinti", localitate: "Balotesti" },
  { judet: "Mehedinti", localitate: "Halanga" },
  { judet: "Mehedinti", localitate: "Putinei" },
  { judet: "Mehedinti", localitate: "Rascolesti" },
  { judet: "Mehedinti", localitate: "Schinteiesti" },
  { judet: "Mehedinti", localitate: "Schitu Topolnitei" },
  { judet: "Mehedinti", localitate: "Cioroboreni" },
  { judet: "Mehedinti", localitate: "Danceu" },
  { judet: "Mehedinti", localitate: "Jiana Mare" },
  { judet: "Mehedinti", localitate: "Jiana Veche" },
  { judet: "Mehedinti", localitate: "Izvoralu de Jos" },
  { judet: "Mehedinti", localitate: "Izvoru Anestilor" },
  { judet: "Mehedinti", localitate: "Petris" },
  { judet: "Mehedinti", localitate: "Stefan Odobleja" },
  { judet: "Mehedinti", localitate: "23 August" },
  { judet: "Mehedinti", localitate: "Barda" },
  { judet: "Mehedinti", localitate: "Bobaita" },
  { judet: "Mehedinti", localitate: "Colibasi" },
  { judet: "Mehedinti", localitate: "Lazu" },
  { judet: "Mehedinti", localitate: "Negresti" },
  { judet: "Mehedinti", localitate: "Izimsa" },
  { judet: "Mehedinti", localitate: "Godeanu (Obarsia-Closani)" },
  { judet: "Mehedinti", localitate: "Prisaceaua" },
  { judet: "Mehedinti", localitate: "Padina" },
  { judet: "Mehedinti", localitate: "Biban" },
  { judet: "Mehedinti", localitate: "Iablanita" },
  { judet: "Mehedinti", localitate: "Olteanca" },
  { judet: "Mehedinti", localitate: "Padina Mica" },
  { judet: "Mehedinti", localitate: "Slasoma" },
  { judet: "Mehedinti", localitate: "Viasu" },
  { judet: "Mehedinti", localitate: "Gornenti" },
  { judet: "Mehedinti", localitate: "Malarisca" },
  { judet: "Mehedinti", localitate: "Baluta" },
  { judet: "Mehedinti", localitate: "Baraiacu" },
  { judet: "Mehedinti", localitate: "Branzeni" },
  { judet: "Mehedinti", localitate: "Buicani" },
  { judet: "Mehedinti", localitate: "Ceptureni" },
  { judet: "Mehedinti", localitate: "Cracu Muntelui" },
  { judet: "Mehedinti", localitate: "Delureni" },
  { judet: "Mehedinti", localitate: "Gardaneasa" },
  { judet: "Mehedinti", localitate: "Gheorghesti" },
  { judet: "Mehedinti", localitate: "Ludu" },
  { judet: "Mehedinti", localitate: "Proitesti" },
  { judet: "Mehedinti", localitate: "Raiculesti" },
  { judet: "Mehedinti", localitate: "Sipotu (Ponoarele)" },
  { judet: "Mehedinti", localitate: "Valea Ursului (Ponoarele)" },
  { judet: "Mehedinti", localitate: "Fantanile Negre" },
  { judet: "Mehedinti", localitate: "Stignita" },
  { judet: "Mehedinti", localitate: "Sipotu (Poroina Mare)" },
  { judet: "Mehedinti", localitate: "Cozia" },
  { judet: "Mehedinti", localitate: "Arvatesti" },
  { judet: "Mehedinti", localitate: "Balota" },
  { judet: "Mehedinti", localitate: "Baltanele (Prunisor)" },
  { judet: "Mehedinti", localitate: "Cervenita" },
  { judet: "Mehedinti", localitate: "Dragotesti" },
  { judet: "Mehedinti", localitate: "Fantana Domneasca" },
  { judet: "Mehedinti", localitate: "Garnita" },
  { judet: "Mehedinti", localitate: "Ghelmegioaia" },
  { judet: "Mehedinti", localitate: "Gutu" },
  { judet: "Mehedinti", localitate: "Igiroasa" },
  { judet: "Mehedinti", localitate: "Lumnic" },
  { judet: "Mehedinti", localitate: "Mijarca" },
  { judet: "Mehedinti", localitate: "Prunaru" },
  { judet: "Mehedinti", localitate: "Zegaia" },
  { judet: "Mehedinti", localitate: "Cearangu" },
  { judet: "Mehedinti", localitate: "Drincea" },
  { judet: "Mehedinti", localitate: "Magurele" },
  { judet: "Mehedinti", localitate: "Recea" },
  { judet: "Mehedinti", localitate: "Satu Nou" },
  { judet: "Mehedinti", localitate: "Poroinita" },
  { judet: "Mehedinti", localitate: "Barlogeni" },
  { judet: "Mehedinti", localitate: "Breznicioara" },
  { judet: "Mehedinti", localitate: "Ceranganul" },
  { judet: "Mehedinti", localitate: "Fata Motrului" },
  { judet: "Mehedinti", localitate: "Posta Veche" },
  { judet: "Mehedinti", localitate: "Satu Mare" },
  { judet: "Mehedinti", localitate: "Tarsa" },
  { judet: "Mehedinti", localitate: "Cerneti" },
  { judet: "Mehedinti", localitate: "Dedovita Noua" },
  { judet: "Mehedinti", localitate: "Dedovita Veche" },
  { judet: "Mehedinti", localitate: "Dudasu" },
  { judet: "Mehedinti", localitate: "Erghevita" },
  { judet: "Mehedinti", localitate: "Poroina" },
  { judet: "Mehedinti", localitate: "Valea Copcii" },
  { judet: "Mehedinti", localitate: "Caramidaru" },
  { judet: "Mehedinti", localitate: "Ciovarnasani" },
  { judet: "Mehedinti", localitate: "Cocorova" },
  { judet: "Mehedinti", localitate: "Craguesti" },
  { judet: "Mehedinti", localitate: "Noaptesa" },
  { judet: "Mehedinti", localitate: "Ohaba" },
  { judet: "Mehedinti", localitate: "Studina" },
  { judet: "Mehedinti", localitate: "Adunatii Teiului" },
  { judet: "Mehedinti", localitate: "Boceni" },
  { judet: "Mehedinti", localitate: "Colaret" },
  { judet: "Mehedinti", localitate: "Cremenea" },
  { judet: "Mehedinti", localitate: "Fata Cremenii" },
  { judet: "Mehedinti", localitate: "Izvoralu" },
  { judet: "Mehedinti", localitate: "Manu" },
  { judet: "Mehedinti", localitate: "Pavat" },
  { judet: "Mehedinti", localitate: "Plopi" },
  { judet: "Mehedinti", localitate: "Valea Ursului (Tamna)" },
  { judet: "Mehedinti", localitate: "Goanta" },
  { judet: "Mehedinti", localitate: "Rosiori" },
  { judet: "Mehedinti", localitate: "Hotarani" },
  { judet: "Mehedinti", localitate: "Almajel" },
  { judet: "Mehedinti", localitate: "Scorila" },
  { judet: "Mehedinti", localitate: "Stircovita" },
  { judet: "Mehedinti", localitate: "Cotoroaia" },
  { judet: "Mehedinti", localitate: "Lac" },
  { judet: "Mehedinti", localitate: "Ruptura" },
  { judet: "Mehedinti", localitate: "Sperlesti" },
  { judet: "Mehedinti", localitate: "Titirigi" },
  { judet: "Mehedinti", localitate: "Valea Buna" },
  { judet: "Mehedinti", localitate: "Voloicel" },
  { judet: "Mures", localitate: "Targu Mures" },
  { judet: "Mures", localitate: "Reghin" },
  { judet: "Mures", localitate: "Sighisoara" },
  { judet: "Mures", localitate: "Ludus" },
  { judet: "Mures", localitate: "Deda" },
  { judet: "Mures", localitate: "Mihesu de Campie" },
  { judet: "Mures", localitate: "Sarmasu" },
  { judet: "Mures", localitate: "Sovata" },
  { judet: "Mures", localitate: "Gurghiu" },
  { judet: "Mures", localitate: "Corunca" },
  { judet: "Mures", localitate: "Acatari" },
  { judet: "Mures", localitate: "Adamus" },
  { judet: "Mures", localitate: "Albesti" },
  { judet: "Mures", localitate: "Alunis" },
  { judet: "Mures", localitate: "Apold" },
  { judet: "Mures", localitate: "Atintis" },
  { judet: "Mures", localitate: "Bahnea" },
  { judet: "Mures", localitate: "Band" },
  { judet: "Mures", localitate: "Batos" },
  { judet: "Mures", localitate: "Bagaciu" },
  { judet: "Mures", localitate: "Balauseri" },
  { judet: "Mures", localitate: "Beica de Jos" },
  { judet: "Mures", localitate: "Bereni" },
  { judet: "Mures", localitate: "Bichis" },
  { judet: "Mures", localitate: "Bogata" },
  { judet: "Mures", localitate: "Brancovenesti" },
  { judet: "Mures", localitate: "Breaza" },
  { judet: "Mures", localitate: "Ceuasu de Campie" },
  { judet: "Mures", localitate: "Chetani" },
  { judet: "Mures", localitate: "Chiheru de Jos" },
  { judet: "Mures", localitate: "Coroisanmartin" },
  { judet: "Mures", localitate: "Cozma" },
  { judet: "Mures", localitate: "Craciunesti" },
  { judet: "Mures", localitate: "Craiesti" },
  { judet: "Mures", localitate: "Cristesti" },
  { judet: "Mures", localitate: "Cucerdea" },
  { judet: "Mures", localitate: "Cuci" },
  { judet: "Mures", localitate: "Danes" },
  { judet: "Mures", localitate: "Eremitu" },
  { judet: "Mures", localitate: "Ernei" },
  { judet: "Mures", localitate: "Faragau" },
  { judet: "Mures", localitate: "Fantanele" },
  { judet: "Mures", localitate: "Galesti" },
  { judet: "Mures", localitate: "Ganesti" },
  { judet: "Mures", localitate: "Gheorghe Doja" },
  { judet: "Mures", localitate: "Ghindari" },
  { judet: "Mures", localitate: "Glodeni" },
  { judet: "Mures", localitate: "Gornesti" },
  { judet: "Mures", localitate: "Grebenisu de Campie" },
  { judet: "Mures", localitate: "Hodac" },
  { judet: "Mures", localitate: "Hodosa" },
  { judet: "Mures", localitate: "Ibanesti" },
  { judet: "Mures", localitate: "Iclanzel" },
  { judet: "Mures", localitate: "Ideciu de Jos" },
  { judet: "Mures", localitate: "Livezeni" },
  { judet: "Mures", localitate: "Lunca" },
  { judet: "Mures", localitate: "Lunca Bradului" },
  { judet: "Mures", localitate: "Madaras" },
  { judet: "Mures", localitate: "Magherani" },
  { judet: "Mures", localitate: "Mica" },
  { judet: "Mures", localitate: "Nades" },
  { judet: "Mures", localitate: "Neaua" },
  { judet: "Mures", localitate: "Ogra" },
  { judet: "Mures", localitate: "Papiu Ilarian" },
  { judet: "Mures", localitate: "Panet" },
  { judet: "Mures", localitate: "Pasareni" },
  { judet: "Mures", localitate: "Petelea" },
  { judet: "Mures", localitate: "Pogaceaua" },
  { judet: "Mures", localitate: "Raciu" },
  { judet: "Mures", localitate: "Rastolita" },
  { judet: "Mures", localitate: "Rusii-Munti" },
  { judet: "Mures", localitate: "Saschiz" },
  { judet: "Mures", localitate: "Sancraiu de Mures" },
  { judet: "Mures", localitate: "Sangeorgiu de Mures" },
  { judet: "Mures", localitate: "Sanger" },
  { judet: "Mures", localitate: "Sanpaul" },
  { judet: "Mures", localitate: "Sanpetru de Campie" },
  { judet: "Mures", localitate: "Santana de Mures" },
  { judet: "Mures", localitate: "Solovastru" },
  { judet: "Mures", localitate: "Stanceni" },
  { judet: "Mures", localitate: "Suplac" },
  { judet: "Mures", localitate: "Suseni" },
  { judet: "Mures", localitate: "Saulia" },
  { judet: "Mures", localitate: "Sincai" },
  { judet: "Mures", localitate: "Taureni" },
  { judet: "Mures", localitate: "Valea Larga" },
  { judet: "Mures", localitate: "Vargata" },
  { judet: "Mures", localitate: "Vanatori" },
  { judet: "Mures", localitate: "Vatava" },
  { judet: "Mures", localitate: "Vetca" },
  { judet: "Mures", localitate: "Viisoara" },
  { judet: "Mures", localitate: "Voivodeni" },
  { judet: "Mures", localitate: "Zagar" },
  { judet: "Mures", localitate: "Zau de Campie" },
  { judet: "Mures", localitate: "Ungheni" },
  { judet: "Mures", localitate: "Tarnaveni" },
  { judet: "Mures", localitate: "Iernut" },
  { judet: "Mures", localitate: "Miercurea Nirajului" },
  { judet: "Mures", localitate: "Sangeorgiu de Padure" },
  { judet: "Mures", localitate: "Vidrasau" },
  { judet: "Mures", localitate: "Mureseni" },
  { judet: "Mures", localitate: "Remetea" },
  { judet: "Mures", localitate: "Cipau" },
  { judet: "Mures", localitate: "Deag" },
  { judet: "Mures", localitate: "Lechinta" },
  { judet: "Mures", localitate: "Oarba de Mures" },
  { judet: "Mures", localitate: "Porumbac" },
  { judet: "Mures", localitate: "Racamet" },
  { judet: "Mures", localitate: "Salcud" },
  { judet: "Mures", localitate: "Sfantu Gheorghe" },
  { judet: "Mures", localitate: "Avramesti" },
  { judet: "Mures", localitate: "Cioarga" },
  { judet: "Mures", localitate: "Ciurgau" },
  { judet: "Mures", localitate: "Fundatura" },
  { judet: "Mures", localitate: "Gheja" },
  { judet: "Mures", localitate: "Rosiori" },
  { judet: "Mures", localitate: "Apalina" },
  { judet: "Mures", localitate: "Iernuteni" },
  { judet: "Mures", localitate: "Angofa" },
  { judet: "Mures", localitate: "Aurel Vlaicu" },
  { judet: "Mures", localitate: "Hetiur" },
  { judet: "Mures", localitate: "Rora" },
  { judet: "Mures", localitate: "Soromiclea" },
  { judet: "Mures", localitate: "Venchi" },
  { judet: "Mures", localitate: "Viilor" },
  { judet: "Mures", localitate: "Capeti" },
  { judet: "Mures", localitate: "Iliesi" },
  { judet: "Mures", localitate: "Sacadat" },
  { judet: "Mures", localitate: "Sarateni" },
  { judet: "Mures", localitate: "Bobohalma" },
  { judet: "Mures", localitate: "Botorca" },
  { judet: "Mures", localitate: "Custelnic" },
  { judet: "Mures", localitate: "Corbesti" },
  { judet: "Mures", localitate: "Gaiesti" },
  { judet: "Mures", localitate: "Gruisor" },
  { judet: "Mures", localitate: "Murgesti" },
  { judet: "Mures", localitate: "Roteni" },
  { judet: "Mures", localitate: "Stejeris" },
  { judet: "Mures", localitate: "Suveica" },
  { judet: "Mures", localitate: "Valenii" },
  { judet: "Mures", localitate: "Chincius" },
  { judet: "Mures", localitate: "Cornesti (Adamus)" },
  { judet: "Mures", localitate: "Craiesti (Adamus)" },
  { judet: "Mures", localitate: "Dambau" },
  { judet: "Mures", localitate: "Herepea" },
  { judet: "Mures", localitate: "Barlibasoaia" },
  { judet: "Mures", localitate: "Boiu" },
  { judet: "Mures", localitate: "Jacu" },
  { judet: "Mures", localitate: "Sapartoc" },
  { judet: "Mures", localitate: "Topa" },
  { judet: "Mures", localitate: "Valea Albestiului" },
  { judet: "Mures", localitate: "Valea Daii" },
  { judet: "Mures", localitate: "Valea Sapartocului" },
  { judet: "Mures", localitate: "Fitcau" },
  { judet: "Mures", localitate: "Lunca Muresului" },
  { judet: "Mures", localitate: "Daia (Apold)" },
  { judet: "Mures", localitate: "Saes" },
  { judet: "Mures", localitate: "Vulcan" },
  { judet: "Mures", localitate: "Botez" },
  { judet: "Mures", localitate: "Cecalaca" },
  { judet: "Mures", localitate: "Istihaza" },
  { judet: "Mures", localitate: "Maldaoci" },
  { judet: "Mures", localitate: "Saniacob" },
  { judet: "Mures", localitate: "Bernadea" },
  { judet: "Mures", localitate: "Cund" },
  { judet: "Mures", localitate: "Daia (Bahnea)" },
  { judet: "Mures", localitate: "Gogan" },
  { judet: "Mures", localitate: "Idiciu" },
  { judet: "Mures", localitate: "Lepindea" },
  { judet: "Mures", localitate: "Draculea Bandului" },
  { judet: "Mures", localitate: "Fanate (Band)" },
  { judet: "Mures", localitate: "Fanatele Madarasului" },
  { judet: "Mures", localitate: "Istan-Tau" },
  { judet: "Mures", localitate: "Marasesti" },
  { judet: "Mures", localitate: "Negrenii de Campie" },
  { judet: "Mures", localitate: "Oroiu" },
  { judet: "Mures", localitate: "Petea" },
  { judet: "Mures", localitate: "Tiptelnic" },
  { judet: "Mures", localitate: "Valea Mare" },
  { judet: "Mures", localitate: "Valea Rece" },
  { judet: "Mures", localitate: "Dedrad" },
  { judet: "Mures", localitate: "Goreni" },
  { judet: "Mures", localitate: "Uila" },
  { judet: "Mures", localitate: "Delenii" },
  { judet: "Mures", localitate: "Bala" },
  { judet: "Mures", localitate: "Ercea" },
  { judet: "Mures", localitate: "Agristeu" },
  { judet: "Mures", localitate: "Chendu" },
  { judet: "Mures", localitate: "Dumitreni" },
  { judet: "Mures", localitate: "Filitelnic" },
  { judet: "Mures", localitate: "Senereus" },
  { judet: "Mures", localitate: "Beica de Sus" },
  { judet: "Mures", localitate: "Cacuciu" },
  { judet: "Mures", localitate: "Nadasa" },
  { judet: "Mures", localitate: "Sanmihai de Padure" },
  { judet: "Mures", localitate: "Serbeni" },
  { judet: "Mures", localitate: "Gambut" },
  { judet: "Mures", localitate: "Nandra" },
  { judet: "Mures", localitate: "Ozd" },
  { judet: "Mures", localitate: "Ranta" },
  { judet: "Mures", localitate: "Idicel" },
  { judet: "Mures", localitate: "Idicel-Padure" },
  { judet: "Mures", localitate: "Sacalu de Padure" },
  { judet: "Mures", localitate: "Valenii de Mures" },
  { judet: "Mures", localitate: "Filpisu Mare" },
  { judet: "Mures", localitate: "Filpisu Mic" },
  { judet: "Mures", localitate: "Bozed" },
  { judet: "Mures", localitate: "Campenita" },
  { judet: "Mures", localitate: "Culpiu" },
  { judet: "Mures", localitate: "Herghelia" },
  { judet: "Mures", localitate: "Porumbeni" },
  { judet: "Mures", localitate: "Sabed" },
  { judet: "Mures", localitate: "Voiniceni" },
  { judet: "Mures", localitate: "Coasta Grindului" },
  { judet: "Mures", localitate: "Cordos" },
  { judet: "Mures", localitate: "Giurgis" },
  { judet: "Mures", localitate: "Grindeni" },
  { judet: "Mures", localitate: "Hadareni" },
  { judet: "Mures", localitate: "Lint" },
  { judet: "Mures", localitate: "Chiheru de Sus" },
  { judet: "Mures", localitate: "Urisiu de Jos" },
  { judet: "Mures", localitate: "Urisiu de Sus" },
  { judet: "Mures", localitate: "Coroi" },
  { judet: "Mures", localitate: "Odrihei" },
  { judet: "Mures", localitate: "Soimus" },
  { judet: "Mures", localitate: "Fanatele Socolului" },
  { judet: "Mures", localitate: "Socolu de Campie" },
  { judet: "Mures", localitate: "Valea Sasului" },
  { judet: "Mures", localitate: "Valea Ungurului" },
  { judet: "Mures", localitate: "Budiu Mic" },
  { judet: "Mures", localitate: "Cinta" },
  { judet: "Mures", localitate: "Cornesti (Craciunesti)" },
  { judet: "Mures", localitate: "Tirimioara" },
  { judet: "Mures", localitate: "Lefaia" },
  { judet: "Mures", localitate: "Milasel" },
  { judet: "Mures", localitate: "Nima Milaselului" },
  { judet: "Mures", localitate: "Ciba" },
  { judet: "Mures", localitate: "Valureni" },
  { judet: "Mures", localitate: "Foi" },
  { judet: "Mures", localitate: "Nicolesti" },
  { judet: "Mures", localitate: "Bord" },
  { judet: "Mures", localitate: "Seulia de Mures" },
  { judet: "Mures", localitate: "Dataseni" },
  { judet: "Mures", localitate: "Dupa Deal (Cuci)" },
  { judet: "Mures", localitate: "Orosia" },
  { judet: "Mures", localitate: "Petrilaca" },
  { judet: "Mures", localitate: "Cris" },
  { judet: "Mures", localitate: "Seleus (Danes)" },
  { judet: "Mures", localitate: "Stejarenii" },
  { judet: "Mures", localitate: "Bistra Muresului" },
  { judet: "Mures", localitate: "Filea" },
  { judet: "Mures", localitate: "Pietris" },
  { judet: "Mures", localitate: "Calugareni" },
  { judet: "Mures", localitate: "Campu Cetatii" },
  { judet: "Mures", localitate: "Damieni" },
  { judet: "Mures", localitate: "Matrici" },
  { judet: "Mures", localitate: "Caluseri" },
  { judet: "Mures", localitate: "Dumbravioara" },
  { judet: "Mures", localitate: "Icland" },
  { judet: "Mures", localitate: "Sacareni" },
  { judet: "Mures", localitate: "Sangeru de Padure" },
  { judet: "Mures", localitate: "Fanate (Faragau)" },
  { judet: "Mures", localitate: "Hodaia" },
  { judet: "Mures", localitate: "Onuca" },
  { judet: "Mures", localitate: "Poarta" },
  { judet: "Mures", localitate: "Tonciu" },
  { judet: "Mures", localitate: "Bordosiu" },
  { judet: "Mures", localitate: "Calimanesti" },
  { judet: "Mures", localitate: "Cibu" },
  { judet: "Mures", localitate: "Roua" },
  { judet: "Mures", localitate: "Viforoasa" },
  { judet: "Mures", localitate: "Sanvasii" },
  { judet: "Mures", localitate: "Adrianu Mare" },
  { judet: "Mures", localitate: "Adrianu Mic" },
  { judet: "Mures", localitate: "Bedeni" },
  { judet: "Mures", localitate: "Maiad" },
  { judet: "Mures", localitate: "Troita" },
  { judet: "Mures", localitate: "Paucisoara" },
  { judet: "Mures", localitate: "Sub Padure" },
  { judet: "Mures", localitate: "Seuca" },
  { judet: "Mures", localitate: "Ilieni" },
  { judet: "Mures", localitate: "Leordeni" },
  { judet: "Mures", localitate: "Satu Nou (Gheorghe Doja)" },
  { judet: "Mures", localitate: "Tirimia" },
  { judet: "Mures", localitate: "Abud" },
  { judet: "Mures", localitate: "Ceie" },
  { judet: "Mures", localitate: "Chibed" },
  { judet: "Mures", localitate: "Solocma" },
  { judet: "Mures", localitate: "Trei Sate" },
  { judet: "Mures", localitate: "Merisor" },
  { judet: "Mures", localitate: "Moisa" },
  { judet: "Mures", localitate: "Pacureni" },
  { judet: "Mures", localitate: "Paingeni" },
  { judet: "Mures", localitate: "Iara de Mures" },
  { judet: "Mures", localitate: "Ilioara" },
  { judet: "Mures", localitate: "Mura Mare" },
  { judet: "Mures", localitate: "Mura Mica" },
  { judet: "Mures", localitate: "Padureni" },
  { judet: "Mures", localitate: "Peris" },
  { judet: "Mures", localitate: "Petrilaca de Mures" },
  { judet: "Mures", localitate: "Teleac" },
  { judet: "Mures", localitate: "Leorinta" },
  { judet: "Mures", localitate: "Valea Sanpetrului (Grebenisu de Campie)" },
  { judet: "Mures", localitate: "Adrian" },
  { judet: "Mures", localitate: "Casva" },
  { judet: "Mures", localitate: "Comori" },
  { judet: "Mures", localitate: "Fundoaia" },
  { judet: "Mures", localitate: "Glajarie" },
  { judet: "Mures", localitate: "Larga (Gurghiu)" },
  { judet: "Mures", localitate: "Orsova" },
  { judet: "Mures", localitate: "Orsova-Padure" },
  { judet: "Mures", localitate: "Pauloaia" },
  { judet: "Mures", localitate: "Arsita" },
  { judet: "Mures", localitate: "Bicasu" },
  { judet: "Mures", localitate: "Dubistea de Padure" },
  { judet: "Mures", localitate: "Mirigioaia" },
  { judet: "Mures", localitate: "Toaca" },
  { judet: "Mures", localitate: "Uricea" },
  { judet: "Mures", localitate: "Ihod" },
  { judet: "Mures", localitate: "Isla" },
  { judet: "Mures", localitate: "Sambrias" },
  { judet: "Mures", localitate: "Blidireasa" },
  { judet: "Mures", localitate: "Bradetelu" },
  { judet: "Mures", localitate: "Dulcea" },
  { judet: "Mures", localitate: "Ibanesti-Padure" },
  { judet: "Mures", localitate: "Lapusna" },
  { judet: "Mures", localitate: "Parau Mare" },
  { judet: "Mures", localitate: "Tireu" },
  { judet: "Mures", localitate: "Tisieu" },
  { judet: "Mures", localitate: "Zimti" },
  { judet: "Mures", localitate: "Capusu de Campie" },
  { judet: "Mures", localitate: "Chisalita" },
  { judet: "Mures", localitate: "Dupa Deal (Iclanzel)" },
  { judet: "Mures", localitate: "Fanate (Iclanzel)" },
  { judet: "Mures", localitate: "Fanatele Capusului" },
  { judet: "Mures", localitate: "Ghidasteu" },
  { judet: "Mures", localitate: "Iclandu Mare" },
  { judet: "Mures", localitate: "Madaraseni" },
  { judet: "Mures", localitate: "Tablaseni" },
  { judet: "Mures", localitate: "Valea Iclandului" },
  { judet: "Mures", localitate: "Deleni (Ideciu de Jos)" },
  { judet: "Mures", localitate: "Ideciu de Sus" },
  { judet: "Mures", localitate: "Bozeni" },
  { judet: "Mures", localitate: "Ivanesti" },
  { judet: "Mures", localitate: "Poienita" },
  { judet: "Mures", localitate: "Sanisor" },
  { judet: "Mures", localitate: "Baita" },
  { judet: "Mures", localitate: "Frunzeni" },
  { judet: "Mures", localitate: "Logig" },
  { judet: "Mures", localitate: "Santu" },
  { judet: "Mures", localitate: "Neagra" },
  { judet: "Mures", localitate: "Salard" },
  { judet: "Mures", localitate: "Bara" },
  { judet: "Mures", localitate: "Candu" },
  { judet: "Mures", localitate: "Drojdii" },
  { judet: "Mures", localitate: "Eremieni" },
  { judet: "Mures", localitate: "Maia" },
  { judet: "Mures", localitate: "Marculeni" },
  { judet: "Mures", localitate: "Silea Nirajului" },
  { judet: "Mures", localitate: "Torba" },
  { judet: "Mures", localitate: "Abus" },
  { judet: "Mures", localitate: "Capalna de Sus" },
  { judet: "Mures", localitate: "Ceuas" },
  { judet: "Mures", localitate: "Deaj" },
  { judet: "Mures", localitate: "Haranglab" },
  { judet: "Mures", localitate: "Somostelnic" },
  { judet: "Mures", localitate: "Beu" },
  { judet: "Mures", localitate: "Dumitrestii" },
  { judet: "Mures", localitate: "Laureni" },
  { judet: "Mures", localitate: "Mosuni" },
  { judet: "Mures", localitate: "Sardu Nirajului" },
  { judet: "Mures", localitate: "Tampa" },
  { judet: "Mures", localitate: "Veta" },
  { judet: "Mures", localitate: "Bujor" },
  { judet: "Mures", localitate: "Cirhagau" },
  { judet: "Mures", localitate: "Groapa Radaii" },
  { judet: "Mures", localitate: "Mogoaia" },
  { judet: "Mures", localitate: "Razoare" },
  { judet: "Mures", localitate: "Saulita" },
  { judet: "Mures", localitate: "Stefanca" },
  { judet: "Mures", localitate: "Magherus" },
  { judet: "Mures", localitate: "Pipea" },
  { judet: "Mures", localitate: "Tigmandru" },
  { judet: "Mures", localitate: "Ghinesti" },
  { judet: "Mures", localitate: "Rigmani" },
  { judet: "Mures", localitate: "Sansimion" },
  { judet: "Mures", localitate: "Vadas" },
  { judet: "Mures", localitate: "Dileu Vechi" },
  { judet: "Mures", localitate: "Giulus" },
  { judet: "Mures", localitate: "Lascud" },
  { judet: "Mures", localitate: "Vaideiu" },
  { judet: "Mures", localitate: "Dobra" },
  { judet: "Mures", localitate: "Merisoru" },
  { judet: "Mures", localitate: "Sandru" },
  { judet: "Mures", localitate: "Ursoaia" },
  { judet: "Mures", localitate: "Berghia" },
  { judet: "Mures", localitate: "Cuiesd" },
  { judet: "Mures", localitate: "Hartau" },
  { judet: "Mures", localitate: "Santioana de Mures" },
  { judet: "Mures", localitate: "Bolintineni" },
  { judet: "Mures", localitate: "Galateni" },
  { judet: "Mures", localitate: "Habic" },
  { judet: "Mures", localitate: "Bologaia" },
  { judet: "Mures", localitate: "Ciulea" },
  { judet: "Mures", localitate: "Deleni (Pogaceaua)" },
  { judet: "Mures", localitate: "Fantana Babii" },
  { judet: "Mures", localitate: "Parau Crucii (Pogaceaua)" },
  { judet: "Mures", localitate: "Scurta" },
  { judet: "Mures", localitate: "Sicele" },
  { judet: "Mures", localitate: "Valea Sanpetrului (Pogaceaua)" },
  { judet: "Mures", localitate: "Valeni" },
  { judet: "Mures", localitate: "Andreneasa" },
  { judet: "Mures", localitate: "Borzia" },
  { judet: "Mures", localitate: "Galaoaia" },
  { judet: "Mures", localitate: "Iod" },
  { judet: "Mures", localitate: "Caciulata" },
  { judet: "Mures", localitate: "Coasta Mare" },
  { judet: "Mures", localitate: "Cotorinau" },
  { judet: "Mures", localitate: "Curete" },
  { judet: "Mures", localitate: "Hagau" },
  { judet: "Mures", localitate: "Lenis" },
  { judet: "Mures", localitate: "Nima Raciului" },
  { judet: "Mures", localitate: "Obarsie" },
  { judet: "Mures", localitate: "Parau Crucii (Raciu)" },
  { judet: "Mures", localitate: "Sanmartinu de Campie" },
  { judet: "Mures", localitate: "Ulies" },
  { judet: "Mures", localitate: "Valea Sanmartinului" },
  { judet: "Mures", localitate: "Valea Seaca" },
  { judet: "Mures", localitate: "Valea Uliesului" },
  { judet: "Mures", localitate: "Maioresti" },
  { judet: "Mures", localitate: "Morareni" },
  { judet: "Mures", localitate: "Sebes" },
  { judet: "Mures", localitate: "Cloasterf" },
  { judet: "Mures", localitate: "Mihai Viteazu" },
  { judet: "Mures", localitate: "Balda" },
  { judet: "Mures", localitate: "Larga (Sarmasu)" },
  { judet: "Mures", localitate: "Morut" },
  { judet: "Mures", localitate: "Sarmasel" },
  { judet: "Mures", localitate: "Sarmasel-Gara" },
  { judet: "Mures", localitate: "Titiana" },
  { judet: "Mures", localitate: "Visinelu" },
  { judet: "Mures", localitate: "Nazna" },
  { judet: "Mures", localitate: "Cotus" },
  { judet: "Mures", localitate: "Tofalau" },
  { judet: "Mures", localitate: "Bezid" },
  { judet: "Mures", localitate: "Bezidu Nou" },
  { judet: "Mures", localitate: "Lotu" },
  { judet: "Mures", localitate: "Barza" },
  { judet: "Mures", localitate: "Cipaieni" },
  { judet: "Mures", localitate: "Dalu" },
  { judet: "Mures", localitate: "Pripoare" },
  { judet: "Mures", localitate: "Valisoara" },
  { judet: "Mures", localitate: "Zapodea" },
  { judet: "Mures", localitate: "Chirileu" },
  { judet: "Mures", localitate: "Dileu Nou" },
  { judet: "Mures", localitate: "Sanmarghita" },
  { judet: "Mures", localitate: "Valea Izvoarelor" },
  { judet: "Mures", localitate: "Barlibas" },
  { judet: "Mures", localitate: "Dambu" },
  { judet: "Mures", localitate: "Satu Nou (Sanpetru de Campie)" },
  { judet: "Mures", localitate: "Sangeorgiu de Campie" },
  { judet: "Mures", localitate: "Tusinu" },
  { judet: "Mures", localitate: "Bardesti" },
  { judet: "Mures", localitate: "Chinari" },
  { judet: "Mures", localitate: "Curteni" },
  { judet: "Mures", localitate: "Jabenita" },
  { judet: "Mures", localitate: "Ciobotani" },
  { judet: "Mures", localitate: "Mestera" },
  { judet: "Mures", localitate: "Idrifaia" },
  { judet: "Mures", localitate: "Laslau Mare" },
  { judet: "Mures", localitate: "Laslau Mic" },
  { judet: "Mures", localitate: "Vaidacuta" },
  { judet: "Mures", localitate: "Luieriu" },
  { judet: "Mures", localitate: "Leorinta-Saulia" },
  { judet: "Mures", localitate: "Macicasesti" },
  { judet: "Mures", localitate: "Padurea" },
  { judet: "Mures", localitate: "Lechincioara" },
  { judet: "Mures", localitate: "Pusta" },
  { judet: "Mures", localitate: "Sincai-Fanate" },
  { judet: "Mures", localitate: "Fanate (Taureni)" },
  { judet: "Mures", localitate: "Moara de Jos" },
  { judet: "Mures", localitate: "Cerghid" },
  { judet: "Mures", localitate: "Cerghizel" },
  { judet: "Mures", localitate: "Moresti" },
  { judet: "Mures", localitate: "Recea" },
  { judet: "Mures", localitate: "Sausa" },
  { judet: "Mures", localitate: "Gradini" },
  { judet: "Mures", localitate: "Malaesti" },
  { judet: "Mures", localitate: "Poduri" },
  { judet: "Mures", localitate: "Valea Fratiei" },
  { judet: "Mures", localitate: "Valea Glodului" },
  { judet: "Mures", localitate: "Valea Padurii" },
  { judet: "Mures", localitate: "Valea Surii" },
  { judet: "Mures", localitate: "Valea Uriesului" },
  { judet: "Mures", localitate: "Grausorul" },
  { judet: "Mures", localitate: "Mitresti" },
  { judet: "Mures", localitate: "Vadu" },
  { judet: "Mures", localitate: "Valea" },
  { judet: "Mures", localitate: "Dumbrava" },
  { judet: "Mures", localitate: "Rapa de Jos" },
  { judet: "Mures", localitate: "Archita" },
  { judet: "Mures", localitate: "Feleag" },
  { judet: "Mures", localitate: "Mureni" },
  { judet: "Mures", localitate: "Soard" },
  { judet: "Mures", localitate: "Jacodu" },
  { judet: "Mures", localitate: "Salasuri" },
  { judet: "Mures", localitate: "Ormenis" },
  { judet: "Mures", localitate: "Santioana" },
  { judet: "Mures", localitate: "Toldal" },
  { judet: "Mures", localitate: "Seleus (Zagar)" },
  { judet: "Mures", localitate: "Barbosi" },
  { judet: "Mures", localitate: "Botei" },
  { judet: "Mures", localitate: "Bujor-Hodaie" },
  { judet: "Mures", localitate: "Ciretea" },
  { judet: "Mures", localitate: "Gaura Sangerului" },
  { judet: "Mures", localitate: "Malea" },
  { judet: "Mures", localitate: "Stefaneaca" },
  { judet: "Mures", localitate: "Tau" },
  { judet: "Neamt", localitate: "Piatra Neamt" },
  { judet: "Neamt", localitate: "Roman" },
  { judet: "Neamt", localitate: "Targu Neamt" },
  { judet: "Neamt", localitate: "Bicaz" },
  { judet: "Neamt", localitate: "Roznov" },
  { judet: "Neamt", localitate: "Agapia" },
  { judet: "Neamt", localitate: "Alexandru cel Bun" },
  { judet: "Neamt", localitate: "Bahna" },
  { judet: "Neamt", localitate: "Baltatesti" },
  { judet: "Neamt", localitate: "Bara" },
  { judet: "Neamt", localitate: "Bargauani" },
  { judet: "Neamt", localitate: "Bicaz-Chei" },
  { judet: "Neamt", localitate: "Bicazu Ardelean" },
  { judet: "Neamt", localitate: "Bodesti" },
  { judet: "Neamt", localitate: "Boghicea" },
  { judet: "Neamt", localitate: "Borca" },
  { judet: "Neamt", localitate: "Borlesti" },
  { judet: "Neamt", localitate: "Bozieni" },
  { judet: "Neamt", localitate: "Brusturi" },
  { judet: "Neamt", localitate: "Candesti" },
  { judet: "Neamt", localitate: "Ceahlau" },
  { judet: "Neamt", localitate: "Cordun" },
  { judet: "Neamt", localitate: "Costisa" },
  { judet: "Neamt", localitate: "Cracaoani" },
  { judet: "Neamt", localitate: "Damuc" },
  { judet: "Neamt", localitate: "Dobreni" },
  { judet: "Neamt", localitate: "Dochia" },
  { judet: "Neamt", localitate: "Doljesti" },
  { judet: "Neamt", localitate: "Dragomiresti" },
  { judet: "Neamt", localitate: "Draganesti" },
  { judet: "Neamt", localitate: "Dulcesti" },
  { judet: "Neamt", localitate: "Dumbrava Rosie" },
  { judet: "Neamt", localitate: "Farcasa" },
  { judet: "Neamt", localitate: "Faurei" },
  { judet: "Neamt", localitate: "Gadinti" },
  { judet: "Neamt", localitate: "Garcina" },
  { judet: "Neamt", localitate: "Gheraesti" },
  { judet: "Neamt", localitate: "Ghindaoani" },
  { judet: "Neamt", localitate: "Girov" },
  { judet: "Neamt", localitate: "Grinties" },
  { judet: "Neamt", localitate: "Grumazesti" },
  { judet: "Neamt", localitate: "Hangu" },
  { judet: "Neamt", localitate: "Horia" },
  { judet: "Neamt", localitate: "Icusesti" },
  { judet: "Neamt", localitate: "Ion Creanga" },
  { judet: "Neamt", localitate: "Margineni" },
  { judet: "Neamt", localitate: "Moldoveni" },
  { judet: "Neamt", localitate: "Negresti" },
  { judet: "Neamt", localitate: "Oniceni" },
  { judet: "Neamt", localitate: "Pastraveni" },
  { judet: "Neamt", localitate: "Pancesti" },
  { judet: "Neamt", localitate: "Pangarati" },
  { judet: "Neamt", localitate: "Petricani" },
  { judet: "Neamt", localitate: "Piatra Soimului" },
  { judet: "Neamt", localitate: "Pipirig" },
  { judet: "Neamt", localitate: "Podoleni" },
  { judet: "Neamt", localitate: "Poiana Teiului" },
  { judet: "Neamt", localitate: "Poienari" },
  { judet: "Neamt", localitate: "Raucesti" },
  { judet: "Neamt", localitate: "Razboieni" },
  { judet: "Neamt", localitate: "Rediu" },
  { judet: "Neamt", localitate: "Romani" },
  { judet: "Neamt", localitate: "Ruginoasa" },
  { judet: "Neamt", localitate: "Sagna" },
  { judet: "Neamt", localitate: "Sabaoani" },
  { judet: "Neamt", localitate: "Savinesti" },
  { judet: "Neamt", localitate: "Secuieni" },
  { judet: "Neamt", localitate: "Stanita" },
  { judet: "Neamt", localitate: "Stefan cel Mare" },
  { judet: "Neamt", localitate: "Tarcau" },
  { judet: "Neamt", localitate: "Tasca" },
  { judet: "Neamt", localitate: "Tazlau" },
  { judet: "Neamt", localitate: "Tamaseni" },
  { judet: "Neamt", localitate: "Timisesti" },
  { judet: "Neamt", localitate: "Trifesti" },
  { judet: "Neamt", localitate: "Tupilati" },
  { judet: "Neamt", localitate: "Tibucani" },
  { judet: "Neamt", localitate: "Urecheni" },
  { judet: "Neamt", localitate: "Valea Ursului" },
  { judet: "Neamt", localitate: "Valeni" },
  { judet: "Neamt", localitate: "Vanatori-Neamt" },
  { judet: "Neamt", localitate: "Zanesti" },
  { judet: "Neamt", localitate: "Ciritei" },
  { judet: "Neamt", localitate: "Doamna" },
  { judet: "Neamt", localitate: "Valeni (Piatra-Neamt)" },
  { judet: "Neamt", localitate: "Capsa" },
  { judet: "Neamt", localitate: "Dodeni" },
  { judet: "Neamt", localitate: "Izvoru Alb" },
  { judet: "Neamt", localitate: "Izvoru Muntelui" },
  { judet: "Neamt", localitate: "Potoci" },
  { judet: "Neamt", localitate: "Secu" },
  { judet: "Neamt", localitate: "Blebea" },
  { judet: "Neamt", localitate: "Humulesti" },
  { judet: "Neamt", localitate: "Humulestii Noi" },
  { judet: "Neamt", localitate: "Agapia-Manastire" },
  { judet: "Neamt", localitate: "Filioara" },
  { judet: "Neamt", localitate: "Sacalusesti" },
  { judet: "Neamt", localitate: "Varatec" },
  { judet: "Neamt", localitate: "Aramesti" },
  { judet: "Neamt", localitate: "Bahnisoara" },
  { judet: "Neamt", localitate: "Brosteni" },
  { judet: "Neamt", localitate: "Izvoare (Bahna)" },
  { judet: "Neamt", localitate: "Liliac" },
  { judet: "Neamt", localitate: "Tutcanii din Deal" },
  { judet: "Neamt", localitate: "Tutcanii din Vale" },
  { judet: "Neamt", localitate: "Valea Arini" },
  { judet: "Neamt", localitate: "Valea Seaca" },
  { judet: "Neamt", localitate: "Causeni" },
  { judet: "Neamt", localitate: "Negresti (Bara)" },
  { judet: "Neamt", localitate: "Nistria" },
  { judet: "Neamt", localitate: "Rediu (Bara)" },
  { judet: "Neamt", localitate: "Slobozia (Boghicea)" },
  { judet: "Neamt", localitate: "Bahna Mare" },
  { judet: "Neamt", localitate: "Baratca" },
  { judet: "Neamt", localitate: "Balanesti" },
  { judet: "Neamt", localitate: "Breaza" },
  { judet: "Neamt", localitate: "Certieni" },
  { judet: "Neamt", localitate: "Chilia" },
  { judet: "Neamt", localitate: "Darloaia" },
  { judet: "Neamt", localitate: "Ghelaiesti" },
  { judet: "Neamt", localitate: "Hartop" },
  { judet: "Neamt", localitate: "Homiceni" },
  { judet: "Neamt", localitate: "Talpa" },
  { judet: "Neamt", localitate: "Vladiceni" },
  { judet: "Neamt", localitate: "Barnadu" },
  { judet: "Neamt", localitate: "Gherman" },
  { judet: "Neamt", localitate: "Ivanes" },
  { judet: "Neamt", localitate: "Telec" },
  { judet: "Neamt", localitate: "Ticos" },
  { judet: "Neamt", localitate: "Bodestii de Jos" },
  { judet: "Neamt", localitate: "Corni" },
  { judet: "Neamt", localitate: "Oslobeni" },
  { judet: "Neamt", localitate: "Lunca (Borca)" },
  { judet: "Neamt", localitate: "Madei" },
  { judet: "Neamt", localitate: "Paraul Carjei" },
  { judet: "Neamt", localitate: "Paraul Pantei" },
  { judet: "Neamt", localitate: "Sabasa" },
  { judet: "Neamt", localitate: "Soci (Borca)" },
  { judet: "Neamt", localitate: "Mastacan (Borlesti)" },
  { judet: "Neamt", localitate: "Nechit" },
  { judet: "Neamt", localitate: "Ruseni (Borlesti)" },
  { judet: "Neamt", localitate: "Sovoaia" },
  { judet: "Neamt", localitate: "Botesti" },
  { judet: "Neamt", localitate: "Barticesti" },
  { judet: "Neamt", localitate: "David" },
  { judet: "Neamt", localitate: "Moreni" },
  { judet: "Neamt", localitate: "Munteni" },
  { judet: "Neamt", localitate: "Nisiporesti" },
  { judet: "Neamt", localitate: "Baneasa" },
  { judet: "Neamt", localitate: "Craiesti" },
  { judet: "Neamt", localitate: "Cuci" },
  { judet: "Neamt", localitate: "Iucsa" },
  { judet: "Neamt", localitate: "Grosi" },
  { judet: "Neamt", localitate: "Ortasti" },
  { judet: "Neamt", localitate: "Poiana (Brusturi)" },
  { judet: "Neamt", localitate: "Rasca" },
  { judet: "Neamt", localitate: "Soimaresti" },
  { judet: "Neamt", localitate: "Tarzia" },
  { judet: "Neamt", localitate: "Barcanesti" },
  { judet: "Neamt", localitate: "Dragova" },
  { judet: "Neamt", localitate: "Padureni" },
  { judet: "Neamt", localitate: "Tardenii Mici" },
  { judet: "Neamt", localitate: "Vadurele (Candesti)" },
  { judet: "Neamt", localitate: "Bistricioara" },
  { judet: "Neamt", localitate: "Paraul Mare" },
  { judet: "Neamt", localitate: "Durau" },
  { judet: "Neamt", localitate: "Pildesti" },
  { judet: "Neamt", localitate: "Simionesti" },
  { judet: "Neamt", localitate: "Dornesti" },
  { judet: "Neamt", localitate: "Frunzeni" },
  { judet: "Neamt", localitate: "Manoaia" },
  { judet: "Neamt", localitate: "Cracaul Negru" },
  { judet: "Neamt", localitate: "Magazia" },
  { judet: "Neamt", localitate: "Mitocu Balan" },
  { judet: "Neamt", localitate: "Poiana Cracaoani" },
  { judet: "Neamt", localitate: "Huisurez" },
  { judet: "Neamt", localitate: "Trei Fantani" },
  { judet: "Neamt", localitate: "Casaria" },
  { judet: "Neamt", localitate: "Poiana (Negresti)" },
  { judet: "Neamt", localitate: "Sarata" },
  { judet: "Neamt", localitate: "Buhonca" },
  { judet: "Neamt", localitate: "Buruienesti" },
  { judet: "Neamt", localitate: "Rotunda" },
  { judet: "Neamt", localitate: "Bornis" },
  { judet: "Neamt", localitate: "Hlapesti" },
  { judet: "Neamt", localitate: "Mastacan (Dragomiresti)" },
  { judet: "Neamt", localitate: "Unghi" },
  { judet: "Neamt", localitate: "Vad" },
  { judet: "Neamt", localitate: "Bozienii de Sus" },
  { judet: "Neamt", localitate: "Britcani" },
  { judet: "Neamt", localitate: "Carlig" },
  { judet: "Neamt", localitate: "Corhana" },
  { judet: "Neamt", localitate: "Poiana (Dulcesti)" },
  { judet: "Neamt", localitate: "Rosiori" },
  { judet: "Neamt", localitate: "Brasauti" },
  { judet: "Neamt", localitate: "Cut" },
  { judet: "Neamt", localitate: "Izvoare (Dumbrava Rosie)" },
  { judet: "Neamt", localitate: "Busmei" },
  { judet: "Neamt", localitate: "Frumosu" },
  { judet: "Neamt", localitate: "Popesti (Farcasa)" },
  { judet: "Neamt", localitate: "Stejaru (Farcasa)" },
  { judet: "Neamt", localitate: "Budesti" },
  { judet: "Neamt", localitate: "Climesti" },
  { judet: "Neamt", localitate: "Micsunesti" },
  { judet: "Neamt", localitate: "Almas" },
  { judet: "Neamt", localitate: "Cuejdiu" },
  { judet: "Neamt", localitate: "Gheraestii Noi" },
  { judet: "Neamt", localitate: "Tetcani" },
  { judet: "Neamt", localitate: "Balusesti (Dochia)" },
  { judet: "Neamt", localitate: "Botesti" },
  { judet: "Neamt", localitate: "Caciulesti" },
  { judet: "Neamt", localitate: "Danesti" },
  { judet: "Neamt", localitate: "Doina" },
  { judet: "Neamt", localitate: "Gura Vaii" },
  { judet: "Neamt", localitate: "Popesti (Girov)" },
  { judet: "Neamt", localitate: "Turturesti" },
  { judet: "Neamt", localitate: "Versesti" },
  { judet: "Neamt", localitate: "Bradu" },
  { judet: "Neamt", localitate: "Poiana (Grinties)" },
  { judet: "Neamt", localitate: "Curechistea" },
  { judet: "Neamt", localitate: "Netezi" },
  { judet: "Neamt", localitate: "Topolita" },
  { judet: "Neamt", localitate: "Buhalnita" },
  { judet: "Neamt", localitate: "Chiriteni" },
  { judet: "Neamt", localitate: "Grozavesti" },
  { judet: "Neamt", localitate: "Ruginesti" },
  { judet: "Neamt", localitate: "Cotu Vames" },
  { judet: "Neamt", localitate: "Balusesti (Icusesti)" },
  { judet: "Neamt", localitate: "Batranesti" },
  { judet: "Neamt", localitate: "Mesteacan" },
  { judet: "Neamt", localitate: "Rocna" },
  { judet: "Neamt", localitate: "Spiridonesti" },
  { judet: "Neamt", localitate: "Tabara" },
  { judet: "Neamt", localitate: "Averesti" },
  { judet: "Neamt", localitate: "Izvoru" },
  { judet: "Neamt", localitate: "Muncelu" },
  { judet: "Neamt", localitate: "Recea" },
  { judet: "Neamt", localitate: "Stejaru (Ion Creanga)" },
  { judet: "Neamt", localitate: "Hartesti" },
  { judet: "Neamt", localitate: "Hoisesti" },
  { judet: "Neamt", localitate: "Itrinesti" },
  { judet: "Neamt", localitate: "Hociungi" },
  { judet: "Neamt", localitate: "Ciornei" },
  { judet: "Neamt", localitate: "Gorun" },
  { judet: "Neamt", localitate: "Linsesti" },
  { judet: "Neamt", localitate: "Lunca (Oniceni)" },
  { judet: "Neamt", localitate: "Marmureni" },
  { judet: "Neamt", localitate: "Pietrosu" },
  { judet: "Neamt", localitate: "Poiana Humei" },
  { judet: "Neamt", localitate: "Pustieta" },
  { judet: "Neamt", localitate: "Solca" },
  { judet: "Neamt", localitate: "Valea Enei" },
  { judet: "Neamt", localitate: "Lunca Moldovei" },
  { judet: "Neamt", localitate: "Radeni" },
  { judet: "Neamt", localitate: "Spiesti" },
  { judet: "Neamt", localitate: "Oantu" },
  { judet: "Neamt", localitate: "Pangaracior" },
  { judet: "Neamt", localitate: "Poiana (Pangarati)" },
  { judet: "Neamt", localitate: "Preluca" },
  { judet: "Neamt", localitate: "Stejaru (Pangarati)" },
  { judet: "Neamt", localitate: "Boistea" },
  { judet: "Neamt", localitate: "Tarpesti" },
  { judet: "Neamt", localitate: "Tolici" },
  { judet: "Neamt", localitate: "Luminis" },
  { judet: "Neamt", localitate: "Negulesti" },
  { judet: "Neamt", localitate: "Poieni" },
  { judet: "Neamt", localitate: "Boboiesti" },
  { judet: "Neamt", localitate: "Dolhesti" },
  { judet: "Neamt", localitate: "Leghin" },
  { judet: "Neamt", localitate: "Pataligeni" },
  { judet: "Neamt", localitate: "Pluton" },
  { judet: "Neamt", localitate: "Stanca" },
  { judet: "Neamt", localitate: "Negritesti" },
  { judet: "Neamt", localitate: "Calugareni" },
  { judet: "Neamt", localitate: "Dreptu" },
  { judet: "Neamt", localitate: "Galu" },
  { judet: "Neamt", localitate: "Paraul Fagului" },
  { judet: "Neamt", localitate: "Petru Voda" },
  { judet: "Neamt", localitate: "Poiana Largului" },
  { judet: "Neamt", localitate: "Roseni" },
  { judet: "Neamt", localitate: "Ruseni (Poiana Teiului)" },
  { judet: "Neamt", localitate: "Savinesti (Poiana Teiului)" },
  { judet: "Neamt", localitate: "Topoliceni" },
  { judet: "Neamt", localitate: "Bunghi" },
  { judet: "Neamt", localitate: "Ciurea" },
  { judet: "Neamt", localitate: "Holm" },
  { judet: "Neamt", localitate: "Patricheni" },
  { judet: "Neamt", localitate: "Sacaleni" },
  { judet: "Neamt", localitate: "Talpalai" },
  { judet: "Neamt", localitate: "Oglinzi" },
  { judet: "Neamt", localitate: "Savesti" },
  { judet: "Neamt", localitate: "Ungheni" },
  { judet: "Neamt", localitate: "Borseni" },
  { judet: "Neamt", localitate: "Razboienii de Jos" },
  { judet: "Neamt", localitate: "Valea Alba" },
  { judet: "Neamt", localitate: "Valea Mare" },
  { judet: "Neamt", localitate: "Betesti" },
  { judet: "Neamt", localitate: "Poloboc" },
  { judet: "Neamt", localitate: "Socea" },
  { judet: "Neamt", localitate: "Gosmani" },
  { judet: "Neamt", localitate: "Silistea" },
  { judet: "Neamt", localitate: "Chintinici" },
  { judet: "Neamt", localitate: "Slobozia (Roznov)" },
  { judet: "Neamt", localitate: "Lutca" },
  { judet: "Neamt", localitate: "Traian (Sabaoani)" },
  { judet: "Neamt", localitate: "Vulpasesti" },
  { judet: "Neamt", localitate: "Dumbrava-Deal" },
  { judet: "Neamt", localitate: "Basta" },
  { judet: "Neamt", localitate: "Barjoveni" },
  { judet: "Neamt", localitate: "Bogzesti" },
  { judet: "Neamt", localitate: "Butnaresti" },
  { judet: "Neamt", localitate: "Giulesti" },
  { judet: "Neamt", localitate: "Prajesti" },
  { judet: "Neamt", localitate: "Secuienii Noi" },
  { judet: "Neamt", localitate: "Uncesti" },
  { judet: "Neamt", localitate: "Chicerea" },
  { judet: "Neamt", localitate: "Ghidion" },
  { judet: "Neamt", localitate: "Poienile Oancei" },
  { judet: "Neamt", localitate: "Todireni" },
  { judet: "Neamt", localitate: "Veja" },
  { judet: "Neamt", localitate: "Vladnicele" },
  { judet: "Neamt", localitate: "Bordea" },
  { judet: "Neamt", localitate: "Carligi" },
  { judet: "Neamt", localitate: "Deleni" },
  { judet: "Neamt", localitate: "Dusesti" },
  { judet: "Neamt", localitate: "Ghigoiesti" },
  { judet: "Neamt", localitate: "Soci (Stefan cel Mare)" },
  { judet: "Neamt", localitate: "Ardeluta" },
  { judet: "Neamt", localitate: "Brates" },
  { judet: "Neamt", localitate: "Cazaci" },
  { judet: "Neamt", localitate: "Schitu Tarcau" },
  { judet: "Neamt", localitate: "Straja" },
  { judet: "Neamt", localitate: "Hamzoaia" },
  { judet: "Neamt", localitate: "Neagra" },
  { judet: "Neamt", localitate: "Ticos-Floarea" },
  { judet: "Neamt", localitate: "Adjudeni" },
  { judet: "Neamt", localitate: "Dumbrava" },
  { judet: "Neamt", localitate: "Plaiesu" },
  { judet: "Neamt", localitate: "Preutesti" },
  { judet: "Neamt", localitate: "Zvoranesti" },
  { judet: "Neamt", localitate: "Miron Costin" },
  { judet: "Neamt", localitate: "Aramoaia" },
  { judet: "Neamt", localitate: "Hanul Ancutei" },
  { judet: "Neamt", localitate: "Totoiesti" },
  { judet: "Neamt", localitate: "Davideni" },
  { judet: "Neamt", localitate: "Tibucanii de Jos" },
  { judet: "Neamt", localitate: "Ingaresti" },
  { judet: "Neamt", localitate: "Plugari" },
  { judet: "Neamt", localitate: "Bucium" },
  { judet: "Neamt", localitate: "Chilii" },
  { judet: "Neamt", localitate: "Giurgeni" },
  { judet: "Neamt", localitate: "Muncelu de Jos" },
  { judet: "Neamt", localitate: "Lunca (Vanatori-Neamt)" },
  { judet: "Neamt", localitate: "Manastirea Neamt" },
  { judet: "Neamt", localitate: "Nemtisor" },
  { judet: "Neamt", localitate: "Agarcia" },
  { judet: "Neamt", localitate: "Bisericani" },
  { judet: "Neamt", localitate: "Bistrita" },
  { judet: "Neamt", localitate: "Scaricica" },
  { judet: "Neamt", localitate: "Vaduri" },
  { judet: "Neamt", localitate: "Vadurele (Alexandru cel Bun)" },
  { judet: "Neamt", localitate: "Viisoara" },
  { judet: "Neamt", localitate: "Traian (Zanesti)" },
  { judet: "Olt", localitate: "Slatina" },
  { judet: "Olt", localitate: "Corabia" },
  { judet: "Olt", localitate: "Caracal" },
  { judet: "Olt", localitate: "Bals" },
  { judet: "Olt", localitate: "Draganesti-Olt" },
  { judet: "Olt", localitate: "Cungrea" },
  { judet: "Olt", localitate: "Icoana" },
  { judet: "Olt", localitate: "Potcoava" },
  { judet: "Olt", localitate: "Scornicesti" },
  { judet: "Olt", localitate: "Piatra Olt" },
  { judet: "Olt", localitate: "Baldovinesti" },
  { judet: "Olt", localitate: "Babiciu" },
  { judet: "Olt", localitate: "Balteni" },
  { judet: "Olt", localitate: "Barasti" },
  { judet: "Olt", localitate: "Barza" },
  { judet: "Olt", localitate: "Bobicesti" },
  { judet: "Olt", localitate: "Brastavatu" },
  { judet: "Olt", localitate: "Brancoveni" },
  { judet: "Olt", localitate: "Brebeni" },
  { judet: "Olt", localitate: "Calui" },
  { judet: "Olt", localitate: "Carlogani" },
  { judet: "Olt", localitate: "Cezieni" },
  { judet: "Olt", localitate: "Cilieni" },
  { judet: "Olt", localitate: "Colonesti" },
  { judet: "Olt", localitate: "Corbu" },
  { judet: "Olt", localitate: "Coteana" },
  { judet: "Olt", localitate: "Crampoia" },
  { judet: "Olt", localitate: "Curtisoara" },
  { judet: "Olt", localitate: "Daneasa" },
  { judet: "Olt", localitate: "Deveselu" },
  { judet: "Olt", localitate: "Dobretu" },
  { judet: "Olt", localitate: "Dobrosloveni" },
  { judet: "Olt", localitate: "Dobroteasa" },
  { judet: "Olt", localitate: "Dobrun" },
  { judet: "Olt", localitate: "Draghiceni" },
  { judet: "Olt", localitate: "Fagetelu" },
  { judet: "Olt", localitate: "Falcoiu" },
  { judet: "Olt", localitate: "Farcasele" },
  { judet: "Olt", localitate: "Ganeasa" },
  { judet: "Olt", localitate: "Gavanesti" },
  { judet: "Olt", localitate: "Garcov" },
  { judet: "Olt", localitate: "Ghimpeteni" },
  { judet: "Olt", localitate: "Giuvarasti" },
  { judet: "Olt", localitate: "Gostavatu" },
  { judet: "Olt", localitate: "Gradinari" },
  { judet: "Olt", localitate: "Gradinile" },
  { judet: "Olt", localitate: "Grojdibodu" },
  { judet: "Olt", localitate: "Gura Padinii" },
  { judet: "Olt", localitate: "Ianca" },
  { judet: "Olt", localitate: "Iancu Jianu" },
  { judet: "Olt", localitate: "Ipotesti" },
  { judet: "Olt", localitate: "Izbiceni" },
  { judet: "Olt", localitate: "Izvoarele" },
  { judet: "Olt", localitate: "Leleasca" },
  { judet: "Olt", localitate: "Maruntei" },
  { judet: "Olt", localitate: "Mihaesti" },
  { judet: "Olt", localitate: "Milcov" },
  { judet: "Olt", localitate: "Morunglav" },
  { judet: "Olt", localitate: "Movileni" },
  { judet: "Olt", localitate: "Nicolae Titulescu" },
  { judet: "Olt", localitate: "Obarsia" },
  { judet: "Olt", localitate: "Oboga" },
  { judet: "Olt", localitate: "Oporelu" },
  { judet: "Olt", localitate: "Optasi-Magura" },
  { judet: "Olt", localitate: "Orlea" },
  { judet: "Olt", localitate: "Osica de Jos" },
  { judet: "Olt", localitate: "Osica de Sus" },
  { judet: "Olt", localitate: "Parscoveni" },
  { judet: "Olt", localitate: "Perieti" },
  { judet: "Olt", localitate: "Plesoiu" },
  { judet: "Olt", localitate: "Poboru" },
  { judet: "Olt", localitate: "Priseaca" },
  { judet: "Olt", localitate: "Radomiresti" },
  { judet: "Olt", localitate: "Redea" },
  { judet: "Olt", localitate: "Rotunda" },
  { judet: "Olt", localitate: "Rusanesti" },
  { judet: "Olt", localitate: "Samburesti" },
  { judet: "Olt", localitate: "Sarbii-Magura" },
  { judet: "Olt", localitate: "Scarisoara" },
  { judet: "Olt", localitate: "Schitu" },
  { judet: "Olt", localitate: "Seaca" },
  { judet: "Olt", localitate: "Slatioara" },
  { judet: "Olt", localitate: "Spineni" },
  { judet: "Olt", localitate: "Sprancenata" },
  { judet: "Olt", localitate: "Stoenesti" },
  { judet: "Olt", localitate: "Stoicanesti" },
  { judet: "Olt", localitate: "Strejesti" },
  { judet: "Olt", localitate: "Studina" },
  { judet: "Olt", localitate: "Serbanesti" },
  { judet: "Olt", localitate: "Soparlita" },
  { judet: "Olt", localitate: "Stefan cel Mare" },
  { judet: "Olt", localitate: "Tatulesti" },
  { judet: "Olt", localitate: "Teslui" },
  { judet: "Olt", localitate: "Tia Mare" },
  { judet: "Olt", localitate: "Topana" },
  { judet: "Olt", localitate: "Traian" },
  { judet: "Olt", localitate: "Tufeni" },
  { judet: "Olt", localitate: "Urzica" },
  { judet: "Olt", localitate: "Valea Mare" },
  { judet: "Olt", localitate: "Vadastra" },
  { judet: "Olt", localitate: "Vadastrita" },
  { judet: "Olt", localitate: "Valeni" },
  { judet: "Olt", localitate: "Valcele" },
  { judet: "Olt", localitate: "Verguleasa" },
  { judet: "Olt", localitate: "Visina" },
  { judet: "Olt", localitate: "Visina Noua" },
  { judet: "Olt", localitate: "Vitomiresti" },
  { judet: "Olt", localitate: "Vladila" },
  { judet: "Olt", localitate: "Voineasa" },
  { judet: "Olt", localitate: "Vulpeni" },
  { judet: "Olt", localitate: "Vulturesti" },
  { judet: "Olt", localitate: "Cireasov" },
  { judet: "Olt", localitate: "Corbeni" },
  { judet: "Olt", localitate: "Romana" },
  { judet: "Olt", localitate: "Teis" },
  { judet: "Olt", localitate: "Tudor Vladimirescu" },
  { judet: "Olt", localitate: "Vartopu" },
  { judet: "Olt", localitate: "Comani" },
  { judet: "Olt", localitate: "Bistrita Noua" },
  { judet: "Olt", localitate: "Criva de Jos" },
  { judet: "Olt", localitate: "Criva de Sus" },
  { judet: "Olt", localitate: "Enosesti" },
  { judet: "Olt", localitate: "Piatra" },
  { judet: "Olt", localitate: "Baltati" },
  { judet: "Olt", localitate: "Bircii" },
  { judet: "Olt", localitate: "Chiteasca" },
  { judet: "Olt", localitate: "Constantinesti" },
  { judet: "Olt", localitate: "Jitaru" },
  { judet: "Olt", localitate: "Margineni-Slobozia" },
  { judet: "Olt", localitate: "Mihailesti-Popesti" },
  { judet: "Olt", localitate: "Mogosesti" },
  { judet: "Olt", localitate: "Negreni" },
  { judet: "Olt", localitate: "Piscani" },
  { judet: "Olt", localitate: "Rusciori" },
  { judet: "Olt", localitate: "Suica" },
  { judet: "Olt", localitate: "Teius" },
  { judet: "Olt", localitate: "Baleasa" },
  { judet: "Olt", localitate: "Brosteni" },
  { judet: "Olt", localitate: "Damburile" },
  { judet: "Olt", localitate: "Gubandru" },
  { judet: "Olt", localitate: "Pietris" },
  { judet: "Olt", localitate: "Barastii de Cepturi" },
  { judet: "Olt", localitate: "Barastii de Vede" },
  { judet: "Olt", localitate: "Boroesti" },
  { judet: "Olt", localitate: "Ciocanesti" },
  { judet: "Olt", localitate: "Lazaresti" },
  { judet: "Olt", localitate: "Mereni" },
  { judet: "Olt", localitate: "Motoesti" },
  { judet: "Olt", localitate: "Popesti (Barasti)" },
  { judet: "Olt", localitate: "Branet" },
  { judet: "Olt", localitate: "Bechet" },
  { judet: "Olt", localitate: "Belgun" },
  { judet: "Olt", localitate: "Chintesti" },
  { judet: "Olt", localitate: "Comanesti" },
  { judet: "Olt", localitate: "Govora" },
  { judet: "Olt", localitate: "Leotesti" },
  { judet: "Olt", localitate: "Mirila" },
  { judet: "Olt", localitate: "Crusovu" },
  { judet: "Olt", localitate: "Margheni" },
  { judet: "Olt", localitate: "Ociogi" },
  { judet: "Olt", localitate: "Valeni (Brancoveni)" },
  { judet: "Olt", localitate: "Teiusu" },
  { judet: "Olt", localitate: "Bucinisu" },
  { judet: "Olt", localitate: "Bucinisu Mic" },
  { judet: "Olt", localitate: "Beculesti" },
  { judet: "Olt", localitate: "Cepari" },
  { judet: "Olt", localitate: "Scorbura" },
  { judet: "Olt", localitate: "Stupina" },
  { judet: "Olt", localitate: "Bondrea" },
  { judet: "Olt", localitate: "Corlatesti" },
  { judet: "Olt", localitate: "Barasti (Colonesti)" },
  { judet: "Olt", localitate: "Batareni" },
  { judet: "Olt", localitate: "Carstani" },
  { judet: "Olt", localitate: "Chelbesti" },
  { judet: "Olt", localitate: "Guesti" },
  { judet: "Olt", localitate: "Maruntei (Colonesti)" },
  { judet: "Olt", localitate: "Navargeni" },
  { judet: "Olt", localitate: "Vlaici" },
  { judet: "Olt", localitate: "Burdulesti" },
  { judet: "Olt", localitate: "Buzesti" },
  { judet: "Olt", localitate: "Ciuresti" },
  { judet: "Olt", localitate: "Milcoveni" },
  { judet: "Olt", localitate: "Buta" },
  { judet: "Olt", localitate: "Cepesti" },
  { judet: "Olt", localitate: "Ibanesti" },
  { judet: "Olt", localitate: "Miesti" },
  { judet: "Olt", localitate: "Otestii de Jos" },
  { judet: "Olt", localitate: "Otestii de Sus" },
  { judet: "Olt", localitate: "Spataru" },
  { judet: "Olt", localitate: "Dobrotinet" },
  { judet: "Olt", localitate: "Linia din Vale" },
  { judet: "Olt", localitate: "Pietrisu" },
  { judet: "Olt", localitate: "Proaspeti" },
  { judet: "Olt", localitate: "Raitiu" },
  { judet: "Olt", localitate: "Berindei" },
  { judet: "Olt", localitate: "Cioflanu" },
  { judet: "Olt", localitate: "Pestra" },
  { judet: "Olt", localitate: "Zanoaga" },
  { judet: "Olt", localitate: "Comanca" },
  { judet: "Olt", localitate: "Curtisoara (Dobretu)" },
  { judet: "Olt", localitate: "Horezu" },
  { judet: "Olt", localitate: "Frasinetu" },
  { judet: "Olt", localitate: "Potopinu" },
  { judet: "Olt", localitate: "Resca" },
  { judet: "Olt", localitate: "Rescuta" },
  { judet: "Olt", localitate: "Batia" },
  { judet: "Olt", localitate: "Campu Mare" },
  { judet: "Olt", localitate: "Vulpesti" },
  { judet: "Olt", localitate: "Bobu" },
  { judet: "Olt", localitate: "Chilii" },
  { judet: "Olt", localitate: "Rosienii Mari" },
  { judet: "Olt", localitate: "Rosienii Mici" },
  { judet: "Olt", localitate: "Ulmet" },
  { judet: "Olt", localitate: "Grozavesti" },
  { judet: "Olt", localitate: "Liiceni" },
  { judet: "Olt", localitate: "Bagesti" },
  { judet: "Olt", localitate: "Chilia" },
  { judet: "Olt", localitate: "Gruiu" },
  { judet: "Olt", localitate: "Isaci" },
  { judet: "Olt", localitate: "Pielcani" },
  { judet: "Olt", localitate: "Cioroiasu" },
  { judet: "Olt", localitate: "Cioroiu" },
  { judet: "Olt", localitate: "Farcasu de Jos" },
  { judet: "Olt", localitate: "Ghimpati" },
  { judet: "Olt", localitate: "Hotarani" },
  { judet: "Olt", localitate: "Dranovatu" },
  { judet: "Olt", localitate: "Gradistea" },
  { judet: "Olt", localitate: "Izvoru" },
  { judet: "Olt", localitate: "Oltisoru" },
  { judet: "Olt", localitate: "Ursa" },
  { judet: "Olt", localitate: "Slaveni" },
  { judet: "Olt", localitate: "Petculesti" },
  { judet: "Olt", localitate: "Runcu Mare" },
  { judet: "Olt", localitate: "Satu Nou (Gradinari)" },
  { judet: "Olt", localitate: "Hotaru" },
  { judet: "Olt", localitate: "Potelu" },
  { judet: "Olt", localitate: "Dobriceni" },
  { judet: "Olt", localitate: "Preotesti" },
  { judet: "Olt", localitate: "Floru" },
  { judet: "Olt", localitate: "Ursoaia" },
  { judet: "Olt", localitate: "Alimanesti" },
  { judet: "Olt", localitate: "Afumati" },
  { judet: "Olt", localitate: "Greeresti" },
  { judet: "Olt", localitate: "Mierlicesti" },
  { judet: "Olt", localitate: "Tonesti (Leleasca)" },
  { judet: "Olt", localitate: "Tufaru" },
  { judet: "Olt", localitate: "Ursi" },
  { judet: "Olt", localitate: "Balanesti" },
  { judet: "Olt", localitate: "Malu Rosu" },
  { judet: "Olt", localitate: "Busca" },
  { judet: "Olt", localitate: "Milcovu din Deal" },
  { judet: "Olt", localitate: "Milcovu din Vale" },
  { judet: "Olt", localitate: "Stejaru" },
  { judet: "Olt", localitate: "Ulmi" },
  { judet: "Olt", localitate: "Barasti (Morunglav)" },
  { judet: "Olt", localitate: "Ghiosani" },
  { judet: "Olt", localitate: "Morunesti" },
  { judet: "Olt", localitate: "Poiana Mare" },
  { judet: "Olt", localitate: "Bacea" },
  { judet: "Olt", localitate: "Ghimpetenii Noi" },
  { judet: "Olt", localitate: "Campu Parului" },
  { judet: "Olt", localitate: "Coteni" },
  { judet: "Olt", localitate: "Obarsia Noua" },
  { judet: "Olt", localitate: "Tabonu" },
  { judet: "Olt", localitate: "Gura Caluiu" },
  { judet: "Olt", localitate: "Beria de Jos" },
  { judet: "Olt", localitate: "Beria de Sus" },
  { judet: "Olt", localitate: "Radesti" },
  { judet: "Olt", localitate: "Optasi" },
  { judet: "Olt", localitate: "Vitanesti" },
  { judet: "Olt", localitate: "Orlea Noua" },
  { judet: "Olt", localitate: "Satu Nou (Gura Padinii)" },
  { judet: "Olt", localitate: "Greci (Osica de Sus)" },
  { judet: "Olt", localitate: "Ostrov" },
  { judet: "Olt", localitate: "Peretu" },
  { judet: "Olt", localitate: "Tomeni" },
  { judet: "Olt", localitate: "Vladuleni" },
  { judet: "Olt", localitate: "Butoi" },
  { judet: "Olt", localitate: "Olari" },
  { judet: "Olt", localitate: "Magura (Perieti)" },
  { judet: "Olt", localitate: "Mierlestii de Sus" },
  { judet: "Olt", localitate: "Arcesti" },
  { judet: "Olt", localitate: "Arcesti-Cot" },
  { judet: "Olt", localitate: "Cocorasti" },
  { judet: "Olt", localitate: "Doba" },
  { judet: "Olt", localitate: "Schitu din Deal" },
  { judet: "Olt", localitate: "Schitu din Vale" },
  { judet: "Olt", localitate: "Albesti" },
  { judet: "Olt", localitate: "Cornatelu" },
  { judet: "Olt", localitate: "Creti" },
  { judet: "Olt", localitate: "Seaca (Poboru)" },
  { judet: "Olt", localitate: "Surpeni" },
  { judet: "Olt", localitate: "Potcoava-Falcoeni" },
  { judet: "Olt", localitate: "Sinesti" },
  { judet: "Olt", localitate: "Trufinesti" },
  { judet: "Olt", localitate: "Valea Merilor" },
  { judet: "Olt", localitate: "Buicesti" },
  { judet: "Olt", localitate: "Saltanesti" },
  { judet: "Olt", localitate: "Calinesti" },
  { judet: "Olt", localitate: "Craciunei" },
  { judet: "Olt", localitate: "Poiana" },
  { judet: "Olt", localitate: "Redisoara" },
  { judet: "Olt", localitate: "Valea Soarelui" },
  { judet: "Olt", localitate: "Jieni" },
  { judet: "Olt", localitate: "Cerbeni" },
  { judet: "Olt", localitate: "Ionicesti" },
  { judet: "Olt", localitate: "Launele" },
  { judet: "Olt", localitate: "Manulesti" },
  { judet: "Olt", localitate: "Stanuleasa (Samburesti)" },
  { judet: "Olt", localitate: "Tonesti (Samburesti)" },
  { judet: "Olt", localitate: "Plaviceni" },
  { judet: "Olt", localitate: "Rudari" },
  { judet: "Olt", localitate: "Catanele" },
  { judet: "Olt", localitate: "Greci (Schitu)" },
  { judet: "Olt", localitate: "Lisa" },
  { judet: "Olt", localitate: "Mosteni" },
  { judet: "Olt", localitate: "Salcia" },
  { judet: "Olt", localitate: "Alunisu" },
  { judet: "Olt", localitate: "Cuza Voda" },
  { judet: "Olt", localitate: "Davidesti" },
  { judet: "Olt", localitate: "Optasani" },
  { judet: "Olt", localitate: "Profa" },
  { judet: "Olt", localitate: "Vineti" },
  { judet: "Olt", localitate: "Barsestii de Sus" },
  { judet: "Olt", localitate: "Frunzaru" },
  { judet: "Olt", localitate: "Uria" },
  { judet: "Olt", localitate: "Colibasi" },
  { judet: "Olt", localitate: "Mamura" },
  { judet: "Olt", localitate: "Strejestii de Sus" },
  { judet: "Olt", localitate: "Arvateasca" },
  { judet: "Olt", localitate: "Plaviceanca" },
  { judet: "Olt", localitate: "Studinita" },
  { judet: "Olt", localitate: "Strugurelu" },
  { judet: "Olt", localitate: "Serbanestii de Sus" },
  { judet: "Olt", localitate: "Ianca Noua" },
  { judet: "Olt", localitate: "Barbalai" },
  { judet: "Olt", localitate: "Lunca" },
  { judet: "Olt", localitate: "Magura (Tatulesti)" },
  { judet: "Olt", localitate: "Mircesti" },
  { judet: "Olt", localitate: "Momaiu" },
  { judet: "Olt", localitate: "Cherlestii Mosteni" },
  { judet: "Olt", localitate: "Cherlestii din Deal" },
  { judet: "Olt", localitate: "Comanita" },
  { judet: "Olt", localitate: "Corbu (Teslui)" },
  { judet: "Olt", localitate: "Deleni" },
  { judet: "Olt", localitate: "Schitu Deleni" },
  { judet: "Olt", localitate: "Doanca" },
  { judet: "Olt", localitate: "Potlogeni" },
  { judet: "Olt", localitate: "Candelesti" },
  { judet: "Olt", localitate: "Cioraca" },
  { judet: "Olt", localitate: "Cojgarei" },
  { judet: "Olt", localitate: "Ungureni" },
  { judet: "Olt", localitate: "Stoborasti" },
  { judet: "Olt", localitate: "Stavaru" },
  { judet: "Olt", localitate: "Barca" },
  { judet: "Olt", localitate: "Recea" },
  { judet: "Olt", localitate: "Turia" },
  { judet: "Olt", localitate: "Zorleasca" },
  { judet: "Olt", localitate: "Mandra" },
  { judet: "Olt", localitate: "Popesti (Valeni)" },
  { judet: "Olt", localitate: "Tirisneag" },
  { judet: "Olt", localitate: "Barcanesti" },
  { judet: "Olt", localitate: "Valcelele de Sus" },
  { judet: "Olt", localitate: "Cazanesti" },
  { judet: "Olt", localitate: "Cucueti" },
  { judet: "Olt", localitate: "Dumitresti" },
  { judet: "Olt", localitate: "Poganu" },
  { judet: "Olt", localitate: "Valea Fetei" },
  { judet: "Olt", localitate: "Vanesti" },
  { judet: "Olt", localitate: "Bulimanu" },
  { judet: "Olt", localitate: "Dejesti" },
  { judet: "Olt", localitate: "Donesti" },
  { judet: "Olt", localitate: "Stanuleasa (Vitomiresti)" },
  { judet: "Olt", localitate: "Trepteni" },
  { judet: "Olt", localitate: "Frasinet-Gara" },
  { judet: "Olt", localitate: "Vladila Noua" },
  { judet: "Olt", localitate: "Blaj" },
  { judet: "Olt", localitate: "Margaritesti" },
  { judet: "Olt", localitate: "Racovita" },
  { judet: "Olt", localitate: "Rusanestii de Sus" },
  { judet: "Olt", localitate: "Cotorbesti" },
  { judet: "Olt", localitate: "Gropsani" },
  { judet: "Olt", localitate: "Mardale" },
  { judet: "Olt", localitate: "Pescaresti" },
  { judet: "Olt", localitate: "Plopsorelu" },
  { judet: "Olt", localitate: "Prisaca" },
  { judet: "Olt", localitate: "Simniceni" },
  { judet: "Olt", localitate: "Tabaci" },
  { judet: "Olt", localitate: "Valea Satului" },
  { judet: "Olt", localitate: "Dienci" },
  { judet: "Olt", localitate: "Valea lui Alb" },
  { judet: "Olt", localitate: "Vlangaresti" },
  { judet: "Prahova", localitate: "Ploiesti" },
  { judet: "Prahova", localitate: "Campina" },
  { judet: "Prahova", localitate: "Plopeni" },
  { judet: "Prahova", localitate: "Valenii de Munte" },
  { judet: "Prahova", localitate: "Sinaia" },
  { judet: "Prahova", localitate: "Comarnic" },
  { judet: "Prahova", localitate: "Pleasa" },
  { judet: "Prahova", localitate: "Mizil" },
  { judet: "Prahova", localitate: "Baicoi" },
  { judet: "Prahova", localitate: "Blejoi" },
  { judet: "Prahova", localitate: "Boldesti Scaeni" },
  { judet: "Prahova", localitate: "Busteni" },
  { judet: "Prahova", localitate: "Ceptura" },
  { judet: "Prahova", localitate: "Azuga" },
  { judet: "Prahova", localitate: "Breaza" },
  { judet: "Prahova", localitate: "Slanic" },
  { judet: "Prahova", localitate: "Urlati" },
  { judet: "Prahova", localitate: "Adunati" },
  { judet: "Prahova", localitate: "Albesti-Paleologu" },
  { judet: "Prahova", localitate: "Alunis" },
  { judet: "Prahova", localitate: "Apostolache" },
  { judet: "Prahova", localitate: "Aricestii Rahtivani" },
  { judet: "Prahova", localitate: "Aricestii Zeletin" },
  { judet: "Prahova", localitate: "Baba Ana" },
  { judet: "Prahova", localitate: "Balta Doamnei" },
  { judet: "Prahova", localitate: "Baltesti" },
  { judet: "Prahova", localitate: "Banesti" },
  { judet: "Prahova", localitate: "Barcanesti" },
  { judet: "Prahova", localitate: "Batrani" },
  { judet: "Prahova", localitate: "Berceni" },
  { judet: "Prahova", localitate: "Bertea" },
  { judet: "Prahova", localitate: "Boldesti-Gradistea" },
  { judet: "Prahova", localitate: "Brazi" },
  { judet: "Prahova", localitate: "Brebu" },
  { judet: "Prahova", localitate: "Bucov" },
  { judet: "Prahova", localitate: "Calugareni" },
  { judet: "Prahova", localitate: "Carbunesti" },
  { judet: "Prahova", localitate: "Cerasu" },
  { judet: "Prahova", localitate: "Chiojdeanca" },
  { judet: "Prahova", localitate: "Ciorani" },
  { judet: "Prahova", localitate: "Cocorastii Mislii" },
  { judet: "Prahova", localitate: "Cocorastii Colt" },
  { judet: "Prahova", localitate: "Colceag" },
  { judet: "Prahova", localitate: "Cornu" },
  { judet: "Prahova", localitate: "Cosminele" },
  { judet: "Prahova", localitate: "Draganesti" },
  { judet: "Prahova", localitate: "Drajna" },
  { judet: "Prahova", localitate: "Dumbrava" },
  { judet: "Prahova", localitate: "Dumbravesti" },
  { judet: "Prahova", localitate: "Filipestii de Padure" },
  { judet: "Prahova", localitate: "Filipestii de Targ" },
  { judet: "Prahova", localitate: "Fantanele" },
  { judet: "Prahova", localitate: "Floresti" },
  { judet: "Prahova", localitate: "Fulga" },
  { judet: "Prahova", localitate: "Gherghita" },
  { judet: "Prahova", localitate: "Gorgota" },
  { judet: "Prahova", localitate: "Gornet" },
  { judet: "Prahova", localitate: "Gornet-Cricov" },
  { judet: "Prahova", localitate: "Gura Vadului" },
  { judet: "Prahova", localitate: "Gura Vitioarei" },
  { judet: "Prahova", localitate: "Iordacheanu" },
  { judet: "Prahova", localitate: "Izvoarele" },
  { judet: "Prahova", localitate: "Jugureni" },
  { judet: "Prahova", localitate: "Lapos" },
  { judet: "Prahova", localitate: "Lipanesti" },
  { judet: "Prahova", localitate: "Magurele" },
  { judet: "Prahova", localitate: "Magureni" },
  { judet: "Prahova", localitate: "Maneciu" },
  { judet: "Prahova", localitate: "Manesti" },
  { judet: "Prahova", localitate: "Olari" },
  { judet: "Prahova", localitate: "Pacureti" },
  { judet: "Prahova", localitate: "Paulesti" },
  { judet: "Prahova", localitate: "Plopu" },
  { judet: "Prahova", localitate: "Podenii Noi" },
  { judet: "Prahova", localitate: "Poiana Campina" },
  { judet: "Prahova", localitate: "Poienarii Burchii" },
  { judet: "Prahova", localitate: "Posesti" },
  { judet: "Prahova", localitate: "Predeal-Sarari" },
  { judet: "Prahova", localitate: "Provita de Jos" },
  { judet: "Prahova", localitate: "Provita de Sus" },
  { judet: "Prahova", localitate: "Puchenii Mari" },
  { judet: "Prahova", localitate: "Rafov" },
  { judet: "Prahova", localitate: "Salcia" },
  { judet: "Prahova", localitate: "Salciile" },
  { judet: "Prahova", localitate: "Scorteni" },
  { judet: "Prahova", localitate: "Secaria" },
  { judet: "Prahova", localitate: "Sangeru" },
  { judet: "Prahova", localitate: "Sirna" },
  { judet: "Prahova", localitate: "Soimari" },
  { judet: "Prahova", localitate: "Sotrile" },
  { judet: "Prahova", localitate: "Starchiojd" },
  { judet: "Prahova", localitate: "Stefesti" },
  { judet: "Prahova", localitate: "Surani" },
  { judet: "Prahova", localitate: "Talea" },
  { judet: "Prahova", localitate: "Tataru" },
  { judet: "Prahova", localitate: "Teisani" },
  { judet: "Prahova", localitate: "Telega" },
  { judet: "Prahova", localitate: "Tinosu" },
  { judet: "Prahova", localitate: "Targsoru Vechi" },
  { judet: "Prahova", localitate: "Tomsani" },
  { judet: "Prahova", localitate: "Vadu Sapat" },
  { judet: "Prahova", localitate: "Valea Calugareasca" },
  { judet: "Prahova", localitate: "Valea Doftanei" },
  { judet: "Prahova", localitate: "Varbilau" },
  { judet: "Prahova", localitate: "Valcanesti" },
  { judet: "Prahova", localitate: "Dambu" },
  { judet: "Prahova", localitate: "Liliesti" },
  { judet: "Prahova", localitate: "Schela" },
  { judet: "Prahova", localitate: "Tufeni" },
  { judet: "Prahova", localitate: "Tintea" },
  { judet: "Prahova", localitate: "Seciu" },
  { judet: "Prahova", localitate: "Breaza de Jos" },
  { judet: "Prahova", localitate: "Breaza de Sus" },
  { judet: "Prahova", localitate: "Frasinet" },
  { judet: "Prahova", localitate: "Gura Beliei" },
  { judet: "Prahova", localitate: "Irimesti" },
  { judet: "Prahova", localitate: "Nistoresti" },
  { judet: "Prahova", localitate: "Podu Corbului" },
  { judet: "Prahova", localitate: "Podu Vadului" },
  { judet: "Prahova", localitate: "Surdesti" },
  { judet: "Prahova", localitate: "Valea Tarsei" },
  { judet: "Prahova", localitate: "Poiana Tapului" },
  { judet: "Prahova", localitate: "Ghiosesti" },
  { judet: "Prahova", localitate: "Podu Lung" },
  { judet: "Prahova", localitate: "Poiana" },
  { judet: "Prahova", localitate: "Posada" },
  { judet: "Prahova", localitate: "Fefelei" },
  { judet: "Prahova", localitate: "Grosani" },
  { judet: "Prahova", localitate: "Prajani" },
  { judet: "Prahova", localitate: "Arionestii Noi" },
  { judet: "Prahova", localitate: "Arionestii Vechi" },
  { judet: "Prahova", localitate: "Cherba" },
  { judet: "Prahova", localitate: "Jercalai" },
  { judet: "Prahova", localitate: "Maruntis" },
  { judet: "Prahova", localitate: "Orzoaia de Jos" },
  { judet: "Prahova", localitate: "Orzoaia de Sus" },
  { judet: "Prahova", localitate: "Schiau (Urlati)" },
  { judet: "Prahova", localitate: "Ulmi" },
  { judet: "Prahova", localitate: "Valea Bobului" },
  { judet: "Prahova", localitate: "Valea Crangului" },
  { judet: "Prahova", localitate: "Valea Mieilor" },
  { judet: "Prahova", localitate: "Valea Nucetului" },
  { judet: "Prahova", localitate: "Valea Pietrei" },
  { judet: "Prahova", localitate: "Valea Seman" },
  { judet: "Prahova", localitate: "Valea Urloii" },
  { judet: "Prahova", localitate: "Ocina de Jos" },
  { judet: "Prahova", localitate: "Ocina de Sus" },
  { judet: "Prahova", localitate: "Albesti-Muru" },
  { judet: "Prahova", localitate: "Cioceni" },
  { judet: "Prahova", localitate: "Vadu Parului" },
  { judet: "Prahova", localitate: "Ostrovu" },
  { judet: "Prahova", localitate: "Buzota" },
  { judet: "Prahova", localitate: "Marlogea" },
  { judet: "Prahova", localitate: "Udresti" },
  { judet: "Prahova", localitate: "Valea Cricovului" },
  { judet: "Prahova", localitate: "Buda (Aricestii Rahtivani)" },
  { judet: "Prahova", localitate: "Nedelea" },
  { judet: "Prahova", localitate: "Stoenesti" },
  { judet: "Prahova", localitate: "Targsoru Nou" },
  { judet: "Prahova", localitate: "Albinari" },
  { judet: "Prahova", localitate: "Ciresanu" },
  { judet: "Prahova", localitate: "Conduratu" },
  { judet: "Prahova", localitate: "Crangurile" },
  { judet: "Prahova", localitate: "Satu Nou (Baba Ana)" },
  { judet: "Prahova", localitate: "Bara" },
  { judet: "Prahova", localitate: "Curcubeu" },
  { judet: "Prahova", localitate: "Lacu Turcului" },
  { judet: "Prahova", localitate: "Izesti" },
  { judet: "Prahova", localitate: "Podenii Vechi" },
  { judet: "Prahova", localitate: "Urleta" },
  { judet: "Prahova", localitate: "Ghighiu" },
  { judet: "Prahova", localitate: "Puscasi" },
  { judet: "Prahova", localitate: "Romanesti" },
  { judet: "Prahova", localitate: "Tatarani" },
  { judet: "Prahova", localitate: "Cartierul Dambu" },
  { judet: "Prahova", localitate: "Catunu (Berceni)" },
  { judet: "Prahova", localitate: "Corlatesti" },
  { judet: "Prahova", localitate: "Moara Noua" },
  { judet: "Prahova", localitate: "Lutu Rosu" },
  { judet: "Prahova", localitate: "Ploiestiori" },
  { judet: "Prahova", localitate: "Tantareni" },
  { judet: "Prahova", localitate: "Boldesti" },
  { judet: "Prahova", localitate: "Gradistea" },
  { judet: "Prahova", localitate: "Batesti" },
  { judet: "Prahova", localitate: "Brazii de Jos" },
  { judet: "Prahova", localitate: "Brazii de Sus" },
  { judet: "Prahova", localitate: "Negoiesti" },
  { judet: "Prahova", localitate: "Popesti (Brazi)" },
  { judet: "Prahova", localitate: "Stejaru" },
  { judet: "Prahova", localitate: "Colonia Brazi" },
  { judet: "Prahova", localitate: "Brebu Megiesesc" },
  { judet: "Prahova", localitate: "Pietriceaua" },
  { judet: "Prahova", localitate: "Podu Cheii" },
  { judet: "Prahova", localitate: "Brebu Manastirei" },
  { judet: "Prahova", localitate: "Bighilin" },
  { judet: "Prahova", localitate: "Chitorani" },
  { judet: "Prahova", localitate: "Valea Orlei" },
  { judet: "Prahova", localitate: "Valea Scheilor" },
  { judet: "Prahova", localitate: "Gogeasca" },
  { judet: "Prahova", localitate: "Ceptura de Jos" },
  { judet: "Prahova", localitate: "Malu Rosu" },
  { judet: "Prahova", localitate: "Rotari" },
  { judet: "Prahova", localitate: "Soimesti" },
  { judet: "Prahova", localitate: "Ceptura de Sus" },
  { judet: "Prahova", localitate: "Slon" },
  { judet: "Prahova", localitate: "Valea Borului" },
  { judet: "Prahova", localitate: "Valea Bradetului" },
  { judet: "Prahova", localitate: "Valea Lespezii" },
  { judet: "Prahova", localitate: "Valea Tocii" },
  { judet: "Prahova", localitate: "Nucet (Chiojdeanca)" },
  { judet: "Prahova", localitate: "Trenu" },
  { judet: "Prahova", localitate: "Cioranii de Sus" },
  { judet: "Prahova", localitate: "Cioranii de Jos" },
  { judet: "Prahova", localitate: "Goruna" },
  { judet: "Prahova", localitate: "Tiparesti" },
  { judet: "Prahova", localitate: "Inotesti" },
  { judet: "Prahova", localitate: "Parepa-Rusani" },
  { judet: "Prahova", localitate: "Valcelele" },
  { judet: "Prahova", localitate: "Cornu de Sus (Cornu)" },
  { judet: "Prahova", localitate: "Valea Oprii" },
  { judet: "Prahova", localitate: "Cornu de Jos (Cornu)" },
  { judet: "Prahova", localitate: "Cosmina de Jos" },
  { judet: "Prahova", localitate: "Cosmina de Sus" },
  { judet: "Prahova", localitate: "Draghicesti" },
  { judet: "Prahova", localitate: "Poiana Trestiei" },
  { judet: "Prahova", localitate: "Catunu (Drajna)" },
  { judet: "Prahova", localitate: "Ciocrac" },
  { judet: "Prahova", localitate: "Drajna de Jos" },
  { judet: "Prahova", localitate: "Drajna de Sus" },
  { judet: "Prahova", localitate: "Faget" },
  { judet: "Prahova", localitate: "Ogretin" },
  { judet: "Prahova", localitate: "Piatra (Drajna)" },
  { judet: "Prahova", localitate: "Pitigoi" },
  { judet: "Prahova", localitate: "Plai" },
  { judet: "Prahova", localitate: "Podurile" },
  { judet: "Prahova", localitate: "Poiana Mierlei" },
  { judet: "Prahova", localitate: "Baraitaru" },
  { judet: "Prahova", localitate: "Belciug" },
  { judet: "Prahova", localitate: "Cornu de Jos (Draganesti)" },
  { judet: "Prahova", localitate: "Hatcarau" },
  { judet: "Prahova", localitate: "Meri" },
  { judet: "Prahova", localitate: "Tufani" },
  { judet: "Prahova", localitate: "Ciupelnita" },
  { judet: "Prahova", localitate: "Cornu de Sus (Dumbrava)" },
  { judet: "Prahova", localitate: "Trestienii de Jos" },
  { judet: "Prahova", localitate: "Trestienii de Sus" },
  { judet: "Prahova", localitate: "Zanoaga" },
  { judet: "Prahova", localitate: "Gavanel" },
  { judet: "Prahova", localitate: "Malaestii de Jos" },
  { judet: "Prahova", localitate: "Malaestii de Sus" },
  { judet: "Prahova", localitate: "Plopeni (Dumbravesti)" },
  { judet: "Prahova", localitate: "Sfarleanca" },
  { judet: "Prahova", localitate: "Bozieni" },
  { judet: "Prahova", localitate: "Ghinoaica" },
  { judet: "Prahova", localitate: "Ungureni (Vadu Sapat)" },
  { judet: "Prahova", localitate: "Ditesti" },
  { judet: "Prahova", localitate: "Minieri" },
  { judet: "Prahova", localitate: "Silistea Dealului" },
  { judet: "Prahova", localitate: "Bratasanca" },
  { judet: "Prahova", localitate: "Ezeni" },
  { judet: "Prahova", localitate: "Marginenii de Jos" },
  { judet: "Prahova", localitate: "Ungureni (Filipestii de Targ)" },
  { judet: "Prahova", localitate: "Cap Rosu" },
  { judet: "Prahova", localitate: "Calinesti" },
  { judet: "Prahova", localitate: "Catina" },
  { judet: "Prahova", localitate: "Novacesti" },
  { judet: "Prahova", localitate: "Fulga de Jos" },
  { judet: "Prahova", localitate: "Fulga de Sus" },
  { judet: "Prahova", localitate: "Fanari (Olari)" },
  { judet: "Prahova", localitate: "Independenta" },
  { judet: "Prahova", localitate: "Malamuc" },
  { judet: "Prahova", localitate: "Olarii Vechi" },
  { judet: "Prahova", localitate: "Ungureni (Gherghita)" },
  { judet: "Prahova", localitate: "Crivina" },
  { judet: "Prahova", localitate: "Fanari (Gorgota)" },
  { judet: "Prahova", localitate: "Poienarii Apostoli" },
  { judet: "Prahova", localitate: "Potigrafu" },
  { judet: "Prahova", localitate: "Bogdanesti" },
  { judet: "Prahova", localitate: "Cuib" },
  { judet: "Prahova", localitate: "Nucet (Gornet)" },
  { judet: "Prahova", localitate: "Coserele" },
  { judet: "Prahova", localitate: "Dobrota" },
  { judet: "Prahova", localitate: "Priseaca" },
  { judet: "Prahova", localitate: "Tarculesti" },
  { judet: "Prahova", localitate: "Valea Seaca" },
  { judet: "Prahova", localitate: "Persunari (Gura Vadului)" },
  { judet: "Prahova", localitate: "Tohani" },
  { judet: "Prahova", localitate: "Bughea de Jos" },
  { judet: "Prahova", localitate: "Fagetu" },
  { judet: "Prahova", localitate: "Fundeni" },
  { judet: "Prahova", localitate: "Poiana Copaceni" },
  { judet: "Prahova", localitate: "Mocesti" },
  { judet: "Prahova", localitate: "Plavia" },
  { judet: "Prahova", localitate: "Straosti" },
  { judet: "Prahova", localitate: "Valea Cucului" },
  { judet: "Prahova", localitate: "Varbila" },
  { judet: "Prahova", localitate: "Cernesti" },
  { judet: "Prahova", localitate: "Chiritesti" },
  { judet: "Prahova", localitate: "Homoraciu" },
  { judet: "Prahova", localitate: "Malu Vanat" },
  { judet: "Prahova", localitate: "Schiulesti" },
  { judet: "Prahova", localitate: "Boboci" },
  { judet: "Prahova", localitate: "Marginea Padurii" },
  { judet: "Prahova", localitate: "Valea Unghiului" },
  { judet: "Prahova", localitate: "Glod" },
  { judet: "Prahova", localitate: "Laposel" },
  { judet: "Prahova", localitate: "Pietricica" },
  { judet: "Prahova", localitate: "Satu Nou (Lipanesti)" },
  { judet: "Prahova", localitate: "Sipotu" },
  { judet: "Prahova", localitate: "Zamfira" },
  { judet: "Prahova", localitate: "Coada Malului" },
  { judet: "Prahova", localitate: "Iazu" },
  { judet: "Prahova", localitate: "Cocorastii Caplii" },
  { judet: "Prahova", localitate: "Lunca Prahovei" },
  { judet: "Prahova", localitate: "Cheia" },
  { judet: "Prahova", localitate: "Chiciureni" },
  { judet: "Prahova", localitate: "Costeni" },
  { judet: "Prahova", localitate: "Facaieni" },
  { judet: "Prahova", localitate: "Gheaba" },
  { judet: "Prahova", localitate: "Maneciu-Pamanteni" },
  { judet: "Prahova", localitate: "Manastirea Suzana" },
  { judet: "Prahova", localitate: "Plaietu" },
  { judet: "Prahova", localitate: "Maneciu-Ungureni" },
  { judet: "Prahova", localitate: "Baltita" },
  { judet: "Prahova", localitate: "Chesnoiu" },
  { judet: "Prahova", localitate: "Coada Izvorului" },
  { judet: "Prahova", localitate: "Cocorastii Grind" },
  { judet: "Prahova", localitate: "Coltu de Jos" },
  { judet: "Prahova", localitate: "Ghioldum" },
  { judet: "Prahova", localitate: "Gura Crivatului" },
  { judet: "Prahova", localitate: "Persunari (Cocorastii Colt)" },
  { judet: "Prahova", localitate: "Piatra (Cocorastii Colt)" },
  { judet: "Prahova", localitate: "Satu de Sus" },
  { judet: "Prahova", localitate: "Zalhanaua" },
  { judet: "Prahova", localitate: "Barzila" },
  { judet: "Prahova", localitate: "Curmatura" },
  { judet: "Prahova", localitate: "Matita" },
  { judet: "Prahova", localitate: "Slavu" },
  { judet: "Prahova", localitate: "Cocosesti" },
  { judet: "Prahova", localitate: "Gageni" },
  { judet: "Prahova", localitate: "Paulestii Noi" },
  { judet: "Prahova", localitate: "Galmeia" },
  { judet: "Prahova", localitate: "Harsa" },
  { judet: "Prahova", localitate: "Nisipoasa" },
  { judet: "Prahova", localitate: "Ghiocel" },
  { judet: "Prahova", localitate: "Mehedinta" },
  { judet: "Prahova", localitate: "Nevesteasca" },
  { judet: "Prahova", localitate: "Podu lui Galben" },
  { judet: "Prahova", localitate: "Popesti (Podenii Noi)" },
  { judet: "Prahova", localitate: "Rahova" },
  { judet: "Prahova", localitate: "Salcioara" },
  { judet: "Prahova", localitate: "Sfacaru" },
  { judet: "Prahova", localitate: "Valea Dulce" },
  { judet: "Prahova", localitate: "Bobolia" },
  { judet: "Prahova", localitate: "Pietrisu" },
  { judet: "Prahova", localitate: "Ragman" },
  { judet: "Prahova", localitate: "Carbunari" },
  { judet: "Prahova", localitate: "Ologeni" },
  { judet: "Prahova", localitate: "Pioresti" },
  { judet: "Prahova", localitate: "Podu Valeni" },
  { judet: "Prahova", localitate: "Poienarii Vechi" },
  { judet: "Prahova", localitate: "Poienarii-Rali" },
  { judet: "Prahova", localitate: "Tatarai" },
  { judet: "Prahova", localitate: "Bodesti" },
  { judet: "Prahova", localitate: "Merdeala" },
  { judet: "Prahova", localitate: "Nucsoara de Jos" },
  { judet: "Prahova", localitate: "Nucsoara de Sus" },
  { judet: "Prahova", localitate: "Posestii-Pamanteni" },
  { judet: "Prahova", localitate: "Posestii-Ungureni" },
  { judet: "Prahova", localitate: "Tarlesti" },
  { judet: "Prahova", localitate: "Valea Plopului" },
  { judet: "Prahova", localitate: "Valea Screzii" },
  { judet: "Prahova", localitate: "Valea Stupinii" },
  { judet: "Prahova", localitate: "Bobicesti" },
  { judet: "Prahova", localitate: "Poienile" },
  { judet: "Prahova", localitate: "Predeal" },
  { judet: "Prahova", localitate: "Sarari" },
  { judet: "Prahova", localitate: "Saratel" },
  { judet: "Prahova", localitate: "Tulburea" },
  { judet: "Prahova", localitate: "Tulburea-Valeni" },
  { judet: "Prahova", localitate: "Vitioara de Sus" },
  { judet: "Prahova", localitate: "Zambroaia" },
  { judet: "Prahova", localitate: "Draganeasa" },
  { judet: "Prahova", localitate: "Piatra (Provita de Jos)" },
  { judet: "Prahova", localitate: "Izvoru" },
  { judet: "Prahova", localitate: "Plaiu (Provita de Sus)" },
  { judet: "Prahova", localitate: "Valea Bradului" },
  { judet: "Prahova", localitate: "Miroslavesti" },
  { judet: "Prahova", localitate: "Moara" },
  { judet: "Prahova", localitate: "Odaile" },
  { judet: "Prahova", localitate: "Pietrosani" },
  { judet: "Prahova", localitate: "Puchenii Mici" },
  { judet: "Prahova", localitate: "Puchenii-Mosneni" },
  { judet: "Prahova", localitate: "Antofiloaia" },
  { judet: "Prahova", localitate: "Buchilasi" },
  { judet: "Prahova", localitate: "Buda (Rafov)" },
  { judet: "Prahova", localitate: "Goga" },
  { judet: "Prahova", localitate: "Malaiesti" },
  { judet: "Prahova", localitate: "Moara Domneasca" },
  { judet: "Prahova", localitate: "Palanca" },
  { judet: "Prahova", localitate: "Sicrita" },
  { judet: "Prahova", localitate: "Butuci" },
  { judet: "Prahova", localitate: "Miresu Mare" },
  { judet: "Prahova", localitate: "Miresu Mic" },
  { judet: "Prahova", localitate: "Piatra Mica" },
  { judet: "Prahova", localitate: "Tisa" },
  { judet: "Prahova", localitate: "Bordenii Mari" },
  { judet: "Prahova", localitate: "Bordenii Mici" },
  { judet: "Prahova", localitate: "Mislea" },
  { judet: "Prahova", localitate: "Sarca" },
  { judet: "Prahova", localitate: "Bradet" },
  { judet: "Prahova", localitate: "Gresia" },
  { judet: "Prahova", localitate: "Poiana Mare" },
  { judet: "Prahova", localitate: "Rotarea" },
  { judet: "Prahova", localitate: "Valea Anei" },
  { judet: "Prahova", localitate: "Zmeuret" },
  { judet: "Prahova", localitate: "Pacuri" },
  { judet: "Prahova", localitate: "Bratesti" },
  { judet: "Prahova", localitate: "Coceana" },
  { judet: "Prahova", localitate: "Habud" },
  { judet: "Prahova", localitate: "Tariceni" },
  { judet: "Prahova", localitate: "Varnita" },
  { judet: "Prahova", localitate: "Lopatnita" },
  { judet: "Prahova", localitate: "Magura" },
  { judet: "Prahova", localitate: "Lunca Mare" },
  { judet: "Prahova", localitate: "Plaiu Campinei" },
  { judet: "Prahova", localitate: "Plaiu Cornului" },
  { judet: "Prahova", localitate: "Seciuri" },
  { judet: "Prahova", localitate: "Vistieru" },
  { judet: "Prahova", localitate: "Scurtesti" },
  { judet: "Prahova", localitate: "Tarsoreni" },
  { judet: "Prahova", localitate: "Plaiu (Talea)" },
  { judet: "Prahova", localitate: "Podgoria" },
  { judet: "Prahova", localitate: "Silistea" },
  { judet: "Prahova", localitate: "Stancesti" },
  { judet: "Prahova", localitate: "Strejnicu" },
  { judet: "Prahova", localitate: "Zahanaua" },
  { judet: "Prahova", localitate: "Bughea de Sus" },
  { judet: "Prahova", localitate: "Olteni" },
  { judet: "Prahova", localitate: "Stubeiu" },
  { judet: "Prahova", localitate: "Valea Stalpului" },
  { judet: "Prahova", localitate: "Bosilcesti" },
  { judet: "Prahova", localitate: "Bustenari" },
  { judet: "Prahova", localitate: "Doftana" },
  { judet: "Prahova", localitate: "Melicesti" },
  { judet: "Prahova", localitate: "Tontesti" },
  { judet: "Prahova", localitate: "Pisculesti" },
  { judet: "Prahova", localitate: "Predesti" },
  { judet: "Prahova", localitate: "Loloiasca" },
  { judet: "Prahova", localitate: "Magula" },
  { judet: "Prahova", localitate: "Satucu" },
  { judet: "Prahova", localitate: "Arva" },
  { judet: "Prahova", localitate: "Coslegi" },
  { judet: "Prahova", localitate: "Darvari" },
  { judet: "Prahova", localitate: "Pantazi" },
  { judet: "Prahova", localitate: "Rachieri" },
  { judet: "Prahova", localitate: "Radila" },
  { judet: "Prahova", localitate: "Schiau (Valea Calugareasca)" },
  { judet: "Prahova", localitate: "Valea Larga" },
  { judet: "Prahova", localitate: "Valea Mantei" },
  { judet: "Prahova", localitate: "Valea Nicovani" },
  { judet: "Prahova", localitate: "Valea Poienii" },
  { judet: "Prahova", localitate: "Valea Popii" },
  { judet: "Prahova", localitate: "Valea Ursoii" },
  { judet: "Prahova", localitate: "Varfurile" },
  { judet: "Prahova", localitate: "Traisteni" },
  { judet: "Prahova", localitate: "Tesila" },
  { judet: "Prahova", localitate: "Cotofenesti" },
  { judet: "Prahova", localitate: "Livadea" },
  { judet: "Prahova", localitate: "Podu Ursului" },
  { judet: "Prahova", localitate: "Poiana Varbilau" },
  { judet: "Prahova", localitate: "Carjari" },
  { judet: "Prahova", localitate: "Trestioara" },
  { judet: "Salaj", localitate: "Zalau" },
  { judet: "Salaj", localitate: "Simleul Silvaniei" },
  { judet: "Salaj", localitate: "Jibou" },
  { judet: "Salaj", localitate: "Cehu Silvaniei" },
  { judet: "Salaj", localitate: "Agrij" },
  { judet: "Salaj", localitate: "Almasu" },
  { judet: "Salaj", localitate: "Babeni" },
  { judet: "Salaj", localitate: "Banisor" },
  { judet: "Salaj", localitate: "Benesat" },
  { judet: "Salaj", localitate: "Bobota" },
  { judet: "Salaj", localitate: "Bocsa" },
  { judet: "Salaj", localitate: "Boghis" },
  { judet: "Salaj", localitate: "Buciumi" },
  { judet: "Salaj", localitate: "Camar" },
  { judet: "Salaj", localitate: "Carastelec" },
  { judet: "Salaj", localitate: "Chiesd" },
  { judet: "Salaj", localitate: "Cizer" },
  { judet: "Salaj", localitate: "Coseiu" },
  { judet: "Salaj", localitate: "Crasna" },
  { judet: "Salaj", localitate: "Creaca" },
  { judet: "Salaj", localitate: "Criseni" },
  { judet: "Salaj", localitate: "Cristolt" },
  { judet: "Salaj", localitate: "Cuzaplac" },
  { judet: "Salaj", localitate: "Dobrin" },
  { judet: "Salaj", localitate: "Dragu" },
  { judet: "Salaj", localitate: "Fildu de Jos" },
  { judet: "Salaj", localitate: "Galgau" },
  { judet: "Salaj", localitate: "Garbou" },
  { judet: "Salaj", localitate: "Halmasd" },
  { judet: "Salaj", localitate: "Hereclean" },
  { judet: "Salaj", localitate: "Hida" },
  { judet: "Salaj", localitate: "Horoatu Crasnei" },
  { judet: "Salaj", localitate: "Ileanda" },
  { judet: "Salaj", localitate: "Ip" },
  { judet: "Salaj", localitate: "Letca" },
  { judet: "Salaj", localitate: "Lozna" },
  { judet: "Salaj", localitate: "Maeriste" },
  { judet: "Salaj", localitate: "Marca" },
  { judet: "Salaj", localitate: "Mesesenii de Jos" },
  { judet: "Salaj", localitate: "Mirsid" },
  { judet: "Salaj", localitate: "Napradea" },
  { judet: "Salaj", localitate: "Nusfalau" },
  { judet: "Salaj", localitate: "Pericei" },
  { judet: "Salaj", localitate: "Plopis" },
  { judet: "Salaj", localitate: "Poiana Blenchii" },
  { judet: "Salaj", localitate: "Romanasi" },
  { judet: "Salaj", localitate: "Rus" },
  { judet: "Salaj", localitate: "Salatig" },
  { judet: "Salaj", localitate: "Sag" },
  { judet: "Salaj", localitate: "Sanmihaiu Almasului" },
  { judet: "Salaj", localitate: "Somes-Odorhei" },
  { judet: "Salaj", localitate: "Surduc" },
  { judet: "Salaj", localitate: "Samsud" },
  { judet: "Salaj", localitate: "Sarmasag" },
  { judet: "Salaj", localitate: "Simisna" },
  { judet: "Salaj", localitate: "Treznea" },
  { judet: "Salaj", localitate: "Valcau de Jos" },
  { judet: "Salaj", localitate: "Varsolt" },
  { judet: "Salaj", localitate: "Zalha" },
  { judet: "Salaj", localitate: "Zimbor" },
  { judet: "Salaj", localitate: "Ortelec" },
  { judet: "Salaj", localitate: "Stana" },
  { judet: "Salaj", localitate: "Horoatu Cehului" },
  { judet: "Salaj", localitate: "Motis" },
  { judet: "Salaj", localitate: "Nadis" },
  { judet: "Salaj", localitate: "Ulciug" },
  { judet: "Salaj", localitate: "Cuceu" },
  { judet: "Salaj", localitate: "Husia" },
  { judet: "Salaj", localitate: "Rona" },
  { judet: "Salaj", localitate: "Var" },
  { judet: "Salaj", localitate: "Bic" },
  { judet: "Salaj", localitate: "Cehei" },
  { judet: "Salaj", localitate: "Pusta" },
  { judet: "Salaj", localitate: "Rastoltu Desert" },
  { judet: "Salaj", localitate: "Babiu" },
  { judet: "Salaj", localitate: "Cutis" },
  { judet: "Salaj", localitate: "Jebucu" },
  { judet: "Salaj", localitate: "Mesteacanu" },
  { judet: "Salaj", localitate: "Petrinzel" },
  { judet: "Salaj", localitate: "Sfaras" },
  { judet: "Salaj", localitate: "Stana" },
  { judet: "Salaj", localitate: "Taudu" },
  { judet: "Salaj", localitate: "Ciocmani" },
  { judet: "Salaj", localitate: "Clit" },
  { judet: "Salaj", localitate: "Pirosa" },
  { judet: "Salaj", localitate: "Poienita" },
  { judet: "Salaj", localitate: "Balan" },
  { judet: "Salaj", localitate: "Chechis" },
  { judet: "Salaj", localitate: "Chendrea" },
  { judet: "Salaj", localitate: "Galpaia" },
  { judet: "Salaj", localitate: "Galgau Almasului" },
  { judet: "Salaj", localitate: "Ban" },
  { judet: "Salaj", localitate: "Peceiu" },
  { judet: "Salaj", localitate: "Alunis" },
  { judet: "Salaj", localitate: "Biusa" },
  { judet: "Salaj", localitate: "Dersida" },
  { judet: "Salaj", localitate: "Zalnoc" },
  { judet: "Salaj", localitate: "Borla" },
  { judet: "Salaj", localitate: "Campia" },
  { judet: "Salaj", localitate: "Salajeni" },
  { judet: "Salaj", localitate: "Bodia" },
  { judet: "Salaj", localitate: "Bogdana" },
  { judet: "Salaj", localitate: "Huta" },
  { judet: "Salaj", localitate: "Rastolt" },
  { judet: "Salaj", localitate: "Sangeorgiu de Meses" },
  { judet: "Salaj", localitate: "Padureni" },
  { judet: "Salaj", localitate: "Dumuslau" },
  { judet: "Salaj", localitate: "Colonia Sighetu Silvaniei" },
  { judet: "Salaj", localitate: "Sighetu Silvaniei" },
  { judet: "Salaj", localitate: "Plesca" },
  { judet: "Salaj", localitate: "Pria" },
  { judet: "Salaj", localitate: "Archid" },
  { judet: "Salaj", localitate: "Chilioara" },
  { judet: "Salaj", localitate: "Huseni" },
  { judet: "Salaj", localitate: "Marin" },
  { judet: "Salaj", localitate: "Ratin" },
  { judet: "Salaj", localitate: "Borza" },
  { judet: "Salaj", localitate: "Brebi" },
  { judet: "Salaj", localitate: "Brusturi" },
  { judet: "Salaj", localitate: "Ciglean" },
  { judet: "Salaj", localitate: "Jac" },
  { judet: "Salaj", localitate: "Lupoaia" },
  { judet: "Salaj", localitate: "Prodanesti" },
  { judet: "Salaj", localitate: "Viile Jacului" },
  { judet: "Salaj", localitate: "Muncel" },
  { judet: "Salaj", localitate: "Poiana Ontii" },
  { judet: "Salaj", localitate: "Valeni" },
  { judet: "Salaj", localitate: "Cristur-Criseni" },
  { judet: "Salaj", localitate: "Garceiu" },
  { judet: "Salaj", localitate: "Cublesu" },
  { judet: "Salaj", localitate: "Galaseni" },
  { judet: "Salaj", localitate: "Mierta" },
  { judet: "Salaj", localitate: "Petrindu" },
  { judet: "Salaj", localitate: "Ruginoasa" },
  { judet: "Salaj", localitate: "Stoboru" },
  { judet: "Salaj", localitate: "Tamasa" },
  { judet: "Salaj", localitate: "Deleni" },
  { judet: "Salaj", localitate: "Doba" },
  { judet: "Salaj", localitate: "Naimon" },
  { judet: "Salaj", localitate: "Sancraiu Silvaniei" },
  { judet: "Salaj", localitate: "Verveghiu" },
  { judet: "Salaj", localitate: "Adalin" },
  { judet: "Salaj", localitate: "Fantanele" },
  { judet: "Salaj", localitate: "Ugrutiu" },
  { judet: "Salaj", localitate: "Voivodeni" },
  { judet: "Salaj", localitate: "Fildu de Mijloc" },
  { judet: "Salaj", localitate: "Fildu de Sus" },
  { judet: "Salaj", localitate: "Tetisu" },
  { judet: "Salaj", localitate: "Barsau Mare" },
  { judet: "Salaj", localitate: "Capalna" },
  { judet: "Salaj", localitate: "Chizeni" },
  { judet: "Salaj", localitate: "Dobrocina" },
  { judet: "Salaj", localitate: "Fodora" },
  { judet: "Salaj", localitate: "Francenii de Piatra" },
  { judet: "Salaj", localitate: "Glod" },
  { judet: "Salaj", localitate: "Gura Vladesei" },
  { judet: "Salaj", localitate: "Bezded" },
  { judet: "Salaj", localitate: "Calacea" },
  { judet: "Salaj", localitate: "Cernuc" },
  { judet: "Salaj", localitate: "Fabrica" },
  { judet: "Salaj", localitate: "Popteleac" },
  { judet: "Salaj", localitate: "Solomon" },
  { judet: "Salaj", localitate: "Aleus" },
  { judet: "Salaj", localitate: "Cerisa" },
  { judet: "Salaj", localitate: "Drighiu" },
  { judet: "Salaj", localitate: "Fufez" },
  { judet: "Salaj", localitate: "Badon" },
  { judet: "Salaj", localitate: "Bocsita" },
  { judet: "Salaj", localitate: "Diosod" },
  { judet: "Salaj", localitate: "Guruslau" },
  { judet: "Salaj", localitate: "Panic" },
  { judet: "Salaj", localitate: "Baica" },
  { judet: "Salaj", localitate: "Miluani" },
  { judet: "Salaj", localitate: "Paduris" },
  { judet: "Salaj", localitate: "Racas" },
  { judet: "Salaj", localitate: "Sanpetru Almasului" },
  { judet: "Salaj", localitate: "Stupini" },
  { judet: "Salaj", localitate: "Trestia" },
  { judet: "Salaj", localitate: "Hurez" },
  { judet: "Salaj", localitate: "Starciu" },
  { judet: "Salaj", localitate: "Seredeiu" },
  { judet: "Salaj", localitate: "Barsauta" },
  { judet: "Salaj", localitate: "Bizusa-Bai" },
  { judet: "Salaj", localitate: "Dabiceni" },
  { judet: "Salaj", localitate: "Dolheni" },
  { judet: "Salaj", localitate: "Luminisu" },
  { judet: "Salaj", localitate: "Maleni" },
  { judet: "Salaj", localitate: "Negreni" },
  { judet: "Salaj", localitate: "Perii Vadului" },
  { judet: "Salaj", localitate: "Podisu" },
  { judet: "Salaj", localitate: "Rastoci" },
  { judet: "Salaj", localitate: "Rogna" },
  { judet: "Salaj", localitate: "Sasa" },
  { judet: "Salaj", localitate: "Cosniciu de Jos" },
  { judet: "Salaj", localitate: "Cosniciu de Sus" },
  { judet: "Salaj", localitate: "Zauan" },
  { judet: "Salaj", localitate: "Zauan-Bai" },
  { judet: "Salaj", localitate: "Ciula" },
  { judet: "Salaj", localitate: "Cozla" },
  { judet: "Salaj", localitate: "Cuciulat" },
  { judet: "Salaj", localitate: "Lemniu" },
  { judet: "Salaj", localitate: "Purcaret" },
  { judet: "Salaj", localitate: "Soimuseni" },
  { judet: "Salaj", localitate: "Toplita" },
  { judet: "Salaj", localitate: "Valisoara" },
  { judet: "Salaj", localitate: "Cormenis" },
  { judet: "Salaj", localitate: "Preluci" },
  { judet: "Salaj", localitate: "Valea Lesului" },
  { judet: "Salaj", localitate: "Valea Loznei" },
  { judet: "Salaj", localitate: "Lesmir" },
  { judet: "Salaj", localitate: "Marca-Huta" },
  { judet: "Salaj", localitate: "Port" },
  { judet: "Salaj", localitate: "Sumal" },
  { judet: "Salaj", localitate: "Cristelec" },
  { judet: "Salaj", localitate: "Doh" },
  { judet: "Salaj", localitate: "Giurtelecu Simleului" },
  { judet: "Salaj", localitate: "Maladia" },
  { judet: "Salaj", localitate: "Uileacu Simleului" },
  { judet: "Salaj", localitate: "Aghires" },
  { judet: "Salaj", localitate: "Fetindia" },
  { judet: "Salaj", localitate: "Mesesenii de Sus" },
  { judet: "Salaj", localitate: "Firminis" },
  { judet: "Salaj", localitate: "Moigrad-Porolissum" },
  { judet: "Salaj", localitate: "Popeni" },
  { judet: "Salaj", localitate: "Cheud" },
  { judet: "Salaj", localitate: "Somes-Guruslau" },
  { judet: "Salaj", localitate: "Tranis" },
  { judet: "Salaj", localitate: "Vadurele" },
  { judet: "Salaj", localitate: "Bilghez" },
  { judet: "Salaj", localitate: "Bozies" },
  { judet: "Salaj", localitate: "Badacin" },
  { judet: "Salaj", localitate: "Periceiu Mic" },
  { judet: "Salaj", localitate: "Sici" },
  { judet: "Salaj", localitate: "Fagetu" },
  { judet: "Salaj", localitate: "Iaz" },
  { judet: "Salaj", localitate: "Falcusa" },
  { judet: "Salaj", localitate: "Gostila" },
  { judet: "Salaj", localitate: "Magura" },
  { judet: "Salaj", localitate: "Chichisa" },
  { judet: "Salaj", localitate: "Ciumarna" },
  { judet: "Salaj", localitate: "Pausa" },
  { judet: "Salaj", localitate: "Poarta Salajului" },
  { judet: "Salaj", localitate: "Romita" },
  { judet: "Salaj", localitate: "Buzas" },
  { judet: "Salaj", localitate: "Fantanele-Rus" },
  { judet: "Salaj", localitate: "Hasmas" },
  { judet: "Salaj", localitate: "Bulgari" },
  { judet: "Salaj", localitate: "Deja" },
  { judet: "Salaj", localitate: "Mineu" },
  { judet: "Salaj", localitate: "Notig" },
  { judet: "Salaj", localitate: "Fizes" },
  { judet: "Salaj", localitate: "Mal" },
  { judet: "Salaj", localitate: "Sarbi" },
  { judet: "Salaj", localitate: "Tusa" },
  { judet: "Salaj", localitate: "Bercea" },
  { judet: "Salaj", localitate: "Santa Maria" },
  { judet: "Salaj", localitate: "Barsa" },
  { judet: "Salaj", localitate: "Domnin" },
  { judet: "Salaj", localitate: "Inau" },
  { judet: "Salaj", localitate: "Soimus" },
  { judet: "Salaj", localitate: "Braglez" },
  { judet: "Salaj", localitate: "Cristoltel" },
  { judet: "Salaj", localitate: "Solona" },
  { judet: "Salaj", localitate: "Testioara" },
  { judet: "Salaj", localitate: "Tihau" },
  { judet: "Salaj", localitate: "Turbuta" },
  { judet: "Salaj", localitate: "Valea Pomilor" },
  { judet: "Salaj", localitate: "Ilisua" },
  { judet: "Salaj", localitate: "Lompirt" },
  { judet: "Salaj", localitate: "Moiad" },
  { judet: "Salaj", localitate: "Poiana Magura" },
  { judet: "Salaj", localitate: "Tarmure" },
  { judet: "Salaj", localitate: "Bozna" },
  { judet: "Salaj", localitate: "Lazuri" },
  { judet: "Salaj", localitate: "Preoteasa" },
  { judet: "Salaj", localitate: "Ratovei" },
  { judet: "Salaj", localitate: "Sub Cetate" },
  { judet: "Salaj", localitate: "Valcau de Sus" },
  { judet: "Salaj", localitate: "Recea" },
  { judet: "Salaj", localitate: "Recea Mica" },
  { judet: "Salaj", localitate: "Ceaca" },
  { judet: "Salaj", localitate: "Ciureni" },
  { judet: "Salaj", localitate: "Valea Ciurenilor" },
  { judet: "Salaj", localitate: "Valea Hranei" },
  { judet: "Salaj", localitate: "Valea Lunga" },
  { judet: "Salaj", localitate: "Vartesca" },
  { judet: "Salaj", localitate: "Chendremal" },
  { judet: "Salaj", localitate: "Dolu" },
  { judet: "Salaj", localitate: "Sancraiu Almasului" },
  { judet: "Salaj", localitate: "Sutoru" },
  { judet: "Satu Mare", localitate: "Satu Mare" },
  { judet: "Satu Mare", localitate: "Carei" },
  { judet: "Satu Mare", localitate: "Turt" },
  { judet: "Satu Mare", localitate: "Negresti - Oas" },
  { judet: "Satu Mare", localitate: "Tasnad" },
  { judet: "Satu Mare", localitate: "Ardud" },
  { judet: "Satu Mare", localitate: "Livada" },
  { judet: "Satu Mare", localitate: "Acas" },
  { judet: "Satu Mare", localitate: "Agris" },
  { judet: "Satu Mare", localitate: "Andrid" },
  { judet: "Satu Mare", localitate: "Apa" },
  { judet: "Satu Mare", localitate: "Batarci" },
  { judet: "Satu Mare", localitate: "Beltiug" },
  { judet: "Satu Mare", localitate: "Berveni" },
  { judet: "Satu Mare", localitate: "Bixad" },
  { judet: "Satu Mare", localitate: "Barsau" },
  { judet: "Satu Mare", localitate: "Bogdand" },
  { judet: "Satu Mare", localitate: "Botiz" },
  { judet: "Satu Mare", localitate: "Calinesti-Oas" },
  { judet: "Satu Mare", localitate: "Camarzana" },
  { judet: "Satu Mare", localitate: "Camin" },
  { judet: "Satu Mare", localitate: "Capleni" },
  { judet: "Satu Mare", localitate: "Cauas" },
  { judet: "Satu Mare", localitate: "Cehal" },
  { judet: "Satu Mare", localitate: "Certeze" },
  { judet: "Satu Mare", localitate: "Ciumesti" },
  { judet: "Satu Mare", localitate: "Craidorolt" },
  { judet: "Satu Mare", localitate: "Crucisor" },
  { judet: "Satu Mare", localitate: "Culciu" },
  { judet: "Satu Mare", localitate: "Doba" },
  { judet: "Satu Mare", localitate: "Dorolt" },
  { judet: "Satu Mare", localitate: "Foieni" },
  { judet: "Satu Mare", localitate: "Gherta Mica" },
  { judet: "Satu Mare", localitate: "Halmeu" },
  { judet: "Satu Mare", localitate: "Hodod" },
  { judet: "Satu Mare", localitate: "Homoroade" },
  { judet: "Satu Mare", localitate: "Lazuri" },
  { judet: "Satu Mare", localitate: "Mediesu Aurit" },
  { judet: "Satu Mare", localitate: "Micula" },
  { judet: "Satu Mare", localitate: "Moftin" },
  { judet: "Satu Mare", localitate: "Odoreu" },
  { judet: "Satu Mare", localitate: "Orasu Nou" },
  { judet: "Satu Mare", localitate: "Paulesti" },
  { judet: "Satu Mare", localitate: "Petresti" },
  { judet: "Satu Mare", localitate: "Pir" },
  { judet: "Satu Mare", localitate: "Piscolt" },
  { judet: "Satu Mare", localitate: "Pomi" },
  { judet: "Satu Mare", localitate: "Porumbesti" },
  { judet: "Satu Mare", localitate: "Racsa" },
  { judet: "Satu Mare", localitate: "Sanislau" },
  { judet: "Satu Mare", localitate: "Santau" },
  { judet: "Satu Mare", localitate: "Sacaseni" },
  { judet: "Satu Mare", localitate: "Sauca" },
  { judet: "Satu Mare", localitate: "Socond" },
  { judet: "Satu Mare", localitate: "Supur" },
  { judet: "Satu Mare", localitate: "Tarna Mare" },
  { judet: "Satu Mare", localitate: "Terebesti" },
  { judet: "Satu Mare", localitate: "Tiream" },
  { judet: "Satu Mare", localitate: "Tarsolt" },
  { judet: "Satu Mare", localitate: "Turulung" },
  { judet: "Satu Mare", localitate: "Urziceni" },
  { judet: "Satu Mare", localitate: "Valea Vinului" },
  { judet: "Satu Mare", localitate: "Vama" },
  { judet: "Satu Mare", localitate: "Vetis" },
  { judet: "Satu Mare", localitate: "Viile Satu Mare" },
  { judet: "Satu Mare", localitate: "Satmarel" },
  { judet: "Satu Mare", localitate: "Ianculesti" },
  { judet: "Satu Mare", localitate: "Luna" },
  { judet: "Satu Mare", localitate: "Tur" },
  { judet: "Satu Mare", localitate: "Blaja" },
  { judet: "Satu Mare", localitate: "Cig" },
  { judet: "Satu Mare", localitate: "Ratiu" },
  { judet: "Satu Mare", localitate: "Sarauad" },
  { judet: "Satu Mare", localitate: "Valea Morii" },
  { judet: "Satu Mare", localitate: "Ganas" },
  { judet: "Satu Mare", localitate: "Mihaieni" },
  { judet: "Satu Mare", localitate: "Unimat" },
  { judet: "Satu Mare", localitate: "Dindesti" },
  { judet: "Satu Mare", localitate: "Irina" },
  { judet: "Satu Mare", localitate: "Lunca Apei" },
  { judet: "Satu Mare", localitate: "Someseni" },
  { judet: "Satu Mare", localitate: "Ardud-Vii" },
  { judet: "Satu Mare", localitate: "Baba Novac" },
  { judet: "Satu Mare", localitate: "Gerausa" },
  { judet: "Satu Mare", localitate: "Madaras" },
  { judet: "Satu Mare", localitate: "Saratura" },
  { judet: "Satu Mare", localitate: "Comlausa" },
  { judet: "Satu Mare", localitate: "Sirlau" },
  { judet: "Satu Mare", localitate: "Tamaseni" },
  { judet: "Satu Mare", localitate: "Barsau de Jos" },
  { judet: "Satu Mare", localitate: "Barsau de Sus" },
  { judet: "Satu Mare", localitate: "Bolda" },
  { judet: "Satu Mare", localitate: "Ghirisa" },
  { judet: "Satu Mare", localitate: "Giungi" },
  { judet: "Satu Mare", localitate: "Ratesti" },
  { judet: "Satu Mare", localitate: "Sandra" },
  { judet: "Satu Mare", localitate: "Lucaceni" },
  { judet: "Satu Mare", localitate: "Boinesti" },
  { judet: "Satu Mare", localitate: "Trip" },
  { judet: "Satu Mare", localitate: "Babta" },
  { judet: "Satu Mare", localitate: "Corund" },
  { judet: "Satu Mare", localitate: "Ser" },
  { judet: "Satu Mare", localitate: "Ciuperceni" },
  { judet: "Satu Mare", localitate: "Coca" },
  { judet: "Satu Mare", localitate: "Lechinta" },
  { judet: "Satu Mare", localitate: "Pasunea Mare" },
  { judet: "Satu Mare", localitate: "Ady Endre" },
  { judet: "Satu Mare", localitate: "Ghenci" },
  { judet: "Satu Mare", localitate: "Ghilesti" },
  { judet: "Satu Mare", localitate: "Hotoan" },
  { judet: "Satu Mare", localitate: "Radulesti" },
  { judet: "Satu Mare", localitate: "Cehalut" },
  { judet: "Satu Mare", localitate: "Orbau" },
  { judet: "Satu Mare", localitate: "Huta-Certeze" },
  { judet: "Satu Mare", localitate: "Moiseni" },
  { judet: "Satu Mare", localitate: "Criseni" },
  { judet: "Satu Mare", localitate: "Eriu-Sancrai" },
  { judet: "Satu Mare", localitate: "Satu Mic" },
  { judet: "Satu Mare", localitate: "Teghea" },
  { judet: "Satu Mare", localitate: "Iegheriste" },
  { judet: "Satu Mare", localitate: "Poiana Codrului" },
  { judet: "Satu Mare", localitate: "Apateu" },
  { judet: "Satu Mare", localitate: "Caraseu" },
  { judet: "Satu Mare", localitate: "Corod" },
  { judet: "Satu Mare", localitate: "Culciu Mare" },
  { judet: "Satu Mare", localitate: "Culciu Mic" },
  { judet: "Satu Mare", localitate: "Lipau" },
  { judet: "Satu Mare", localitate: "Boghis" },
  { judet: "Satu Mare", localitate: "Dacia" },
  { judet: "Satu Mare", localitate: "Paulian" },
  { judet: "Satu Mare", localitate: "Traian" },
  { judet: "Satu Mare", localitate: "Atea" },
  { judet: "Satu Mare", localitate: "Dara" },
  { judet: "Satu Mare", localitate: "Petea" },
  { judet: "Satu Mare", localitate: "Babesti" },
  { judet: "Satu Mare", localitate: "Cidreag" },
  { judet: "Satu Mare", localitate: "Dobolt" },
  { judet: "Satu Mare", localitate: "Halmeu-Vii" },
  { judet: "Satu Mare", localitate: "Mesteacan" },
  { judet: "Satu Mare", localitate: "Giurtelecu Hododului" },
  { judet: "Satu Mare", localitate: "Lelei" },
  { judet: "Satu Mare", localitate: "Nadisu Hododului" },
  { judet: "Satu Mare", localitate: "Chilia" },
  { judet: "Satu Mare", localitate: "Homorodu de Jos" },
  { judet: "Satu Mare", localitate: "Homorodu de Mijloc" },
  { judet: "Satu Mare", localitate: "Homorodu de Sus" },
  { judet: "Satu Mare", localitate: "Necopoi" },
  { judet: "Satu Mare", localitate: "Solduba" },
  { judet: "Satu Mare", localitate: "Bercu" },
  { judet: "Satu Mare", localitate: "Nisipeni" },
  { judet: "Satu Mare", localitate: "Noroieni" },
  { judet: "Satu Mare", localitate: "Peles" },
  { judet: "Satu Mare", localitate: "Pelisor" },
  { judet: "Satu Mare", localitate: "Adrian" },
  { judet: "Satu Mare", localitate: "Dumbrava" },
  { judet: "Satu Mare", localitate: "Livada Mica" },
  { judet: "Satu Mare", localitate: "Babasesti" },
  { judet: "Satu Mare", localitate: "Iojib" },
  { judet: "Satu Mare", localitate: "Medies-Raturi" },
  { judet: "Satu Mare", localitate: "Medies-Vii" },
  { judet: "Satu Mare", localitate: "Potau" },
  { judet: "Satu Mare", localitate: "Romanesti" },
  { judet: "Satu Mare", localitate: "Bercu Nou" },
  { judet: "Satu Mare", localitate: "Micula Noua" },
  { judet: "Satu Mare", localitate: "Domanesti" },
  { judet: "Satu Mare", localitate: "Ghilvaci" },
  { judet: "Satu Mare", localitate: "Ghirolt" },
  { judet: "Satu Mare", localitate: "Istrau" },
  { judet: "Satu Mare", localitate: "Moftinu Mare" },
  { judet: "Satu Mare", localitate: "Moftinu Mic" },
  { judet: "Satu Mare", localitate: "Sanmiclaus" },
  { judet: "Satu Mare", localitate: "Berindan" },
  { judet: "Satu Mare", localitate: "Cucu" },
  { judet: "Satu Mare", localitate: "Eteni" },
  { judet: "Satu Mare", localitate: "Martinesti" },
  { judet: "Satu Mare", localitate: "Vanatoresti" },
  { judet: "Satu Mare", localitate: "Orasu Nou-Vii" },
  { judet: "Satu Mare", localitate: "Prilog" },
  { judet: "Satu Mare", localitate: "Prilog-Vii" },
  { judet: "Satu Mare", localitate: "Racsa-Vii" },
  { judet: "Satu Mare", localitate: "Remetea Oasului" },
  { judet: "Satu Mare", localitate: "Amati" },
  { judet: "Satu Mare", localitate: "Ambud" },
  { judet: "Satu Mare", localitate: "Hrip" },
  { judet: "Satu Mare", localitate: "Petin" },
  { judet: "Satu Mare", localitate: "Ruseni" },
  { judet: "Satu Mare", localitate: "Dindestiu Mic" },
  { judet: "Satu Mare", localitate: "Piru Nou" },
  { judet: "Satu Mare", localitate: "Sarvazel" },
  { judet: "Satu Mare", localitate: "Resighea" },
  { judet: "Satu Mare", localitate: "Scarisoara Noua" },
  { judet: "Satu Mare", localitate: "Aciua" },
  { judet: "Satu Mare", localitate: "Bicau" },
  { judet: "Satu Mare", localitate: "Borlesti" },
  { judet: "Satu Mare", localitate: "Berea" },
  { judet: "Satu Mare", localitate: "Horea" },
  { judet: "Satu Mare", localitate: "Marna Noua" },
  { judet: "Satu Mare", localitate: "Viisoara" },
  { judet: "Satu Mare", localitate: "Chereusa" },
  { judet: "Satu Mare", localitate: "Sudurau" },
  { judet: "Satu Mare", localitate: "Chegea" },
  { judet: "Satu Mare", localitate: "Becheni" },
  { judet: "Satu Mare", localitate: "Cean" },
  { judet: "Satu Mare", localitate: "Chisau" },
  { judet: "Satu Mare", localitate: "Silvas" },
  { judet: "Satu Mare", localitate: "Cuta" },
  { judet: "Satu Mare", localitate: "Hodisa" },
  { judet: "Satu Mare", localitate: "Soconzel" },
  { judet: "Satu Mare", localitate: "Stana" },
  { judet: "Satu Mare", localitate: "Dobra" },
  { judet: "Satu Mare", localitate: "Giorocuta" },
  { judet: "Satu Mare", localitate: "Hurezu Mare" },
  { judet: "Satu Mare", localitate: "Racova" },
  { judet: "Satu Mare", localitate: "Secheresa" },
  { judet: "Satu Mare", localitate: "Supuru de Sus" },
  { judet: "Satu Mare", localitate: "Supuru de Jos" },
  { judet: "Satu Mare", localitate: "Bocicau" },
  { judet: "Satu Mare", localitate: "Valea Seaca" },
  { judet: "Satu Mare", localitate: "Vagas" },
  { judet: "Satu Mare", localitate: "Aliceni" },
  { judet: "Satu Mare", localitate: "Aliza" },
  { judet: "Satu Mare", localitate: "Gelu" },
  { judet: "Satu Mare", localitate: "Piscari" },
  { judet: "Satu Mare", localitate: "Portita" },
  { judet: "Satu Mare", localitate: "Vezendiu" },
  { judet: "Satu Mare", localitate: "Gherta Mare" },
  { judet: "Satu Mare", localitate: "Turt-Bai" },
  { judet: "Satu Mare", localitate: "Draguseni" },
  { judet: "Satu Mare", localitate: "Turulung-Vii" },
  { judet: "Satu Mare", localitate: "Urziceni-Padure" },
  { judet: "Satu Mare", localitate: "Marius" },
  { judet: "Satu Mare", localitate: "Rosiori" },
  { judet: "Satu Mare", localitate: "Sai" },
  { judet: "Satu Mare", localitate: "Decebal" },
  { judet: "Satu Mare", localitate: "Oar" },
  { judet: "Satu Mare", localitate: "Cionchesti" },
  { judet: "Satu Mare", localitate: "Medisa" },
  { judet: "Satu Mare", localitate: "Tataresti" },
  { judet: "Satu Mare", localitate: "Tireac" },
  { judet: "Sibiu", localitate: "Sibiu" },
  { judet: "Sibiu", localitate: "Medias" },
  { judet: "Sibiu", localitate: "Copsa Mica" },
  { judet: "Sibiu", localitate: "Cisnadie" },
  { judet: "Sibiu", localitate: "Selimbar" },
  { judet: "Sibiu", localitate: "Saliste" },
  { judet: "Sibiu", localitate: "Bazna" },
  { judet: "Sibiu", localitate: "Miercurea Sibiului" },
  { judet: "Sibiu", localitate: "Avrig" },
  { judet: "Sibiu", localitate: "Agnita" },
  { judet: "Sibiu", localitate: "Dumbraveni" },
  { judet: "Sibiu", localitate: "Ocna Sibiului" },
  { judet: "Sibiu", localitate: "Talmaciu" },
  { judet: "Sibiu", localitate: "Alma" },
  { judet: "Sibiu", localitate: "Apoldu de Jos" },
  { judet: "Sibiu", localitate: "Arpasu de Jos" },
  { judet: "Sibiu", localitate: "Atel" },
  { judet: "Sibiu", localitate: "Axente Sever" },
  { judet: "Sibiu", localitate: "Barghis" },
  { judet: "Sibiu", localitate: "Biertan" },
  { judet: "Sibiu", localitate: "Blajel" },
  { judet: "Sibiu", localitate: "Boita" },
  { judet: "Sibiu", localitate: "Brateiu" },
  { judet: "Sibiu", localitate: "Bradeni" },
  { judet: "Sibiu", localitate: "Bruiu" },
  { judet: "Sibiu", localitate: "Chirpar" },
  { judet: "Sibiu", localitate: "Carta" },
  { judet: "Sibiu", localitate: "Cartisoara" },
  { judet: "Sibiu", localitate: "Cristian" },
  { judet: "Sibiu", localitate: "Darlos" },
  { judet: "Sibiu", localitate: "Gura Raului" },
  { judet: "Sibiu", localitate: "Hoghilag" },
  { judet: "Sibiu", localitate: "Iacobeni" },
  { judet: "Sibiu", localitate: "Jina" },
  { judet: "Sibiu", localitate: "Laslea" },
  { judet: "Sibiu", localitate: "Loamnes" },
  { judet: "Sibiu", localitate: "Ludos" },
  { judet: "Sibiu", localitate: "Marpod" },
  { judet: "Sibiu", localitate: "Merghindeal" },
  { judet: "Sibiu", localitate: "Micasasa" },
  { judet: "Sibiu", localitate: "Mihaileni" },
  { judet: "Sibiu", localitate: "Mosna" },
  { judet: "Sibiu", localitate: "Nocrich" },
  { judet: "Sibiu", localitate: "Orlat" },
  { judet: "Sibiu", localitate: "Pauca" },
  { judet: "Sibiu", localitate: "Poiana Sibiului" },
  { judet: "Sibiu", localitate: "Poplaca" },
  { judet: "Sibiu", localitate: "Porumbacu de Jos" },
  { judet: "Sibiu", localitate: "Racovita" },
  { judet: "Sibiu", localitate: "Rasinari" },
  { judet: "Sibiu", localitate: "Rau Sadului" },
  { judet: "Sibiu", localitate: "Rosia" },
  { judet: "Sibiu", localitate: "Sadu" },
  { judet: "Sibiu", localitate: "Slimnic" },
  { judet: "Sibiu", localitate: "Seica Mare" },
  { judet: "Sibiu", localitate: "Seica Mica" },
  { judet: "Sibiu", localitate: "Sura Mare" },
  { judet: "Sibiu", localitate: "Sura Mica" },
  { judet: "Sibiu", localitate: "Tilisca" },
  { judet: "Sibiu", localitate: "Tarnava" },
  { judet: "Sibiu", localitate: "Turnu Rosu" },
  { judet: "Sibiu", localitate: "Valea Viilor" },
  { judet: "Sibiu", localitate: "Vurpar" },
  { judet: "Sibiu", localitate: "Paltinis" },
  { judet: "Sibiu", localitate: "Ighisu Nou" },
  { judet: "Sibiu", localitate: "Coves" },
  { judet: "Sibiu", localitate: "Ruja" },
  { judet: "Sibiu", localitate: "Bradu" },
  { judet: "Sibiu", localitate: "Glamboaca" },
  { judet: "Sibiu", localitate: "Sacadate" },
  { judet: "Sibiu", localitate: "Marsa" },
  { judet: "Sibiu", localitate: "Cisnadioara" },
  { judet: "Sibiu", localitate: "Ernea" },
  { judet: "Sibiu", localitate: "Saros pe Tarnave" },
  { judet: "Sibiu", localitate: "Toparcea" },
  { judet: "Sibiu", localitate: "Colonia Talmaciu" },
  { judet: "Sibiu", localitate: "Lazaret" },
  { judet: "Sibiu", localitate: "Lotrioara" },
  { judet: "Sibiu", localitate: "Paltin" },
  { judet: "Sibiu", localitate: "Talmacel" },
  { judet: "Sibiu", localitate: "Altina" },
  { judet: "Sibiu", localitate: "Benesti" },
  { judet: "Sibiu", localitate: "Ghijasa de Sus" },
  { judet: "Sibiu", localitate: "Sangatin" },
  { judet: "Sibiu", localitate: "Arpasu de Sus" },
  { judet: "Sibiu", localitate: "Nou Roman" },
  { judet: "Sibiu", localitate: "Dupus" },
  { judet: "Sibiu", localitate: "Giacas" },
  { judet: "Sibiu", localitate: "Smig" },
  { judet: "Sibiu", localitate: "Agarbiciu" },
  { judet: "Sibiu", localitate: "Soala" },
  { judet: "Sibiu", localitate: "Boian" },
  { judet: "Sibiu", localitate: "Velt" },
  { judet: "Sibiu", localitate: "Apos" },
  { judet: "Sibiu", localitate: "Ighisu Vechi" },
  { judet: "Sibiu", localitate: "Pelisor" },
  { judet: "Sibiu", localitate: "Vecerd" },
  { judet: "Sibiu", localitate: "Zlagna" },
  { judet: "Sibiu", localitate: "Copsa Mare" },
  { judet: "Sibiu", localitate: "Richis" },
  { judet: "Sibiu", localitate: "Paucea" },
  { judet: "Sibiu", localitate: "Romanesti" },
  { judet: "Sibiu", localitate: "Buzd" },
  { judet: "Sibiu", localitate: "Retis" },
  { judet: "Sibiu", localitate: "Teline" },
  { judet: "Sibiu", localitate: "Gherdeal" },
  { judet: "Sibiu", localitate: "Somartin" },
  { judet: "Sibiu", localitate: "Poienita" },
  { judet: "Sibiu", localitate: "Sasaus" },
  { judet: "Sibiu", localitate: "Vard" },
  { judet: "Sibiu", localitate: "Veseud (Chirpar)" },
  { judet: "Sibiu", localitate: "Curciu" },
  { judet: "Sibiu", localitate: "Valea Lunga" },
  { judet: "Sibiu", localitate: "Prod" },
  { judet: "Sibiu", localitate: "Valchid" },
  { judet: "Sibiu", localitate: "Movile" },
  { judet: "Sibiu", localitate: "Netus" },
  { judet: "Sibiu", localitate: "Noistat" },
  { judet: "Sibiu", localitate: "Stejarisu" },
  { judet: "Sibiu", localitate: "Floresti" },
  { judet: "Sibiu", localitate: "Malancrav" },
  { judet: "Sibiu", localitate: "Nou Sasesc" },
  { judet: "Sibiu", localitate: "Roandola" },
  { judet: "Sibiu", localitate: "Alamor" },
  { judet: "Sibiu", localitate: "Armeni" },
  { judet: "Sibiu", localitate: "Hasag" },
  { judet: "Sibiu", localitate: "Mandra" },
  { judet: "Sibiu", localitate: "Sadinca" },
  { judet: "Sibiu", localitate: "Gusu" },
  { judet: "Sibiu", localitate: "Ilimbav" },
  { judet: "Sibiu", localitate: "Dealu Frumos" },
  { judet: "Sibiu", localitate: "Chesler" },
  { judet: "Sibiu", localitate: "Tapu" },
  { judet: "Sibiu", localitate: "Valeni" },
  { judet: "Sibiu", localitate: "Apoldu de Sus" },
  { judet: "Sibiu", localitate: "Dobarca" },
  { judet: "Sibiu", localitate: "Metis" },
  { judet: "Sibiu", localitate: "Moardas" },
  { judet: "Sibiu", localitate: "Ravasel" },
  { judet: "Sibiu", localitate: "Salcau" },
  { judet: "Sibiu", localitate: "Alma Vii" },
  { judet: "Sibiu", localitate: "Nemsa" },
  { judet: "Sibiu", localitate: "Fofeldea" },
  { judet: "Sibiu", localitate: "Ghijasa de Jos" },
  { judet: "Sibiu", localitate: "Hosman" },
  { judet: "Sibiu", localitate: "Tichindeal" },
  { judet: "Sibiu", localitate: "Bogatu Roman" },
  { judet: "Sibiu", localitate: "Brosteni" },
  { judet: "Sibiu", localitate: "Presaca" },
  { judet: "Sibiu", localitate: "Colun" },
  { judet: "Sibiu", localitate: "Porumbacu de Sus" },
  { judet: "Sibiu", localitate: "Sarata" },
  { judet: "Sibiu", localitate: "Scoreiu" },
  { judet: "Sibiu", localitate: "Sebesu de Sus" },
  { judet: "Sibiu", localitate: "Prislop" },
  { judet: "Sibiu", localitate: "Casolt" },
  { judet: "Sibiu", localitate: "Cornatel" },
  { judet: "Sibiu", localitate: "Daia" },
  { judet: "Sibiu", localitate: "Nou" },
  { judet: "Sibiu", localitate: "Nucet" },
  { judet: "Sibiu", localitate: "Aciliu" },
  { judet: "Sibiu", localitate: "Amnas" },
  { judet: "Sibiu", localitate: "Crint" },
  { judet: "Sibiu", localitate: "Fantanele" },
  { judet: "Sibiu", localitate: "Gales" },
  { judet: "Sibiu", localitate: "Mag" },
  { judet: "Sibiu", localitate: "Sacel" },
  { judet: "Sibiu", localitate: "Sibiel" },
  { judet: "Sibiu", localitate: "Vale" },
  { judet: "Sibiu", localitate: "Albi" },
  { judet: "Sibiu", localitate: "Padureni" },
  { judet: "Sibiu", localitate: "Rusi" },
  { judet: "Sibiu", localitate: "Veseud (Slimnic)" },
  { judet: "Sibiu", localitate: "Boarta" },
  { judet: "Sibiu", localitate: "Buia" },
  { judet: "Sibiu", localitate: "Mighindoala" },
  { judet: "Sibiu", localitate: "Petis" },
  { judet: "Sibiu", localitate: "Stenea" },
  { judet: "Sibiu", localitate: "Sorostin" },
  { judet: "Sibiu", localitate: "Bungard" },
  { judet: "Sibiu", localitate: "Mohu" },
  { judet: "Sibiu", localitate: "Vestem" },
  { judet: "Sibiu", localitate: "Hamba" },
  { judet: "Sibiu", localitate: "Rusciori" },
  { judet: "Sibiu", localitate: "Colonia Tarnava" },
  { judet: "Sibiu", localitate: "Rod" },
  { judet: "Sibiu", localitate: "Sebesu de Jos" },
  { judet: "Sibiu", localitate: "Motis" },
  { judet: "Suceava", localitate: "Suceava" },
  { judet: "Suceava", localitate: "Campulung Moldovenesc" },
  { judet: "Suceava", localitate: "Radauti" },
  { judet: "Suceava", localitate: "Falticeni" },
  { judet: "Suceava", localitate: "Vatra Dornei" },
  { judet: "Suceava", localitate: "Gura Humorului" },
  { judet: "Suceava", localitate: "Siret" },
  { judet: "Suceava", localitate: "Scheia" },
  { judet: "Suceava", localitate: "Solca" },
  { judet: "Suceava", localitate: "Brosteni" },
  { judet: "Suceava", localitate: "Cajvana" },
  { judet: "Suceava", localitate: "Dolhasca" },
  { judet: "Suceava", localitate: "Frasin" },
  { judet: "Suceava", localitate: "Liteni" },
  { judet: "Suceava", localitate: "Milisauti" },
  { judet: "Suceava", localitate: "Salcea" },
  { judet: "Suceava", localitate: "Vicovu de Sus" },
  { judet: "Suceava", localitate: "Arbore" },
  { judet: "Suceava", localitate: "Baia" },
  { judet: "Suceava", localitate: "Balaceana" },
  { judet: "Suceava", localitate: "Balcauti" },
  { judet: "Suceava", localitate: "Berchisesti" },
  { judet: "Suceava", localitate: "Bilca" },
  { judet: "Suceava", localitate: "Bogdanesti" },
  { judet: "Suceava", localitate: "Boroaia" },
  { judet: "Suceava", localitate: "Bosanci" },
  { judet: "Suceava", localitate: "Botosana" },
  { judet: "Suceava", localitate: "Breaza" },
  { judet: "Suceava", localitate: "Brodina" },
  { judet: "Suceava", localitate: "Bunesti" },
  { judet: "Suceava", localitate: "Burla" },
  { judet: "Suceava", localitate: "Cacica" },
  { judet: "Suceava", localitate: "Calafindesti" },
  { judet: "Suceava", localitate: "Capu Campului" },
  { judet: "Suceava", localitate: "Carlibaba" },
  { judet: "Suceava", localitate: "Ciocanesti" },
  { judet: "Suceava", localitate: "Ciprian Porumbescu" },
  { judet: "Suceava", localitate: "Comanesti" },
  { judet: "Suceava", localitate: "Cornu Luncii" },
  { judet: "Suceava", localitate: "Cosna" },
  { judet: "Suceava", localitate: "Crucea" },
  { judet: "Suceava", localitate: "Darmanesti" },
  { judet: "Suceava", localitate: "Dolhesti" },
  { judet: "Suceava", localitate: "Dorna-Arini" },
  { judet: "Suceava", localitate: "Dorna Candrenilor" },
  { judet: "Suceava", localitate: "Dornesti" },
  { judet: "Suceava", localitate: "Dragoiesti" },
  { judet: "Suceava", localitate: "Draguseni" },
  { judet: "Suceava", localitate: "Dumbraveni" },
  { judet: "Suceava", localitate: "Fantana Mare" },
  { judet: "Suceava", localitate: "Fantanele" },
  { judet: "Suceava", localitate: "Forasti" },
  { judet: "Suceava", localitate: "Fratautii Noi" },
  { judet: "Suceava", localitate: "Fratautii Vechi" },
  { judet: "Suceava", localitate: "Frumosu" },
  { judet: "Suceava", localitate: "Fundu Moldovei" },
  { judet: "Suceava", localitate: "Galanesti" },
  { judet: "Suceava", localitate: "Gramesti" },
  { judet: "Suceava", localitate: "Granicesti" },
  { judet: "Suceava", localitate: "Hantesti" },
  { judet: "Suceava", localitate: "Hartop" },
  { judet: "Suceava", localitate: "Horodnic de Jos" },
  { judet: "Suceava", localitate: "Horodnic de Sus" },
  { judet: "Suceava", localitate: "Horodniceni" },
  { judet: "Suceava", localitate: "Iacobeni" },
  { judet: "Suceava", localitate: "Iaslovat" },
  { judet: "Suceava", localitate: "Ilisesti" },
  { judet: "Suceava", localitate: "Ipotesti" },
  { judet: "Suceava", localitate: "Izvoarele Sucevei" },
  { judet: "Suceava", localitate: "Malini" },
  { judet: "Suceava", localitate: "Manastirea Humorului" },
  { judet: "Suceava", localitate: "Marginea" },
  { judet: "Suceava", localitate: "Mitocu Dragomirnei" },
  { judet: "Suceava", localitate: "Moara" },
  { judet: "Suceava", localitate: "Moldova-Sulita" },
  { judet: "Suceava", localitate: "Moldovita" },
  { judet: "Suceava", localitate: "Musenita" },
  { judet: "Suceava", localitate: "Ostra" },
  { judet: "Suceava", localitate: "Paltinoasa" },
  { judet: "Suceava", localitate: "Panaci" },
  { judet: "Suceava", localitate: "Partestii de Jos" },
  { judet: "Suceava", localitate: "Patrauti" },
  { judet: "Suceava", localitate: "Poiana Stampei" },
  { judet: "Suceava", localitate: "Poieni-Solca" },
  { judet: "Suceava", localitate: "Pojorata" },
  { judet: "Suceava", localitate: "Preutesti" },
  { judet: "Suceava", localitate: "Putna" },
  { judet: "Suceava", localitate: "Radaseni" },
  { judet: "Suceava", localitate: "Rasca" },
  { judet: "Suceava", localitate: "Sadova" },
  { judet: "Suceava", localitate: "Satu Mare" },
  { judet: "Suceava", localitate: "Siminicea" },
  { judet: "Suceava", localitate: "Slatina" },
  { judet: "Suceava", localitate: "Straja" },
  { judet: "Suceava", localitate: "Stroiesti" },
  { judet: "Suceava", localitate: "Stulpicani" },
  { judet: "Suceava", localitate: "Sucevita" },
  { judet: "Suceava", localitate: "Saru Dornei" },
  { judet: "Suceava", localitate: "Serbauti" },
  { judet: "Suceava", localitate: "Todiresti" },
  { judet: "Suceava", localitate: "Udesti" },
  { judet: "Suceava", localitate: "Ulma" },
  { judet: "Suceava", localitate: "Vadu Moldovei" },
  { judet: "Suceava", localitate: "Valea Moldovei" },
  { judet: "Suceava", localitate: "Vama" },
  { judet: "Suceava", localitate: "Vatra Moldovitei" },
  { judet: "Suceava", localitate: "Veresti" },
  { judet: "Suceava", localitate: "Vicovu de Jos" },
  { judet: "Suceava", localitate: "Voitinel" },
  { judet: "Suceava", localitate: "Volovat" },
  { judet: "Suceava", localitate: "Vulturesti" },
  { judet: "Suceava", localitate: "Zamostea" },
  { judet: "Suceava", localitate: "Zvoristea" },
  { judet: "Suceava", localitate: "Neagra Sarului" },
  { judet: "Suceava", localitate: "Oniceni" },
  { judet: "Suceava", localitate: "Soldanesti" },
  { judet: "Suceava", localitate: "Tarna Mare" },
  { judet: "Suceava", localitate: "Voronet" },
  { judet: "Suceava", localitate: "Manastioara (Siret)" },
  { judet: "Suceava", localitate: "Padureni" },
  { judet: "Suceava", localitate: "Argestru" },
  { judet: "Suceava", localitate: "Rosu" },
  { judet: "Suceava", localitate: "Todireni" },
  { judet: "Suceava", localitate: "Adancata" },
  { judet: "Suceava", localitate: "Artari" },
  { judet: "Suceava", localitate: "Beresti" },
  { judet: "Suceava", localitate: "Calugareni" },
  { judet: "Suceava", localitate: "Fetesti" },
  { judet: "Suceava", localitate: "Bodnareni" },
  { judet: "Suceava", localitate: "Clit" },
  { judet: "Suceava", localitate: "Bogata" },
  { judet: "Suceava", localitate: "Gropeni" },
  { judet: "Suceava", localitate: "Negostina" },
  { judet: "Suceava", localitate: "Barasti" },
  { judet: "Suceava", localitate: "Giulesti" },
  { judet: "Suceava", localitate: "Moisa" },
  { judet: "Suceava", localitate: "Sacuta" },
  { judet: "Suceava", localitate: "Cumparatura" },
  { judet: "Suceava", localitate: "Breaza de Sus" },
  { judet: "Suceava", localitate: "Parau Negrei" },
  { judet: "Suceava", localitate: "Brodina de Jos" },
  { judet: "Suceava", localitate: "Cununschi" },
  { judet: "Suceava", localitate: "Dubiusca" },
  { judet: "Suceava", localitate: "Ehreste" },
  { judet: "Suceava", localitate: "Falcau" },
  { judet: "Suceava", localitate: "Norocu" },
  { judet: "Suceava", localitate: "Paltin" },
  { judet: "Suceava", localitate: "Sadau" },
  { judet: "Suceava", localitate: "Zalomestra" },
  { judet: "Suceava", localitate: "Cotargasi" },
  { judet: "Suceava", localitate: "Darmoxa" },
  { judet: "Suceava", localitate: "Frasin (Brosteni)" },
  { judet: "Suceava", localitate: "Haleasa" },
  { judet: "Suceava", localitate: "Holda" },
  { judet: "Suceava", localitate: "Holdita" },
  { judet: "Suceava", localitate: "Lungeni" },
  { judet: "Suceava", localitate: "Neagra" },
  { judet: "Suceava", localitate: "Pietroasa" },
  { judet: "Suceava", localitate: "Petia" },
  { judet: "Suceava", localitate: "Podeni" },
  { judet: "Suceava", localitate: "Ses" },
  { judet: "Suceava", localitate: "Uncesti" },
  { judet: "Suceava", localitate: "Maidan" },
  { judet: "Suceava", localitate: "Partestii de Sus" },
  { judet: "Suceava", localitate: "Runcu" },
  { judet: "Suceava", localitate: "Solonetu Nou" },
  { judet: "Suceava", localitate: "Codru" },
  { judet: "Suceava", localitate: "Botosanita Mare" },
  { judet: "Suceava", localitate: "Calinesti (Serbauti)" },
  { judet: "Suceava", localitate: "Carlibaba Noua" },
  { judet: "Suceava", localitate: "Iedu" },
  { judet: "Suceava", localitate: "Sesuri" },
  { judet: "Suceava", localitate: "Tibau" },
  { judet: "Suceava", localitate: "Valea Stanei" },
  { judet: "Suceava", localitate: "Botos" },
  { judet: "Suceava", localitate: "Brasca" },
  { judet: "Suceava", localitate: "Humoreni" },
  { judet: "Suceava", localitate: "Baisesti" },
  { judet: "Suceava", localitate: "Braiesti" },
  { judet: "Suceava", localitate: "Dumbrava (Cornu Luncii)" },
  { judet: "Suceava", localitate: "Paiseni" },
  { judet: "Suceava", localitate: "Sasca Mare" },
  { judet: "Suceava", localitate: "Sasca Mica" },
  { judet: "Suceava", localitate: "Sasca Noua" },
  { judet: "Suceava", localitate: "Sinca" },
  { judet: "Suceava", localitate: "Chiril" },
  { judet: "Suceava", localitate: "Cojoci" },
  { judet: "Suceava", localitate: "Satu Mare (Crucea)" },
  { judet: "Suceava", localitate: "Calinesti (Darmanesti)" },
  { judet: "Suceava", localitate: "Calinesti-Vasilache" },
  { judet: "Suceava", localitate: "Danila" },
  { judet: "Suceava", localitate: "Mariteia Mica" },
  { judet: "Suceava", localitate: "Maritei" },
  { judet: "Suceava", localitate: "Budeni" },
  { judet: "Suceava", localitate: "Gulia" },
  { judet: "Suceava", localitate: "Poiana (Dolhasca)" },
  { judet: "Suceava", localitate: "Poienari" },
  { judet: "Suceava", localitate: "Probota" },
  { judet: "Suceava", localitate: "Silistea Noua" },
  { judet: "Suceava", localitate: "Valea Poienei" },
  { judet: "Suceava", localitate: "Dolhestii Mici" },
  { judet: "Suceava", localitate: "Valea Bourei" },
  { judet: "Suceava", localitate: "Dolhestii Mari" },
  { judet: "Suceava", localitate: "Dealu Floreni" },
  { judet: "Suceava", localitate: "Podu Cosnei" },
  { judet: "Suceava", localitate: "Poiana Negrii" },
  { judet: "Suceava", localitate: "Romanesti (Cosna)" },
  { judet: "Suceava", localitate: "Tesna (Cosna)" },
  { judet: "Suceava", localitate: "Valea Bancului" },
  { judet: "Suceava", localitate: "Cozanesti" },
  { judet: "Suceava", localitate: "Gheorghiteni" },
  { judet: "Suceava", localitate: "Ortoaia" },
  { judet: "Suceava", localitate: "Rusca" },
  { judet: "Suceava", localitate: "Sunatori" },
  { judet: "Suceava", localitate: "Iaz" },
  { judet: "Suceava", localitate: "Corlata" },
  { judet: "Suceava", localitate: "Lucacesti" },
  { judet: "Suceava", localitate: "Mazanaesti" },
  { judet: "Suceava", localitate: "Brosteni (Draguseni)" },
  { judet: "Suceava", localitate: "Gara Leu" },
  { judet: "Suceava", localitate: "Salageni" },
  { judet: "Suceava", localitate: "Banesti" },
  { judet: "Suceava", localitate: "Cotu Dobei" },
  { judet: "Suceava", localitate: "Slobozia (Fantanele)" },
  { judet: "Suceava", localitate: "Stamate" },
  { judet: "Suceava", localitate: "Antoceni" },
  { judet: "Suceava", localitate: "Boura" },
  { judet: "Suceava", localitate: "Manolea" },
  { judet: "Suceava", localitate: "Rosiori" },
  { judet: "Suceava", localitate: "Rusi" },
  { judet: "Suceava", localitate: "Tolesti" },
  { judet: "Suceava", localitate: "Uidesti" },
  { judet: "Suceava", localitate: "Bucsoaia" },
  { judet: "Suceava", localitate: "Doroteia" },
  { judet: "Suceava", localitate: "Plutonita" },
  { judet: "Suceava", localitate: "Costisa" },
  { judet: "Suceava", localitate: "Maneuti" },
  { judet: "Suceava", localitate: "Deia" },
  { judet: "Suceava", localitate: "Dragosa" },
  { judet: "Suceava", localitate: "Botus" },
  { judet: "Suceava", localitate: "Botusel" },
  { judet: "Suceava", localitate: "Branistea" },
  { judet: "Suceava", localitate: "Colacu" },
  { judet: "Suceava", localitate: "Delnita" },
  { judet: "Suceava", localitate: "Delut" },
  { judet: "Suceava", localitate: "Obcina" },
  { judet: "Suceava", localitate: "Plai" },
  { judet: "Suceava", localitate: "Smida Ungurenilor" },
  { judet: "Suceava", localitate: "Hurjuieni" },
  { judet: "Suceava", localitate: "Balinesti" },
  { judet: "Suceava", localitate: "Botosanita Mica" },
  { judet: "Suceava", localitate: "Rudesti" },
  { judet: "Suceava", localitate: "Verbia" },
  { judet: "Suceava", localitate: "Dumbrava (Granicesti)" },
  { judet: "Suceava", localitate: "Gura Solcii" },
  { judet: "Suceava", localitate: "Iacobesti" },
  { judet: "Suceava", localitate: "Romanesti (Granicesti)" },
  { judet: "Suceava", localitate: "Slobozia Sucevei" },
  { judet: "Suceava", localitate: "Horodnic" },
  { judet: "Suceava", localitate: "Botesti" },
  { judet: "Suceava", localitate: "Bradatel" },
  { judet: "Suceava", localitate: "Mihaiesti" },
  { judet: "Suceava", localitate: "Rotopanesti" },
  { judet: "Suceava", localitate: "Mestecanis" },
  { judet: "Suceava", localitate: "Lisaura" },
  { judet: "Suceava", localitate: "Tisauti" },
  { judet: "Suceava", localitate: "Bobeica" },
  { judet: "Suceava", localitate: "Brodina (Izvoarele Sucevei)" },
  { judet: "Suceava", localitate: "Corni" },
  { judet: "Suceava", localitate: "Roscani" },
  { judet: "Suceava", localitate: "Rotunda" },
  { judet: "Suceava", localitate: "Silistea" },
  { judet: "Suceava", localitate: "Vercicani" },
  { judet: "Suceava", localitate: "Iesle" },
  { judet: "Suceava", localitate: "Paraie" },
  { judet: "Suceava", localitate: "Poiana Marului" },
  { judet: "Suceava", localitate: "Valeni-Stanisoara" },
  { judet: "Suceava", localitate: "Plesa" },
  { judet: "Suceava", localitate: "Poiana Micului" },
  { judet: "Suceava", localitate: "Badeuti" },
  { judet: "Suceava", localitate: "Gara" },
  { judet: "Suceava", localitate: "Lunca (Milisauti)" },
  { judet: "Suceava", localitate: "Dragomirna" },
  { judet: "Suceava", localitate: "Lipoveni" },
  { judet: "Suceava", localitate: "Mitocasi" },
  { judet: "Suceava", localitate: "Bulai" },
  { judet: "Suceava", localitate: "Frumoasa" },
  { judet: "Suceava", localitate: "Groapa Vladichii" },
  { judet: "Suceava", localitate: "Liteni (Moara)" },
  { judet: "Suceava", localitate: "Moara Carp" },
  { judet: "Suceava", localitate: "Moara Nica" },
  { judet: "Suceava", localitate: "Vornicenii Mari" },
  { judet: "Suceava", localitate: "Vornicenii Mici" },
  { judet: "Suceava", localitate: "Benia" },
  { judet: "Suceava", localitate: "Argel" },
  { judet: "Suceava", localitate: "Demacusa" },
  { judet: "Suceava", localitate: "Rasca" },
  { judet: "Suceava", localitate: "Bainet" },
  { judet: "Suceava", localitate: "Bancesti" },
  { judet: "Suceava", localitate: "Climauti" },
  { judet: "Suceava", localitate: "Vascauti" },
  { judet: "Suceava", localitate: "Vicsani" },
  { judet: "Suceava", localitate: "Tarnicioara" },
  { judet: "Suceava", localitate: "Catrinari" },
  { judet: "Suceava", localitate: "Coverca" },
  { judet: "Suceava", localitate: "Dragoiasa" },
  { judet: "Suceava", localitate: "Glodu" },
  { judet: "Suceava", localitate: "Paltinis" },
  { judet: "Suceava", localitate: "Capu Codrului" },
  { judet: "Suceava", localitate: "Deleni" },
  { judet: "Suceava", localitate: "Varvata" },
  { judet: "Suceava", localitate: "Varfu Dealului" },
  { judet: "Suceava", localitate: "Casoi" },
  { judet: "Suceava", localitate: "Dornisoara" },
  { judet: "Suceava", localitate: "Pilugani" },
  { judet: "Suceava", localitate: "Praleni" },
  { judet: "Suceava", localitate: "Tataru" },
  { judet: "Suceava", localitate: "Tesna (Poiana Stampei)" },
  { judet: "Suceava", localitate: "Valea Putnei" },
  { judet: "Suceava", localitate: "Arghira" },
  { judet: "Suceava", localitate: "Bahna Arin" },
  { judet: "Suceava", localitate: "Basarabi" },
  { judet: "Suceava", localitate: "Husi" },
  { judet: "Suceava", localitate: "Leucusesti" },
  { judet: "Suceava", localitate: "Gura Putnei" },
  { judet: "Suceava", localitate: "Lamaseni" },
  { judet: "Suceava", localitate: "Pocoleni" },
  { judet: "Suceava", localitate: "Buda (Rasca)" },
  { judet: "Suceava", localitate: "Dumbraveni (Rasca)" },
  { judet: "Suceava", localitate: "Jahalia" },
  { judet: "Suceava", localitate: "Slatioara (Rasca)" },
  { judet: "Suceava", localitate: "Mereni" },
  { judet: "Suceava", localitate: "Plopeni" },
  { judet: "Suceava", localitate: "Prelipca" },
  { judet: "Suceava", localitate: "Varatec" },
  { judet: "Suceava", localitate: "Tibeni" },
  { judet: "Suceava", localitate: "Grigoresti" },
  { judet: "Suceava", localitate: "Gainesti" },
  { judet: "Suceava", localitate: "Herla" },
  { judet: "Suceava", localitate: "Valcelele" },
  { judet: "Suceava", localitate: "Zaharesti" },
  { judet: "Suceava", localitate: "Gemenea" },
  { judet: "Suceava", localitate: "Negrileasa" },
  { judet: "Suceava", localitate: "Slatioara (Stulpicani)" },
  { judet: "Suceava", localitate: "Vadu Negrilesei" },
  { judet: "Suceava", localitate: "Voievodeasa" },
  { judet: "Suceava", localitate: "Gura Haitii" },
  { judet: "Suceava", localitate: "Plaiu Sarului" },
  { judet: "Suceava", localitate: "Sarisor" },
  { judet: "Suceava", localitate: "Sarisoru Mare" },
  { judet: "Suceava", localitate: "Saru Bucovinei" },
  { judet: "Suceava", localitate: "Florinta" },
  { judet: "Suceava", localitate: "Mihoveni" },
  { judet: "Suceava", localitate: "Sfantu Ilie" },
  { judet: "Suceava", localitate: "Trei Movile" },
  { judet: "Suceava", localitate: "Costana" },
  { judet: "Suceava", localitate: "Parhauti" },
  { judet: "Suceava", localitate: "Sarghiesti" },
  { judet: "Suceava", localitate: "Solonet" },
  { judet: "Suceava", localitate: "Chiliseni" },
  { judet: "Suceava", localitate: "Luncusoara" },
  { judet: "Suceava", localitate: "Manastioara (Udesti)" },
  { judet: "Suceava", localitate: "Plavalari" },
  { judet: "Suceava", localitate: "Poieni-Suceava" },
  { judet: "Suceava", localitate: "Racova" },
  { judet: "Suceava", localitate: "Reuseni" },
  { judet: "Suceava", localitate: "Rusii-Manastioara" },
  { judet: "Suceava", localitate: "Securiceni" },
  { judet: "Suceava", localitate: "Stirbat" },
  { judet: "Suceava", localitate: "Costileva" },
  { judet: "Suceava", localitate: "Lupcina" },
  { judet: "Suceava", localitate: "Magura" },
  { judet: "Suceava", localitate: "Nisipitu" },
  { judet: "Suceava", localitate: "Camarzani" },
  { judet: "Suceava", localitate: "Ciumulesti" },
  { judet: "Suceava", localitate: "Cotu Baii" },
  { judet: "Suceava", localitate: "Dumbravita" },
  { judet: "Suceava", localitate: "Ioneasa" },
  { judet: "Suceava", localitate: "Mesteceni" },
  { judet: "Suceava", localitate: "Movileni" },
  { judet: "Suceava", localitate: "Nigotesti" },
  { judet: "Suceava", localitate: "Praxia" },
  { judet: "Suceava", localitate: "Spataresti" },
  { judet: "Suceava", localitate: "Mironu" },
  { judet: "Suceava", localitate: "Molid" },
  { judet: "Suceava", localitate: "Prisaca Dornei" },
  { judet: "Suceava", localitate: "Stramtura" },
  { judet: "Suceava", localitate: "Ciumarna" },
  { judet: "Suceava", localitate: "Paltinu" },
  { judet: "Suceava", localitate: "Bursuceni" },
  { judet: "Suceava", localitate: "Corocaiesti" },
  { judet: "Suceava", localitate: "Hancea" },
  { judet: "Suceava", localitate: "Bivolaria" },
  { judet: "Suceava", localitate: "Giurgesti" },
  { judet: "Suceava", localitate: "Hreatca" },
  { judet: "Suceava", localitate: "Jacota" },
  { judet: "Suceava", localitate: "Meresti" },
  { judet: "Suceava", localitate: "Osoi" },
  { judet: "Suceava", localitate: "Plesesti" },
  { judet: "Suceava", localitate: "Valea Glodului" },
  { judet: "Suceava", localitate: "Badragi" },
  { judet: "Suceava", localitate: "Ciomartan" },
  { judet: "Suceava", localitate: "Cojocareni" },
  { judet: "Suceava", localitate: "Corpaci" },
  { judet: "Suceava", localitate: "Lunca (Zamostea)" },
  { judet: "Suceava", localitate: "Nicani" },
  { judet: "Suceava", localitate: "Rauteni" },
  { judet: "Suceava", localitate: "Tautesti" },
  { judet: "Suceava", localitate: "Buda (Zvoristea)" },
  { judet: "Suceava", localitate: "Dealu" },
  { judet: "Suceava", localitate: "Poiana (Zvoristea)" },
  { judet: "Suceava", localitate: "Slobozia (Zvoristea)" },
  { judet: "Suceava", localitate: "Stanca" },
  { judet: "Suceava", localitate: "Stancuta" },
  { judet: "Suceava", localitate: "Serbanesti" },
  { judet: "Teleorman", localitate: "Alexandria" },
  { judet: "Teleorman", localitate: "Rosiorii de Vede" },
  { judet: "Teleorman", localitate: "Turnu Magurele" },
  { judet: "Teleorman", localitate: "Zimnicea" },
  { judet: "Teleorman", localitate: "Videle" },
  { judet: "Teleorman", localitate: "Babaita" },
  { judet: "Teleorman", localitate: "Balaci" },
  { judet: "Teleorman", localitate: "Beciu" },
  { judet: "Teleorman", localitate: "Beuca" },
  { judet: "Teleorman", localitate: "Blejesti" },
  { judet: "Teleorman", localitate: "Bogdana" },
  { judet: "Teleorman", localitate: "Botoroaga" },
  { judet: "Teleorman", localitate: "Bragadiru" },
  { judet: "Teleorman", localitate: "Branceni" },
  { judet: "Teleorman", localitate: "Bujoreni" },
  { judet: "Teleorman", localitate: "Bujoru" },
  { judet: "Teleorman", localitate: "Buzescu" },
  { judet: "Teleorman", localitate: "Calinesti" },
  { judet: "Teleorman", localitate: "Calmatuiu" },
  { judet: "Teleorman", localitate: "Calmatuiu de Sus" },
  { judet: "Teleorman", localitate: "Cervenia" },
  { judet: "Teleorman", localitate: "Ciolanesti" },
  { judet: "Teleorman", localitate: "Ciuperceni" },
  { judet: "Teleorman", localitate: "Contesti" },
  { judet: "Teleorman", localitate: "Cosmesti" },
  { judet: "Teleorman", localitate: "Crangu" },
  { judet: "Teleorman", localitate: "Crevenicu" },
  { judet: "Teleorman", localitate: "Crangeni" },
  { judet: "Teleorman", localitate: "Didesti" },
  { judet: "Teleorman", localitate: "Dobrotesti" },
  { judet: "Teleorman", localitate: "Dracea" },
  { judet: "Teleorman", localitate: "Dracsenei" },
  { judet: "Teleorman", localitate: "Draganesti de Vede" },
  { judet: "Teleorman", localitate: "Draganesti-Vlasca" },
  { judet: "Teleorman", localitate: "Fantanele" },
  { judet: "Teleorman", localitate: "Frasinet" },
  { judet: "Teleorman", localitate: "Frumoasa" },
  { judet: "Teleorman", localitate: "Furculesti" },
  { judet: "Teleorman", localitate: "Galateni" },
  { judet: "Teleorman", localitate: "Gratia" },
  { judet: "Teleorman", localitate: "Islaz" },
  { judet: "Teleorman", localitate: "Izvoarele" },
  { judet: "Teleorman", localitate: "Lisa" },
  { judet: "Teleorman", localitate: "Lita" },
  { judet: "Teleorman", localitate: "Lunca" },
  { judet: "Teleorman", localitate: "Magura" },
  { judet: "Teleorman", localitate: "Maldaeni" },
  { judet: "Teleorman", localitate: "Marzanesti" },
  { judet: "Teleorman", localitate: "Mavrodin" },
  { judet: "Teleorman", localitate: "Mereni" },
  { judet: "Teleorman", localitate: "Mosteni" },
  { judet: "Teleorman", localitate: "Nanov" },
  { judet: "Teleorman", localitate: "Nasturelu" },
  { judet: "Teleorman", localitate: "Necsesti" },
  { judet: "Teleorman", localitate: "Nenciulesti" },
  { judet: "Teleorman", localitate: "Olteni" },
  { judet: "Teleorman", localitate: "Orbeasca" },
  { judet: "Teleorman", localitate: "Peretu" },
  { judet: "Teleorman", localitate: "Piatra" },
  { judet: "Teleorman", localitate: "Pietrosani" },
  { judet: "Teleorman", localitate: "Plopii-Slavitesti" },
  { judet: "Teleorman", localitate: "Plosca" },
  { judet: "Teleorman", localitate: "Poeni" },
  { judet: "Teleorman", localitate: "Poroschia" },
  { judet: "Teleorman", localitate: "Purani" },
  { judet: "Teleorman", localitate: "Putineiu" },
  { judet: "Teleorman", localitate: "Radoiesti" },
  { judet: "Teleorman", localitate: "Rasmiresti" },
  { judet: "Teleorman", localitate: "Saceni" },
  { judet: "Teleorman", localitate: "Saelele" },
  { judet: "Teleorman", localitate: "Salcia" },
  { judet: "Teleorman", localitate: "Sarbeni" },
  { judet: "Teleorman", localitate: "Scrioastea" },
  { judet: "Teleorman", localitate: "Scurtu Mare" },
  { judet: "Teleorman", localitate: "Seaca" },
  { judet: "Teleorman", localitate: "Segarcea-Vale" },
  { judet: "Teleorman", localitate: "Sfintesti" },
  { judet: "Teleorman", localitate: "Silistea" },
  { judet: "Teleorman", localitate: "Silistea Gumesti" },
  { judet: "Teleorman", localitate: "Slobozia Mandra" },
  { judet: "Teleorman", localitate: "Smardioasa" },
  { judet: "Teleorman", localitate: "Stejaru" },
  { judet: "Teleorman", localitate: "Storobaneasa" },
  { judet: "Teleorman", localitate: "Suhaia" },
  { judet: "Teleorman", localitate: "Talpa" },
  { judet: "Teleorman", localitate: "Tatarastii de Jos" },
  { judet: "Teleorman", localitate: "Tatarastii de Sus" },
  { judet: "Teleorman", localitate: "Tiganesti" },
  { judet: "Teleorman", localitate: "Traian" },
  { judet: "Teleorman", localitate: "Trivalea-Mosteni" },
  { judet: "Teleorman", localitate: "Troianul" },
  { judet: "Teleorman", localitate: "Uda-Clocociov" },
  { judet: "Teleorman", localitate: "Vartoape" },
  { judet: "Teleorman", localitate: "Vedea" },
  { judet: "Teleorman", localitate: "Viisoara" },
  { judet: "Teleorman", localitate: "Vitanesti" },
  { judet: "Teleorman", localitate: "Zambreasca" },
  { judet: "Teleorman", localitate: "Slavesti" },
  { judet: "Teleorman", localitate: "Cosoaia" },
  { judet: "Teleorman", localitate: "Burdeni" },
  { judet: "Teleorman", localitate: "Tecuci" },
  { judet: "Teleorman", localitate: "Clanita" },
  { judet: "Teleorman", localitate: "Merisani (Babaita)" },
  { judet: "Teleorman", localitate: "Baciu" },
  { judet: "Teleorman", localitate: "Sericu" },
  { judet: "Teleorman", localitate: "Brosteanca" },
  { judet: "Teleorman", localitate: "Ulmeni" },
  { judet: "Teleorman", localitate: "Urluiu" },
  { judet: "Teleorman", localitate: "Calugaru" },
  { judet: "Teleorman", localitate: "Tarnava" },
  { judet: "Teleorman", localitate: "Tunari" },
  { judet: "Teleorman", localitate: "Valea Ciresului" },
  { judet: "Teleorman", localitate: "Darvas" },
  { judet: "Teleorman", localitate: "Prunaru" },
  { judet: "Teleorman", localitate: "Antonesti" },
  { judet: "Teleorman", localitate: "Copaceanca" },
  { judet: "Teleorman", localitate: "Licuriciu" },
  { judet: "Teleorman", localitate: "Marita" },
  { judet: "Teleorman", localitate: "Bujoru (Calmatuiu)" },
  { judet: "Teleorman", localitate: "Caravaneti" },
  { judet: "Teleorman", localitate: "Nicolae Balcescu" },
  { judet: "Teleorman", localitate: "Bacalesti" },
  { judet: "Teleorman", localitate: "Ionascu" },
  { judet: "Teleorman", localitate: "Baldovinesti" },
  { judet: "Teleorman", localitate: "Ciolanestii din Deal" },
  { judet: "Teleorman", localitate: "Ciolanestii din Vale" },
  { judet: "Teleorman", localitate: "Poiana" },
  { judet: "Teleorman", localitate: "Ciuperceni (Cosmesti)" },
  { judet: "Teleorman", localitate: "Balta Sarata" },
  { judet: "Teleorman", localitate: "Dorobantu" },
  { judet: "Teleorman", localitate: "Stejaru (Crangeni)" },
  { judet: "Teleorman", localitate: "Florica" },
  { judet: "Teleorman", localitate: "Secara" },
  { judet: "Teleorman", localitate: "Zlata" },
  { judet: "Teleorman", localitate: "Radulesti" },
  { judet: "Teleorman", localitate: "Insuratei" },
  { judet: "Teleorman", localitate: "Satu Nou" },
  { judet: "Teleorman", localitate: "Merisani (Dobrotesti)" },
  { judet: "Teleorman", localitate: "Dracsani" },
  { judet: "Teleorman", localitate: "Odobeasca" },
  { judet: "Teleorman", localitate: "Plopi" },
  { judet: "Teleorman", localitate: "Satul Vechi" },
  { judet: "Teleorman", localitate: "Magura cu Liliac" },
  { judet: "Teleorman", localitate: "Vacaresti" },
  { judet: "Teleorman", localitate: "Comoara" },
  { judet: "Teleorman", localitate: "Vaceni" },
  { judet: "Teleorman", localitate: "Pauleasca" },
  { judet: "Teleorman", localitate: "Mosteni (Furculesti)" },
  { judet: "Teleorman", localitate: "Spatarei" },
  { judet: "Teleorman", localitate: "Voievoda" },
  { judet: "Teleorman", localitate: "Bascoveni" },
  { judet: "Teleorman", localitate: "Gradisteanca" },
  { judet: "Teleorman", localitate: "Ciurari-Deal" },
  { judet: "Teleorman", localitate: "Draghinesti" },
  { judet: "Teleorman", localitate: "Moldoveni" },
  { judet: "Teleorman", localitate: "Vanatori" },
  { judet: "Teleorman", localitate: "Pleasov" },
  { judet: "Teleorman", localitate: "Prundu" },
  { judet: "Teleorman", localitate: "Paru Rotund" },
  { judet: "Teleorman", localitate: "Guruieni" },
  { judet: "Teleorman", localitate: "Cernetu" },
  { judet: "Teleorman", localitate: "Teleormanu" },
  { judet: "Teleorman", localitate: "Valea Parului" },
  { judet: "Teleorman", localitate: "Merenii de Jos" },
  { judet: "Teleorman", localitate: "Merenii de Sus" },
  { judet: "Teleorman", localitate: "Stefeni" },
  { judet: "Teleorman", localitate: "Zimnicele" },
  { judet: "Teleorman", localitate: "Belciug" },
  { judet: "Teleorman", localitate: "Gardesti" },
  { judet: "Teleorman", localitate: "Perii Brosteni" },
  { judet: "Teleorman", localitate: "Laceni" },
  { judet: "Teleorman", localitate: "Orbeasca de Jos" },
  { judet: "Teleorman", localitate: "Orbeasca de Sus" },
  { judet: "Teleorman", localitate: "Barsestii de Jos" },
  { judet: "Teleorman", localitate: "Brancoveanca" },
  { judet: "Teleorman", localitate: "Dudu" },
  { judet: "Teleorman", localitate: "Smardan" },
  { judet: "Teleorman", localitate: "Banov" },
  { judet: "Teleorman", localitate: "Bratesti" },
  { judet: "Teleorman", localitate: "Catunu" },
  { judet: "Teleorman", localitate: "Preajba" },
  { judet: "Teleorman", localitate: "Tavarlau" },
  { judet: "Teleorman", localitate: "Vatasi" },
  { judet: "Teleorman", localitate: "Calomfiresti" },
  { judet: "Teleorman", localitate: "Baduleasa" },
  { judet: "Teleorman", localitate: "Carlomanu" },
  { judet: "Teleorman", localitate: "Cetatea" },
  { judet: "Teleorman", localitate: "Radoiesti-Deal" },
  { judet: "Teleorman", localitate: "Radoiesti-Vale" },
  { judet: "Teleorman", localitate: "Ludaneasca" },
  { judet: "Teleorman", localitate: "Baneasa" },
  { judet: "Teleorman", localitate: "Tudor Vladimirescu" },
  { judet: "Teleorman", localitate: "Butculesti" },
  { judet: "Teleorman", localitate: "Ciurari" },
  { judet: "Teleorman", localitate: "Sarbenii de Jos" },
  { judet: "Teleorman", localitate: "Udeni" },
  { judet: "Teleorman", localitate: "Brebina" },
  { judet: "Teleorman", localitate: "Cucueti" },
  { judet: "Teleorman", localitate: "Viile" },
  { judet: "Teleorman", localitate: "Albeni" },
  { judet: "Teleorman", localitate: "Dracesti" },
  { judet: "Teleorman", localitate: "Negrilesti" },
  { judet: "Teleorman", localitate: "Scurtu-Slavesti" },
  { judet: "Teleorman", localitate: "Valea Postei" },
  { judet: "Teleorman", localitate: "Navodari" },
  { judet: "Teleorman", localitate: "Olteanca" },
  { judet: "Teleorman", localitate: "Segarcea-Deal" },
  { judet: "Teleorman", localitate: "Butesti" },
  { judet: "Teleorman", localitate: "Puranii de Sus" },
  { judet: "Teleorman", localitate: "Silistea Mica" },
  { judet: "Teleorman", localitate: "Uda-Paciurea" },
  { judet: "Teleorman", localitate: "Soimu" },
  { judet: "Teleorman", localitate: "Bratcovu" },
  { judet: "Teleorman", localitate: "Gresia" },
  { judet: "Teleorman", localitate: "Socetu" },
  { judet: "Teleorman", localitate: "Beiu" },
  { judet: "Teleorman", localitate: "Linia Costii" },
  { judet: "Teleorman", localitate: "Rotaresti" },
  { judet: "Teleorman", localitate: "Talpa Postei" },
  { judet: "Teleorman", localitate: "Talpa-Bascoveni" },
  { judet: "Teleorman", localitate: "Talpa-Ograzile" },
  { judet: "Teleorman", localitate: "Lada" },
  { judet: "Teleorman", localitate: "Negreni" },
  { judet: "Teleorman", localitate: "Negrenii de Sus" },
  { judet: "Teleorman", localitate: "Negrenii-Osebiti" },
  { judet: "Teleorman", localitate: "Obartu" },
  { judet: "Teleorman", localitate: "Dobreni" },
  { judet: "Teleorman", localitate: "Udupu" },
  { judet: "Teleorman", localitate: "Bratasani" },
  { judet: "Teleorman", localitate: "Deparati" },
  { judet: "Teleorman", localitate: "Dulceni" },
  { judet: "Teleorman", localitate: "Vatra" },
  { judet: "Teleorman", localitate: "Garagau" },
  { judet: "Teleorman", localitate: "Vartoapele de Jos" },
  { judet: "Teleorman", localitate: "Vartoapele de Sus" },
  { judet: "Teleorman", localitate: "Albesti" },
  { judet: "Teleorman", localitate: "Cosoteni" },
  { judet: "Teleorman", localitate: "Dulceanca" },
  { judet: "Teleorman", localitate: "Meri" },
  { judet: "Teleorman", localitate: "Purani (Vitanesti)" },
  { judet: "Teleorman", localitate: "Schitu Poienari" },
  { judet: "Teleorman", localitate: "Silistea (Vitanesti)" },
  { judet: "Timis", localitate: "Timisoara" },
  { judet: "Timis", localitate: "Periam" },
  { judet: "Timis", localitate: "Lugoj" },
  { judet: "Timis", localitate: "Jimbolia" },
  { judet: "Timis", localitate: "Sannicolau Mare" },
  { judet: "Timis", localitate: "Buzias" },
  { judet: "Timis", localitate: "Sandra" },
  { judet: "Timis", localitate: "Satchinez" },
  { judet: "Timis", localitate: "Chisoda" },
  { judet: "Timis", localitate: "Dumbravita" },
  { judet: "Timis", localitate: "Ghiroda" },
  { judet: "Timis", localitate: "Faget" },
  { judet: "Timis", localitate: "Deta" },
  { judet: "Timis", localitate: "Gataia" },
  { judet: "Timis", localitate: "Recas" },
  { judet: "Timis", localitate: "Ciacova" },
  { judet: "Timis", localitate: "Balint" },
  { judet: "Timis", localitate: "Banloc" },
  { judet: "Timis", localitate: "Bara" },
  { judet: "Timis", localitate: "Beba Veche" },
  { judet: "Timis", localitate: "Becicherecu Mic" },
  { judet: "Timis", localitate: "Belint" },
  { judet: "Timis", localitate: "Bethausen" },
  { judet: "Timis", localitate: "Biled" },
  { judet: "Timis", localitate: "Birda" },
  { judet: "Timis", localitate: "Barna" },
  { judet: "Timis", localitate: "Bogda" },
  { judet: "Timis", localitate: "Boldur" },
  { judet: "Timis", localitate: "Brestovat" },
  { judet: "Timis", localitate: "Carpinis" },
  { judet: "Timis", localitate: "Cenad" },
  { judet: "Timis", localitate: "Cenei" },
  { judet: "Timis", localitate: "Checea" },
  { judet: "Timis", localitate: "Cheveresu Mare" },
  { judet: "Timis", localitate: "Comlosu Mare" },
  { judet: "Timis", localitate: "Costeiu" },
  { judet: "Timis", localitate: "Criciova" },
  { judet: "Timis", localitate: "Curtea" },
  { judet: "Timis", localitate: "Darova" },
  { judet: "Timis", localitate: "Denta" },
  { judet: "Timis", localitate: "Dudestii Noi" },
  { judet: "Timis", localitate: "Dudestii Vechi" },
  { judet: "Timis", localitate: "Dumbrava" },
  { judet: "Timis", localitate: "Fibis" },
  { judet: "Timis", localitate: "Fardea" },
  { judet: "Timis", localitate: "Foeni" },
  { judet: "Timis", localitate: "Gavojdia" },
  { judet: "Timis", localitate: "Ghilad" },
  { judet: "Timis", localitate: "Ghizela" },
  { judet: "Timis", localitate: "Giarmata" },
  { judet: "Timis", localitate: "Giera" },
  { judet: "Timis", localitate: "Giroc" },
  { judet: "Timis", localitate: "Giulvaz" },
  { judet: "Timis", localitate: "Gottlob" },
  { judet: "Timis", localitate: "Iecea Mare" },
  { judet: "Timis", localitate: "Jamu Mare" },
  { judet: "Timis", localitate: "Jebel" },
  { judet: "Timis", localitate: "Lenauheim" },
  { judet: "Timis", localitate: "Liebling" },
  { judet: "Timis", localitate: "Livezile" },
  { judet: "Timis", localitate: "Lovrin" },
  { judet: "Timis", localitate: "Margina" },
  { judet: "Timis", localitate: "Masloc" },
  { judet: "Timis", localitate: "Manastiur" },
  { judet: "Timis", localitate: "Moravita" },
  { judet: "Timis", localitate: "Mosnita Noua" },
  { judet: "Timis", localitate: "Nadrag" },
  { judet: "Timis", localitate: "Nitchidorf" },
  { judet: "Timis", localitate: "Ohaba Lunga" },
  { judet: "Timis", localitate: "Ortisoara" },
  { judet: "Timis", localitate: "Otelec" },
  { judet: "Timis", localitate: "Parta" },
  { judet: "Timis", localitate: "Padureni" },
  { judet: "Timis", localitate: "Peciu Nou" },
  { judet: "Timis", localitate: "Pesac" },
  { judet: "Timis", localitate: "Pietroasa" },
  { judet: "Timis", localitate: "Pischia" },
  { judet: "Timis", localitate: "Racovita" },
  { judet: "Timis", localitate: "Remetea Mare" },
  { judet: "Timis", localitate: "Sacosu Turcesc" },
  { judet: "Timis", localitate: "Saravale" },
  { judet: "Timis", localitate: "Sacalaz" },
  { judet: "Timis", localitate: "Secas" },
  { judet: "Timis", localitate: "Sanandrei" },
  { judet: "Timis", localitate: "Sanmihaiu Roman" },
  { judet: "Timis", localitate: "Sanpetru Mare" },
  { judet: "Timis", localitate: "Sag" },
  { judet: "Timis", localitate: "Stiuca" },
  { judet: "Timis", localitate: "Teremia Mare" },
  { judet: "Timis", localitate: "Tomesti" },
  { judet: "Timis", localitate: "Tomnatic" },
  { judet: "Timis", localitate: "Topolovatu Mare" },
  { judet: "Timis", localitate: "Tormac" },
  { judet: "Timis", localitate: "Traian Vuia" },
  { judet: "Timis", localitate: "Uivar" },
  { judet: "Timis", localitate: "Valcani" },
  { judet: "Timis", localitate: "Varias" },
  { judet: "Timis", localitate: "Victor Vlad Delamarina" },
  { judet: "Timis", localitate: "Voiteg" },
  { judet: "Timis", localitate: "Carani" },
  { judet: "Timis", localitate: "Bacova" },
  { judet: "Timis", localitate: "Silagiu" },
  { judet: "Timis", localitate: "Opatita" },
  { judet: "Timis", localitate: "Batesti" },
  { judet: "Timis", localitate: "Begheiu Mic" },
  { judet: "Timis", localitate: "Bichigi" },
  { judet: "Timis", localitate: "Branesti" },
  { judet: "Timis", localitate: "Bunea Mare" },
  { judet: "Timis", localitate: "Bunea Mica" },
  { judet: "Timis", localitate: "Colonia Mica" },
  { judet: "Timis", localitate: "Jupanesti" },
  { judet: "Timis", localitate: "Povargina" },
  { judet: "Timis", localitate: "Temeresti" },
  { judet: "Timis", localitate: "Maguri" },
  { judet: "Timis", localitate: "Tapia" },
  { judet: "Timis", localitate: "Bodo" },
  { judet: "Timis", localitate: "Fadimac" },
  { judet: "Timis", localitate: "Targoviste" },
  { judet: "Timis", localitate: "Dolat" },
  { judet: "Timis", localitate: "Ofsenita" },
  { judet: "Timis", localitate: "Partos" },
  { judet: "Timis", localitate: "Soca" },
  { judet: "Timis", localitate: "Dobresti" },
  { judet: "Timis", localitate: "Lapusnic" },
  { judet: "Timis", localitate: "Radmanesti" },
  { judet: "Timis", localitate: "Spata" },
  { judet: "Timis", localitate: "Botesti" },
  { judet: "Timis", localitate: "Botinesti" },
  { judet: "Timis", localitate: "Drinova" },
  { judet: "Timis", localitate: "Juresti" },
  { judet: "Timis", localitate: "Poganesti" },
  { judet: "Timis", localitate: "Sarazani" },
  { judet: "Timis", localitate: "Cherestur" },
  { judet: "Timis", localitate: "Pordeanu" },
  { judet: "Timis", localitate: "Babsa" },
  { judet: "Timis", localitate: "Chizatau" },
  { judet: "Timis", localitate: "Gruni" },
  { judet: "Timis", localitate: "Cladova" },
  { judet: "Timis", localitate: "Cliciova" },
  { judet: "Timis", localitate: "Cutina" },
  { judet: "Timis", localitate: "Leucusesti" },
  { judet: "Timis", localitate: "Nevrincea" },
  { judet: "Timis", localitate: "Uihei" },
  { judet: "Timis", localitate: "Altringen" },
  { judet: "Timis", localitate: "Buzad" },
  { judet: "Timis", localitate: "Charlotenburg" },
  { judet: "Timis", localitate: "Comeat" },
  { judet: "Timis", localitate: "Sintar" },
  { judet: "Timis", localitate: "Jabar" },
  { judet: "Timis", localitate: "Ohaba-Forgaci" },
  { judet: "Timis", localitate: "Sinersig" },
  { judet: "Timis", localitate: "Cosarii" },
  { judet: "Timis", localitate: "Hodos (Brestovat)" },
  { judet: "Timis", localitate: "Lucaret" },
  { judet: "Timis", localitate: "Tes" },
  { judet: "Timis", localitate: "Iecea Mica" },
  { judet: "Timis", localitate: "Bobda" },
  { judet: "Timis", localitate: "Dragsina" },
  { judet: "Timis", localitate: "Vucova" },
  { judet: "Timis", localitate: "Cebza" },
  { judet: "Timis", localitate: "Gad" },
  { judet: "Timis", localitate: "Macedonia" },
  { judet: "Timis", localitate: "Obad" },
  { judet: "Timis", localitate: "Petroman" },
  { judet: "Timis", localitate: "Comlosu Mic" },
  { judet: "Timis", localitate: "Lunga" },
  { judet: "Timis", localitate: "Hezeris" },
  { judet: "Timis", localitate: "Paru" },
  { judet: "Timis", localitate: "Tipari" },
  { judet: "Timis", localitate: "Valea Lunga Romana" },
  { judet: "Timis", localitate: "Ciresu" },
  { judet: "Timis", localitate: "Ciresu Mic" },
  { judet: "Timis", localitate: "Jdioara" },
  { judet: "Timis", localitate: "Cosava" },
  { judet: "Timis", localitate: "Homojdia" },
  { judet: "Timis", localitate: "Hodos (Darova)" },
  { judet: "Timis", localitate: "Sacosu Mare" },
  { judet: "Timis", localitate: "Brestea" },
  { judet: "Timis", localitate: "Rovinita Mare" },
  { judet: "Timis", localitate: "Rovinita Mica" },
  { judet: "Timis", localitate: "Cheglevici" },
  { judet: "Timis", localitate: "Colonia Bulgara" },
  { judet: "Timis", localitate: "Bucovat (Dumbrava)" },
  { judet: "Timis", localitate: "Rachita" },
  { judet: "Timis", localitate: "Dragsinesti" },
  { judet: "Timis", localitate: "Gladna Montana" },
  { judet: "Timis", localitate: "Gladna Romana" },
  { judet: "Timis", localitate: "Hauzesti" },
  { judet: "Timis", localitate: "Matnicu Mic" },
  { judet: "Timis", localitate: "Zolt" },
  { judet: "Timis", localitate: "Cruceni" },
  { judet: "Timis", localitate: "Jena" },
  { judet: "Timis", localitate: "Lugojel" },
  { judet: "Timis", localitate: "Salbagel" },
  { judet: "Timis", localitate: "Berecuta" },
  { judet: "Timis", localitate: "Butin" },
  { judet: "Timis", localitate: "Manastire" },
  { judet: "Timis", localitate: "Percosova" },
  { judet: "Timis", localitate: "Sangeorge" },
  { judet: "Timis", localitate: "Sculia" },
  { judet: "Timis", localitate: "Semlacu Mare" },
  { judet: "Timis", localitate: "Semlacu Mic" },
  { judet: "Timis", localitate: "Giarmata-Vii" },
  { judet: "Timis", localitate: "Hisias" },
  { judet: "Timis", localitate: "Paniova" },
  { judet: "Timis", localitate: "Sanovita" },
  { judet: "Timis", localitate: "Cerneteaz" },
  { judet: "Timis", localitate: "Granicerii" },
  { judet: "Timis", localitate: "Toager" },
  { judet: "Timis", localitate: "Crai Nou" },
  { judet: "Timis", localitate: "Ivanda" },
  { judet: "Timis", localitate: "Rudna" },
  { judet: "Timis", localitate: "Clopodia" },
  { judet: "Timis", localitate: "Ferendia" },
  { judet: "Timis", localitate: "Gherman" },
  { judet: "Timis", localitate: "Latunas" },
  { judet: "Timis", localitate: "Bulgarus" },
  { judet: "Timis", localitate: "Grabat" },
  { judet: "Timis", localitate: "Cerna" },
  { judet: "Timis", localitate: "Iosif" },
  { judet: "Timis", localitate: "Vizejdia" },
  { judet: "Timis", localitate: "Breazova" },
  { judet: "Timis", localitate: "Bulza" },
  { judet: "Timis", localitate: "Cosevita" },
  { judet: "Timis", localitate: "Costeiu de Sus" },
  { judet: "Timis", localitate: "Grosi" },
  { judet: "Timis", localitate: "Nemesesti" },
  { judet: "Timis", localitate: "Sintesti" },
  { judet: "Timis", localitate: "Zorani" },
  { judet: "Timis", localitate: "Alios" },
  { judet: "Timis", localitate: "Remetea Mica" },
  { judet: "Timis", localitate: "Padurani" },
  { judet: "Timis", localitate: "Remetea-Lunca" },
  { judet: "Timis", localitate: "Topla" },
  { judet: "Timis", localitate: "Dejan" },
  { judet: "Timis", localitate: "Gaiu Mic" },
  { judet: "Timis", localitate: "Stamora Germana" },
  { judet: "Timis", localitate: "Albina" },
  { judet: "Timis", localitate: "Mosnita Veche" },
  { judet: "Timis", localitate: "Rudicica" },
  { judet: "Timis", localitate: "Urseni" },
  { judet: "Timis", localitate: "Crivina" },
  { judet: "Timis", localitate: "Blajova" },
  { judet: "Timis", localitate: "Duboz" },
  { judet: "Timis", localitate: "Dubesti" },
  { judet: "Timis", localitate: "Iersnic" },
  { judet: "Timis", localitate: "Ohaba Romana" },
  { judet: "Timis", localitate: "Calacea" },
  { judet: "Timis", localitate: "Cornesti" },
  { judet: "Timis", localitate: "Seceani" },
  { judet: "Timis", localitate: "Dinias" },
  { judet: "Timis", localitate: "Sanmartinu Sarbesc" },
  { judet: "Timis", localitate: "Crivina de Sus" },
  { judet: "Timis", localitate: "Farasesti" },
  { judet: "Timis", localitate: "Poieni" },
  { judet: "Timis", localitate: "Bencecu de Jos" },
  { judet: "Timis", localitate: "Bencecu de Sus" },
  { judet: "Timis", localitate: "Murani" },
  { judet: "Timis", localitate: "Salciua Noua" },
  { judet: "Timis", localitate: "Capat" },
  { judet: "Timis", localitate: "Dragoiesti" },
  { judet: "Timis", localitate: "Ficatar" },
  { judet: "Timis", localitate: "Hitias" },
  { judet: "Timis", localitate: "Sarbova" },
  { judet: "Timis", localitate: "Bazos" },
  { judet: "Timis", localitate: "Herneacova" },
  { judet: "Timis", localitate: "Izvin" },
  { judet: "Timis", localitate: "Nadas" },
  { judet: "Timis", localitate: "Petrovaselo" },
  { judet: "Timis", localitate: "Stanciova" },
  { judet: "Timis", localitate: "Bazosu Nou" },
  { judet: "Timis", localitate: "Bucovat (Remetea Mare)" },
  { judet: "Timis", localitate: "Ianova" },
  { judet: "Timis", localitate: "Berini" },
  { judet: "Timis", localitate: "Icloda" },
  { judet: "Timis", localitate: "Otvesti" },
  { judet: "Timis", localitate: "Stamora Romana" },
  { judet: "Timis", localitate: "Uliuc" },
  { judet: "Timis", localitate: "Unip" },
  { judet: "Timis", localitate: "Barateaz" },
  { judet: "Timis", localitate: "Hodoni" },
  { judet: "Timis", localitate: "Beregsau Mare" },
  { judet: "Timis", localitate: "Beregsau Mic" },
  { judet: "Timis", localitate: "Covaci" },
  { judet: "Timis", localitate: "Sanmihaiu German" },
  { judet: "Timis", localitate: "Utvin" },
  { judet: "Timis", localitate: "Igris" },
  { judet: "Timis", localitate: "Cheches" },
  { judet: "Timis", localitate: "Crivobara" },
  { judet: "Timis", localitate: "Vizma" },
  { judet: "Timis", localitate: "Dragomiresti" },
  { judet: "Timis", localitate: "Olosag" },
  { judet: "Timis", localitate: "Zgribesti" },
  { judet: "Timis", localitate: "Nerau" },
  { judet: "Timis", localitate: "Teremia Mica" },
  { judet: "Timis", localitate: "Balosesti" },
  { judet: "Timis", localitate: "Colonia Fabricii" },
  { judet: "Timis", localitate: "Luncanii de Jos" },
  { judet: "Timis", localitate: "Luncanii de Sus" },
  { judet: "Timis", localitate: "Romanesti" },
  { judet: "Timis", localitate: "Cralovat" },
  { judet: "Timis", localitate: "Ictar-Budinti" },
  { judet: "Timis", localitate: "Iosifalau" },
  { judet: "Timis", localitate: "Sustra" },
  { judet: "Timis", localitate: "Topolovatu Mic" },
  { judet: "Timis", localitate: "Cadar" },
  { judet: "Timis", localitate: "Sipet" },
  { judet: "Timis", localitate: "Jupani" },
  { judet: "Timis", localitate: "Saceni" },
  { judet: "Timis", localitate: "Sudrias" },
  { judet: "Timis", localitate: "Surducu Mic" },
  { judet: "Timis", localitate: "Susani" },
  { judet: "Timis", localitate: "Iohanisfeld" },
  { judet: "Timis", localitate: "Pustinis" },
  { judet: "Timis", localitate: "Rauti" },
  { judet: "Timis", localitate: "Sanmartinu Maghiar" },
  { judet: "Timis", localitate: "Gelu" },
  { judet: "Timis", localitate: "Sanpetru Mic" },
  { judet: "Timis", localitate: "Herendesti" },
  { judet: "Timis", localitate: "Honorici" },
  { judet: "Timis", localitate: "Padureni (Victor Vlad Delamarina)" },
  { judet: "Timis", localitate: "Petroasa Mare" },
  { judet: "Timis", localitate: "Pini" },
  { judet: "Timis", localitate: "Visag" },
  { judet: "Timis", localitate: "Folea" },
  { judet: "Tulcea", localitate: "Tulcea" },
  { judet: "Tulcea", localitate: "Macin" },
  { judet: "Tulcea", localitate: "Babadag" },
  { judet: "Tulcea", localitate: "Isaccea" },
  { judet: "Tulcea", localitate: "Sulina" },
  { judet: "Tulcea", localitate: "Baia" },
  { judet: "Tulcea", localitate: "Beidaud" },
  { judet: "Tulcea", localitate: "Bestepe" },
  { judet: "Tulcea", localitate: "C.A. Rosetti" },
  { judet: "Tulcea", localitate: "Carcaliu" },
  { judet: "Tulcea", localitate: "Casimcea" },
  { judet: "Tulcea", localitate: "Ceamurlia de Jos" },
  { judet: "Tulcea", localitate: "Ceatalchioi" },
  { judet: "Tulcea", localitate: "Cerna" },
  { judet: "Tulcea", localitate: "Chilia Veche" },
  { judet: "Tulcea", localitate: "Ciucurova" },
  { judet: "Tulcea", localitate: "Crisan" },
  { judet: "Tulcea", localitate: "Daeni" },
  { judet: "Tulcea", localitate: "Dorobantu" },
  { judet: "Tulcea", localitate: "Frecatei" },
  { judet: "Tulcea", localitate: "Greci" },
  { judet: "Tulcea", localitate: "Grindu" },
  { judet: "Tulcea", localitate: "Hamcearca" },
  { judet: "Tulcea", localitate: "Horia" },
  { judet: "Tulcea", localitate: "I.C. Bratianu" },
  { judet: "Tulcea", localitate: "Izvoarele" },
  { judet: "Tulcea", localitate: "Jijila" },
  { judet: "Tulcea", localitate: "Jurilovca" },
  { judet: "Tulcea", localitate: "Luncavita" },
  { judet: "Tulcea", localitate: "Mahmudia" },
  { judet: "Tulcea", localitate: "Maliuc" },
  { judet: "Tulcea", localitate: "Mihai Bravu" },
  { judet: "Tulcea", localitate: "Mihail Kogalniceanu" },
  { judet: "Tulcea", localitate: "Murighiol" },
  { judet: "Tulcea", localitate: "Nalbant" },
  { judet: "Tulcea", localitate: "Niculitel" },
  { judet: "Tulcea", localitate: "Nufaru" },
  { judet: "Tulcea", localitate: "Ostrov" },
  { judet: "Tulcea", localitate: "Pardina" },
  { judet: "Tulcea", localitate: "Peceneaga" },
  { judet: "Tulcea", localitate: "Sarichioi" },
  { judet: "Tulcea", localitate: "Sfantu Gheorghe" },
  { judet: "Tulcea", localitate: "Slava Cercheza" },
  { judet: "Tulcea", localitate: "Smardan" },
  { judet: "Tulcea", localitate: "Somova" },
  { judet: "Tulcea", localitate: "Stejaru" },
  { judet: "Tulcea", localitate: "Topolog" },
  { judet: "Tulcea", localitate: "Turcoaia" },
  { judet: "Tulcea", localitate: "Valea Nucarilor" },
  { judet: "Tulcea", localitate: "Valea Teilor" },
  { judet: "Tulcea", localitate: "Vacareni" },
  { judet: "Tulcea", localitate: "Cataloi" },
  { judet: "Tulcea", localitate: "Tudor Vladimirescu" },
  { judet: "Tulcea", localitate: "Revarsarea" },
  { judet: "Tulcea", localitate: "Tichilesti" },
  { judet: "Tulcea", localitate: "Camena" },
  { judet: "Tulcea", localitate: "Caugagia" },
  { judet: "Tulcea", localitate: "Ceamurlia de Sus" },
  { judet: "Tulcea", localitate: "Panduru" },
  { judet: "Tulcea", localitate: "Neatarnarea" },
  { judet: "Tulcea", localitate: "Sarighiol de Deal" },
  { judet: "Tulcea", localitate: "Cardon" },
  { judet: "Tulcea", localitate: "Letea" },
  { judet: "Tulcea", localitate: "Periprava" },
  { judet: "Tulcea", localitate: "Sfistofca" },
  { judet: "Tulcea", localitate: "Cismeaua Noua" },
  { judet: "Tulcea", localitate: "Corugea" },
  { judet: "Tulcea", localitate: "Haidar" },
  { judet: "Tulcea", localitate: "Rahman" },
  { judet: "Tulcea", localitate: "Razboieni" },
  { judet: "Tulcea", localitate: "Stanca" },
  { judet: "Tulcea", localitate: "Lunca" },
  { judet: "Tulcea", localitate: "Patlageanca" },
  { judet: "Tulcea", localitate: "Plauru" },
  { judet: "Tulcea", localitate: "Salceni" },
  { judet: "Tulcea", localitate: "General Praporgescu" },
  { judet: "Tulcea", localitate: "Mircea Voda" },
  { judet: "Tulcea", localitate: "Traian" },
  { judet: "Tulcea", localitate: "Caslita" },
  { judet: "Tulcea", localitate: "Ostrov Tataru" },
  { judet: "Tulcea", localitate: "Tatanir" },
  { judet: "Tulcea", localitate: "Atmagea" },
  { judet: "Tulcea", localitate: "Fantana Mare" },
  { judet: "Tulcea", localitate: "Caraorman" },
  { judet: "Tulcea", localitate: "Mila 23" },
  { judet: "Tulcea", localitate: "Ardealu" },
  { judet: "Tulcea", localitate: "Carjelari" },
  { judet: "Tulcea", localitate: "Fantana Oilor" },
  { judet: "Tulcea", localitate: "Mesteru" },
  { judet: "Tulcea", localitate: "Posta" },
  { judet: "Tulcea", localitate: "Telita" },
  { judet: "Tulcea", localitate: "Balabancea" },
  { judet: "Tulcea", localitate: "Caprioara" },
  { judet: "Tulcea", localitate: "Nifon" },
  { judet: "Tulcea", localitate: "Closca" },
  { judet: "Tulcea", localitate: "Floresti" },
  { judet: "Tulcea", localitate: "Alba" },
  { judet: "Tulcea", localitate: "Iulia" },
  { judet: "Tulcea", localitate: "Garvan" },
  { judet: "Tulcea", localitate: "Salcioara" },
  { judet: "Tulcea", localitate: "Visina" },
  { judet: "Tulcea", localitate: "Rachelu" },
  { judet: "Tulcea", localitate: "Baltenii de Jos" },
  { judet: "Tulcea", localitate: "Baltenii de Sus" },
  { judet: "Tulcea", localitate: "Gorgova" },
  { judet: "Tulcea", localitate: "Ilganii de Sus" },
  { judet: "Tulcea", localitate: "Partizani" },
  { judet: "Tulcea", localitate: "Vulturu" },
  { judet: "Tulcea", localitate: "Satu Nou" },
  { judet: "Tulcea", localitate: "Turda" },
  { judet: "Tulcea", localitate: "Lastuni" },
  { judet: "Tulcea", localitate: "Randunica" },
  { judet: "Tulcea", localitate: "Colina" },
  { judet: "Tulcea", localitate: "Dunavatu de Jos" },
  { judet: "Tulcea", localitate: "Dunavatu de Sus" },
  { judet: "Tulcea", localitate: "Plopul" },
  { judet: "Tulcea", localitate: "Sarinasuf" },
  { judet: "Tulcea", localitate: "Uzlina" },
  { judet: "Tulcea", localitate: "Nicolae Balcescu" },
  { judet: "Tulcea", localitate: "Trestenic" },
  { judet: "Tulcea", localitate: "Ilganii de Jos" },
  { judet: "Tulcea", localitate: "Malcoci" },
  { judet: "Tulcea", localitate: "Victoria" },
  { judet: "Tulcea", localitate: "Piatra" },
  { judet: "Tulcea", localitate: "Enisala" },
  { judet: "Tulcea", localitate: "Sabangia" },
  { judet: "Tulcea", localitate: "Visterna" },
  { judet: "Tulcea", localitate: "Zebil" },
  { judet: "Tulcea", localitate: "Slava Rusa" },
  { judet: "Tulcea", localitate: "Mineri" },
  { judet: "Tulcea", localitate: "Parches" },
  { judet: "Tulcea", localitate: "Mina Altan Tepe" },
  { judet: "Tulcea", localitate: "Vasile Alecsandri" },
  { judet: "Tulcea", localitate: "Calfa" },
  { judet: "Tulcea", localitate: "Cerbu" },
  { judet: "Tulcea", localitate: "Fagarasu Nou" },
  { judet: "Tulcea", localitate: "Luminita" },
  { judet: "Tulcea", localitate: "Magurele" },
  { judet: "Tulcea", localitate: "Sambata Noua" },
  { judet: "Tulcea", localitate: "Agighiol" },
  { judet: "Tulcea", localitate: "Iazurile" },
  { judet: "Valcea", localitate: "Ramnicu Valcea" },
  { judet: "Valcea", localitate: "Dragasani" },
  { judet: "Valcea", localitate: "Balcesti" },
  { judet: "Valcea", localitate: "Susani" },
  { judet: "Valcea", localitate: "Babeni" },
  { judet: "Valcea", localitate: "Calimanesti" },
  { judet: "Valcea", localitate: "Brezoi" },
  { judet: "Valcea", localitate: "Horezu" },
  { judet: "Valcea", localitate: "Baile Olanesti" },
  { judet: "Valcea", localitate: "Ocnele Mari" },
  { judet: "Valcea", localitate: "Baile Govora" },
  { judet: "Valcea", localitate: "Berbesti" },
  { judet: "Valcea", localitate: "Alunu" },
  { judet: "Valcea", localitate: "Amarasti" },
  { judet: "Valcea", localitate: "Barbatesti" },
  { judet: "Valcea", localitate: "Berislavesti" },
  { judet: "Valcea", localitate: "Boisoara" },
  { judet: "Valcea", localitate: "Budesti" },
  { judet: "Valcea", localitate: "Bujoreni" },
  { judet: "Valcea", localitate: "Bunesti" },
  { judet: "Valcea", localitate: "Caineni" },
  { judet: "Valcea", localitate: "Cernisoara" },
  { judet: "Valcea", localitate: "Copaceni" },
  { judet: "Valcea", localitate: "Costesti" },
  { judet: "Valcea", localitate: "Creteni" },
  { judet: "Valcea", localitate: "Daesti" },
  { judet: "Valcea", localitate: "Danicei" },
  { judet: "Valcea", localitate: "Diculesti" },
  { judet: "Valcea", localitate: "Dragoesti" },
  { judet: "Valcea", localitate: "Fartatesti" },
  { judet: "Valcea", localitate: "Fauresti" },
  { judet: "Valcea", localitate: "Francesti" },
  { judet: "Valcea", localitate: "Galicea" },
  { judet: "Valcea", localitate: "Ghioroiu" },
  { judet: "Valcea", localitate: "Glavile" },
  { judet: "Valcea", localitate: "Golesti" },
  { judet: "Valcea", localitate: "Gradistea" },
  { judet: "Valcea", localitate: "Gusoeni" },
  { judet: "Valcea", localitate: "Ionesti" },
  { judet: "Valcea", localitate: "Lacusteni" },
  { judet: "Valcea", localitate: "Ladesti" },
  { judet: "Valcea", localitate: "Lalosu" },
  { judet: "Valcea", localitate: "Lapusata" },
  { judet: "Valcea", localitate: "Livezi" },
  { judet: "Valcea", localitate: "Lungesti" },
  { judet: "Valcea", localitate: "Maciuca" },
  { judet: "Valcea", localitate: "Madulari" },
  { judet: "Valcea", localitate: "Malaia" },
  { judet: "Valcea", localitate: "Maldaresti" },
  { judet: "Valcea", localitate: "Mateesti" },
  { judet: "Valcea", localitate: "Mihaesti" },
  { judet: "Valcea", localitate: "Milcoiu" },
  { judet: "Valcea", localitate: "Mitrofani" },
  { judet: "Valcea", localitate: "Muereasca" },
  { judet: "Valcea", localitate: "Nicolae Balcescu" },
  { judet: "Valcea", localitate: "Olanu" },
  { judet: "Valcea", localitate: "Orlesti" },
  { judet: "Valcea", localitate: "Otesani" },
  { judet: "Valcea", localitate: "Pausesti" },
  { judet: "Valcea", localitate: "Pausesti-Maglasi" },
  { judet: "Valcea", localitate: "Perisani" },
  { judet: "Valcea", localitate: "Pesceana" },
  { judet: "Valcea", localitate: "Pietrari" },
  { judet: "Valcea", localitate: "Popesti" },
  { judet: "Valcea", localitate: "Prundeni" },
  { judet: "Valcea", localitate: "Racovita" },
  { judet: "Valcea", localitate: "Roesti" },
  { judet: "Valcea", localitate: "Rosiile" },
  { judet: "Valcea", localitate: "Runcu" },
  { judet: "Valcea", localitate: "Salatrucel" },
  { judet: "Valcea", localitate: "Scundu" },
  { judet: "Valcea", localitate: "Sinesti" },
  { judet: "Valcea", localitate: "Sirineasa" },
  { judet: "Valcea", localitate: "Slatioara" },
  { judet: "Valcea", localitate: "Stanesti" },
  { judet: "Valcea", localitate: "Stefanesti" },
  { judet: "Valcea", localitate: "Stoenesti" },
  { judet: "Valcea", localitate: "Stoilesti" },
  { judet: "Valcea", localitate: "Stroesti" },
  { judet: "Valcea", localitate: "Tetoiu" },
  { judet: "Valcea", localitate: "Sutesti" },
  { judet: "Valcea", localitate: "Titesti" },
  { judet: "Valcea", localitate: "Tomsani" },
  { judet: "Valcea", localitate: "Vaideeni" },
  { judet: "Valcea", localitate: "Valea Mare" },
  { judet: "Valcea", localitate: "Vladesti" },
  { judet: "Valcea", localitate: "Voicesti" },
  { judet: "Valcea", localitate: "Voineasa" },
  { judet: "Valcea", localitate: "Zatreni" },
  { judet: "Valcea", localitate: "Aranghel" },
  { judet: "Valcea", localitate: "Cazanesti (Ramnicu Valcea)" },
  { judet: "Valcea", localitate: "Copacelu" },
  { judet: "Valcea", localitate: "Dealu Malului" },
  { judet: "Valcea", localitate: "Poenari" },
  { judet: "Valcea", localitate: "Priba" },
  { judet: "Valcea", localitate: "Stolniceni" },
  { judet: "Valcea", localitate: "Troian" },
  { judet: "Valcea", localitate: "Raureni" },
  { judet: "Valcea", localitate: "Bonciu" },
  { judet: "Valcea", localitate: "Capu Dealului" },
  { judet: "Valcea", localitate: "Paduretu" },
  { judet: "Valcea", localitate: "Romani" },
  { judet: "Valcea", localitate: "Tatarani" },
  { judet: "Valcea", localitate: "Valea Mare (Babeni)" },
  { judet: "Valcea", localitate: "Curaturile" },
  { judet: "Valcea", localitate: "Gatejesti" },
  { judet: "Valcea", localitate: "Prajila" },
  { judet: "Valcea", localitate: "Cheia" },
  { judet: "Valcea", localitate: "Comanca" },
  { judet: "Valcea", localitate: "Gurguiata" },
  { judet: "Valcea", localitate: "Livadia" },
  { judet: "Valcea", localitate: "Mosoroasa" },
  { judet: "Valcea", localitate: "Olanesti" },
  { judet: "Valcea", localitate: "Pietrisu" },
  { judet: "Valcea", localitate: "Tisa" },
  { judet: "Valcea", localitate: "Benesti" },
  { judet: "Valcea", localitate: "Carlogani" },
  { judet: "Valcea", localitate: "Chirculesti" },
  { judet: "Valcea", localitate: "Gorunesti (Balcesti)" },
  { judet: "Valcea", localitate: "Irimesti" },
  { judet: "Valcea", localitate: "Otetelisu" },
  { judet: "Valcea", localitate: "Preotesti" },
  { judet: "Valcea", localitate: "Satu Poieni" },
  { judet: "Valcea", localitate: "Calinesti" },
  { judet: "Valcea", localitate: "Corbu" },
  { judet: "Valcea", localitate: "Draganesti (Brezoi)" },
  { judet: "Valcea", localitate: "Golotreni" },
  { judet: "Valcea", localitate: "Pascoaia" },
  { judet: "Valcea", localitate: "Proieni" },
  { judet: "Valcea", localitate: "Valea lui Stan" },
  { judet: "Valcea", localitate: "Varatica" },
  { judet: "Valcea", localitate: "Caciulata" },
  { judet: "Valcea", localitate: "Jiblea Noua" },
  { judet: "Valcea", localitate: "Jiblea Veche" },
  { judet: "Valcea", localitate: "Pausa" },
  { judet: "Valcea", localitate: "Seaca (Calimanesti)" },
  { judet: "Valcea", localitate: "Valea Caselor (Dragasani)" },
  { judet: "Valcea", localitate: "Zarneni" },
  { judet: "Valcea", localitate: "Zlatarei" },
  { judet: "Valcea", localitate: "Ifrimesti" },
  { judet: "Valcea", localitate: "Ramesti (Horezu)" },
  { judet: "Valcea", localitate: "Romanii de Jos" },
  { judet: "Valcea", localitate: "Romanii de Sus" },
  { judet: "Valcea", localitate: "Tanasesti" },
  { judet: "Valcea", localitate: "Ursani" },
  { judet: "Valcea", localitate: "Buda" },
  { judet: "Valcea", localitate: "Cosota" },
  { judet: "Valcea", localitate: "Facai" },
  { judet: "Valcea", localitate: "Gura Suhasului" },
  { judet: "Valcea", localitate: "Lunca (Ocnele Mari)" },
  { judet: "Valcea", localitate: "Ocnita" },
  { judet: "Valcea", localitate: "Slatioarele" },
  { judet: "Valcea", localitate: "Teica" },
  { judet: "Valcea", localitate: "Bodesti (Alunu)" },
  { judet: "Valcea", localitate: "Coltesti" },
  { judet: "Valcea", localitate: "Igoiu" },
  { judet: "Valcea", localitate: "Ilaciu" },
  { judet: "Valcea", localitate: "Ocracu" },
  { judet: "Valcea", localitate: "Rosia" },
  { judet: "Valcea", localitate: "Meresesti" },
  { judet: "Valcea", localitate: "Nemoiu" },
  { judet: "Valcea", localitate: "Padina" },
  { judet: "Valcea", localitate: "Palanga" },
  { judet: "Valcea", localitate: "Teiul" },
  { judet: "Valcea", localitate: "Barzesti" },
  { judet: "Valcea", localitate: "Bodesti (Barbatesti)" },
  { judet: "Valcea", localitate: "Negrulesti" },
  { judet: "Valcea", localitate: "Damteni" },
  { judet: "Valcea", localitate: "Dealu Alunis" },
  { judet: "Valcea", localitate: "Rosioara" },
  { judet: "Valcea", localitate: "Targu Gangulesti" },
  { judet: "Valcea", localitate: "Valea Mare (Berbesti)" },
  { judet: "Valcea", localitate: "Bradisor" },
  { judet: "Valcea", localitate: "Dangesti" },
  { judet: "Valcea", localitate: "Radacinesti" },
  { judet: "Valcea", localitate: "Robaia" },
  { judet: "Valcea", localitate: "Scaueni" },
  { judet: "Valcea", localitate: "Stoenesti (Berislavesti)" },
  { judet: "Valcea", localitate: "Bumbuesti" },
  { judet: "Valcea", localitate: "Gaujani" },
  { judet: "Valcea", localitate: "Barza" },
  { judet: "Valcea", localitate: "Barsesti (Budesti)" },
  { judet: "Valcea", localitate: "Bercioiu" },
  { judet: "Valcea", localitate: "Linia (Budesti)" },
  { judet: "Valcea", localitate: "Piscu Pietrei" },
  { judet: "Valcea", localitate: "Racovita (Budesti)" },
  { judet: "Valcea", localitate: "Ruda" },
  { judet: "Valcea", localitate: "Bogdanesti (Bujoreni)" },
  { judet: "Valcea", localitate: "Gura Vaii" },
  { judet: "Valcea", localitate: "Lunca (Bujoreni)" },
  { judet: "Valcea", localitate: "Malu Alb" },
  { judet: "Valcea", localitate: "Malu Vartop" },
  { judet: "Valcea", localitate: "Olteni" },
  { judet: "Valcea", localitate: "Coasta Mare" },
  { judet: "Valcea", localitate: "Firesti" },
  { judet: "Valcea", localitate: "Rapanesti" },
  { judet: "Valcea", localitate: "Teiusu" },
  { judet: "Valcea", localitate: "Titireci" },
  { judet: "Valcea", localitate: "Cainenii Mari" },
  { judet: "Valcea", localitate: "Cainenii Mici" },
  { judet: "Valcea", localitate: "Greblesti" },
  { judet: "Valcea", localitate: "Priloage" },
  { judet: "Valcea", localitate: "Rau Vadului" },
  { judet: "Valcea", localitate: "Robesti" },
  { judet: "Valcea", localitate: "Armasesti" },
  { judet: "Valcea", localitate: "Grosi" },
  { judet: "Valcea", localitate: "Madulari (Cernisoara)" },
  { judet: "Valcea", localitate: "Modoia" },
  { judet: "Valcea", localitate: "Obarsia" },
  { judet: "Valcea", localitate: "Sarsanesti" },
  { judet: "Valcea", localitate: "Balteni" },
  { judet: "Valcea", localitate: "Bondoci" },
  { judet: "Valcea", localitate: "Hotarasa" },
  { judet: "Valcea", localitate: "Ulmetu" },
  { judet: "Valcea", localitate: "Vetelu" },
  { judet: "Valcea", localitate: "Bistrita" },
  { judet: "Valcea", localitate: "Pietreni" },
  { judet: "Valcea", localitate: "Varatici" },
  { judet: "Valcea", localitate: "Izvoru" },
  { judet: "Valcea", localitate: "Mrenesti" },
  { judet: "Valcea", localitate: "Streminoasa" },
  { judet: "Valcea", localitate: "Babuesti" },
  { judet: "Valcea", localitate: "Fedelesoiu" },
  { judet: "Valcea", localitate: "Sanbotin" },
  { judet: "Valcea", localitate: "Badeni" },
  { judet: "Valcea", localitate: "Ceretu" },
  { judet: "Valcea", localitate: "Ciresul" },
  { judet: "Valcea", localitate: "Dealu Launele" },
  { judet: "Valcea", localitate: "Dealu Scheiului" },
  { judet: "Valcea", localitate: "Dobresti" },
  { judet: "Valcea", localitate: "Dragulesti" },
  { judet: "Valcea", localitate: "Glodu" },
  { judet: "Valcea", localitate: "Gura Crucilor" },
  { judet: "Valcea", localitate: "Launele de Jos" },
  { judet: "Valcea", localitate: "Linia pe Vale" },
  { judet: "Valcea", localitate: "Udresti" },
  { judet: "Valcea", localitate: "Valea Scheiului" },
  { judet: "Valcea", localitate: "Buciumeni" },
  { judet: "Valcea", localitate: "Geamana (Dragoesti)" },
  { judet: "Valcea", localitate: "Babeni-Oltetu" },
  { judet: "Valcea", localitate: "Budesti (Diculesti)" },
  { judet: "Valcea", localitate: "Bungetani" },
  { judet: "Valcea", localitate: "Colelia" },
  { judet: "Valcea", localitate: "Gainesti" },
  { judet: "Valcea", localitate: "Marcusu" },
  { judet: "Valcea", localitate: "Milesti" },
  { judet: "Valcea", localitate: "Afanata" },
  { judet: "Valcea", localitate: "Becsani" },
  { judet: "Valcea", localitate: "Catetu" },
  { judet: "Valcea", localitate: "Cuci" },
  { judet: "Valcea", localitate: "Dancai" },
  { judet: "Valcea", localitate: "Dejoi" },
  { judet: "Valcea", localitate: "Dozesti" },
  { judet: "Valcea", localitate: "Garnicet" },
  { judet: "Valcea", localitate: "Giulesti" },
  { judet: "Valcea", localitate: "Giulestii de Sus" },
  { judet: "Valcea", localitate: "Maricesti" },
  { judet: "Valcea", localitate: "Nisipi" },
  { judet: "Valcea", localitate: "Popesti (Fartatesti)" },
  { judet: "Valcea", localitate: "Rusanesti" },
  { judet: "Valcea", localitate: "Seciu" },
  { judet: "Valcea", localitate: "Stanculesti" },
  { judet: "Valcea", localitate: "Sotani" },
  { judet: "Valcea", localitate: "Tanislavi" },
  { judet: "Valcea", localitate: "Valea Ursului" },
  { judet: "Valcea", localitate: "Balutoaia" },
  { judet: "Valcea", localitate: "Cosani" },
  { judet: "Valcea", localitate: "Dezrobiti" },
  { judet: "Valcea", localitate: "Genuneni" },
  { judet: "Valcea", localitate: "Manailesti" },
  { judet: "Valcea", localitate: "Mosteni" },
  { judet: "Valcea", localitate: "Surpatele" },
  { judet: "Valcea", localitate: "Viisoara" },
  { judet: "Valcea", localitate: "Bratia din Deal" },
  { judet: "Valcea", localitate: "Bratia din Vale" },
  { judet: "Valcea", localitate: "Cocoru" },
  { judet: "Valcea", localitate: "Cremenari" },
  { judet: "Valcea", localitate: "Dealu Mare (Galicea)" },
  { judet: "Valcea", localitate: "Ostroveni" },
  { judet: "Valcea", localitate: "Teiu" },
  { judet: "Valcea", localitate: "Valea Raului" },
  { judet: "Valcea", localitate: "Cazanesti (Ghioroiu)" },
  { judet: "Valcea", localitate: "Herasti" },
  { judet: "Valcea", localitate: "Mierea" },
  { judet: "Valcea", localitate: "Poienari" },
  { judet: "Valcea", localitate: "Stirbesti" },
  { judet: "Valcea", localitate: "Aninoasa" },
  { judet: "Valcea", localitate: "Jarostea" },
  { judet: "Valcea", localitate: "Olteanca (Glavile)" },
  { judet: "Valcea", localitate: "Voiculeasa" },
  { judet: "Valcea", localitate: "Aldesti" },
  { judet: "Valcea", localitate: "Blidari" },
  { judet: "Valcea", localitate: "Coasta (Golesti)" },
  { judet: "Valcea", localitate: "Draganesti (Golesti)" },
  { judet: "Valcea", localitate: "Gibesti" },
  { judet: "Valcea", localitate: "Giurgiuveni" },
  { judet: "Valcea", localitate: "Opatesti" },
  { judet: "Valcea", localitate: "Poenita" },
  { judet: "Valcea", localitate: "Popesti (Golesti)" },
  { judet: "Valcea", localitate: "Tulei-Campeni" },
  { judet: "Valcea", localitate: "Vatasesti" },
  { judet: "Valcea", localitate: "Diaconesti" },
  { judet: "Valcea", localitate: "Dobricea" },
  { judet: "Valcea", localitate: "Linia (Gradistea)" },
  { judet: "Valcea", localitate: "Obislavu" },
  { judet: "Valcea", localitate: "Strachinesti" },
  { judet: "Valcea", localitate: "Turburea" },
  { judet: "Valcea", localitate: "Tuturu" },
  { judet: "Valcea", localitate: "Valea Gradistei" },
  { judet: "Valcea", localitate: "Burdalesti" },
  { judet: "Valcea", localitate: "Dealu Mare (Gusoeni)" },
  { judet: "Valcea", localitate: "Gusoianca" },
  { judet: "Valcea", localitate: "Magureni" },
  { judet: "Valcea", localitate: "Sparleni" },
  { judet: "Valcea", localitate: "Bucsani" },
  { judet: "Valcea", localitate: "Dealu Mare (Ionesti)" },
  { judet: "Valcea", localitate: "Delureni (Ionesti)" },
  { judet: "Valcea", localitate: "Fiscalia" },
  { judet: "Valcea", localitate: "Fotesti" },
  { judet: "Valcea", localitate: "Guguianca" },
  { judet: "Valcea", localitate: "Marcea" },
  { judet: "Valcea", localitate: "Prodanesti" },
  { judet: "Valcea", localitate: "Berbesti (Lalosu)" },
  { judet: "Valcea", localitate: "Ghindari" },
  { judet: "Valcea", localitate: "Mologesti" },
  { judet: "Valcea", localitate: "Oltetani" },
  { judet: "Valcea", localitate: "Portaresti" },
  { judet: "Valcea", localitate: "Cermegesti (Ladesti)" },
  { judet: "Valcea", localitate: "Chiricesti" },
  { judet: "Valcea", localitate: "Ciumagi" },
  { judet: "Valcea", localitate: "Dealu Corni" },
  { judet: "Valcea", localitate: "Gageni" },
  { judet: "Valcea", localitate: "Maldaresti (Ladesti)" },
  { judet: "Valcea", localitate: "Olteanca (Ladesti)" },
  { judet: "Valcea", localitate: "Pasculesti" },
  { judet: "Valcea", localitate: "Popesti (Ladesti)" },
  { judet: "Valcea", localitate: "Beresti" },
  { judet: "Valcea", localitate: "Brosteni" },
  { judet: "Valcea", localitate: "Mijati" },
  { judet: "Valcea", localitate: "Sarulesti" },
  { judet: "Valcea", localitate: "Scorusu" },
  { judet: "Valcea", localitate: "Serbanesti (Lapusata)" },
  { judet: "Valcea", localitate: "Zarnesti" },
  { judet: "Valcea", localitate: "Parausani" },
  { judet: "Valcea", localitate: "Paraienii de Jos" },
  { judet: "Valcea", localitate: "Paraienii de Mijloc" },
  { judet: "Valcea", localitate: "Paraienii de Sus" },
  { judet: "Valcea", localitate: "Plesoiu (Livezi)" },
  { judet: "Valcea", localitate: "Tina" },
  { judet: "Valcea", localitate: "Carcadiesti" },
  { judet: "Valcea", localitate: "Dumbrava" },
  { judet: "Valcea", localitate: "Fumureni" },
  { judet: "Valcea", localitate: "Gantulei" },
  { judet: "Valcea", localitate: "Stanesti-Lunca" },
  { judet: "Valcea", localitate: "Ciungetu" },
  { judet: "Valcea", localitate: "Salistea" },
  { judet: "Valcea", localitate: "Greci" },
  { judet: "Valcea", localitate: "Turcesti" },
  { judet: "Valcea", localitate: "Bocsa" },
  { judet: "Valcea", localitate: "Botorani" },
  { judet: "Valcea", localitate: "Ciocanari" },
  { judet: "Valcea", localitate: "Maciuceni" },
  { judet: "Valcea", localitate: "Maldaresti (Maciuca)" },
  { judet: "Valcea", localitate: "Oveselu" },
  { judet: "Valcea", localitate: "Popesti (Maciuca)" },
  { judet: "Valcea", localitate: "Stefanesti (Maciuca)" },
  { judet: "Valcea", localitate: "Zavoieni" },
  { judet: "Valcea", localitate: "Balsoara" },
  { judet: "Valcea", localitate: "Bantesti" },
  { judet: "Valcea", localitate: "Dimulesti" },
  { judet: "Valcea", localitate: "Iacovile" },
  { judet: "Valcea", localitate: "Mamu" },
  { judet: "Valcea", localitate: "Maldarestii de Jos" },
  { judet: "Valcea", localitate: "Rosoveni" },
  { judet: "Valcea", localitate: "Telechesti" },
  { judet: "Valcea", localitate: "Arsanca" },
  { judet: "Valcea", localitate: "Barsesti (Mihaesti)" },
  { judet: "Valcea", localitate: "Buleta" },
  { judet: "Valcea", localitate: "Govora" },
  { judet: "Valcea", localitate: "Gurisoara" },
  { judet: "Valcea", localitate: "Magura" },
  { judet: "Valcea", localitate: "Munteni" },
  { judet: "Valcea", localitate: "Negreni" },
  { judet: "Valcea", localitate: "Rugetu (Mihaesti)" },
  { judet: "Valcea", localitate: "Scarisoara" },
  { judet: "Valcea", localitate: "Stuparei" },
  { judet: "Valcea", localitate: "Vulpuesti" },
  { judet: "Valcea", localitate: "Cazanesti (Milcoiu)" },
  { judet: "Valcea", localitate: "Ciutesti" },
  { judet: "Valcea", localitate: "Izbasesti" },
  { judet: "Valcea", localitate: "Suricaru" },
  { judet: "Valcea", localitate: "Tepsenari" },
  { judet: "Valcea", localitate: "Andreiesti" },
  { judet: "Valcea", localitate: "Francesti-Coasta" },
  { judet: "Valcea", localitate: "Gavanesti" },
  { judet: "Valcea", localitate: "Hotarele" },
  { judet: "Valcea", localitate: "Muereasca de Sus" },
  { judet: "Valcea", localitate: "Pripoara" },
  { judet: "Valcea", localitate: "Suta" },
  { judet: "Valcea", localitate: "Banesti" },
  { judet: "Valcea", localitate: "Corbii din Vale" },
  { judet: "Valcea", localitate: "Dosu Raului" },
  { judet: "Valcea", localitate: "Galtofani" },
  { judet: "Valcea", localitate: "Ginerica" },
  { judet: "Valcea", localitate: "Linia Hanului" },
  { judet: "Valcea", localitate: "Mazararu" },
  { judet: "Valcea", localitate: "Mangureni" },
  { judet: "Valcea", localitate: "Plesoiu (Nicolae Balcescu)" },
  { judet: "Valcea", localitate: "Popesti (Nicolae Balcescu)" },
  { judet: "Valcea", localitate: "Predesti" },
  { judet: "Valcea", localitate: "Rotarasti" },
  { judet: "Valcea", localitate: "Schitu" },
  { judet: "Valcea", localitate: "Serbaneasa" },
  { judet: "Valcea", localitate: "Tufanii" },
  { judet: "Valcea", localitate: "Valea Balceasca" },
  { judet: "Valcea", localitate: "Valea Viei" },
  { judet: "Valcea", localitate: "Casa Veche" },
  { judet: "Valcea", localitate: "Cioboti" },
  { judet: "Valcea", localitate: "Dragioiu" },
  { judet: "Valcea", localitate: "Nicolesti" },
  { judet: "Valcea", localitate: "Stoicanesti" },
  { judet: "Valcea", localitate: "Auresti" },
  { judet: "Valcea", localitate: "Procopoaia" },
  { judet: "Valcea", localitate: "Scaiosi" },
  { judet: "Valcea", localitate: "Silea" },
  { judet: "Valcea", localitate: "Bogdanesti (Otesani)" },
  { judet: "Valcea", localitate: "Carstanesti" },
  { judet: "Valcea", localitate: "Cucesti" },
  { judet: "Valcea", localitate: "Sub Deal" },
  { judet: "Valcea", localitate: "Barcanele" },
  { judet: "Valcea", localitate: "Buzdugan" },
  { judet: "Valcea", localitate: "Cernelele" },
  { judet: "Valcea", localitate: "Pausesti-Otasau" },
  { judet: "Valcea", localitate: "Serbanesti (Pausesti)" },
  { judet: "Valcea", localitate: "Solicesti" },
  { judet: "Valcea", localitate: "Valeni (Pausesti)" },
  { judet: "Valcea", localitate: "Coasta (Pausesti-Maglasi)" },
  { judet: "Valcea", localitate: "Pietrari (Pausesti-Maglasi)" },
  { judet: "Valcea", localitate: "Ulmetel" },
  { judet: "Valcea", localitate: "Valea Cheii" },
  { judet: "Valcea", localitate: "Vladuceni" },
  { judet: "Valcea", localitate: "Baiasu" },
  { judet: "Valcea", localitate: "Bratovesti" },
  { judet: "Valcea", localitate: "Cucoiu" },
  { judet: "Valcea", localitate: "Mlaceni" },
  { judet: "Valcea", localitate: "Podeni" },
  { judet: "Valcea", localitate: "Poiana" },
  { judet: "Valcea", localitate: "Pripoare" },
  { judet: "Valcea", localitate: "Spinu" },
  { judet: "Valcea", localitate: "Surdoiu" },
  { judet: "Valcea", localitate: "Cermegesti (Pesceana)" },
  { judet: "Valcea", localitate: "Lupoaia" },
  { judet: "Valcea", localitate: "Negraia" },
  { judet: "Valcea", localitate: "Roesti (Pesceana)" },
  { judet: "Valcea", localitate: "Ursoaia" },
  { judet: "Valcea", localitate: "Pietrarii de Sus" },
  { judet: "Valcea", localitate: "Curtea" },
  { judet: "Valcea", localitate: "Daesti (Popesti)" },
  { judet: "Valcea", localitate: "Firijba" },
  { judet: "Valcea", localitate: "Meieni" },
  { judet: "Valcea", localitate: "Ursi (Popesti)" },
  { judet: "Valcea", localitate: "Valea Caselor (Popesti)" },
  { judet: "Valcea", localitate: "Barbuceni" },
  { judet: "Valcea", localitate: "Calina" },
  { judet: "Valcea", localitate: "Zavideni" },
  { judet: "Valcea", localitate: "Balota" },
  { judet: "Valcea", localitate: "Blanoiu" },
  { judet: "Valcea", localitate: "Bradu-Clocotici" },
  { judet: "Valcea", localitate: "Copaceni (Racovita)" },
  { judet: "Valcea", localitate: "Gruiu Lupului" },
  { judet: "Valcea", localitate: "Tutulesti" },
  { judet: "Valcea", localitate: "Baiasa" },
  { judet: "Valcea", localitate: "Bajenari" },
  { judet: "Valcea", localitate: "Barbarigeni" },
  { judet: "Valcea", localitate: "Ciocaltei" },
  { judet: "Valcea", localitate: "Cueni" },
  { judet: "Valcea", localitate: "Frasina" },
  { judet: "Valcea", localitate: "Piscu Scoartei" },
  { judet: "Valcea", localitate: "Rapa Caramizii" },
  { judet: "Valcea", localitate: "Saioci" },
  { judet: "Valcea", localitate: "Balaciu" },
  { judet: "Valcea", localitate: "Cherasti" },
  { judet: "Valcea", localitate: "Hotaroaia" },
  { judet: "Valcea", localitate: "Lupuiesti" },
  { judet: "Valcea", localitate: "Pasarei" },
  { judet: "Valcea", localitate: "Pertesti" },
  { judet: "Valcea", localitate: "Plesesti" },
  { judet: "Valcea", localitate: "Ratalesti" },
  { judet: "Valcea", localitate: "Romanesti" },
  { judet: "Valcea", localitate: "Zgubea" },
  { judet: "Valcea", localitate: "Caligi" },
  { judet: "Valcea", localitate: "Gropeni" },
  { judet: "Valcea", localitate: "Snamana" },
  { judet: "Valcea", localitate: "Surpati" },
  { judet: "Valcea", localitate: "Valea Babei" },
  { judet: "Valcea", localitate: "Varateci" },
  { judet: "Valcea", localitate: "Patesti" },
  { judet: "Valcea", localitate: "Seaca (Salatrucel)" },
  { judet: "Valcea", localitate: "Serbanesti (Salatrucel)" },
  { judet: "Valcea", localitate: "Avramesti" },
  { judet: "Valcea", localitate: "Blejani" },
  { judet: "Valcea", localitate: "Crangu" },
  { judet: "Valcea", localitate: "Ciucheti" },
  { judet: "Valcea", localitate: "Dealu Bisericii" },
  { judet: "Valcea", localitate: "Mijlocu" },
  { judet: "Valcea", localitate: "Popesti (Sinesti)" },
  { judet: "Valcea", localitate: "Urzica" },
  { judet: "Valcea", localitate: "Coasta Cerbului" },
  { judet: "Valcea", localitate: "Gorunesti (Slatioara)" },
  { judet: "Valcea", localitate: "Milostea" },
  { judet: "Valcea", localitate: "Mogesti" },
  { judet: "Valcea", localitate: "Rugetu (Slatioara)" },
  { judet: "Valcea", localitate: "Barcanesti" },
  { judet: "Valcea", localitate: "Cioponesti" },
  { judet: "Valcea", localitate: "Cuculesti" },
  { judet: "Valcea", localitate: "Garnicetu" },
  { judet: "Valcea", localitate: "Linia Dealului" },
  { judet: "Valcea", localitate: "Suiesti" },
  { judet: "Valcea", localitate: "Valea Lunga" },
  { judet: "Valcea", localitate: "Varleni" },
  { judet: "Valcea", localitate: "Barlogu" },
  { judet: "Valcea", localitate: "Budurasti" },
  { judet: "Valcea", localitate: "Deleni" },
  { judet: "Valcea", localitate: "Dobriceni" },
  { judet: "Valcea", localitate: "Gruieri" },
  { judet: "Valcea", localitate: "Gruiu" },
  { judet: "Valcea", localitate: "Mogosesti" },
  { judet: "Valcea", localitate: "Neghinesti" },
  { judet: "Valcea", localitate: "Piscu Mare" },
  { judet: "Valcea", localitate: "Popesti (Stoenesti)" },
  { judet: "Valcea", localitate: "Suseni" },
  { judet: "Valcea", localitate: "Zmeuratu" },
  { judet: "Valcea", localitate: "Balomireasa" },
  { judet: "Valcea", localitate: "Barsoiu" },
  { judet: "Valcea", localitate: "Bulagei" },
  { judet: "Valcea", localitate: "Delureni (Stoilesti)" },
  { judet: "Valcea", localitate: "Geamana (Stoilesti)" },
  { judet: "Valcea", localitate: "Ghiobesti" },
  { judet: "Valcea", localitate: "Giuroiu" },
  { judet: "Valcea", localitate: "Izvoru Rece (Stoilesti)" },
  { judet: "Valcea", localitate: "Malu" },
  { judet: "Valcea", localitate: "Netesti" },
  { judet: "Valcea", localitate: "Obogeni" },
  { judet: "Valcea", localitate: "Stanesti (Stoilesti)" },
  { judet: "Valcea", localitate: "Ursi (Stoilesti)" },
  { judet: "Valcea", localitate: "Vladulesti" },
  { judet: "Valcea", localitate: "Ciresu" },
  { judet: "Valcea", localitate: "Dianu" },
  { judet: "Valcea", localitate: "Obrocesti" },
  { judet: "Valcea", localitate: "Pojogi-Cerna" },
  { judet: "Valcea", localitate: "Borosesti" },
  { judet: "Valcea", localitate: "Cetateaua" },
  { judet: "Valcea", localitate: "Izvorasu" },
  { judet: "Valcea", localitate: "Mazili" },
  { judet: "Valcea", localitate: "Racu" },
  { judet: "Valcea", localitate: "Verdea" },
  { judet: "Valcea", localitate: "Aricioaia" },
  { judet: "Valcea", localitate: "Ciorasti" },
  { judet: "Valcea", localitate: "Slavitesti" },
  { judet: "Valcea", localitate: "Valea Alunisului" },
  { judet: "Valcea", localitate: "Condoiesti" },
  { judet: "Valcea", localitate: "Dobrusa" },
  { judet: "Valcea", localitate: "Serbanesti (Stefanesti)" },
  { judet: "Valcea", localitate: "Ramesti (Susani)" },
  { judet: "Valcea", localitate: "Sarbi" },
  { judet: "Valcea", localitate: "Stoiculesti" },
  { judet: "Valcea", localitate: "Usurei" },
  { judet: "Valcea", localitate: "Baroiu" },
  { judet: "Valcea", localitate: "Budele" },
  { judet: "Valcea", localitate: "Maneasa" },
  { judet: "Valcea", localitate: "Nenciulesti" },
  { judet: "Valcea", localitate: "Popesti (Tetoiu)" },
  { judet: "Valcea", localitate: "Tepesti" },
  { judet: "Valcea", localitate: "Baltateni" },
  { judet: "Valcea", localitate: "Bogdanesti (Tomsani)" },
  { judet: "Valcea", localitate: "Chiceni" },
  { judet: "Valcea", localitate: "Dumbravesti" },
  { judet: "Valcea", localitate: "Folestii de Jos" },
  { judet: "Valcea", localitate: "Miresti" },
  { judet: "Valcea", localitate: "Folestii de Sus" },
  { judet: "Valcea", localitate: "Cerna" },
  { judet: "Valcea", localitate: "Cornet" },
  { judet: "Valcea", localitate: "Izvoru Rece (Vaideeni)" },
  { judet: "Valcea", localitate: "Marita" },
  { judet: "Valcea", localitate: "Batasani" },
  { judet: "Valcea", localitate: "Delureni (Valea Mare)" },
  { judet: "Valcea", localitate: "Draganu" },
  { judet: "Valcea", localitate: "Margineni" },
  { judet: "Valcea", localitate: "Pietroasa" },
  { judet: "Valcea", localitate: "Fundatura" },
  { judet: "Valcea", localitate: "Pleasa" },
  { judet: "Valcea", localitate: "Priporu" },
  { judet: "Valcea", localitate: "Trundin" },
  { judet: "Valcea", localitate: "Tighina" },
  { judet: "Valcea", localitate: "Voicestii din Vale" },
  { judet: "Valcea", localitate: "Valea Macesului" },
  { judet: "Valcea", localitate: "Voinesita" },
  { judet: "Valcea", localitate: "Butanu" },
  { judet: "Valcea", localitate: "Ciortesti" },
  { judet: "Valcea", localitate: "Contea" },
  { judet: "Valcea", localitate: "Dealu Glameia" },
  { judet: "Valcea", localitate: "Dealu Valeni" },
  { judet: "Valcea", localitate: "Fauresti (Zatreni)" },
  { judet: "Valcea", localitate: "Ganesti" },
  { judet: "Valcea", localitate: "Lacustenii de Jos" },
  { judet: "Valcea", localitate: "Lacustenii de Sus" },
  { judet: "Valcea", localitate: "Manicea" },
  { judet: "Valcea", localitate: "Mecea" },
  { judet: "Valcea", localitate: "Oltetu" },
  { judet: "Valcea", localitate: "Sascioara" },
  { judet: "Valcea", localitate: "Stanomiru" },
  { judet: "Valcea", localitate: "Valea Valeni" },
  { judet: "Valcea", localitate: "Valeni (Zatreni)" },
  { judet: "Valcea", localitate: "Zatrenii de Sus" },
  { judet: "Vaslui", localitate: "Vaslui" },
  { judet: "Vaslui", localitate: "Barlad" },
  { judet: "Vaslui", localitate: "Husi" },
  { judet: "Vaslui", localitate: "Negresti" },
  { judet: "Vaslui", localitate: "Murgeni" },
  { judet: "Vaslui", localitate: "Albesti" },
  { judet: "Vaslui", localitate: "Alexandru Vlahuta" },
  { judet: "Vaslui", localitate: "Arsura" },
  { judet: "Vaslui", localitate: "Bacani" },
  { judet: "Vaslui", localitate: "Bacesti" },
  { judet: "Vaslui", localitate: "Balteni" },
  { judet: "Vaslui", localitate: "Banca" },
  { judet: "Vaslui", localitate: "Berezeni" },
  { judet: "Vaslui", localitate: "Blagesti" },
  { judet: "Vaslui", localitate: "Bogdana" },
  { judet: "Vaslui", localitate: "Bogdanesti" },
  { judet: "Vaslui", localitate: "Bogdanita" },
  { judet: "Vaslui", localitate: "Botesti" },
  { judet: "Vaslui", localitate: "Bunesti-Averesti" },
  { judet: "Vaslui", localitate: "Ciocani" },
  { judet: "Vaslui", localitate: "Codaesti" },
  { judet: "Vaslui", localitate: "Coroiesti" },
  { judet: "Vaslui", localitate: "Costesti" },
  { judet: "Vaslui", localitate: "Cozmesti" },
  { judet: "Vaslui", localitate: "Cretesti" },
  { judet: "Vaslui", localitate: "Danesti" },
  { judet: "Vaslui", localitate: "Deleni" },
  { judet: "Vaslui", localitate: "Delesti" },
  { judet: "Vaslui", localitate: "Dimitrie Cantemir" },
  { judet: "Vaslui", localitate: "Dodesti" },
  { judet: "Vaslui", localitate: "Dragomiresti" },
  { judet: "Vaslui", localitate: "Dranceni" },
  { judet: "Vaslui", localitate: "Duda-Epureni" },
  { judet: "Vaslui", localitate: "Dumesti" },
  { judet: "Vaslui", localitate: "Epureni" },
  { judet: "Vaslui", localitate: "Falciu" },
  { judet: "Vaslui", localitate: "Feresti" },
  { judet: "Vaslui", localitate: "Fruntiseni" },
  { judet: "Vaslui", localitate: "Gagesti" },
  { judet: "Vaslui", localitate: "Garceni" },
  { judet: "Vaslui", localitate: "Gherghesti" },
  { judet: "Vaslui", localitate: "Grivita" },
  { judet: "Vaslui", localitate: "Hoceni" },
  { judet: "Vaslui", localitate: "Iana" },
  { judet: "Vaslui", localitate: "Ibanesti" },
  { judet: "Vaslui", localitate: "Ivanesti" },
  { judet: "Vaslui", localitate: "Ivesti" },
  { judet: "Vaslui", localitate: "Laza" },
  { judet: "Vaslui", localitate: "Lipovat" },
  { judet: "Vaslui", localitate: "Lunca Banului" },
  { judet: "Vaslui", localitate: "Malusteni" },
  { judet: "Vaslui", localitate: "Miclesti" },
  { judet: "Vaslui", localitate: "Muntenii de Jos" },
  { judet: "Vaslui", localitate: "Muntenii de Sus" },
  { judet: "Vaslui", localitate: "Oltenesti" },
  { judet: "Vaslui", localitate: "Osesti" },
  { judet: "Vaslui", localitate: "Padureni" },
  { judet: "Vaslui", localitate: "Perieni" },
  { judet: "Vaslui", localitate: "Pochidia" },
  { judet: "Vaslui", localitate: "Pogana" },
  { judet: "Vaslui", localitate: "Pogonesti" },
  { judet: "Vaslui", localitate: "Poienesti" },
  { judet: "Vaslui", localitate: "Puiesti" },
  { judet: "Vaslui", localitate: "Pungesti" },
  { judet: "Vaslui", localitate: "Puscasi" },
  { judet: "Vaslui", localitate: "Rafaila" },
  { judet: "Vaslui", localitate: "Rebricea" },
  { judet: "Vaslui", localitate: "Rosiesti" },
  { judet: "Vaslui", localitate: "Solesti" },
  { judet: "Vaslui", localitate: "Stanilesti" },
  { judet: "Vaslui", localitate: "Stefan cel Mare" },
  { judet: "Vaslui", localitate: "Suletea" },
  { judet: "Vaslui", localitate: "Tacuta" },
  { judet: "Vaslui", localitate: "Tanacu" },
  { judet: "Vaslui", localitate: "Tatarani" },
  { judet: "Vaslui", localitate: "Todiresti" },
  { judet: "Vaslui", localitate: "Tutova" },
  { judet: "Vaslui", localitate: "Valeni" },
  { judet: "Vaslui", localitate: "Vetrisoaia" },
  { judet: "Vaslui", localitate: "Viisoara" },
  { judet: "Vaslui", localitate: "Vinderei" },
  { judet: "Vaslui", localitate: "Voinesti" },
  { judet: "Vaslui", localitate: "Vulturesti" },
  { judet: "Vaslui", localitate: "Vutcani" },
  { judet: "Vaslui", localitate: "Zapodeni" },
  { judet: "Vaslui", localitate: "Zorleni" },
  { judet: "Vaslui", localitate: "Bahnari" },
  { judet: "Vaslui", localitate: "Brodoc" },
  { judet: "Vaslui", localitate: "Moara Grecilor" },
  { judet: "Vaslui", localitate: "Rediu (Vaslui)" },
  { judet: "Vaslui", localitate: "Viisoara (Vaslui)" },
  { judet: "Vaslui", localitate: "Cazanesti" },
  { judet: "Vaslui", localitate: "Cioatele" },
  { judet: "Vaslui", localitate: "Glodeni" },
  { judet: "Vaslui", localitate: "Parpanita" },
  { judet: "Vaslui", localitate: "Poiana" },
  { judet: "Vaslui", localitate: "Valea Mare (Negresti)" },
  { judet: "Vaslui", localitate: "Corni-Albesti" },
  { judet: "Vaslui", localitate: "Crasna" },
  { judet: "Vaslui", localitate: "Gura Albesti" },
  { judet: "Vaslui", localitate: "Buda (Alexandru Vlahuta)" },
  { judet: "Vaslui", localitate: "Ghicani" },
  { judet: "Vaslui", localitate: "Manzati" },
  { judet: "Vaslui", localitate: "Morareni" },
  { judet: "Vaslui", localitate: "Putu Olarului" },
  { judet: "Vaslui", localitate: "Fundatura (Arsura)" },
  { judet: "Vaslui", localitate: "Mihail Kogalniceanu" },
  { judet: "Vaslui", localitate: "Pahnesti" },
  { judet: "Vaslui", localitate: "1 Decembrie" },
  { judet: "Vaslui", localitate: "Gara Banca" },
  { judet: "Vaslui", localitate: "Ghermanesti (Banca)" },
  { judet: "Vaslui", localitate: "Miclesti (Banca)" },
  { judet: "Vaslui", localitate: "Mitoc" },
  { judet: "Vaslui", localitate: "Satu Nou (Banca)" },
  { judet: "Vaslui", localitate: "Salcioara" },
  { judet: "Vaslui", localitate: "Sarbi" },
  { judet: "Vaslui", localitate: "Stoisesti" },
  { judet: "Vaslui", localitate: "Stramtura-Mitoc" },
  { judet: "Vaslui", localitate: "Tifu" },
  { judet: "Vaslui", localitate: "Baltateni" },
  { judet: "Vaslui", localitate: "Drujesti" },
  { judet: "Vaslui", localitate: "Suseni" },
  { judet: "Vaslui", localitate: "Vulpaseni" },
  { judet: "Vaslui", localitate: "Armaseni (Bacesti)" },
  { judet: "Vaslui", localitate: "Babusa" },
  { judet: "Vaslui", localitate: "Paltinis" },
  { judet: "Vaslui", localitate: "Tibanestii Buhlii" },
  { judet: "Vaslui", localitate: "Vovriesti" },
  { judet: "Vaslui", localitate: "Balteni-Deal" },
  { judet: "Vaslui", localitate: "Chetresti" },
  { judet: "Vaslui", localitate: "Musata" },
  { judet: "Vaslui", localitate: "Ranceni" },
  { judet: "Vaslui", localitate: "Satu Nou (Berezeni)" },
  { judet: "Vaslui", localitate: "Stuhulet" },
  { judet: "Vaslui", localitate: "Igesti" },
  { judet: "Vaslui", localitate: "Sipeni" },
  { judet: "Vaslui", localitate: "Arsita" },
  { judet: "Vaslui", localitate: "Fantana Blanarului" },
  { judet: "Vaslui", localitate: "Gavanu" },
  { judet: "Vaslui", localitate: "Lacu Babei" },
  { judet: "Vaslui", localitate: "Plopeni" },
  { judet: "Vaslui", localitate: "Similisoara" },
  { judet: "Vaslui", localitate: "Suceveni" },
  { judet: "Vaslui", localitate: "Verdes" },
  { judet: "Vaslui", localitate: "Buda (Bogdanesti)" },
  { judet: "Vaslui", localitate: "Horoiata" },
  { judet: "Vaslui", localitate: "Hupca" },
  { judet: "Vaslui", localitate: "Orgoiesti" },
  { judet: "Vaslui", localitate: "Ulea" },
  { judet: "Vaslui", localitate: "Untesti" },
  { judet: "Vaslui", localitate: "Visinari" },
  { judet: "Vaslui", localitate: "Vladesti" },
  { judet: "Vaslui", localitate: "Cartibasi" },
  { judet: "Vaslui", localitate: "Cepesti" },
  { judet: "Vaslui", localitate: "Coroiesti (Bogdanita)" },
  { judet: "Vaslui", localitate: "Radaesti" },
  { judet: "Vaslui", localitate: "Schitu" },
  { judet: "Vaslui", localitate: "Tunsesti" },
  { judet: "Vaslui", localitate: "Ganesti" },
  { judet: "Vaslui", localitate: "Gugesti" },
  { judet: "Vaslui", localitate: "Talpigeni" },
  { judet: "Vaslui", localitate: "Armaseni (Bunesti-Averesti)" },
  { judet: "Vaslui", localitate: "Bunesti" },
  { judet: "Vaslui", localitate: "Plopi" },
  { judet: "Vaslui", localitate: "Podu Oprii" },
  { judet: "Vaslui", localitate: "Averesti" },
  { judet: "Vaslui", localitate: "Rosiori" },
  { judet: "Vaslui", localitate: "Tabalaiesti" },
  { judet: "Vaslui", localitate: "Ghergheleu" },
  { judet: "Vaslui", localitate: "Pribesti" },
  { judet: "Vaslui", localitate: "Rediu Galian" },
  { judet: "Vaslui", localitate: "Chilieni" },
  { judet: "Vaslui", localitate: "Coroiestii de Sus" },
  { judet: "Vaslui", localitate: "Hreasca" },
  { judet: "Vaslui", localitate: "Mireni" },
  { judet: "Vaslui", localitate: "Movileni" },
  { judet: "Vaslui", localitate: "Pacuraresti" },
  { judet: "Vaslui", localitate: "Chitcani" },
  { judet: "Vaslui", localitate: "Dinga" },
  { judet: "Vaslui", localitate: "Parvesti" },
  { judet: "Vaslui", localitate: "Puntiseni" },
  { judet: "Vaslui", localitate: "Radesti" },
  { judet: "Vaslui", localitate: "Budesti" },
  { judet: "Vaslui", localitate: "Cretestii de Sus" },
  { judet: "Vaslui", localitate: "Satu Nou (Cretesti)" },
  { judet: "Vaslui", localitate: "Bereasa" },
  { judet: "Vaslui", localitate: "Botoaia" },
  { judet: "Vaslui", localitate: "Emil Racovita" },
  { judet: "Vaslui", localitate: "Rascani (Danesti)" },
  { judet: "Vaslui", localitate: "Tatarani (Danesti)" },
  { judet: "Vaslui", localitate: "Bulboaca" },
  { judet: "Vaslui", localitate: "Moreni" },
  { judet: "Vaslui", localitate: "Zizinca" },
  { judet: "Vaslui", localitate: "Albesti (Delesti)" },
  { judet: "Vaslui", localitate: "Balesti" },
  { judet: "Vaslui", localitate: "Fastaci" },
  { judet: "Vaslui", localitate: "Fundatura (Delesti)" },
  { judet: "Vaslui", localitate: "Harsova" },
  { judet: "Vaslui", localitate: "Hordilesti" },
  { judet: "Vaslui", localitate: "Manastirea (Delesti)" },
  { judet: "Vaslui", localitate: "Raduiesti" },
  { judet: "Vaslui", localitate: "Grumezoaia" },
  { judet: "Vaslui", localitate: "Gusitei" },
  { judet: "Vaslui", localitate: "Hurdugi" },
  { judet: "Vaslui", localitate: "Plotonesti" },
  { judet: "Vaslui", localitate: "Urlati" },
  { judet: "Vaslui", localitate: "Babuta" },
  { judet: "Vaslui", localitate: "Belzeni" },
  { judet: "Vaslui", localitate: "Botoi" },
  { judet: "Vaslui", localitate: "Ciuperca" },
  { judet: "Vaslui", localitate: "Doagele" },
  { judet: "Vaslui", localitate: "Poiana Pietrei" },
  { judet: "Vaslui", localitate: "Popesti (Dragomiresti)" },
  { judet: "Vaslui", localitate: "Radeni" },
  { judet: "Vaslui", localitate: "Semenea" },
  { judet: "Vaslui", localitate: "Tulesti" },
  { judet: "Vaslui", localitate: "Vladia" },
  { judet: "Vaslui", localitate: "Albita" },
  { judet: "Vaslui", localitate: "Baile Dranceni" },
  { judet: "Vaslui", localitate: "Ghermanesti (Dranceni)" },
  { judet: "Vaslui", localitate: "Rasesti" },
  { judet: "Vaslui", localitate: "Soparleni" },
  { judet: "Vaslui", localitate: "Bobesti" },
  { judet: "Vaslui", localitate: "Duda" },
  { judet: "Vaslui", localitate: "Epureni (Duda-Epureni)" },
  { judet: "Vaslui", localitate: "Valea Grecului" },
  { judet: "Vaslui", localitate: "Dumestii Vechi" },
  { judet: "Vaslui", localitate: "Schinetea" },
  { judet: "Vaslui", localitate: "Valea Mare (Dumesti)" },
  { judet: "Vaslui", localitate: "Barlalesti" },
  { judet: "Vaslui", localitate: "Bursuci" },
  { judet: "Vaslui", localitate: "Horga" },
  { judet: "Vaslui", localitate: "Bogdanesti (Falciu)" },
  { judet: "Vaslui", localitate: "Bozia" },
  { judet: "Vaslui", localitate: "Copaceana" },
  { judet: "Vaslui", localitate: "Odaia Bogdana" },
  { judet: "Vaslui", localitate: "Ranzesti" },
  { judet: "Vaslui", localitate: "Giurcani" },
  { judet: "Vaslui", localitate: "Peicani" },
  { judet: "Vaslui", localitate: "Popeni (Gagesti)" },
  { judet: "Vaslui", localitate: "Tupilati" },
  { judet: "Vaslui", localitate: "Dumbraveni" },
  { judet: "Vaslui", localitate: "Racova" },
  { judet: "Vaslui", localitate: "Racovita" },
  { judet: "Vaslui", localitate: "Slobozia" },
  { judet: "Vaslui", localitate: "Trohan" },
  { judet: "Vaslui", localitate: "Chetrosu" },
  { judet: "Vaslui", localitate: "Corodesti" },
  { judet: "Vaslui", localitate: "Dragomanesti" },
  { judet: "Vaslui", localitate: "Draxeni" },
  { judet: "Vaslui", localitate: "Lazu" },
  { judet: "Vaslui", localitate: "Lunca" },
  { judet: "Vaslui", localitate: "Soci" },
  { judet: "Vaslui", localitate: "Valea Lupului" },
  { judet: "Vaslui", localitate: "Grajdeni" },
  { judet: "Vaslui", localitate: "Odaia Bursucani" },
  { judet: "Vaslui", localitate: "Trestiana" },
  { judet: "Vaslui", localitate: "Barbosi" },
  { judet: "Vaslui", localitate: "Deleni (Hoceni)" },
  { judet: "Vaslui", localitate: "Oteleni" },
  { judet: "Vaslui", localitate: "Rediu (Hoceni)" },
  { judet: "Vaslui", localitate: "Siscani" },
  { judet: "Vaslui", localitate: "Tomsa" },
  { judet: "Vaslui", localitate: "Halaresti" },
  { judet: "Vaslui", localitate: "Recea" },
  { judet: "Vaslui", localitate: "Silistea (Iana)" },
  { judet: "Vaslui", localitate: "Vadurile" },
  { judet: "Vaslui", localitate: "Albina" },
  { judet: "Vaslui", localitate: "Blesca" },
  { judet: "Vaslui", localitate: "Brosteni" },
  { judet: "Vaslui", localitate: "Buscata" },
  { judet: "Vaslui", localitate: "Cosca" },
  { judet: "Vaslui", localitate: "Cosesti" },
  { judet: "Vaslui", localitate: "Fundatura Mare" },
  { judet: "Vaslui", localitate: "Fundatura Mica" },
  { judet: "Vaslui", localitate: "Harsoveni" },
  { judet: "Vaslui", localitate: "Iezerel" },
  { judet: "Vaslui", localitate: "Ursoaia" },
  { judet: "Vaslui", localitate: "Valea Mare (Ivanesti)" },
  { judet: "Vaslui", localitate: "Valea Oanei" },
  { judet: "Vaslui", localitate: "Belcesti" },
  { judet: "Vaslui", localitate: "Polocin" },
  { judet: "Vaslui", localitate: "Bejenesti" },
  { judet: "Vaslui", localitate: "Poiana lui Alexa" },
  { judet: "Vaslui", localitate: "Rasnita" },
  { judet: "Vaslui", localitate: "Sauca" },
  { judet: "Vaslui", localitate: "Teisoru" },
  { judet: "Vaslui", localitate: "Valea Targului" },
  { judet: "Vaslui", localitate: "Capusneni" },
  { judet: "Vaslui", localitate: "Chitoc" },
  { judet: "Vaslui", localitate: "Corbu" },
  { judet: "Vaslui", localitate: "Fundu Vaii (Lipovat)" },
  { judet: "Vaslui", localitate: "Broscosesti" },
  { judet: "Vaslui", localitate: "Condrea" },
  { judet: "Vaslui", localitate: "Focsa" },
  { judet: "Vaslui", localitate: "Lunca Veche" },
  { judet: "Vaslui", localitate: "Otetoaia" },
  { judet: "Vaslui", localitate: "Raducani" },
  { judet: "Vaslui", localitate: "Ghireasca" },
  { judet: "Vaslui", localitate: "Lupesti" },
  { judet: "Vaslui", localitate: "Manastirea (Malusteni)" },
  { judet: "Vaslui", localitate: "Manzatesti" },
  { judet: "Vaslui", localitate: "Tutcani" },
  { judet: "Vaslui", localitate: "Chircesti" },
  { judet: "Vaslui", localitate: "Popesti (Miclesti)" },
  { judet: "Vaslui", localitate: "Bacaoani" },
  { judet: "Vaslui", localitate: "Manjesti" },
  { judet: "Vaslui", localitate: "Secuia" },
  { judet: "Vaslui", localitate: "Carja" },
  { judet: "Vaslui", localitate: "Floreni" },
  { judet: "Vaslui", localitate: "Latesti" },
  { judet: "Vaslui", localitate: "Raiu" },
  { judet: "Vaslui", localitate: "Sarateni" },
  { judet: "Vaslui", localitate: "Schineni" },
  { judet: "Vaslui", localitate: "Curteni" },
  { judet: "Vaslui", localitate: "Pahna" },
  { judet: "Vaslui", localitate: "Tarzii" },
  { judet: "Vaslui", localitate: "Vinetesti" },
  { judet: "Vaslui", localitate: "Zgura" },
  { judet: "Vaslui", localitate: "Buda (Osesti)" },
  { judet: "Vaslui", localitate: "Padureni (Osesti)" },
  { judet: "Vaslui", localitate: "Valcele" },
  { judet: "Vaslui", localitate: "Capotesti" },
  { judet: "Vaslui", localitate: "Davidesti" },
  { judet: "Vaslui", localitate: "Ivanesti (Padureni)" },
  { judet: "Vaslui", localitate: "Leosti (Padureni)" },
  { judet: "Vaslui", localitate: "Rusca" },
  { judet: "Vaslui", localitate: "Todireni" },
  { judet: "Vaslui", localitate: "Valeni (Padureni)" },
  { judet: "Vaslui", localitate: "Crang" },
  { judet: "Vaslui", localitate: "Crangu Nou" },
  { judet: "Vaslui", localitate: "Podu Petris" },
  { judet: "Vaslui", localitate: "Bogesti" },
  { judet: "Vaslui", localitate: "Carjoani" },
  { judet: "Vaslui", localitate: "Mascurei" },
  { judet: "Vaslui", localitate: "Tomesti" },
  { judet: "Vaslui", localitate: "Dealu Secarii" },
  { judet: "Vaslui", localitate: "Floresti" },
  { judet: "Vaslui", localitate: "Frasinu" },
  { judet: "Vaslui", localitate: "Fundu Vaii (Poienesti)" },
  { judet: "Vaslui", localitate: "Oprisita" },
  { judet: "Vaslui", localitate: "Poienesti-Deal" },
  { judet: "Vaslui", localitate: "Bartalus-Mocani" },
  { judet: "Vaslui", localitate: "Bartalus-Razesi" },
  { judet: "Vaslui", localitate: "Calimanesti" },
  { judet: "Vaslui", localitate: "Cetatuia" },
  { judet: "Vaslui", localitate: "Cristesti" },
  { judet: "Vaslui", localitate: "Fantanele" },
  { judet: "Vaslui", localitate: "Fulgu" },
  { judet: "Vaslui", localitate: "Galtesti" },
  { judet: "Vaslui", localitate: "Iezer" },
  { judet: "Vaslui", localitate: "Lalesti" },
  { judet: "Vaslui", localitate: "Rotari" },
  { judet: "Vaslui", localitate: "Rusi" },
  { judet: "Vaslui", localitate: "Armasoaia" },
  { judet: "Vaslui", localitate: "Cursesti-Deal" },
  { judet: "Vaslui", localitate: "Cursesti-Vale" },
  { judet: "Vaslui", localitate: "Hordila" },
  { judet: "Vaslui", localitate: "Rapsa" },
  { judet: "Vaslui", localitate: "Silistea (Pungesti)" },
  { judet: "Vaslui", localitate: "Stejaru" },
  { judet: "Vaslui", localitate: "Toporasti" },
  { judet: "Vaslui", localitate: "Bolati" },
  { judet: "Vaslui", localitate: "Craciunesti" },
  { judet: "Vaslui", localitate: "Draxeni" },
  { judet: "Vaslui", localitate: "Macresti (Rebricea)" },
  { judet: "Vaslui", localitate: "Ratesu Cuzei" },
  { judet: "Vaslui", localitate: "Sasova" },
  { judet: "Vaslui", localitate: "Tatomiresti" },
  { judet: "Vaslui", localitate: "Tufestii de Jos" },
  { judet: "Vaslui", localitate: "Codreni" },
  { judet: "Vaslui", localitate: "Gara Rosiesti" },
  { judet: "Vaslui", localitate: "Gura Idrici" },
  { judet: "Vaslui", localitate: "Idrici" },
  { judet: "Vaslui", localitate: "Rediu (Rosiesti)" },
  { judet: "Vaslui", localitate: "Valea lui Darie" },
  { judet: "Vaslui", localitate: "Bousori" },
  { judet: "Vaslui", localitate: "Iaz" },
  { judet: "Vaslui", localitate: "Satu Nou (Solesti)" },
  { judet: "Vaslui", localitate: "Serbotesti" },
  { judet: "Vaslui", localitate: "Stioborani" },
  { judet: "Vaslui", localitate: "Valea Silistei" },
  { judet: "Vaslui", localitate: "Bogdana-Voloseni" },
  { judet: "Vaslui", localitate: "Budu Cantemir" },
  { judet: "Vaslui", localitate: "Chersacosu" },
  { judet: "Vaslui", localitate: "Gura Vaii" },
  { judet: "Vaslui", localitate: "Poganesti" },
  { judet: "Vaslui", localitate: "Saratu" },
  { judet: "Vaslui", localitate: "Barzesti" },
  { judet: "Vaslui", localitate: "Brahasoaia" },
  { judet: "Vaslui", localitate: "Calugareni" },
  { judet: "Vaslui", localitate: "Cantalaresti" },
  { judet: "Vaslui", localitate: "Maraseni" },
  { judet: "Vaslui", localitate: "Muntenesti" },
  { judet: "Vaslui", localitate: "Fedesti" },
  { judet: "Vaslui", localitate: "Jigalia" },
  { judet: "Vaslui", localitate: "Rascani (Suletea)" },
  { judet: "Vaslui", localitate: "Benesti" },
  { judet: "Vaslui", localitate: "Satu Nou (Muntenii de Sus)" },
  { judet: "Vaslui", localitate: "Cujba" },
  { judet: "Vaslui", localitate: "Dumasca" },
  { judet: "Vaslui", localitate: "Focseasca" },
  { judet: "Vaslui", localitate: "Mircesti" },
  { judet: "Vaslui", localitate: "Protopopesti" },
  { judet: "Vaslui", localitate: "Sofieni" },
  { judet: "Vaslui", localitate: "Baltati" },
  { judet: "Vaslui", localitate: "Crasnaseni" },
  { judet: "Vaslui", localitate: "Giurgesti" },
  { judet: "Vaslui", localitate: "Leosti (Tatarani)" },
  { judet: "Vaslui", localitate: "Mantu" },
  { judet: "Vaslui", localitate: "Stroiesti" },
  { judet: "Vaslui", localitate: "Valea Seaca" },
  { judet: "Vaslui", localitate: "Valea lui Bosie" },
  { judet: "Vaslui", localitate: "Cotic" },
  { judet: "Vaslui", localitate: "Dragesti" },
  { judet: "Vaslui", localitate: "Huc" },
  { judet: "Vaslui", localitate: "Plopoasa" },
  { judet: "Vaslui", localitate: "Silistea (Todiresti)" },
  { judet: "Vaslui", localitate: "Sofronesti" },
  { judet: "Vaslui", localitate: "Valea Popii" },
  { judet: "Vaslui", localitate: "Viisoara (Todiresti)" },
  { judet: "Vaslui", localitate: "Badeana" },
  { judet: "Vaslui", localitate: "Borodesti" },
  { judet: "Vaslui", localitate: "Ciortolom" },
  { judet: "Vaslui", localitate: "Coroiu" },
  { judet: "Vaslui", localitate: "Crivesti" },
  { judet: "Vaslui", localitate: "Satu Nou (Pochidia)" },
  { judet: "Vaslui", localitate: "Salceni" },
  { judet: "Vaslui", localitate: "Vizureni" },
  { judet: "Vaslui", localitate: "Moara Domneasca" },
  { judet: "Vaslui", localitate: "Bumbata" },
  { judet: "Vaslui", localitate: "Halta Dodesti" },
  { judet: "Vaslui", localitate: "Urdesti" },
  { judet: "Vaslui", localitate: "Valeni (Viisoara)" },
  { judet: "Vaslui", localitate: "Viltotesti" },
  { judet: "Vaslui", localitate: "Bradesti" },
  { judet: "Vaslui", localitate: "Docani" },
  { judet: "Vaslui", localitate: "Docaneasa" },
  { judet: "Vaslui", localitate: "Gara Docaneasa" },
  { judet: "Vaslui", localitate: "Gara Talasman" },
  { judet: "Vaslui", localitate: "Obarseni (Vinderei)" },
  { judet: "Vaslui", localitate: "Valea Lunga" },
  { judet: "Vaslui", localitate: "Avramesti" },
  { judet: "Vaslui", localitate: "Bancesti" },
  { judet: "Vaslui", localitate: "Corobanesti" },
  { judet: "Vaslui", localitate: "Gardesti" },
  { judet: "Vaslui", localitate: "Marasesti" },
  { judet: "Vaslui", localitate: "Obarseni (Voinesti)" },
  { judet: "Vaslui", localitate: "Obarsenii Lingurari" },
  { judet: "Vaslui", localitate: "Rugaria" },
  { judet: "Vaslui", localitate: "Stancaseni" },
  { judet: "Vaslui", localitate: "Uricari" },
  { judet: "Vaslui", localitate: "Buhaiesti" },
  { judet: "Vaslui", localitate: "Podeni" },
  { judet: "Vaslui", localitate: "Voinesti (Vulturesti)" },
  { judet: "Vaslui", localitate: "Malaiesti" },
  { judet: "Vaslui", localitate: "Posta Elan" },
  { judet: "Vaslui", localitate: "Butucaria" },
  { judet: "Vaslui", localitate: "Ciofeni" },
  { judet: "Vaslui", localitate: "Delea" },
  { judet: "Vaslui", localitate: "Dobroslovesti" },
  { judet: "Vaslui", localitate: "Macresti (Zapodeni)" },
  { judet: "Vaslui", localitate: "Portari" },
  { judet: "Vaslui", localitate: "Telejna" },
  { judet: "Vaslui", localitate: "Uncesti" },
  { judet: "Vaslui", localitate: "Dealu Mare" },
  { judet: "Vaslui", localitate: "Popeni (Zorleni)" },
  { judet: "Vaslui", localitate: "Simila" },
  { judet: "Vrancea", localitate: "Focsani" },
  { judet: "Vrancea", localitate: "Adjud" },
  { judet: "Vrancea", localitate: "Panciu" },
  { judet: "Vrancea", localitate: "Marasesti" },
  { judet: "Vrancea", localitate: "Odobesti" },
  { judet: "Vrancea", localitate: "Andreiasu de Jos" },
  { judet: "Vrancea", localitate: "Balesti" },
  { judet: "Vrancea", localitate: "Barsesti" },
  { judet: "Vrancea", localitate: "Biliesti" },
  { judet: "Vrancea", localitate: "Boghesti" },
  { judet: "Vrancea", localitate: "Bolotesti" },
  { judet: "Vrancea", localitate: "Bordesti" },
  { judet: "Vrancea", localitate: "Brosteni" },
  { judet: "Vrancea", localitate: "Campineanca" },
  { judet: "Vrancea", localitate: "Campuri" },
  { judet: "Vrancea", localitate: "Carligele" },
  { judet: "Vrancea", localitate: "Chiojdeni" },
  { judet: "Vrancea", localitate: "Ciorasti" },
  { judet: "Vrancea", localitate: "Corbita" },
  { judet: "Vrancea", localitate: "Cotesti" },
  { judet: "Vrancea", localitate: "Dumbraveni" },
  { judet: "Vrancea", localitate: "Dumitresti" },
  { judet: "Vrancea", localitate: "Fitionesti" },
  { judet: "Vrancea", localitate: "Garoafa" },
  { judet: "Vrancea", localitate: "Golesti" },
  { judet: "Vrancea", localitate: "Gologanu" },
  { judet: "Vrancea", localitate: "Gugesti" },
  { judet: "Vrancea", localitate: "Gura Calitei" },
  { judet: "Vrancea", localitate: "Homocea" },
  { judet: "Vrancea", localitate: "Jaristea" },
  { judet: "Vrancea", localitate: "Jitia" },
  { judet: "Vrancea", localitate: "Maicanesti" },
  { judet: "Vrancea", localitate: "Mera" },
  { judet: "Vrancea", localitate: "Milcovul" },
  { judet: "Vrancea", localitate: "Movilita" },
  { judet: "Vrancea", localitate: "Nanesti" },
  { judet: "Vrancea", localitate: "Naruja" },
  { judet: "Vrancea", localitate: "Negrilesti" },
  { judet: "Vrancea", localitate: "Nereju" },
  { judet: "Vrancea", localitate: "Nistoresti" },
  { judet: "Vrancea", localitate: "Obrejita" },
  { judet: "Vrancea", localitate: "Paltin" },
  { judet: "Vrancea", localitate: "Paulesti" },
  { judet: "Vrancea", localitate: "Paunesti" },
  { judet: "Vrancea", localitate: "Ploscuteni" },
  { judet: "Vrancea", localitate: "Poiana Cristei" },
  { judet: "Vrancea", localitate: "Popesti" },
  { judet: "Vrancea", localitate: "Pufesti" },
  { judet: "Vrancea", localitate: "Racoasa" },
  { judet: "Vrancea", localitate: "Rastoaca" },
  { judet: "Vrancea", localitate: "Reghiu" },
  { judet: "Vrancea", localitate: "Ruginesti" },
  { judet: "Vrancea", localitate: "Sihlea" },
  { judet: "Vrancea", localitate: "Slobozia Bradului" },
  { judet: "Vrancea", localitate: "Slobozia Ciorasti" },
  { judet: "Vrancea", localitate: "Soveja" },
  { judet: "Vrancea", localitate: "Spulber" },
  { judet: "Vrancea", localitate: "Straoane" },
  { judet: "Vrancea", localitate: "Suraia" },
  { judet: "Vrancea", localitate: "Tamboesti" },
  { judet: "Vrancea", localitate: "Tanasoaia" },
  { judet: "Vrancea", localitate: "Tataranu" },
  { judet: "Vrancea", localitate: "Tulnici" },
  { judet: "Vrancea", localitate: "Tifesti" },
  { judet: "Vrancea", localitate: "Urechesti" },
  { judet: "Vrancea", localitate: "Valea Sarii" },
  { judet: "Vrancea", localitate: "Vanatori" },
  { judet: "Vrancea", localitate: "Vartescoiu" },
  { judet: "Vrancea", localitate: "Vidra" },
  { judet: "Vrancea", localitate: "Vintileasca" },
  { judet: "Vrancea", localitate: "Vizantea-Livezi" },
  { judet: "Vrancea", localitate: "Vrancioaia" },
  { judet: "Vrancea", localitate: "Vulturu" },
  { judet: "Vrancea", localitate: "Mandresti-Moldova" },
  { judet: "Vrancea", localitate: "Mandresti-Munteni" },
  { judet: "Vrancea", localitate: "Adjudu Vechi" },
  { judet: "Vrancea", localitate: "Burcioaia" },
  { judet: "Vrancea", localitate: "Siscani" },
  { judet: "Vrancea", localitate: "Calimanesti" },
  { judet: "Vrancea", localitate: "Haret" },
  { judet: "Vrancea", localitate: "Modruzeni" },
  { judet: "Vrancea", localitate: "Padureni (Marasesti)" },
  { judet: "Vrancea", localitate: "Siretu" },
  { judet: "Vrancea", localitate: "Tisita" },
  { judet: "Vrancea", localitate: "Unirea" },
  { judet: "Vrancea", localitate: "Crucea de Jos" },
  { judet: "Vrancea", localitate: "Crucea de Sus" },
  { judet: "Vrancea", localitate: "Dumbrava (Panciu)" },
  { judet: "Vrancea", localitate: "Neicu" },
  { judet: "Vrancea", localitate: "Satu Nou (Panciu)" },
  { judet: "Vrancea", localitate: "Andreiasu de Sus" },
  { judet: "Vrancea", localitate: "Arsita" },
  { judet: "Vrancea", localitate: "Fetig" },
  { judet: "Vrancea", localitate: "Hotaru" },
  { judet: "Vrancea", localitate: "Rachitasu" },
  { judet: "Vrancea", localitate: "Titila" },
  { judet: "Vrancea", localitate: "Topesti" },
  { judet: "Vrancea", localitate: "Bichesti" },
  { judet: "Vrancea", localitate: "Boghestii de Sus" },
  { judet: "Vrancea", localitate: "Chitcani" },
  { judet: "Vrancea", localitate: "Iugani" },
  { judet: "Vrancea", localitate: "Placinteni" },
  { judet: "Vrancea", localitate: "Plesesti" },
  { judet: "Vrancea", localitate: "Prisecani" },
  { judet: "Vrancea", localitate: "Tabucesti" },
  { judet: "Vrancea", localitate: "Gagesti" },
  { judet: "Vrancea", localitate: "Ivancesti" },
  { judet: "Vrancea", localitate: "Pietroasa (Bolotesti)" },
  { judet: "Vrancea", localitate: "Putna" },
  { judet: "Vrancea", localitate: "Vitanestii de sub Magura" },
  { judet: "Vrancea", localitate: "Bordestii de Jos" },
  { judet: "Vrancea", localitate: "Arva" },
  { judet: "Vrancea", localitate: "Pitulusa" },
  { judet: "Vrancea", localitate: "Pietroasa (Campineanca)" },
  { judet: "Vrancea", localitate: "Valcele" },
  { judet: "Vrancea", localitate: "Fetesti" },
  { judet: "Vrancea", localitate: "Gura Vaii" },
  { judet: "Vrancea", localitate: "Rotilestii Mari" },
  { judet: "Vrancea", localitate: "Rotilestii Mici" },
  { judet: "Vrancea", localitate: "Blidari (Carligele)" },
  { judet: "Vrancea", localitate: "Bontesti" },
  { judet: "Vrancea", localitate: "Dalhauti" },
  { judet: "Vrancea", localitate: "Catauti" },
  { judet: "Vrancea", localitate: "Lojnita" },
  { judet: "Vrancea", localitate: "Luncile" },
  { judet: "Vrancea", localitate: "Maracini" },
  { judet: "Vrancea", localitate: "Podurile" },
  { judet: "Vrancea", localitate: "Seciu" },
  { judet: "Vrancea", localitate: "Tulburea" },
  { judet: "Vrancea", localitate: "Codresti" },
  { judet: "Vrancea", localitate: "Mihalceni" },
  { judet: "Vrancea", localitate: "Salcia Noua" },
  { judet: "Vrancea", localitate: "Salcia Veche" },
  { judet: "Vrancea", localitate: "Satu Nou (Ciorasti)" },
  { judet: "Vrancea", localitate: "Spatareasa" },
  { judet: "Vrancea", localitate: "Buda" },
  { judet: "Vrancea", localitate: "Izvoarele" },
  { judet: "Vrancea", localitate: "Largaseni" },
  { judet: "Vrancea", localitate: "Ochesesti" },
  { judet: "Vrancea", localitate: "Radacinesti" },
  { judet: "Vrancea", localitate: "Serbanesti" },
  { judet: "Vrancea", localitate: "Tutu" },
  { judet: "Vrancea", localitate: "Valcelele" },
  { judet: "Vrancea", localitate: "Budesti" },
  { judet: "Vrancea", localitate: "Golestii de Sus" },
  { judet: "Vrancea", localitate: "Valea Cotesti" },
  { judet: "Vrancea", localitate: "Alexandru Vlahuta" },
  { judet: "Vrancea", localitate: "Candesti" },
  { judet: "Vrancea", localitate: "Dragosloveni (Dumbraveni)" },
  { judet: "Vrancea", localitate: "Bicestii de Jos" },
  { judet: "Vrancea", localitate: "Bicestii de Sus" },
  { judet: "Vrancea", localitate: "Blidari (Dumitresti)" },
  { judet: "Vrancea", localitate: "Dumitrestii de Sus" },
  { judet: "Vrancea", localitate: "Dumitrestii-Fata" },
  { judet: "Vrancea", localitate: "Galoiesti" },
  { judet: "Vrancea", localitate: "Lastuni" },
  { judet: "Vrancea", localitate: "Lupoaia" },
  { judet: "Vrancea", localitate: "Motnau" },
  { judet: "Vrancea", localitate: "Poienita" },
  { judet: "Vrancea", localitate: "Roscari" },
  { judet: "Vrancea", localitate: "Siminoc" },
  { judet: "Vrancea", localitate: "Tinoasa" },
  { judet: "Vrancea", localitate: "Trestia" },
  { judet: "Vrancea", localitate: "Valea Mica" },
  { judet: "Vrancea", localitate: "Ciolanesti" },
  { judet: "Vrancea", localitate: "Ghimicesti" },
  { judet: "Vrancea", localitate: "Holbanesti" },
  { judet: "Vrancea", localitate: "Manastioara" },
  { judet: "Vrancea", localitate: "Bizighesti" },
  { judet: "Vrancea", localitate: "Ciuslea" },
  { judet: "Vrancea", localitate: "Doaga" },
  { judet: "Vrancea", localitate: "Faurei" },
  { judet: "Vrancea", localitate: "Precistanu" },
  { judet: "Vrancea", localitate: "Rachitosu" },
  { judet: "Vrancea", localitate: "Strajescu" },
  { judet: "Vrancea", localitate: "Ceardac" },
  { judet: "Vrancea", localitate: "Oreavu" },
  { judet: "Vrancea", localitate: "Balanesti" },
  { judet: "Vrancea", localitate: "Cocosari" },
  { judet: "Vrancea", localitate: "Dealu Lung" },
  { judet: "Vrancea", localitate: "Groapa Tufei" },
  { judet: "Vrancea", localitate: "Lacu lui Baban" },
  { judet: "Vrancea", localitate: "Plopu" },
  { judet: "Vrancea", localitate: "Poenile" },
  { judet: "Vrancea", localitate: "Rasca" },
  { judet: "Vrancea", localitate: "Sotarcari" },
  { judet: "Vrancea", localitate: "Argea" },
  { judet: "Vrancea", localitate: "Costisa (Homocea)" },
  { judet: "Vrancea", localitate: "Lespezi" },
  { judet: "Vrancea", localitate: "Padureni (Jaristea)" },
  { judet: "Vrancea", localitate: "Scanteia" },
  { judet: "Vrancea", localitate: "Varsatura" },
  { judet: "Vrancea", localitate: "Cerbu" },
  { judet: "Vrancea", localitate: "Dealu Sarii" },
  { judet: "Vrancea", localitate: "Jitia de Jos" },
  { judet: "Vrancea", localitate: "Magura" },
  { judet: "Vrancea", localitate: "Belciugele" },
  { judet: "Vrancea", localitate: "Ramniceni" },
  { judet: "Vrancea", localitate: "Slobozia Botesti" },
  { judet: "Vrancea", localitate: "Stupina" },
  { judet: "Vrancea", localitate: "Tataru" },
  { judet: "Vrancea", localitate: "Livada" },
  { judet: "Vrancea", localitate: "Milcovel" },
  { judet: "Vrancea", localitate: "Rosioara" },
  { judet: "Vrancea", localitate: "Vulcaneasa" },
  { judet: "Vrancea", localitate: "Lamotesti" },
  { judet: "Vrancea", localitate: "Diocheti-Rediu" },
  { judet: "Vrancea", localitate: "Frecatei" },
  { judet: "Vrancea", localitate: "Trotusanu" },
  { judet: "Vrancea", localitate: "Valeni (Movilita)" },
  { judet: "Vrancea", localitate: "Calienii Noi" },
  { judet: "Vrancea", localitate: "Calienii Vechi" },
  { judet: "Vrancea", localitate: "Podu Narujei" },
  { judet: "Vrancea", localitate: "Podu Stoica" },
  { judet: "Vrancea", localitate: "Rebegari" },
  { judet: "Vrancea", localitate: "Bradacesti" },
  { judet: "Vrancea", localitate: "Chiricani" },
  { judet: "Vrancea", localitate: "Nereju Mic" },
  { judet: "Vrancea", localitate: "Sahastru" },
  { judet: "Vrancea", localitate: "Batcari" },
  { judet: "Vrancea", localitate: "Bradetu" },
  { judet: "Vrancea", localitate: "Fagetu" },
  { judet: "Vrancea", localitate: "Podu Schiopului" },
  { judet: "Vrancea", localitate: "Romanesti" },
  { judet: "Vrancea", localitate: "Ungureni" },
  { judet: "Vrancea", localitate: "Valea Neagra" },
  { judet: "Vrancea", localitate: "Vetresti-Herastrau" },
  { judet: "Vrancea", localitate: "Carsochesti-Corabita" },
  { judet: "Vrancea", localitate: "Ghebari" },
  { judet: "Vrancea", localitate: "Moraresti" },
  { judet: "Vrancea", localitate: "Pavalari" },
  { judet: "Vrancea", localitate: "Prahuda" },
  { judet: "Vrancea", localitate: "Tojanii de Jos" },
  { judet: "Vrancea", localitate: "Tojanii de Sus" },
  { judet: "Vrancea", localitate: "Tepa" },
  { judet: "Vrancea", localitate: "Tipau" },
  { judet: "Vrancea", localitate: "Valcani" },
  { judet: "Vrancea", localitate: "Viisoara (Paunesti)" },
  { judet: "Vrancea", localitate: "Dealu Cucului" },
  { judet: "Vrancea", localitate: "Dumbrava (Poiana Cristei)" },
  { judet: "Vrancea", localitate: "Mahriu" },
  { judet: "Vrancea", localitate: "Odobasca" },
  { judet: "Vrancea", localitate: "Petreanu" },
  { judet: "Vrancea", localitate: "Podu Lacului" },
  { judet: "Vrancea", localitate: "Taratu" },
  { judet: "Vrancea", localitate: "Ciorani" },
  { judet: "Vrancea", localitate: "Domnesti-Sat" },
  { judet: "Vrancea", localitate: "Domnesti-Targ" },
  { judet: "Vrancea", localitate: "Gogoiu" },
  { judet: "Vrancea", localitate: "Marasti" },
  { judet: "Vrancea", localitate: "Varnita" },
  { judet: "Vrancea", localitate: "Verdea" },
  { judet: "Vrancea", localitate: "Farcas" },
  { judet: "Vrancea", localitate: "Jgheaburi" },
  { judet: "Vrancea", localitate: "Piscu Reghiului" },
  { judet: "Vrancea", localitate: "Raiuti" },
  { judet: "Vrancea", localitate: "Sindrilari" },
  { judet: "Vrancea", localitate: "Ursoaia" },
  { judet: "Vrancea", localitate: "Valea Milcovului" },
  { judet: "Vrancea", localitate: "Anghelesti" },
  { judet: "Vrancea", localitate: "Copacesti" },
  { judet: "Vrancea", localitate: "Valeni (Ruginesti)" },
  { judet: "Vrancea", localitate: "Bogza" },
  { judet: "Vrancea", localitate: "Caiata" },
  { judet: "Vrancea", localitate: "Voetin" },
  { judet: "Vrancea", localitate: "Cornetu" },
  { judet: "Vrancea", localitate: "Coroteni" },
  { judet: "Vrancea", localitate: "Liesti" },
  { judet: "Vrancea", localitate: "Olareni" },
  { judet: "Vrancea", localitate: "Valea Beciului" },
  { judet: "Vrancea", localitate: "Armeni" },
  { judet: "Vrancea", localitate: "Jiliste" },
  { judet: "Vrancea", localitate: "Dragosloveni (Soveja)" },
  { judet: "Vrancea", localitate: "Rucareni" },
  { judet: "Vrancea", localitate: "Muncelu" },
  { judet: "Vrancea", localitate: "Repedea" },
  { judet: "Vrancea", localitate: "Valeni (Straoane)" },
  { judet: "Vrancea", localitate: "Calimaneasa" },
  { judet: "Vrancea", localitate: "Costisa (Tanasoaia)" },
  { judet: "Vrancea", localitate: "Costisa de Sus" },
  { judet: "Vrancea", localitate: "Covrag" },
  { judet: "Vrancea", localitate: "Feldioara" },
  { judet: "Vrancea", localitate: "Galbeni" },
  { judet: "Vrancea", localitate: "Nanesti (Tanasoaia)" },
  { judet: "Vrancea", localitate: "Vladnicu de Jos" },
  { judet: "Vrancea", localitate: "Vladnicu de Sus" },
  { judet: "Vrancea", localitate: "Bordeasca Noua" },
  { judet: "Vrancea", localitate: "Bordeasca Veche" },
  { judet: "Vrancea", localitate: "Martinesti" },
  { judet: "Vrancea", localitate: "Vajaitoarea" },
  { judet: "Vrancea", localitate: "Padureni (Tamboesti)" },
  { judet: "Vrancea", localitate: "Pietroasa (Tamboesti)" },
  { judet: "Vrancea", localitate: "Slimnic" },
  { judet: "Vrancea", localitate: "Trestieni" },
  { judet: "Vrancea", localitate: "Coza" },
  { judet: "Vrancea", localitate: "Gresu" },
  { judet: "Vrancea", localitate: "Haulisca" },
  { judet: "Vrancea", localitate: "Lepsa" },
  { judet: "Vrancea", localitate: "Batinesti" },
  { judet: "Vrancea", localitate: "Clipicesti" },
  { judet: "Vrancea", localitate: "Igesti" },
  { judet: "Vrancea", localitate: "Olesesti" },
  { judet: "Vrancea", localitate: "Patrascani" },
  { judet: "Vrancea", localitate: "Sarbi" },
  { judet: "Vrancea", localitate: "Vitanesti" },
  { judet: "Vrancea", localitate: "Terchesti" },
  { judet: "Vrancea", localitate: "Colacu" },
  { judet: "Vrancea", localitate: "Matacina" },
  { judet: "Vrancea", localitate: "Poduri" },
  { judet: "Vrancea", localitate: "Prisaca" },
  { judet: "Vrancea", localitate: "Balta Ratei" },
  { judet: "Vrancea", localitate: "Jorasti" },
  { judet: "Vrancea", localitate: "Mircestii Noi" },
  { judet: "Vrancea", localitate: "Mircestii Vechi" },
  { judet: "Vrancea", localitate: "Petresti" },
  { judet: "Vrancea", localitate: "Radulesti" },
  { judet: "Vrancea", localitate: "Beciu" },
  { judet: "Vrancea", localitate: "Faraoanele" },
  { judet: "Vrancea", localitate: "Olteni" },
  { judet: "Vrancea", localitate: "Pietroasa (Vartescoiu)" },
  { judet: "Vrancea", localitate: "Ramniceanca" },
  { judet: "Vrancea", localitate: "Burca" },
  { judet: "Vrancea", localitate: "Iresti" },
  { judet: "Vrancea", localitate: "Ruget" },
  { judet: "Vrancea", localitate: "Scafari" },
  { judet: "Vrancea", localitate: "Serbesti" },
  { judet: "Vrancea", localitate: "Tichiris" },
  { judet: "Vrancea", localitate: "Viisoara (Vidra)" },
  { judet: "Vrancea", localitate: "Voloscani" },
  { judet: "Vrancea", localitate: "Bahnele" },
  { judet: "Vrancea", localitate: "Dupa Magura" },
  { judet: "Vrancea", localitate: "Neculele" },
  { judet: "Vrancea", localitate: "Poiana Stoichii" },
  { judet: "Vrancea", localitate: "Tanasari" },
  { judet: "Vrancea", localitate: "Livezile" },
  { judet: "Vrancea", localitate: "Mesteacanu" },
  { judet: "Vrancea", localitate: "Piscu Radului" },
  { judet: "Vrancea", localitate: "Vizantea Manastireasca" },
  { judet: "Vrancea", localitate: "Vizantea Razaseasca" },
  { judet: "Vrancea", localitate: "Bodesti" },
  { judet: "Vrancea", localitate: "Muncei" },
  { judet: "Vrancea", localitate: "Plostina" },
  { judet: "Vrancea", localitate: "Poiana" },
  { judet: "Vrancea", localitate: "Spinesti" },
  { judet: "Vrancea", localitate: "Botarlau" },
  { judet: "Vrancea", localitate: "Hangulesti" },
  { judet: "Vrancea", localitate: "Maluri" },
  { judet: "Vrancea", localitate: "Vadu Rosca" },
];
